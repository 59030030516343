import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateSince'
})
export class DateSincePipe implements PipeTransform {

  transform(milliseconds: any, args?: any): any {

      if(milliseconds <= 0 || isNaN(milliseconds)){
          return '';
      }


      const secondsAgo = Math.round(parseInt(milliseconds, 10) / 1000);
      if (isNaN(secondsAgo) || secondsAgo < 0){
          return '';
      }

      if (secondsAgo < 30){
          return 'just now';
      }

      if (secondsAgo < 60){
          return this.pluralize('sec', secondsAgo);
      }

      const minutesAgo = Math.round(secondsAgo / 60);

      if (minutesAgo < 60){
          return this.pluralize('min', minutesAgo);
      }

      const hoursAgo = Math.round(minutesAgo / 60);

      if (hoursAgo < 24){
          return this.pluralize('hour', hoursAgo);
      }

      const daysAgo = Math.round(hoursAgo / 24);

      return this.pluralize('day', daysAgo);
  }

  pluralize(str: string, count: number): string{

      return `${count} ${str}${count > 1 ? 's' : ''} ago`;

  }



}
