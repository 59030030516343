import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {EMPTY, merge, Observable, of, Subject} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {SearchableObject, SearchableObjectType, SearchFieldCriteria} from '@looma/shared/search';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {NamedValue} from '@looma/shared/types/named_value';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Utils} from '@looma/shared/utils';
import {WordFilterable} from '@looma/shared/types/word_filterable';
import {SearchService} from "@looma/shared/services/search.sevice";

@LifecycleHooks()
@Component({
    selector: 'app-searchable-autocomplete',
    templateUrl: './searchable-autocomplete.component.html',
    styleUrls: ['./searchable-autocomplete.component.scss'],
    exportAs: 'objectSearchAutoComplete',
})
export class SearchableAutocompleteComponent implements OnInit, OnDestroy, AfterViewInit {

    control = new FormControl();
    data: Observable<NamedValue[]>;

    private searchExternal = new Subject<string>();
    private valueSubject = new Subject<NamedValue | null>();
    private _searchCriteria: SearchFieldCriteria[] = [];
    private _hasDirtySearchCriteria = true;
    private _searchObjectType: SearchableObject;

    private focusWatcher = new FocusWatcher(this);
    private searchSource: SearchableSource;

    @ViewChild(MatAutocomplete, {static: true}) autocomplete: MatAutocomplete;

    @Input('objectType')
    set objectType(value: SearchableObjectType) {
        this._searchObjectType = SearchableObject.byType(value)
    }

    get searchObjectType(): SearchableObject {
        return this._searchObjectType;
    }

    @Input('value')
    set value(value: NamedValue) {
        if (value) {
            this.control.setValue(value)
        }
    }

    @Input('searchCriteria')
    set searchCriteria(value: SearchFieldCriteria | SearchFieldCriteria[]) {
        if (value instanceof SearchFieldCriteria) {
            value = [value]
        }
        if (JSON.stringify(value) !== JSON.stringify(this._searchCriteria)) {
            this._searchCriteria = value;
            this._hasDirtySearchCriteria = true;
        }
    }

    @Input('enabled')
    set enabled(value: boolean) {
        if (value !== this.control.enabled) {
            if (value) {
                this.control.enable();
            } else {
                this.control.disable();
            }
        }
    }

    get enabled(): boolean {
        return this.control.enabled;
    }

    @Input('searchValues') set searchValues(values: WordFilterable[]) {
        if (Array.isArray(values)) {
            this.searchSource = new LocalSearch(values)
        } else {
            this.searchSource = new RemoteSearch(this.svcApi)
        }
    }
    

    @Output()
    get onSelectionChanged(): Observable<NamedValue | null> {
        return this.valueSubject.pipe(
            distinctUntilChanged((x: NamedValue, y: NamedValue) => {
                return JSON.stringify(x) === JSON.stringify(y)
            })
        )
    }
    
    constructor(
        private svcApi: SearchService,
        private _elemRef: ElementRef<any>
    ) {
        this.searchValues = null;
    }

    ngOnInit(): void {

        let selectedValue = null;

        this.data = merge(
            this.control.valueChanges.pipe(
                tap(x => {
                    if (x instanceof NamedValue) {
                        selectedValue = x;
                        setTimeout(() => {
                            this.focusWatcher.blur()
                        }, 0)
                    } else {
                        selectedValue = null;
                    }
                }),
                debounceTime(300),
            ),
            this.searchExternal
        ).pipe(
            filter(value => typeof value === 'string'),
            map(value => ((value || '')).trim()),
            filter(_ => this.focusWatcher.isFocused),
            switchMap((value: string) => this.loadData(value)),
            takeUntil(Utils.onDestroy(this)),
        );

        this.focusWatcher.onFocusChanged().subscribe(isFocused => {
            if (!isFocused) {
                this.valueSubject.next(selectedValue);
                if (!selectedValue) {
                    this.clear()
                }
            } else {
                this.searchExternal.next('')
            }
        })
    }

    loadData(searchInput: string): Observable<NamedValue[]> {
        if (!this.focusWatcher.isFocused) {
            return EMPTY
        }
        if (!this.searchObjectType) {
            return EMPTY;
        }
        let criteria = [SearchFieldCriteria.newTextContainsCriteria(searchInput)];
        if (this._searchCriteria && this._searchCriteria.length) {
            criteria = criteria.concat(this._searchCriteria)
        }
        return this.svcApi.searchObject(this.searchObjectType, criteria)
    }

    onFocus(ev: FocusEvent): void {
        this.focusWatcher.handleEvent(ev);
        if (this._hasDirtySearchCriteria) {
            this._hasDirtySearchCriteria = false;
            this.searchExternal.next(this.control.value || '')
        }
    }

    onBlur(ev: FocusEvent): void {
        this.focusWatcher.handleEvent(ev);
    }

    ngOnDestroy(): void {
        this.valueSubject.complete();
    }

    displayFn(a: NamedValue): string {
        return a ? a.name : '';
    }

    clear(): void {
        this.control.setValue('');
        this.focusWatcher.clearValue()
    }

    ngAfterViewInit(): void {
    }
}


class FocusWatcher {

    focusSub = new Subject<boolean>();
    isFocused: boolean;
    htmlEl: HTMLInputElement;

    get focusedElement(): HTMLInputElement | null {
        if (this.isFocused) {
            return this.htmlEl
        }
        return null
    }

    constructor(private parent: SearchableAutocompleteComponent) {
    }

    onFocusChanged(): Observable<boolean> {
        return this.focusSub.pipe(
            takeUntil(Utils.onDestroy(this.parent)),
            debounceTime(100),
            distinctUntilChanged(),
            tap(x => {
                this.isFocused = x
            })
        )
    }


    handleEvent(ev: FocusEvent) {
        let newFocused = false;
        switch (ev.type) {
            case 'focus':
            case 'blur':
                newFocused = ev.type == 'focus';
                break;
            default:
                return
        }
        this.htmlEl = ev.target as HTMLInputElement;
        this.focusSub.next(newFocused);
    }

    blur() {
        if (this.htmlEl) {
            this.htmlEl.blur()
        }
    }

    focus() {
        if (this.htmlEl) {
            this.htmlEl.focus()
        }
    }

    clearValue() {
        if (this.htmlEl) {
            this.htmlEl.value = ''
        }
    }

}


interface SearchableSource {
    search(kind: SearchableObject, criteria: SearchFieldCriteria[]): Observable<NamedValue[]>;
}

class LocalSearch implements SearchableSource {

    private searchPipe = new Subject<string>();
    private resultStream: Observable<NamedValue[]>;

    constructor(private data: WordFilterable[]) {
        this.resultStream = Utils.filterByWords(of(data), this.searchPipe).pipe(
            map(value => value.map(value1 => NamedValue.from(value1.getId(), value1.getDisplayName())))
        )
    }

    search(kind: SearchableObject, criteria: SearchFieldCriteria[]): Observable<NamedValue[]> {
        if (criteria.length == 1) {
            if (criteria[0].value.length == 1) {
                this.searchPipe.next(criteria[0].value[0].value)
            }
        }
        return this.resultStream.pipe(
            take(1)
        )
    }
}

class RemoteSearch implements SearchableSource {
    
    constructor(private svcApi: SearchService) {
    }

    search(kind: SearchableObject, criteria: SearchFieldCriteria[]): Observable<NamedValue[]> {
        return this.svcApi.searchObject(kind, criteria);
    }
    
}
