<mat-dialog-content>

    <div class="preview_canvas_wrap">
        <div #canvasWrapEl class="preview_canvas mat-elevation-z2">
            <canvas #canvasEl></canvas>
        </div>
    </div>

    <div class="edit_options_panel  mat-elevation-z2">

        <div fxLayout="column" style="height: 100%; width: 100%">
            <div fxFlex>
                <input type="file" #backgroundImageInput
                       style="position:absolute; top: -1000000px; left:-1000000px; width:1px; height:1px;"
                       (change)="onBackgroundImagePicked($event)"
                       accept="image/*"/>

                <div class="mb-16">
                    <button mat-raised-button (click)="openFileBackgroundPicker()">Upload Background Image</button>
                </div>

                <!--        <button mat-raised-button color="primary" (click)="renderer.download()">Download</button>-->

                <mat-form-field class="title_input">
                    <mat-label>Film Title</mat-label>
                    <textarea matInput [ngModel]="templateVars.title.text"
                              (ngModelChange)="handleTitleChanged($event)"></textarea>
                </mat-form-field>

                <div class="full-width">
                    <mat-label>Logo Scale</mat-label>
                    <br/>
                    <mat-slider min="0.2" max="1" step="0.01" class="full-width" [ngModel]="logoScale"
                                (ngModelChange)="handleLogoScaleChanged($event)">
                        <input matSliderThumb>
                    </mat-slider>
                </div>

            </div>
            <div>
                <button mat-raised-button color="primary" (click)="save()">Ok</button>
                <button mat-button (click)="cancel()">Cancel</button>
            </div>
        </div>


    </div>
</mat-dialog-content>
