import {ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {BehaviorSubject, EMPTY, merge, Observable, Subject, Subscription} from "rxjs";
import {debounceTime, map, takeUntil} from "rxjs/operators";
import {ColorSpace, Utils} from "@looma/shared/utils";
import {
    ApiDataService,
    PromoPlaylistMutationInput,
    PromoPlaylistMutationOp,
    PromoPlaylistSlotMutationInput,
    RecordMutationInput
} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {VideoPlayerService} from "../../../../services/video-player.service";
import {moveItemInArray} from "@angular/cdk/drag-drop";
import {NamedValue} from "@looma/shared/types/named_value";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";
import {
    PromoPlaylistAssignment,
    PromoPlaylistAssignmentSlot,
    RetailerPromoPeriodPlaylistAssignmentFlag
} from "@looma/shared/models/promo_playlist_assignment";
import {MediaPlaylist, MediaPlaylistVersion} from "@looma/shared/models/media_playlist";
import {
    MediaPlaylistVersionDialogComponent
} from "../media-playlist-version-dialog/media-playlist-version-dialog.component";
import {PageControllerService} from "../../../../layout/components/looma-page/looma-page.component";
import {ToastNotificationService} from "../../../../services/toast-notification.service";
import {ErrorSnackbarData} from '@looma/shared/types/error-snackbar-data';
import {PlaylistsControllerService} from "../../services/playlist-controller.service";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {Router} from "@angular/router";
import {
    PromoSubgroupPlaylistScheduleComponent
} from "../promo-subgroup-playlist-schedule/promo-subgroup-playlist-schedule.component";
import {RetailerPromoPeriodProgramEntry} from "@looma/shared/models/retailer_promo_periods";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {GridDragDropController} from "../../../../layout/components/looma-grid/looma-grid.component";
import {PromoPlaylistMediaBundle} from "@looma/shared/models/PromoPlaylistMediaBundle";
import Timeout = NodeJS.Timeout;

const
    EditBrandCampaign = 'Edit Brand Campaign',
    ChangeMediaContent = 'Change Media Content';

@LifecycleHooks()
@Component({
    selector: 'app-promo-campaign-playlist-tab',
    templateUrl: './promo-campaign-playlist-tab.component.html',
    styleUrls: ['./promo-campaign-playlist-tab.component.scss']
})
export class PromoCampaignPlaylistTabComponent implements OnInit {

    constructor(
        private router: Router,
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private svcVideoPlayer: VideoPlayerService,
        private svcPlaylists: PlaylistsControllerService,
        private tabController: PageControllerService,
        private svcToastNotif: ToastNotificationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }


    playlistMediaBundleDataSource = new PromoPlaylistMediaBundleDataSource();

    @Input('promoPeriodProgramEntry') set promoPeriodProgramEntry(value: RetailerPromoPeriodProgramEntry) {
        this._promoPeriodProgramEntry = value;
        this.init(value)
    }

    get promoPeriodProgramEntry(): RetailerPromoPeriodProgramEntry {
        return this._promoPeriodProgramEntry;
    }

    @ViewChild('headerSideTpl', {static: true}) set headerSideTpl(tmpl: TemplateRef<any>) {
        this.tabController.setHeaderNav(tmpl)
    }

    get isBusy(): boolean {
        return this.mutationSub && !this.mutationSub.closed;
    }

    private _promoPeriodProgramEntry: RetailerPromoPeriodProgramEntry;

    private brandColorSpace = new ColorSpace(1);

    private playlistRegistryMap: Map<string, PlaylistRegistry>;

    hasDirtyAssignments = false;
    hasUnpublishedPlaylists = false;
    hasInactivatedPlaylists = false;
    private assignmentChangedSub = new BehaviorSubject<CampaignPlaylist>(null);
    private mutationSub: Subscription;

    private playbackEntriesMenuOptions = new Map<string, string[]>();

    ngOnInit() {

        this.assignmentChangedSub.pipe(
            debounceTime(10),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.refreshIsDirty()
        })

    }

    private refreshIsDirty() {
        let isDirty = false;
        let hasUnpublished = false;
        let hasInactivated = false;
        if (!this.playlistRegistryMap) {
            return
        }
        for (const reg of this.playlistRegistryMap.values()) {
            for (const v of reg.campaignPlaylists) {
                if (v.hasChanges) {
                    isDirty = true
                }
                if (v.draftNeedsPublish || v.draftNeedsRepublish) {
                    hasUnpublished = true
                }
                if (v.draftCanActivate) {
                    hasInactivated = true
                }
                if (isDirty && hasUnpublished) {
                    break
                }
            }
        }

        let changed = false
        if (this.hasDirtyAssignments != isDirty) {
            this.hasDirtyAssignments = isDirty;
            changed = true
        }

        if (this.hasUnpublishedPlaylists != hasUnpublished) {
            this.hasUnpublishedPlaylists = hasUnpublished;
            changed = true
        }

        if (this.hasInactivatedPlaylists != hasInactivated) {
            this.hasInactivatedPlaylists = hasInactivated;
            changed = true
        }

        if (changed) {
            this.tabController.notifyHeaderChanged()
        }

    }


    init(promoProgramCampaign: RetailerPromoPeriodProgramEntry) {
        this.svcPlaylists.registerMediaContentBundles(promoProgramCampaign.playlistMediaBundles);

        this.playlistRegistryMap = new Map<string, PlaylistRegistry>();

        for (const segment of promoProgramCampaign.deviceSlotSegments) {
            const k = segment.getStringId()
            this.playlistRegistryMap.set(k, new PlaylistRegistry(this.svcPlaylists, segment, this.assignmentChangedSub))
        }

        PlaylistRegistry.appendPlaylistsForBrandCampaigns(promoProgramCampaign, this.playlistRegistryMap);

        this.playlistMediaBundleDataSource.setLocalData(promoProgramCampaign.playlistMediaBundles || [])

        return;
    }

    editMediaBundle(mb: PromoPlaylistMediaBundle) {
        const isEdit = !mb.isNewRecord();
        this.svcPlaylists.editMediaBundle(mb).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(updated => {
            if (!updated) {
                return;
            }
            this.svcPlaylists.registerMediaContentBundles([mb]);
            this.playlistMediaBundleDataSource.replaceItem(updated);
            let isChanged = false;
            if (isEdit) {
                for (const reg of this.playlistRegistryMap.values()) {
                    for (const pl of reg.campaignPlaylists) {
                        for (const loop of pl.loops) {
                            for (const entry of loop.entries) {
                                if (entry.mediaBundle?.getId() == mb?.getId()) {
                                    entry.mediaBundle = mb;
                                    isChanged = true;
                                }
                            }
                        }
                    }
                }
            }

            if (isChanged) {
                setTimeout(() => {
                    this.changeDetectorRef.detectChanges();
                }, 0)
            }
        })
    }

    deleteMediaBundle(mb: PromoPlaylistMediaBundle) {

        this.svcPlaylists.deleteMediaBundle(mb).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(resp => {
            if (resp.success) {
                this.playlistMediaBundleDataSource.removeItem(resp.data)
            } else {
                this.svcLayout.showMutationResultMessage(resp);
            }
        })
    }

    assignToEmptySlots(mb: PromoPlaylistMediaBundle) {
        let changed = false
        for (const playlist of this.collectPlaylists()) {
            for (const loop of playlist.loops) {
                for (const entry of loop.entries) {
                    if (!entry.mediaBundle && entry.brandCampaign?.brandPartner?.getId() == mb.brandPartner?.getId()) {
                        entry.setMediaBundle(mb)
                        changed = true;
                    }
                }
            }
        }

        if (changed) {
            this.refreshIsDirty();
        }
    }

    createMediaBundle() {

        this.svcPlaylists.createMediaBundle(
            this.promoPeriodProgramEntry.promoPeriod,
            this.promoPeriodProgramEntry.promoProgram
        ).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.playlistMediaBundleDataSource.addItem(value, false)
        })

    }

    goToSlotSubgroups(segment: DeviceSlotSegment) {
        PromoSubgroupPlaylistScheduleComponent.open(this.router, this.promoPeriodProgramEntry.promoPeriod.promoSchedule, this.promoPeriodProgramEntry.promoPeriod, segment)
    }

    addNewPlaylist(segment: DeviceSlotSegment) {
        const reg = this.getPlaylistRegistry(segment)
        if (reg) {
            this.svcLayout.prompt("New Playlist", "Playlist Name").pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(plName => {
                plName = (plName || '').trim()
                if (plName != '') {
                    reg.addEmptyPlaylist(plName)
                }
            })
        }
    }

    deletePlaylist(pl: CampaignPlaylist) {
        if (pl) {
            const reg = this.getPlaylistRegistry(pl.segment)
            if (!reg) {
                return
            }
            this.svcLayout.confirm('Delete Playlist', 'Are you sure you want to delete this playlist?').pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                if (value) {
                    if (pl.playlistAssignment.isNewRecord()) {
                        reg.removePlaylist(pl.key)
                    } else {
                        this.submitPlaylistMutation(PromoPlaylistMutationOp.Delete, [pl.getBaseMutationData()])
                    }
                }
            })
        }
    }

    changePlaylistName(pl: CampaignPlaylist) {
        if (pl) {
            this.svcLayout.prompt({
                title: 'Edit Playlist',
                message: 'Playlist Name',
                initialText: pl.name
            }).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                if (value) {
                    pl.setName(value)
                }
            })
        }
    }

    makeDefaultPlaylist(pl: CampaignPlaylist) {
        if (pl) {

            const reg = this.getPlaylistRegistry(pl.segment)
            if (!reg) {
                return
            }

            const prevDefault = reg.campaignPlaylists.find(value => value.playlistAssignment?.isDefault)
            if (!prevDefault || prevDefault == pl) {
                return
            }

            this.svcLayout.confirm('Change default playlist', `Are you sure you want to change the default playlist?`).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                if (!value) {
                    return
                }
                const data = pl.getBaseMutationData(false, false)
                this.submitPlaylistMutation(PromoPlaylistMutationOp.MakeDefault, [data], results => {
                    if (Array.isArray(results) && results.length == 1) {
                        const res = results[0]
                        if (res.data.isDefault) {
                            prevDefault.playlistAssignment.isDefault = false
                        }
                    }
                })
            })
        }
    }

    massAddPlaylistItem() {
        this.svcPlaylists.massAssignSplashScreen(
            this.promoPeriodProgramEntry.promoPeriod,
            this.promoPeriodProgramEntry.promoProgram
        ).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(resp => {
            if (resp.success) {
                this.playlistMediaBundleDataSource.setLocalData(resp.dataArray)
            } else {
                this.svcLayout.showMutationResultMessage(resp)
            }
        })
    }

    getCampaignPlaylists(segment: DeviceSlotSegment): CampaignPlaylist[] {
        return this.getPlaylistRegistry(segment)?.campaignPlaylists || [];
    }

    getPlaylistRegistry(segment: DeviceSlotSegment): PlaylistRegistry {
        const k = segment.getStringId()
        const reg = this.playlistRegistryMap.get(k);
        return reg;
    }

    getPrimaryPlaylist(segment: DeviceSlotSegment): CampaignPlaylist {
        const playlists = this.getPlaylistRegistry(segment).campaignPlaylists;


        if (!playlists?.length) {
            return null
        }

        for (const pl of playlists) {
            if (pl.playlistAssignment.isDefault) {
                return pl
            }
        }

        return null
    }

    getAssignedColor(camp: BrandPromoCampaign) {
        if (!camp) {
            return 'rgba(0,0,0,0)'
        }

        return this.brandColorSpace.getColor(camp.id);
    }

    getMenuOptionsForPlaybackEntry(playbackEntry: PlaybackLoopEntry) {
        if (!playbackEntry) {
            return []
        }
        const id = playbackEntry.id;
        if (!this.playbackEntriesMenuOptions.has(id)) {
            const opts: string[] = [];

            opts.push(ChangeMediaContent)
            opts.push(EditBrandCampaign);

            this.playbackEntriesMenuOptions.set(id, opts)
        }
        return this.playbackEntriesMenuOptions.get(id);
    }

    performMenuActionForPlaybackEntry(action: string, playbackEntry: PlaybackLoopEntry) {
        const brandCampaign = this.svcPlaylists.getBrandCampaign(playbackEntry.brandCampaign.getId());
        switch (action) {
            case ChangeMediaContent:
                const availableMediaBundles = this.playlistMediaBundleDataSource.getData().map(value => value.getData()).filter(mediaBundle => mediaBundle.brandPartner.id == brandCampaign.brandPartner.id)

                availableMediaBundles.unshift(PromoPlaylistMediaBundle.EMPTY);
                this.svcLayout.promptSearchObject('Select Media Content', 'Media Content', {
                    objectType: "PromoPlaylistMediaBundle",
                    values: availableMediaBundles
                }).pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe((value: NamedValue) => {
                    if (!value) {
                        return
                    }
                    const sel = availableMediaBundles.find(mediaBundle => mediaBundle.id == value.value);
                    playbackEntry.setMediaBundle(sel);
                });
                break;
            case EditBrandCampaign:

                if (this.isBusy) {
                    return
                }

                this.mutationSub = this.svcPlaylists.editBrandCampaign(brandCampaign).pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe(brandCampaign => {
                    if (brandCampaign) {
                        this.playbackEntriesMenuOptions.clear();
                        for (const playlist of this.collectPlaylists()) {
                            for (const loop of playlist.loops) {
                                for (const entry of loop.entries) {
                                    entry.brandCampaign = this.svcPlaylists.getBrandCampaign(entry.brandCampaign.getId())
                                }
                            }
                        }
                    }
                });
                break;
        }
    }

    playFile(mediaBundle: PromoPlaylistMediaBundle) {
        this.svcVideoPlayer.playContent(mediaBundle?.primaryMediaContent)
    }

    resetPlaylists(...playlists: CampaignPlaylist[] | null) {

        this.svcLayout.confirm('Reset playlist', `
Are you sure you want to reset this playlist? 
By continuing all playlist entries will be restored to their default values.        
        `).subscribe(value => {
            if (!value) {
                return
            }

            const mutations = this.collectPlaylistsMutationData(playlists, playlist => {
                if (!playlist.canBeReset) {
                    return null
                }
                return playlist.getBaseMutationData()
            })

            this.submitPlaylistMutation(PromoPlaylistMutationOp.Reset, mutations)
        })


    }

    savePlaylists(...playlists: CampaignPlaylist[] | null) {
        const mutations = this.collectPlaylistsMutationData(playlists, playlist => {
            if (!playlist.hasChanges) {
                return null
            }
            return playlist.getBaseMutationData(true, true)
        })

        this.submitPlaylistMutation(PromoPlaylistMutationOp.Upsert, mutations)
    }

    publishPlaylists(...playlists: CampaignPlaylist[] | null) {
        const mutations = this.collectPlaylistsMutationData(playlists, playlist => {
            if (playlist.hasChanges) {
                return null
            }
            if (!playlist.draftNeedsRepublish && !playlist.draftNeedsPublish) {
                return null
            }
            return playlist.getBaseMutationData()
        })

        this.submitPlaylistMutation(PromoPlaylistMutationOp.Publish, mutations)
    }

    activatePlaylists(...playlists: CampaignPlaylist[] | null) {
        const mutations = this.collectPlaylistsMutationData(playlists, playlist => {
            if (playlist.hasChanges) {
                return null
            }
            if (!playlist.draftCanActivate) {
                return null
            }
            return playlist.getBaseMutationData()
        })

        this.submitPlaylistMutation(PromoPlaylistMutationOp.Activate, mutations)
    }

    private collectPlaylists(playlists?: CampaignPlaylist[]): CampaignPlaylist[] {
        if (!Array.isArray(playlists) || playlists.length == 0) {
            playlists = []
            for (const reg of this.playlistRegistryMap.values()) {
                playlists = playlists.concat(...reg.campaignPlaylists)
            }
        }
        return playlists
    }

    private collectPlaylistsMutationData(
        playlists: CampaignPlaylist[],
        fn: (playlist: CampaignPlaylist) => RecordMutationInput<PromoPlaylistAssignment, PromoPlaylistMutationInput>
    ): RecordMutationInput<PromoPlaylistAssignment, PromoPlaylistMutationInput>[] {
        const data: RecordMutationInput<PromoPlaylistAssignment, PromoPlaylistMutationInput>[] = []

        if (Array.isArray(playlists)) {
            playlists = this.collectPlaylists(playlists)
            for (const pl of playlists) {
                const mutationData = fn(pl)
                if (mutationData) {
                    data.push(mutationData)
                }
            }
        }

        return data

    }

    viewPlaylistDetails(playlist: MediaPlaylist, version: MediaPlaylistVersion) {
        if (playlist && version) {
            MediaPlaylistVersionDialogComponent.open(this.svcLayout, playlist.id, version.id)
        }
    }

    submitPlaylistMutation(
        op: PromoPlaylistMutationOp,
        mutationData: RecordMutationInput<PromoPlaylistAssignment, PromoPlaylistMutationInput>[],
        callback?: (results: MutationResponse<PromoPlaylistAssignment>[]) => void
    ) {
        if (!Array.isArray(mutationData) || !mutationData.length) {
            return
        }

        if (!Utils.isUnsubscribed(this.mutationSub)) {
            return
        }

        this.mutationSub = this.svcPlaylists.submitPlaylistMutation(op, mutationData).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(responses => {

            if (Utils.isFunction(callback)) {
                callback(responses)
            }

            const errors: ErrorSnackbarData[] = [];
            let idx = 0;
            for (const mutationResponse of responses) {
                if (!mutationResponse.success) {
                    const err = Utils.extractMutationErrors(mutationResponse)
                    if (err) {
                        const rec = mutationResponse.data
                        err.title = `Segment ${rec.deviceSlotSegment.getDisplayName()} - ${err.title}`
                        errors.push(err)
                    }
                } else {
                    if (mutationResponse.triggeredJobId) {
                        console.warn('got job', mutationResponse.triggeredJobId)
                        this.svcToastNotif.create({
                            title: `Distributing playlist for segment ${mutationResponse.data.deviceSlotSegment.name} `,
                        }).watchJob(mutationResponse.triggeredJobId).subscribe()
                    }

                    const original = mutationData[idx]
                    LOOP:
                        for (const reg of this.playlistRegistryMap.values()) {
                            for (const playlist of reg.campaignPlaylists) {
                                if (playlist.key == original.mutationKey) {
                                    if (op == PromoPlaylistMutationOp.Delete) {
                                        reg.removePlaylist(playlist.key)
                                    } else {
                                        reg.registerPlaylist(mutationResponse.data, playlist.key)
                                    }

                                    break LOOP
                                }
                            }
                        }

                }

                idx += 1;
            }


            if (errors.length) {
                this.svcLayout.showSnackErrors(errors);
            } else {
                this.svcLayout.showSnackMessage("Playlists updated")
            }

            this.refreshIsDirty()
        })
    }

    onDrop(ev: any) {
        console.warn('onDrop', ev)
    }
}


class CampaignPlaylist {

    private _hasUnassignedSlots: boolean | null = null;

    loops: PlaybackLoop[] = [];
    public segment: DeviceSlotSegment;
    readonly key: string;
    readonly assignmentId: string
    readonly regionTypeKey: string

    private _notifChannel: Subject<CampaignPlaylist>
    private readonly _entriesSignature: Signature
    private readonly _fieldsSignature: Signature
    private _signatureChangedSubscription: Subscription

    name: string

    constructor(private svcPlaylists: PlaylistsControllerService, public playlistAssignment: PromoPlaylistAssignment) {
        this.segment = playlistAssignment.deviceSlotSegment;
        this.regionTypeKey = this.segment.getStringId()
        this.assignmentId = (playlistAssignment.isNewRecord() ? Utils.generateUniqueId() : playlistAssignment.id).toString()
        this.key = `${this.regionTypeKey}-${this.assignmentId}`

        this.applyDefaultValues()

        this._entriesSignature = new Signature(() => {
            let sig = ''
            const empty = 'null'
            for (const loop of this.loops) {
                for (const x of loop.entries) {
                    sig = sig.concat('-', x.brandCampaign?.id || empty, '-', x.mediaBundle?.id || empty)
                }
            }
            return sig
        })

        this._fieldsSignature = new Signature(() => {
            return this.name
        })

    }

    applyDefaultValues() {
        this.name = this.playlistAssignment.name || ''
        this.loops = []
        let prevEntry: PromoPlaylistAssignmentSlot = null;
        let loop = new PlaybackLoop(this);
        for (const playbackEntry of this.playlistAssignment.playbackEntries) {
            const mediaBundle = this.svcPlaylists.getMediaContentBundle(playbackEntry.mediaBundleId)
            const brandCampaign = this.svcPlaylists.getBrandCampaign(playbackEntry.brandCampaignId)

            if (prevEntry && (prevEntry.loopIndex != playbackEntry.loopIndex)) {
                loop = new PlaybackLoop(this)
            }

            const entry = new PlaybackLoopEntry(this, mediaBundle, brandCampaign, playbackEntry.brandCampaignSlotId)
            loop.entries.push(entry)
            prevEntry = playbackEntry
            this.addLoop(loop)
        }

        if (this._entriesSignature) {
            this._entriesSignature.reset()
        }

        if (this._fieldsSignature) {
            this._fieldsSignature.reset()
        }
    }

    setName(name: string) {
        this.name = name
        if (this._fieldsSignature) {
            this._fieldsSignature.invalidate()
        }
    }

    get hasNoSlots(): boolean {
        const entries = this.playlistAssignment.playbackEntries;
        return !entries || (entries.length == 0)
    }

    get hasUnassignedSlots(): boolean {
        if (!Utils.isBoolean(this._hasUnassignedSlots)) {
            for (const x of this.loops) {
                for (const y of x.entries) {
                    if (!y.mediaBundle) {
                        this._hasUnassignedSlots = true;
                    }
                }
            }
        }
        return this._hasUnassignedSlots;
    }

    get hasChanges(): boolean {
        if (this.hasNoSlots) {
            return false
        }
        if (this.playlistAssignment.isNewRecord()) {
            return true
        }
        return this._entriesSignature.changed || this._fieldsSignature.changed;
    }

    get canBeDeleted(): boolean {
        if (this.playlistAssignment.isDefault) {
            return false
        }

        return true
    }

    getBaseMutationData(includeFields = false, includePlaylistEntries = false): RecordMutationInput<PromoPlaylistAssignment, PromoPlaylistMutationInput> {

        const playlistMutationData: Partial<PromoPlaylistMutationInput> = {
            deviceSlotSegmentId: this.segment.id,
            promoPeriodId: this.svcPlaylists.promoPeriod.id
        }

        if (!this.playlistAssignment.isNewRecord()) {
            playlistMutationData.id = this.playlistAssignment.id
        }

        if (includePlaylistEntries && (this.playlistAssignment.isNewRecord() || this._entriesSignature.changed)) {
            playlistMutationData.playbackEntries = []

            let loopIndex = 0
            for (const playbackLoop of this.loops) {
                let trackIndex = 0;
                for (const loopEntry of playbackLoop.entries) {
                    const playbackEntry: PromoPlaylistSlotMutationInput = {
                        brandCampaignSlotId: loopEntry.brandSlotId + '',
                        loopIndex: loopIndex,
                        trackIndex: trackIndex
                    }
                    if (loopEntry.mediaBundle) {
                        playbackEntry.mediaBundleId = loopEntry.mediaBundle.getStringId()
                    }
                    playlistMutationData.playbackEntries.push(playbackEntry)
                    trackIndex += 1
                }
                loopIndex += 1
            }
        }

        if (includeFields && (this.playlistAssignment.isNewRecord() || this._fieldsSignature.changed)) {
            playlistMutationData.name = this.name
        }

        const data: RecordMutationInput<PromoPlaylistAssignment, PromoPlaylistMutationInput> = {
            mutationKey: this.key,
            record: this.playlistAssignment,
            mutationInput: playlistMutationData as PromoPlaylistMutationInput,
        }

        return data
    }

    setNotifChannel(ch: Subject<CampaignPlaylist>) {
        if (this._notifChannel != ch) {
            this._notifChannel = ch
            Utils.unsubscribe(this._signatureChangedSubscription)
            if (ch) {
                if (this.hasChanges) {
                    ch.next(this)
                }

                this._signatureChangedSubscription = Signature.combine(this._entriesSignature, this._fieldsSignature).subscribe(value => {
                    ch.next(this)
                })

            }
        }
    }

    get playlistCreated(): boolean {
        return !this.playlistAssignment.isNewRecord();
    }

    get canRevertChanges(): boolean {
        return this.hasChanges;
    }

    get draftNeedsPublish(): boolean {
        if (this.hasUnassignedSlots || this.hasChanges) {
            return false
        }

        return this.playlistAssignment.hasFlag(RetailerPromoPeriodPlaylistAssignmentFlag.DraftNeedsPublish)
    }

    get draftCanActivate(): boolean {
        if (this.hasUnassignedSlots || this.hasChanges) {
            return false
        }
        if (!this.draftMediaPlaylistVersion) {
            return false
        }
        return this.playlistAssignment.hasFlag(RetailerPromoPeriodPlaylistAssignmentFlag.DraftCanActivate)
    }

    get draftNeedsRepublish(): boolean {
        if (this.hasUnassignedSlots || this.hasChanges) {
            return false
        }
        if (!this.draftMediaPlaylistVersion) {
            return false
        }
        return this.playlistAssignment.hasFlag(RetailerPromoPeriodPlaylistAssignmentFlag.DraftNeedsRepublish)
    }

    get mediaPlaylist(): MediaPlaylist {
        if (this.playlistAssignment) {
            return this.playlistAssignment.mediaPlaylist
        }
        return null
    }

    get canBeReset(): boolean {
        if (!this.playlistAssignment) {
            return false
        }
        return !this.playlistAssignment.isNewRecord()
    }

    get draftMediaPlaylistVersion(): MediaPlaylistVersion {
        if (this.playlistAssignment && this.playlistAssignment.mediaPlaylist) {
            return this.playlistAssignment.mediaPlaylist.draftVersion
        }
        return null
    }

    get activeMediaPlaylistVersion(): MediaPlaylistVersion {
        if (this.playlistAssignment && this.playlistAssignment.mediaPlaylist) {
            return this.playlistAssignment.mediaPlaylist.activeVersion
        }
        return null
    }

    get isEmpty(): boolean {
        for (const loop of this.loops) {
            if (loop.entries.length > 0) {
                return false
            }
        }
        return true
    }

    onLoopEntryUpdated(entry: PlaybackLoopEntry) {
        this._hasUnassignedSlots = null;
        this.invalidateSignature()
    }

    canRemoveLoop(loop: PlaybackLoop): boolean {
        if (this.loops.indexOf(loop) >= 0) {
            return this.loops.length > 1;
        }
        return false
    }

    removeLoop(loop: PlaybackLoop): boolean {
        if (this.canRemoveLoop(loop)) {
            this.loops = this.loops.filter(value => value != loop);
            this.invalidateSignature()
            return true
        }
        return false
    }

    cloneLoop(loop: PlaybackLoop) {
        const idx = this.loops.indexOf(loop);
        if (idx >= 0) {
            this.loops.splice(idx, 0, loop.clone());
            this.invalidateSignature()
        }
    }

    addLoop(loop: PlaybackLoop) {
        if (loop.entries.length > 0) {
            if (this.loops.indexOf(loop) == -1) {
                this.loops.push(loop)
                this.invalidateSignature()
            }
        }
    }

    changeItemPosition(loop: PlaybackLoop, fromIndex: number, toIndex: number) {
        if (this.loops.indexOf(loop) >= 0) {
            moveItemInArray(loop.entries, fromIndex, toIndex);
            this.invalidateSignature()
        }
    }

    private invalidateSignature() {
        if (this._entriesSignature) {
            this._entriesSignature.invalidate()
        }
    }


}

class PlaybackLoop {
    entries: PlaybackLoopEntry[] = [];

    constructor(public campaignPlaylist: CampaignPlaylist) {
    }

    clone(): PlaybackLoop {
        const dup = new PlaybackLoop(this.campaignPlaylist);
        dup.entries = this.entries.map(value => {
            const clone = new PlaybackLoopEntry(this.campaignPlaylist, value.mediaBundle, value.brandCampaign, value.brandSlotId);
            clone.mediaBundle = value.mediaBundle;
            return clone
        });

        return dup;
    }

}

class PlaybackLoopEntry {

    static idCounter = 1;

    id: string;

    constructor(
        private campaignPlaylist: CampaignPlaylist,
        public mediaBundle: PromoPlaylistMediaBundle,
        public brandCampaign: BrandPromoCampaign,
        public brandSlotId: number) {
        this.id = (PlaybackLoopEntry.idCounter++) + ''
    }

    setMediaBundle(mediaBundle: PromoPlaylistMediaBundle) {
        if (!Utils.itemsEqual(mediaBundle, this.mediaBundle)) {
            this.mediaBundle = mediaBundle
            this.campaignPlaylist.onLoopEntryUpdated(this)
        }
    }
}


class PlaylistRegistry {

    constructor(
        private svcPlaylists: PlaylistsControllerService,
        public readonly segment: DeviceSlotSegment,
        private playlistNotifChan: Subject<CampaignPlaylist>
    ) {

    }

    readonly slots: PlaylistRegistrySlot[] = [];
    campaignPlaylists: CampaignPlaylist[] = []

    private playlistExpandedMap = new Map<string, boolean>();

    static appendPlaylistsForBrandCampaigns(
        data: RetailerPromoPeriodProgramEntry,
        dest: Map<string, PlaylistRegistry>
    ) {

        for (const brandCampaign of data.brandCampaigns) {
            for (const slot of brandCampaign.slotAssignments) {

                const key = slot.deviceSlotSegment.getStringId()
                const reg = dest.get(key)
                if (!reg) {
                    continue
                }
                reg.slots.push({
                    brandCampaign: brandCampaign,
                    slotAssignmentId: slot.id,
                    slotAssignmentIndex: slot.slotIndex
                })
            }
        }

        for (const x of data.playlistAssignments) {
            const segmentId = x.deviceSlotSegment.getStringId()
            dest.get(segmentId).registerPlaylist(x, segmentId)
        }


        for (const reg of dest.values()) {
            if (reg.slots?.length && !reg.campaignPlaylists.length) {
                reg.addEmptyPlaylist()
            }
        }

    }

    isPlaylistExpanded(pl: CampaignPlaylist) {
        if (this.playlistExpandedMap.has(pl.key)) {
            return this.playlistExpandedMap.get(pl.key)
        }
        if (pl.playlistAssignment.isDefault) {
            return true
        }
        return false
    }

    setPlaylistExpanded(pl: CampaignPlaylist, expanded: boolean) {
        if (!pl) {
            return
        }
        this.playlistExpandedMap.set(pl.key, expanded)
    }

    private replacePlaylistExpanded(oldKey: string, newKey: string) {
        if (this.playlistExpandedMap.has(oldKey) && Utils.isString(newKey) && oldKey != newKey) {
            const v = this.playlistExpandedMap.get(oldKey)
            this.playlistExpandedMap.delete(oldKey)
            this.playlistExpandedMap.set(newKey, v)
        }
    }

    removePlaylist(playlistKey: string) {
        const playlists = [].concat(this.campaignPlaylists)
        const idx = playlists.findIndex(value => value.key == playlistKey)
        if (idx < 0) {
            return
        }
        playlists[idx].setNotifChannel(null)
        playlists.splice(idx, 1)
        this.campaignPlaylists = playlists
    }

    registerPlaylist(pl: PromoPlaylistAssignment, replaceKey?: string, prepend = false): CampaignPlaylist {
        const playlist = new CampaignPlaylist(this.svcPlaylists, pl)
        if (!replaceKey) {
            replaceKey = playlist.key
        }
        const playlists = [].concat(this.campaignPlaylists)
        const prevIndex = playlists.findIndex(value => value.key == replaceKey)

        if (prevIndex >= 0) {
            playlists[prevIndex].setNotifChannel(null)
            playlists.splice(prevIndex, 1, playlist)
        } else {
            if (prepend) {
                playlists.unshift(playlist)
            } else {
                playlists.push(playlist)
            }
        }
        this.replacePlaylistExpanded(replaceKey, playlist.key)
        playlist.setNotifChannel(this.playlistNotifChan)
        this.campaignPlaylists = playlists
        return playlist
    }

    addEmptyPlaylist(name = 'New Playlist'): boolean {
        if (!this.slots.length) {
            return false
        }
        const assignment = new PromoPlaylistAssignment()
        assignment.deviceSlotSegment = this.segment
        assignment.name = name
        let trackIndex = 0;
        for (const slot of this.slots) {
            if (!assignment.playbackEntries) {
                assignment.playbackEntries = []
            }
            assignment.playbackEntries.push({
                brandCampaignId: slot.brandCampaign.getId(),
                brandCampaignSlotId: parseInt(slot.slotAssignmentId),
                loopIndex: 0,
                mediaFileVariantId: 0,
                mediaBundleId: 0,
                trackIndex: trackIndex
            });
            trackIndex += 1
        }

        const pl = this.registerPlaylist(assignment, null, true)
        this.setPlaylistExpanded(pl, true)
    }
}

interface PlaylistRegistrySlot {
    brandCampaign: BrandPromoCampaign
    slotAssignmentIndex: number
    slotAssignmentId: string
}

interface PlaylistMutationData {
    data: PromoPlaylistMutationInput
    op: PlaylistMutationData
    playlistKey: string
}


interface SignatureItem {
    source: Signature
    index: number
    changed: boolean
}

class Signature {
    private _initial: string
    private _current: string
    private _refreshTimeout: Timeout
    private _changedSubject = new Subject<boolean>()

    // emits true whenever ANY signature.onChanged() emits true 
    // emits false when ALL signature.onChanged() emits false 
    static combine(...sigs: Signature[]): Observable<boolean> {

        if (!Array.isArray(sigs) || !sigs.length) {
            return EMPTY
        }

        return new Observable<boolean>(subscriber => {
            const changes = new Array(sigs.length).fill(false)
            let wasChanged = false

            const sources: Observable<SignatureItem>[] = sigs.map((sig, index) => {
                const source = sig
                const idx = index
                return sig.onChanged().pipe(
                    map(changed => {
                        const v: SignatureItem = {
                            changed: changed,
                            source: source,
                            index: idx
                        }
                        return v
                    })
                )
            })

            const subscription = merge(...sources).subscribe(value => {
                changes[value.index] = value.changed
                if (wasChanged != value.changed) {
                    const isChanged = changes.indexOf(true) >= 0
                    if (isChanged != wasChanged) {
                        wasChanged = isChanged
                        subscriber.next(wasChanged)
                    }
                }
            })

            return () => {
                subscription.unsubscribe()
            }
        })
    }

    constructor(private calc: () => string) {
        this.reset()
    }

    invalidate() {
        const wasChanged = this.changed
        this._current = null
        clearTimeout(this._refreshTimeout)
        this._refreshTimeout = setTimeout(() => {
            const isChanged = this.changed
            if (wasChanged != isChanged) {
                this._changedSubject.next(isChanged)
            }
        }, 0)
    }

    get changed(): boolean {
        if (!this._current) {
            this._current = this.calc().toString()
        }
        return this._current != this._initial
    }

    reset() {
        let wasChanged = false
        if (this._initial) {
            wasChanged = this.changed
        }
        this._current = this._initial = this.calc().toString()
        if (wasChanged) {
            this._changedSubject.next(false)
        }
    }

    onChanged(): Observable<boolean> {
        return this._changedSubject
    }

}


class PromoPlaylistMediaBundleDataSource extends ModelListDataSource<PromoPlaylistMediaBundle> {
    public ddController: DDController;

    constructor() {
        super({
            columns: [{
                key: 'name',
                label: 'Name',
                valueReader: (item: PromoPlaylistMediaBundle): string => {
                    return item?.name
                }
            }, {
                key: "content_types",
                width: '120px',
                label: ""
            }]
        })

        this.ddController = new DDController();
    }

}


class DDController extends GridDragDropController<PromoPlaylistMediaBundle> {
}
