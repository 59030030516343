<div fxLayout="row">
    <span class="mat-dialog-title" fxFlex>Edit Image</span>
    <!--<mat-slide-toggle [(ngModel)]="deviceSlotImage.approved">Approved</mat-slide-toggle>-->
</div>

<mat-dialog-content>

    <mat-slide-toggle *ngIf="hasZeroScoreSubmission" style="margin: 16px 0;" [(ngModel)]="approveZeroScores">Approve Zero Scores</mat-slide-toggle>
    
    <app-autocomplete-search-field
        placeholder="Device Slot"
        [searchObjectType]="'DeviceSlot'"
        selectionMode="single"
        [value]="deviceSlotImage?.deviceSlot"
        (valueChanged)="onDeviceSlotChanged($event)"></app-autocomplete-search-field>

    <mat-form-field class="full-width">
        <input matInput [matDatepicker]="datePicker" placeholder="Date" [value]="deviceSlotImage.date"
               (dateChange)="onDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>

    <!--<div *ngIf="!deviceSlotAssignmentChanged && scores?.length">
        <div class="h2 mt-16 font-weight-500">Brand Scores:</div>
        <div *ngFor="let score of scores">
            <mat-form-field class="full-width">
                <input readonly type="number" matInput [placeholder]="score.brandPartner.getDisplayName()" autocomplete="off"
                       [value]="(score.score || '').toString()  "/>
            </mat-form-field>
        </div>
    </div>-->


</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isBusy">Save</button>
    <button mat-button (click)="close()">cancel</button>
</mat-dialog-actions>