import {Injectable} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {Platform} from '@angular/cdk/platform';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {

    private _configSubject: BehaviorSubject<any>;
    private readonly _defaultConfig: any;

    constructor(
        private _platform: Platform,
        private _router: Router,
    ) {
        this._defaultConfig = {
            customScrollbars: true,
            layout: {
                navbar: {
                    primaryBackground: 'fuse-navy-700',
                    secondaryBackground: 'fuse-navy-900',
                    hidden: false,
                },
                toolbar: {
                    background: 'fuse-white-500',
                    hidden: false,
                },
                footer: {
                    background: 'fuse-navy-900',
                    hidden: false,
                }
            }
        };

        // Initialize the service
        this._init();
    }


    set config(value) {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();

        // Merge the new config
        config = Object.assign({}, config, value);
        // Notify the observers
        this._configSubject.next(config);
    }

    public onConfigurationChanged(): Observable<any> {
        return this._configSubject.asObservable();
    }

    private _init(): void {


        // Set the config from the default config

        this._configSubject = new BehaviorSubject( cloneDeep(this._defaultConfig) );

        // Reload the default layout config on every RoutesRecognized event
        // if the current layout config is different from the default one
        this._router.events
            .pipe(filter(event => event instanceof RoutesRecognized))
            .subscribe(() => {
                if (!isEqual(this._configSubject.getValue().layout, this._defaultConfig.layout)) {
                    // Clone the current config
                    const config = cloneDeep(this._configSubject.getValue());

                    // Reset the layout from the default config
                    config.layout =  cloneDeep(this._defaultConfig.layout) as any;

                    // Set the config
                    this._configSubject.next(config);
                }
            });
    }


}

function  cloneDeep<T>(src: T): T {
    return JSON.parse(JSON.stringify(src))
}

function isEqual(a: any, b: any): boolean{
    return JSON.stringify(a) === JSON.stringify(b)
}
