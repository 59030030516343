export interface CursorFilter {
    cursor?: string
    sort?: ColumnSortInfo[]
}

export interface ColumnSortInfo {
    column: string
    direction: SortDirection
}

export enum SortDirection {
    None = 'None',
    Asc = 'Asc',
    Desc = 'Desc',
    
}
