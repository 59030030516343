<div app-looma-page pageTitle="Device Slots" pageIcon="important_devices">
    <looma-grid [dataSource]="dataSource">
        <ng-template rowExpansionTemplate let-data="data">
            <div style="padding-left: 30px; display: flex; align-items: baseline">
                <mat-card style="padding: 10px; margin:10px">
                    <mat-card-title>Properties</mat-card-title>
                    <ul>
                        <li style="padding-top: 5px; padding-bottom: 5px">Store
                            Location: {{data.store_location ? data.store_location : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">Loop
                            Placement: {{data.loop_placement ? data.loop_placement : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">
                            Temperature: {{data.temperature ? data.temperature : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">Mount: {{data.mount ? data.mount : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">Loop X
                            Score: {{data.loop_x_score ? data.loop_x_score : "-"}}</li>
                    </ul>
                </mat-card>

                <mat-card style="padding: 10px; margin:10px">
                    <mat-card-title>Decor/HX</mat-card-title>
                    <ul>
                        <li style="padding-top: 5px; padding-bottom: 5px">
                            Header: {{data.decor?.header ? data.decor?.header : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">
                            Blades: {{data.decor?.blades ? data.decor?.blades : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">Shelf
                            Ribbons: {{data.decor?.shelfRibbons ? data.decor?.shelfRibbons : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">Accent
                            Card: {{data.decor?.accentCard ? data.decor?.accentCard : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">Cooler
                            Decal: {{data.decor?.coolerDecal ? data.decor?.coolerDecal : "-"}}</li>
                        <li style="padding-top: 5px; padding-bottom: 5px">Side
                            Lettering: {{data.decor?.sideLettering ? data.decor?.sideLettering : "-"}}</li>
                    </ul>
                </mat-card>
            </div>
        </ng-template>

        <ng-template gridColumn="device_slot_type" let-slot="data">
            <span [class.unassigned_device_slot]="!isSlotAssigned(slot)">{{getSlotAssignmentName(slot)}}</span>
        </ng-template>
        <ng-template gridColumn="has_warnings" let-slot="data">
            <button mat-icon-button color="warn" *ngIf="slot.hasInstallationWarnings" (click)="showSlotWarnings(slot)">
                <mat-icon>warning</mat-icon>
            </button>
        </ng-template>
        <ng-template gridColumn="last_received_image" let-slot="data">
            <div *ngIf="slot.lastReceivedImage">
                <a [routerLink]="['/device-slot-images', slot.lastReceivedImage.id]"
                   routerLinkActive="active">{{slot.lastReceivedImage.date |  date:'mediumDate'}}</a>
            </div>
        </ng-template>
        <ng-template gridColumn="last_scored_image" let-slot="data">
            <div *ngIf="slot.lastScoreSubmission?.deviceSlotImage">
                <a [routerLink]="['/device-slot-images', slot.lastScoreSubmission.deviceSlotImage.id]"
                   routerLinkActive="active">{{slot.lastScoreSubmission?.deviceSlotImage.date |  date:'mediumDate'}}</a>
            </div>
        </ng-template>
    </looma-grid>

    <ng-template pageHeaderNav>
        <mat-form-field style="width: 150px;margin-right:16px;">
            <mat-label>Installation Warnings</mat-label>
            <mat-select value="Any" (valueChange)="onFilterSelectionChanged('tablet_warnings',$event)">
                <mat-option *ngFor="let opt of ['Any','Yes','No']" value="{{opt}}">{{opt}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 150px;margin-right:16px;">
            <mat-label>Loop Placement</mat-label>
            <mat-select value="Any" (valueChange)="onFilterSelectionChanged('looma_placement',$event)">
                <mat-option *ngFor="let opt of ['Any', 'End-cap', 'In-line', 'Wing', 'OOD']"
                            value="{{opt}}">{{opt}}</mat-option>
            </mat-select>
        </mat-form-field>
        
        <app-autocomplete-search-field showClear="true" placeholder="Retailer" searchObjectType="Retailer"
                                       (valueChanged)="onRetailerFilterChanged($event)" selectionMode="single"
                                       style="width: 300px; padding-right: 20px"></app-autocomplete-search-field>

        <mat-form-field>
            <mat-label>Store Num</mat-label>
            <input [formControl]="storeNumberInput" matInput fxFlex/>
        </mat-form-field>

        <button mat-raised-button (click)="addNewRetailer()">New Device Slot</button>
    </ng-template>
</div>
