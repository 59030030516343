import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {

    private _sessionVars: Map<any, any> = new Map();

    constructor() {
    }

    clearSessionValues() {
        this._sessionVars = new Map<any, any>();
    }

    setSessionValue(key: any, value: any): void {
        this._sessionVars.set(key, value);
    }

    getSessionValue<T>(key: any, valueIfMissing?: T): T | null {
        if (this._sessionVars.has(key)) {
            return this._sessionVars.get(key);
        }
        return valueIfMissing || null
    }

    setStoredValue(key: any, value: any): void {
        if (!value) {
            localStorage.removeItem(key)
        } else {
            localStorage.setItem(key, JSON.stringify(value))
        }

    }

    getStoredValue<T>(key: string, valueIfMissing?: T): T {
        if (localStorage.hasOwnProperty(key)) {
            const rawValue = localStorage.getItem(key);
            try {
                return JSON.parse(rawValue) as T;
            } catch (e) {
                console.error('error while deserializing value', rawValue)
            }
        }
        return valueIfMissing;
    }
}
