import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Platform} from '@angular/cdk/platform';
import {filter, takeUntil} from 'rxjs/operators';
import PerfectScrollbar from 'perfect-scrollbar';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {AppConfigService} from '../../services/config.service';
import {Utils} from '@looma/shared/utils';
import Timeout = NodeJS.Timeout;

@LifecycleHooks()
@Directive({
    selector: '[appPerfectScrollbar]'
})
export class PerfectScrollbarDirective implements AfterViewInit, OnDestroy {
    ps: PerfectScrollbar;

    // Private
    private _enabled = false;
    private _options: any;
    private updateTimeout: Timeout;


    constructor(
        public elementRef: ElementRef,
        private _fuseConfigService: AppConfigService,
        private _platform: Platform,
        private _router: Router
    ) {
        this._options = {
            updateOnRouteChange: false
        };

        this.enabled = true;


    }

    @Input()
    set appPerfectScrollbarOptions(value) {
        // Merge the options
        this._options = {...this._options, ...value};
    }

    get appPerfectScrollbarOptions(): any {
        // Return the options
        return this._options;
    }

    set enabled(value: boolean) {
        value = !!value;

        // Return, if both values are the same
        if (this._enabled === value) {
            return;
        }

        this._enabled = value;

        if (this._enabled) {
            this.init();
        } else {
            this.ngOnDestroy();
        }
    }

    get enabled(): boolean {
        return !!this._enabled;
    }


    ngAfterViewInit(): void {
        // Scroll to the top on every route change
        if (this.appPerfectScrollbarOptions.updateOnRouteChange) {
            this._router.events
                .pipe(
                    takeUntil(Utils.onDestroy(this)),
                    filter(event => event instanceof NavigationEnd)
                )
                .subscribe(() => {
                    setTimeout(() => {
                        this.scrollToTop();
                        this.update();
                    }, 0);
                });
        }
    }


    ngOnDestroy(): void {
        if (!this.ps) {
            return;
        }

        this.ps.destroy();

        this.ps = null;

    }


    private init(): void {
        // Return, if already initialized
        if (this.ps) {
            return;
        }

        if (this._platform.ANDROID || this._platform.IOS) {
            return
        }

        // Initialize the perfect-scrollbar
        this.ps = new PerfectScrollbar(this.elementRef.nativeElement);
    }


    @HostListener('window:resize')
    _updateOnResize(): void {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            this.update()
        }, 150);
    }


    @HostListener('document:click', ['$event'])
    documentClick(event: Event): void {
        this.update()
    }


    update(): void {
        if (!this.ps) {
            return;
        }

        // Update the perfect-scrollbar
        this.ps.update();
    }


    destroy(): void {
        this.ngOnDestroy();
    }


    scrollToX(x: number, speed?: number): void {
        this.animateScrolling('scrollLeft', x, speed);
    }

    scrollToY(y: number, speed?: number): void {
        this.animateScrolling('scrollTop', y, speed);
    }


    scrollToTop(offset?: number, speed?: number): void {
        this.animateScrolling('scrollTop', (offset || 0), speed);
    }


    scrollToLeft(offset?: number, speed?: number): void {
        this.animateScrolling('scrollLeft', (offset || 0), speed);
    }


    scrollToRight(offset?: number, speed?: number): void {
        const width = this.elementRef.nativeElement.scrollWidth;

        this.animateScrolling('scrollLeft', width - (offset || 0), speed);
    }

    scrollToBottom(offset?: number, speed?: number): void {
        const height = this.elementRef.nativeElement.scrollHeight;

        this.animateScrolling('scrollTop', height - (offset || 0), speed);
    }

    animateScrolling(target: string, value: number, speed?: number): void {
        if (!speed) {
            this.elementRef.nativeElement[target] = value;

            // PS has weird event sending order, this is a workaround for that
            this.update();
        } else if (value !== this.elementRef.nativeElement[target]) {
            let newValue = 0;
            let scrollCount = 0;

            let oldTimestamp = performance.now();
            let oldValue = this.elementRef.nativeElement[target];

            const cosParameter = (oldValue - value) / 2;

            const step = (newTimestamp) => {
                scrollCount += Math.PI / (speed / (newTimestamp - oldTimestamp));

                newValue = Math.round(value + cosParameter + cosParameter * Math.cos(scrollCount));

                // Only continue animation if scroll position has not changed
                if (this.elementRef.nativeElement[target] === oldValue) {
                    if (scrollCount >= Math.PI) {
                        this.elementRef.nativeElement[target] = value;

                        // PS has weird event sending order, this is a workaround for that
                        this.update();

                        this.update();
                    } else {
                        this.elementRef.nativeElement[target] = oldValue = newValue;

                        oldTimestamp = newTimestamp;

                        window.requestAnimationFrame(step);
                    }
                }
            };

            window.requestAnimationFrame(step);
        }
    }
}
