import {BaseModel} from '@looma/shared/models/base_model';
import {Assignable} from "@looma/shared/types/assignable";
import {Utils} from "@looma/shared/utils";

export class UserRole extends BaseModel {

    static readonly Admin = UserRole.newRole("-1", "Administrator")
    static readonly DeviceTechnician = UserRole.newRole("4", "DeviceTechnician")

    static newRole(id: string, name: string): UserRole {
        const u = new UserRole();
        u.id = id;
        u.name = name;
        return u;
    }


    id: string;
    name: string;
    description: string;
    permissions: UserAccessPermission[];

    groupedPermissions() {
        return Utils.groupBy(this.permissions, f => f.groupName)
    }

    permissionGroups(): string[] {
        const groupedPermissions = this.groupedPermissions()
        if (groupedPermissions) {
            return Array.from(groupedPermissions.keys())
        } else {
            return []
        }
    }
}

export class UserAccessPermission extends Assignable {

    static AppAccess = class {
        static readonly Uptime = new UserAccessPermission("UPTIME_ACCESS");
        static readonly LoopDashboard = new UserAccessPermission("LOOP_ACCESS");
        static readonly PhotoScoring = new UserAccessPermission("PHOTO_SCORING_ACCESS");
        static readonly MerchantDashboard = new UserAccessPermission("MERCHANT_ACCESS");
        static readonly HtSubmissionPortal = new UserAccessPermission("HT_SUBMISSION_PORTAL_ACCESS");
    }

    static LoopDashboard = class {
        static readonly CanAssociateWithBrand = new UserAccessPermission("LOOP_CAN_ASSOCIATE_BRAND");
        static readonly CanViewPastCampaigns = new UserAccessPermission("LOOP_SHOW_PAST_CAMPAIGNS");
        static readonly CanViewFutureCampaigns = new UserAccessPermission("LOOP_SHOW_FUTURE_CAMPAIGNS");
    }

    static Uptime = class {
        static readonly Devices = new UserAccessPermission("UPTIME_DEVICES_ACCESS");
        static readonly StoreImages = new UserAccessPermission("UPTIME_STORE_IMAGES_ACCESS");
        static readonly Campaigns = new UserAccessPermission("UPTIME_CAMPAIGN_ACCESS");
        static readonly UserManagement = new UserAccessPermission("UPTIME_USER_MANAGEMENT_ACCESS");
        static readonly Films = new UserAccessPermission("UPTIME_FILMS_ACCESS");
        static readonly BrandPartners = new UserAccessPermission("UPTIME_BRAND_PARTNER_ACCESS");
        static readonly Products = new UserAccessPermission("UPTIME_PRODUCT_ACCESS");
        static readonly Retailers = new UserAccessPermission("UPTIME_RETAILERS_ACCESS");
        static readonly Stores = new UserAccessPermission("UPTIME_STORES_ACCESS");
        static readonly DeviceSlots = new UserAccessPermission("UPTIME_DEVICE_SLOTS_ACCESS");
    }

    constructor(key: string) {
        super();
        this.key = key;
    }

    key: string;
    name: string;
    groupName: string;
}

export interface UserRoleInput {
    id?: string;
    name?: string;
    description?: string;
    assignedPermissions?: string[];
}

