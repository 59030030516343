import 'zone.js'

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {secrets} from '@looma/config/secrets';


document.write(`
<script src="//apis.google.com/js/api.js" async defer></script>
`);

const mapsScript = document.createElement('script');
mapsScript.src = `//maps.googleapis.com/maps/api/js?key=${secrets.google.apiKey}&libraries=places`;
document.head.appendChild(mapsScript);

const gapiScript = document.createElement('script');
gapiScript.src = `https://apis.google.com/js/platform.js`;
document.head.appendChild(gapiScript);

if ( environment.production ){
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));


