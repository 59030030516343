<div app-looma-page pageTitle="Product Enrollment Status" pageIcon="checklist">

    <div style="overflow: auto">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

            <!-- Column Definitions -->
            <ng-container matColumnDef="segmentName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="segmentName"> Segment Name</th>
                <td mat-cell *matCellDef="let element"> {{ element.segmentName }}</td>
            </ng-container>

            <ng-container matColumnDef="productName">
                <th mat-header-cell *matHeaderCellDef> Product Name</th>
                <td mat-cell *matCellDef="let element"> {{ element.productName }}</td>
            </ng-container>

            <ng-container matColumnDef="upcCode">
                <th mat-header-cell *matHeaderCellDef> UPC Code</th>
                <td mat-cell *matCellDef="let element"> {{ element.upcCode }}</td>
            </ng-container>

            <ng-container matColumnDef="brandPartnerName">
                <th mat-header-cell *matHeaderCellDef> Brand Partner Name</th>
                <td mat-cell *matCellDef="let element"> {{ element.brandPartnerName }}</td>
            </ng-container>

            <ng-container matColumnDef="isEnrolled">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="isEnrolled"> Is Enrolled</th>
                <td mat-cell *matCellDef="let element">
                    <span class="badge" [ngClass]="{'yes': element.isEnrolled, 'no': !element.isEnrolled}">
                      {{ element.isEnrolled ? 'Yes' : 'No' }}
                    </span>
                </td>
            </ng-container>

            <!-- Header and Row Declarations -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>


    <ng-template pageHeaderNav>
        <button mat-raised-button color="primary" (click)="export()">
            <mat-icon>cloud_download</mat-icon>
            Download CSV
        </button>
    </ng-template>
</div>
