import {BaseModel} from '@looma/shared/models/base_model';

export class DevicePowerCycleLog extends BaseModel {

    on_at: Date;
    off_at: Date;
    charged_at: Date;
    discharging_at: Date;
    onDurationSecs: number | undefined;

    assign(obj: any): this {
        this.assignDate(obj, 'on_at');
        this.assignDate(obj, 'off_at');
        this.assignDate(obj, 'charged_at');
        this.assignDate(obj, 'discharging_at');

        const onTs = this.on_at && this.on_at.getTime() || 0;
        const offTs = this.off_at && this.off_at.getTime() || 0;

        if (onTs && offTs) {
            this.onDurationSecs = (offTs - onTs) / 1000;
        }
        return this
    }

}
