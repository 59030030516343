import {BaseModel} from '@looma/shared/models/base_model';
import {UserAssociation} from '@looma/shared/models/user_association'
import {UserAccessPermission, UserRole} from "@looma/shared/models/user_role";

export class User extends BaseModel {
    id: string;
    uid: string;
    email: string;
    display_name: string;
    photo_url: string;
    associations: UserAssociation;
    email_notification_sent_at: string;
    last_logged_in_at: Date;
    account_activated_at: Date;
    roles: UserRole[];
    invitationEmail: string;
    rights: Set<string>
    metadata: UserMetadata
    requestedAccessFrom: string
    status: 'SignUpRequested' | 'PendingPasswordSetup' | 'Active' | 'Disabled'

    assign(input: any): this {
        super.assign(input);
        this.display_name = this.display_name || '';
        this.assignTypedObject(input, 'associations', UserAssociation);
        this.assignDate(input, 'email_notification_sent_at');
        this.assignDate(input, 'last_logged_in_at');
        this.assignDate(input, 'account_activated_at');
        this.assignTypedArray(input, 'roles', UserRole);
        this.rights = readRights(input)

        return this;
    }

    isNewRecord(): boolean {
        return !this.id
    }

    isAdmin(): boolean {
        return !this.isNewRecord() &&
            this.roles.length == 1 && this.roles[0].id == UserRole.Admin.id
    }

    allAccessPermissions(): UserAccessPermission[] {
        let allPermissions: UserAccessPermission[] = []
        this.roles.forEach(role => {
            allPermissions = allPermissions.concat(role.permissions)
        })
        return allPermissions;
    }

    hasAccessPermission(permissionStr: UserAccessPermission): boolean {
        return this.isAdmin() || this.allAccessPermissions().map(t => {
            return t.key
        }).indexOf(permissionStr.key) >= 0;
    }

    hasRight(right: string): boolean {

        return this.rights?.has(right) || false
    }

    isSignUpRequested(): boolean {
        return this.status == "SignUpRequested"
    }

    isPendingPasswordSetup(): boolean {
        return this.status == "PendingPasswordSetup"
    }

    isActive(): boolean {
        return this.status == "Active"
    }
}


export interface UserMutationData {
    email?: string;
    name?: string;
    brandPartnerIds?: string[];
    childBrandIds?: string[];
    retailerIds?: string[];
    storeGroupIds?: string[];
    programIds?: string[];
    timeZone?: string;
    messagingToken?: string;
    roleIds?: string[];
    isAdministrator?: boolean;
    invitationEmail?: string;
    metadata?: Partial<UserMetadata>
}

export interface UserMetadata {
    freshdeskUserId: number
}

export interface UserSignUpDetails {
    name: string
    email: string
}


function readRights(input: any): Set<string> {
    const parsedRights = new Set<string>()
    if (input?.rights) {
        for (const ns of Object.keys(input.rights)) {
            const nsRights = input.rights[ns]
            if (Array.isArray(nsRights)) {
                for (const right of nsRights) {
                    parsedRights.add(right)
                }
            }
        }
    }
    return parsedRights
}
