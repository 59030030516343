<div #rootEl style="height: 100%; display: flex" >
    <div #scrollMeasureEl class="scrollbar-measure"></div>
    <cdk-virtual-scroll-viewport #virtualScrollEl [itemSize]="this.cellBoundingRect?.height " style="flex: 1; min-height: 100px;" >
        <div class="row proxy" >
            <div  class="cell" >
                <div class="aspectratio" >
                    <div #proxyEl class="content"></div>
                </div>
            </div>
        </div>
        <div  *cdkVirtualFor="let row of visibleRowsCount ? this.datasourceRows : []; let rowIndex = index" class="line" fxLayout="row">
            <div *ngFor="let item of row; let cellIndex=index" class="cell_size">
                <ng-content *ngTemplateOutlet="itemTemplate; context:{data: item, index: rowIndex*row.length+cellIndex}"></ng-content>
            </div>

        </div>
    </cdk-virtual-scroll-viewport>
</div>