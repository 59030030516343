<div style="width: 100%; height: 100%; position: relative; padding-bottom: 16px" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="end" fxFlex="0 1 auto" class="image_controls">
        <button mat-icon-button (click)="rotate(false)" matTooltip="Rotate right" [disabled]="!canRotateImage" tabindex="-1">
            <mat-icon>rotate_left</mat-icon>
        </button>
        <button mat-icon-button (click)="rotate(true)"  matTooltip="Rotate left" [disabled]="!canRotateImage"  tabindex="-1">
            <mat-icon>rotate_right</mat-icon>
        </button>
        <button mat-icon-button (click)="setZoom(1)"  matTooltip="Zoom in"  tabindex="-1">
            <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button (click)="setZoom(-1)"  matTooltip="Zoom out"  tabindex="-1">
            <mat-icon>zoom_out</mat-icon>
        </button>
        <button mat-icon-button (click)="reset()"  matTooltip="Reset"  tabindex="-1">
            <mat-icon>search_off</mat-icon>
        </button>
    </div>

    <svg #svgPreview fxFlex="1 1 auto">
        <image width="100%" height="100%" [attr.xlink:href]="onImageAvailable() | async"/>
    </svg>
    
    <div fxFlex="0 1 auto" *ngIf="label" class="image_label">
        {{label}}
    </div>


</div>
