<div app-looma-page pageTitle="Film variables" pageIcon="videocam"
     [headerSideTpl]="headerSideTpl">
    <looma-grid [dataSource]="dataSource">
    </looma-grid>
</div>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button color="warn" (click)="uploadFilmVariables()">Upload film variables</button>
    </div>
</ng-template>