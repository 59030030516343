import {BaseModel} from '@looma/shared/models/base_model';
import {DeviceSlot} from '@looma/shared/models/device_slot'
import {DeviceSlotImageScore} from '@looma/shared/models/device_slot_image_score'

export class DeviceSlotImagePhoto extends BaseModel {
    id: string;
    deviceSlot: DeviceSlot;
    date: Date;
    approved: boolean;
    source: string;

    thumb: DeviceSlotImagePhotoVersion;
    large: DeviceSlotImagePhotoVersion;
    original: DeviceSlotImagePhotoVersion;

    scores: DeviceSlotImageScore[];

    isHidden: boolean;
    isPrimary: boolean;

    private warnings: string;


    assign(input: any): this {
        super.assign(input);
        this.assignDate(input, 'date');
        this.assignTypedObject(input, "thumb", DeviceSlotImagePhotoVersion)
        this.assignTypedObject(input, "large", DeviceSlotImagePhotoVersion)
        this.assignTypedObject(input, "original", DeviceSlotImagePhotoVersion)
        this.approved = !!input.approved;
        return this;
    }

}


export class DeviceSlotImagePhotoVersion extends BaseModel {
    id: string
    width: number
    height: number
    isActive: boolean
    isPrimary: boolean
    url: string
}


export interface DeviceSlotImagePhotoMutationInput {
    deviceSlotImagePhotoId: string
    rotation?: number
    isPrimary?: boolean
    isHidden?: boolean
}
