import {Component} from '@angular/core';
import {
    DeleteItemComponent,
    EditItemComponent,
    ModelListPageComponent
} from "../../../shared/model_list_page_component";
import {CursorFilter} from "@looma/shared/types/cursor_filter";
import {MediaPlaylist, MediaPlaylistFilter} from "@looma/shared/models/media_playlist";
import {LayoutService} from "../../../services/layout.service";
import {ApiDataService} from "../../../services/api-data.service";
import {ModelDataSource} from "../../../layout/components/looma-grid/grid-data-source";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Observable} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import gql from "graphql-tag";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";
import {MediaPlaylistVersionDialogComponent} from "../../retailer-promo-campaigns/components/media-playlist-version-dialog/media-playlist-version-dialog.component";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {MediaPlaylistEditDialogComponent} from "../media-playlist-edit-dialog/media-playlist-edit-dialog.component";

const ActionViewActivePlaylistVersion = 'ActionViewActivePlaylistVersion'
const ActionViewEntries = 'ActionViewEntries'

@LifecycleHooks()
@Component({
    selector: 'app-media-playlist-list',
    templateUrl: './media-playlist-list.component.html',
    styleUrls: ['./media-playlist-list.component.scss']
})
export class MediaPlaylistListComponent extends ModelListPageComponent<MediaPlaylist, CursorFilter> implements EditItemComponent<MediaPlaylist>, DeleteItemComponent<MediaPlaylist> {

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private router: Router
    ) {
        super(MediaPlaylist);


        this.addRowAction({
            icon: 'playlist_play',
            showAlways: false,
            primary: true,
            label: 'View Playlist Entries',
            key: ActionViewEntries,
        });

        this.addRowAction({
            icon: 'send',
            showAlways: false,
            primary: true,
            label: 'View Published Playlist',
            key: ActionViewActivePlaylistVersion,
        });

        
    }

    initDataSource(): ModelDataSource<MediaPlaylist, CursorFilter> {
        return new MediaPlaylistDataSource(this.svcApi);
    }

    canEditItem(item: MediaPlaylist): boolean {
        return true;
    }

    performEditItem(item: MediaPlaylist): Observable<MediaPlaylist> {
        return MediaPlaylistEditDialogComponent.open(this.svcLayout, {
            mediaPlaylist: item,
            gqlFieldsFragment: MEDIA_PLAYLIST_FIELDS_FRAGMENT
        })
    }

    isActionAvailable(action: string, entry: MediaPlaylist): boolean {
        switch (action){
            case ActionViewActivePlaylistVersion:
                return !!entry.activeVersion
            case ActionViewEntries:
                return true
        }
        
        return super.isActionAvailable(action, entry)
    }
    
    performMenuAction(action: string, entry: MediaPlaylist): boolean {
        switch (action){
            case ActionViewEntries:
                this.router.navigateByUrl(`media-playlists/${entry.id}`)
                return true
            case ActionViewActivePlaylistVersion:
                if(entry.activeVersion?.id){
                    MediaPlaylistVersionDialogComponent.open(this.svcLayout, entry.id, entry.activeVersion.id)
                    return true
                }
                return false
            default:
                return super.performMenuAction(action, entry)
                
        }
    }

    canDeleteItem(item: MediaPlaylist): boolean {
        return true;
    }

    performDeleteItem(item: MediaPlaylist): Observable<MutationResponse<MediaPlaylist>> {
        return undefined;
    }

}


class MediaPlaylistDataSource extends ModelDataSource<MediaPlaylist, MediaPlaylistFilter> {
    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Name',
                    valueReader: (item: MediaPlaylist) => {
                        return item.name;
                    }
                }
            ]
        });
    }

    loadData(dataFilter: MediaPlaylistFilter): Observable<CursorFeed<MediaPlaylist>> {
        const filter: Partial<MediaPlaylistFilter> = dataFilter || {}
        filter.standalone = true
        return this.svcApi.rawQuery({
            query: FETCH_PLAYLIST_GQL_QUERY,
            variables: {
                filter: filter
            }
        }).pipe(
            map(value => {
                return CursorFeed.create(value.data['mediaPlaylists'], MediaPlaylist, 'data');
            })
        )

    }

}

const MEDIA_PLAYLIST_FIELDS_FRAGMENT = gql `
fragment MediaPlaylistFieldsShort on MediaPlaylist {
  id
  name
  activeVersion {
    id
  }
}
`

const FETCH_PLAYLIST_GQL_QUERY = gql`
${MEDIA_PLAYLIST_FIELDS_FRAGMENT}
query fetchMediaPlaylists($filter:MediaPlaylistFilter!){
  mediaPlaylists(filter:$filter){
    cursor
    data: mediaPlaylists{
      ...MediaPlaylistFieldsShort
    }
  }
}
`
