import {Injectable} from '@angular/core';
import {ApiDataService} from './api-data.service';
import {merge, Observable, of, Subject} from 'rxjs';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {catchError, debounceTime, distinctUntilChanged, filter, flatMap, map, switchMap, take, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {ToastNotificationService, ToastNotificationStyle} from './toast-notification.service';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';

// chrome://settings/content/notifications
@Injectable({
    providedIn: 'root'
})
export class WebNotificationsService {

    private notificationShown: boolean;
    private notificationsPipe: Subject<NotificationMessage<any>> = new Subject();

    constructor(
        private svcApi: ApiDataService,
        private svcAuth: LoomaAuthService,
        private angularFireMessaging: AngularFireMessaging,
        private svcToastNotif: ToastNotificationService
    ) {

        const notifPipe = new Subject<{}>()

        this.angularFireMessaging.messages.subscribe(
            (messaging: any) => {
                console.warn('have messaging', messaging);

                const fn = (payload: any) => {
                    notifPipe.next(payload)
                };

                messaging._next = fn

                setTimeout(() => {
                    messaging.onMessageCallback = fn;
                }, 0)

            }
        );

        // keep the angularFireMessaging token synced, regardless of the logged in user
        this.svcAuth.onSessionChanged().pipe(
            filter(value => !!value), // only actual users

            switchMap(_ => {
                return this.withPermission().pipe(
                    filter(value => !!value),
                    takeWhile(value => value),

                    flatMap(_ => {
                        return merge(
                            this.angularFireMessaging.requestToken,
                            this.angularFireMessaging.tokenChanges
                        )
                    }),
                    distinctUntilChanged(),
                    takeUntil(this.svcAuth.onSessionChanged().pipe(
                        filter(value => !value)
                    )), // stop asking for angularFireMessaging tokens whenever the user logs out
                    filter(value => value && value !== ''),
                    debounceTime(200),
                    flatMap(value => {
                        return this.svcApi.setMyMessagingToken(value)
                    })

                )
            })
        ).subscribe();

        this.svcAuth.onSessionAvailable().pipe(
            switchMap(_ => {
                return this.withPermission().pipe(
                    filter(value => !!value),
                    flatMap(_ => {
                        return notifPipe.asObservable()
                    }),
                )
            })
        ).subscribe(value => {
            const notif = value['data'] as NotificationMessage<any>;
            if(notif && notif.type && notif.data){
                try {
                    notif.data = JSON.parse(notif.data);
                    this.handleNotification(notif);
                }catch (e) {
                    console.warn('error while deserializing notification response', value)
                }
            }
        });




    }

    private handleNotification(notif: NotificationMessage<any>): void{
        this.notificationsPipe.next(notif);

    }

    public onNotification<T>(notificationType?: string): Observable<NotificationMessage<T>>{
        return this.notificationsPipe.pipe(
            map(value => value as NotificationMessage<T>),
            filter(value => notificationType && (value.type === notificationType))
        )
    }

    private withPermission(): Observable<boolean>{
        return  this.angularFireMessaging.requestPermission.pipe(
            map(_ => true),

            catchError(err => of(false)),
            take(1),
            tap(enabled => {
                if(!enabled){
                    this.showNotification()
                }
            }),

        )
    }

    private showNotification(): void{
        if(!this.notificationShown){
            this.notificationShown = true;
            this.svcToastNotif.create({
                id:'web-notifications-disabled',
                title:'Web Notifications',
                description:`
This app will not work correctly unless web notifications are enabled<br><a target="_blank" href="chrome://settings/content/notifications">Open Settings</a>                
                `,
                style: ToastNotificationStyle.Error,
                dismissable: true
            })
        }
    }

}

export interface NotificationMessage<T>{
    type: string
    data: T
}
