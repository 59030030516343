import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Retailer} from "@looma/shared/models/retailer";
import gql from "graphql-tag";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {map, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {ActivatedRoute} from "@angular/router";
import {ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {StoreGroup} from "@looma/shared/models/store_group";
import {StoreGroupsTabControllerService} from "../store-groups/store-groups.component";

@LifecycleHooks()
@Component({
    selector: 'app-retailer-store-groups-list',
    templateUrl: './retailer-store-groups-list.component.html',
    styleUrls: ['./retailer-store-groups-list.component.scss']
})
export class RetailerStoreGroupsListComponent implements OnInit {
    @Input('retailer') retailer: Retailer

    dataSource = new StoreGroupDataSource()

    constructor(
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private svcTabs: StoreGroupsTabControllerService
    ) {
    }

    ngOnInit(): void {
        this.svcTabs.setActiveTab(this);

        this.svcApi.rawQueryNoCache({
            query: QUERY_FETCH_STORE_GROUPS,
            variables: {
                retailerId: this.retailer.id
            }
        }).pipe(
            map(value => {
                const retailers = Utils.getNestedTypedArray(value, Retailer, 'data', 'retailers_feed', "retailers")
                return retailers[0]
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.dataSource.setLocalData(value?.storeGroups || [])
        })
    }
}


class StoreGroupDataSource extends ModelListDataSource<StoreGroup> {
    constructor() {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Name',
                    valueReader: (item: StoreGroup) => {
                        return item.name
                    }
                },
                {
                    key: 'store_count',
                    label: 'Store Count',
                    valueReader: (item: StoreGroup) => {
                        return item.stores.length
                    }
                },

            ]
        });

    }
}


const RETAILER_WITH_STORE_GROUPS_FIELDS = `
id
retailer_name
storeGroups(includeUnassignedGroups: false) {
    id
    name
    stores {
      id
      retailer_store_num
      location {
        geo_point
        city
        state
        country_code
        zip_code
        street_address
      }
    }
}
`

const QUERY_FETCH_STORE_GROUPS = gql`
    query ($retailerId: ID!) {
        retailers_feed(filter: {id: $retailerId}) {
            retailers {
                ${RETAILER_WITH_STORE_GROUPS_FIELDS}
            }
        }
    }
`