import {Component, Inject, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {PromoPlaylistMediaBundle} from "@looma/shared/models/PromoPlaylistMediaBundle";
import {LayoutService} from "../../../../services/layout.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {EMPTY, merge, Observable, of} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {MediaContent, MediaContentType} from "@looma/shared/models/media_content";
import {
    MediaContentPickerDialogComponent
} from "../../../media-content/media-content-picker-dialog/media-content-picker-dialog.component";
import {flatMap, map, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {VideoPlayerService} from "../../../../services/video-player.service";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {Retailer} from "@looma/shared/models/retailer";
import {
    SplashImageEditorDialogComponent
} from "../../../splash-image-gen/components/splash-image-editor-dialog/splash-image-editor-dialog.component";
import {GetJobTokenFunc, ToastNotificationService} from "../../../../services/toast-notification.service";
import {ApiResponse} from "@looma/shared/models/api_response";
import {MediaContentEditComponent} from "../../../media-content/media-content-edit/media-content-edit.component";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {
    FRAGMENT_MEDIA_CONTENT_FIELDS,
    MUTATION_ADD_MEDIA_CONTENT
} from "../../../media-content/media-content-type-list/media-content-type-list.component";
import gql from "graphql-tag";

@LifecycleHooks()
@Component({
    selector: 'app-promo-playlist-media-bundle-dialog',
    templateUrl: './promo-playlist-media-bundle-dialog.component.html',
    styleUrls: ['./promo-playlist-media-bundle-dialog.component.scss']
})
export class PromoPlaylistMediaBundleDialogComponent extends ModelEditDialog<PromoPlaylistMediaBundle> implements OnInit {

    dialogTitle = "New Playlist Item";
    playlistMediaBundle: PromoPlaylistMediaBundle
    retailer: Retailer

    MediaContentType = MediaContentType

    mediaContentName = ''

    private splashUploadSession: UploadSession =
        this.svcUpload.newUploadSession({
            fileTypes: ['image'],
            multiSelection: false,
        })

    static open(svcLayout: LayoutService, item: PromoPlaylistMediaBundle, retailer: Retailer, gqlFields: string): Observable<PromoPlaylistMediaBundle> {
        const data: PromoPlaylistMediaBundleDialogData = {
            playlistMediaBundle: item,
            gqlFields: gqlFields,
            retailer: retailer
        }

        return svcLayout.openDialogForResult(PromoPlaylistMediaBundleDialogComponent, {
            data: data,
        })
    }

    constructor(
        public svcLayout: LayoutService,
        public dialogRef: MatDialogRef<PromoPlaylistMediaBundleDialogComponent>,
        private svcApi: ApiDataService,
        private svcVideoPlayer: VideoPlayerService,
        private sanitization: DomSanitizer,
        private svcToastNotif: ToastNotificationService,
        private svcUpload: UploadService,
        @Inject(MAT_DIALOG_DATA) private dialogData: PromoPlaylistMediaBundleDialogData
    ) {
        super();
        this.playlistMediaBundle = dialogData.playlistMediaBundle;
        this.retailer = dialogData.retailer;
        this.dialogTitle = this.playlistMediaBundle.isNewRecord() ? 'New Playlist Item' : 'Edit Playlist Item'
        if (!this.playlistMediaBundle.isNewRecord()) {
            this.playlistMediaBundle = this.playlistMediaBundle.clone();
        }

        this.mediaContentName = this.playlistMediaBundle.name
    }


    ngOnInit(): void {
    }


    onSave(): Observable<MutationResponse<PromoPlaylistMediaBundle>> | null {
        const op = this.playlistMediaBundle.isNewRecord() ? MutationOperation.Create : MutationOperation.Update;
        return this.svcApi.mutatePromoPlaylistMediaBundle(
            op, {
                id: this.playlistMediaBundle.id,
                promoPeriodId: this.playlistMediaBundle.promoPeriod?.id,
                promoProgramId: this.playlistMediaBundle.promoProgram?.id,
                brandPartnerId: this.playlistMediaBundle.brandPartner?.id,
                name: this.mediaContentName,
                mediaContentIds: this.playlistMediaBundle.mediaContents.map(value => value.id),
            },
            this.dialogData.gqlFields
        );
    }


    addItem(contentType: MediaContentType) {
        MediaContentPickerDialogComponent.open(this.svcLayout, {
            contentTypes: [contentType],
            parentBrands: [this.playlistMediaBundle.brandPartner]
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(newMediaContent => {
            this.insertItem(newMediaContent)
        })
    }

    private insertItem(newMediaContent: MediaContent) {
        if (!newMediaContent) {
            return;
        }
        const mediaContents = this.playlistMediaBundle.mediaContents;
        const existing = mediaContents.find(mc => mc.getId() == newMediaContent.getId());
        if (!existing) {
            let insertIndex = mediaContents.length;
            if (newMediaContent.contentType == MediaContentType.SplashScreen) {
                insertIndex = 0;
                for (let i = 0; i < mediaContents.length; i++) {
                    if (mediaContents[i].contentType == MediaContentType.SplashScreen) {
                        insertIndex = i + 1;
                    }
                }
            }
            console.warn('insertIndex', insertIndex)
            mediaContents.splice(insertIndex, 0, newMediaContent)
            this.playlistMediaBundle.mediaContents = [].concat(mediaContents);
        }
    }

    handleReorder(ev: CdkDragDrop<any>) {
        moveItemInArray(this.playlistMediaBundle.mediaContents, ev.previousIndex, ev.currentIndex)
    }

    playItem(content: MediaContent) {
        this.svcVideoPlayer.playContent(content);
    }

    removeMediaContent(index: number) {
        const items = this.playlistMediaBundle.mediaContents;
        if (index < items.length) {
            const toRemove = items[index];
            this.svcLayout.onConfirmed('Remote Item', `Are you sure you want to remove ${toRemove.displayName}?`).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                items.splice(index, 1);
                this.playlistMediaBundle.mediaContents = [].concat(items)
            })
        }
    }

    canRemoveMediaContent(index: number): boolean {
        const items = this.playlistMediaBundle.mediaContents;
        if (index < items.length) {
            if (items[index].contentType == MediaContentType.Film) {
                let count = 0;
                for (const item of items) {
                    if (item.contentType == MediaContentType.Film) {
                        count += 1;
                    }
                    if (count > 1) {
                        return true;
                    }
                }
            } else {
                return true;
            }
        }
        return false;
    }

    sanitizeImageUrl(imgUrl: string): SafeStyle {
        return this.sanitization.bypassSecurityTrustStyle(` url(${imgUrl})`);
    }

    generateSplash(film: MediaContent) {
        if (this.splashUploadSession) {
            this.splashUploadSession.destroy()
        }

        const brandPartner = film.brandPartner
        const retailer = this.retailer

        const uploadSession = this.splashUploadSession = this.svcUpload.newUploadSession({
            fileTypes: ['image'],
            multiSelection: false,
        })

        this.svcToastNotif.connect(uploadSession, (file) => {
            return this.svcApi.rawMutate(MUTATION_ADD_MEDIA_CONTENT, {
                data: {
                    gsFileLocation: file.firebaseKey,
                    contentType: MediaContentType.SplashScreen,
                    fileName: file.fileName,
                    filmTypeEnum: null,
                    retailerId: retailer.id,
                    brandId: brandPartner.id,
                    isGenerated: true
                }
            }, "uploadMediaContent").pipe(
                flatMap(value => {
                    return of(value.data.firebaseJobId)
                })
            )

        }).pipe(
            takeUntil(Utils.onDestroy(this)),
            flatMap(value => {
                return this.getMediaContent(value.file.extra.get("mcId"))
            })
        ).subscribe(value => {
            this.insertItem(value)
        })

        SplashImageEditorDialogComponent.open(this.svcLayout, {
            retailer: this.retailer,
            templateVars: {
                logo: {
                    url: brandPartner.logoUrl,
                    scale: 0.8,
                },
                title: {
                    text: film.displayName
                },
                backgroundImageUrl: film.filmSplashImageUrl
            }
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            uploadSession.addBlob(value, "uploaded_file.png")
        })

    }

    private getMediaContent(id: string): Observable<MediaContent> {
        return this.svcApi.rawQuery({
            query: MEDIA_CONTENT_LIST_QUERY,
            fetchPolicy: 'no-cache',
            variables: {
                criteria: {
                    id: id
                }
            }
        }).pipe(
            map(value => {
                const rawData = value.data['data']['mediaContents'] as any;
                return (new MediaContent().assign(rawData[0]))
            })
        );
    }

}

export interface PromoPlaylistMediaBundleDialogData {
    playlistMediaBundle: PromoPlaylistMediaBundle;
    gqlFields: string;
    retailer: Retailer;
}


const MEDIA_CONTENT_LIST_QUERY = gql`
    ${FRAGMENT_MEDIA_CONTENT_FIELDS}
    query media_content($criteria: MediaContentFilterCriteria!){
        data: mediaContents(criteria:$criteria){
            cursor
            mediaContents {
                ...MediaContentFields
            }
        }
    }
`;
