import {Directive, HostBinding, HostListener, Output} from '@angular/core'
import {Subject} from "rxjs";

@Directive({
    selector: '[appFileDd]'
})
export class FileDdDirective {

    constructor() {
    }

    @HostBinding('class.fileover') fileOver: boolean
    @Output() fileDropped = new Subject<File[]>()

    @HostListener('dragover', ['$event']) onDragOver(ev: DragEvent) {
        ev.preventDefault()
        ev.stopPropagation()
        this.fileOver = true
    }
    
    @HostListener('dragleave', ['$event'])
    public onDragLeave(ev: DragEvent) {
        ev.preventDefault()
        ev.stopPropagation()
        this.fileOver = false
    }

    @HostListener('drop', ['$event'])
    public ondrop(ev: DragEvent) {
        ev.preventDefault()
        ev.stopPropagation()
        this.fileOver = false
        const files = Array.prototype.slice.call(ev.dataTransfer.files)
        if (files.length > 0) {
            this.fileDropped.next(files)
        }
    }

}
