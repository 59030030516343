<div app-looma-page [pageTitle]="pageTitle" pageIcon="insert_photo" [pageSubtitle]=pageSubtitle [headerSideTpl]="headerSideTpl">
    <app-aspect-ratio-grid [dataLoader]="photosLoader" [itemTemplate]="tplItem" style="height: 100%; flex:1"
                           [columnsCount]="4" aspectRatio="16:9+50px"></app-aspect-ratio-grid>
</div>

<ng-template #tplItem let-deviceSlotImagePhoto="data" let-index="index">
    <mat-card class="image_tile mat-elevation-z3" [class.primary]="deviceSlotImagePhoto.isPrimary">
        <div class="img" [style.background-image]="getBackgroundThumbImage(deviceSlotImagePhoto)">

        </div>
        <div class="bottom" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button matTooltip="Edit" (click)="editSlotImagePhoto(deviceSlotImagePhoto)">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </mat-card>
</ng-template>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px">
        <button mat-raised-button color="warn" (click)="editSlotImage()" *ngIf="deviceSlotImage">
            Edit
        </button>
        <button mat-raised-button color="warn" matTooltip="Send in scoring queue" (click)="sendToScoringQueue()"
                *ngIf="deviceSlotImage?.lastScoreSubmission">
            Send in scoring queue
        </button>
    </div>
    
</ng-template>

