<button mat-raised-button color="warn" [matMenuTriggerFor]="menuDeviceCommand" class="mr-16" [disabled]="this.disabled">
    SEND COMMAND
</button>

<mat-menu #menuDeviceCommand="matMenu">
    <ng-template matMenuContent>
        <button *ngFor="let cmd of availableDeviceCommands" mat-menu-item
                (click)="this.commandSelected.next(cmd)">{{cmd.displayMessage}}</button>
    </ng-template>
</mat-menu>

