<div class="notifications-container " fxLayout="column" fxLayoutGap="-10px" fxLayoutAlign="space-around end"   >


    <div *ngFor="let notification of getNotifications() | async" class="notification-card mat-elevation-z5 {{getCssClass(notification)}}">

        <div class="notification-status-wrap" *ngIf="notification.style && notification.style != 'none'">
            <mat-progress-spinner *ngIf="getSpinnerMode(notification)" [mode]="getSpinnerMode(notification)" [value]="notification.progress"  [diameter]="24" ></mat-progress-spinner>
            <mat-icon *ngIf="!getSpinnerMode(notification)">{{getIconName(notification)}}</mat-icon>
        </div>
        <div class="notification-content" style="display: inline-flex; flex-direction: row; align-items: center" >
            <div>
                <div class="notification-title" *ngIf="notification.title">{{notification?.title}}</div>
                <div class="notification-body" *ngIf="notification.description" [innerHTML]="notification?.description" style="min-width: 200px;"></div>
            </div>
            <div *ngIf="notification.actions" class="notification-action-links">
                <a href="javascript: void(0);"  *ngFor="let action of notification.actions" (click)="onNotificationActionClick(action.id, notification)" >{{action.text}}</a>
            </div>

        </div>
        <button mat-icon-button class="dismiss-button" style="align-self: center;" *ngIf="notification.dismissable" (click)="notification.dismiss()">
            <mat-icon >clear</mat-icon>
        </button>


    </div>

    <div *ngIf="hasDismissableNotifications()">
        <button mat-raised-button (click)="clearDismissableNotifications()" color="accent">Clear Notifications</button>
    </div>

</div>