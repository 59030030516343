import {Utils} from "@looma/shared/utils";
import {map, takeUntil} from "rxjs/operators";
import {Observable, Subscription} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import gql from "graphql-tag";
import {ApiDataService} from "../../../services/api-data.service";

export abstract class AbstractBrandEditDialog {

    loomaIdInvalidMsg: string = null
    loomaIdValidateSubscription: Subscription

    abstract getSvcApi(): ApiDataService

    abstract hint(): string

    abstract brandId(): string

    onLoomaIdSuffixChanged(suffix: string): void {
        console.warn('onLoomaIdSuffixChanged', suffix)
        suffix = (suffix || '').trim()

        Utils.unsubscribe(this.loomaIdValidateSubscription)

        if (!suffix.match(/^[A-Z0-9]{3}$/)) {
            this.loomaIdInvalidMsg = 'Invalid length - must have 3 capital letters or digits'
            return
        }
        this.loomaIdInvalidMsg = null

        let updatedLoomaId = ''
        updatedLoomaId = suffix
        const prefix = this.hint()

        if (prefix) {
            updatedLoomaId = `${prefix.trim()}-${suffix}`
        }

        this.loomaIdValidateSubscription = this.checkLoomaIdAvailable(updatedLoomaId).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(isValid => {
            if (!isValid) {
                this.loomaIdInvalidMsg = 'Invalid value - already used'
            } else {
                this.loomaIdInvalidMsg = null
                this.onLoomaIDAvailable(updatedLoomaId)
            }
        })
    }

    abstract onLoomaIDAvailable(loomaId: string): void

    checkLoomaIdAvailable(loomaId: string): Observable<boolean> {
        return this.getSvcApi().rawQuery({
            query: SEARCH_BRANDS_BY_LOOMA_ID_QUERY,
            fetchPolicy: "no-cache",
            variables: {
                loomaId: loomaId
            }
        }).pipe(
            map(value => {
                const rawData = value.data['brands'] as any;
                const bp: BrandPartner = CursorFeed.create(rawData, BrandPartner, 'brand_partners').firstItem()
                if (bp) {
                    return bp.getStringId() == this.brandId()
                }
                return true
            }),
        )
    }
}

const SEARCH_BRANDS_BY_LOOMA_ID_QUERY = gql`
    query($loomaId:ID!){
        brands(criteria: {loomaId:$loomaId}) {
            brand_partners {
                id
            }
        }
    }
`
