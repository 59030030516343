import {EnvironmentProvider} from "@looma/shared/services/environment-provider.service";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UptimeEnvironmentProvider extends EnvironmentProvider {
    getUptimeTransferBucket(): string {
        return environment.integrationBucket;
    }
}
