import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {concatMap, debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil} from "rxjs/operators";
import {RemoteMessage, WebsocketService} from "../../../../services/websocket.service";
import {AsyncSubject, BehaviorSubject, merge, Observable, Subject, Subscription, timer} from "rxjs";
import {ToastNotificationService} from "../../../../services/toast-notification.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Utils} from "@looma/shared/utils";
import {
    DeviceRemoteControlService,
    RTC_STREAM_TYPE_AUDIO,
    RTC_STREAM_TYPE_NONE,
    RTC_STREAM_TYPE_TERMINAL,
    RTC_STREAM_TYPE_VIDEO_CAMERA,
    RTC_STREAM_TYPE_VIDEO_SCREEN_SHARE,
    RtcDeviceSession,
    ShellCommandOutput,
    ShellCommandStatusCodes,
    ShellTerminalSession
} from "../../../../services/device-remote-control.service";
import {ShellTerminalComponent} from "../../../../layout/components/shell-terminal/shell-terminal.component";
import {ApiDataService} from "../../../../services/api-data.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";

const RTC_SESSIONS_NODE = "rtc_sessions";
const PARTICIPANTS_COLLECTION = "participants";
const MESSAGES_COLLECTION = "messages";

@LifecycleHooks()
@Component({
    selector: 'app-remotecontrol',
    templateUrl: './remotecontrol.component.html',
    styleUrls: ['./remotecontrol.component.scss'],
    host: {
        '(document:keydown)': 'handleDocumentKeyboardEvent($event)',
        '(document:click)': 'handleDocumentClickEvent($event)',
    }
})
export class RemotecontrolComponent implements OnInit, OnDestroy {

    @ViewChild('videoPlayer', {static: true}) videoplayer: ElementRef;
    @ViewChild('videoGestureOverlay', {static: true}) videoGestureOverlay: ElementRef;

    deviceSession: RtcDeviceSession;
    private gestureDetector = new GestureDetector();
    private connSubs: Subscription[] = [];

    isLoading = true;
    viewSelection = new ViewSelection();
    private videoSize: Size = null;
    /*private deviceScreenSize:Size = {
        width:1280,
        height:800,
    };*/
    private videoSizeChangedSub = new Subject<HTMLVideoElement>();
    private drawings: Drawings;
    private connSubject = new BehaviorSubject<RtcDeviceSession>(null);

    isVideoViewFocused = false;
    private termSessionManager: TerminalSessionManager;

    isRestartingConnection = false;

    private connectionResetObs = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private svcWs: WebsocketService,
        private svcToast: ToastNotificationService,
        private snackBar: MatSnackBar,
        private svcDeviceRemoteControl: DeviceRemoteControlService,
        private svcApi: ApiDataService,
    ) {
    }

    restartRemoteControlApp(): void {
        this.isRestartingConnection = true;
        this.connectionResetObs.next(true);
        this.svcApi.restartRemoteControlApp(this.route.snapshot.paramMap.get('id')).subscribe(resp => {
            if (resp.success) {
            } else {
                this.snackBar.open(resp.message, null, {duration: 5000})
            }

            timer(1000).pipe(
                takeUntil(Utils.onDestroy(this)) as any
            ).subscribe(value => {
                this.initConnection();
            });

            timer(5000).pipe(
                takeUntil(Utils.onDestroy(this)) as any
            ).subscribe(value => {
                this.isRestartingConnection = false
            })
        })
    }

    setConnection(conn: RtcDeviceSession): void {
        console.warn('setConnection initialized', conn)
        if (this.deviceSession) {
            this.deviceSession.close()
        }
        this.deviceSession = conn;

        Utils.unsubscribe(this.connSubs);
        this.connSubject.next(conn);
        if (conn) {
            let sub = conn.onStreamsAvailable().subscribe((streams: MediaStream[]) => {
                this.attachStreams([].concat(streams))
            });
            this.connSubs.push(sub);

            sub = this.viewSelection.onStreamTypeChanged().pipe(
                switchMap((value: number) => {
                    this.isVideoViewFocused = false;
                    this.drawings.setFaces(null);
                    return conn.selectStream(value)
                })
            ).subscribe(value => {

            });
            this.connSubs.push(sub);

            sub = conn.onRemoteMessage().subscribe((value: RemoteMessage) => {
                switch (value.type) {
                    case "rtc/faceDetector":
                        const d = value.data['result'];
                        if (d) {
                            const res = new DetectorResult(d);
                            if (this.drawings) {
                                this.drawings.setFaces(res);
                            }
                        }

                        break;
                    default:
                        break
                }
            });
            this.connSubs.push(sub);

        }
    }

    onRtcSessionConnected(): Observable<RtcDeviceSession> {
        return this.connSubject.asObservable().pipe(
            filter(conn => !!conn) as any,
            take(1),
            switchMap((conn: RtcDeviceSession) => {
                return conn.onConnected()
            })
        )
    }

    onTerminalDisposed(term: ShellTerminalComponent): void {
        if (this.termSessionManager) {
            this.termSessionManager.close();
            this.termSessionManager = null;
        }
    }

    onTerminalOpened(term: ShellTerminalComponent): void {
        if (this.termSessionManager) {
            this.termSessionManager.close();
        }
        this.termSessionManager = new TerminalSessionManager(this, term, this.route.snapshot.paramMap.get('id'));
    }

    onVideoMetadataLoaded(event: Event): void {
        this.onSizeChanged(event.target as HTMLVideoElement)
    }

    onVideoResize(event: Event): void {
        const el = event.target as HTMLElement;
        this.onSizeChanged(event.target as HTMLVideoElement)
    }

    private onSizeChanged(el: HTMLVideoElement): void {
        this.videoSize = {
            width: el.videoWidth,
            height: el.videoHeight
        };
        this.videoSizeChangedSub.next(el)
    }

    private get isScreenSharingActive(): boolean {
        return this.viewSelection.viewType === 'screen';
    }

    ngOnInit(): void {
        this.drawings = new Drawings(this.videoGestureOverlay.nativeElement as HTMLCanvasElement);

        this.gestureDetector.onMouseEvent().pipe(
            takeUntil(Utils.onDestroy(this)) as any
        ).subscribe((ev: MotionEvent) => {
            if (this.deviceSession && this.deviceSession.isConnected) {
                if (this.isScreenSharingActive) {
                    console.warn('screen/gesture', ev)
                    this.deviceSession.messagingConn.sendMessageDirect('screen/gesture', ev)
                }
            }
        });

        const videoResize = this.videoSizeChangedSub.pipe(
            debounceTime(100) as any,
            map((value: HTMLVideoElement) => {
                return Utils.getBox(value)
            })
        );

        merge(
            videoResize, Utils.onResize(this.videoplayer)
        ).pipe(
            takeUntil(Utils.onDestroy(this)) as any
        ).subscribe((value: { width: number, height: number, left: number, top: number }) => {
            if (!this.videoSize) {
                return
            }

            const videoGestureOverlay = this.videoGestureOverlay.nativeElement as HTMLVideoElement;
            const width = value.height * this.videoSize.width / this.videoSize.height;
            videoGestureOverlay.width = width;
            videoGestureOverlay.height = value.height;
            videoGestureOverlay.style.width = width + 'px';
            videoGestureOverlay.style.height = value.height + 'px';
            videoGestureOverlay.style.left = ((value.width - width) / 2) + 'px';

            this.gestureDetector.setScale(
                width / this.deviceSession.displayInfo.width,
                value.height / this.deviceSession.displayInfo.height
            );

            this.drawings.redraw()
        });

        this.initConnection();
    }


    initConnection(): void {
        const deviceId = this.route.snapshot.paramMap.get('id');

        this.route.queryParams.pipe(
            take(1) as any
        ).subscribe(params => {
            let paramStream = 'screen';
            if (params.hasOwnProperty('view')) {
                paramStream = params['view']
            }

            this.viewSelection.viewType = paramStream;

            this.svcDeviceRemoteControl.openRtcSession(deviceId, this.viewSelection.streamFlags).pipe(
                takeUntil(Utils.onDestroy(this)) as any,
                takeUntil(this.connectionResetObs) as any
            ).subscribe((x: RtcDeviceSession) => {
                this.setConnection(x)
            }, e => {
                this.snackBar.open(Utils.extractErrorMessage(e, 'Error establishing connection'), null, {duration: 5000})
            })
        });

    }

    handleMouseEvent(ev: MouseEvent): void {
        if (this.isVideoViewFocused) {
            if (ev.which === 3) {
                return
            }
            this.gestureDetector.handleMouseEvent(ev)
        } else if (this.isScreenSharingActive && ev.type === 'mouseup') {
            this.isVideoViewFocused = true
        }

    }

    handleDocumentClickEvent(ev: MouseEvent): void {
        if (this.isScreenSharingActive) {
            if (ev.target === this.videoGestureOverlay.nativeElement) {
                this.isVideoViewFocused = true
            } else {
                this.isVideoViewFocused = false
            }
        }

    }

    handleDocumentKeyboardEvent(ev: KeyboardEvent): void {
        let broadcastData = null;
        if (this.isScreenSharingActive && this.isVideoViewFocused) {
            if (ev.key.length === 1) {
                broadcastData = {
                    gesture_type: 'keychar',
                    keychar: ev.key
                };
            } else {
                let gestureType: string = null;

                switch (ev.keyCode) {
                    case 8:
                        gestureType = 'backspace';
                        break;
                    case 46:
                        gestureType = 'delete';
                        break;
                    case 13:
                        gestureType = 'enter';
                        break;
                    case 37:
                        gestureType = 'left';
                        break;
                    case 38:
                        gestureType = 'up';
                        break;
                    case 39:
                        gestureType = 'right';
                        break;
                    case 40:
                        gestureType = 'down';
                        break;
                    default:
                        break
                }

                if (gestureType) {
                    broadcastData = {
                        gesture_type: gestureType
                    }
                }
            }
        }

        if (broadcastData) {
            ev.preventDefault();
            ev.stopPropagation();
            this.deviceSession.messagingConn.sendMessageDirect('screen/gesture', broadcastData)
        }
    }

    handleScrollEvent(ev: WheelEvent): void {
        this.gestureDetector.handleMouseEvent(ev)
    }

    handlePaste(ev: any): void {
        console.warn('handlePaste', ev)
    }

    attachStreams(streams: MediaStream[]): void {
        const element = this.videoplayer.nativeElement;
        let hasVideoStreams = false
        for (const stream of streams) {
            if (typeof element.srcObject !== 'undefined') {
                element.srcObject = stream;
            } else if (typeof element.mozSrcObject !== 'undefined') {
                element.mozSrcObject = stream;
            } else if (typeof element.src !== 'undefined') {
                element.src = URL.createObjectURL(stream as any as MediaSource);
            } else {
                console.log(element, stream, 'Error attaching stream to element.');
            }

            for (const t of stream.getTracks()) {
                if (t.kind === 'video') {
                    hasVideoStreams = true
                }
            }


        }
        if (hasVideoStreams) {
            element.style.display = 'block';
            element.controls = false;
            element.autoplay = true;
        } else {
            element.style.display = 'none';
        }


    }

    ngOnDestroy(): void {
        this.setConnection(null);
        this.videoSizeChangedSub.complete();
        Utils.unsubscribe(this.connSubs)
    }


}


class GestureDetector {
    private isDown = false;
    private mouseEventSubject = new Subject<MouseEvent | WheelEvent>();

    private _videoScale = {
        x: 1,
        y: 1,
    }

    setScale(x: number, y: number): void {
        console.warn('setScale', x, y)
        this._videoScale = {
            x: x,
            y: y,
        }
    }

    handleMouseEvent(ev: MouseEvent | WheelEvent): void {

        switch (ev.type) {
            case 'mousedown':
                if (!this.isDown) {
                    this.mouseEventSubject.next(ev)
                }
                this.isDown = true;
                break;
            case 'mouseup':
                if (this.isDown) {
                    this.mouseEventSubject.next(ev)
                }
                this.isDown = false;
                break;
            case 'mousemove':
                if (this.isDown) {
                    this.mouseEventSubject.next(ev)
                }
                break;
            case 'mouseleave':
                if (this.isDown) {
                    this.mouseEventSubject.next(new MouseEvent('mouseup', {
                        clientX: ev.clientX,
                        clientY: ev.clientY,
                        relatedTarget: ev.relatedTarget
                    }));
                }
                this.isDown = false;
                break;
            case 'wheel':
                this.mouseEventSubject.next(ev);
                break
        }
    }

    onMouseEvent(): Observable<MotionEvent> {
        return this.mouseEventSubject.asObservable().pipe(
            distinctUntilChanged<MouseEvent>((x, y) => {
                if (x.type === y.type) {
                    if (x.type === 'mousemove') {
                        return Math.abs(x.timeStamp - y.timeStamp) < 200
                    } else if (x.type === 'wheel') {
                        return false
                    }
                    return true

                }

                return false
            }),
            map((ev: MouseEvent | WheelEvent) => {
                return this.makeMotionEvent(ev)
            })
        )

    }

    private makeMotionEvent(ev: MouseEvent | WheelEvent): MotionEvent {
        const rect = (((ev.target || ev.relatedTarget) as any) as HTMLElement).getBoundingClientRect();

        const clientX = (ev.clientX - rect.left) / this._videoScale.x;
        const clientY = (ev.clientY - rect.top) / this._videoScale.y;

        const res: MotionEvent = {gesture_type: ev.type, clientX: clientX, clientY: clientY, timestamp: ev.timeStamp};

        if (ev instanceof WheelEvent) {
            res.deltaX = ev.deltaX * (1280 / screen.width) / 10;
            res.deltaY = ev.deltaY * (800 / screen.height) / 10;
            console.warn('WheelEvent', ev, this._videoScale)
        }

        return res
    }

}

interface MotionEvent {
    gesture_type: string
    clientX: number
    clientY: number
    deltaX?: number
    deltaY?: number
    timestamp: number
}

class ViewSelection {
    private _videoStreamType: number;
    private _audioStreamType: number;
    private changedSub = new Subject<number>();

    private _viewType = 'screen';

    get videoStreamType(): string {
        switch (this._videoStreamType) {
            case RTC_STREAM_TYPE_VIDEO_CAMERA:
                return 'camera';
            case RTC_STREAM_TYPE_VIDEO_SCREEN_SHARE:
                return 'screen';
            case RTC_STREAM_TYPE_TERMINAL:
                return 'terminal'
        }
        return ''
    }

    get viewType(): string {
        return this._viewType;
    }

    set viewType(viewType) {
        let newViewType = this._viewType;
        let newVideoType = this._videoStreamType;
        switch (viewType) {
            case 'camera':
                newVideoType = RTC_STREAM_TYPE_VIDEO_CAMERA;
                newViewType = viewType;
                break;
            case 'screen':
                newVideoType = RTC_STREAM_TYPE_VIDEO_SCREEN_SHARE;
                newViewType = viewType;
                break;
            case 'terminal':
                newVideoType = RTC_STREAM_TYPE_NONE;
                newViewType = viewType;
                break;
            default:
                return;
        }
        if (newViewType !== this._viewType) {
            this._viewType = newViewType
        }
        if (newVideoType !== this._videoStreamType) {
            this._videoStreamType = newVideoType;
            this.notifyStreamTypeChanged()
        }
    }

    get audioStreamEnabled(): boolean {
        return this._audioStreamType === RTC_STREAM_TYPE_AUDIO
    }

    set audioStreamEnabled(v: boolean) {
        let audioStream = 0;
        if (v) {
            audioStream = RTC_STREAM_TYPE_AUDIO
        }
        if (audioStream !== this._audioStreamType) {
            this._audioStreamType = audioStream;
            this.notifyStreamTypeChanged()
        }

    }

    private notifyStreamTypeChanged(): void {
        const flags = this._audioStreamType | this._videoStreamType;
        this.changedSub.next(flags)
    }


    get streamFlags(): number {
        return this._videoStreamType | this._audioStreamType;
    }

    onStreamTypeChanged(): Observable<number> {
        return this.changedSub.asObservable();
    }

}

interface Size {
    width: number,
    height: number
}

interface FaceData {
    eye_open: number,
    smiling: number,
    head_y: number,
    head_z: number,
    rect: number[],
    tracking_id: number
}

class DetectorResult {
    faces: FaceData[];
    frame_size: number[];

    constructor(props: any) {
        Object.assign((this as any), props);
        if (!Array.isArray(this.faces)) {
            this.faces = []
        }
    }

}


class Drawings {
    private ctx: CanvasRenderingContext2D;
    private lastResult: DetectorResult;
    private canvasDirty = false;

    constructor(private canvas: HTMLCanvasElement) {
        this.ctx = canvas.getContext("2d");
    }

    setFaces(r: DetectorResult): void {
        this.lastResult = r;
        this.redraw()
    }

    redraw(): void {
        this.clear();
        if (this.lastResult) {
            const scale = this.canvas.width / this.lastResult.frame_size[0];

            for (const face of this.lastResult.faces) {
                this.ctx.beginPath();
                this.ctx.lineWidth = 3;
                this.ctx.strokeStyle = "rgba(255, 0, 0, 0.5)";
                const left = face.rect[0], top = face.rect[1], right = face.rect[2], bottom = face.rect[3],
                    width = right - left, height = bottom - top;
                this.ctx.rect(left * scale, top * scale, width * scale, height * scale);

                this.ctx.stroke();
                let currentTop = top * scale + 10;
                const currentLeft = left * scale + 6;
                this.drawText("Face " + face.tracking_id, currentLeft, currentTop);
                currentTop += 30;
                this.drawText("Head Rotation Right " + face.head_y, currentLeft, currentTop);
                currentTop += 30;
                this.drawText("Head Tilt sideways " + face.head_z, currentLeft, currentTop);
                this.canvasDirty = true
            }

        }
    }

    drawText(text: string, left: number, top: number): void {
        const textWidth = this.ctx.measureText(text).width;
        this.ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
        this.ctx.font = "15px Arial";
        this.ctx.fillRect(left, top, textWidth, 30);
        this.ctx.fillStyle = "white";
        this.ctx.fillText(text, left, top + 15);
    }

    clear(): void {
        if (this.canvasDirty) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.canvasDirty = false
        }
    }

}

interface TerminalOpenResult {
    termSession: ShellTerminalSession
    openResult: ShellCommandOutput
}

class TerminalSessionManager {

    private shellPath: string;
    private terminalSession: ShellTerminalSession;
    private closeSubject = new AsyncSubject<boolean>();
    private _isClosed = false;
    private _hasRunningCmd = false;

    constructor(private parent: RemotecontrolComponent, private term: ShellTerminalComponent, private deviceId: string) {
        parent.onRtcSessionConnected().pipe(
            concatMap(rtcSession => {
                return rtcSession.terminalSession.open().pipe(
                    map(output => {
                        const res: TerminalOpenResult = {
                            openResult: output,
                            termSession: rtcSession.terminalSession
                        };

                        return res
                    })
                )
            }),
            takeUntil(this.closeSubject) as any
        ).subscribe((res: TerminalOpenResult) => {
            this.shellPath = res.openResult.path;
            this.terminalSession = res.termSession;
            this.prompt();
        })

    }


    private prompt(): void {
        this.term.read(`root@${this.deviceId}:${this.shellPath} # `).pipe(
            takeUntil(this.closeSubject) as any
        ).subscribe((value: string) => {
            this.exec(value);
        })
    }

    private exec(cmd: string): void {
        cmd = (cmd || '').trim();
        if (cmd === '') {
            setTimeout(() => {
                this.prompt();
            }, 0);
            return;
        }
        if (this._hasRunningCmd) {
            throw new Error('another command in progress')
        }

        const sub = this.term.onCancel().pipe(
            take(1) as any
        ).subscribe(value1 => {
            this.terminalSession.reset().subscribe();
        });

        this.terminalSession.exec(cmd).pipe(
            takeUntil(this.closeSubject) as any
        ).subscribe((output: ShellCommandOutput) => {

            if (output.status_code === ShellCommandStatusCodes.QUEUED) {
                this._hasRunningCmd = true;
            }
            if (output.status_code >= ShellCommandStatusCodes.RUNNING) {
                this.writeResultText(output.stdout);
                this.writeResultText(output.stderr, true);
            }

            if (output.status_code > ShellCommandStatusCodes.RUNNING) {
                this.shellPath = output.path;
                this.prompt()
            }
        }, error => {

        }, () => {
            this._hasRunningCmd = false;
            sub.unsubscribe();
        })
    }

    private writeResultText(text: string, stdErr: boolean = false): void {
        text = (text || '').trim();
        if (text !== '') {
            text = text.replace(/[\r\n]+/g, "\r").replace(/[\r]+/g, "\r\n") + "\r\n";
            if (stdErr) {
                this.term.writeError(text)
            } else {
                this.term.writePlain(text)
            }
        }
    }

    close(): void {
        if (!this._isClosed) {
            this._isClosed = true;
            this.closeSubject.next(true);
            this.closeSubject.complete();
            this.terminalSession.dispose();
        }
    }

}
