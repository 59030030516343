import {BaseModel} from '@looma/shared/models/base_model';
import {DeviceSlot} from '@looma/shared/models/device_slot'
import {RemoteDeviceInfo} from '@looma/shared/models/remote_device_info'
import {RemoteAppInfo} from '@looma/shared/models/remote_app_info'
import {RemoteWifiInfo} from '@looma/shared/models/remote_wifi_info'
import {RemotePlaybackInfo} from '@looma/shared/models/remote_playback_info'
import {RemoteStatusInfo} from '@looma/shared/models/remote_status_info'
import {RemoteAccelerometerInfo} from '@looma/shared/models/remote_accelerometer_info'
import {RemoteRealtimeStats} from '@looma/shared/models/remote_realtime_stats'
import {RemoteBatteryInfo} from '@looma/shared/models/remote_battery_info'
import {RemoteLocationInfo} from '@looma/shared/models/remote_location_info'
import {RemotePlaylist} from '@looma/shared/models/remote_playlist'
import {Store} from '@looma/shared/models/store'
import {DevicePowerCycleLog} from '@looma/shared/models/device_power_cycle_log'
import {formatBytes, Utils} from "@looma/shared/utils";
import {RemoteCampaignInfo} from "@looma/shared/models/remote_campaign_info";
import {RemoteDeviceDiskInfo} from "@looma/shared/models/remote_device_disk_info";
import {RemoteDeviceScreenInfo} from "@looma/shared/models/remote_device_screen_info";

export class Device extends BaseModel {
    id: string;
    device_id: string;
    asset_tag: string;
    created_at: string;
    updated_at: string;

    teamviewer_id: string;

    assignation?: DeviceSlot;
    remote_device_info?: RemoteDeviceInfo;
    remote_wifi_info?: RemoteWifiInfo;
    remote_playback_info?: RemotePlaybackInfo;
    remote_status_info?: RemoteStatusInfo;
    remote_accelerometer_info?: RemoteAccelerometerInfo;
    remote_battery_info?: RemoteBatteryInfo;
    remote_location_info?: RemoteLocationInfo;
    remote_realtime_stats?: RemoteRealtimeStats;
    power_cycle_logs?: DevicePowerCycleLog[];
    remoteDeviceCampaignInfo?: RemoteCampaignInfo;

    store?: Store;


    remote_apps_info: RemoteAppInfo[] | undefined;

    remoteDeviceConfig: RemoteDeviceConfig

    diskInfo: RemoteDeviceDiskInfo
    screenInfo: RemoteDeviceScreenInfo
    

    private _batteryProperties: Map<string, any> = new Map();
    private _wifiProperties: Map<string, any> = new Map();
    private _diskProps: Map<string, any>
    private _accelerometerProperties: Map<string, any> = new Map();
    private _installedAppProperties: Map<string, any> = new Map();

    private _isDeviceConfigurable: boolean

    get isDeviceConfigurable(): boolean {
        if (!Utils.isBoolean(this._isDeviceConfigurable)) {
            const adminVersionName = (this.remote_apps_info || []).find(x => x.device_app.package_name == "com.theloomaproject.admin")?.version_code || 0
            this._isDeviceConfigurable = adminVersionName >= 211
        }
        return this._isDeviceConfigurable
    }

    assign(obj: any): this {
        super.assign(obj);

        this.assignNested(obj, 'assignation', new DeviceSlot());
        this.assignNested(obj, 'power_cycle_logs', new DevicePowerCycleLog());

        this.assignNested(obj, 'remote_device_info', new RemoteDeviceInfo());
        this.assignNested(obj, 'remote_wifi_info', new RemoteWifiInfo());
        this.assignNested(obj, 'remote_playback_info', new RemotePlaybackInfo());

        this.assignNested(obj, 'remote_status', new RemoteStatusInfo(), 'remote_status_info');
        this.assignNested(obj, 'remote_accelerometer_info', new RemoteAccelerometerInfo());
        this.assignNested(obj, 'remote_battery_info', new RemoteBatteryInfo());
        this.assignNested(obj, 'remote_location_info', new RemoteLocationInfo());

        this.assignNested(obj, 'remoteDeviceCampaignInfo', new RemoteCampaignInfo());
        this.assignNested(obj, 'remote_realtime_stats', new RemoteRealtimeStats());
        this.assignTypedObject(obj, 'remoteDeviceConfig', RemoteDeviceConfig);
        this.assignTypedObject(obj, 'diskInfo', RemoteDeviceDiskInfo);
        this.assignTypedObject(obj, 'screenInfo', RemoteDeviceScreenInfo);

        this.assignNested(obj, 'store', new Store());

        const apps: RemoteAppInfo[] = [];

        if (Array.isArray(obj.remote_apps_info)) {
            for (const item of obj.remote_apps_info) {
                apps.push(new RemoteAppInfo().assign(item));
            }
        }

        this.remote_apps_info = apps;

        const powerLogs: DevicePowerCycleLog[] = [];

        if (Array.isArray(obj.power_cycle_logs)) {
            for (const item of obj.power_cycle_logs) {
                powerLogs.push(new DevicePowerCycleLog().assign(item));
            }
        }

        this.power_cycle_logs = powerLogs;

        if (obj.assignation) {
            this.assignation = new DeviceSlot();
            this.assignation.assign(obj.assignation);
        }

        return this;
    }

    get batteryProperties(): Map<string, any> {
        if (!this._batteryProperties.size) {
            if (this.remote_battery_info) {
                this.remote_battery_info.addProperties(this._batteryProperties);
            }
        }
        return this._batteryProperties;
    }

    get wifiProperties(): Map<string, any> {
        if (!this._wifiProperties.size) {
            if (this.remote_wifi_info) {
                this.remote_wifi_info.addProperties(this._wifiProperties);
            }
        }
        return this._wifiProperties;
    }

    get diskProps(): Map<string, any> {

        if (!this._diskProps) {
            const diskProps = this._diskProps = new Map()
            if (this.diskInfo) {
                diskProps.set('Total', formatBytes(this.diskInfo.total))
                diskProps.set('Available', formatBytes(this.diskInfo.available))
                diskProps.set('Usage', this.diskInfo.usedPercent() + '%')
            }
        }

        return this._diskProps
    }

    get accelerometerProperties(): Map<string, any> {
        if (!this._accelerometerProperties.size) {
            if (this.remote_accelerometer_info) {
                this._accelerometerProperties.set('X', this.remote_accelerometer_info.X + ` (+${this.remote_accelerometer_info.deviation_x}%)`);
                this._accelerometerProperties.set('Y', this.remote_accelerometer_info.Y + ` (+${this.remote_accelerometer_info.deviation_y}%)`);
                this._accelerometerProperties.set('Z', this.remote_accelerometer_info.Z + ` (+${this.remote_accelerometer_info.deviation_z}%)`);
            }
        }
        return this._accelerometerProperties;
    }

    get installedAppProperties(): Map<string, InstalledDeviceAppStatus> {
        if (!this._installedAppProperties.size) {
            if (this.remote_apps_info && this.remote_apps_info.length) {
                for (const installedApp of this.remote_apps_info) {
                    if (installedApp.device_app) {
                        this._installedAppProperties.set(installedApp.device_app.app_name, new InstalledDeviceAppStatus(installedApp));
                    }
                }
            }
        }
        return this._installedAppProperties;
    }

    get activePlaylist(): (RemotePlaylist | null) {
        return this.remote_playback_info && this.remote_playback_info.active_playlist || null;
    }
}


class InstalledDeviceAppStatus {
    appName: string;
    installedAppVersion: number;
    latestAppVersion: number;
    isOutdated: boolean;

    constructor(appInfo: RemoteAppInfo) {
        this.appName = appInfo.device_app.app_name;
        this.installedAppVersion = appInfo.version_code;
        this.latestAppVersion = appInfo.device_app.version_code;
        this.isOutdated = appInfo.version_code < appInfo.device_app.version_code
    }

}

export class RemoteDeviceConfig extends BaseModel {
    autoRebootEnabled: boolean
    envSwitchEnabled: boolean
    volumeTiersEnabled: boolean
}


export interface RemoteDeviceConfigMutationData {
    autoRebootEnabled: boolean
    envSwitchEnabled: boolean
    volumeTiersEnabled: boolean
}
