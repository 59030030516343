import {BaseModel} from '@looma/shared/models/base_model';
import {BrandPartner} from '@looma/shared/models/brand_partner'

export class DeviceSlotImageScore extends BaseModel {
    id: string;
    score: number;
    brandPartnerId: string;
    brandPartner: BrandPartner;
    
    assign(input: any): this {
        super.assign(input);
        this.assignNested(input, 'brandPartner', new BrandPartner());
        return this
    }
}
