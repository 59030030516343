import { Component } from '@angular/core';
import { ApiDataService } from '../../../../services/api-data.service';
import { PreferencesService } from '@looma/shared/services/preferences.service';
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";


export enum DashboardPageTab{
    NetworkHealthOverview,
    NetworkHealthHistory,
}

@LifecycleHooks()
@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent{

    DashboardPageTab: DashboardPageTab
    activeDashboardPageTab = DashboardPageTab.NetworkHealthHistory

    constructor(
        private svcApi: ApiDataService,
        private svcPrefs: PreferencesService){
    }

}
