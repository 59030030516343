import {ChangeDetectorRef, Component, Inject, Injectable} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";

import {
    DeviceSlotGridController,
    ItemCollection,
    ItemGridController
} from "../device-slot-assignment-list/device_slot_assignment_controller";
import {DeviceSlot} from "@looma/shared/models/device_slot";

export const DD_ACCEPT_ANY = 'any';

@Injectable()
class AssignmentController extends DeviceSlotGridController {
}

@LifecycleHooks()
@Component({
    selector: 'app-device-slots-assignment',
    templateUrl: './device-slots-assignment.component.html',
    styleUrls: ['./device-slots-assignment.component.scss'],
    providers: [{provide: ItemGridController, multi: false, useClass: AssignmentController}],
})
export class DeviceSlotsAssignmentComponent {
    constructor(
        private changeDetector: ChangeDetectorRef,
        @Inject(ItemGridController) public controller: AssignmentController
    ) {
    }

    setup(defaultDevices: ItemCollection<DeviceSlot>, collections: ItemCollection<DeviceSlot>[]) {
        this.controller.setup(this.changeDetector, defaultDevices, collections)
    }
}

