<h2 matDialogTitle style="padding-top:0; margin:0">{{config.title || 'Confirm'}}</h2>
<div mat-dialog-content class="p-16">
    <mat-form-field style="width: 100%" *ngIf="config.type == 'text' || config.type == 'number'">
        <input matInput
               autocomplete="off"
               [type]="inputType"
               [(ngModel)]="textValue"
               placeholder="{{config.message}}">
    </mat-form-field>

    <app-autocomplete-search-field [placeholder]="config.message" *ngIf="config.type=='search'"
                                   [searchObjectType]="config.searchConfig.objectType"
                                   [searchValues]="config?.searchConfig?.values"
                                   (valueChanged)="handleValueChanged($event)"
                                   selectionMode="single"></app-autocomplete-search-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" class="mat-button" (click)="closeWithResult()"
            [disabled]="!hasValue">{{config.positiveButtonText || 'Ok'}}</button>
    <button mat-button (click)="cancel()">{{config.negativeButtonText || 'Cancel'}}</button>
</div>
