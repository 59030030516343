import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {RemoteDataService} from "@looma/shared/services/remote_data_service";
import gql from "graphql-tag";
import {BaseModel} from "@looma/shared/models/base_model";
import {Apollo} from "apollo-angular";

@Injectable({
    providedIn: 'root'
})
export class RemoteJobService extends RemoteDataService{

    constructor(apollo: Apollo
    ) {
        super(apollo)
    }

    watchJob(jobId: string): Observable<RunningJobInfo> {
        return this.rawSubscribe(WATCH_JOB_GQL, {jobId: jobId}, RunningJobInfo)
    }

}

const WATCH_JOB_GQL = gql`
    subscription WatchJob($jobId: String!){
        watchJob(jobId:$jobId){
            status
            message
            progress{
                kind
                label
                value
            }
            extra {
                key
                value
            }
        }
    }
`


export enum RunningJobStatus {
    Unknown = 0,
    Queued = 1,
    Running = 2,
    Failure = 3,
    Success = 4
}

export class RunningJobInfo extends BaseModel {
    status: number;
    message: string;
    // data: { [key: string]: any };
    progress: { kind: string, label: string, value: number }[];
    extra: { key: string, value }[];

    get isSuccess(): boolean {
        return this.status === RunningJobStatus.Success
    }

    get isFailure(): boolean {
        return this.status === RunningJobStatus.Failure
    }

    get isDone(): boolean {
        return this.isSuccess || this.isFailure;
    }

    getCurrentProgressHint(): { label: string, value: number } {
        if (Array.isArray(this.progress) && this.progress.length) {
            const x = this.progress[this.progress.length - 1];
            return {
                label: x.label,
                value: x.value,
            }
        }
    }

    getExtra(key): string {
        if (Array.isArray(this.extra)) {
            for (const entry of this.extra) {
                if (entry.key == key) {
                    return entry.value
                }
            }
        }
    }

}

interface StatusDescription {
    progress: number;
    description: string;
}
