<h2 mat-dialog-title>Create new product from campaign submission</h2>
<mat-dialog-content [formGroup]="form">
    <div style="width: 200px; height:200px;">
        <app-image-tile-uploader
            [imageUrl]="submission.thumbUrl"
            [texts]="{empty:'Click to upload product image', nonEmpty:'Click to change image'}"
            (onFileUploaded)="handleFileUploaded($event)"
            (onUploadStateChanged)="handleUploaderStateChanged($event)"
        ></app-image-tile-uploader>
    </div>

    <mat-form-field>
        <mat-label>Brand</mat-label>
        <input matInput formControlName="brand" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>UPC</mat-label>
        <input matInput formControlName="upc" autocomplete="off"/>
    </mat-form-field>

    <app-autocomplete-search-field
        placeholder="Retailer"
        [searchObjectType]="'Retailer'"
        [value]="selectedRetailers"
        selectionMode="multi"
        (valueChanged)="onRetailerSelected($event)"></app-autocomplete-search-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="create()" [disabled]="!canSubmit()">Create new product</button>
    <button mat-button (click)="close()" [disabled]="isBusy">cancel</button>
</mat-dialog-actions>

