import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {ApiDataService} from "../../../../services/api-data.service";
import {Utils} from "@looma/shared/utils";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {RetailerPromoPeriod, RetailerPromoPeriodProgramEntry} from "@looma/shared/models/retailer_promo_periods";
import {RetailerPromoSchedule} from "@looma/shared/models/retailer_promo_schedule";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {
    PlaylistsControllerService,
    QUERY_FETCH_PLAYLIST_DATA_FOR_PROMO_PERIOD
} from "../../services/playlist-controller.service";
import {DeviceApp} from "@looma/shared/models/device_app";

@LifecycleHooks()
@Component({
    selector: 'app-promo-period-playlists-overview',
    templateUrl: './promo-period-playlists-overview.component.html',
    styleUrls: ['./promo-period-playlists-overview.component.scss'],
    providers: [PlaylistsControllerService]
})
export class PromoPeriodPlaylistsOverviewComponent implements OnInit {

    constructor(
        private activatedRoute: ActivatedRoute,
        private svcApi: ApiDataService,
        private svcController: PlaylistsControllerService
    ) {

    }

    get promoPeriod(): RetailerPromoPeriod {
        return this.svcController.promoPeriod
    }

    title = 'Playlists';

    programEntries: RetailerPromoPeriodProgramEntry[];

    static open(router: Router, promoSchedule: RetailerPromoSchedule, promoPeriod: RetailerPromoPeriod, prog?: RetailerPromoProgram) {
        const extras: NavigationExtras = {}
        if (prog) {
            extras.queryParams = {
                prog: prog.id
            }
        }
        router.navigate([`promo-periods/${promoSchedule.id}-${promoPeriod.id}/playlists`], extras)
    }

    ngOnInit() {
        this.init();
    }

    init() {
        const id = this.activatedRoute.snapshot.paramMap.get('id') || '';
        const chunks = id.split('-');

        const selectedProgramId = this.activatedRoute.snapshot.queryParamMap.get('prog')

        if (chunks.length == 2) {
            const promoScheduleId = parseInt(chunks[0])
            const promoPeriodId = parseInt(chunks[1])
            this.svcApi.rawQueryNoCache({
                query: QUERY_FETCH_PLAYLIST_DATA_FOR_PROMO_PERIOD,
                variables: {
                    promoScheduleId: promoScheduleId,
                    promoPeriodId: promoPeriodId,
                    includePromoProgram: !!selectedProgramId,
                    promoProgramId: selectedProgramId
                },
            }).subscribe(value => {
                
                const src = Utils.getNestedObject(value.data, 'retailerPromoSchedules', 'data')
                const retailerPromoPrograms = Utils.getNestedTypedArray(value.data, RetailerPromoProgram,'retailerPromoPrograms', 'retailerPromoPrograms')
                
                let kioskAppId = DeviceApp.LoopPlayerApp.id
                if(Array.isArray(retailerPromoPrograms) && retailerPromoPrograms.length > 0){
                    kioskAppId = retailerPromoPrograms[0].kioskDeviceApp?.id
                }
                if (Array.isArray(src)) {
                    const promoPeriod = Utils.getNestedTypedObject(src[0], RetailerPromoPeriod, 'promoPeriod')
                    this.svcController.init(promoScheduleId, promoPeriod)
                    this.programEntries = promoPeriod.programEntries.filter(p => p.promoProgram.kioskDeviceApp.id == kioskAppId)

                    const sch = new RetailerPromoSchedule()
                    sch.id = promoScheduleId + ''
                    promoPeriod.promoSchedule = sch;

                    this.programEntries.forEach(e => {
                        e.promoPeriod = promoPeriod
                    })

                    this.title = `${promoPeriod.name} Playlists`
                }

            })
        }

    }

    isSelectedProgram(prog: RetailerPromoProgram): boolean {
        const id = this.activatedRoute.snapshot.queryParamMap.get('prog') + ''
        return id == prog.id + ''
    }
}


