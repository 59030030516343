import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import gql from "graphql-tag";
import {map, switchMap} from "rxjs/operators";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {LayoutService} from "../../../services/layout.service";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {ApiDataService} from "../../../services/api-data.service";
import {SurveyContent, SurveyContentType} from "@looma/shared/models/survey_content";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {SurveyDataEditComponent} from "../survey-data-edit/survey-data-edit.component";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {MatTable} from "@angular/material/table";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {RetailerPromoSchedule} from "@looma/shared/models/retailer_promo_schedule";
import {EMPTY} from "rxjs";

@LifecycleHooks()
@Component({
    selector: 'app-survey-data-list',
    templateUrl: './survey-data-list.component.html',
    styleUrls: ['./survey-data-list.component.scss']
})
export class SurveyDataListComponent implements OnInit {

    @ViewChildren('tbl') tables: QueryList<MatTable<MatTable<SurveyContent>>>;

    readonly allSurveyContentTypes = SurveyContent.contentTypes

    displayedColumns: string[] = ['position', 'imageUrl', 'displayName', 'contentKey', 'description', 'extra', 'actions'];
    contentIndex = new Map<SurveyContentType, SurveyContent[]>()
    promoProgramId: string
    promoPeriodId: string
    promoScheduleId: string

    hasNoContent = false

    static open(router: Router, promoSchedule: RetailerPromoSchedule, promoPeriod: RetailerPromoPeriod, prog: RetailerPromoProgram) {
        const extras: NavigationExtras = {}
        if (prog) {
            extras.queryParams = {
                prog: prog.id
            }
        }
        router.navigate([`promo-periods/${promoSchedule.id}-${promoPeriod.id}/surveys/static-data`], extras)
    }

    constructor(private activatedRoute: ActivatedRoute,
                private svcLayout: LayoutService,
                private router: Router,
                private svcApi: ApiDataService) {
    }

    getContentKeys() {
        return SurveyContent.surveyContentTypes()
    }

    getContentValues(key: SurveyContentType): SurveyContent[] {
        return this.contentIndex.get(key)
    }

    ngOnInit(): void {
        this.promoProgramId = this.activatedRoute.snapshot.queryParams["prog"] || null
        if (this.promoProgramId == null) {
            return;
        }

        const id = this.activatedRoute.snapshot.paramMap.get('id');
        const chunks = id.split('-');

        if (chunks.length != 2) {
            return
        }

        this.promoScheduleId = chunks[0]
        this.promoPeriodId = chunks[1]

        this.fetchData()
    }

    fetchData() {
        this.hasNoContent = false
        this.svcApi.rawQuery({
            query: SURVEY_DATA_LIST_QUERY,
            fetchPolicy: "no-cache",
            variables: {
                criteria: {
                    retailerPromoPeriodId: this.promoPeriodId,
                    retailerPromoProgramId: this.promoProgramId
                }
            }
        }).pipe(
            map(value => {
                const rawData = value.data['data'] as any;
                return CursorFeed.create(rawData, SurveyContent, 'surveyContents')
            })
        ).subscribe(result => {
            if (result.data) {
                this.hasNoContent = result.data?.length === 0
                const indexMap = new Map<SurveyContentType, SurveyContent[]>()
                result.data.reduce((map, obj) => {
                    if (map.has(obj.surveyContentType)) {
                        const contents = map.get(obj.surveyContentType)
                        contents.push(obj)
                    } else {
                        map.set(obj.surveyContentType, [obj])
                    }
                    return map;
                }, indexMap)

                this.contentIndex = indexMap
            }
        });
    }

    getContentByKey(key: string): string {
        return SurveyContent.getContentTypeName(key);
    }

    onEdit(element: SurveyContent | null, surveyContentType: SurveyContentType): void {
        let content: SurveyContent = new SurveyContent()
        if (element == null) {
            content.retailerPromoProgramId = this.promoProgramId
            content.retailerPromoPeriodId = this.promoPeriodId
            content.surveyContentType = surveyContentType
        } else {
            content = element
        }


        SurveyDataEditComponent.open(this.svcLayout, content, this.contentIndex).subscribe(data => {
            if (!data) {
                return;
            }
            if (element == null || element.isNewRecord()) {
                if (this.contentIndex.has(data.surveyContentType)) {
                    const items = this.contentIndex.get(data.surveyContentType);
                    items.push(data);
                    this.contentIndex.set(data.surveyContentType, items);
                } else {
                    this.contentIndex.set(data.surveyContentType, [data]);
                }
            } else {
                const items = this.contentIndex.get(data.surveyContentType);
                const newContent = items.map(i => {
                    if (i.id == data.id) {
                        return data
                    } else {
                        return i
                    }
                })
                this.contentIndex.set(data.surveyContentType, newContent);
            }

            const idx = SurveyContent.surveyContentTypes().indexOf(data.surveyContentType)
            this.tables.toArray()[idx].renderRows()
        });
    }

    dropTable(event: CdkDragDrop<SurveyContent[]>): void {
        if (!event || event.currentIndex == event.previousIndex) {
            return;
        }
    }

    goToManageProducts(): void {
        const extras: NavigationExtras = {}
        if (this.promoProgramId) {
            extras.queryParams = {
                prog: this.promoProgramId
            }
        }
        this.router.navigate([`promo-periods/${this.promoScheduleId}-${this.promoPeriodId}/surveys`], extras)
    }

    getExtraStr(extra: SurveyContent[]): string {
        return extra.map(e => e.displayName).join(", ")
    }

    addContentType(contentType: SurveyContentType) {
        this.onEdit(null, contentType)
    }

    copyContent() {
        this.svcApi.rawQuery({
            query: QUERY_COPIABLE_PROMO_PERIODS,
            variables: {
                promoProgramId: this.promoProgramId
            }
        }).pipe(
            switchMap(value => {
                const promoPeriods = (value.data['data'] as any[]).map(d => new RetailerPromoPeriod().assign(d))
                return this.svcLayout.promptSearchObject(
                    'Select Source Promo Period',
                    '',
                    {
                        objectType: "PromoPeriod",
                        values: promoPeriods
                    },
                ).pipe(
                    switchMap(namedValue => {
                        const promoPeriod = promoPeriods.find(pp => pp.getStringId() == namedValue.value)
                        if (!promoPeriod) {
                            return EMPTY
                        }
                        return this.svcApi.rawMutate(MUTATION_COPY_CONTENTS, {
                            srcPromoPeriodId: promoPeriod.id,
                            destPromoPeriodId: this.promoPeriodId,
                            promoProgramId: this.promoProgramId,
                        }, 'data')
                    })
                )
            }),
        ).subscribe(value => {
            if (value.success) {
                this.fetchData()
            }
        })
    }
}

export const FRAGMENT_SURVEY_DATA_FIELDS = gql`
  fragment SurveyContentFields on SurveyContent {
    id
    retailerPromoPeriodId
    retailerPromoProgramId
    surveyContentType
    displayName
    contentKey
    description
    imageUrl
    orderIdx
    references {
      id
      displayName
      surveyContentType
      contentKey
    }
  }
`;

export const SURVEY_DATA_LIST_QUERY = gql`
  ${FRAGMENT_SURVEY_DATA_FIELDS}
  query surveyContents($criteria: SurveyContentFilter){
    data: surveyContents(criteria:$criteria){
      cursor
      surveyContents {
        ...SurveyContentFields
      }
    }
  }
`;


const QUERY_COPIABLE_PROMO_PERIODS = gql`
  query ($promoProgramId: ID!) {
    data: getCopiableContentsPromoPeriod(promoProgramId: $promoProgramId) {
      id
      name
    }
  }
`;

const MUTATION_COPY_CONTENTS = gql`
  mutation ($srcPromoPeriodId: ID!, $destPromoPeriodId: ID!, $promoProgramId: ID!) {
    data: copySurveyContents(
      srcPromoPeriodId: $srcPromoPeriodId
      destPromoPeriodId: $destPromoPeriodId
      promoProgramId: $promoProgramId
    ) {
      success
    }
  }
`;