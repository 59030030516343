<div app-looma-page pageTitle="Store Groups" [pageSubtitle]="retailer?.retailer_name" pageIcon="shopping_cart">

    <ng-template pageHeaderNav>
        <div style="display: flex;gap: 16px" *ngIf="!isEditingGroup">
            <button mat-button style="color: white" (click)="svcStoreGroups.setActiveViewType('list')">List View
            </button>
            <button mat-raised-button (click)="addNewStoreGroup()">New Group</button>
            <button mat-raised-button color="warn" (click)="saveChanges()" [disabled]="isBusy">Save</button>
        </div>
        <div style="display: flex;gap: 16px" *ngIf="isEditingGroup">
            <button mat-raised-button color="warn" (click)="stopEditingAssignment()">Done</button>
        </div>
    </ng-template>

    <div style="flex: 1; height:100%; display: flex">
        <div style="flex:1">
            <google-map
                [options]="{fullscreenControl:false, mapTypeControl:false, streetViewControl: false}"
                (mapMousemove)="onMapMouseMove($event)"
                (mouseup)="onMapMouseUp($event)"
                (mousedown)="onMapMouseDown($event)" #devicesMap style="flex:1" width="100%" height="100%">
                <map-marker #marker="mapMarker" *ngFor="let storeInfo of visibleStores; let idx = index"
                            [position]="{lat:storeInfo.lat, lng:storeInfo.lng}" [icon]="getMarkerIcon(storeInfo)"
                            (mapClick)="onStoreMarkerClick(storeInfo)">
                </map-marker>

            </google-map>
        </div>
        <div style="width:400px; display: flex; z-index: 1" class="mat-elevation-z3">
            <looma-grid [dataSource]="storeGroupsDataSource">
                <ng-template emptyDataContent>
                    <div style="text-align: center; padding:16px">No groups</div>
                </ng-template>

                <ng-template gridColumnHeader="name">
                    <span style="color: black; font-size:14px">Store Groups</span>
                </ng-template>

                <ng-template gridColumn="name" let-storeGroupInfo="data">
                    <span class="bullet" [style.background-color]="storeGroupInfo.color"></span>
                    <span
                        [class.hightlight_text]="editingGroup && (editingGroup?.storeGroup?.id == storeGroupInfo.id)"
                        [class.fade_text]="editingGroup && (editingGroup?.storeGroup?.id !== storeGroupInfo.id)"
                    >{{storeGroupInfo.name}}</span>
                </ng-template>

                <ng-template gridColumnHeader="row_actions">
                </ng-template>

                <ng-template rowActions="2" let-storeGroupInfo="data">

                    <button mat-icon-button (click)="editStoreGroup(storeGroupInfo)" title="Edit"
                            *ngIf="groupHasActions(storeGroupInfo)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <mat-menu #storeGroupInfoMenu="matMenu">
                        <button mat-menu-item (click)="startEditingAssignment(storeGroupInfo)">Edit assigned stores
                        </button>
                        <button mat-menu-item (click)="deleteStoreGroup(storeGroupInfo)">Delete</button>
                    </mat-menu>

                    <button mat-icon-button [matMenuTriggerFor]="storeGroupInfoMenu"
                            *ngIf="groupHasActions(storeGroupInfo)">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </ng-template>
            </looma-grid>
        </div>
    </div>
</div>