import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AppNavigation, AppNavigationItem} from '../shared/app-navigation';
import {AppNavigationConfig} from "../navigation/navigation";
import {skip} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class AppNavigationService {
    onItemCollapsed: Subject<any>;
    onItemCollapseToggled: Subject<any>;

    // Private
    private navItemsSubject = new BehaviorSubject<AppNavigationItem[]>([].concat(AppNavigationConfig.navigationItems));

    constructor() {
        // Set the defaults
        this.onItemCollapsed = new Subject();
        this.onItemCollapseToggled = new Subject();

    }

    get onNavigationChanged(): Observable<AppNavigationItem[]> {
        return this.navItemsSubject.pipe(skip(1))
    }

    setNavigationItems(items: AppNavigationItem[]) {
        this.navItemsSubject.next([].concat(items || []))
    }

    get navigationItems(): AppNavigationItem[] {
        return this.navItemsSubject.value
    }

}
