import {BaseModel} from '@looma/shared/models/base_model';

export class RemotePlaylistTrack extends BaseModel {
    id: number;
    name: string;

    assign(input: any): this {
        super.assign(input);
        return this;
    }
}
