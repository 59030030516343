import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {fabric} from "fabric";
import {Utils} from "@looma/shared/utils";

export abstract class TemplateComponent {

    private requestRefreshSubject = new Subject()
    private readonly refreshSub: Subscription

    protected constructor() {
        this.refreshSub = this.requestRefreshSubject.pipe(
            debounceTime(10)
        ).subscribe(value => {
            this.draw()
        })
        this.requestRefresh()
    }

    protected busySubject = new BehaviorSubject({component: this, busy: false})
    protected processSub: Subscription

    fabricObject: fabric.Object

    protected requestRefresh() {
        this.requestRefreshSubject.next(this)
        this.setBusy(true)
    }

    protected setBusy(newBusy: boolean) {
        if (newBusy != this.busySubject.value.busy) {
            this.busySubject.next({component: this, busy: newBusy})
        }
    }

    abstract draw()

    destroy() {
        this.busySubject.complete()
        this.requestRefreshSubject.complete()
        Utils.unsubscribe(this.processSub)
        Utils.unsubscribe(this.refreshSub)
    }
}