import {Component, Inject, Input, Optional} from '@angular/core';
import {SegmentCostEntry} from "@looma/shared/models/retailer_promo_period_submission_schedule";
import {QUERY_SUBMISSION, RetailerPromoPeriodSubmission} from "@looma/shared/models/retailer_promo_period_submission";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {gql} from "apollo-angular";
import {
    PromoScheduleProgramOverviewComponent
} from "../promo-schedule-program-overview/promo-schedule-program-overview.component";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";

@LifecycleHooks()
@Component({
    selector: 'app-campaign-submission-card',
    templateUrl: './campaign-submission-card.component.html',
    styleUrls: ['./campaign-submission-card.component.scss']
})
export class CampaignSubmissionCardComponent {

    @Input() submission: RetailerPromoPeriodSubmission

    brandCampaign: BrandPromoCampaign

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
                @Optional() public dialogRef: MatDialogRef<CampaignSubmissionCardComponent>,
                private svcApi: ApiDataService,
                public dialog: MatDialog) {
        if (this.data?.submissionId) {
            this.svcApi.rawQuery({
                query: QUERY_SUBMISSION,
                variables: {
                    criteria: {
                        id: this.data.submissionId
                    }
                }
            }).pipe(takeUntil(Utils.onDestroy(this)))
                .subscribe(result => {
                        const submissions = result.data["submissions"]
                        if (submissions) {
                            this.submission = submissions[0]
                            this.brandCampaign = this.submission.brandCampaign
                        }
                    }
                );
        }
    }

    getTotalSegmentCost(segments: SegmentCostEntry[]): number {
        return segments.reduce((acc, segment) => acc + segment.segmentCost, 0);
    }

    createCampaign(submission: RetailerPromoPeriodSubmission) {
        const dialogRef = this.dialog.open(PromoScheduleProgramOverviewComponent, {
            width: '1000px',
            height: '800px',
            data: {submission: submission}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.length > 0) {
                this.brandCampaign = result[0]
            }
        });
    }
}

