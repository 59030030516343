<h2 mat-dialog-title>Device Volume</h2>

<mat-dialog-content>
    <div class="volume_dialog_form" style="padding-top:30px" *ngIf="device?.remote_device_info?.last_heartbeat_at">
        <ng-container [ngTemplateOutlet]="tplVolume"
                      [ngTemplateOutletContext]="{
        volumeKey:'player_volume', 
        label:'Volume',
        value: device?.remote_device_info?.player_volume
        }"></ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!saveEnabled">Save</button>
    <button mat-button (click)="cancel()" [disabled]="!cancelEnabled">Cancel</button>
</mat-dialog-actions>

<ng-template #tplVolume let-volumeKey="volumeKey" let-label="label" let-value="value" let-hint="hint">
    <div class="py-10 h-48" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <span class="h4">{{label}}<small *ngIf="hint"><br/>{{hint}}</small></span>
        <mat-slider
            [disabled]="isUpdatingVolume"
            fxFlex
            thumbLabel
            tickInterval="1"
            min="0"
            max="1"
            showTickMarks="true"
            step="0.01"
            discrete
            [displayWith]="formatDeviceVolumeLabel"
        >
            <input matSliderThumb [value]="value" (valueChange)="onDeviceVolumeChanged($event)">

        </mat-slider>
    </div>
</ng-template>