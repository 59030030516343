import {NgModule} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {HttpClientModule} from '@angular/common/http';
import {Utils} from '@looma/shared/utils';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';
import {HttpLink, InMemoryCache, split} from "@apollo/client/core";
import {WebSocketLink} from "@apollo/client/link/ws";
import {setContext} from "@apollo/client/link/context";
import {getMainDefinition} from "@apollo/client/utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";



@NgModule({
    exports: [
        HttpClientModule,
    ],
})
export class GraphQLModule {
    constructor(
        apollo: Apollo,
        authService: LoomaAuthService
    ) {

        const httpEndpoint = Utils.getApiEndpoint('query')
        const wsEndpoint = httpEndpoint.replace(/^http/, 'ws')


        const http = new HttpLink({
            uri: Utils.getApiEndpoint('query')
        });

        const auth = setContext(async (_, {headers}) => {
            const newHeaders = await (authService.appendAuthorizationHeaders(headers).toPromise());
            return {
                headers: {
                    ...newHeaders,
                }
            };
        });

        const wsLink = new WebSocketLink(
            new SubscriptionClient(wsEndpoint, {
                reconnect: true
            })
        );


        const link = split(
            ({query}) => {
                const data = getMainDefinition(query);
                return (
                    data.kind === 'OperationDefinition' && data.operation === 'subscription'
                );
            },
            wsLink,
            http
        )
        
        apollo.create({
            cache: new InMemoryCache(),
            link: auth.concat(link)
        })

    }

}
