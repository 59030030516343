<h2 mat-dialog-title>{{this.currentUser.isNewRecord() ? "Create User" : "Edit user " + this.currentUser.email}}</h2>

<mat-dialog-content [formGroup]="form">

    <div *ngIf="!currentUser.isNewRecord() && currentUser.isAdmin()">
        <h3 *ngIf="this.currentUser.isAdmin()" style="color: #2E7D32">Administrator</h3>
    </div>

    <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" autocomplete="off" type="email"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Display Name</mat-label>
        <input matInput formControlName="display_name" autocomplete="off"/>
    </mat-form-field>

    <div *ngIf="(this.currentUser.isNewRecord());">
        <mat-checkbox formControlName="is_administrator">Administrator</mat-checkbox>
    </div>

    <div *ngIf="!(form.get('is_administrator').value) && !(this.currentUser.isAdmin())"
         style="margin-top: 10px; margin-bottom: 10px">
        <h3>Roles: </h3>
        <mat-selection-list *ngIf="allUserRole" (selectionChange)="onUserRoleSelected($event)">
            <mat-list-option *ngFor="let role of allUserRole || []" [selected]="isSelected(role)"
                             [value]="role">
                {{role.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div *ngIf="canAssociateBrands()">
        <app-autocomplete-search-field
            placeholder="Associated Brands"
            [searchObjectType]="'ParentBrand'"
            [value]="selectedBrands"
            style="line-height: 40px"
            (valueChanged)="onBrandSelectionChanged($event)" selectionMode="multi"
        >
        </app-autocomplete-search-field>

        <mat-card>
            <mat-card-header>Child brands</mat-card-header>
            <mat-card-content>
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <button mat-icon-button disabled></button>
                        <mat-checkbox [checked]="node.isChecked"
                                      (change)="toggleChildren(node)">{{node.name}}</mat-checkbox>
                    </mat-tree-node>
                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                        <button mat-icon-button matTreeNodeToggle>
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <mat-checkbox [checked]="node.isChecked"
                                      (change)="toggleChildren(node)">{{node.name}}</mat-checkbox>
                    </mat-tree-node>
                </mat-tree>
            </mat-card-content>
        </mat-card>


        <app-autocomplete-search-field
            placeholder="Associated Retailers"
            [searchObjectType]="'Retailer'"
            [value]="selectedRetailers"
            style="line-height: 40px"
            (valueChanged)="onRetailerSelectionChanged($event)" selectionMode="multi"
        >
        </app-autocomplete-search-field>
    </div>


    <div *ngIf="canAssociatePrograms()">
        <app-autocomplete-search-field
            placeholder="Associated Retailers"
            [searchObjectType]="'Retailer'"
            [value]="selectedRetailers"
            style="line-height: 40px"
            (valueChanged)="onRetailerSelectionChanged($event)" selectionMode="single"
        ></app-autocomplete-search-field>

        <app-autocomplete-search-field
            placeholder="Associated Programs"
            [searchObjectType]="'PromoProgram'"
            [value]="selectedPrograms"
            selectionMode="multi"
            style="line-height: 40px"
            [searchCriteria]="programSearchCriteria"
            (valueChanged)="onProgramSelectionChanged($event)"
        >
        </app-autocomplete-search-field>
    </div>

    <div *ngIf="hasSelectedRole(UserRole.DeviceTechnician)">
        <app-autocomplete-search-field
            placeholder="Store Groups"
            [searchObjectType]="'StoreGroup'"
            [searchCriteria]="storeGroupSearchCriteria"
            [value]="selectedStoreGroups"
            style="line-height: 40px"
            (valueChanged)="onStoreGroupSelectionChanged($event)" selectionMode="multi"
        >
        </app-autocomplete-search-field>

        <mat-form-field>
            <mat-label>Freshdesk User ID</mat-label>
            <input matInput formControlName="freshdeskUserId" autocomplete="off" type="number"/>
        </mat-form-field>
    </div>

    <div
        *ngIf="(this.currentUser.isNewRecord() || 
        this.currentUser.isSignUpRequested() || 
        this.currentUser.isPendingPasswordSetup()) && !(form.get('is_administrator').value)">
        <mat-checkbox formControlName="send_sales_invite_email"
                      (change)="checkBoxToggle()">Send sales invitation email
        </mat-checkbox>
        <br><br>
        <mat-checkbox formControlName="send_content_invite_email"
                      (change)="checkBoxToggle()">Send content invitation email
        </mat-checkbox>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isSavingData || !form.valid">Save</button>
    <button mat-button (click)="close()" [disabled]="isSavingData">cancel</button>
</mat-dialog-actions>