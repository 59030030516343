import {BaseModel} from '@looma/shared/models/base_model';
import {RemotePlaylist} from '@looma/shared/models/remote_playlist'

export class RemotePlaybackInfo extends BaseModel {
    assigned_playlist: RemotePlaylist;
    active_playlist: RemotePlaylist;
    session_started_at: Date;
    session_started_ago: number;
    session_track_names: string[];
    session_updated_at: Date;
    session_updated_ago: number;

    session_playback_time: number;
    session_buffering_time: number;

    assign(obj: any): this {
        super.assign(obj);

        this.assignDate(obj, 'session_updated_at');
        this.assignDate(obj, 'session_started_at');

        if (this.session_updated_at) {
            this.session_updated_ago = (Date.now() - this.session_updated_at.getTime()) / 1000;
        }
        if (this.session_started_at) {
            this.session_started_ago = (Date.now() - this.session_started_at.getTime()) / 1000;
        }
        this.assignNested(obj, 'active_playlist', new RemotePlaylist(),);
        this.assignNested(obj, 'assigned_playlist', new RemotePlaylist(),);
        return this;
    }
}
