import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {UploadService} from "@looma/shared/services/upload.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {Observable, Subscription} from "rxjs";
import {DeviceSlot, DeviceSlotMutationInput} from "@looma/shared/models/device_slot";
import {Retailer} from "@looma/shared/models/retailer";
import {map, takeUntil} from "rxjs/operators";
import {SearchableField, SearchFieldCriteria} from "@looma/shared/search";
import {NamedValue} from "@looma/shared/types/named_value";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {Store} from "@looma/shared/models/store";
import {Utils} from "@looma/shared/utils";
import gql from "graphql-tag";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {ProductCategory} from "@looma/shared/models/product_category";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {DeviceSlotType} from "@looma/shared/models/device_slot_type";
import {DeviceApp} from "@looma/shared/models/device_app";

@LifecycleHooks()
@Component({
    selector: 'app-brands-edit-dialog',
    templateUrl: './device-slots-edit-dialog.component.html',
    styleUrls: ['./device-slots-edit-dialog.component.scss']
})
export class DeviceSlotsEditDialogComponent extends ModelEditDialog<DeviceSlot> implements OnInit, OnDestroy {

    readonly storeLocationsOpts = ["Perimeter", "Perimeter-Facing", "Non-perimeter Facing"];
    readonly loopPlacementsOpts = ["End-cap", "In-line", "Wing", "OOD"];
    readonly temperatureOpts = ["Cold", "Warm"];
    readonly mountOpts = ["Shelf", "Fridge", "Loop Shelf (Standard)"];
    readonly loopXOpts = Array.from(Array(101).keys())
    readonly loomaPhasesOpts = ["Pilot", "1", "2"];
    readonly fixtureTypeOpts = ["Corner", "Floor Stack", "In-Aisle", "End Cap", "Wing", "Wedge"];
    readonly decorHeaderOpts = ["None", "Standard", "Round", "Box Header"];
    readonly yesNoOpts = ["Yes", "No"];
    readonly coolerDecalOpts = ["10\" decal", "15\" decal", "n/a"];
    readonly sideLetteringOpts = ["Yes", "No", "n/a"];

    form: FormGroup;
    deviceSlot: DeviceSlot;
    isSavingData = false;
    selectedRetailer: Retailer;
    selectedStore: Store;
    selectedKioskApp = DeviceApp.LoopPlayerApp

    storeSearchCriteria: SearchFieldCriteria[];

    availableCategories: ProductCategory[];
    availableRetailerPrograms: RetailerPromoProgram[];

    selectedProgram: RetailerPromoProgram;
    selectedDeviceSlotType: DeviceSlotType;

    private loadRetailerSub: Subscription = null;

    readonly KioskApps = DeviceApp.KioskApps;


    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<DeviceSlotsEditDialogComponent>,
                private svcApi: ApiDataService,
                private svcUpload: UploadService,
                public svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) data) {
        super();

        let deviceSlotData = {};
        if (data && data.hasOwnProperty('deviceSlot')) {
            deviceSlotData = data.deviceSlot || {};
            if (deviceSlotData instanceof DeviceSlot) {
                const slot = new DeviceSlot();
                Object.assign(slot, deviceSlotData)
                this.deviceSlot = slot;
            }
        }

        if (!this.deviceSlot) {
            this.deviceSlot = new DeviceSlot();
        }
        this.selectedKioskApp = this.deviceSlot.kioskDeviceApp || DeviceApp.LoopPlayerApp;
        this.selectedDeviceSlotType = this.deviceSlot?.deviceSlotType
        this.selectedProgram = this.deviceSlot?.deviceSlotType?.retailerPromoProgram


        this.selectedRetailer = this.deviceSlot.store?.retailer;
        this.selectedStore = this.deviceSlot.store;

        if (!this.deviceSlot.isNewRecord()) {
            this.loadRetailerData()
        }
        
        this.form = fb.group({
            name: new FormControl({value: this.deviceSlot.name, disabled: true}),
            loomaPhase: new FormControl({value: this.deviceSlot.looma_phase, disabled: false},),

            storeLocation: new FormControl({value: this.deviceSlot.store_location, disabled: false},),
            loopPlacement: new FormControl({value: this.deviceSlot.loop_placement, disabled: false},),
            temperature: new FormControl({value: this.deviceSlot.temperature, disabled: false},),
            mount: new FormControl({value: this.deviceSlot.mount, disabled: false},),
            loopXScore: new FormControl({value: this.deviceSlot.loop_x_score, disabled: false},),
            decorHeader: new FormControl({value: this.deviceSlot.decor?.header, disabled: false},),
            decorBlades: new FormControl({value: this.deviceSlot.decor?.blades, disabled: false},),
            decorShelfRibbons: new FormControl({value: this.deviceSlot.decor?.shelfRibbons, disabled: false},),
            decorAccentCard: new FormControl({value: this.deviceSlot.decor?.accentCard, disabled: false},),
            decorCoolerDecal: new FormControl({value: this.deviceSlot.decor?.coolerDecal, disabled: false},),
            decorSideLettering: new FormControl({value: this.deviceSlot.decor?.sideLettering, disabled: false},),

            productCategory: new FormControl({
                value: this.deviceSlot.product_category,
                disabled: false
            }, [Validators.required]),
        });

        this.refreshStoreSearchCriteria();
    }

    get isFormValid(): boolean {
        if (!this.selectedRetailer) {
            return false
        }
        if (!this.selectedStore) {
            return false
        }
        if (!this.selectedKioskApp) {
            return false
        }
        if (!this.form.valid) {
            return false
        }
        return true
    }

    get isLoadingRetailerDate(): boolean {
        return !Utils.isUnsubscribed(this.loadRetailerSub)
    }

    get needsSlotTypeAssignment(): boolean {
        return this.selectedKioskApp?.id != DeviceApp.SurveyApp.id
    }

    onRetailerSelected(data: NamedValue) {
        const newRetailer = Retailer.from(data);
        if (!Utils.itemsEqual(this.selectedRetailer, newRetailer)) {
            this.selectedRetailer = Retailer.from(data);
            this.selectedStore = null;
            this.refreshStoreSearchCriteria();
        }
        this.loadRetailerData()
    }

    onStoreSelected(data: NamedValue) {
        this.selectedStore = Store.from(data)
    }

    onKioskAppSelected(data: NamedValue) {
        const app = data ? DeviceApp.KioskApps.find(app => app.id == data.value) : null
        this.selectedDeviceSlotType = null
        this.selectedProgram = null
        this.selectedKioskApp = app
    }

    onProgramSelected(data: NamedValue) {
        let newProgram: RetailerPromoProgram;
        if (data) {
            newProgram = (this.availableRetailerPrograms || []).find(value => value.getId() == data.intValue())
        } else {
            newProgram = null;
        }

        if (!Utils.itemsEqual(newProgram, this.selectedProgram)) {
            this.selectedProgram = newProgram;
            this.selectedDeviceSlotType = null
        }
    }

    onDeviceSlotTypeSelected(data: NamedValue) {
        if (!data) {
            this.selectedDeviceSlotType = null;
            return
        }
        let newDeviceSlotType: DeviceSlotType;
        if (this.selectedProgram) {
            newDeviceSlotType = this.selectedProgram.deviceSlotTypes.find(value => value.getId() == data.intValue())
        }
        if (!Utils.itemsEqual(newDeviceSlotType, this.selectedDeviceSlotType)) {
            this.selectedDeviceSlotType = newDeviceSlotType;
        }
    }

    ngOnInit(): void {
        this.loadRetailerData()
    }

    ngOnDestroy(): void {
    }

    isSameObj(a: any, b: any): boolean {
        if (typeof a === "string" && typeof b === "string") {
            return a === b;
        }
        return (a && b) && a.id === b.id;
    }

    onSave(): Observable<MutationResponse<DeviceSlot>> {
        if (!this.form.valid) {
            return;
        }

        const newRecord = this.deviceSlot.isNewRecord();

        const data: DeviceSlotMutationInput = {};
        if (!newRecord) {
            data.id = this.deviceSlot.id.toString()
        }else{
            data.kioskDeviceAppId = this.selectedKioskApp.id
        }

        data.store_id = this.selectedStore.getStringId();
        data.product_category_id = this.form.get('productCategory').value.id;
        data.looma_phase = this.form.get('loomaPhase').value;

        data.store_location = this.form.get('storeLocation').value
        data.loop_placement = this.form.get('loopPlacement').value
        data.temperature = this.form.get('temperature').value
        data.mount = this.form.get('mount').value
        data.loop_x_score = this.form.get('loopXScore').value

        data.decorHeader = this.form.get('decorHeader').value
        data.decorAccentCard = this.form.get('decorAccentCard').value
        data.decorBlades = this.form.get('decorBlades').value
        data.decorCoolerDecal = this.form.get('decorCoolerDecal').value
        data.decorShelfRibbons = this.form.get('decorShelfRibbons').value
        data.decorSideLettering = this.form.get('decorSideLettering').value

        if (this.selectedDeviceSlotType) {
            data.deviceSlotTypeId = this.selectedDeviceSlotType.getStringId();
        } else {
            data.deviceSlotTypeId = '0'
        }

        const action = newRecord ? MutationOperation.Create : MutationOperation.Update;
        return this.svcApi.mutateDeviceSlot(action, data)
    }

    handleAction(action: 'ok' | 'cancel'): void {
        switch (action) {
            case 'cancel':
                this.dialogRef.close(null);
                break;
            case 'ok':
                const obs = this.onSave();
                if (!obs) {
                    return;
                }
                this.submitMutation(obs);
                break;
        }
    }

    private refreshStoreSearchCriteria(): void {
        this.storeSearchCriteria = null;
        if (this.selectedRetailer) {
            this.storeSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, this.selectedRetailer.id).asArray();
        }
    }

    loadRetailerData(): void {
        Utils.unsubscribe(this.loadRetailerSub)

        if (!this.selectedRetailer) {
            return;
        }

        this.loadRetailerSub = this.svcApi.rawQueryNoCache({
            query: gql`
                query fetchRetailerData($retailerId:ID!) {
                    retailers_feed(filter: { id: $retailerId }) {
                        retailers {
                            product_categories{
                                id
                                category_name
                            }
                            promoPrograms {
                                id
                                name
                                deviceSlotTypes {
                                    id
                                    name
                                }
                            }
                        }

                    }
                }
            `,
            variables: {
                retailerId: this.selectedRetailer.getStringId()
            }
        }).pipe(
            takeUntil(Utils.onDestroy(this)),
            map(value => {
                const feed = CursorFeed.create(value.data['retailers_feed'], Retailer, 'retailers')
                return feed.data[0]
            })
        ).subscribe((ret: Retailer) => {
            this.availableCategories = ret.product_categories || [];
            this.availableRetailerPrograms = ret.promoPrograms || [];

            if (this.selectedProgram) {
                this.selectedProgram = this.availableRetailerPrograms.find(value => value.id == this.selectedProgram.id)
            }

        })
    }


}
