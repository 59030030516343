import {NgModule} from '@angular/core';


import {DevicesListComponent} from './components/devices-list/devices-list.component';
import {AppCommonModule} from '../../shared/common.module';
import {DeviceDetailsComponent} from './components/device-details/device-details.component';
import {RouterModule} from '@angular/router';
import {LayoutModule} from "../../layout/layout.module";
import {CommandResultsListComponent} from './components/command-results-list/command-results-list.component';
import {DeviceEditComponent} from './components/device-edit/device-edit.component';
import {AppReleasesComponent} from './components/app-releases/app-releases.component';
import {RemotecontrolComponent} from './components/remotecontrol/remotecontrol.component';
import {NgxEchartsModule} from "ngx-echarts";
import {FlexLayoutModule} from "@angular/flex-layout";
import * as echarts from 'echarts';
import {LoomaPipesModule} from "@looma/shared/pipes/looma-pipes.module";
import {DeviceVolumeDialogComponent} from './components/device-volume-dialog/device-volume-dialog.component';
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {MatSliderModule} from "@angular/material/slider";

@NgModule({
    declarations: [
        DevicesListComponent,
        DeviceDetailsComponent,
        CommandResultsListComponent,
        DeviceEditComponent,
        AppReleasesComponent,
        RemotecontrolComponent,
        DeviceVolumeDialogComponent,
    ],
    imports: [
        AppCommonModule,
        RouterModule,
        LayoutModule,
        NgxEchartsModule.forRoot({echarts: echarts}),
        FlexLayoutModule,
        LoomaPipesModule,
        MatLegacyChipsModule,
        MatSliderModule
    ],
    exports     : [
        DevicesListComponent,
    ]
})

export class DevicesModule
{
}
