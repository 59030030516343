import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiDataService } from "../../../../services/api-data.service";
import { LayoutService } from "../../../../services/layout.service";
import { Location, LocationInput } from "@looma/shared/models/location";
import { ProductCategory } from "@looma/shared/models/product_category";
import { MutationOperation } from "@looma/shared/models/mutation_operation";
import { UploadService, UploadSession } from "@looma/shared/services/upload.service";
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";
import { Retailer, RetailerInput } from "@looma/shared/models/retailer";
import { ModelEditDialog } from "../../../../shared/model_edit_dialog";
import { MutationResponse } from "@looma/shared/types/mutation_response";
import { Observable } from "rxjs";
import { RetailerRegion } from "@looma/shared/models/retailer_region";
import { DeviceUserRight } from "@looma/shared/models/user_right";

@LifecycleHooks()
@Component({
    selector: 'app-retailers-edit-dialog',
    templateUrl: './retailers-edit-dialog.component.html',
    styleUrls: [ './retailers-edit-dialog.component.scss' ]
})
export class RetailersEditDialogComponent extends ModelEditDialog<Retailer> implements OnInit, OnDestroy{

    form: FormGroup;
    retailer: Retailer;
    isSavingData = false;
    categories: ProductCategory[];
    regions = RetailerRegion.regions;
    selectedRegions: string[] = [];

    private readonly uploadSession: UploadSession;
    private selectedLocation: Location;

    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<RetailersEditDialogComponent>,
                private svcApi: ApiDataService,
                private svcUpload: UploadService,
                public svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) data){
        super();

        let retData = {};
        if (data && data.hasOwnProperty('retailer')) {
            retData = data.retailer || {};
            if (retData instanceof Retailer) {
                this.retailer = retData as Retailer
            }
        }

        if (!this.retailer) {
            this.retailer = new Retailer().assign(retData || {});
        }

        this.selectedLocation = this.retailer.location;
        if (this.retailer.regions) {
            this.selectedRegions = this.retailer.regions.map(r => r.region_name)
        }

        this.form = fb.group({
            name: new FormControl({ value: this.retailer.retailer_name, disabled: false }, [ Validators.required ]),
            retailerId: new FormControl({ value: this.retailer.retailer_id, disabled: false }, [ Validators.required ]),
            websiteUrl: new FormControl({ value: this.retailer.website_url, disabled: false }),
            locationsFormControl: new FormControl({
                value: this.retailer.location?.prettyPrint(),
                disabled: false
            }, [ Validators.required ]),
            categoriesFormControl: new FormControl({
                value: this.retailer.product_categories,
                disabled: false
            }, [ Validators.required ]),
            regionsFormControl: new FormControl({
                value: this.selectedRegions,
                disabled: false
            }, [ Validators.required ]),
            offlineAlertsDisabled: new FormControl({ value: this.retailer.offlineAlertsDisabled, disabled: false }, [])
        });
    }

    ngOnInit(): void{
        this.initializeProductCategories();
    }

    ngOnDestroy(): void{
        if (this.uploadSession) {
            this.uploadSession.destroy()
        }
    }

    private initializeProductCategories(): void{
        this.svcApi.getProductCategories().subscribe(categories => {
            this.categories = categories;
        })
    }

    uploadLogo(): void{
        this.uploadSession.openPicker();
    }

    isSmeObj(a: any, b: any): boolean{
        return (a && b) && a.id === b.id;
    }

    isSameStr(a: any, b: any): boolean{
        return (a && b) && a === b;
    }

    onLocationSelected(location: Location): void{
        this.selectedLocation = location;
    }

    onSave(): Observable<MutationResponse<Retailer>>{
        if (!this.form.valid) {
            return;
        }

        const newRecord = this.retailer.isNewRecord();

        const data: RetailerInput = {};
        if (!this.retailer.isNewRecord()) {
            data.id = this.retailer.id.toString()
        }
        data.name = this.form.get('name').value;
        data.retailer_id = this.form.get('retailerId').value;
        data.location = this.form.get('locationsFormControl').value;
        data.product_category_ids = this.form.get('categoriesFormControl').value.map(t => t.id);
        data.region_ids = this.form.get('regionsFormControl').value;
        data.website_url = this.form.get('websiteUrl').value;
        data.offlineAlertsDisabled = this.retailer.offlineAlertsDisabled

        if (this.selectedLocation) {
            const locationInput: LocationInput = {};
            locationInput.countryCode = this.selectedLocation.country_code;
            locationInput.state = this.selectedLocation.state;
            locationInput.city = this.selectedLocation.city;
            locationInput.streetAddress = this.selectedLocation.street_address;
            locationInput.zipCode = this.selectedLocation.zip_code;
            data.location = locationInput;
        }

        const action = newRecord ? MutationOperation.Create : MutationOperation.Update;
        return this.svcApi.upsertRetailer(action, data);
    }

    handleAction(action: 'ok' | 'cancel'): void{
        switch (action) {
            case 'cancel':
                this.dialogRef.close(null);
                break;
            case 'ok':
                const obs = this.onSave();
                if (!obs) {
                    return;
                }
                this.submitMutation(obs);
                break;
        }
    }

    protected readonly DeviceUserRight = DeviceUserRight;
}
