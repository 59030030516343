import {NgModule} from '@angular/core';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {
    LoomaPageComponent,
    LoomaPageHeaderNav,
    LoomaPageTabDirective
} from './components/looma-page/looma-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {ContentComponent} from './components/content/content.component';
import {NavBarComponent} from './components/navbar/navbar.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {FooterComponent} from './components/footer/footer.component';
import {AppLayoutComponent} from './components/layout/app_layout.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RouterModule} from '@angular/router';
import {PerfectScrollbarDirective} from './directives/perfect-scrollbar.directive';
import {NavVerticalGroupComponent} from './components/navigation/vertical/group/group.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {NavVerticalItemComponent} from './components/navigation/vertical/item/item.component';
import {NavVerticalCollapsableComponent} from './components/navigation/vertical/collapsable/collapsable.component';
import {AppCommonModule} from '../shared/common.module';
import {MultiSelectDialogComponent} from './components/multi-select-dialog/multi-select-dialog.component';
import {ErrorSnackbarComponent} from './components/error-snackbar/error-snackbar.component';
import {DeviceSlotAssignmentComponent} from './components/device-slot-assignment/device-slot-assignment.component';
import {ImageTileUploaderComponent} from './components/image-tile-uploader/image-tile-uploader.component';
import {FileDdDirective} from './directives/file-dd.directive';


@NgModule({
    imports: [
        MatCardModule,
        MatIconModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        RouterModule,
        AppCommonModule,
    ],
    exports: [

        ToolbarComponent,
        ContentComponent,
        NavBarComponent,
        SidebarComponent,
        FooterComponent,
        AppLayoutComponent,

        PerfectScrollbarDirective,
        AppCommonModule,
        NavigationComponent,
        NavVerticalItemComponent,
        NavVerticalGroupComponent,
        NavVerticalCollapsableComponent,
        NavBarComponent,
        LoomaPageComponent,
        LoomaPageTabDirective,
        LoomaPageHeaderNav,
        DeviceSlotAssignmentComponent,
        ImageTileUploaderComponent,
        FileDdDirective
    ],
    declarations: [

        NavVerticalGroupComponent,
        NavVerticalCollapsableComponent,
        NavBarComponent,
        NavigationComponent,
        NavVerticalItemComponent,

        PerfectScrollbarDirective,
        AppLayoutComponent,
        SidebarComponent,
        NavBarComponent,
        FooterComponent,
        ContentComponent,
        ToolbarComponent,
        LoomaPageComponent,
        LoomaPageTabDirective,
        LoomaPageHeaderNav,
        MultiSelectDialogComponent,
        ErrorSnackbarComponent,
        DeviceSlotAssignmentComponent,
        ImageTileUploaderComponent,
        FileDdDirective,
    ],
})
export class LayoutModule{
}
