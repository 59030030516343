import {Assignable} from '@looma/shared/types/assignable';
import {NamedIdentifiable} from '@looma/shared/types/identifiable';
import {BaseModel} from "@looma/shared/models/base_model";

export class NamedValue extends Assignable implements NamedIdentifiable {

    name: string;
    value: string;
    hint: string;

    static from(value: string | number | BaseModel, name?: string, hint?: string): NamedValue {
        const instance = new NamedValue();
        if (value instanceof BaseModel) {
            instance.name = value.getDisplayName();
            instance.value = value.getStringId()
        } else {
            instance.name = name;
            instance.value = value + '';
        }

        instance.hint = hint;
        return instance
    }

    assign(input: any): this {
        if (input) {
            const target: any = this;
            Object.assign(target, input);
        }

        return this;
    }

    getId(): any {
        return this.value
    }

    intValue(): number {
        return parseInt(this.value, 10);
    }

    stringValue(): string {
        return this.value ? this.value + '' : null
    }

    getDisplayName(): string {
        return this.name
    }

    get hasHint(): boolean {
        return !!this.hint && (this.hint != '')
    }
}


export class SearchObject extends NamedValue {
    extra: object

    getBooleanExtra(key, defaultValue = false): boolean {
        switch (this.extra?.[key]) {
            case 'true':
                return true
            case 'false':
                return false
            default:
                return defaultValue
        }
    }

}