import {BaseModel} from '@looma/shared/models/base_model';
import {StringPair} from "../../loop-dashboard/src/app/models/string_pair";

export class BrandPromoCampaignOverview extends BaseModel {
    stores_count: number
    score_tiers_breakdown: StringPair[]
    scoreInfo: CampaignScoreInfo

    assign(input: any): this {
        super.assign(input);
        this.assignTypedArray(input, 'score_tiers_breakdown', StringPair);
        this.scoreInfo = new CampaignScoreInfo(this.score_tiers_breakdown);
        return this
    }
}

const POOR_SCORE = "poor",
    OK_SCORE = "ok",
    GOOD_SCORE = "good",
    EXCELLENT_SCORE = "excellent"

export class CampaignScoreInfo {

    private score_tiers_map = new Map<string, number>();
    private readonly totals: number;

    readonly svgOutPoorScore: string;
    readonly svgOutGoodScore: string;
    readonly svgOutOkScore: string;
    readonly svgOutExcellentScore: string;

    readonly svgDashOffsetPoorScore: number;
    readonly svgDashOffsetOkScore: number;
    readonly svgDashOffsetGoodScore: number;
    readonly svgDashOffsetExcellentScore: number;

    constructor(score_tiers_breakdown: StringPair[]) {
        score_tiers_breakdown.forEach(entry => {
            switch (entry.key) {
                case "1":
                    this.score_tiers_map.set(POOR_SCORE, Number(entry.value));
                    break;
                case "2":
                    this.score_tiers_map.set(OK_SCORE, Number(entry.value));
                    break;
                case "3":
                    this.score_tiers_map.set(GOOD_SCORE, Number(entry.value));
                    break;
                case "4":
                    this.score_tiers_map.set(EXCELLENT_SCORE, Number(entry.value));
                    break;
            }
        })

        this.totals = Array.from(this.score_tiers_map.values()).reduce((sum, item) => sum + item)
        
        this.svgOutPoorScore = this.getPercentForSvgOutput(POOR_SCORE);
        this.svgOutOkScore = this.getPercentForSvgOutput(OK_SCORE);
        this.svgOutGoodScore = this.getPercentForSvgOutput(GOOD_SCORE);
        this.svgOutExcellentScore = this.getPercentForSvgOutput(EXCELLENT_SCORE);

        this.svgDashOffsetExcellentScore = 25;
        this.svgDashOffsetGoodScore = 100 - this.getPercentage(EXCELLENT_SCORE) + this.svgDashOffsetExcellentScore;
        this.svgDashOffsetOkScore = 100 - (this.getPercentage(EXCELLENT_SCORE) + this.getPercentage(GOOD_SCORE)) + this.svgDashOffsetExcellentScore;
        this.svgDashOffsetPoorScore = 100 - (this.getPercentage(EXCELLENT_SCORE) + this.getPercentage(GOOD_SCORE) + this.getPercentage(OK_SCORE)) +this.svgDashOffsetExcellentScore;
    }

    getCount(key: string): number {
        return this.score_tiers_map.get(key) || 0
    }

    private getPercentForSvgOutput(key: string): string {
        const percent = this.getPercentage(key);
        const diff = 100 - percent
        return `${percent} ${diff}`
    }

    getPercentage(key: string): number {
        if (this.totals === 0) {
            return 0;
        }
        const count = this.getCount(key);
        return Math.round(count * 100 / this.totals);
    }
}
