import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DeviceFieldSearchCondition, DeviceSearchCriteria} from '@looma/shared/models/device_search';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-looma-grid-filter',
    templateUrl: './looma-grid-filter.component.html',
    styleUrls: ['./looma-grid-filter.component.scss']
})
export class LoomaGridFilterComponent implements OnInit, OnDestroy {

    @Input('filterCriteria')
    public filterCriteria: DeviceSearchCriteria = new DeviceSearchCriteria();
    activeFilterCondition: DeviceFieldSearchCondition;

    @Output('onFilterConditionsChanged')
    public onFilterConditionsChanged: Subject<DeviceSearchCriteria> = new Subject();

    constructor() { }

    ngOnInit(): void {
    }

    addCriteria(): void{
        this.activeFilterCondition = new DeviceFieldSearchCondition();
        this.filterCriteria.filters.push(this.activeFilterCondition);
    }

    onItemChanged(criteria: DeviceFieldSearchCondition, idx: number): void{
        this.activeFilterCondition = null;
        if(criteria.isEmpty){
            this.filterCriteria.filters.splice(idx, 1)
        }else{
            this.filterCriteria.filters[idx] = criteria
        }

        this.notifyChanged();
    }

    setFilterCriteria(criteria: DeviceSearchCriteria): void{
        this.filterCriteria = criteria;
        this.activeFilterCondition = null;
    }

    private notifyChanged(): void{
        this.onFilterConditionsChanged.next(this.filterCriteria);
    }

    ngOnDestroy(): void {
        this.onFilterConditionsChanged.complete();
    }

    clearFilters(): void{
        this.onFilterConditionsChanged.next(this.filterCriteria = new DeviceSearchCriteria())
    }



}
