import {Component, HostListener, OnInit, ViewChild} from '@angular/core'
import {StoreGroupAssignmentService} from "../../services/store-group-assignment.service"
import {LifecycleHooks} from "@looma/shared/lifecycle_utils"
import {takeUntil} from "rxjs/operators"
import {ColorSequence, createStoreMarker, Utils} from "@looma/shared/utils"
import {LayoutService} from "../../../../services/layout.service"
import {ActivatedRoute} from "@angular/router"
import {Retailer} from "@looma/shared/models/retailer"
import {StoreGroup, StoreGroupMutationInput} from "@looma/shared/models/store_group"
import {Store} from "@looma/shared/models/store"
import {GoogleMap} from "@angular/google-maps"
import {ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source"
import {BaseModel} from "@looma/shared/models/base_model"
import {Observable, Subject, Subscription} from "rxjs";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

const UNASSIGNED_FILL_COLOR = '#999'

@LifecycleHooks()
@Component({
    selector: 'app-retailer-store-groups-map-assignment',
    templateUrl: './retailer-store-groups-map-assignment.component.html',
    styleUrls: ['./retailer-store-groups-map-assignment.component.scss']
})
export class RetailerStoreGroupsMapAssignmentComponent implements OnInit {
    retailer: Retailer
    colors = new ColorSequence()
    visibleStores: StoreInfo[] = []
    allStores: StoreInfo[] = []
    markerImages = new Map<string, string>()
    markerSelector: MapMarkerSelectionRect

    @HostListener('window:keydown', ['$event'])
    handleKeyDownEvent(event: KeyboardEvent) {
        this.markerSelector?.onKeyEvent('down', event)
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyUpEvent(event: KeyboardEvent) {
        this.markerSelector?.onKeyEvent('up', event)
    }

    get isEditingGroup() {
        return !!this.editingGroup
    }

    editingGroup: EditingGroupInfo
    unassignedStoreGroup: StoreGroupInfo
    private _deletedGroups: StoreGroup[] = []

    private _devicesMap: GoogleMap

    private loadSub: Subscription

    storeGroupsDataSource: ModelListDataSource<StoreGroupInfo> = ModelListDataSource.create([{
        key: 'name',
        label: 'Name',
        valueReader: (item: StoreGroupInfo): string => {
            return item.storeGroup.name
        }
    }, {
        key: 'store_count',
        label: 'Store Count',
        width: '100px',
        valueReader: (item: StoreGroupInfo): string => {
            return item.storeInfos.length + ''
        }
    }], [])

    @ViewChild('devicesMap', {static: true}) set devicesMap(gMap: GoogleMap) {
        this._devicesMap = gMap
        this.markerSelector = new MapMarkerSelectionRect(gMap)
        this.refreshMapBounds()
        const self = this;

        (function checkMapReady() {
            if (!gMap.googleMap) {
                setTimeout(checkMapReady, 50)
            } else {
                self.onMapAvailable()
            }
        })()

    }

    get devicesMap(): GoogleMap {
        return this._devicesMap
    }

    constructor(
        public svcStoreGroups: StoreGroupAssignmentService,
        private svcLayout: LayoutService,
        private route: ActivatedRoute,
    ) {

    }

    get isBusy() {
        return !Utils.isUnsubscribed(this.loadSub)
    }

    ngOnInit() {
        this.loadSub = this.svcStoreGroups.getRetailerData(
            this.route.snapshot.paramMap.get('retailer_id')
        ).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.setRetailer(value)
        })

        this.markerSelector.onMapSelectionChanged.pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(data => {
            this.addEditingStores(data)
        })

    }

    setRetailer(retailer: Retailer) {
        this.retailer = retailer
        this._deletedGroups.length = 0
        this.visibleStores.length = 0
        this.allStores.length = 0
        this.markerImages.clear()
        this.colors.reset()

        const storeGroups: StoreGroupInfo[] = []
        this.allStores.length = 0
        for (const storeGroup of retailer.storeGroups) {
            const isUnassignedStoresGroup = parseInt(storeGroup.id) < 0
            let color = UNASSIGNED_FILL_COLOR
            if (!isUnassignedStoresGroup) {
                color = this.colors.nextColor()
            }

            const groupInfo = new StoreGroupInfo(isUnassignedStoresGroup, storeGroup, color)
            if (isUnassignedStoresGroup) {
                this.unassignedStoreGroup = groupInfo
            }
            storeGroups.push(groupInfo)

            for (const store of storeGroup.stores) {
                const storeInfo = new StoreInfo(
                    store, groupInfo
                )
                this.allStores.unshift(storeInfo)
                groupInfo.storeInfos.unshift(storeInfo)
            }
        }
        this.storeGroupsDataSource.setLocalData(storeGroups)
        this.visibleStores = this.allStores
        this.refreshMapBounds()
    }

    groupHasActions(group: StoreGroupInfo) {
        if (this.isEditingGroup) {
            return false
        }
        if (group.isUnassignedStoresGroup) {
            return false
        }
        return true
    }

    onStoreMarkerClick(storeInfo: StoreInfo) {
        if (!this.editingGroup) {
            return
        }
        const id = storeInfo.store.getStringId()
        const selectedStoreIds = this.editingGroup.selectedStoreIds
        if (selectedStoreIds.has(id)) {
            selectedStoreIds.delete(id)
        } else {
            selectedStoreIds.add(id)
        }
    }

    addEditingStores(data: MapSelectionEventData) {
        if (!this.editingGroup) {
            return
        }
        for (const storeInfo of this.visibleStores) {
            if (data.bounds.contains(storeInfo)) {
                const id = storeInfo.store.getStringId()
                if (data.type == 'remove') {
                    this.editingGroup.selectedStoreIds.delete(id)
                } else {
                    this.editingGroup.selectedStoreIds.add(id)
                }
            }
        }
    }

    getMarkerIcon(storeInfo: StoreInfo) {
        const fillColor = this.getMarkerIconFillColor(storeInfo), key = `${storeInfo.store.getId()}-${fillColor}`
        if (!this.markerImages.has(key)) {
            this.markerImages.set(key, createStoreMarker({
                text: storeInfo.store.getPaddedStoreNum(),
                fillColor: fillColor,
            }))
        }
        return this.markerImages.get(key)
    }

    private getMarkerIconFillColor(storeInfo: StoreInfo) {
        if (!this.editingGroup) {
            return storeInfo.storeGroupInfo.color
        }
        if (this.editingGroup.selectedStoreIds.has(storeInfo.store.getStringId())) {
            return this.editingGroup.storeGroup.color
        }
        return UNASSIGNED_FILL_COLOR
    }

    onMapAvailable() {
        this.refreshMapBounds()
    }

    refreshMapBounds(): void {
        const googleMap = this.devicesMap?.googleMap
        console.warn('refreshMapBounds', googleMap, this.visibleStores.length)
        if (googleMap && this.visibleStores.length) {
            const bounds = new google.maps.LatLngBounds()
            for (const store of this.visibleStores) {
                bounds.extend(new google.maps.LatLng(store.lat, store.lng))
            }
            googleMap.fitBounds(bounds)
        }
    }

    editStoreGroup(group: StoreGroupInfo) {
        this.svcLayout.prompt({
            title: 'Update store group',
            initialText: group.storeGroup.name,
            type: 'text',
            message: 'Name'
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            group.storeGroup.name = value
            this.storeGroupsDataSource.replaceItem(group)
        })
    }


    deleteStoreGroup(groupInfo: StoreGroupInfo) {
        if (groupInfo.isUnassignedStoresGroup) {
            return
        }
        this.svcLayout.onConfirmed('Delete group', `Are you sure you want to delete ${groupInfo.storeGroup.getDisplayName()}`).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.unassignedStoreGroup.storeInfos = [
                ...groupInfo.storeInfos.map(g => {
                    g.storeGroupInfo = this.unassignedStoreGroup
                    return g
                }),
                ...this.unassignedStoreGroup.storeInfos
            ]

            this.storeGroupsDataSource.removeItem(groupInfo)
            this.storeGroupsDataSource.replaceItem(this.unassignedStoreGroup)
            if (!groupInfo.storeGroup.isNewRecord()) {
                this._deletedGroups.push(groupInfo.storeGroup)
            }
            this.colors.freeColor(groupInfo.color)
        })
    }

    startEditingAssignment(group: StoreGroupInfo) {
        if (this.editingGroup) {
            return
        }

        group = this.storeGroupsDataSource.getLocalData().find(value => value.getStringId() === group.getStringId())

        this.editingGroup = {
            storeGroup: group,
            selectedStoreIds: new Set(group.storeInfos.map(value => value.store.getStringId())),
        }

        const editableStores = [
            ...group.storeInfos,
            ...this.unassignedStoreGroup.storeInfos
        ]

        this.markerSelector.setEnabled(true)

        this.visibleStores = editableStores
    }

    stopEditingAssignment() {
        if (!this.editingGroup) {
            return
        }

        this.markerSelector.setEnabled(false)

        this.editingGroup.storeGroup.storeInfos = []
        this.unassignedStoreGroup.storeInfos = []

        for (const storeInfo of this.visibleStores) {
            const isSelected = this.editingGroup.selectedStoreIds.has(storeInfo.store.getStringId())
            if (isSelected) {
                this.editingGroup.storeGroup.storeInfos.push(storeInfo)
                storeInfo.storeGroupInfo = this.editingGroup.storeGroup
            } else {
                this.unassignedStoreGroup.storeInfos.push(storeInfo)
                storeInfo.storeGroupInfo = this.unassignedStoreGroup
            }
        }

        this.visibleStores = [
            ...this.allStores
        ]

        this.storeGroupsDataSource.replaceItem(this.editingGroup.storeGroup)
        this.storeGroupsDataSource.replaceItem(this.unassignedStoreGroup)

        this.editingGroup = null
    }

    addNewStoreGroup() {
        this.svcLayout.prompt({
            title: 'New store group',
            type: 'text',
            message: 'Name'
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            const group = new StoreGroup()
            group.assign({retailer: this.unassignedStoreGroup.storeGroup.retailer, name: value, stores: []})
            const groupInfo = new StoreGroupInfo(false, group, this.colors.nextColor())
            const arr = [...this.storeGroupsDataSource.getLocalData()]
            arr.splice(arr.length - 1, 0, groupInfo)
            this.storeGroupsDataSource.setLocalData(arr)
            setTimeout(() => {
                this.startEditingAssignment(groupInfo)
            }, 0)
        })
    }

    saveChanges() {
        const data: StoreGroupMutationInput[] = []

        for (const groupInfo of this.storeGroupsDataSource.getLocalData()) {
            if (groupInfo.isUnassignedStoresGroup) {
                continue
            }
            const storeGroup = groupInfo.storeGroup

            const mutData: StoreGroupMutationInput = {
                op: MutationOperation.Create,
                name: storeGroup.name,
                storeIds: groupInfo.storeInfos.map(storeInfo => storeInfo.store.id),
            }

            if (!storeGroup.isNewRecord()) {
                mutData.op = MutationOperation.Update
                mutData.id = storeGroup.getId()
            }
            data.push(mutData)
        }

        this._deletedGroups.forEach(group => {
            data.push({
                op: MutationOperation.Delete,
                id: group.getId(),
            })
        })

        this.loadSub = this.svcStoreGroups.saveChanges(data).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(res => {
            if (!res.success) {
                this.svcLayout.showMutationResultMessage(res)
            } else {
                this.svcLayout.showMessage("Saved")
                this.setRetailer(res.data)
            }
        })
    }


    onMapMouseUp(ev) {
        this.markerSelector.onMapMouseUp(ev)
    }

    onMapMouseDown(ev) {
        this.markerSelector.onMapMouseDown(ev)
    }

    onMapMouseMove(ev: google.maps.MapMouseEvent) {
        this.markerSelector.onMapMouseMove(ev)
    }

    refreshMapMarkers() {

    }
}


class StoreGroupInfo extends BaseModel {
    storeInfos: StoreInfo[] = []
    id = Utils.nextNumericId()

    constructor(
        public isUnassignedStoresGroup: boolean,
        public storeGroup: StoreGroup,
        public color: string
    ) {
        super()

    }

    assign(input: any): this {
        return super.assign(input);
    }

}

class StoreInfo {
    private markers = new Map<string, string>()
    lat: number
    lng: number
    latLng: google.maps.LatLngLiteral

    constructor(
        public store: Store,
        public storeGroupInfo: StoreGroupInfo
    ) {
        const geo_point = store.location.geo_point
        this.lat = geo_point[0]
        this.lng = geo_point[1]
    }

}


interface EditingGroupInfo {
    storeGroup: StoreGroupInfo,
    selectedStoreIds: Set<string>
}

class MapMarkerSelectionRect {
    isShiftKeyPressed = false
    isCtrlKeyPressed = false
    isMouseDown = false
    selectionRect: google.maps.Rectangle
    lastMoveEvent: google.maps.MapMouseEvent
    mouseDownPos: google.maps.LatLng

    private isEnabled = false

    private mapSelectionChangedSub = new Subject<MapSelectionEventData>()

    get onMapSelectionChanged(): Observable<MapSelectionEventData> {
        return this.mapSelectionChangedSub
    }


    constructor(
        private gMap: GoogleMap
    ) {
    }

    setEnabled(newEnabled) {
        if (this.isEnabled != newEnabled) {
            this.isEnabled = newEnabled
            this.lastMoveEvent = null
            this.gMap.googleMap.setOptions({
                draggable: true
            })
            this.selectionRect?.setMap(null)
        }
    }

    onKeyEvent(dir: 'up' | 'down', ev: KeyboardEvent) {
        if (!this.isEnabled) {
            return
        }
        if (ev.keyCode == 16) {
            this.isShiftKeyPressed = dir == 'down'
        } else if (ev.keyCode == 17) {
            this.isCtrlKeyPressed = dir == 'down'
        }
    }

    onMapMouseDown(ev: MouseEvent) {
        if (!this.isEnabled) {
            return
        }
        this.isMouseDown = true
        if (this.isShiftKeyPressed) {
            this.mouseDownPos = this.lastMoveEvent.latLng
            this.gMap.googleMap.setOptions({
                draggable: false
            })
        }
    }

    onMapMouseUp(ev: MouseEvent) {
        if (!this.isEnabled) {
            return
        }

        if (this.isMouseDown && this.isShiftKeyPressed) {
            this.isMouseDown = false
            if (!this.selectionRect) {
                return
            }

            const boundsSelectionArea = new google.maps.LatLngBounds(
                this.selectionRect.getBounds().getSouthWest(),
                this.selectionRect.getBounds().getNorthEast()
            )

            this.mapSelectionChangedSub.next({
                bounds: boundsSelectionArea,
                type: this.isCtrlKeyPressed ? 'remove' : 'add'
            })

            this.selectionRect.setMap(null) // remove the rectangle
            this.selectionRect = null

        }

        this.gMap.googleMap.setOptions({
            draggable: true
        })

    }

    onMapMouseMove(ev: google.maps.MapMouseEvent) {
        if (!this.isEnabled) {
            return
        }

        this.lastMoveEvent = ev
        if (this.isMouseDown && this.isShiftKeyPressed) {
            if (this.selectionRect) {
                const bounds = new google.maps.LatLngBounds(this.mouseDownPos, null)
                bounds.extend(ev.latLng)
                this.selectionRect.setBounds(bounds)

            } else {
                const bounds = new google.maps.LatLngBounds()
                bounds.extend(ev.latLng)
                this.selectionRect = new google.maps.Rectangle({
                    map: this.gMap.googleMap,
                    bounds: bounds,
                    fillOpacity: 0.15,
                    strokeWeight: 0.9,
                    clickable: false
                })
            }
        }
    }
}


interface MapSelectionEventData {
    type: 'add' | 'remove',
    bounds: google.maps.LatLngBounds
}