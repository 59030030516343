import {Component, Injectable, OnInit} from '@angular/core';
import {ApiDataService} from "../../../../services/api-data.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Retailer} from "@looma/shared/models/retailer";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {PromoSchedulesForRetailerListComponent} from "../promo-schedules-for-retailer-list/promo-schedules-for-retailer-list.component";
import {PreferencesService} from "@looma/shared/services/preferences.service";

@Injectable()
export class ScheduleTabControllerService {
    private _activeTab: PromoSchedulesForRetailerListComponent;

    constructor(
        private svcPrefs: PreferencesService
    ) {
    }

    setActiveTab(tab: PromoSchedulesForRetailerListComponent) {
        this._activeTab = tab;
        setTimeout(() => {
            this.svcPrefs.setSessionValue('active_schedule_retailer_tab', tab.retailer)
        }, 0)
    }

    getActiveTab(): PromoSchedulesForRetailerListComponent {
        return this._activeTab;
    }

    isActiveRetailer(ret: Retailer) {
        const selectedRetailer = this.svcPrefs.getSessionValue('active_schedule_retailer_tab') as Retailer;
        return ret && (selectedRetailer?.id == ret?.id);
    }
}

@LifecycleHooks()
@Component({
    selector: 'app-promo-schedules-list',
    templateUrl: './promo-schedules-list.component.html',
    styleUrls: ['./promo-schedules-list.component.scss'],
    providers: [ScheduleTabControllerService]
})
export class PromoSchedulesListComponent implements OnInit {

    retailers: Retailer[] = [];

    constructor(public svcApi: ApiDataService, public svcScheduleTabs: ScheduleTabControllerService) {
    }

    ngOnInit(): void {
        this.svcApi.getRetailers().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.retailers = Retailer.getSortedRetailers(value);
        })
    }

    createNewSchedule() {
        this.svcScheduleTabs.getActiveTab().createNewSchedule()
    }

}


