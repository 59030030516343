<h2 mat-dialog-title>Crate new brand from campaign submission</h2>
<mat-dialog-content [formGroup]="form">
    <div style="width: 200px; height:200px;">
        <app-image-tile-uploader
            [imageUrl]="submission.brandPartner.logoUrl"
            [texts]="{empty:'Click to upload logo', nonEmpty:'Click to change logo'}"
            (onFileUploaded)="handleFileUploaded($event)"
            (onUploadStateChanged)="handleUploaderStateChanged($event)"
        ></app-image-tile-uploader>
    </div>

    <mat-form-field>
        <mat-label>Parent company</mat-label>
        <input matInput formControlName="parentCompany" autocomplete="off"/>
    </mat-form-field>


    <mat-form-field floatLabel="always">
        <mat-label>Looma ID</mat-label>
        <div style="display: flex; flex-direction: row">
            <span style="margin-top: -0.3px" *ngIf="submission">{{ submission.submittedByBrand.looma_id }}-</span>
            <input style="flex: 1" matInput formControlName="loomaId" autocomplete="off"
                   (ngModelChange)="onLoomaIdSuffixChanged($event)"
            />
        </div>
        <mat-hint align="start"><span style="color:red" *ngIf="loomaIdInvalidMsg">{{ loomaIdInvalidMsg }}</span>
        </mat-hint>
        <mat-spinner *ngIf="isValidatingLoomaId" matSuffix diameter="16" style="display: inline-flex"></mat-spinner>
    </mat-form-field>

    <mat-form-field>
        <mat-select multiple="true" formControlName="categoriesFormControl" placeholder="Product category">
            <mat-option *ngFor="let l of submission.submittedByBrand.product_categories || []" [value]="l">
                {{ l.category_name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="create()" [disabled]="!canSubmit()">Create new brand</button>
    <button mat-button (click)="close()" [disabled]="isBusy">cancel</button>
</mat-dialog-actions>
