import {Component, Inject, OnInit} from '@angular/core';
import {MediaPlaylist} from "@looma/shared/models/media_playlist";
import {DocumentNode} from "graphql";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ModelEditDialog} from "../../../shared/model_edit_dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {LayoutService} from "../../../services/layout.service";
import {ApiDataService} from "../../../services/api-data.service";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-media-playlist-edit-dialog',
    templateUrl: './media-playlist-edit-dialog.component.html',
    styleUrls: ['./media-playlist-edit-dialog.component.scss']
})
export class MediaPlaylistEditDialogComponent extends ModelEditDialog<MediaPlaylist> implements OnInit {

    constructor(
        public svcLayout: LayoutService,
        public dialogRef: MatDialogRef<MediaPlaylistEditDialogComponent>,
        private svcApi: ApiDataService,
        @Inject(MAT_DIALOG_DATA) private dialogData: MediaPlaylistDialogData
    ) {
        super();
        this.mediaPlaylist = dialogData.mediaPlaylist || new MediaPlaylist();
        this.mediaPlaylist.name = this.mediaPlaylist.name || '';
        this.dialogTitle = this.mediaPlaylist.isNewRecord() ? 'New Playlist' : 'Edit Playlist'
    }

    get isValid(): boolean {
        return this.mediaPlaylist.name.trim() != ''
    }


    mediaPlaylist: MediaPlaylist;
    dialogTitle: string

    static open(svcLayout: LayoutService, data: MediaPlaylistDialogData): Observable<MediaPlaylist> {

        return svcLayout.openDialogForResult(MediaPlaylistEditDialogComponent, {data: data})
    }


    ngOnInit(): void {
    }

    onSave(): Observable<MutationResponse<MediaPlaylist>> | null {
        const op = this.mediaPlaylist.isNewRecord() ? MutationOperation.Create : MutationOperation.Update;
        return this.svcApi.mutateMediaPlaylist(op, {
            id: this.mediaPlaylist.id,
            name: this.mediaPlaylist.name
        }, this.dialogData.gqlFieldsFragment);
    }


}


export interface MediaPlaylistDialogData {
    mediaPlaylist: MediaPlaylist
    gqlFieldsFragment: DocumentNode
}