import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrandsListComponent} from './components/brands-list/brands-list.component';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import {BrandsEditDialogComponent} from './components/brands-edit-dialog/brands-edit-dialog.component';
import { BrandsSearchInputComponent } from './components/brands-search-input/brands-search-input.component';
import { CustomBrandsComponent } from './components/custom-brands/custom-brands.component';
import { CreateCustomBandDialogComponent } from './components/create-custom-band-dialog/create-custom-band-dialog.component';

@NgModule({
  declarations: [BrandsListComponent, BrandsEditDialogComponent, BrandsSearchInputComponent, CustomBrandsComponent, CreateCustomBandDialogComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule
    ],
    entryComponents: [BrandsEditDialogComponent]
})
export class BrandsModule { }
