<h2 mat-dialog-title>{{"Edit film variable " + this.mediaFileFilmVariable.film_variable.name}}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <mat-label>Variable name</mat-label>
        <input matInput formControlName="variableName"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Variable value</mat-label>
        <input matInput formControlName="variableValue"/>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isSavingData || !form.valid">Save</button>
    <button mat-button (click)="close()" [disabled]="isSavingData">cancel</button>
</mat-dialog-actions>
