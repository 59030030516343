import {Injectable} from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import {Observable} from 'rxjs';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';
import {map, tap} from 'rxjs/operators';
import {AppNavigation, AppNavigationHelper} from "../../uptime-dashboard/src/app/shared/app-navigation";
import {AuthSession} from "@looma/shared/auth/auth_session";
import {AppNavigationConfig} from "../../uptime-dashboard/src/app/navigation/navigation";

const navigationItems = AppNavigationConfig.navigationItems

abstract class BaseLoomaGuard implements CanActivate, CanActivateChild {

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkCanActivate(route)
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkCanActivate(childRoute);
    }

    abstract checkCanActivate(route: ActivatedRouteSnapshot): Observable<boolean>
}

@Injectable({
    providedIn: 'root'
})
export class UptimeSignedInGuard extends BaseLoomaGuard {
    constructor(protected svcAuth: LoomaAuthService, protected router: Router) {
        super();
    }

    checkCanActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.svcAuth.onSessionAvailable().pipe(
            map(session => {
                const pathIndex = AppNavigationHelper.indexByPath(navigationItems[0].children, new Map<string, AppNavigation>())
                const navConfig = pathIndex.get(route.url[0].path)
                if (!navConfig) {
                    return false
                }

                if (session?.authenticated &&
                    !session?.needsAccountSetup &&
                    this.hasPermission(navConfig, session)) {
                    return true
                }

                const redirectTo = this.svcAuth.getRedirectUrl();
                if (redirectTo) {
                    this.router.navigateByUrl(redirectTo);
                }
                return false
            })
        )
    }

    private hasPermission(navConfig: AppNavigation, authSession: AuthSession) {
        if (!navConfig.permission) {
            return true
        }
        return authSession.hasAccessPermission(navConfig.permission)
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoomaSignedInGuard extends BaseLoomaGuard {

    constructor(protected svcAuth: LoomaAuthService, protected router: Router) {
        super();
    }

    checkCanActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.svcAuth.onSessionAvailable().pipe(
            map(session => {
                if (session?.authenticated &&
                    !session?.needsAccountSetup) {
                    return true
                }

                const redirectTo = this.svcAuth.getRedirectUrl();
                if (redirectTo) {
                    this.router.navigateByUrl(redirectTo);
                }
                return false
            })
        )
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoomaSignedOutGuard extends BaseLoomaGuard {
    constructor(protected svcAuth: LoomaAuthService, protected router: Router) {
        super();
    }

    checkCanActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.svcAuth.onSessionAvailable().pipe(
            map(session => {
                if (!session?.authenticated) {
                    return true
                }
                const redirectTo = this.svcAuth.getRedirectUrl();
                if (redirectTo) {
                    this.router.navigateByUrl(redirectTo);
                }

                return false
            }),
            tap(x => {
                // console.warn('LoomaSignedOutGuard', x)
            })
        )
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoomaNeedsAccountSetupGuard extends BaseLoomaGuard {
    constructor(protected svcAuth: LoomaAuthService, protected router: Router, protected activatedRoute: ActivatedRoute) {
        super();
    }

    checkCanActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.svcAuth.onSessionAvailable().pipe(
            map(session => {

                if (session?.needsAccountSetup) {
                    return true
                }
                const redirectTo = this.svcAuth.getRedirectUrl();
                if (redirectTo) {
                    this.router.navigateByUrl(redirectTo);
                }

                return false
            }),
            tap(x => {
                // console.warn('LoomaNeedsAccountSetupGuard', x)
            })
        )
    }
}


