import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GenericReportsComponent} from './generic-reports/generic-reports.component';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";


@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    declarations: [GenericReportsComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule
    ]
})
export class ReportsModule {
}
