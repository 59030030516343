<h2 mat-dialog-title>{{ this.retailer.isNewRecord() ? "Create Retailer" : "Edit " + this.retailer.retailer_name }}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Retailer ID</mat-label>
        <input matInput formControlName="retailerId" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Website URL</mat-label>
        <input matInput formControlName="websiteUrl" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field *ngIf="categories?.length > 0">
        <mat-select multiple="true" formControlName="categoriesFormControl" placeholder="Product category"
                    [value]="retailer.product_categories" [compareWith]="isSmeObj">
            <mat-option *ngFor="let l of categories || []" [value]="l">
                {{ l.category_name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Region</mat-label>
        <mat-select multiple="true" formControlName="regionsFormControl" placeholder="Region"
                    [value]="this.selectedRegions" [compareWith]="isSameStr">
            <mat-option *ngFor="let l of regions || []" [value]="l">
                {{ l }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Location</mat-label>
        <input matInput (addressChanged)="onLocationSelected($event)" appPlacesAutocomplete class="input"
               formControlName="text" id="addressText" formControlName="locationsFormControl" autocomplete="off">
    </mat-form-field>

    <div class="mat-form-field" appearance="outline">
        <span style="color: black">Offline alerts disabled: </span>
        <mat-slide-toggle [checked]="retailer.offlineAlertsDisabled"
                          (change)="retailer.offlineAlertsDisabled = !retailer.offlineAlertsDisabled"></mat-slide-toggle>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleAction('ok')" [disabled]="isSavingData || !form.valid">
        Save
    </button>
    <button mat-button (click)="handleAction('cancel')" [disabled]="isSavingData">cancel</button>
</mat-dialog-actions>