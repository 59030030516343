import {BaseModel} from '@looma/shared/models/base_model';
import {Store} from '@looma/shared/models/store'
import {WordFilterable} from '@looma/shared/types/word_filterable';

export class RetailerRegion extends BaseModel implements WordFilterable {

    static regions: string[] = [...Array(20).keys()].map(i => `${i + 1}`);

    id: string;
    region_name: string;
    stores: Store[];

    assign(input: any): this {
        super.assign(input);
        if (Array.isArray(input.stores)) {
            this.stores = input.stores.map(x => new Store().assign(x))
        } else {
            this.stores = []
        }

        return this;
    }

    getPhrases(): string[] {
        return [this.region_name];
    }

    getDisplayName(): string {
        return this.region_name
    }

}

