import {BaseModel} from '@looma/shared/models/base_model';

export class DeviceApp extends BaseModel {

    static readonly LoopPlayerApp = new DeviceApp().assign({
        id: '2',
        app_name: 'Loop Player'
    })
    static readonly SurveyApp = new DeviceApp().assign({
        id: '8',
        app_name: 'Survey'
    })

    static readonly KioskApps = [DeviceApp.LoopPlayerApp, DeviceApp.SurveyApp];


    id: string;
    app_name: string;
    package_name: string;
    version_name: string;
    version_code: number;

    getDisplayName(): string {
        return this.app_name
    }
}

Object.freeze(DeviceApp.LoopPlayerApp)
Object.freeze(DeviceApp.SurveyApp)
