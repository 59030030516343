<div app-looma-page pageTitle="Retailers" pageIcon="business"  >
    <looma-grid [dataSource]="dataSource">
        <ng-template gridColumn="name" let-data="data">
            <a href="{{data.website_url}}" target="_blank" style="color: #006064;">
                {{data.name}}
            </a>
        </ng-template>
    </looma-grid>

    <ng-template pageHeaderNav>
        <button mat-raised-button (click)="addNewRetailer()">New Retailer</button>
    </ng-template>
</div>
