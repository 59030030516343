<div app-looma-page pageTitle="Programs" pageIcon="videocam">

    <looma-grid>
        <ng-template rowExpansionTemplate let-program="data">
            <looma-grid [dataSource]="dataSource.getDeviceTypesDataSource(program)" [scrollContent]="false"
                        [rowActions]="deviceSlotTypeActions">
                <ng-template gridColumnHeader="name">
                    <span style="color: black; font-size: 1.2em">Device Slot Type</span>
                </ng-template>
            </looma-grid>
        </ng-template>

    </looma-grid>

    <ng-template pageHeaderNav>
        <div style="display: flex; gap:16px; align-items: center">
            <mat-form-field>
                <mat-label>Search by Program Name</mat-label>
                <input #upcSearchInput autocomplete="off" matInput fxFlex (input)="onProgramNameFilterChanged($event)"/>
                <button matSuffix mat-icon-button aria-label="Clear" (click)="clearProgramNameFilter()"
                        *ngIf="upcSearchInput?.value != ''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>


            <app-autocomplete-search-field showClear="true" placeholder="Retailer" searchObjectType="Retailer"
                                           (valueChanged)="onRetailerFilterChanged($event)" selectionMode="single"
                                           style="width: 300px; padding-right: 20px"></app-autocomplete-search-field>

            <button mat-raised-button (click)="addNewProgram()">New Program</button>
        </div>
    </ng-template>
</div>
