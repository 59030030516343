import {Component, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ApiDataService} from "../../../../services/api-data.service";
import {ActivatedRoute} from "@angular/router";
import {flatMap, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {DeviceSlotImage, DeviceSlotImageMutationInput} from "@looma/shared/models/device_slot_image";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {DeviceSlotImagePhoto} from "@looma/shared/models/device_slot_image_photo";
import {ListCursorLoader} from "@looma/shared/cursor_loader";
import {EditDeviceSlotImagePhotoDialogComponent} from "../edit-device-slot-image-photo-dialog/edit-device-slot-image-photo-dialog.component";
import {LayoutService} from "../../../../services/layout.service";
import {EditDeviceSlotImageDialogComponent} from "../edit-device-slot-image-dialog/edit-device-slot-image-dialog.component";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-device-slot-image-photos-list',
    templateUrl: './device-slot-image-photos-list.component.html',
    styleUrls: ['./device-slot-image-photos-list.component.scss']
})
export class DeviceSlotImagePhotosListComponent implements OnInit {

    deviceSlotImage: DeviceSlotImage
    photosLoader: ListCursorLoader<DeviceSlotImagePhoto> = null
    pageTitle: string
    pageSubtitle: string

    constructor(
        private route: ActivatedRoute,
        private svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private sanitization: DomSanitizer
    ) {
    }

    ngOnInit(): void {

        this.deviceSlotImage = null;
        this.photosLoader = null;
        this.pageTitle = null

        this.svcApi.getDeviceSlotImages({
            ids: [this.route.snapshot.paramMap.get("imageId")]
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.data?.length == 1) {
                this.onDeviceSlotIMageAvailable(value.data[0])
            }

        })
    }

    editSlotImage() {
        EditDeviceSlotImageDialogComponent.open(this.svcLayout, this.deviceSlotImage).subscribe(value => {
            if (value instanceof DeviceSlotImage) {
                this.onDeviceSlotIMageAvailable(value)
            }
        })
    }

    private onDeviceSlotIMageAvailable(deviceSlotImage: DeviceSlotImage) {
        this.deviceSlotImage = deviceSlotImage
        this.photosLoader = new ListCursorLoader(this.deviceSlotImage.photos)
        this.pageTitle = this.deviceSlotImage.displayName()
        this.pageSubtitle = Utils.formatDate(this.deviceSlotImage.date, 'mediumDate')
    }

    getBackgroundThumbImage(img: DeviceSlotImagePhoto): SafeStyle {
        return this.sanitization.bypassSecurityTrustStyle(` url(${img.thumb.url})`);
    }

    editSlotImagePhoto(photo: DeviceSlotImagePhoto) {
        EditDeviceSlotImagePhotoDialogComponent.open(this.svcLayout, this.deviceSlotImage, photo).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value) {
                this.deviceSlotImage = value;
                this.photosLoader.setItems(value.photos)
            }
        })
    }

    approveImage() {
        this.submitWithConfirmation(`Approve image`, 'Are you sure you want to approve this image?', {
            approved: true,
        })
    }

    sendToScoringQueue() {
        this.submitWithConfirmation(`Scoring image`, 'Are you sure you want to resend this image in the scoring queue?', {
            resendToScore: true
        })
    }

    private submitWithConfirmation(title: string, description: string, data: Partial<DeviceSlotImageMutationInput>) {
        this.svcLayout.onConfirmed(title, description).pipe(
            flatMap(value => {
                data.id = this.deviceSlotImage.id
                return this.svcApi.mutateDeviceSlotImage(MutationOperation.Update, data)

            })
        ).subscribe(value => {
            if (value.success) {
                this.deviceSlotImage = value.data;
                this.photosLoader.setItems(this.deviceSlotImage.photos)
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Save error')
            }
        })

    }

}

