import {BaseModel} from '@looma/shared/models/base_model';
import {Retailer} from '@looma/shared/models/retailer'
import {WordFilterable} from '@looma/shared/types/word_filterable';
import {Store} from "@looma/shared/models/store";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

export class StoreGroup extends BaseModel implements WordFilterable {
    id: string;
    retailer: Retailer;
    name: string
    stores: Store[]

    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'retailer', Retailer);
        this.assignTypedArray(input, 'stores', Store);

        return this;
    }
}

export interface StoreGroupMutationInput {
    op: MutationOperation
    id?: number
    name?: string
    storeIds?: number[]
}
