import {BaseModel} from '@looma/shared/models/base_model';
import {Retailer} from '@looma/shared/models/retailer'
import {RemotePlaylist} from '@looma/shared/models/remote_playlist'
import {RetailerCampaignLibraryFile} from '@looma/shared/models/retailer_campaign_library_file'
import {RetailerCampaignRollout} from '@looma/shared/models/retailer_campaign_rollout'

export class RetailerCampaign extends BaseModel {

    id: string;
    status: number;
    name: string;
    retailer_id: number;
    retailer: Retailer;
    start_date: Date;
    end_date: Date;
    playlists: RemotePlaylist[];
    library_files: RetailerCampaignLibraryFile[];
    rollouts: RetailerCampaignRollout[];

    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'retailer', Retailer);
        this.assignTypedArray(input, 'playlists', RemotePlaylist);
        this.assignTypedArray(input, 'library_files', RetailerCampaignLibraryFile);
        this.assignTypedArray(input, 'rollouts', RetailerCampaignRollout);


        this.assignDate(input, 'start_date');

        this.assignDate(input, 'end_date');

        return this;
    }

    get isStartedOrEnded(): boolean {
        switch (this.status) {
            case RetailerCampaignStatus.Started:
            case RetailerCampaignStatus.Ended:
                return true
        }
        return false
    }

    get isEditable(): boolean {
        switch (this.status) {
            case RetailerCampaignStatus.Draft:
            case RetailerCampaignStatus.Planned:
            case RetailerCampaignStatus.Started:
                return true
        }
        return false
    }
}

export enum RetailerCampaignStatus {
    Draft = 0,
    Planned = 1,
    Started = 2,
    Ended = 3,
    Aborted = 4,
}
