<mat-dialog-content style="width:1000px; height: 600px; padding:0; position:relative" fxLayout="column">

    <div fxLayout="column" style="height: 100%">
        <div class="filters mat-elevation-z3">
            <div mat-dialog-title>Select Media Content</div>

            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start stretch">
                <app-autocomplete-search-field fxFlex="25" placeholder="Parent Company"
                                               [showClear]="true"
                                               [value]="selectionOptions.selectedParentBrand"
                                               [disabled]="!this.selectionOptions.parentBrandSelectable"
                                               [searchValues]="selectionOptions?.availableParentBrands"
                                               searchObjectType="ParentBrand"
                                               selectionMode="single"
                                               (valueChanged)="onParentBrandSelected($event)">

                </app-autocomplete-search-field>
                <app-autocomplete-search-field #selectedChildBrandComp fxFlex="25" placeholder="Brand" [showClear]="true"
                                               [value]="selectionOptions.selectedChildBrand" 
                                               [searchCriteria]="selectionOptions.childBrandSearchCriteria"
                                               [searchValues]="selectionOptions?.availableChildBrands"
                                               searchObjectType="BrandPartner" selectionMode="single"
                                               (valueChanged)="onChildBrandSelected($event)">

                </app-autocomplete-search-field>
                <mat-form-field fxFlex="25">
                    <mat-label>Type</mat-label>
                    <mat-select [disabled]="!this.selectionOptions.contentTypeSelectable"
                                [(ngModel)]="selectionOptions.selectedContentType"
                                (selectionChange)="onContentTypeSelected($event)">
                        <mat-option *ngFor="let opt of selectionOptions?.availableMediaContentTypes" [value]="opt" title="{{opt}}">{{opt}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="25">
                    <input matInput autocomplete="off" [formControl]="textSearchControl">
                    <mat-label>Film Name</mat-label>
                </mat-form-field>
            </div>


        </div>
        <app-aspect-ratio-grid fxFlex [dataLoader]="mediaFileLoader" [itemTemplate]="tplMediaContent" style=" flex:1; "
                               [columnsCount]="4" aspectRatio="16:9+50px"></app-aspect-ratio-grid>
    </div>


</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!selectedMediaContent" (click)="handleAction('ok')">Ok
    </button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>

<ng-template #tplMediaContent let-mediaContent="data" let-index="index">
    <div class="video_tile" [class.selected]="selectedMediaContent?.getId() == mediaContent.getId()"
         (click)="selectMediaContent(mediaContent)">
        <media-content-type-icon [mediaContentType]="mediaContent?.contentType" elevation="1"></media-content-type-icon>
        <div class="img" [style.background-image]="getBackgroundThumbImage(mediaContent)">

        </div>
        <div class="bottom" fxLayout="row" fxLayoutAlign="center center">
            <div style="margin-left: 8px; display: grid">
                <div class="ellipsize"
                     title="{{mediaContent.displayName}}">{{mediaContent.displayName}}</div>
                <div class="ellipsize" style="font-size: 0.9em; color: rgba(0,0,0,0.7)"
                     title="{{mediaContent.displayName}}">{{mediaContent.brandPartner?.name}}</div>
            </div>

            <button mat-icon-button (click)="playItem(mediaContent); $event.stopPropagation()">
                <mat-icon>play_arrow</mat-icon>
            </button>
        </div>
    </div>
</ng-template>