import {BaseModel} from '@looma/shared/models/base_model';
import {BehaviorSubject, Observable, of, Subject, Subscription} from 'rxjs';
import {CursorFeed} from '@looma/shared/cursor_feed';
import {skip, takeUntil} from 'rxjs/operators';
import {Utils} from '@looma/shared/utils';


export abstract class BaseCursorLoader<T extends BaseModel> {
    private _version: number;
    private invalidateSubject: BehaviorSubject<number>;
    private mutateItemSubject = new Subject<LoaderItemOp<T>>();
    protected isActive = true;

    static forList<T extends BaseModel>(items?: T[]): ListCursorLoader<T> {
        return new ListCursorLoader(items);
    }

    abstract next(startFrom: string, pageSize?: number): Observable<CursorFeed<T>>

    invalidate(): void {
        this._version = 0;
        this.getInvalidateSubject().next(this.version)
    }

    onInvalidate(): Observable<number> {
        return this.getInvalidateSubject().pipe(
            skip(1) as any
        );
    }

    onItemMutate(): Observable<LoaderItemOp<T>> {
        return this.mutateItemSubject;
    }

    replaceItem(item: T, pos?: number): void {
        this.mutateItemSubject.next({item: item, position: pos || -1, op: CursorItemOpType.Replace})
    }

    removeItem(item: T, pos?: number): void {
        this.mutateItemSubject.next({item: item, position: pos || -1, op: CursorItemOpType.Remove})
    }

    prependItem(item: T): void {
        this.mutateItemSubject.next({item: item, position: 0, op: CursorItemOpType.Add})
    }

    private getInvalidateSubject(): Subject<number> {
        if (!this.invalidateSubject) {
            this.invalidateSubject = new BehaviorSubject<number>(this.version);
        }
        return this.invalidateSubject
    }

    get version(): number {
        if (!this._version) {
            this._version = Date.now();
        }
        return this._version
    }

    setActive(active: boolean) {
        this.isActive = active
    }


}

export class ListCursorLoader<T extends BaseModel> extends BaseCursorLoader<T> {
    private items: T[];

    constructor(items: T[]) {
        super();
        this.setItems(items);
    }

    next(startFrom: string): Observable<CursorFeed<T>> {
        return of(CursorFeed.forElements(this.items));
    }

    setItems(items: T[]) {
        if (!Array.isArray(items)) {
            items = [];
        }
        this.items = items;
        this.invalidate();
    }

}


export class CursorDataProvider<T extends BaseModel> {
    private nextCursor = '';

    private loader: BaseCursorLoader<T>;
    private dataLoadingSub: Subscription;
    private dataLoaderSubs: Subscription[];
    private dataVersion = 0;
    private _loadedData: T[] = [];

    private dataAvailableObs = new BehaviorSubject<CursorDataBatch<T>>(null);

    setLoader(loader: BaseCursorLoader<T>): void {
        Utils.unsubscribe(this.dataLoaderSubs);
        this.loader = loader;
        this.nextCursor = null;

        if (loader) {
            const sub = loader.onInvalidate().subscribe(value => {
                this.nextCursor = null;
                this.dataVersion = 0;
                const triggered = this.next()
            });
            const subs = [sub];
            this.dataLoaderSubs = subs;
        }
    }

    getLoader(): BaseCursorLoader<T> {
        return this.loader;
    }


    next(pageSize?: number): boolean {
        if (this.dataLoadingSub != null && !this.dataLoadingSub.closed) {
            // already loading something
            return false
        }
        if (this.loader == null) {
            return false
        } else if (this.dataVersion === this.loader.version && !this.nextCursor) {
            return false
        }

        const startFrom = this.dataVersion === 0 ? null : this.nextCursor;

        this.dataLoadingSub = this.loader.next(startFrom, pageSize).pipe(
            takeUntil(this.loader.onInvalidate()) as any
        ).subscribe((value: CursorFeed<T>) => {

            this.nextCursor = value.cursor;
            const append = this.dataVersion !== 0;
            this.dataVersion = this.loader.version;
            if (!this.dataAvailableObs.closed) {
                const batchData: CursorDataBatch<T> = {
                    data: value.data,
                    hasNext: !!this.nextCursor,
                    append: append,
                    replace: !append
                };
                if (batchData.replace) {
                    this._loadedData = batchData.data
                } else {
                    this._loadedData = this._loadedData.concat(batchData.data)
                }
                this.dataAvailableObs.next(batchData)
            }
        });
        return true
    }

    cancelLoad() {
        Utils.unsubscribe(this.dataLoadingSub);
    }

    get loadedData(): T[] {
        return this._loadedData;
    }

    get hasNext(): boolean {
        if (!this.loader) {
            return false
        }
        if (this.dataVersion !== this.loader.version) {
            return true
        }
        return !!this.nextCursor
    }

    destroy(): void {
        Utils.unsubscribe(this.dataLoadingSub);
        Utils.unsubscribe(this.dataLoaderSubs);
        this.nextCursor = null;
    }

    onDataAvailable(): Observable<CursorDataBatch<T>> {
        const src = this.dataAvailableObs;
        if (src.value == null) { // meaning we didn't load the first data batch yet
            return src.pipe(
                skip(1)
            )
        }
        return src
    }

    replaceOrPrepend(item: T) {
        const idx = this._loadedData.findIndex(value => value.getId() == item.getId());
        if (idx >= 0) {
            this._loadedData[idx] = item
        } else {
            this._loadedData.unshift(item)
        }
        this.onLocalDataChanged();
    }

    remove(item: T) {
        const idx = this._loadedData.findIndex(value => value.getId() == item.getId());
        if (idx >= 0) {
            this._loadedData.splice(idx, 1);
            this.onLocalDataChanged();
        }
    }

    private onLocalDataChanged() {
        this._loadedData = [].concat(this._loadedData);
        const batchData: CursorDataBatch<T> = {
            data: this._loadedData,
            hasNext: !!this.nextCursor,
            append: false,
            replace: true
        };
        this.dataAvailableObs.next(batchData);
    }

}

export interface CursorDataBatch<T> {
    data: T[]
    hasNext: boolean
    append: boolean
    replace: boolean
}

export interface LoaderItemOp<T> {
    item: T
    position?: number
    op: CursorItemOpType
}

export enum CursorItemOpType {
    Replace, Remove, Add
}
