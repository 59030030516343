export class MutationResponse<T> {
    success: boolean;
    message: string;
    validationErrors: MutationValidationError[]
    triggeredJobId: string;
    data: T;
    dataArray: T[]

    constructor(input?: any) {
        this.validationErrors = [];
        if (input) {
            const target: any = this;
            Object.assign(target, input);
            if (input.hasOwnProperty('validationErrors')) {
                this.assignValidationErrors(input.validationErrors)
            }
        }
    }

    assignValidationErrors(errs: any) {
        this.validationErrors = [];
        if (Array.isArray(errs)) {
            for (const err of errs) {
                if (err && err.hasOwnProperty('name') && err.hasOwnProperty('errors')) {
                    const e = new MutationValidationError();
                    e.name = err.name;
                    if (Array.isArray(err.errors)) {
                        e.errors = Array.from(err.errors).map(value => (value || 'Unexpected error').toString())
                    }
                    this.validationErrors.push(e)
                }
            }
        }
    }
}


export class MutationValidationError {
    name: string
    errors: string[]
}
