import {Component, Inject, OnInit} from '@angular/core';
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LayoutService} from "../../../../services/layout.service";
import {ApiDataService} from "../../../../services/api-data.service";
import {merge, Observable, OperatorFunction} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {NamedValue} from "@looma/shared/types/named_value";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {SearchableField, SearchFieldCriteria} from "@looma/shared/search";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {BrandProduct} from "@looma/shared/models/brand_product";
import {
    BrandPromoCampaign,
    BrandPromoCampaignAttachment,
    BrandPromoCampaignAttachmentType,
    BrandPromoCampaignMutationInput,
    BrandPromoCampaignSlotAssignmentInput
} from "@looma/shared/models/brand_promo_campaign";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {User} from "@looma/shared/models/user";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {map, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {ToastNotificationService} from "../../../../services/toast-notification.service";
import {RunningJobInfo} from "@looma/shared/services/remote-job.service";
import {ProductEditDialogComponent} from "../../../products/product-edit-dialog/product-edit-dialog.component";
import {RetailerPromoPeriodSubmission} from "@looma/shared/models/retailer_promo_period_submission";
import {MatCheckboxChange} from "@angular/material/checkbox";

@LifecycleHooks()
@Component({
    selector: 'app-brand-campaign-edit-dialog',
    templateUrl: './brand-campaign-edit-dialog.component.html',
    styleUrls: ['./brand-campaign-edit-dialog.component.scss']
})
export class BrandCampaignEditDialogComponent extends ModelEditDialog<BrandPromoCampaign> implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<BrandCampaignEditDialogComponent>,
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private svcUpload: UploadService,
        private svcToastNotif: ToastNotificationService,
        @Inject(MAT_DIALOG_DATA) private dialogData: BrandCampaignEditDialogData,
    ) {
        super();

        this.brandCampaign = dialogData.brandCampaign;

        if (this.brandCampaign.isNewRecord()) {
            this.title = 'New Brand Campaign'
            this.brandCampaign.isVisible = true;
            this.brandCampaign.cprEnabled = false;
            this.brandCampaign.executionReportingEnabled = false;
            this.brandCampaign.isPilot = false;
        } else {
            this.title = `Edit ${this.brandCampaign.name}`
        }

        this.selectedProducts = [].concat(this.brandCampaign.featuredProducts || []);
        this.selectedUsers = [].concat(this.brandCampaign.contactUsers || []);
        this.cprEmailRecipients = [].concat(this.brandCampaign.cprRecipientsUsers || []);
        this.attachments = [].concat(this.brandCampaign.attachments || []);

        this.campaignSubmission = dialogData.campaignSubmission

        this.refreshChildBrandSearchCriteria();
        this.refreshProductSearchCriteria()
    }

    BrandPromoCampaignAttachmentType = BrandPromoCampaignAttachmentType
    title: string;
    brandCampaign: BrandPromoCampaign;
    contactsSearchCriteria: SearchFieldCriteria[];
    childBrandSearchCriteria: SearchFieldCriteria[];
    productSearchCriteria: SearchFieldCriteria[];

    public selectedProducts: BrandProduct[];
    public selectedUsers: User[];
    public cprEmailRecipients: User[] = [];

    merchGuideUploadSession: UploadSession
    agreementUploadSession: UploadSession
    storeListUploadSession: UploadSession

    pendingUploadsCount = 0;

    attachments: BrandPromoCampaignAttachment[];
    hasDirtyAttachments = false;

    campaignSubmission: RetailerPromoPeriodSubmission

    static open(svcLayout: LayoutService, brandCampaign: BrandPromoCampaign,
                gqlFields: string, campaignSubmission: RetailerPromoPeriodSubmission = null): Observable<BrandPromoCampaign> {
        const data: BrandCampaignEditDialogData = {
            brandCampaign: brandCampaign,
            gqlFields: gqlFields,
            campaignSubmission: campaignSubmission
        };

        return svcLayout.openDialogForResult(BrandCampaignEditDialogComponent, {
            data: data,
            width: '800px'
        })
    }

    ngOnInit() {
        const merchGuideUploadSession = this.merchGuideUploadSession = this.svcUpload.getUploadSession(`brand_campaign_${BrandPromoCampaignAttachmentType.MerchGuide}`, {
            fileTypes: ['pdf'],
            multiSelection: false,
            recreate: true
        });

        const agreementUploadSession = this.agreementUploadSession = this.svcUpload.getUploadSession(`brand_campaign_${BrandPromoCampaignAttachmentType.Agreement}`, {
            fileTypes: ['pdf'],
            multiSelection: false,
            recreate: true
        });

        const storeListUploadSession = this.storeListUploadSession = this.svcUpload.getUploadSession(`brand_campaign_${BrandPromoCampaignAttachmentType.StoreList}`, {
            fileTypes: ['csv'],
            multiSelection: false,
            recreate: true
        });

        Utils.onDestroy(this).subscribe(value => {
            merchGuideUploadSession.destroy();
            agreementUploadSession.destroy();
            storeListUploadSession.destroy();
        })

        const transformer = (t: BrandPromoCampaignAttachmentType): OperatorFunction<{
            file: UploadedFileInfo,
            job?: RunningJobInfo
        }, UploadedAttachment> => {
            return source => {
                return source.pipe(
                    map(data => {
                        return {
                            attachmentType: t,
                            info: data.file
                        }
                    })
                )
            }
        }

        merge(
            agreementUploadSession.onFileAdded(),
            merchGuideUploadSession.onFileAdded(),
            storeListUploadSession.onFileAdded(),
        ).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.pendingUploadsCount += 1;
        });


        merge(
            this.svcToastNotif.connect(agreementUploadSession, null).pipe(
                transformer(BrandPromoCampaignAttachmentType.Agreement)
            ),
            this.svcToastNotif.connect(merchGuideUploadSession, null).pipe(
                transformer(BrandPromoCampaignAttachmentType.MerchGuide)
            ),
            this.svcToastNotif.connect(storeListUploadSession, null).pipe(
                transformer(BrandPromoCampaignAttachmentType.StoreList)
            ),
        ).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.pendingUploadsCount -= 1;
            this.removeAttachment(value.attachmentType)
            this.attachments.push({
                fileName: value.info.fileName,
                downloadUrl: value.info.downloadUrl,
                attachmentType: value.attachmentType
            });
            this.hasDirtyAttachments = true;
        })

    }

    public get hasUploadsInProgress(): boolean {
        return this.pendingUploadsCount != 0
    }

    onSave(): Observable<MutationResponse<BrandPromoCampaign>> {
        const mutationData: BrandPromoCampaignMutationInput = {
            id: this.brandCampaign.getStringId(),
            name: this.brandCampaign.name.trim(),
            isVisible: this.brandCampaign.isVisible,
            cprEnabled: this.brandCampaign.cprEnabled,
            executionReportingEnabled: this.brandCampaign.executionReportingEnabled,
            isPilot: !!this.brandCampaign.isPilot,
            submissionId: this.campaignSubmission?.id
        }

        if (Array.isArray(this.selectedProducts)) {
            mutationData.featuredProductIds = this.selectedProducts.map(value => value.getStringId())
        }
        if (Array.isArray(this.brandCampaign?.featuredBrands)) {
            mutationData.featuredBrandIds = this.brandCampaign.featuredBrands.map(value => value.getStringId())
        }

        if (Array.isArray(this.selectedUsers)) {
            mutationData.contactUserIds = this.selectedUsers.map(value => value.getStringId())
        }

        if (this.brandCampaign.isNewRecord()) {
            mutationData.brandPartnerId = this.brandCampaign.brandPartner.getStringId()
        }

        if (this.brandCampaign.promoPeriod) {
            mutationData.promoPeriodId = this.brandCampaign.promoPeriod.getStringId()
        }

        if (this.brandCampaign.promoProgram) {
            mutationData.promoProgramId = this.brandCampaign.promoProgram.getStringId()
        }

        if (this.brandCampaign.campaignCostDollars) {
            mutationData.campaignCostDollars = this.brandCampaign.campaignCostDollars
        }

        if (this.brandCampaign.campaignDiscountDollars) {
            mutationData.campaignDiscountDollars = this.brandCampaign.campaignDiscountDollars
        }

        if (this.brandCampaign.cprEnabled) {
            mutationData.cprEnabled = this.brandCampaign.cprEnabled
        }

        if (this.brandCampaign.executionReportingEnabled) {
            mutationData.executionReportingEnabled = this.brandCampaign.executionReportingEnabled
        }

        if (this.brandCampaign.isNewRecord() && Array.isArray(this.brandCampaign.slotAssignments)) {
            mutationData.slotAssignments = this.brandCampaign.slotAssignments.map(value => {

                const d: BrandPromoCampaignSlotAssignmentInput = {
                    deviceSlotSegmentId: value.deviceSlotSegment.getStringId(),
                    slotIndex: value.slotIndex
                }

                return d
            })
        }

        if (Array.isArray(this.cprEmailRecipients)) {
            mutationData.cprRecipientsUserIds = this.cprEmailRecipients.map(value => value.getStringId())
        }

        if (this.hasDirtyAttachments) {
            mutationData.attachments = this.attachments.map(value => {
                return {
                    attachmentType: value.attachmentType,
                    fileName: value.fileName,
                    fileKey: value.downloadUrl
                }
            })
        }

        const op = this.brandCampaign.isNewRecord() ? MutationOperation.Create : MutationOperation.Update;
        return this.svcApi.mutateBrandPromoCampaign(op, mutationData, this.dialogData.gqlFields)
    }

    onParentBrandSelected(value: NamedValue) {
        if (!this.brandCampaign.isNewRecord()) {
            return
        }
        let bp: BrandPartner = null;
        if (value) {
            bp = new BrandPartner();
            bp.id = value.value;
            bp.name = value.name;

            if ((this.brandCampaign.name || '').trim() == '') {
                this.brandCampaign.name = `${bp.name} ${this.brandCampaign.promoPeriod.name}`;
            }
        }
        this.brandCampaign.brandPartner = bp;

        this.refreshChildBrandSearchCriteria();
    }

    onChildBrandSelected(values: NamedValue[]) {
        let bp: BrandPartner = null;
        if (Array.isArray(values)) {
            this.brandCampaign.featuredBrands = values.map(value => {
                bp = new BrandPartner();
                bp.id = value.value;
                bp.name = value.name;
                return bp
            })
        } else {
            this.brandCampaign.featuredBrands = [];
        }

        const featuredBrands = this.brandCampaign.featuredBrands || []
        let bpNames = ''
        for (let i = 0; i < featuredBrands.length; i++) {
            const bp = featuredBrands[i]

            if (bpNames != '') {
                if (i < featuredBrands.length - 1) {
                    bpNames += ', '
                } else if (i < featuredBrands.length) {
                    bpNames += ' & '
                }
            }
            bpNames += bp.name
        }
        this.brandCampaign.name = `${bpNames} ${this.brandCampaign.promoPeriod.name}`

        this.refreshProductSearchCriteria()
    }

    onProductSelectionChanged(value: NamedValue[]) {
        this.selectedProducts = (value || []).map(nv => {
            const p = new BrandProduct();
            p.id = nv.value;
            p.name = nv.getDisplayName();
            return p;
        });
    }

    onUserSelectionChanged(value: NamedValue[]) {
        this.selectedUsers = (value || []).map(nv => {
            const p = new User();
            p.id = nv.value;
            p.display_name = nv.name;
            p.email = nv.hint
            return p;
        });
    }

    addNewProduct() {
        const product = new BrandProduct()

        let brandsEditable = true
        const featuredBrands = this.brandCampaign.featuredBrands || []

        if (featuredBrands.length > 0) {
            product.brand_partner = this.brandCampaign.featuredBrands[0]
            if (featuredBrands.length == 1) {
                brandsEditable = false
            }
        }
        if (this.brandCampaign.retailer) {
            product.retailers = [this.brandCampaign.retailer]
        }

        ProductEditDialogComponent.open(this.svcLayout, {
            product: product,
            brandEditable: brandsEditable
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe((product) => {
            if (product) {
                const sel = [
                    ...this.selectedProducts,
                    product
                ].map(value => NamedValue.from(value))
                this.onProductSelectionChanged(sel)
            }

        })
    }

    private refreshChildBrandSearchCriteria() {
        this.contactsSearchCriteria = null;
        this.childBrandSearchCriteria = null;
        if (this.brandCampaign.brandPartner) {
            this.childBrandSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.ParentId, this.brandCampaign.brandPartner.id).asArray();
            this.contactsSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.BrandId, this.brandCampaign.brandPartner.id).asArray();
        }
    }

    private refreshProductSearchCriteria() {
        this.productSearchCriteria = null
        const featuredBrands = this.brandCampaign.featuredBrands || []
        if (featuredBrands.length) {
            const ids = featuredBrands.map(value => value.id)
            this.productSearchCriteria = SearchFieldCriteria.newInCriteria(SearchableField.BrandId, ids).asArray()
        }
    }

    canSave(): boolean {
        if (this.hasUploadsInProgress) {
            return false;
        }
        if (!this.brandCampaign.brandPartner) {
            return false
        }
        if ((this.brandCampaign.name || '').trim() == '') {
            return false
        }
        return true;
    }

    getAttachment(attachmentType: BrandPromoCampaignAttachmentType): BrandPromoCampaignAttachment {
        return this.attachments.find(value => value.attachmentType == attachmentType)
    }

    deleteAttachment(attachmentType: BrandPromoCampaignAttachmentType) {
        const attachment = this.getAttachment(attachmentType)
        if (attachment) {
            this.svcLayout.onConfirmed('Remote attachment', `Are you sure you want to remove ${attachment.fileName} ?`).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                this.removeAttachment(attachmentType)
            })
        }
    }

    private removeAttachment(attachmentType: BrandPromoCampaignAttachmentType) {
        const idx = this.attachments.indexOf(this.getAttachment(attachmentType))
        if (idx >= 0) {
            this.attachments.splice(idx, 1)
            this.attachments = [].concat(this.attachments)
            this.hasDirtyAttachments = true;
        }
    }

    openFilePicker(attachmentType: BrandPromoCampaignAttachmentType) {
        switch (attachmentType) {
            case BrandPromoCampaignAttachmentType.Agreement:
                this.agreementUploadSession.openPicker();
                break
            case BrandPromoCampaignAttachmentType.MerchGuide:
                this.merchGuideUploadSession.openPicker();
                break
            case BrandPromoCampaignAttachmentType.StoreList:
                this.storeListUploadSession.openPicker();
                break
        }
    }

    onCprEmailRecipientChanged(evt: MatCheckboxChange, user: User) {
        if (evt.checked) {
            this.cprEmailRecipients.push(user);
        } else {
            const index = this.cprEmailRecipients.findIndex(u => u.id === user.id);
            if (index !== -1) {
                this.cprEmailRecipients.splice(index, 1);
            }
        }
    }

    isUserSelectedForCPR(p: User): boolean {
        return this.brandCampaign.cprRecipientsUsers?.some(s => s.id === p.id) || false;
    }
}


export interface BrandCampaignEditDialogData {
    brandCampaign: BrandPromoCampaign
    gqlFields: string
    campaignSubmission: RetailerPromoPeriodSubmission
}


interface UploadedAttachment {
    attachmentType: BrandPromoCampaignAttachmentType
    info: UploadedFileInfo
}


