import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {MediaObserver} from '@angular/flex-layout';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, flatMap, map, take} from 'rxjs/operators';
import {ComponentType} from '@angular/cdk/portal';
import {ImageLoader} from '@looma/shared/image_loader';
import {
    ConfirmDialogComponent,
    ConfirmDialogData
} from '@looma/shared/components/confirm-dialog/confirm-dialog.component';
import {Utils} from '@looma/shared/utils';

export abstract class LoomaLayoutService {

    protected activeSnackbar: MatSnackBarRef<any>;

    protected constructor(
        public mediaObserver: MediaObserver,
        public snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {

    }

    isActiveMediaQuery(mediaQuery: string | string[]) {
        return this.mediaObserver.isActive(mediaQuery)
    }

    onActiveMediaQueryChanged(mediaQuery: string | string[]): Observable<boolean> {
        return this.mediaObserver.asObservable().pipe(
            map(_ => this.mediaObserver.isActive(mediaQuery)),
            distinctUntilChanged()
        )
    }

    showMessage(msg: string, duration: number = 5000): MatSnackBarRef<SimpleSnackBar> {
        if (this.activeSnackbar) {
            this.activeSnackbar.dismiss();
        }
        const snack = this.snackBar.open(msg, null, {duration: duration});
        snack.afterDismissed().subscribe(value => {
            if (snack == this.activeSnackbar) {
                this.activeSnackbar = null
            }
        });
        this.activeSnackbar = snack;
        return snack;
    }

    openDialogForResult(dialogType: ComponentType<any>, config?: MatDialogConfig<any>): Observable<any> {
        return new Observable(subscriber => {

            const dialogConfig = {
                ...{autoFocus: false},
                ...(config || {})
            }

            subscriber.next(this.dialog.open(dialogType, dialogConfig));
            subscriber.complete()
        }).pipe(
            flatMap((dialogRef: MatDialogRef<any, any>) => {
                return dialogRef.afterClosed()
            })
        )
    }

    newImageLoader(cacheSize: number): ImageLoader {
        return new ImageLoader(cacheSize);
    }

    onConfirmed(title: string, description: string): Observable<boolean> {
        return this.confirm(title, description).pipe(
            take(1),
            filter(value => !!value)
        )
    }

    confirm(title: string | ConfirmDialogData, description?: string): Observable<boolean> {
        const dialogConfig = new MatDialogConfig();

        let dialogData: Partial<ConfirmDialogData> = {}
        if (Utils.isString(title) && Utils.isString(description)) {
            dialogData.title = title + '';
            dialogData.message = description;
        } else {
            dialogData = title as ConfirmDialogData;
        }

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = dialogData;


        return new Observable(subscriber => {
            subscriber.next(this.dialog.open(ConfirmDialogComponent, dialogConfig));
            subscriber.complete()
        }).pipe(
            flatMap((dialogRef: MatDialogRef<any, any>) => {
                return dialogRef.afterClosed().pipe(
                    filter(value => {
                        switch (value) {
                            case 'positive':
                            case 'negative':
                                return true;
                            default:
                                return false;
                        }
                    }),
                    map(value => {
                        return value === 'positive'
                    })
                );
            })
        ) as Observable<boolean>;
    }

}

