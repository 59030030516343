import {DeviceUserRight} from "@looma/shared/models/user_right";

export interface DeviceCommand {
    key: string;
    displayMessage: string;
    showInMenu?: boolean
    userRight: string
    confirmationMessage(deviceCount: any): string;
}

const makeMessage: (msgPrefix: string) => (devicesCount: any) => string =
    msgPrefix => {
        return devicesCount => {
            let suffix = '';
            if(typeof devicesCount === 'number'){
                if(devicesCount === 1){
                    suffix = '<strong>one</strong> device';
                }else{
                    let cssClass ='';
                    if(devicesCount > 50){
                        cssClass = 'color-error'
                    }else if(devicesCount > 10){
                        cssClass = 'color-warn'
                    }
                    suffix = `<strong class="${cssClass}">${devicesCount}</strong> devices`;
                }
            }else if(typeof devicesCount === 'string'){
                suffix = devicesCount+'';
            }else{
                suffix = 'this device';
            }
            return msgPrefix+' '+suffix+'?';
        }
    };

export const KioskModeCommand: DeviceCommand = {
    key: 'kiosk_mode_enabled',
    confirmationMessage: makeMessage('Are you sure you want to change kiosk mode on'),
    displayMessage:'Update Kiosk Mode',
    showInMenu: false,
    userRight: DeviceUserRight.SetKioskMode,
};

export const SetPlaylistCommand: DeviceCommand = {
    key: 'set_playlist',
    confirmationMessage: makeMessage('Are you sure you want to change the playlist on'),
    displayMessage:'Set Playlist',
    showInMenu: false,
    userRight: DeviceUserRight.SetPlaylist,
};

export const COMMANDS: DeviceCommand[] = [{
    key: 'app_restart',
    displayMessage: 'Restart Kiosk App',
    confirmationMessage: makeMessage('Are you sure you want to restart Kiosk app on'),
    userRight: DeviceUserRight.RestartKioskApp
}, {
    key: 'restart_remote_service',
    displayMessage: 'Restart Admin App',
    confirmationMessage: makeMessage('Are you sure you want to restart Admin app on'),
    userRight: DeviceUserRight.RestartAdminApp
}, {
    key: 'record_audio',
    displayMessage: 'Record Audio Sample',
    confirmationMessage: makeMessage('Are you sure you want to start recording audio on'),
    userRight: DeviceUserRight.RecordAudio
}, {
    key: 'get_logs',
    displayMessage: 'Get Logs',
    confirmationMessage: makeMessage('Are you sure you want to get the logs from'),
    userRight: DeviceUserRight.GetLogs
}, {
    key: 'reboot',
    displayMessage: 'Reboot',
    confirmationMessage: makeMessage('Are you sure you want to reboot'),
    userRight: DeviceUserRight.Reboot
}, {
    key: 'download_active_playlist',
    confirmationMessage: makeMessage('Are you sure you want to re-download the active playlist on'),
    displayMessage: 'Download Active Playlist',
    userRight: DeviceUserRight.DownloadContent
},{
    key: 'take_screen_photo',
    confirmationMessage: makeMessage('Are you sure you want to take a screenshot on'),
    displayMessage: 'Screenshot',
    userRight: DeviceUserRight.Screenshot
},{
    key: 'take_screen_record',
    confirmationMessage: makeMessage('Are you sure you want to record the display on'),
    displayMessage: 'Screen video record',
    userRight: DeviceUserRight.ScreenVideo
},{
    key: 'take_camera_photo',
    confirmationMessage: makeMessage('Are you sure you want to record audio on'),
    displayMessage: 'Camera Photo',
    userRight: DeviceUserRight.CamPhoto,
},{
    key: 'reset_device_position',
    confirmationMessage: makeMessage('Are you sure you want to reset the position on'),
    displayMessage:'Reset Device Position',
    showInMenu: false,
    userRight: DeviceUserRight.SetAccelerometer,
},{
    key: 'clear_firebase_cache',
    confirmationMessage: makeMessage('Are you sure you want to clear the firebase cache on'),
    displayMessage:'Clear Firebase Cache',
    showInMenu: true,
    userRight: DeviceUserRight.ClearCache,
},{
    key: 'restart_display',
    confirmationMessage: makeMessage('Are you sure you want to restart the display on'),
    displayMessage:'Restart Display',
    userRight: DeviceUserRight.RestartDisplay,
},KioskModeCommand, SetPlaylistCommand];


export function getCommand(key: string): DeviceCommand{
    return COMMANDS.find(value => value.key === key);
}
