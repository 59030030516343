import {Component, Inject, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {LayoutService} from "../../../../services/layout.service";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {Observable, Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SurveyProductEntry, SurveyProductEntryInput} from "@looma/shared/models/survey_product_entry";
import {RetailerPromoPeriodProgramEntry} from "@looma/shared/models/retailer_promo_periods";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";
import {MatSelectChange} from "@angular/material/select";
import {MediaContent, MediaContentType} from "@looma/shared/models/media_content";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {VideoPlayerService} from "../../../../services/video-player.service";
import {
    MediaContentPickerDialogComponent
} from "../../../media-content/media-content-picker-dialog/media-content-picker-dialog.component";
import {map, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../../services/toast-notification.service";
import firebase from 'firebase/compat/app';
import {BrandProduct} from "@looma/shared/models/brand_product";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {SurveyContent, SurveyContentType} from "@looma/shared/models/survey_content";
import {ApiDataService} from "../../../../services/api-data.service";
import {SURVEY_DATA_LIST_QUERY} from "../../../survey/survey-data-list/survey-data-list.component";
import gql from "graphql-tag";

@LifecycleHooks()
@Component({
    selector: 'app-survey-product-edit',
    templateUrl: './survey-product-edit.component.html',
    styleUrls: ['./survey-product-edit.component.scss']
})
export class SurveyProductEditComponent implements OnInit {

    form: FormGroup;
    isSavingData = false;

    contentIndex = new Map<SurveyContentType, SurveyContent[]>()

    segment: DeviceSlotSegment
    programEntry: RetailerPromoPeriodProgramEntry
    surveyProduct: SurveyProductEntry

    availableCampaigns: BrandPromoCampaign[] = []
    selectedCampaign: BrandPromoCampaign
    selectedFeaturedProduct: BrandProduct
    selectedWineType: SurveyContent
    selectedPricingType: SurveyContent
    selectedMediaContents: MediaContent[] = [] as MediaContent[]

    productPlacementImageUrl: string;
    firebaseProductPlacementImageKey: string;

    tastingNotesImageUrl: string;
    firebaseTastingNotesImageKey: string;

    isUploadingImage: boolean;

    private productPlacementToastNotif: ToastNotification;
    private tastingNoteToastNotif: ToastNotification;

    private tastingNoteImageAddedSubscription: Subscription;
    private readonly productPlacementUploadSession: UploadSession;
    private readonly tastingNotesUploadSession: UploadSession;


    static open(
        svcLayout: LayoutService,
        data: SurveyProductEditDialogData,
    ): Observable<SurveyProductEntry> {
        return svcLayout.openDialogForResult(SurveyProductEditComponent, {
            data: data,
            width: '800px'
        })
    }

    constructor(public svcLayout: LayoutService,
                private fb: FormBuilder,
                private sanitization: DomSanitizer,
                private svcApi: ApiDataService,
                private svcUpload: UploadService,
                private svcToastNotif: ToastNotificationService,
                private svcVideoPlayer: VideoPlayerService,
                private dialogRef: MatDialogRef<SurveyProductEditComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: SurveyProductEditDialogData) {

        this.programEntry = dialogData.programEntry
        this.segment = dialogData.deviceSlotSegment
        this.surveyProduct = dialogData.surveyProductEntry != null ? dialogData.surveyProductEntry : new SurveyProductEntry()
        this.selectedCampaign = this.surveyProduct?.brandPromoCampaign
        this.selectedFeaturedProduct = this.surveyProduct?.brandProduct
        this.selectedMediaContents = this.surveyProduct?.mediaContents != null ? this.surveyProduct.mediaContents : [] as MediaContent[]
        this.selectedWineType = this.surveyProduct.wineType
        this.selectedPricingType = this.surveyProduct.winePricing
        this.productPlacementImageUrl = this.surveyProduct.placementImageUrl
        this.tastingNotesImageUrl = this.surveyProduct.tastingNoteImageUrl

        this.loadSurveyContentData()

        this.productPlacementUploadSession = svcUpload.getUploadSession('survey_product_placement_images', {
            fileTypes: ['image'],
            multiSelection: false
        });

        this.tastingNotesUploadSession = svcUpload.getUploadSession('survey_tasting_notes_images', {
            fileTypes: ['image'],
            multiSelection: false
        });

        this.form = fb.group({
            brandCampaign: new FormControl({value: this.selectedCampaign, disabled: false}, [Validators.required]),
            featuredProduct: new FormControl({
                value: this.selectedFeaturedProduct,
                disabled: false
            }, [Validators.required]),
            description: new FormControl({
                value: this.surveyProduct?.description,
                disabled: false
            }, [Validators.required]),
            wineType: new FormControl({
                value: this.selectedWineType,
                disabled: false
            },),
            pricingType: new FormControl({
                value: this.selectedPricingType,
                disabled: false
            }, [Validators.required]),
            tastingNotesName: new FormControl({
                value: this.surveyProduct?.tastingNoteName,
                disabled: false
            }, [Validators.required]),
        })

        this.availableCampaigns = this.programEntry.brandCampaigns.filter(campaign => {
            const segmentIds = campaign.slotAssignments.map(t => t.deviceSlotSegment.id);
            return segmentIds.indexOf(this.segment.id) > -1
        })

        this.initializeProductPlacementUploadSession();
        this.initializeTastingNoteUploadSession();
    }

    private initializeProductPlacementUploadSession() {
        this.productPlacementUploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                const storage = firebase.storage();
                this.firebaseProductPlacementImageKey = file.firebaseKey;

                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.productPlacementImageUrl = url;
                    this.dismissPoductPlacementToastNotif();
                });
            }, error => {
                this.svcLayout.showSnackMessage('Update error');
                this.isSavingData = false;
                this.dismissPoductPlacementToastNotif();
            });

        this.productPlacementUploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                this.productPlacementToastNotif = this.svcToastNotif.create({
                    title: "Product placement image upload",
                    description: 'Uploading',
                    dismissable: false,
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                });
                this.isUploadingImage = true;
            });
    }

    private initializeTastingNoteUploadSession() {
        this.tastingNotesUploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                const storage = firebase.storage();
                this.firebaseTastingNotesImageKey = file.firebaseKey;

                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.tastingNotesImageUrl = url;
                    this.dismissTastingNoteToastNotif();
                });
            }, error => {
                this.svcLayout.showSnackMessage('Update error');
                this.isSavingData = false;
                this.dismissTastingNoteToastNotif();
            });


        this.tastingNoteImageAddedSubscription = this.tastingNotesUploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                this.tastingNoteToastNotif = this.svcToastNotif.create({
                    title: "Tasting note image upload",
                    description: 'Uploading',
                    dismissable: false,
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                });
                this.isUploadingImage = true;
            });
    }

    ngOnInit(): void {
    }

    save() {
        if (!this.form.valid) {
            return;
        }

        if (this.surveyProduct.isNewRecord()) {
            if (this.firebaseTastingNotesImageKey == null) {
                this.svcLayout.showMessage("Tasting notes image not uploaded")
                return
            }

            if (this.productPlacementImageUrl == null) {
                this.svcLayout.showMessage("Product placement image not uploaded")
                return
            }
        }

        this.isSavingData = true
        const data: SurveyProductEntryInput = {
            id: this.surveyProduct.id,
            retailerPromoPeriodId: this.programEntry.promoPeriod.id,
            deviceSlotSegmentId: this.segment.id,
            brandProductId: this.selectedFeaturedProduct.id,
            brandCampaignId: this.selectedCampaign.id,
            description: this.form.get("description").value,
            wineTypeId: this.selectedWineType?.id,
            winePricingId: this.selectedPricingType.id,
            mediaContentIds: this.selectedMediaContents.map(m => m.id),
            tastingNoteName: this.form.get("tastingNotesName").value,
            tastingNoteFirebaseImageKey: this.firebaseTastingNotesImageKey,
            placementImageFirebaseImageKey: this.firebaseProductPlacementImageKey,
        }

        return this.svcApi.rawObjectMutate(MUTATION_UPSERT_SURVEY_PRODUCT, {
            input: data,
        }, SurveyProductEntry)
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(result => {
                this.isSavingData = false
                if (result.success) {
                    this.dialogRef.close(result.data)
                } else {
                    this.svcLayout.showMutationResultMessage(result)
                }
            })
    }


    loadSurveyContentData() {
        this.svcApi.rawQuery({
            query: SURVEY_DATA_LIST_QUERY,
            fetchPolicy: "no-cache",
            variables: {
                criteria: {
                    retailerPromoPeriodId: this.programEntry.promoPeriod.id,
                    retailerPromoProgramId: this.programEntry.promoProgram.id,
                }
            }
        }).pipe(
            map(value => {
                const rawData = value.data['data'] as any;
                return CursorFeed.create(rawData, SurveyContent, 'surveyContents')
            })
        ).subscribe(result => {
            if (result.data) {
                const indexMap = new Map<SurveyContentType, SurveyContent[]>()
                result.data.reduce((map, obj) => {
                    if (map.has(obj.surveyContentType)) {
                        const contents = map.get(obj.surveyContentType)
                        contents.push(obj)
                    } else {
                        map.set(obj.surveyContentType, [obj])
                    }
                    return map;
                }, indexMap)

                this.contentIndex = indexMap
            }
        });
    }

    close(): void {
        this.dialogRef.close()
    }

    onCampaignSelectionChanged(data: MatSelectChange): void {
        this.selectedCampaign = data.value as BrandPromoCampaign;
    }

    sanitizeImageUrl(imgUrl: string): SafeStyle {
        return this.sanitization.bypassSecurityTrustStyle(` url(${imgUrl})`);
    }

    removeMediaContent(i: number) {
    }

    canRemoveMediaContent(i: number) {
        return true;
    }

    playItem(content: MediaContent) {
        this.svcVideoPlayer.playContent(content);
    }

    addItem() {
        MediaContentPickerDialogComponent.open(this.svcLayout, {
            contentTypes: [MediaContentType.AncillaryContent, MediaContentType.Film, MediaContentType.SplashScreen],
            parentBrands: [this.selectedCampaign.brandPartner]
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(newMediaContent => {
            if (!newMediaContent) {
                return;
            }
            const mediaContents = this.selectedMediaContents;
            const existing = mediaContents.find(mc => mc.getId() == newMediaContent.getId());
            if (!existing) {
                let insertIndex = mediaContents.length;
                if (newMediaContent.contentType == MediaContentType.SplashScreen) {
                    insertIndex = 0;
                    for (let i = 0; i < mediaContents.length; i++) {
                        if (mediaContents[i].contentType == MediaContentType.SplashScreen) {
                            insertIndex = i + 1;
                        }
                    }
                }

                mediaContents.splice(insertIndex, 0, newMediaContent)
                this.selectedMediaContents = [].concat(mediaContents);
            }
        })
    }

    addTastingNotesImage() {
        this.tastingNotesUploadSession.openPicker()
    }

    addProductPlacementImage() {
        this.productPlacementUploadSession.openPicker()
    }

    private dismissPoductPlacementToastNotif(): void {
        if (this.productPlacementToastNotif) {
            this.productPlacementToastNotif.dismiss();
            this.productPlacementToastNotif = null;
        }
        this.isUploadingImage = false;
    }

    private dismissTastingNoteToastNotif(): void {
        if (this.tastingNoteToastNotif) {
            this.tastingNoteToastNotif.dismiss();
            this.tastingNoteToastNotif = null;
        }
        this.isUploadingImage = false;
    }

    isSameObj(a: any, b: any): boolean {
        return (a && b) && a.id === b.id;
    }

    onFeaturedProductChanged(data: MatSelectChange) {
        const selectedProduct = this.selectedFeaturedProduct = data.value as BrandProduct;

        if (this.surveyProduct.isNewRecord()) {
            this.form.disable()

            this.svcApi.rawQuery({
                query: QUERY_SEARCH_SURVEY_PRODUCT,
                variables: {
                    brandProductID: selectedProduct.id,
                    retailerID: this.segment.promoPeriod.retailer.id,
                }
            }).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                this.form.enable()
                const prevSurveyProduct = Utils.getNestedTypedObject(value, SurveyProductEntry, 'data', 'findSurveyProduct')
                if (prevSurveyProduct) {
                    this.svcLayout.onConfirmed('Copy details', 'Do you want to copy the product details from the previous promo period?').pipe(
                        takeUntil(Utils.onDestroy(this))
                    ).subscribe(value => {
                        this.form.get("description").setValue(prevSurveyProduct.description)
                        this.form.get("tastingNotesName").setValue(prevSurveyProduct.tastingNoteName)

                        this.firebaseTastingNotesImageKey = null
                        this.tastingNotesImageUrl = null
                        if (prevSurveyProduct.tastingNoteImageKey) {
                            this.firebaseTastingNotesImageKey = prevSurveyProduct.tastingNoteImageKey
                            this.tastingNotesImageUrl = prevSurveyProduct.tastingNoteImageUrl
                        }

                        // this.productPlacementImageUrl = null
                        // this.firebaseProductPlacementImageKey = null
                        // if (prevSurveyProduct.placementImageKey) {
                        //     this.firebaseProductPlacementImageKey = prevSurveyProduct.placementImageKey
                        //     this.productPlacementImageUrl = prevSurveyProduct.placementImageUrl
                        // }

                    })
                }
            })

            const prevSurveyProduct = null

            
        }
    }

    onWineTypeChanged(data: MatSelectChange) {
        this.selectedWineType = data.value as SurveyContent
    }

    getWineTypes(): SurveyContent[] {
        return this.contentIndex.get(SurveyContentType.WineType);
    }

    getWinePricing(): SurveyContent[] {
        return this.contentIndex.get(SurveyContentType.WinePricing);
    }

    onPricingTypeChanged(data: MatSelectChange) {
        this.selectedPricingType = data.value as SurveyContent
    }
}

const SURVEY_PRODUCT_FIELDS = `
id
description
placementImageUrl
placementImageKey
tastingNoteName
tastingNoteImageUrl
tastingNoteImageKey
wineType {
    id
    displayName
    description
    imageUrl
}
winePricing {
    id
    displayName
    description
    imageUrl
}
brandProduct {
    id
    name
    image_url
    upc_code
    brand_partner {
        id
        name
    }
}
mediaContents {
    id
    displayName
    defaultMediaContentVersion {
        id
        fileName
        thumbnail
        processedUrl
        originalUrl
    }
}
brandPromoCampaign {
    id
    name
    featuredProducts {
        id
        name
    }
}
`

export const MUTATION_UPSERT_SURVEY_PRODUCT = gql`
    mutation upsertSurveyProduct($input: SurveyProductInput!) {
        upsertSurveyProduct(input: $input) {
            success
            message
            validationErrors {
                name
                errors
            }
            surveyProductEntry {
                ${SURVEY_PRODUCT_FIELDS}
            }
        }
    }
`;

const QUERY_SEARCH_SURVEY_PRODUCT = gql`
    query findSurveyProduct($brandProductID:ID!, $retailerID: ID!) {
        findSurveyProduct(brandProductID:$brandProductID, retailerID:$retailerID){
            ${SURVEY_PRODUCT_FIELDS}
        }
    }
`

export interface SurveyProductEditDialogData {
    deviceSlotSegment: DeviceSlotSegment
    programEntry: RetailerPromoPeriodProgramEntry
    surveyProductEntry?: SurveyProductEntry
}
