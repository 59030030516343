import {BaseModel} from "@looma/shared/models/base_model";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {MediaContent, MediaContentType} from "@looma/shared/models/media_content";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";

let sEmptyVariant: PromoPlaylistMediaBundle;

export class PromoPlaylistMediaBundle extends BaseModel {

    static get EMPTY(): PromoPlaylistMediaBundle {
        if (!sEmptyVariant) {
            sEmptyVariant = new PromoPlaylistMediaBundle();
            sEmptyVariant.id = '-1';
            sEmptyVariant.name = 'Empty Media Content';
        }
        return sEmptyVariant
    }

    id: string;
    name: string;
    promoPeriod: RetailerPromoPeriod
    brandPartner: BrandPartner
    promoProgram: RetailerPromoProgram

    mediaContents: MediaContent[];
    
    get primaryMediaContent(): MediaContent{
        return this.mediaContents.find(value => value.contentType == MediaContentType.Film);
    }

    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'promoPeriod', RetailerPromoPeriod);
        this.assignTypedObject(input, 'brandPartner', BrandPartner);
        this.assignTypedObject(input, 'promoProgram', RetailerPromoProgram);
        this.assignTypedArray(input, 'mediaContents', MediaContent);

        return this;
    }

    isEmptyMediaContent(): boolean {
        return this.id == PromoPlaylistMediaBundle.EMPTY.id;
    }
    
}

export interface PromoPlaylistMediaBundleMutationInput {
    id: string;
    name: string;
    promoPeriodId: string;
    brandPartnerId: string;
    promoProgramId: string;
    mediaContentIds: string[];
}
