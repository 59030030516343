<div style="position: relative; height: 100%; background: white" fxLayout="row" fxLayoutAlign="stretch"
     fxLayoutGap="16">
    <div fxFlex style="overflow: auto; background: #ccc">

        <div *ngFor="let segment of promoPeriodProgramEntry.deviceSlotSegments">
            <div class="section_header mat-elevation-z3" fxLayout="row" fxLayoutAlign="space-between center">
                <div class=" mat-subheading-2" style="margin: 0; font-weight: 500">{{segment.name}} - <span
                    class="playlist_name">{{getPrimaryPlaylist(segment)?.name}}</span></div>
                <mat-menu #optionsMenu="matMenu">
                    <button *ngIf="getPlaylistRegistry(segment)?.slots?.length" mat-menu-item
                            (click)="addNewPlaylist(segment)">New Playlist
                    </button>
                    <button mat-menu-item (click)="goToSlotSubgroups(segment)">Manage Slot Subgroups</button>
                    <button mat-menu-item *ngIf="getPrimaryPlaylist(segment)"
                            (click)="changePlaylistName(getPrimaryPlaylist(segment))">Edit Playlist Name
                    </button>
                </mat-menu>

                <button disableRipple mat-icon-button [matMenuTriggerFor]="optionsMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
            <div class="section_content">
                <ng-container *ngIf="getPlaylistRegistry(segment); let registry">
                    <ng-container [ngSwitch]="registry.campaignPlaylists?.length || 0">
                        <ng-container *ngSwitchCase="0">
                            <div fxLayoutAlign="center center" class="playlists_body no_slots_warn">
                                <ng-container
                                    *ngTemplateOutlet="warn; context:{title:'No brand slots available for this playlist', style:'error'}"></ng-container>
                            </div>
                        </ng-container>
                        <div *ngSwitchCase="1">
                            <div class="playlists_body">
                                <ng-container
                                    *ngTemplateOutlet="tplPlaylist; context:{playlist:registry.campaignPlaylists[0]}"></ng-container>
                            </div>

                        </div>
                        <div *ngSwitchDefault class="playlists_body">
                            <mat-accordion multi>
                                <mat-expansion-panel hideToggle
                                                     (expandedChange)="registry.setPlaylistExpanded(playlist, $event)"
                                                     *ngFor=" let playlist of registry.campaignPlaylists; let index = index"
                                                     [class.default_playlist]="playlist.playlistAssignment.isDefault"
                                                     expanded="{{registry.isPlaylistExpanded(playlist)}}">
                                    <mat-expansion-panel-header>
                                        <div class="panel_header"
                                             fxLayout="row" fxLayoutAlign="space-between center">
                                            <span>
                                                <mat-icon class="icon" title="Default Playlist">new_releases</mat-icon>
                                                <span class="title ">{{playlist?.name}}</span>
                                            </span>

                                        </div>
                                        <div>

                                            <mat-menu #playlistMenu="matMenu">
                                                <button mat-menu-item
                                                        (click)="changePlaylistName(playlist)">Edit Name
                                                </button>
                                                <button mat-menu-item
                                                        [disabled]="playlist.playlistAssignment.isNewRecord()"
                                                        (click)="makeDefaultPlaylist(playlist)">Make Default
                                                </button>
                                                <button mat-menu-item
                                                        (click)="addNewPlaylist(segment)">Clone
                                                </button>
                                                <button mat-menu-item [disabled]="!playlist.canBeReset"
                                                        (click)="resetPlaylists(playlist)">Reset
                                                </button>
                                                <button mat-menu-item [disabled]="!playlist.canBeDeleted"
                                                        (click)="deletePlaylist(playlist)">Delete
                                                </button>
                                            </mat-menu>

                                            <button disableRipple mat-icon-button [matMenuTriggerFor]="playlistMenu"
                                                    (click)="$event.stopPropagation()">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>

                                        </div>

                                    </mat-expansion-panel-header>
                                    <ng-container
                                        *ngTemplateOutlet="tplPlaylist; context:{playlist:playlist}"></ng-container>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </ng-container>


                </ng-container>
            </div>
        </div>

    </div>
    <div fxFlex="500px" style="display: flex; position: relative; flex-direction: column" class="mat-elevation-z3">

        <looma-grid [dataSource]="playlistMediaBundleDataSource"
                    [dragDropController]="playlistMediaBundleDataSource.ddController" fxFlex>
            <ng-template emptyDataContent>
                <div style="text-align: center; padding:16px">No items</div>
            </ng-template>

            <ng-template gridColumnHeader="name">
                <span style="color: black; font-size:14px">Playlist Items</span>
            </ng-template>

            <ng-template gridColumn="name" let-mediaBundle="data">
                <div>
                    <div>{{mediaBundle.name}}</div>
                    <div style="font-size: 0.9em; color: rgba(0,0,0,0.7)">{{mediaBundle.brandPartner?.name}}</div>
                </div>
            </ng-template>

            <ng-template gridColumn="content_types" let-mediaBundle="data">
                <media-content-type-icon *ngFor="let mediaContent of mediaBundle.mediaContents"
                                         [title]="mediaContent.displayName"
                                         [mediaContentType]="mediaContent.contentType"></media-content-type-icon>
            </ng-template>

            <ng-template gridColumnHeader="row_actions">
                <button mat-mini-fab style="transform: scale(0.7); box-shadow: none" (click)="createMediaBundle()">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-template>

            <ng-template gridColumnHeader="content_types">
                <div style="display: flex; justify-content: end; width:100%">
                    <button mat-icon-button title="Add Splash Screen" (click)="massAddPlaylistItem()">
                        <mat-icon>add_to_photos</mat-icon>
                    </button>
                </div>

            </ng-template>

            <ng-template rowActions="2" let-mediaBundle="data">
                <button mat-icon-button [matMenuTriggerFor]="mediaBundleMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #mediaBundleMenu="matMenu">
                    <button mat-menu-item (click)="editMediaBundle(mediaBundle)">Edit</button>
                    <button mat-menu-item (click)="deleteMediaBundle(mediaBundle)">Delete</button>
                    <button mat-menu-item (click)="assignToEmptySlots(mediaBundle)">Assign to empty slots</button>
                </mat-menu>
            </ng-template>
        </looma-grid>

    </div>
</div>


<mat-menu #brandCampaignOptionsMenu="matMenu">
    <ng-template matMenuContent let-playbackEntry="playbackEntry">
        <button mat-menu-item *ngFor="let action of getMenuOptionsForPlaybackEntry(playbackEntry)"
                (click)="performMenuActionForPlaybackEntry(action, playbackEntry)">{{action}}</button>
    </ng-template>
</mat-menu>

<ng-template #warn let-title="title" let-subtitle="subtitle" let-style="style">
    <div class="playlist_warn"
         [class.with_error]="style=='error'"
         [class.with_warn]="style=='warn'"
         [class.with_info]="style=='info'"
         style="flex: 0 0 auto"
         fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon *ngIf="style=='error'">warning</mat-icon>
        <mat-icon *ngIf="style=='warn'">warning</mat-icon>
        <mat-icon *ngIf="style=='info'">info</mat-icon>
        <div style="flex: 1">
            <div class="title">{{title}}</div>
            <div class="subtitle" *ngIf="subtitle">{{subtitle}}</div>
        </div>

    </div>

</ng-template>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px">
        <button mat-raised-button color="warn" (click)="savePlaylists()" [disabled]="isBusy || !hasDirtyAssignments">
            Save Playlists
        </button>
        <button mat-raised-button color="warn" (click)="publishPlaylists()"
                [disabled]="isBusy || hasDirtyAssignments || !hasUnpublishedPlaylists">Publish Valid Playlists
        </button>
        <button mat-raised-button color="warn" (click)="activatePlaylists()"
                [disabled]="isBusy || hasDirtyAssignments || !hasInactivatedPlaylists">Activate Valid Playlists
        </button>
    </div>

</ng-template>

<ng-template #tplPlaylist let-playlist="playlist">
    <div class="playback_loop">
        <div *ngFor="let loop of playlist.loops; let index=index" cdkDropList
             (cdkDropListDropped)="playlist.changeItemPosition(loop, $event.previousIndex, $event.currentIndex)">
            <div *ngIf="index == 0" class="playlist_actions">

                <div *ngIf="playlist.activeMediaPlaylistVersion" fxLayoutAlign="space-between center">
                    <ng-container
                        *ngTemplateOutlet="warn; context:{title:'Active version', subtitle:playlist.activeMediaPlaylistVersion.firebasePath, style:'info'}"></ng-container>
                    <button disableRipple mat-button
                            (click)="viewPlaylistDetails(playlist.mediaPlaylist, playlist.activeMediaPlaylistVersion)">
                        View
                    </button>
                </div>

                <div *ngIf="playlist.hasUnassignedSlots" fxLayoutAlign="space-between center">
                    <ng-container
                        *ngTemplateOutlet="warn; context:{title:'Some entries are missing media files', style:'error'}"></ng-container>
                </div>

                <div *ngIf="playlist.hasChanges" fxLayoutAlign="space-between center">
                    <div *ngIf="playlist.playlistCreated">
                        <ng-container
                            *ngTemplateOutlet="warn; context:{title:'This playlist has unsaved changed', style:'warn'}"></ng-container>
                    </div>
                    <div *ngIf="!playlist.playlistCreated">
                        <ng-container style="flex: 1"
                                      *ngTemplateOutlet="warn; context:{title:'Playlist not yet created', style:'info'}"></ng-container>
                    </div>

                    <div>
                        <button disableRipple mat-flat-button color="accent" (click)="savePlaylists(playlist)"
                                [disabled]="isBusy">{{playlist.playlistCreated ? 'Save' : 'Create Playlist'}}</button>
                        <button disableRipple mat-button *ngIf="playlist.canRevertChanges" [disabled]="isBusy"
                                (click)="playlist.applyDefaultValues()">Revert
                        </button>
                    </div>
                </div>

                <div *ngIf="playlist.draftMediaPlaylistVersion && !playlist.hasChanges">
                    <div *ngIf="playlist.draftNeedsRepublish" fxLayoutAlign="space-between center">
                        <ng-container
                            *ngTemplateOutlet="warn; context:{title:'Draft needs to be republished', style:'warn'}"></ng-container>
                        <button disableRipple mat-flat-button (click)="publishPlaylists(playlist)" [disabled]="isBusy"
                                color="accent">Republish Now
                        </button>
                    </div>
                    <div *ngIf="playlist.draftCanActivate" fxLayoutAlign="space-between center">
                        <ng-container
                            *ngTemplateOutlet="warn; context:{title:'Inactive draft can be activated', subtitle:playlist.draftMediaPlaylistVersion.firebasePath, style:'warn'}"></ng-container>
                        <div>
                            <button disableRipple mat-raised-button color="warn" (click)="activatePlaylists(playlist)"
                                    [disabled]="isBusy">Activate
                            </button>
                            <button disableRipple mat-button
                                    (click)="viewPlaylistDetails(playlist.mediaPlaylist, playlist.draftMediaPlaylistVersion)">
                                View
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="playlist.draftNeedsPublish" fxLayoutAlign="space-between center">
                    <ng-container
                        *ngTemplateOutlet="warn; context:{title:'Draft not published', style:'warn'}"></ng-container>
                    <button mat-flat-button (click)="publishPlaylists(playlist)" [disabled]="isBusy"
                            color="accent">Publish Now
                    </button>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span class="loop_name">
                                <span *ngIf="playlist.loops.length > 1">Playlist Loop {{index + 1}}</span>
                            </span>

                <div class="loop_actions">
                    <button color="accent" title="Clone Loop" mat-icon-button
                            (click)="playlist.cloneLoop(loop, true)">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                    <button color="warn" title="Remove Loop" mat-icon-button
                            [disabled]="!playlist.canRemoveLoop(loop)"
                            (click)="playlist.removeLoop(loop, true)">
                        <mat-icon>remove_circle</mat-icon>
                    </button>
                </div>
            </div>

            <div class="mat-elevation-z3 playback_entry"
                 *ngFor="let playbackEntry of loop.entries; let i = index;"
                 cdkDrag
                 cdkDropList
                 (cdkDragDropped)="onDrop($event)"
                 cdkDragLockAxis="y"
                 fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="8px">
                <div class="drag_placeholder" *cdkDragPlaceholder></div>
                <span class="drag_handle"
                      [style.background-color]="getAssignedColor(playbackEntry.brandCampaign)"
                      cdkDragHandle>{{i + 1}}</span>

                <div fxFlex>
                    <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="8px">
                        <!--<mat-icon >drag_indicator</mat-icon>-->

                        <span class="h3">{{playbackEntry.brandCampaign?.brandPartner?.name}} </span>
                        <span style="color:rgba(0,0,0,0.5); font-size:13px"
                              fxFlex>{{playbackEntry.brandCampaign?.name}}</span>
                    </div>
                    <span *ngIf="!playbackEntry.mediaBundle"
                          style="color:#dd2c00;  font-weight: 500; font-size: 13px">No Media Content</span>
                    <span *ngIf="playbackEntry?.mediaBundle?.isEmptyMediaContent()"
                          style="color:#ff8f00; font-weight: 500; font-size: 13px">Empty Media File</span>
                    <div fxLayout="row" fxLayoutAlign="start center"
                         *ngIf="playbackEntry.mediaBundle && !playbackEntry.mediaBundle.isEmptyMediaContent()">
                        <span
                            style="background: rgba(0,0,0,0.2); padding: 2px 8px; border-radius: 5px;">{{playbackEntry.mediaBundle.name}}</span>
                        <mat-icon style="cursor: pointer;" (click)="playFile(playbackEntry.mediaBundle)">play_arrow
                        </mat-icon>
                    </div>
                </div>

                <button mat-icon-button [matMenuTriggerFor]="brandCampaignOptionsMenu"
                        [matMenuTriggerData]="{playbackEntry: playbackEntry}">
                    <mat-icon>more_vert</mat-icon>
                </button>

            </div>

        </div>

    </div>
</ng-template>