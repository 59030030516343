import {BaseModel} from '@looma/shared/models/base_model';

export class Location extends BaseModel {

    id: string;
    location_name: string;
    street_address: string;
    city: string;
    state: string;
    country_code: string;
    phone_number: string;
    zip_code: string;
    zip_code_extended: string;
    timezone_id: string;
    geo_point: number[];

    public prettyPrint(): string {
        return [this.street_address, this.city, this.state, this.country_code].filter(value => value !== null && value !== undefined && value !== '')
            .join(', ')
    }
    
    get fullAddress(): string {
        return this.prettyPrint()
    }
}

export interface LocationInput {
    city?: string
    state?: string
    countryCode?: string
    streetAddress?: string
    zipCode?: string
    lat?: number
    lng?: number
}
