import {Injectable} from '@angular/core';
import gql from "graphql-tag";
import {StoreGroupMutationInput} from "@looma/shared/models/store_group";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {Retailer} from "@looma/shared/models/retailer";
import {map, takeUntil, tap} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {ApiDataService} from "../../../services/api-data.service";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";

export type StoreGroupAssignmentViewType = 'list' | 'map'

@Injectable({
    providedIn: 'root'
})
export class StoreGroupAssignmentService {

    private saveSub: Subscription
    public retailer: Retailer

    private viewTypeSub = new BehaviorSubject<StoreGroupAssignmentViewType>('list')

    constructor(
        private svcApi: ApiDataService
    ) {
    }

    get activeViewType(): StoreGroupAssignmentViewType {
        return this.viewTypeSub.value
    }

    setActiveViewType(vt: StoreGroupAssignmentViewType) {
        if (this.activeViewType != vt) {
            this.viewTypeSub.next(vt)
        }
    }

    onViewTypeChanged(): Observable<StoreGroupAssignmentViewType> {
        return this.viewTypeSub
    }

    saveChanges(data: StoreGroupMutationInput[]): Observable<MutationResponse<Retailer>> {
        return this.svcApi.rawObjectMutate(MUTATION_UPDATE_STORE_GROUPS, {
            retailerId: this.retailer.id,
            data: data
        }, Retailer).pipe(
            tap(value => {
                if (value.success) {
                    this.setRetailerData(value.data)
                }
            })
        )
    }

    getRetailerData(retailerId: string) {
        if (this.retailer?.getStringId() == retailerId) {
            return of(this.retailer)
        }
        this.retailer = null
        return this.svcApi.rawQuery({
            query: QUERY_FETCH_STORE_GROUPS,
            variables: {
                retailerId: retailerId
            }
        }).pipe(
            map(value => {
                const retailers = Utils.getNestedTypedArray(value, Retailer, 'data', 'retailers_feed', "retailers")
                return retailers[0]
            }),
            tap(value => {
                this.setRetailerData(value)
            })
        )
    }

    private setRetailerData(retailer) {
        this.retailer = retailer
    }

}


const RETAILER_WITH_STORE_GROUPS_FIELDS = `
id
retailer_name
storeGroups(includeUnassignedGroups: true) {
    id
    name
    stores {
      id
      retailer_store_num
      location {
        geo_point
        city
        state
        country_code
        zip_code
        street_address
      }
    }
}
`

const QUERY_FETCH_STORE_GROUPS = gql`
    query ($retailerId: ID!) {
        retailers_feed(filter: {id: $retailerId}) {
            retailers {
                ${RETAILER_WITH_STORE_GROUPS_FIELDS}
            }
        }
    }
`

const MUTATION_UPDATE_STORE_GROUPS = gql`
    mutation($retailerId: ID!, $data: [StoreGroupMutationInput!]!){
        mutateStoreGroups(retailerId:$retailerId, data: $data){
            success
            message
            validationErrors{
                fieldId
                name
                errors
            }
            retailer{
                ${RETAILER_WITH_STORE_GROUPS_FIELDS}
            }
        }
    }
`

