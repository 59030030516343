<div id="main">
    <div id="container-1" class="container">
        <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        <div id="container-2" class="container">
            <div id="container-3" class="container">
                <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                <app-content></app-content>
            </div>
        </div>
    </div>
</div>

<ng-template #toolbar>
    <toolbar style="background-color: #fff;" *ngIf="!fuseConfig.layout.toolbar.hidden"></toolbar>
</ng-template>


<ng-template #leftNavbar>
    <app-sidebar *ngIf="!fuseConfig.layout.navbar.hidden" name="navbar" class="navbar-app-sidebar class"
                 lockedOpen="xl">
        <navbar class="left-navbar"></navbar>
    </app-sidebar>
    <div class="notifications-container " fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around end">
        <app-toast-notifications></app-toast-notifications>
    </div>
</ng-template>
