import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {takeUntil} from 'rxjs/operators';


import {MediaMatchService} from '../../../services/media-match.service';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {User} from '@looma/shared/models/user';
import {AppConfigService} from '../../../services/config.service';
import {AppSidebarService} from '../sidebar/sidebar.service';
import {Utils} from '@looma/shared/utils';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';
import {Router} from '@angular/router';

@LifecycleHooks()
@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy{
    navigation: any;
    currentUser: User;

    constructor(
        private svcAppConfig: AppConfigService,
        private sidebarService: AppSidebarService,
        private authService: LoomaAuthService,
        private router: Router,
        private mediaMatchService: MediaMatchService,
    ){

        authService.onSessionChanged().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.currentUser = value?.user;
        });
    }

    ngOnInit(): void{
        this.mediaMatchService.onActiveChanged('xl').pipe(
            takeUntil(Utils.onDestroy(this)),
        ).subscribe(value => {
            this.setSidebarOpened(value)
        });


    }

    ngOnDestroy(): void{
    }

    performLogout(): void{
        this.authService.signOut().subscribe();
    }


    setSidebarOpened(opened: boolean): void{
        const sidebar = this.sidebarService.getSidebar('navbar');
        if(sidebar){
            if(opened){
                sidebar.open()
            }else{
                sidebar.close()
            }
        }
    }


}
