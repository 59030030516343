import {BaseModel} from '@looma/shared/models/base_model';
import {DeviceApp} from '@looma/shared/models/device_app'


export class RemoteAppInfo extends BaseModel {
    device_app_id: number;
    version_name: string;
    version_code: number;
    device_app: DeviceApp;

    assign(input: any): this {
        super.assign(input);
        this.assignNested(input, 'device_app', new DeviceApp());

        return this;
    }
}
