import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {
    ItemCollection,
    ItemGridController,
    ItemGridDataSource
} from "../../../../layout/components/device-slot-assignment-list/device_slot_assignment_controller";
import {BrandProduct} from "@looma/shared/models/brand_product";
import {ApiDataService} from "../../../../services/api-data.service";
import gql from "graphql-tag";
import {DD_ACCEPT_ANY} from "../../../../layout/components/device-slots-assignment/device-slots-assignment.component";
import {
    QUERY_PROGRAM_SUBMISSION_SCHEDULE,
    RetailerPromoPeriodSubmissionSchedule,
    SubmissionScheduleCriteria
} from "@looma/shared/models/retailer_promo_period_submission_schedule";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {BaseModel} from "@looma/shared/models/base_model";
import {ToastService} from "../../../../../../../loop-dashboard/src/app/shared/services/toast.service";
import {FRAGMENT_API_RESPONSE_FIELDS} from "@looma/shared/services/queries";
import {ConfirmDialogComponent} from "@looma/shared/components/confirm-dialog/confirm-dialog.component";
import {LayoutService} from "../../../../services/layout.service";
import {TemplatePortal} from "@angular/cdk/portal";
import {Overlay} from "@angular/cdk/overlay";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {MatSelectChange} from "@angular/material/select";

@LifecycleHooks()
@Component({
    selector: 'app-wine-recs-campaign-generation',
    templateUrl: './wine-recs-campaign-generation.component.html',
    styleUrls: ['./wine-recs-campaign-generation.component.scss']
})
export class WineRecsCampaignGenerationComponent implements OnInit {

    submissionScheduleId = "112"
    wineRecPromoProgramId = 70

    controller = new ProductController(this.toastSvc);
    isBusy = false;

    defaultCollection: ItemCollection<WineRec>
    otherCollections: ItemCollection<WineRec>[]

    selectedProducts: WineRecSegmentInput[] = []
    retailerPromoPeriod: RetailerPromoPeriod

    pageTitle = "Generate Campaigns: "

    @ViewChild('tplVarietalFilter') tplVarietalFilter: TemplateRef<any>;

    allVarietals: DeviceSlotSegment[] = []
    
    static open(router: Router, promoPeriod: RetailerPromoPeriod) {
        if (!promoPeriod) {
            return
        }
        router.navigate(['promo-schedules/campaign-generation/wine-recs/', promoPeriod.id])
    }

    getDefaultDataSource() {
        return this.controller.defaultItemDataSource as ProductDataSource
    }

    constructor(private activatedRoute: ActivatedRoute,
                private svcApi: ApiDataService,
                private toastSvc: ToastService,
                private changeDetector: ChangeDetectorRef,
                public svcLayout: LayoutService,
                private overlay: Overlay,
                private viewContainerRef: ViewContainerRef,
                private router: Router) {
    }

    ngOnInit(): void {
        const promoPeriodId = this.activatedRoute.snapshot.paramMap.get("retailerPromoPeriodId") || null
        this.loadPromoPeriod(promoPeriodId)
    }

    generateCampaigns() {
        this.svcLayout.openDialogForResult(ConfirmDialogComponent, {
            data: {
                message: `Are you sure you want to generate campaigns for the assigned products? 
                            <br/>The campaigns will be created automatically and will be assigned to the specific segment and promo period.`
            }
        }).subscribe(confirmed => {
            if (confirmed == "positive") {
                this.isBusy = true
                this.svcApi.rawMutate(
                    MUTATION_CAMPAIGN_GENERATION,
                    {
                        promoPeriodID: this.retailerPromoPeriod.id,
                        wineRecSegmentInput: this.selectedProducts,
                    }, 'data'
                ).subscribe(result => {
                    this.isBusy = false
                    if (result.success) {
                        this.toastSvc.openSnackBarWithDuration("OK", "Campaigns generated successfully", 3)
                        this.manageSlotAssignements()
                    } else {
                        this.toastSvc.openSnackBarWithDuration("", result.message, 3)
                    }
                })
            } else {
                return
            }
        })
    }

    loadPromoPeriod(id: string) {
        this.svcApi.rawQuery({
            query: GET_PROMO_PERIOD,
            variables: {
                id: id,
                promoProgramId: this.wineRecPromoProgramId
            }
        }).subscribe(result => {
            if (result?.data) {
                this.retailerPromoPeriod = new RetailerPromoPeriod()
                this.retailerPromoPeriod.assign(result.data["retailerPromoPeriod"])

                this.allVarietals = this.retailerPromoPeriod.deviceSlotSegments

                this.pageTitle = `${this.pageTitle} In-Aisle Wine Recs / ${this.retailerPromoPeriod.name}`

                const criteria: SubmissionScheduleCriteria = {
                    id: this.submissionScheduleId
                };

                this.svcApi.rawQuery({
                    query: QUERY_PROGRAM_SUBMISSION_SCHEDULE, variables: {
                        criteria: criteria
                    }
                }).pipe(takeUntil(Utils.onDestroy(this))).subscribe(result => {
                    const scheduleData = result.data["programSubmissionSchedule"]
                    const schedule = new RetailerPromoPeriodSubmissionSchedule()
                    schedule.assign(scheduleData)

                    const enrolledProducts = schedule.submissions.reduce((acc, submission) => {
                        let productIds = []
                        productIds = productIds.concat(submission.featuredProducts.map(p => p.id))
                        return acc.concat(productIds)
                    }, [])

                    const arrs = schedule.preferredProduct.segments.reduce((acc, segment) => {
                        const products = segment.products;

                        const wineRecs = products.filter(p => {
                            return enrolledProducts.includes(p.id);
                        }).map(p => new WineRec(p, segment.segmentName));

                        return acc.concat(wineRecs);
                    }, []);

                    arrs.sort((a, b) => {
                        if (a.segmentName < b.segmentName) {
                            return -1
                        }

                        if (a.segmentName > b.segmentName) {
                            return 1
                        }

                        const displayNameA = a.getDisplayName();
                        const displayNameB = b.getDisplayName();
                        if (displayNameA < displayNameB) {
                            return -1
                        }

                        if (displayNameA > displayNameB) {
                            return 1
                        }

                        if (a.product.brand_partner.name < b.product.brand_partner.name) {
                            return -1;
                        }
                        if (a.product.brand_partner.name > b.product.brand_partner.name) {
                            return 1;
                        }

                        return 0;
                    });

                    this.defaultCollection = {
                        key: "default",
                        items: [...arrs],
                        label: "Enrolled wines",
                    }

                    this.otherCollections = this.retailerPromoPeriod.deviceSlotSegments.map(s => {
                        return {
                            extras: {id: s.id},
                            key: s.name,
                            items: [],
                            label: s.name,
                        }
                    })

                    this.controller.onAssignmentChanged().pipe(takeUntil(Utils.onDestroy(this))).subscribe(r => {
                        if (r) {
                            this.selectedProducts = []
                            this.controller.itemDataSources.forEach(ds => {
                                const key = ds.dataSourceKey
                                const productIds = ds.data.map(x => x.getData().product.id)
                                this.selectedProducts.push({
                                    segmentName: key,
                                    segmentId: ds.collection.extras["id"],
                                    productIds: productIds,
                                })
                            })

                        }
                    })

                    this.controller.setup(this.changeDetector, this.defaultCollection, this.otherCollections)
                })
            }
        })
    }

    manageSlotAssignements() {
        const extras = {
            queryParams: {
                promo_period: this.retailerPromoPeriod.id
            }
        }
        this.router.navigate([`promo-schedules/${this.retailerPromoPeriod.promoSchedule.retailer.id}-${this.retailerPromoPeriod.promoSchedule.id}-${this.wineRecPromoProgramId}/program-overview`], extras)
    }

    canGenerate(): boolean {
        return this.selectedProducts.some(s => s.productIds.length > 0)
    }

    openFilters(event: MouseEvent) {
        const portal = new TemplatePortal(this.tplVarietalFilter, this.viewContainerRef, {});
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().flexibleConnectedTo(event.target as HTMLElement).withPositions([{
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
            }]),
            hasBackdrop: true,
            backdropClass: 'transparent'
        });
        overlayRef.backdropClick().subscribe(() => overlayRef.detach());
        overlayRef.attach(portal);
    }
}

class ProductDataSource extends ItemGridDataSource<WineRec> {
    selectedVarietals: DeviceSlotSegment[] = []
    
    constructor(controller: ItemGridController<WineRec>, collection: ItemCollection<WineRec>) {
        super(controller, collection, [{
            key: "brand",
            label: "Brand",
            valueReader: value => value.product.brand_partner.name
        }, {
            key: "name",
            label: "Name",
            valueReader: value => value.product.name
        }, {
            key: "upc_code",
            label: "UPC",
            valueReader: value => value.product.upc_code
        }]);
    }

    filter(event: MatSelectChange) {
        const filterKey = "varietal"
        const sel = event.value.length == 0 ? [] : event.value;
        this.selectedVarietals = sel
        if (sel.length == 0) {
            this.removeLocalFilter(filterKey)
        } else {
            this.setLocalFilter(filterKey, data => {
                return sel.map(s => s.name).some(s => s == data.varietal) 
            })
        }
    }
    
    isVarietalSelected(id) {
        return this.selectedVarietals.some(s => s.id == id)
    }
}

class ProductController extends ItemGridController<WineRec> {

    constructor(private toastSvc: ToastService,) {
        super();
    }

    initDataSource(collection: ItemCollection<WineRec>, isDefault: boolean): ItemGridDataSource<WineRec> {
        if (isDefault) {
            return new ProductDataSource(this, {
                label: collection.label,
                key: collection.key,
                items: [].concat(collection.items),
                acceptedItemGroups: DD_ACCEPT_ANY
            });
        }
        return new ProductDataSource(this, collection)
    }


    canMoveItems(items: WineRec[], srcSource: ItemGridDataSource<WineRec>, destSource: ItemGridDataSource<WineRec>): boolean {
        const invalidItems = items ? items.filter(i => i.varietal !== destSource.dataSourceKey) : [];
        if (invalidItems.length > 0) {
            const invalidProductsMessage = invalidItems.map(i => i.product.name).join(', ');
            this.toastSvc.openSnackBarWithDuration('', `Invalid varietal assignment for: ${invalidProductsMessage}`, 3);
            return false;
        }
        return true;
    }
}

const GET_PROMO_PERIOD = gql`
    query retailerPromoPeriod($id: ID!, $promoProgramId: ID!) {
        retailerPromoPeriod(id: $id) {
            id
            name       
            promoSchedule {
                id
                retailer {
                    id
                }
            }           
            deviceSlotSegments(filter:{promoProgramId: $promoProgramId}) {
                id
                name                
            }     
        }
    }
    `;

const MUTATION_CAMPAIGN_GENERATION = gql`
    ${FRAGMENT_API_RESPONSE_FIELDS}
    mutation wineRecCampaignGeneration($promoPeriodID: ID!, $wineRecSegmentInput: [WineRecSegmentInput!]) {
        data: wineRecCampaignGeneration(promoPeriodID: $promoPeriodID, wineRecSegmentInput: $wineRecSegmentInput) {            
            ...ApiResponseFields
        }    
    }
`


class WineRec extends BaseModel {
    constructor(public product: BrandProduct, public varietal: string) {
        super();
    }

    getId(): number {
        return Number(this.product.id);
    }

    getDisplayName(): string {
        return this.varietal + " - Product: " + this.product.name;
    }
}

export interface WineRecSegmentInput {
    segmentName: string
    segmentId: string
    productIds: string[]
}
