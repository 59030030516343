import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {COMMANDS, DeviceCommand} from '@looma/shared/types/device_commands';
import {Subject} from 'rxjs';
import {LoomaAuthService} from "@looma/shared/auth/components/services/looma-auth.service";

@Component({
    selector: 'app-device-command-button',
    templateUrl: './device-command-button.component.html',
    styleUrls: ['./device-command-button.component.scss']
})
export class DeviceCommandButtonComponent implements OnInit, OnDestroy {

    @Input('disabled')
    disabled = false;

    availableDeviceCommands: DeviceCommand[]

    @Output('commandSelected')
    public commandSelected: Subject<DeviceCommand> = new Subject();

    constructor(svcAuth: LoomaAuthService) {
        const currentUser = svcAuth.currentUser
        this.availableDeviceCommands = COMMANDS.filter(cmd => {
            return currentUser.hasRight(cmd.userRight) && cmd.showInMenu !== false
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.commandSelected.complete();
    }

}
