import {Component, Inject, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Device} from "@looma/shared/models/device";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {ApiDataService, DeviceUpdateData} from "../../../../services/api-data.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LayoutService} from "../../../../services/layout.service";
import {Observable} from "rxjs";

@LifecycleHooks()
@Component({
    selector: 'app-device-volume-dialog',
    templateUrl: './device-volume-dialog.component.html',
    styleUrls: ['./device-volume-dialog.component.scss']
})
export class DeviceVolumeDialogComponent implements OnInit {

    device: Device
    isUpdatingVolume = false;
    updateVolumesData: Partial<DeviceUpdateData> = null;

    static open(svcLayout: LayoutService, device: Device): Observable<boolean> {
        const data: DeviceVolumeDialogData = {
            device: device
        }

        return svcLayout.openDialogForResult(DeviceVolumeDialogComponent, {data: data});
    }

    constructor(private svcApi: ApiDataService, private dialogRef: MatDialogRef<DeviceVolumeDialogComponent>,
                private svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) data: DeviceVolumeDialogData) {
        this.device = data.device;
    }

    ngOnInit(): void {
    }

    formatDeviceVolumeLabel(value: number) {
        value = Math.min(100, Math.max(0, Math.round(value * 100)))
        return value + ''
    }

    onDeviceVolumeChanged(value: number) {
        this.updateVolumesData = this.updateVolumesData || {};
        this.updateVolumesData.volume = value;
    }

    submit() {
        if (!this.updateVolumesData) {
            return this.cancel();
        }

        if (this.isUpdatingVolume) {
            return
        }


        this.isUpdatingVolume = true;
        this.svcApi.updateDevice(this.device, this.updateVolumesData).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(resp => {
            if (resp.success) {
                this.dialogRef.close(true)
            } else {
                let msg = "Error updating volume"
                if (resp.errors) {
                    const respErr = Object.values(resp.errors).join(", ")
                    if (respErr != "") {
                        msg = respErr
                    }
                }
                this.svcLayout.showSnackMessage(msg)
            }
            this.isUpdatingVolume = false
        })
    }

    cancel() {
        if (this.isUpdatingVolume) {
            return
        }
        this.dialogRef.close(false)
    }

    get saveEnabled() {
        return !this.isUpdatingVolume && !!this.updateVolumesData;
    }

    get cancelEnabled() {
        return !this.isUpdatingVolume;
    }

}

interface DeviceVolumeDialogData {
    device: Device
}
