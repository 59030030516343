import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {LayoutService} from "../../../services/layout.service";
import {Observable} from "rxjs";
import {MediaContent, MediaContentInput, MediaContentType, StoryTypes} from "@looma/shared/models/media_content";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../services/api-data.service";
import {VideoPlayerService} from "../../../services/video-player.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NamedValue} from "@looma/shared/types/named_value";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import gql from "graphql-tag";
import {MUTATION_RESPONSE_FIELDS} from "../../../services/queries";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {MediaContentVersion} from "@looma/shared/models/media_content_version";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {ToastNotificationService} from "../../../services/toast-notification.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@LifecycleHooks()
@Component({
    selector: 'app-media-content-edit',
    templateUrl: './media-content-edit.component.html',
    styleUrls: ['./media-content-edit.component.scss']
})
export class MediaContentEditComponent implements OnInit, OnDestroy {

    mediaContentTypes = MediaContent.contentTypes
    allSlideTypes = [
        "Awards and Certifications",
        "Better for You",
        "Celebrating Diversity",
        "Crafted for Good",
        "Differentiators and Fun Facts",
        "Editorial",
        "Host with the Most",
        "How to Enjoy",
        "Ingredient Spotlight",
        "Make it Yourself",
        "Maker Spotlight",
        "N/A",
        "Perfect Pairing",
        "Product Spotlight",
        "Protagonist Quote",
        "Rave Reviews",
        "Save Now",
        "Seasonal Inspiration",
        "Story of the Name",
        "Tasting Notes",
        "Trivia",
        "We're From Here"
    ]

    form: FormGroup;
    mediaContent: MediaContent;
    selectedBrandPartnerId: string;
    selectedRetailerId: string;
    isSavingData = false;

    StoryTypes = StoryTypes;

    thumbnailImageGsKey: string
    splashImageGsKey: string
    previewFilmThumbUrl: string
    previewFilmSplashUrl: string
    cleanVersionPreviewThumbUrl: string

    private readonly uploadFilmThumbSession: UploadSession;
    private readonly uploadFilmSplashSession: UploadSession;

    static open(svcLayout: LayoutService, mediaContent: MediaContent): Observable<MediaContent> {
        const data: MediaContentEditData = {
            mediaContent: mediaContent,
        };
        return svcLayout.openDialogForResult(MediaContentEditComponent, {
            data: data,
            width: '1000px'
        })
    }

    constructor(public dialogRef: MatDialogRef<MediaContentEditComponent>,
                public svcLayout: LayoutService,
                private svcApi: ApiDataService,
                private svcVideoPlayer: VideoPlayerService,
                private fb: FormBuilder,
                private svcUpload: UploadService,
                private svcToastNotif: ToastNotificationService,
                @Inject(MAT_DIALOG_DATA) private dialogData: MediaContentEditData) {

        this.mediaContent = dialogData.mediaContent;
        this.selectedBrandPartnerId = this.mediaContent.brandPartner?.id
        this.selectedRetailerId = this.mediaContent.retailer?.id.toString()
        this.previewFilmThumbUrl = this.mediaContent?.defaultMediaContentVersion?.thumbnail
        this.previewFilmSplashUrl = this.mediaContent?.filmSplashImageUrl
        this.cleanVersionPreviewThumbUrl = this.mediaContent?.cleanMediaContentVersion?.thumbnail

        if (this.mediaContent.contentType == MediaContentType.Film) {
            this.form = fb.group({
                mediaContentType: new FormControl({value: this.mediaContent.contentType, disabled: false},),
                displayName: new FormControl(this.mediaContent.displayName, [Validators.required]),
                filmMaker: new FormControl(this.mediaContent.filmMaker),
                vimeoVideoId: new FormControl(this.mediaContent.vimeoVideoId),
                description: new FormControl({value: this.mediaContent.description, disabled: false},),
                storyType: new FormControl({value: this.mediaContent.storyType || 'N/A', disabled: false},),
                featuredProduct: new FormControl({value: this.mediaContent.featuredProduct, disabled: false},),
                storyIqScore: new FormControl({value: this.mediaContent.storyIqScore, disabled: false},),
            });
        } else if (this.mediaContent.contentType == MediaContentType.AncillaryContent) {
            this.form = fb.group({
                mediaContentType: new FormControl({value: this.mediaContent.contentType, disabled: false},),
                displayName: new FormControl(this.mediaContent.displayName, [Validators.required]),
                vimeoVideoId: new FormControl(this.mediaContent.vimeoVideoId),
                description: new FormControl({value: this.mediaContent.description, disabled: false},),
                slideType: new FormControl({
                    value: this.mediaContent.slideType,
                    disabled: false
                }, [Validators.required]),
                featuredProduct: new FormControl({value: this.mediaContent.featuredProduct, disabled: false},),
            });
        } else if (this.mediaContent.contentType == MediaContentType.SplashScreen) {
            this.form = fb.group({
                mediaContentType: new FormControl({value: this.mediaContent.contentType, disabled: false},),
                displayName: new FormControl(this.mediaContent.displayName, [Validators.required]),
                description: new FormControl({value: this.mediaContent.description, disabled: false},),
            });
        }

        this.uploadFilmThumbSession = svcUpload.getUploadSession('uploadFilmThumbSession', {
            fileTypes: ['image'],
            multiSelection: false
        });

        this.uploadFilmSplashSession = svcUpload.getUploadSession('uploadFilmSplashSession', {
            fileTypes: ['image'],
            multiSelection: false
        });


    }

    handleAction(type: string) {
        switch (type) {
            case 'cancel':
                this.dialogRef.close(null);
                break;
            case 'ok':
                this.isSavingData = true
                const obs = this.onSave();
                if (!obs) {
                    return;
                }

                obs.subscribe(value => {
                    this.isSavingData = false
                    if (value.success) {
                        this.dialogRef.close(value.data)
                    } else {
                        this.svcLayout.showSnackMessage(value.message || 'Unexpected error');
                    }
                }, error => {
                    this.svcLayout.showSnackMessage('Api error');
                });
                break;
        }
    }

    private onSave(): Observable<MutationResponse<MediaContent>> {
        const data: MediaContentInput = {
            id: this.mediaContent.id,
            contentType: this.form.get('mediaContentType').value,
            displayName: this.form.get("displayName").value,
            description: this.form.get("description")?.value,
            filmMaker: this.form.get("filmMaker")?.value,
            vimeoVideoId: this.form.get("vimeoVideoId")?.value,
            storyType: this.form.get("storyType")?.value,
            slideType: this.form.get("slideType")?.value,
            storyIqScore: this.form.get("storyIqScore")?.value?.toString(),
            featuredProduct: this.form.get("featuredProduct")?.value,
            brandPartnerId: this.selectedBrandPartnerId,
            retailerId: this.selectedRetailerId,
            thumbnailImageStorageKey: this.thumbnailImageGsKey,
            splashImageStorageKey: this.splashImageGsKey,
            isVisible: this.mediaContent.isVisible,
        }

        return this.svcApi.rawObjectMutate(MUTATION_MEDIA_CONTENT, {
            op: MutationOperation.Update,
            data: data,
        }, MediaContent)
    }

    onBrandSelected(brand: NamedValue): void {
        this.selectedBrandPartnerId = brand?.value;
    }

    onRetailerSelected(retailer: NamedValue): void {
        this.selectedRetailerId = retailer?.value;
    }

    isFilm() {
        return this.mediaContent.contentType == MediaContentType.Film;
    }

    isShopperEdSlide() {
        return this.mediaContent.isShopperEdSlide()
    }

    shouldDisplayRetailer() {
        return this.mediaContent.contentType == MediaContentType.SplashScreen;
    }

    isVimeoDataAvailable(mediaContent: MediaContent): boolean {
        return mediaContent.vimeoVideoId != null;
    }

    deleteVersion(version: MediaContentVersion) {
        return this.svcApi.rawObjectMutate(MUTATION_DELETE_MEDIA_CONTENT_VERSION, {
            mediaContentVersionId: version.id,
        }, MediaContent)
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(result => {
                this.mediaContent = (new MediaContent().assign(result.data))
            })
    }

    setActiveVersion(version: MediaContentVersion) {
        return this.svcApi.rawObjectMutate(MUTATION_ACTIVATE_MEDIA_CONTENT_VERSION, {
            mediaContentVersionId: version.id,
        }, MediaContent)
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(result => {
                this.mediaContent = (new MediaContent().assign(result.data))
                this.previewFilmThumbUrl = this.mediaContent.defaultMediaContentVersion?.thumbnail
                this.cleanVersionPreviewThumbUrl = this.mediaContent.cleanMediaContentVersion?.thumbnail
            })
    }

    isSameObj(a: any, b: any): boolean {
        if (typeof a === "string" && typeof b === "string") {
            return a === b;
        }
        return (a && b) && a.id === b.id;
    }

    ngOnDestroy(): void {
        this.uploadFilmThumbSession.destroy()
        this.uploadFilmSplashSession.destroy()
    }

    ngOnInit(): void {

        this.uploadFilmThumbSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this))
        )
            .subscribe((file: UploadedFileInfo) => {
                this.previewFilmThumbUrl = file.downloadUrl
                this.thumbnailImageGsKey = file.firebaseKey
            }, error => {
                this.svcLayout.showSnackMessage('File upload error');
            });

        this.uploadFilmSplashSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this))
        )
            .subscribe((file: UploadedFileInfo) => {
                this.previewFilmSplashUrl = file.downloadUrl
                this.splashImageGsKey = file.firebaseKey

            }, error => {
                this.svcLayout.showSnackMessage('File upload error');
            });

        this.svcToastNotif.connect(this.uploadFilmThumbSession, null)
        this.svcToastNotif.connect(this.uploadFilmSplashSession, null)
    }

    get hasPendingUploads() {
        return this.uploadFilmThumbSession.hasPendingUploads || this.uploadFilmSplashSession.hasPendingUploads
    }

    openThumbPicker() {
        this.uploadFilmThumbSession.openPicker()
    }

    openSplashImagePicker() {
        this.uploadFilmSplashSession.openPicker()
    }

    isBusy() {
        return this.isSavingData || this.hasPendingUploads
    }

    setVisibility(evt: MatSlideToggleChange) {
        if (evt == null) {
            return
        }
        this.mediaContent.isVisible = evt.checked
    }
}

export interface MediaContentEditData {
    mediaContent: MediaContent
}

export const MUTATION_MEDIA_CONTENT = gql`
    mutation upsertMediaContent($op: MutationOperation!, $data: MediaContentInput! ) {
        upsertMediaContent(op: $op, data: $data) {
            ${MUTATION_RESPONSE_FIELDS}
            mediaContent {
                id
                contentType
                displayName
                description
                filmMaker
                vimeoVideoId
                storyType
                slideType
                storyIqScore
                featuredProduct
                isDraft
                isVisible
                featuredBrandCampaigns {
                    id
                    name
                }
                brandPartner {
                    id
                    name
                }
                retailer {
                    id
                    retailer_name
                }
                externalUrl
                externalThumbUrl
                externalDownloadUrl
                defaultMediaContentVersion {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                mediaContentVersions {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                cleanMediaContentVersion {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                cleanVersions {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
            }
        }
    }
`;

export const MUTATION_DELETE_MEDIA_CONTENT = gql`
    mutation upsertMediaContent($op: MutationOperation!, $data: MediaContentInput! ) {
        upsertMediaContent(op: $op, data: $data) {
            ${MUTATION_RESPONSE_FIELDS}
        }
    }
`;

export const MUTATION_ACTIVATE_MEDIA_CONTENT_VERSION = gql`
    mutation setActiveVersion($mediaContentVersionId: ID!) {
        setActiveVersion(mediaContentVersionId: $mediaContentVersionId) {
            ${MUTATION_RESPONSE_FIELDS}
            mediaContent {
                id
                contentType
                displayName
                description
                filmMaker
                vimeoVideoId
                storyType
                slideType
                featuredProduct
                isDraft
                isVisible
                brandPartner {
                    id
                    name
                }
                retailer {
                    id
                    retailer_name
                }
                externalUrl
                externalThumbUrl
                externalDownloadUrl
                defaultMediaContentVersion {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                cleanMediaContentVersion {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                mediaContentVersions {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                cleanVersions {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
            }
        }
    }
`;


export const MUTATION_DELETE_MEDIA_CONTENT_VERSION = gql`
    mutation deleteVersion($mediaContentVersionId: ID!) {
        deleteVersion(mediaContentVersionId: $mediaContentVersionId) {
            ${MUTATION_RESPONSE_FIELDS}
            mediaContent {
                id
                contentType
                displayName
                description
                filmMaker
                vimeoVideoId
                storyType
                slideType
                featuredProduct
                isDraft
                isVisible
                brandPartner {
                    id
                    name
                }
                retailer {
                    id
                    retailer_name
                }
                externalUrl
                externalThumbUrl
                externalDownloadUrl
                defaultMediaContentVersion {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                cleanMediaContentVersion {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                mediaContentVersions {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
                cleanVersions {
                    id
                    fileName
                    processedUrl
                    thumbnail
                    createdAt
                }
            }
        }
    }
`;