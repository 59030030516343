import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthServiceConfig} from '@looma/shared/auth/components/services/looma-auth.service';
import {LoomaLoginComponent} from '@looma/shared/auth/components/looma-login/looma-login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {LoomaComponentsModule} from '@looma/shared/components/looma-components.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {LoomaLayoutService} from "@looma/shared/services/looma-layout.service";
import {LayoutService} from "../../photo-scoring-dashboard/src/app/shared/layout.service";
import {
    LoomaAccountSetupComponent
} from "@looma/shared/auth/components/looma-account-setup/looma-account-setup.component";
import {HttpClientModule} from "@angular/common/http";

@NgModule({
    declarations: [
        LoomaLoginComponent, LoomaAccountSetupComponent
    ],
    exports: [
        LoomaLoginComponent, LoomaAccountSetupComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        LoomaComponentsModule,
        FlexLayoutModule,
        MatButtonModule,
        HttpClientModule
    ]
})
export class LoomaAuthModule {
    static forRoot(config: Partial<AuthServiceConfig>): ModuleWithProviders<LoomaAuthModule> {
        return {
            ngModule: LoomaAuthModule,
            providers: [
                {provide: AuthServiceConfig, useValue: config},
                {provide: LoomaLayoutService, useExisting: LayoutService, multi: false}
            ]
        };
    }
}

