import {AppNavigation, AppNavigationItem} from '../shared/app-navigation';
import {UserAccessPermission} from "@looma/shared/models/user_role";
import {isMobileBrowser} from "@looma/shared/utils";

export const navSections = {
    Dashboard: {
        id: 'dashboard',
        icon: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: 'dashboard',
    } as AppNavigation,
    DeviceList: {
        id: 'devices-list',
        icon: 'list',
        title: 'List',
        type: 'item',
        url: 'devices',
        permission: UserAccessPermission.Uptime.Devices,
        availableForMobile: true
    } as AppNavigation,
    DeviceCommandResultsList: {
        id: 'command-results',
        icon: 'settings_remote',
        title: 'Command Results',
        type: 'item',
        url: 'command-results',
        permission: UserAccessPermission.Uptime.Devices
    } as AppNavigation,
    CampaignsList: {
        id: 'campaigns',
        icon: 'videocam',
        title: 'Campaigns',
        type: 'item',
        url: 'campaigns',
        permission: UserAccessPermission.Uptime.Campaigns
    } as AppNavigation,
    DeviceSlotImages: {
        id: 'device-slot-images',
        icon: 'insert_photo',
        title: 'Store Images',
        type: 'item',
        url: 'device-slot-images',
        permission: UserAccessPermission.Uptime.StoreImages
    } as AppNavigation,
    Users: {
        id: 'users',
        icon: 'supervisor_account',
        title: 'User directory',
        type: 'item',
        url: 'users',
        permission: UserAccessPermission.Uptime.UserManagement
    } as AppNavigation,
    UserRoles: {
        id: 'users-roles',
        icon: 'verified_user',
        title: 'User Roles',
        type: 'item',
        url: 'user-roles',
        permission: UserAccessPermission.Uptime.UserManagement
    } as AppNavigation,
    BrandPartnersLibrary: {
        id: 'brands',
        icon: 'stars',
        title: 'Brands',
        type: 'item',
        url: 'brands',
        permission: UserAccessPermission.Uptime.BrandPartners
    } as AppNavigation,
    ProductLibrary: {
        id: 'brand-products',
        icon: 'shopping_basket',
        title: 'Products',
        type: 'item',
        url: 'brand-products',
        permission: UserAccessPermission.Uptime.Products
    } as AppNavigation,
    RetailerLibrary: {
        id: 'retailers',
        icon: 'business',
        title: 'Retailers',
        type: 'item',
        url: 'retailers',
        permission: UserAccessPermission.Uptime.Retailers
    } as AppNavigation,
    StoreLibrary: {
        id: 'stores',
        icon: 'shopping_cart',
        title: 'Stores',
        type: 'item',
        url: 'stores',
        permission: UserAccessPermission.Uptime.Stores
    } as AppNavigation,
    StoreGroups: {
        id: 'store_groups',
        icon: 'shopping_cart',
        title: 'Store Groups',
        type: 'item',
        url: 'store-groups',
        permission: UserAccessPermission.Uptime.Stores
    } as AppNavigation,
    DeviceSlots: {
        id: 'device-slots',
        icon: 'important_devices',
        title: 'Device Slots',
        type: 'item',
        url: 'device-slots',
        permission: UserAccessPermission.Uptime.DeviceSlots
    } as AppNavigation,
    PromoPrograms: {
        id: 'promo-programs',
        icon: 'videocam',
        title: 'Programs',
        type: 'item',
        url: 'promo-programs',
        permission: UserAccessPermission.Uptime.Campaigns,
    } as AppNavigation,
    PromoSchedule: {
        id: 'promo-schedules',
        icon: 'videocam',
        title: 'Schedule',
        type: 'item',
        url: 'promo-schedules',
        permission: UserAccessPermission.Uptime.Campaigns,
    } as AppNavigation,
    CampaignReports: {
        id: 'brand-campaign-reports',
        icon: 'show_chart',
        title: 'Campaign Reports',
        type: 'item',
        url: 'brand-campaign-reports',
        permission: UserAccessPermission.Uptime.Campaigns,
    } as AppNavigation,
    ScheduleCalendar: {
        id: 'schedule-calendar',
        icon: 'event_note',
        title: 'Schedule calendar',
        type: 'item',
        url: 'schedule-calendar',
        permission: UserAccessPermission.Uptime.Campaigns,
    } as AppNavigation,
    MediaPlaylists: {
        id: 'media-playlists',
        icon: 'featured_play_list',
        title: 'Media Playlists',
        type: 'item',
        url: 'media-playlists',
        permission: UserAccessPermission.Uptime.Films,
    } as AppNavigation,
    MediaContentsList: {
        id: 'media-content',
        icon: 'videocam',
        title: 'Media Content',
        type: 'item',
        url: 'media-content',
        permission: UserAccessPermission.Uptime.Films,
    } as AppNavigation,
    Reports: {
        id: 'reports',
        icon: 'analytics',
        title: 'Execution Reports',
        type: 'item',
        url: 'reports',
        permission: UserAccessPermission.Uptime.Campaigns,
    } as AppNavigation,
};

const AllNavigationItems: AppNavigation[] = [{
    id: 'sections',
    title: 'Sections',
    type: 'group',
    availableForMobile: true,
    children: [
        navSections.Dashboard,
        {
            id: 'devices-list',
            icon: 'tablet_android',
            title: 'Devices',
            url: '/devices',
            type: 'collapsable',
            permission: UserAccessPermission.Uptime.Devices,
            availableForMobile: true,
            children: [
                navSections.DeviceList,
                navSections.DeviceCommandResultsList
            ]
        },
        navSections.DeviceSlotImages,
        {
            id: 'user-section',
            icon: 'person',
            title: 'Users',
            type: 'collapsable',
            children: [
                navSections.Users,
                navSections.UserRoles,
            ]
        },
        {
            id: 'library-items',
            icon: 'info',
            title: 'Library',
            type: 'collapsable',
            children: [
                navSections.BrandPartnersLibrary,
                navSections.ProductLibrary,
                navSections.RetailerLibrary,
                navSections.StoreLibrary,
                navSections.StoreGroups,
                navSections.DeviceSlots,
                navSections.MediaContentsList,
                navSections.MediaPlaylists,
            ]
        },
        {
            id: 'promo-campaigns',
            icon: 'videocam',
            title: 'Promo Campaigns',
            type: 'collapsable',
            children: [
                navSections.PromoPrograms,
                navSections.ScheduleCalendar,
                navSections.PromoSchedule,
                navSections.CampaignReports,
            ]
        },
        navSections.Reports,
    ]
}
];

class NavigationConfig {
    private _navigationItems: AppNavigationItem[]
    get navigationItems(): AppNavigationItem[] {
        if (!this._navigationItems) {
            if (isMobileBrowser()) {
                this._navigationItems = this.filterAvailableForMobile(AllNavigationItems)
            } else {
                this._navigationItems = AllNavigationItems
            }
        }
        return this._navigationItems
    }

    get navigationEntryPoint(): string {
        if (isMobileBrowser()) {
            return 'devices'
        }
        return 'dashboard'
    }

    get navigationNotFoundRedirect(): string {
        return this.navigationEntryPoint
    }

    private filterAvailableForMobile(items: AppNavigationItem[], dest?: AppNavigation[]): AppNavigation[] {
        dest = dest || []
        for (const item of (items || [])) {
            if (!item.availableForMobile) {
                continue
            }
            const current = {
                ...item,
                children: []
            }

            dest.push(current)
            if (Array.isArray(item.children) && item.children.length > 0) {
                this.filterAvailableForMobile(item.children, current.children)
            }
            if (current.children.length == 0) {
                current.children = null
            }
        }
        return dest
    }

}

export const AppNavigationConfig = new NavigationConfig()
