import {BaseModel} from '@looma/shared/models/base_model';
import {User} from '@looma/shared/models/user';
import {Assignable} from '@looma/shared/types/assignable';
import {Utils} from '@looma/shared/utils';
import {BrandPartner} from '@looma/shared/models/brand_partner';
import {UserAccessPermission} from "@looma/shared/models/user_role";
import {IdTokenResult} from "@firebase/auth";
import {Retailer} from "@looma/shared/models/retailer";


export class AuthSession extends BaseModel {

    get needsAccountSetup(): boolean {
        return this.getClaim('account-setup-required') + '' === 'true'
    }

    get authenticated(): boolean {
        return parseInt(this?.user?.id) > 0
    }

    get permissions(): string[] {
        return this?.user?.allAccessPermissions().map(t => t.key);
    }

    constructor(public user: User, public brands: BrandPartner[],  public retailers: Retailer[], private idTokenResult: IdTokenResult) {
        super();
    }

    static sessionsEqual(a: AuthSession, b: AuthSession): boolean {
        if (!a && !b) {
            return true
        }
        const aId = parseInt(a?.user?.id) || 0
        const bId = parseInt(b?.user?.id) || 0
        if (aId != bId) {
            return false
        }
        if (a?.authenticated != b?.authenticated) {
            return false
        }
        if (a?.needsAccountSetup != b?.needsAccountSetup) {
            return false
        }
        return true

    }

    static empty(): AuthSession {
        return new AuthSession(null, null, null, null)
    }

    static from(idTokenResult: IdTokenResult, authData: AuthenticatedUserData): AuthSession {
        return new AuthSession(authData?.user, authData?.brands, authData?.retailers, idTokenResult);
    }

    hasAccessPermission(permission: UserAccessPermission): boolean {
        return this?.user?.hasAccessPermission(permission)
    }

    assign(input: any): this {
        return super.assign(input);
    }

    getClaim(key: string): string {
        if (this.idTokenResult && this.idTokenResult.claims) {
            return String(this.idTokenResult.claims[key])
        }
        return null
    }

    setProfilePhoto(url: string) {
        if (url && this.user) {
            this.user.photo_url = url
        }
    }
}

export class AuthenticatedUserData implements Assignable {
    user: User;
    brands: BrandPartner[];
    retailers: Retailer[];

    assign(input: any): this {
        this.user = Utils.getNestedTypedObject(input, User, 'user')
        this.brands = Utils.getNestedTypedArray(input, BrandPartner, 'brands')
        this.retailers = Utils.getNestedTypedArray(input, Retailer, 'retailers')
        return this;
    }
}
