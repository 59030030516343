<div>
    <div style="display: flex; gap:16px">
        <div>
            <mat-form-field>
                <mat-label>Chart type</mat-label>
                <mat-select [(ngModel)]="selectedChartType" (ngModelChange)="onSelectedChartTypeChanged($event)">
                    <mat-option *ngFor="let opt of AvailableDashboardChartTypes" [value]="opt">{{ opt }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div style="width: 200px">
            <app-autocomplete-search-field showClear="true" placeholder="Retailer"
                                           (valueChanged)="onRetailerFilterChanged($event)" selectionMode="single"
                                           searchObjectType="Retailer"></app-autocomplete-search-field>
        </div>
        <div style="width: 200px">
            <app-autocomplete-search-field [clearOn]="retailerChangedPipe" showClear="true" placeholder="Program"
                                           [disabled]="!selectedRetailerId"
                                           [searchCriteria]="retailerFieldsSearchCriteria"
                                           (valueChanged)="onRetailerFieldFilterChanged($event,'program')"
                                           selectionMode="single"
                                           searchObjectType="PromoProgram"></app-autocomplete-search-field>
        </div>
        <div style="width: 200px" *ngIf="selectedChartType == DashboardChartType.UptimePercentage">
            <mat-form-field *ngIf="!selectedRetailerHasDivisions">
                <mat-select multiple="true" placeholder="Region"
                            [(ngModel)]="selectedRegions" (ngModelChange)="onSelectedRegionOrDivisionChanged($event)"
                            [compareWith]="isSmeObj">
                    <mat-option *ngFor="let l of availableRegions || []" [value]="l">
                        {{ l.getDisplayName() }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="selectedRetailerHasDivisions">
                <mat-select multiple="true" placeholder="Division"
                            [(ngModel)]="selectedDivisions" (ngModelChange)="onSelectedRegionOrDivisionChanged($event)"
                            [compareWith]="isSmeObj">
                    <mat-option *ngFor="let l of availableDivisions || []" [value]="l">
                        {{ l.getDisplayName() }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <mat-form-field *ngIf="selectedChartType == DashboardChartType.UptimePercentage">
            <input matInput [matDatepicker]="startDatePicker" placeholder="Choose start date" [(ngModel)]="startDate"
                   (ngModelChange)="handleDateChanged('start')">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="selectedChartType == DashboardChartType.UptimePercentage">
            <input matInput [matDatepicker]="endDatePicker" placeholder="Choose end date" [(ngModel)]="endDate"
                   (ngModelChange)="handleDateChanged('end')">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="chart_description" *ngIf="chartDescription">{{ chartDescription }}</div>
    <div style="width: 100%; margin-left: auto; margin-right: auto;" echarts
         [options]="getEchartsOptions()"
         (chartInit)="onChartInit($event)">
    </div>
</div>