import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ApiDataService } from "../../../../services/api-data.service";
import { LayoutService } from "../../../../services/layout.service";
import { Location, LocationInput } from "@looma/shared/models/location";
import { UploadService } from "@looma/shared/services/upload.service";
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";
import { ModelEditDialog } from "../../../../shared/model_edit_dialog";
import { MutationResponse } from "@looma/shared/types/mutation_response";
import { Observable, Subscription } from "rxjs";
import { Store, StoreMutationInput } from "@looma/shared/models/store";
import { map, takeUntil } from "rxjs/operators";
import { Retailer } from "@looma/shared/models/retailer";
import { MutationOperation } from "@looma/shared/models/mutation_operation";
import { NamedValue } from "@looma/shared/types/named_value";
import { Utils } from "@looma/shared/utils";
import { BaseModel } from "@looma/shared/models/base_model";
import { SearchableField, SearchFieldCriteria } from "@looma/shared/search";

const StorePriorities = Object.freeze([
    'Standard',
    'Priority',
])

@LifecycleHooks()
@Component({
    selector: 'app-stores-edit-dialog',
    templateUrl: './stores-edit-dialog.component.html',
    styleUrls: [ './stores-edit-dialog.component.scss' ]
})
export class StoresEditDialogComponent extends ModelEditDialog<Store> implements OnInit, OnDestroy{

    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<StoresEditDialogComponent>,
                private svcApi: ApiDataService,
                private svcUpload: UploadService,
                public svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) data: StoresEditDialogData){
        super();

        this.store = new Store();
        Object.assign(this.store, data.store)

        if (!this.store.isNewRecord()) {
            this.onRetailerSelectionChanged(this.store.retailer.namedValue())
        }
        this.selectedRetailerDivision = this.store?.division?.namedValue()

        this.store.product_categories = this.store.product_categories || [];

        this.store.operationsPriority ||= 0

        this.selectedLocation = this.store.location;

        this.form = fb.group({
            name: new FormControl(this.store.retailer_store_num, [ Validators.required ]),
            retailerRegionFormControl: new FormControl(this.store.retailer_region, [ Validators.required ]),
            locationsFormControl: new FormControl(this.store.location?.prettyPrint(), [ Validators.required ]),
            categoriesFormControl: new FormControl(this.store.product_categories, [ Validators.required ]),
            operationsPriorityFormControl: new FormControl(this.store.operationsPriority || 0, [ Validators.required ]),
        });


    }

    form: FormGroup;
    store: Store;

    selectedRetailer: Retailer;
    private selectedLocation: Location;

    private loadRetailerSub: Subscription

    retailerDivisionSearchCriteria: SearchFieldCriteria
    selectedRetailerDivision: NamedValue

    availableStorePriorities = StorePriorities

    static open(svcLayout: LayoutService, store: Store): Observable<Store>{
        const data: StoresEditDialogData = {
            store: store
        }
        const dialogConfig: MatDialogConfig = {
            width: '800px',
            data: data,
        }

        return svcLayout.openDialogForResult(StoresEditDialogComponent, dialogConfig)
    }

    onRetailerSelectionChanged(nv: NamedValue): void{
        this.loadRetailer(nv.value)
        this.retailerDivisionSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, nv.value);
        this.selectedRetailerDivision = null
    }

    onRetailerDivisionChanged(value: NamedValue){
        this.selectedRetailerDivision = value
    }

    private loadRetailer(id: string | number): void{
        Utils.unsubscribe(this.loadRetailerSub)
        this.loadRetailerSub = this.svcApi.getRetailersFeed({ id: id + '' }).pipe(
            takeUntil(Utils.onDestroy(this)),
            map(feed => {
                return feed.firstItem()
            })
        ).subscribe(retailer => {
            this.selectedRetailer = retailer
        })
    }

    ngOnInit(): void{
    }

    ngOnDestroy(): void{
    }

    isSmeObj(a: BaseModel, b: BaseModel): boolean{
        return Utils.itemsEqual(a, b)
    }

    onLocationSelected(location: Location): void{
        this.selectedLocation = location;
    }


    onSave(): Observable<MutationResponse<Store>>{
        if (!this.form.valid) {
            return;
        }

        const newRecord = this.store.isNewRecord();

        const data: StoreMutationInput = {};
        if (!newRecord) {
            data.id = this.store.id.toString()
        }
        data.retailer_store_num = this.form.get('name').value;
        data.retailer_id = this.selectedRetailer.id.toString()
        data.product_category_ids = this.form.get('categoriesFormControl').value.map(t => t.id);
        data.retailer_region_id = this.form.get('retailerRegionFormControl').value.id;
        data.operationsPriority = this.form.get('operationsPriorityFormControl').value;
        data.division = this.selectedRetailerDivision?.name

        console.warn('data', data)

        if (this.selectedLocation) {

            this.selectedLocation.country_code = this.sanitizeLocationField(this.selectedLocation.country_code)
            this.selectedLocation.state = this.sanitizeLocationField(this.selectedLocation.state)
            this.selectedLocation.city = this.sanitizeLocationField(this.selectedLocation.city)
            this.selectedLocation.street_address = this.sanitizeLocationField(this.selectedLocation.street_address)
            this.selectedLocation.zip_code = this.sanitizeLocationField(this.selectedLocation.zip_code)

            const locationInput: LocationInput = {
                countryCode: this.selectedLocation.country_code,
                state: this.selectedLocation.state,
                city: this.selectedLocation.city,
                streetAddress: this.selectedLocation.street_address,
                zipCode: this.selectedLocation.zip_code
            };

            const geo = this.selectedLocation.geo_point
            if (Array.isArray(geo)) {
                locationInput.lat = geo[0];
                locationInput.lng = geo[1];
            }
            data.location = locationInput;
        }

        const action = newRecord ? MutationOperation.Create : MutationOperation.Update;
        return this.svcApi.upsertStore(action, data);
    }

    handleAction(action: 'ok' | 'cancel'): void{
        switch (action) {
            case 'cancel':
                this.dialogRef.close(null);
                break;
            case 'ok':
                const obs = this.onSave();
                if (!obs) {
                    return;
                }
                this.submitMutation(obs);
                break;
        }
    }

    sanitizeLocationField(field: string){
        return field?.replace(/,/g, "")
    }
}

export interface StoresEditDialogData{
    store: Store
}
