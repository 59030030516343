import {ImageSourceType, insetRect, loadImage, Rect, Utils} from "@looma/shared/utils";
import {fabric} from "fabric";
import {IObjectOptions} from "fabric/fabric-impl";
import {TemplateComponent} from "./template_component";

const BLANK_IMAGE = new Image()
BLANK_IMAGE.src = Utils.BLANK_IMAGE

type ImageFitMode = 'none' | 'center-crop' | 'center-fill'

export class TemplateImageComponent extends TemplateComponent {

    private renderBounds: Rect
    private renderScale = 1
    private imageSource: ImageSourceType = Utils.BLANK_IMAGE

    private image: fabric.Image = new fabric.Image(BLANK_IMAGE, {})

    constructor(
        private canvas: fabric.Canvas,
        private bounds: Rect,
        private imageFit: ImageFitMode,
        private defaultObjectOptions: IObjectOptions = {},
    ) {
        super()

        this.image.set({
            ...defaultObjectOptions,
            top: 0,
            left: 0,
        })

        this.canvas.add(this.image)

        this.renderBounds = {
            ...bounds
        }
    }

    setSource(src: File | Blob | string | HTMLImageElement) {
        this.imageSource = src
        this.requestRefresh()
    }

    setScale(newScale: number) {
        if (newScale < 0) {
            newScale = 0
        }
        if (newScale > 1) {
            newScale = 1
        }
        if (newScale != this.renderScale) {
            this.renderScale = newScale
            const paddingX = this.bounds.width * (1 - newScale) / 2
            const paddingY = this.bounds.height * (1 - newScale) / 2

            this.renderBounds = insetRect(this.bounds, paddingX, paddingY)
            console.warn(newScale, paddingX, paddingY, this.renderBounds, this.bounds)
            this.requestRefresh()
        }
    }

    draw() {
        Utils.unsubscribe(this.processSub)
        this.processSub = loadImage(this.imageSource).subscribe(imgEl => {
            this.imageSource = imgEl
            let {width, height} = imgEl
            let scale = 1
            const renderBounds = this.renderBounds
            if (imgEl.src == Utils.BLANK_IMAGE) {
                width = renderBounds.width
                height = renderBounds.height
            } else {
                const scaleX = width / renderBounds.width, scaleY = height / renderBounds.height

                switch (this.imageFit) {
                    case 'none':
                        break
                    case "center-crop":
                        if (scaleX > 1 || scaleY > 1) {
                            scale = Math.min(scaleY, scaleX)
                        } else {
                            scale = 1 / Math.min(scaleY, scaleX)
                        }
                        break
                    case "center-fill":
                        scale = 1 / Math.min(scaleY, scaleX)
                        console.warn('scale',this.imageFit, scale, scaleX, scaleY)
                        break
                }

            }

            this.image.setElement(imgEl)

            this.image.scale(scale)
            
            const paddingX = (renderBounds.width - width*scale)/2
            const paddingY = (renderBounds.height - height*scale)/2

            this.image.set({
                left: renderBounds.left+paddingX,
                top: renderBounds.top+paddingY  ,
            })

            this.canvas.requestRenderAll()
            this.setBusy(false)
        })
    }


}
