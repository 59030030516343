import {Utils} from '@looma/shared/utils';

export class ApiResponse {
    success: boolean;
    message: string;
    data: { [key: string]: string };
    errors: { [key: string]: string };

    assign(input: any): this {
        Object.assign(this, input);
        Utils.assignStringPair(this, input, 'data');
        Utils.assignStringPair(this, input, 'errors');
        return this
    }

}

