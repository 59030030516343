import {BaseModel} from '@looma/shared/models/base_model';

export enum SurveyContentType {
    MealType = "MealType",
    DrinkerType = "DrinkerType",
    WinePricing = "WinePricing",
    WineType = "WineType",
}

export class SurveyContent extends BaseModel {

    public static contentTypes = [
        {id: SurveyContentType.MealType, name: "Meal Type"},
        {id: SurveyContentType.DrinkerType, name: "Drinker Type"},
        {id: SurveyContentType.WinePricing, name: "Wine Pricing"},
        {id: SurveyContentType.WineType, name: "Wine Type"},
    ]

    id: string;
    retailerPromoPeriodId: string;
    retailerPromoProgramId: string;
    displayName: string;
    description: string;
    imageUrl: string;
    surveyContentType: SurveyContentType;
    orderIdx: number;
    contentKey: string;
    references: SurveyContent[];
    updatedAt: Date;
    createdAt: Date;

    public static surveyContentTypes(): string[] {
        return Object.values(SurveyContentType);
    }

    public static getContentTypeName(key: string) {
        return SurveyContent.contentTypes.find(t => t.id == key)?.name
    }

    assign(input: any): this {
        super.assign(input);
        this.assignDate(input, 'updatedAt');
        this.assignDate(input, 'createdAt');
        this.assignTypedArray(input, 'references', SurveyContent);
        return this;
    }
}

export interface SurveyContentInput {
    id?: string;
    retailerPromoPeriodId?: string
    retailerPromoProgramId?: string
    surveyContentType?: string;
    displayName?: string;
    description?: string;
    imageFirebaseKey?: string;
    orderIdx?: number
    references?: SurveyContentReference[]
    contentKey?: string;
}

export interface SurveyContentReference {
    surveyContentType: string
    id: string
}

export interface SurveyContentFilter {
    id?: string
    retailerPromoPeriodID?: string
    surveyContentType?: string
}
