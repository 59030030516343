import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppCommonModule} from "../../shared/common.module";
import {RouterModule} from "@angular/router";
import {LayoutModule} from "../../layout/layout.module";
import {DndModule} from "ngx-drag-drop";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatBadgeModule} from "@angular/material/badge";
import {ProgramsListComponent} from './components/programs-list/programs-list.component';
import {ProgramEditDialogComponent} from './components/program-edit-dialog/program-edit-dialog.component';
import {
    ProgramDeviceSlotTypeDialogComponent
} from './components/program-device-slot-type-dialog/program-device-slot-type-dialog.component';
import {
    ProgramDeviceSlotTypeAssignmentComponent
} from './components/program-device-slot-type-assignment/program-device-slot-type-assignment.component';

@NgModule({
    declarations: [ProgramsListComponent, ProgramEditDialogComponent, ProgramDeviceSlotTypeDialogComponent, ProgramDeviceSlotTypeAssignmentComponent],
    imports: [
        CommonModule,
        AppCommonModule,
        RouterModule,
        LayoutModule,
        DndModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatBadgeModule
    ]
})
export class RetailerPromoProgramsModule {
}
