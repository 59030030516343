<h2 mat-dialog-title>{{ this.brand.isNewRecord() ? "Create Brand" : "Edit brand " + this.brand.name }}</h2>

<mat-dialog-content [formGroup]="form">

    <div style="width: 200px; height:200px;">
        <app-image-tile-uploader
            [imageUrl]="brand?.logoUrl"
            [texts]="{empty:'Click to upload logo', nonEmpty:'Click to change logo'}"
            (onFileUploaded)="handleFileUploaded($event)"
            (onUploadStateChanged)="handleUploaderStateChanged($event)"
        ></app-image-tile-uploader>
    </div>

    <app-autocomplete-search-field *ngIf="brand.isBrand"
                                   placeholder="Parent Company"
                                   selectionMode="single"
                                   [value]="selectedParentBrand"
                                   searchObjectType="ParentBrand"
                                   showClear="true"
                                   (valueChanged)="onParentBrandChanged($event)">
    </app-autocomplete-search-field>

    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" autocomplete="off"/>
    </mat-form-field>


    <mat-form-field floatLabel="always" *ngIf="brand.isBrand">
        <mat-label>Looma ID</mat-label>
        <div style="display: flex; flex-direction: row">
            <span style="margin-top: -0.3px" *ngIf="selectedParentBrand">{{ selectedParentBrand.hint }}-</span>
            <input style="flex: 1" matInput formControlName="loomaId" autocomplete="off"
                   (ngModelChange)="onLoomaIdSuffixChanged($event)"
            />
        </div>
        <mat-hint align="start"><span style="color:red" *ngIf="loomaIdInvalidMsg">{{ loomaIdInvalidMsg }}</span>
        </mat-hint>
        <mat-spinner *ngIf="isValidatingLoomaId" matSuffix diameter="16" style="display: inline-flex"></mat-spinner>

    </mat-form-field>

    <mat-form-field *ngIf="brand.isParentCompany">
        <mat-label>Looma ID</mat-label>
        <input matInput formControlName="loomaId" autocomplete="off" (ngModelChange)="onLoomaIdSuffixChanged($event)"/>
        <mat-hint align="start"><span style="color:red" *ngIf="loomaIdInvalidMsg">{{ loomaIdInvalidMsg }}</span>
        </mat-hint>
        <mat-spinner *ngIf="isValidatingLoomaId" matSuffix diameter="16" style="display: inline-flex"></mat-spinner>
    </mat-form-field>

    <mat-form-field *ngIf="categories?.length > 0">
        <mat-select multiple="true" formControlName="categoriesFormControl" placeholder="Product category"
                    [value]="brand.product_categories" [compareWith]="isSmeObj">
            <mat-option *ngFor="let l of categories || []" [value]="l">
                {{ l.category_name }}
            </mat-option>
        </mat-select>
    </mat-form-field>


</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!canSubmit()">Save</button>
    <button mat-button (click)="close()" [disabled]="isBusy">cancel</button>
</mat-dialog-actions>