import {
    Attribute,
    ChangeDetectorRef,
    Component,
    Directive,
    Host,
    Injectable,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef
} from '@angular/core';
import {appAnimations} from '@looma/shared/animations';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AppNavigationItem} from '../../../shared/app-navigation';
import Timeout = NodeJS.Timeout;

interface PageTab {
    name: string;
    template?: TemplateRef<any>
}

@Injectable()
export class PageControllerService {

    private _activeSectionSubject = new BehaviorSubject<PageTab>(null);
    private _headerNavSubject = new BehaviorSubject<TemplateRef<any>>(null);
    private viewInitialized = false;

    changeDetector: ChangeDetectorRef;

    registerTabs: Set<LoomaPageTabDirective>;
    public hasTabs = false;
    activateTimeout: Timeout

    get activeSection(): PageTab {
        return this._activeSectionSubject.value;
    }

    set activeSection(t: PageTab) {
        if (!t) {
            return;
        }
        const found = Array.from(this.registerTabs).find(value => {
            if (value == t) {
                return true
            }
            if (t.name) {
                return t.name == value.name
            }
            return false;
        });

        if (found && (found != this._activeSectionSubject.value)) {
            this._activeSectionSubject.next(null);
            clearTimeout(this.activateTimeout)
            this.activateTimeout = setTimeout(() => {
                // avoid freezing the page whenever some heavy weight tab pages become active
                this._activeSectionSubject.next(found);
            }, 0)
        }

    }


    get tabChanged(): Observable<PageTab> {
        return this._activeSectionSubject;
    }

    get headerNavChanged(): Observable<TemplateRef<any>> {
        return this._headerNavSubject;
    }

    setHeaderNav(tmpl: TemplateRef<any>) {
        this._headerNavSubject.next(tmpl);
        if (this.viewInitialized) {
            this.changeDetector.detectChanges();
        }
    }

    notifyHeaderChanged() {
        if (this.changeDetector) {
            const v = this._headerNavSubject.value
            if (v) {
                // this._headerNavSubject.next(this._headerNavSubject.value)
                this.changeDetector.detectChanges();
            }

        }
    }

    registerTab(tab: LoomaPageTabDirective) {
        if (!this.registerTabs) {
            this.registerTabs = new Set<LoomaPageTabDirective>();
        }
        if (this.registerTabs.has(tab)) {
            return
        }
        const prevLength = this.registerTabs.size;
        this.registerTabs.add(tab);
        if (prevLength != this.registerTabs.size) {
            if (this.registerTabs.size == 1) {
                this.activeSection = tab;
            }
            this.hasTabs = true;
        }
    }

    setViewInitialized(v: boolean) {
        this.viewInitialized = v;
    }
}


@Component({
    selector: '[app-looma-page]',
    templateUrl: './looma-page.component.html',
    styleUrls: ['./looma-page.component.scss'],
    providers: [PageControllerService],
    animations: appAnimations,
})
export class LoomaPageComponent implements OnInit, OnDestroy {

    @Input('pageTitle')
    pageTitle: string;

    @Input('pageSubtitle')
    pageSubtitle: string;

    @Input('pageIcon')
    pageIcon: string;

    @Input('headerSideTpl')
    set headerSideTpl(value: TemplateRef<any>) {
        this.svcPageController.setHeaderNav(value)
    }

    private mNavSection: AppNavigationItem;

    @Input('navSection')
    set navSection(item: AppNavigationItem) {
        if (!item) {
            item = this.mNavSection;
        }
        let changed = false;
        if (item && this.mNavSection) {
            changed = item.id !== this.mNavSection.id;
        } else {
            changed = !!item
        }
        if (changed) {
            this.mNavSection = item;
            this.pageIcon = this.mNavSection.icon;
            this.pageTitle = this.mNavSection.title;

        }
    }

    private backPressedEmitter: Subject<boolean>;

    hasBackPressedListener(): boolean {
        return !!this.backPressedEmitter;
    }

    @Output()
    get onBackPressed(): Observable<boolean> {
        if (!this.backPressedEmitter) {
            this.backPressedEmitter = new Subject()
        }
        return this.backPressedEmitter
    }

    constructor(
        private changeDetector: ChangeDetectorRef,
        public svcPageController: PageControllerService
    ) {
        this.svcPageController.changeDetector = changeDetector;

    }

    ngOnInit(): void {
        this.svcPageController.setViewInitialized(true);
    }

    handleBackPressed(): void {
        if (this.backPressedEmitter) {
            this.backPressedEmitter.next((true))
        }
    }

    ngOnDestroy(): void {
        if (this.backPressedEmitter) {
            this.backPressedEmitter.complete();
        }
    }

}


@Directive({
    selector: 'ng-template[pageTab]'
})
export class LoomaPageTabDirective implements PageTab {

    constructor(@Attribute('pageTab') public name: string,
                private parent: PageControllerService,
                @Host() public template: TemplateRef<any>) {

    }

    @Input('pageTab') set pageTab(name: string) {
        this.name = name;
        this.parent.registerTab(this);
    }

    @Input('pageTabActive') set pageTabActive(value: boolean) {
        this.parent.registerTab(this);
        if (value) {
            this.parent.activeSection = this
        }
    }

}

@Directive({
    selector: 'ng-template[pageHeaderNav]'
})
export class LoomaPageHeaderNav {

    constructor(
        @Attribute('pageHeaderNav') public headerSideTpl: TemplateRef<any>,
        parent: PageControllerService,
        @Host() template: TemplateRef<any>) {
        parent.setHeaderNav(template);
    }

}
