<div class="page-layout blank">

    <div class="header accent" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
         fxLayoutAlign.lt-sm="start start">

        <!-- APP TITLE -->
        <div class="logo my-12 m-sm-0" fxLayout="row"
             fxLayoutAlign="start center"
             *ngIf="!isMobile">
            <button mat-icon-button class="mr-0 mr-sm-16" (click)="location.back()">
                <mat-icon>arrow_back</mat-icon>
            </button>

            <div class=" mr-8">
                <device-status-icon [status]="device?.remote_status_info?.playback_status || 0"></device-status-icon>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start">
                <div class="h2">
                    {{device?.device_id}}
                </div>
                <div class="subtitle secondary-text">
                    <span>Device Details</span>
                </div>
            </div>
        </div>

        <div class="logo m-sm-0" fxLayout="row"
             fxLayoutAlign="start center"
             *ngIf="isMobile">
            <button mat-icon-button class="mr-0 mr-sm-16" (click)="location.back()">
                <mat-icon>arrow_back</mat-icon>
            </button>

            <div class=" mr-8">
                <device-status-icon [status]="device?.remote_status_info?.playback_status || 0"></device-status-icon>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start">
                <div class="subtitle secondary-text">
                    <span>{{stringifyDeviceAssignation(device)}}</span>
                </div>
                <div class="subtitle secondary-text">
                    {{device?.device_id}}
                </div>
            </div>
        </div>


        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="stretch" fxLayoutGap="16px"
             class="mr-32"
             [ngClass]="{'device_actions' : !isMobile, 'device_actions_mobile' : isMobile}">

            <mat-menu #menuDeviceCommand="matMenu">
                <ng-template matMenuContent>
                    <button *ngFor="let cmd of availableDeviceCommands" mat-menu-item
                            (click)="invokeCommand(cmd)">{{cmd.displayMessage}}</button>
                </ng-template>
            </mat-menu>

            <div style="display: flex; background-color: white; width: 100%" *ngIf="isMobile">
                <div style="flex-direction: row; width: 100%;">
                    <div style="height: 180px; display: flex; width: 100%; overflow: hidden">
                        <div
                            style="width: 100%; height:200px; flex-direction: row; align-items: center; text-align: center">
                            <h4 style="color: black; margin: 0; padding-top: 10px; font-weight: bold">STATUS</h4>
                            <div style="width: 100px; height:100px; margin-left: auto; margin-right: auto;" echarts
                                 [options]="getEchartsOptions('device_status')"
                                 (chartInit)="onChartInit($event,'device_status')">
                            </div>
                            <h4 style="color: black; margin: 0;">Last Reported</h4>
                            <div style="color: black"
                                 *ngIf="device && device.remote_playback_info && device.remote_playback_info.session_updated_at">
                                <span
                                    class="h4">{{device.remote_playback_info.session_updated_ago * 1000 | dateSince}}</span>
                            </div>
                        </div>
                        <div
                            style="width: 100%; height:200px; flex-direction: row; align-items: center; text-align: center">
                            <h4 style="color: black; margin: 0; padding-top: 10px; font-weight: bold">HEARTBEAT</h4>
                            <div
                                style="width: 100px; height:100px; margin-left: auto; margin-right: auto; padding-top: 15px"
                                echarts [options]="getEchartsOptions('heartbeat_status')"
                                (chartInit)="onChartInit($event,'heartbeat_status')">
                            </div>
                            <h4 style="color: black; margin: 0;">Last Reported</h4>
                            <div style="color: black;"
                                 *ngIf="device?.remote_device_info?.last_heartbeat_ago">
                                <span
                                    class="h4">{{device?.remote_device_info?.last_heartbeat_ago * 1000 | dateSince}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <button mat-raised-button color="warn" [matMenuTriggerFor]="menuDeviceCommand"
                    *ngIf="availableDeviceCommands?.length"
                    [ngClass]="{'mobile_margin' : isMobile}">
                SEND COMMAND
            </button>

            <button mat-raised-button color="warn" (click)="navigateToRemoteControl()" *ngIf="!isMobile">
                Remote Control
            </button>

            <button mat-raised-button (click)="navigateToEdit()" *ngIf="hasRight(DeviceUserRight.EditMetadata) && !isMobile">Edit</button>

            <button mat-raised-button color="warn" (click)="replaceDevice()"
                    *ngIf="hasRight(DeviceUserRight.SetAssignation) && !isMobile && device?.assignation?.store">
                Replace Device
            </button>

            <button mat-raised-button (click)="openAddImageDialog()" [disabled]="!device?.assignation" *ngIf="hasRight(DeviceUserRight.UploadPhoto)"
                    class="mobile_margin">Upload Photo
            </button>

        </div>

        <!-- / APP TITLE -->

    </div>

    <div class="content p-16 pt-0">
        <div class="left" *ngIf="!isMobile">

            <div fxLayout="row" class="mb-32 p-16 mr-16 " style="background: #353A48;">

                <div fxFlex="30" class="auto-width  device-summary mr-16" fxLayout="row" fxLayoutAlign="stretch"
                     id="elem" style="height: 200px;">
                    <div style="position: absolute; width:230px; height:230px; right:-15px; ">
                        <div style="width: 230px; height:230px" echarts [options]="getEchartsOptions('device_status')"
                             (chartInit)="onChartInit($event,'device_status')"></div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="space-between start" class="p-8"
                         style="margin-right:200px; width:100%; position:relative">
                        <span class="color-accent font-size-18">Status</span>
                        <div>
                            <div
                                *ngIf="device && device.remote_playback_info && device.remote_playback_info.session_updated_at">
                                <span class="h4 secondary-text text-truncate">Last Reported</span>
                                <span
                                    class="h4">{{device.remote_playback_info.session_updated_ago * 1000 | dateSince}}</span>
                            </div>
                        </div>

                    </div>

                </div>

                <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-between" class="mr-16">
                    <div fxFlex="46" class="device-summary auto-width  device-summary" fxLayout="stretch">
                        <div class="chart-wrapper size-medium" style="top:10px">
                            <div class="chart size-medium" echarts [options]="getEchartsOptions('heartbeat_status')"
                                 (chartInit)="onChartInit($event,'heartbeat_status')"></div>
                        </div>
                        <div class="chart-legend" fxLayout="column" fxLayoutAlign="space-between end"
                             *ngIf="device?.remote_device_info?.last_heartbeat_ago">
                            <span class="color-accent font-size-16">Heart Beat</span>
                            <span
                                class="secondary-text font-size-15">{{device?.remote_device_info?.last_heartbeat_ago * 1000 | dateSince}}</span>
                        </div>
                    </div>

                    <div fxFlex="46" class="device-summary auto-width  device-summary" fxLayout="stretch">
                        <div class="chart-wrapper size-medium" style="top:10px">
                            <div class="chart size-medium" echarts [options]="getEchartsOptions('volume_status')"
                                 (chartInit)="onChartInit($event,'volume_status')"></div>
                        </div>
                        <div class="chart-legend" fxLayout="column" fxLayoutAlign="space-between end">
                            <span class="color-accent font-size-16">Volume</span>
                            <span
                                class="secondary-text font-size-15">Current {{device?.remote_device_info?.adjusted_volume}}
                                %</span>
                            <span
                                class="secondary-text font-size-15">Default {{device?.remote_device_info?.player_volume}}
                                %</span>
                        </div>
                    </div>

                </div>

                <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-between" class="mr-16">
                    <div fxFlex="46" class="device-summary auto-width  device-summary" fxLayout="stretch">
                        <div class="chart-wrapper size-small" style="top:-4px">
                            <div class="chart size-small" echarts [options]="getEchartsOptions('up_time_status')"
                                 (chartInit)="onChartInit($event,'up_time_status')"></div>
                        </div>
                        <div class="chart-legend" fxLayout="column" fxLayoutAlign="space-between end">
                            <span class="color-accent font-size-16">Up Time</span>
                            <span
                                class="secondary-text font-size-15">{{device?.remote_realtime_stats?.up_time | humanDuration}}</span>
                        </div>
                    </div>

                    <div fxFlex="46" class="device-summary auto-width  device-summary" fxLayout="stretch">
                        <div class="chart-wrapper size-small" style="top:-4px">
                            <div class="chart size-small" echarts [options]="getEchartsOptions('playback_time_status')"
                                 (chartInit)="onChartInit($event,'playback_time_status')"></div>
                        </div>
                        <div class="chart-legend" fxLayout="column" fxLayoutAlign="space-between end">
                            <span class="color-accent font-size-16">Playback Time</span>
                            <span
                                class="secondary-text font-size-15">{{device?.remote_realtime_stats?.playback_time | humanDuration}}</span>
                        </div>
                    </div>
                </div>

                <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-between">
                    <div fxFlex="46" class="device-summary auto-width  device-summary" fxLayout="stretch">
                        <div class="chart-wrapper size-medium" style="top:10px">
                            <div class="chart size-medium" echarts [options]="getEchartsOptions('wifi_status')"
                                 (chartInit)="onChartInit($event,'wifi_status')"></div>
                        </div>
                        <div class="chart-legend" fxLayout="column" fxLayoutAlign="space-between end">
                            <span class="color-accent font-size-16">Wifi</span>
                            <span class="secondary-text font-size-15 text-truncate"
                                  title="{{device?.remote_wifi_info?.ssid}}">{{device?.remote_wifi_info?.ssid}}</span>
                        </div>
                    </div>

                    <div fxFlex="46" class="device-summary auto-width  device-summary" fxLayout="stretch">
                        <div class="chart-wrapper size-medium" style="top:10px">
                            <div class="chart size-medium" echarts [options]="getEchartsOptions('battery_status')"
                                 (chartInit)="onChartInit($event,'battery_status')"></div>
                        </div>
                        <div class="chart-legend" fxLayout="column" fxLayoutAlign="space-between end">
                            <span class="color-accent font-size-16">Battery</span>
                            <span class="secondary-text font-size-15">
                                <span *ngIf="device?.remote_battery_info?.is_charging">Is charging</span>
                            </span>
                        </div>
                    </div>
                </div>


            </div>

            <div class="mb-32" fxLayout="column" *ngIf="device">
                <div fxLayout="row" *ngIf="device?.assignation?.store">

                    <mat-chip-list>
                        <mat-chip><span
                            style="font-weight: normal;">retailer:&nbsp; </span> {{device.assignation.store.retailer.retailer_name}}
                        </mat-chip>
                        <mat-chip><span
                            style="font-weight: normal;">region:&nbsp; </span> {{device.assignation.store.retailer_region.region_name}}
                        </mat-chip>
                        <mat-chip><span
                            style="font-weight: normal;">store num:&nbsp; </span> {{device.assignation.store.retailer_store_num}}
                        </mat-chip>
                        <mat-chip><span
                            style="font-weight: normal;">product category:&nbsp; </span> {{device.assignation.product_category.category_name}}
                        </mat-chip>
                    </mat-chip-list>

                    <button *ngIf="hasRight(DeviceUserRight.SetAssignation)" mat-raised-button color="warn" class="ml-16" (click)="unassignDevice()">
                        UNASSIGN
                    </button>
                </div>

                <div class="colored-bg p-8 warn-500 mr-16 h3" *ngIf="!device?.assignation?.store">
                    This device is not assigned to a store
                </div>
            </div>


            <mat-card class="mr-16 mb-16">
                <mat-tab-group *ngIf="device">
                    <mat-tab label="Reported Issues">
                        <ng-container *ngTemplateOutlet="noRecord"></ng-container>
                    </mat-tab>
                    <mat-tab label="Power Cycle Logs">
                        <ng-template matTabContent>
                            <div *ngIf="getDevicePowerCycleLogsDataSource().hasData else noRecord"
                                 style="max-height:410px; overflow: auto;">
                                <mat-table [dataSource]="getDevicePowerCycleLogsDataSource()" class="mat-elevation-z1">
                                    <ng-container cdkColumnDef="on_at">
                                        <mat-header-cell *cdkHeaderCellDef> On At</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.on_at | date:'short':'utc'}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="charged_at">
                                        <mat-header-cell *cdkHeaderCellDef> Charged At</mat-header-cell>
                                        <mat-cell
                                            *cdkCellDef="let row"> {{row.charged_at | date:'short':'utc'}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="discharging_at">
                                        <mat-header-cell *cdkHeaderCellDef> Discharging At</mat-header-cell>
                                        <mat-cell
                                            *cdkCellDef="let row"> {{row.discharging_at | date:'short':'utc'}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="off_at">
                                        <mat-header-cell *cdkHeaderCellDef> Off At</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.off_at | date:'short':'utc'}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="onDurationSecs">
                                        <mat-header-cell *cdkHeaderCellDef> On duration</mat-header-cell>
                                        <mat-cell
                                            *cdkCellDef="let row"> {{row.onDurationSecs | humanDuration}} </mat-cell>
                                    </ng-container>


                                    <mat-header-row
                                        *cdkHeaderRowDef="['on_at','charged_at','discharging_at','off_at','onDurationSecs']; sticky: true"></mat-header-row>
                                    <mat-row
                                        *cdkRowDef="let row; columns: ['on_at','charged_at','discharging_at','off_at','onDurationSecs']"></mat-row>

                                </mat-table>
                            </div>
                        </ng-template>
                    </mat-tab>


                    <mat-tab label="Command History">
                        <ng-template matTabContent>
                            <div *ngIf="getDeviceResultsDataSource().hasData else noRecord"
                                 style="max-height:410px; overflow: auto;">
                                <mat-table [dataSource]="getDeviceResultsDataSource()" class="mat-elevation-z1">
                                    <ng-container cdkColumnDef="cmdName">
                                        <mat-header-cell *cdkHeaderCellDef> Command Name</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.cmdName}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="cmdArgs">
                                        <mat-header-cell *cdkHeaderCellDef> Command Args</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.cmdArgs}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="issuedByUser">
                                        <mat-header-cell *cdkHeaderCellDef>Issued By</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.issuedByUser}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="issuedAt">
                                        <mat-header-cell *cdkHeaderCellDef>Issued At</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.issuedAt}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="statusName">
                                        <mat-header-cell *cdkHeaderCellDef>Status</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.statusName}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="message">
                                        <mat-header-cell *cdkHeaderCellDef>Message</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.message}} </mat-cell>
                                    </ng-container>

                                    <ng-container cdkColumnDef="attachment">
                                        <mat-header-cell *cdkHeaderCellDef>Attachment</mat-header-cell>
                                        <mat-cell *cdkCellDef="let row">
                                            <a *ngIf="row.attachmentUrl" href="{{row.attachmentUrl}}" target="_blank"
                                               style="color: #006064;">{{getFileName(row.attachmentUrl)}}</a>
                                            <span *ngIf="!row?.attachmentUrl">None</span>
                                        </mat-cell>
                                    </ng-container>


                                    <mat-header-row
                                        *cdkHeaderRowDef="['cmdName','cmdArgs','issuedByUser','issuedAt','statusName','message','attachment']; sticky: true"></mat-header-row>
                                    <mat-row
                                        *cdkRowDef="let row; columns: ['cmdName','cmdArgs','issuedByUser','issuedAt','statusName','message','attachment']"></mat-row>

                                </mat-table>
                            </div>
                        </ng-template>
                    </mat-tab>

                </mat-tab-group>
            </mat-card>


        </div>

        <div class="right pt-16">


            <div fxLayout="column" fxLayoutAlign="stretch" style="width: 100%">

                <div class="fuse-card px-16 mb-32">
                    <div class=" py-8 kiosk_mode_switch bb h-48" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h4">Kiosk mode
                        <strong style="color:white; padding:4px 8px; border-radius:10px;"
                                [style.backgroundColor]="device?.remote_device_info?.kiosk_mode_enabled ? 'green' : 'red'">{{device?.remote_device_info?.kiosk_mode_enabled ? 'Enabled' : 'Disabled'}}</strong>

                        </span>
                        <span *ngIf="hasRight(DeviceUserRight.SetKioskMode) && device?.remote_device_info">
                            <button *ngIf="device?.remote_device_info?.kiosk_mode_enabled" mat-stroked-button
                                    color="warn" (click)="setKioskModeEnabled(false)">Turn Off</button>
                            <button *ngIf="!device?.remote_device_info?.kiosk_mode_enabled" mat-stroked-button
                                    color="warn" (click)="setKioskModeEnabled(true)">Turn On</button>
                        </span>
                        <!--<ui-switch [disabled]="!device" [checked]="device?.remote_device_info?.kiosk_mode_enabled" uncheckedLabel="off"  checkedLabel="on"  size="small" ></ui-switch>-->
                    </div>

                    <div class=" py-8 kiosk_mode_switch bb h-48" fxLayout="row" fxLayoutAlign="space-between center"
                         *ngIf="device?.assignation">
                        <span class="h4">Installed
                        <strong style="color:white; padding:4px 8px; border-radius:10px;"
                                [style.backgroundColor]="device?.assignation.is_installed ? 'green' : 'red'">{{device?.assignation.is_installed ? 'Yes' : 'No'}}</strong>

                        </span>
                        <span *ngIf=" hasRight(DeviceUserRight.SetKioskMode) && device?.remote_device_info">
                            <button *ngIf="device?.assignation?.is_installed" mat-stroked-button color="warn"
                                    (click)="setInstalled(false)">uninstall</button>
                            <button *ngIf="!device?.assignation?.is_installed" mat-stroked-button color="warn"
                                    (click)="setInstalled(true)">install</button>
                        </span>
                        <!--<ui-switch [disabled]="!device" [checked]="device?.remote_device_info?.kiosk_mode_enabled" uncheckedLabel="off"  checkedLabel="on"  size="small" ></ui-switch>-->
                    </div>


                    <div class="py-8 bb h-48" fxLayout="row" fxLayoutAlign="space-between center"
                         *ngIf="device?.remote_realtime_stats?.last_rebooted_at">
                        <span class="h4">Last Rebooted</span>
                        <span>{{device.remote_realtime_stats.last_rebooted_at | date:'short'}}</span>
                    </div>

                    <div class="py-8 h-48" fxLayout="row" fxLayoutAlign="space-between center"
                         *ngIf="device?.remote_device_info?.last_heartbeat_at">
                        <span class="h4">Last Heartbeat</span>
                        <span>{{device.remote_device_info.last_heartbeat_at | date:'short'}}</span>
                    </div>

                    <div class="py-8 h-48" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px"
                         *ngIf="device?.remote_device_info">
                        <span
                            class="h3">
                            Volume: <span
                            class="volume_chip">{{ formatDeviceVolumePercent(device?.remote_device_info?.player_volume) }}</span>
                        </span>
                        <button *ngIf="hasRight(DeviceUserRight.SetVolume) && device?.remote_device_info" mat-stroked-button
                                color="warn" (click)="changeDeviceVolume()">Change
                        </button>

                    </div>
                </div>

                <div class="fuse-card mb-32">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Currently playing
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{device && device.remoteDeviceCampaignInfo && device.remoteDeviceCampaignInfo.activeCampaignName || 'Unknown'}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div *ngIf="device && device.activePlaylist && device.activePlaylist.tracks">
                                <div class="py-8" *ngFor="let entry of device.activePlaylist.tracks">
                                    <span class="h4 secondary-text text-truncate"
                                          title="{{entry.name}}">{{entry.name}}</span>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Installed Apps
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div *ngIf="device && device.remote_apps_info">
                                <div class="py-8" fxLayout="row" fxLayoutAlign="space-between center"
                                     *ngFor="let entry of device.installedAppProperties | keyvalue">
                                    <span class="h4">{{entry.key}}</span>
                                    <span [style.color]="entry.value.isOutdated ? 'red' : 'green'"
                                          title="Latest available {{entry.value.latestAppVersion}}">{{entry.value.installedAppVersion}}</span>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Wifi Info
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div *ngIf="device && device.remote_wifi_info">
                                <div class="py-8" fxLayout="row" fxLayoutAlign="space-between center"
                                     *ngFor="let entry of device.wifiProperties | keyvalue">
                                    <span class="h4">{{entry.key}}</span>
                                    <span>{{entry.value}}</span>
                                </div>

                            </div>

                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Disk Info
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div *ngIf="device && device.diskProps">
                                <div class="py-8" fxLayout="row" fxLayoutAlign="space-between center"
                                     *ngFor="let entry of device.diskProps | keyvalue">
                                    <span class="h4">{{entry.key}}</span>
                                    <span>{{entry.value}}</span>
                                </div>

                            </div>

                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Battery Info
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div *ngIf="device && device.remote_battery_info">
                                <div class="py-8" fxLayout="row" fxLayoutAlign="space-between center"
                                     *ngFor="let entry of device.batteryProperties | keyvalue">
                                    <span class="h4">{{entry.key}}</span>
                                    <span>{{entry.value}}</span>
                                </div>

                            </div>


                        </mat-expansion-panel>

                        <mat-expansion-panel class="device_location_panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Device Location
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <google-map #deviceMap [options]="mapOptions" style="flex:1" width="100%" height="200px"
                                        *ngIf="device?.remote_location_info">

                                <map-marker
                                    [position]="{lat:device.remote_location_info.lat, lng:device.remote_location_info.lng}"></map-marker>
                            </google-map>

                            <!--                            <agm-map *ngIf="device && device.remote_location_info"-->
                            <!--                                     [latitude]="device.remote_location_info.lat"-->
                            <!--                                     [longitude]="device.remote_location_info.lng" [zoom]="15" [zoomControl]="false"-->
                            <!--                                     style="height: 200px;">-->
                            <!--                                <agm-marker [latitude]="device.remote_location_info.lat"-->
                            <!--                                            [longitude]="device.remote_location_info.lng"></agm-marker>-->
                            <!--                            </agm-map>-->
                        </mat-expansion-panel>


                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div>
                                        <div>Accelerometer Info</div>
                                        <div style="color:red"
                                             *ngIf="device?.remote_accelerometer_info?.isCalibrated == false">
                                            <small>uncalibrated</small>
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div *ngIf="device?.remote_accelerometer_info">
                                <div class="py-8" fxLayout="row" fxLayoutAlign="space-between center"
                                     *ngFor="let entry of device.accelerometerProperties | keyvalue">
                                    <span class="h4">{{entry.key}}</span>
                                    <span>{{entry.value}}</span>
                                </div>
                                <button mat-raised-button color="accent" class="full-width"
                                        *ngIf="hasRight(DeviceUserRight.SetAccelerometer)"
                                        (click)="resetDevicePosition()">Make Default
                                </button>
                                <div class="py-8 mt-16" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span class="h4"><strong>Alert Threshold</strong></span>
                                    <span>{{device.remote_accelerometer_info.calibration_threshold}}</span>
                                </div>
                                <button mat-raised-button color="accent" class="full-width"
                                        *ngIf="hasRight(DeviceUserRight.SetAccelerometer)"
                                        (click)="changeDeviceCalibrationThreshold()">Change Threshold
                                </button>

                            </div>

                        </mat-expansion-panel>

                        <mat-expansion-panel *ngIf="device?.isDeviceConfigurable">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Device Config
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="device_config_field">
                                <div class="field_label">
                                    Auto Reboot Enabled
                                </div>
                                <div class="field_control">
                                    <mat-slide-toggle [checked]="device.remoteDeviceConfig.autoRebootEnabled"
                                                      [disabled]="!hasRight(DeviceUserRight.SetConfig)"
                                                      (change)="setRemoteConfigFieldValue('auto_reboot_enabled', $event.checked)"></mat-slide-toggle>
                                </div>
                            </div>

                            <div class="device_config_field">
                                <div class="field_label">
                                    Environment Switch Enabled
                                </div>
                                <div class="field_control">
                                    <mat-slide-toggle [checked]="device.remoteDeviceConfig.envSwitchEnabled"
                                                      [disabled]="!hasRight(DeviceUserRight.SetConfig)"
                                                      (change)="setRemoteConfigFieldValue('env_switch_enabled', $event.checked)"></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="device_config_field">
                                <div class="field_label">
                                    Volume Tiers Enabled
                                </div>
                                <div class="field_control">
                                    <mat-slide-toggle [checked]="device.remoteDeviceConfig.volumeTiersEnabled"
                                                      [disabled]="!hasRight(DeviceUserRight.SetConfig)"
                                                      (change)="setRemoteConfigFieldValue('volume_tiers_enabled', $event.checked)"></mat-slide-toggle>
                                </div>
                            </div>

                            <button *ngIf="hasRight(DeviceUserRight.SetConfig)" class="mt-16" mat-raised-button color="warn" [disabled]="!hasRemoteConfigChanges"
                                    (click)="saveRemoteConfig()">Save
                            </button>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>


                <div class="fuse-card px-16 mb-32" *ngIf="device">
                    <div class="py-8 bb h-48" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h4">Android Id</span>
                        <span>{{device.device_id}}</span>
                    </div>
                    <div class="py-8 bb h-48" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h4">Wifi Mac Address</span>
                        <span>{{device.wifiProperties.get('Wifi Mac Address')}}</span>
                    </div>
                    <div class="py-8 bb h-48" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h4">Ethernet Mac Address</span>
                        <span>{{device.asset_tag}}</span>
                    </div>
                    <div class="py-8 bb h-48" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h4">Teamviewer Id</span>
                        <span>{{device.teamviewer_id}}</span>
                    </div>


                </div>

            </div>
        </div>
    </div>

    <ng-template #noRecord>
        <div style="text-align: center" class="p-16">
            No Records found
        </div>
    </ng-template>

</div>

