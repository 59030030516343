<div app-looma-page pageTitle="Survey question data" pageIcon="analytics" [headerSideTpl]="headerSideTpl">
    <div style="display: flex; flex-direction: column; width: 100%; padding: 20px; overflow: scroll"
         *ngIf="contentIndex">
        <mat-card style="margin-top: 10px;" *ngFor="let i of getContentKeys()">
            <mat-card-title style="font-size: 17px">{{getContentByKey(i)}}</mat-card-title>
            <mat-card-content>
                <table mat-table [dataSource]="getContentValues(i)" #tbl cdkDropList
                       (cdkDropListDropped)="dropTable($event)" [cdkDropListData]="i.value"
                       style="width: 100%">
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef style="width: 70px"> No.</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon cdkDragHandle>reorder</mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="imageUrl">
                        <th mat-header-cell *matHeaderCellDef style="width: 130px">Image</th>
                        <td mat-cell *matCellDef="let element">
                            <img [src]="element.imageUrl" style="height: 80px; object-fit: contain"/>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef style="width: 200px">Name</th>
                        <td mat-cell *matCellDef="let element">{{element.displayName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="contentKey">
                        <th mat-header-cell *matHeaderCellDef style="width: 200px">Content Key</th>
                        <td mat-cell *matCellDef="let element">{{element.contentKey}}</td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let element">{{element.description}}</td>
                    </ng-container>

                    <ng-container matColumnDef="extra">
                        <th mat-header-cell *matHeaderCellDef>References</th>
                        <td mat-cell *matCellDef="let element">{{getExtraStr(element.references)}}</td>
                    </ng-container>

                    <ng-container cdkColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef style="text-align: right">Action</th>
                        <td mat-cell *matCellDef="let element" style="text-align: right">
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Survey data actions">
                                <mat-icon>more_horiz</mat-icon>
                            </button>

                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="onEdit(element, element.surveyContentType)">Edit</button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"></tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">

        <button mat-raised-button *ngIf="hasNoContent" (click)="copyContent()">
            <mat-icon>content_copy</mat-icon>
            Copy Content
        </button>
        
        <button mat-raised-button [matMenuTriggerFor]="menu">
            <mat-icon>add</mat-icon>
            Add new content
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let i of allSurveyContentTypes"
                    (click)="addContentType(i.id)">{{i.name}}</button>
        </mat-menu>

        <button mat-raised-button (click)="goToManageProducts()" color="warn">
            <mat-icon>toc</mat-icon>
            Manage products
        </button>
    </div>
</ng-template>