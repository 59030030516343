<div app-looma-page pageTitle="Devices" pageIcon="tablet_android" [headerSideTpl]="headerSideTpl">
    <div [class.hidden]="!filterVisible"
         style="background: #f1f1f1; box-shadow: inset 0px -2px 4px 1px rgba(0, 0, 0, 0.1)" class="p-16">
        <app-looma-grid-filter
            (onFilterConditionsChanged)="onSearchCriteriaChanged($event, true)"></app-looma-grid-filter>
        <div class="mt-16" *ngIf="lastReceivedSearchCriteria?.filters.length">
            <button mat-raised-button (click)="saveCriteria(lastReceivedSearchCriteria)">Save</button>
            <button mat-raised-button color="warn" (click)="clearSearchCriteria()" class="ml-16">Clear</button>
        </div>
    </div>
    <looma-grid [dataSource]="gridDataSource">
        <ng-template gridColumn="deviceStatus" let-data="data">
            <device-status-icon [status]="data.deviceStatus"></device-status-icon>
        </ng-template>

        <ng-template gridColumn="retailerStoreAddress" let-data="data">
            <span style="font-size: 0.8em">{{data.retailerStoreAddress}}</span>
        </ng-template>

        <ng-template gridColumn="lastPlaybackReportedAt" let-data="data">
            <ng-content
                *ngTemplateOutlet="dateSince; context:{date: data.lastPlaybackReportedAt, since: data.secondsSincePlaybackReported}"></ng-content>
        </ng-template>

        <ng-template gridColumn="lastRebootedAt" let-data="data">
            <ng-content
                *ngTemplateOutlet="dateSince; context:{date: data.lastRebootedAt, since: data.secondsSinceRebooted}"></ng-content>
        </ng-template>

        <ng-template #dateSince let-inDate="date" let-since="since">
            <span matTooltip="{{inDate | date:'short' }}">{{since | dateSince}}</span>
        </ng-template>

        <ng-template gridColumn="deviceId" let-data="data">
            <a [routerLink]="['/devices', data.deviceId]" style="color: #006064;">{{data.deviceId}}</a>
        </ng-template>

        <ng-template gridColumn="deviceId" let-data="data">
            <a [routerLink]="['/devices', data.deviceId]" style="color: #006064;">{{data.deviceId}}</a>
        </ng-template>

        <ng-template gridColumn="kioskModeAppName" let-data="data">
            <div style="font-size: 0.8em" matTooltip="{{data.kioskModeAppName}}">{{data.kioskModeAppName}}</div>
        </ng-template>

        <ng-template gridColumn="assignedCampaignName" let-data="data">
            <div style="font-size: 0.8em">{{data.assignedCampaignName}}</div>
        </ng-template>

        <ng-template gridColumn="activeCampaignName" let-data="data">
            <div style="font-size: 0.8em">{{data.activeCampaignName}}</div>
        </ng-template>

        <ng-template gridColumn="activePlaylistReference" let-data="data">
            <div class="ellipsize pr-16" style="font-size: 0.8em"
                 matTooltip="{{data.activePlaylistReference}}">{{data.activePlaylistReference}}</div>
        </ng-template>

        <ng-template gridColumn="assignedPlaylistReference" let-data="data">
            <div class="ellipsize pr-16" style="font-size: 0.8em"
                 matTooltip="{{data.assignedPlaylistReference}}">{{data.assignedPlaylistReference}}</div>
        </ng-template>

        <ng-template gridColumn="ssid" let-data="data">
            <span class="ellipsize pr-16" style="font-size: 0.8em"
                  matTooltip="{{data.ssid}}">{{data.ssid}}</span>
        </ng-template>

        <ng-template gridColumn="outdatedAppsCount" let-data="data">
            <span style="text-align: center;width: 100%"
                  [style.color]="data.outdatedAppsCount > 0 ? 'red' : ''">{{data.outdatedAppsCount}}</span>
        </ng-template>
    </looma-grid>
</div>

<ng-template #headerSideTpl>

    <div class="search" flex fxLayout="row" fxLayoutAlign="start center" >
        <mat-icon style="color: rgba(0, 0, 0, 0.54);">search</mat-icon>
        <input [formControl]="searchInput" placeholder="Enter text to search..." fxFlex>
        <button mat-icon-button color="primary" *ngIf="getSavedFilter()" (click)="setFilter('')">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    

    <button mat-icon-button [matMenuTriggerFor]="appMenu" class="hide-sm">
        <mat-icon>settings</mat-icon>
    </button>
    <button mat-icon-button (click)="filterVisible=!filterVisible" class="hide-sm">
        <mat-icon>filter_list</mat-icon>
    </button>

    <button mat-icon-button (click)="downloadCsv()" class="hide-sm">
        <mat-icon>download</mat-icon>
    </button>


    <app-device-command-button [disabled]="gridDataSource.hasEmptySelection()"
                               (commandSelected)="invokeCommand($event)" class="hide-sm"></app-device-command-button>


    <mat-menu #appMenu="matMenu">
        <div cdkDropList (cdkDropListDropped)="onColumnSortDrop($event)">
            <div class="p-8" *ngFor="let column of gridDataSource.customizableColumns" cdkDrag>
                <mat-icon cdkDragHandle class="drag-handle">drag_handle</mat-icon>
                <mat-checkbox [(ngModel)]="column.visible"
                              (click)="$event.stopPropagation(); onColumnSelectionChanged(column, $event)">{{column.label}}</mat-checkbox>
            </div>
        </div>
    </mat-menu>

</ng-template>





