<div>
    <mat-form-field style="width: 100%">
        <mat-label>Search by Name or Looma ID</mat-label>
        <input #inputElement [formControl]="searchInput" matInput fxFlex (focusin)="handleInputFocus()"/>
    </mat-form-field>

    <ng-template>
        <div class="menu-surface">
            <div class="hint" *ngIf="searchResultData.state=='none'">No text to search</div>
            <div class="hint" *ngIf="searchResultData.state=='loading'">Searching...</div>
            <div class="hint" *ngIf="searchResultData.state=='empty'">No Results</div>
            <div *ngIf="searchResultData.state=='ready'">

                <mat-selection-list [multiple]="false" (selectionChange)="handleSelectionChanged($event)">
                    <strong *ngIf="searchResultData.parentBrands?.length">Brands</strong>
                    <mat-list-option *ngFor="let brand of searchResultData.parentBrands" [value]="brand">
                        {{brand.name}}
                    </mat-list-option>
                    <strong *ngIf="searchResultData.childBrands?.length">Child Brands</strong>
                    <mat-list-option *ngFor="let brand of searchResultData.childBrands" [value]="brand">
                        {{brand.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

        </div>
    </ng-template>

</div>