import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {AppConfigService} from '../../../services/config.service';
import {Utils} from '@looma/shared/utils';


@LifecycleHooks()
@Component({
    selector     : 'app-layout-component',
    templateUrl  : './app_layout.component.html',
    styleUrls    : ['./app_layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppLayoutComponent implements OnInit, OnDestroy {
    fuseConfig: any;

    constructor(private appConfigSer: AppConfigService){
    }

    ngOnInit(): void{
        this.appConfigSer.onConfigurationChanged()
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }
    
    ngOnDestroy(): void{
    }
}
