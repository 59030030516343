import {BaseModel} from "@looma/shared/models/base_model";
import {RetailerPromoPeriodSubmissionSchedule} from "@looma/shared/models/retailer_promo_period_submission_schedule";
import gql from "graphql-tag";
import {RetailerPromoPeriodSubmission} from "@looma/shared/models/retailer_promo_period_submission";

type SelectionState = "opened" | "finalized"

export class RetailerScheduleSubmissionSelection extends BaseModel {
    id: string;
    schedule: RetailerPromoPeriodSubmissionSchedule
    selectedSubmissions: RetailerPromoPeriodSubmission[]
    selectionState: SelectionState
    submittedByUser: string
    groupedSelectedSubmissions: GroupedSelection[]

    assign(obj: any): this {
        super.assign(obj);
        this.assignTypedObject(obj, 'schedule', RetailerPromoPeriodSubmissionSchedule);
        this.assignTypedArray(obj, 'selectedSubmissions', RetailerPromoPeriodSubmission);
        this.assignTypedArray(obj, 'groupedSelectedSubmissions', GroupedSelection);
        return this;
    }

    get groupsForSelection(): GroupedSelection[] {
        if (!this.groupedSelectedSubmissions || this.groupedSelectedSubmissions.length == 0) {
            this.groupedSelectedSubmissions = [
                new GroupedSelection("default", this.selectedSubmissions || [])
            ]
        }
        return this.groupedSelectedSubmissions
    }
}

export class GroupedSelection extends BaseModel {
    groupName: string
    selectedSubmissions: RetailerPromoPeriodSubmission[]

    assign(obj: any): this {
        super.assign(obj);
        this.assignTypedArray(obj, 'selectedSubmissions', RetailerPromoPeriodSubmission);
        return this;
    }

    constructor(groupName: string, selectedSubmissions: RetailerPromoPeriodSubmission[]) {
        super();
        this.groupName = groupName;
        this.selectedSubmissions = selectedSubmissions;
    }
}


export interface RetailerPromoPeriodSubmissionInput {
    id?: string
    scheduleId: string
    selectedSubmissionIds: number[]
    selectionState: SelectionState
    groupedSelection: GroupedSelectionInput[]
}

export interface GroupedSelectionInput {
    selectedSubmissionIds: number[]
    groupName: string
}

export const MUTATION_UPSERT_SUBMISSION_SCHEDULE = gql`
mutation upsertSelection($data: RetailerScheduleSubmissionSelectionInput!) {
     result: upsertSelection(data: $data) {
        id        
      }  
}  
`;

export const MUTATION_REOPEN_SELECTION = gql`
mutation reopen($selectionId: ID!) {
     result: reopen(selectionId: $selectionId) {
        id        
      }  
}  
`;


