import {Component, OnInit} from '@angular/core';
import {ModelDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {Observable} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {
    DeleteItemComponent,
    EditItemComponent,
    ModelListPageComponent
} from "../../../../shared/model_list_page_component";
import {MatDialogConfig} from "@angular/material/dialog";
import {DeviceSlotsEditDialogComponent} from "../device-slots-edit-dialog/device-slots-edit-dialog.component";
import {ButtonActions} from "../../../../shared/button_actions";
import {DeviceSlot, DeviceSlotMutationInput, DeviceSlotSearchCriteria} from "@looma/shared/models/device_slot";
import {NamedValue} from "@looma/shared/types/named_value";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Retailer} from "@looma/shared/models/retailer";
import {FormControl} from "@angular/forms";
import {getYesNoAnyBooleanValue, Utils, YesNoAny} from '@looma/shared/utils';
import {CursorFilter} from '@looma/shared/types/cursor_filter';
import {
    DeviceSlotInstallationWarningsDialogComponent
} from "../device-slot-installation-warnings-dialog/device-slot-installation-warnings-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-device-slot-list',
    templateUrl: './device-slots-list.component.html',
    styleUrls: ['./device-slots-list.component.scss']
})
export class DeviceSlotsListComponent extends ModelListPageComponent<DeviceSlot, DeviceSlotSearchCriteria>
    implements OnInit,
        EditItemComponent<DeviceSlot>, DeleteItemComponent<DeviceSlot> {

    selectedRetailer: Retailer;
    storeNumberInput: FormControl;

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        super(DeviceSlot);
        this.storeNumberInput = new FormControl();
    }

    ngOnInit(): void {
        this.storeNumberInput.valueChanges
            .pipe(
                takeUntil(Utils.onDestroy(this)),
                debounceTime(300),
                distinctUntilChanged()
            ).subscribe(searchText => {
            this.doStoreNumFilterChanged(searchText);
        });

        const showWarningsForId = this.activatedRoute.snapshot.queryParamMap.get('installation_warnings')
        if (showWarningsForId && !isNaN(parseInt(showWarningsForId))) {
            this.router.navigate(['.'], {replaceUrl: true, relativeTo: this.activatedRoute, queryParams: {}});

            this.svcApi.getDeviceSlotsFeed({
                id: showWarningsForId
            }).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                if (value?.data?.length == 1) {
                    const slot = value.data[0];
                    if (slot.hasInstallationWarnings) {
                        this.showSlotWarnings(slot)
                    }
                }
            })
        }
    }

    get dataSource(): DeviceSlotsDataSource {
        return super.dataSource as DeviceSlotsDataSource;
    }

    initDataSource(): ModelDataSource<DeviceSlot, DeviceSlotSearchCriteria> {
        return new DeviceSlotsDataSource(this.svcApi);
    }

    canEditItem(item: DeviceSlot): boolean {
        return true;
    }

    performEditItem(item: DeviceSlot): Observable<DeviceSlot> {
        const dialogConfig: MatDialogConfig = {}
        dialogConfig.data = {};
        dialogConfig.width = '800px';
        if (item) {
            dialogConfig.data = {
                deviceSlot: item
            };
        }
        return this.svcLayout.openDialogForResult(DeviceSlotsEditDialogComponent, dialogConfig)
    }

    addNewRetailer(): void {
        this.performMenuAction(ButtonActions.Edit, new DeviceSlot())
    }

    onRetailerFilterChanged(retailer: NamedValue): void {
        if (retailer && retailer.value) {
            const dataFilter = {
                id: retailer.value
            };
            this.svcApi.getRetailersFeed(dataFilter)
                .pipe(takeUntil(Utils.onDestroy(this)))
                .subscribe(ret => {
                    this.selectedRetailer = ret.data[0];
                });
        } else {
            this.selectedRetailer = null;
        }

        this.dataSource.applyFilter({
            retailer_id: retailer?.value || null
        })
    }

    private doStoreNumFilterChanged(searchText: string): void {
        searchText = (searchText || '').trim()
        this.dataSource.applyFilter({
            retailer_store_num: searchText == '' ? null : searchText
        })
    }

    isSlotAssigned(slot: DeviceSlot): boolean {
        return !!(slot.deviceSlotType?.retailerPromoProgram) || false
    }

    getSlotAssignmentName(slot: DeviceSlot): string {
        if (!this.isSlotAssigned(slot)) {
            return 'unassigned'
        }
        return `${slot.deviceSlotType.retailerPromoProgram.name} - ${slot.deviceSlotType.name}`
    }

    onFilterSelectionChanged(what: string, value: string): void {
        switch (what) {
            case 'tablet_warnings':
                const yn = YesNoAny[value]
                this.dataSource.applyFilter({
                    hasTabletInstallationWarnings: getYesNoAnyBooleanValue(yn)
                });
                break;
            case 'looma_placement':
                if (value == 'Any') {
                    value = null
                }
                this.dataSource.applyFilter({
                    loopPlacement: value
                });
                break;
        }
    }

    showSlotWarnings(slot: DeviceSlot) {
        DeviceSlotInstallationWarningsDialogComponent.open(this.svcLayout, slot.unwrap()).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value) {
                this.dataSource.replaceItem(value)
            }
        })
    }

    canDeleteItem(item: DeviceSlot): boolean {
        return item.lastReceivedImage == null && item.device == null;
    }

    performDeleteItem(item: DeviceSlot): Observable<MutationResponse<DeviceSlot>> {
        const data: DeviceSlotMutationInput = {
            id: item.id
        };

        return this.svcApi.mutateDeviceSlot(MutationOperation.Delete, data)
    }
}

class DeviceSlotsDataSource extends ModelDataSource<DeviceSlot, DeviceSlotSearchCriteria> {
    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Name',
                    valueReader: (item: DeviceSlot) => {
                        return item.name;
                    }
                }, {
                    key: 'device',
                    label: 'Assigned Device',
                    valueReader: (item: DeviceSlot) => {
                        return item.device?.device_id;
                    }
                }, {
                    key: 'retailer',
                    label: 'Retailer',
                    valueReader: (item: DeviceSlot) => {
                        return item.store.retailer.retailer_name;
                    }
                }, {
                    key: 'store',
                    label: 'Store Num.',
                    valueReader: (item: DeviceSlot) => {
                        return item.store.getDisplayName();
                    }
                }, {
                    key: 'product-category',
                    label: 'Product category',
                    valueReader: (item: DeviceSlot) => {
                        return item.product_category.category_name;
                    }
                }, {
                    key: 'device_slot_type',
                    label: 'Device Slot Type',
                }, {
                    key: 'kiosk_app',
                    label: 'Active Application',
                    valueReader: (slot: DeviceSlot) => {
                        return slot.kioskDeviceApp?.getDisplayName()
                    }
                }, {
                    key: 'looma-phase',
                    label: 'Looma Phase',
                    valueReader: (item: DeviceSlot) => {
                        return item.looma_phase;
                    }
                }, {
                    key: 'last_received_image',
                    label: 'Last Received Image',
                    valueReader: (item: DeviceSlot) => {
                        return item.lastReceivedImage?.id;
                    }
                }, {
                    key: 'last_scored_image',
                    label: 'Last Scored Image',
                    valueReader: (item: DeviceSlot) => {
                        return item.lastReceivedImage?.id;
                    }
                }, {
                    key: 'has_warnings',
                    label: '',
                    width: '30px',
                    valueReader: (item: DeviceSlot) => {
                        return item.hasInstallationWarnings
                    }
                }
            ]
        });
    }

    loadData(dataFilter: CursorFilter): Observable<CursorFeed<DeviceSlot>> {
        return this.svcApi.getDeviceSlotsFeed(dataFilter)
    }

    replaceItem(item: DeviceSlot): boolean {
        return super.replaceItem(item);
    }
}



