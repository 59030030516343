import {BaseModel} from '@looma/shared/models/base_model';
import {MediaFileVersion} from '@looma/shared/models/media_file_version'
import {MediaFile} from '@looma/shared/models/media_file'
import {PromoPlaylistAssignment} from '@looma/shared/models/promo_playlist_assignment'

let sEmptyVariant: MediaFileVariant

export class MediaFileVariant extends BaseModel {

    static get EMPTY(): MediaFileVariant {
        if (!sEmptyVariant) {
            sEmptyVariant = new MediaFileVariant();
            sEmptyVariant.id = '-1';
            sEmptyVariant.display_name = 'Empty Media File';
        }
        return sEmptyVariant
    }

    id: string;
    display_name: string;
    external_url: string;
    vimeo_video_id: string;
    external_thumb_url: string;
    external_download_url: string;
    proxy_url: string;
    processed_url: string;
    preview_url: string;
    is_active: boolean;
    description: string;
    media_file_versions: MediaFileVersion[];
    splash_screen: MediaFileVersion;
    defaultMediaFileVersion: MediaFileVersion;
    media_file: MediaFile;
    scheduledPromoPlaylists: PromoPlaylistAssignment[];

    assign(input: any): this {
        super.assign(input);
        if (this.isEmptyVariant()) {
            return MediaFileVariant.EMPTY as this;
        }
        this.assignTypedArray(input, 'media_file_versions', MediaFileVersion);
        this.assignTypedObject(input, 'splash_screen', MediaFileVersion);
        this.assignTypedObject(input, 'defaultMediaFileVersion', MediaFileVersion);
        this.assignTypedObject(input, 'media_file', MediaFile);
        this.assignTypedArray(input, 'scheduledPromoPlaylists', PromoPlaylistAssignment)
        return this;
    }

    isNewRecord(): boolean {
        return !this.id
    }

    isEmptyVariant(): boolean {
        return (this.id + '') == '-1'
    }
}

export interface MediaFileVariantInput {
    mediaFileId?: string,
    id?: string,
    displayName?: string,
    description?: string,
    vimeoVideoId?: string
}
