<h2 mat-dialog-title>Home screen for {{this.surveyVersion.retailerPromoPeriod.name}}
    - {{this.surveyVersion.deviceSlotSegment.name}}</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <mat-label>Home screen name</mat-label>
        <input matInput formControlName="homeScreenName" autocomplete="off"/>
    </mat-form-field>

    <div style="padding: 20px">
        <a [href]="surveyVersion?.homeScreenUrl" target="_blank">{{surveyVersion?.homeScreenUrl}}</a>
    </div>

    <button mat-icon-button (click)="openFilePicker()">
        <mat-icon>add</mat-icon>
        Upload home screen html
    </button>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSave()" [disabled]="isBusy">
        Save
    </button>
    <button mat-button (click)="onCancel()" [disabled]="isBusy">cancel</button>
</mat-dialog-actions>
