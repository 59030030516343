import {BaseModel} from "@looma/shared/models/base_model";
import {Component, OnDestroy} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {LayoutService} from "../services/layout.service";
import {merge, Observable, of} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {bufferTime, flatMap, map, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {AbstractControl, FormGroup} from "@angular/forms";

@Component({
    template: ''
})
// tslint:disable-next-line:component-class-suffix
export abstract class ModelEditDialog<T extends BaseModel> implements OnDestroy {

    get isBusy(): boolean {
        return this.busyLocks > 0
    }

    abstract dialogRef: MatDialogRef<any>;
    abstract svcLayout: LayoutService;

    private busyLocks = 0;

    private formControlErrors = new Map<AbstractControl, string[]>()
    private registeredFormControls = new Set<AbstractControl>()
    private registeredFormGroups = new Set<FormGroup>()


    submitMutation(obs: Observable<MutationResponse<T>>) {
        this.wrap(obs).subscribe(value => {
            if (value.success) {
                this.dialogRef.close(value.data)
            } else {
                this.svcLayout.showMutationResultMessage(value)
            }
        }, error => {
            this.svcLayout.showSnackMessage('Unexpected error')
        });
    }

    ngOnDestroy(): void {
    }

    handleAction(action: 'ok' | 'cancel'): void {
        switch (action) {
            case 'cancel':
                this.dialogRef.close(null);
                break;
            case 'ok':
                const obs = this.onSave();
                if (!obs) {
                    return;
                }
                this.submitMutation(obs);
        }
    }

    canSave(): boolean {
        if (this.registeredFormControls.size > 0) {
            for (const f of this.registeredFormControls) {
                if (f.invalid) {
                    return false
                }
            }
            return true
        }
        return true;
    }


    protected wrap<Q>(src: Observable<Q>): Observable<Q> {
        return new Observable<Q>(subscriber => {
            this.setBusy(true);

            const subscription = src.pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                subscriber.next(value)
            }, error => {
                subscriber.error(error)
            }, () => {
                subscriber.complete()
            });

            return () => {
                subscription.unsubscribe();
                this.setBusy(false)
            }
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        )
    }

    setBusy(busy: boolean) {
        this.busyLocks += busy ? 1 : -1
    }

    abstract onSave(): Observable<MutationResponse<T>> | null;

    registerFormControlsObject(obj: { [key: string]: AbstractControl }) {
        const controls: AbstractControl[] = [];

        for (const key in obj) {
            if (obj[key] instanceof AbstractControl) {
                controls.push(obj[key]);
            }
        }
        if (controls.length) {
            this.registerFormControls(controls);
        }
    }

    registerFormControls(controls: AbstractControl[]) {
        const sources = controls.reduce((accum: Observable<AbstractControl>[], control: AbstractControl) => {
            if ((control instanceof AbstractControl) && !this.registeredFormControls.has(control)) {
                this.registeredFormControls.add(control)
                if (control instanceof FormGroup) {
                    this.registeredFormGroups.add(control)
                }
                const valueChangesSource = control.valueChanges.pipe(
                    map(value => control),
                )

                const statusChangesSource = control.statusChanges.pipe(
                    map(value => control),
                )

                accum.push(merge(valueChangesSource, statusChangesSource));
            }
            return accum
        }, [])

        merge(
            ...sources
        ).pipe(
            bufferTime(100),
            flatMap(value => {
                const values = Array.from(new Set(value))
                return of(...values)
            })
        ).subscribe((value: AbstractControl) => {
            this.invalidateErrorMessages(value)
            this.invalidateErrorMessages(value.parent)
        })

    }

    invalidateErrorMessages(control: AbstractControl) {
        this.formControlErrors.delete(control)
    }


    hasErrorMessage(control: AbstractControl): boolean {
        return !!this.getErrorMessage(control)
    }

    getErrorMessage(control: AbstractControl): string | null {
        if (control instanceof AbstractControl) {
            const err = this.getFormControlErrorMessage(control as AbstractControl)
            if (err) {
                return err
            }
            if (control instanceof FormGroup) {
                const group = control as FormGroup
                for (const control of Object.values(group.controls)) {
                    const err = this.getErrorMessage(control)
                    if (err) {
                        return err
                    }
                }
            }
        }

        return null
    }

    private getFormControlErrorMessage(control: AbstractControl): string | null {
        const errs = this.getFormControlErrorMessages(control)
        if (Array.isArray(errs)) {
            return errs[0]
        }
        return null
    }

    getFormControlErrorMessages(control: AbstractControl): string[] | null {
        if (!this.formControlErrors.has(control)) {
            const errors = new Set<string>()
            if (control.invalid) {
                if (control.errors) {
                    for (const [key, value] of Object.entries(control.errors)) {

                        let msg = ''
                        if (value && value.hasOwnProperty('message')) {
                            msg = value['message']
                        } else {
                            switch (key) {
                                case 'required':
                                    msg = 'This field is required'
                                    break
                                case 'minlength':
                                    msg = `The minimum length for this field is ${value?.minlength?.requiredLength} characters.`
                                    break
                                case 'maxlength':
                                    msg = `The maximum length for this field is ${value?.maxlength?.requiredLength} characters.`
                                    break
                                default:
                                    break

                            }
                        }
                        if (msg != '') {
                            errors.add(msg)
                        } else {
                            console.error("Don't know how to handle this validation error", key, value)
                        }
                    }


                }
            }

            this.formControlErrors.set(control, errors.size > 0 ? Array.from(errors) : null)
        }

        return this.formControlErrors.get(control)
    }

}
