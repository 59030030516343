import {Injectable, NgZone} from '@angular/core';
import firebase from 'firebase/compat/app';
import {BehaviorSubject, EMPTY, from, Observable, of, OperatorFunction, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, filter, flatMap, map, switchMap, take, tap} from 'rxjs/operators';
import {SignInError} from '../../sign_in_error';
import gql from "graphql-tag";
import {NavigationExtras, Router,} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {AuthenticatedUserData, AuthSession} from '@looma/shared/auth/auth_session';
import {secrets} from '@looma/config/secrets';
import {Location} from '@angular/common';
import {SplashScreenService} from '@looma/shared/services/splash-screen.service';
import {User} from '@looma/shared/models/user';
import {PreferencesService} from '@looma/shared/services/preferences.service';
import {isUnauthorizedRequestError, Utils} from '@looma/shared/utils';
import {LoomaLayoutService} from "@looma/shared/services/looma-layout.service";
import 'firebase/compat/auth';
import {HttpClient} from "@angular/common/http";
import IdTokenResult = firebase.auth.IdTokenResult;


@Injectable({
    providedIn: 'root'
})
export class LoomaAuthService {

    private auth: firebase.auth.Auth;

    private sessionChangedSubject = new BehaviorSubject<AuthSession>(null);

    engTeamEmails = [
        "popovici@eloquentix.com",
        "cristian@eloquentix.com",
        "denny@theloomaproject.com",
        "john@theloomaproject.com"
    ]

    constructor(public config: AuthServiceConfig,
                protected router: Router,
                protected apollo: Apollo,
                protected location: Location,
                protected svcSplash: SplashScreenService,
                protected svcPrefs: PreferencesService,
                protected svcLayout: LoomaLayoutService,
                protected ngZone: NgZone,
                protected httpClient: HttpClient
    ) {

        firebase.initializeApp(secrets.firebase);

        this.auth = firebase.auth();

        this.svcSplash.show(this)
        const urlParams = new URLSearchParams(window.location.search);

        const tkn = urlParams.get('tkn');
        this.recoverSession(tkn).subscribe(
            result => {
                console.log(result)
                this.svcSplash.hide(this)
            },
            error => {
                if (error.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN') {
                    this.setCurrentSession(null);
                    if (this.config.loginUrl) {
                        this.navigate(this.config.loginUrl);
                    }
                }
            }
        );

        const msg = (urlParams.get('msg') || '').trim();
        if (msg != '') {
            this.svcLayout.showMessage(msg)
        }
    }

    get isInAccountSetupPage(): boolean {
        return this.location.path().startsWith(this.config.accountSetupUrl)
    }

    get isInLoginPage(): boolean {
        return this.location.path().startsWith(this.config.loginUrl)
    }

    get isInDashboardPage(): boolean {
        return this.location.path().startsWith(this.config.dashboardUrl)
    }

    getRedirectUrl() {
        const session = this.currentSession;
        if (session.needsAccountSetup) {
            return this.config.accountSetupUrl
        }
        if (session?.authenticated) {
            return this.config.dashboardUrl
        }
        return this.config.loginUrl
    }


    get currentSession(): AuthSession {
        return this.sessionChangedSubject.value
    }

    get currentUser(): User {
        return this.currentSession?.user
    }

    get currentUserIsAdmin(): boolean {
        return this.currentUser?.isAdmin()
    }

    get currentUserIsEngTeam(): boolean {
        return this.engTeamEmails.indexOf(this.currentUser?.email) >= 0;
    }

    get currentUserHasLoomaEmail(): boolean {
        const email = this.currentUser?.email
        if (email) {
            const regex = new RegExp(`@theloomaproject.com`, 'i');
            return regex.test(email);
        } else {
            return false
        }
    }

    signInWithGoogle(): Observable<SignInResult> {
        return this.signIn(this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()))
    }

    signInWithEmailAndPassword(email: string, pwd: string): Observable<SignInResult> {
        return this.signIn(this.auth.signInWithEmailAndPassword(email, pwd))
    }

    signUp(name: string, email: string): Observable<any> {
        const signUpUrl = Utils.getMainEndpoint() + "users/sign_up"
        const body = {
            name: name,
            email: email,
            signUpFrom: this.config.app,
        };
        return this.httpClient.post(signUpUrl, body);
    }

    private signIn(p: Promise<firebase.auth.UserCredential>): Observable<SignInResult> {
        return from(p).pipe(
            map(credential => {
                return credential && credential.user || null
            }),
            this.signInWithUser(),
        )
    }

    protected recoverSession(tkn?: string): Observable<SignInResult> {
        const authReadyObs = new Observable<boolean>(subscriber => {
            this.auth.onAuthStateChanged(a => {
                subscriber.next(true);
                subscriber.complete();
            })
        });

        return authReadyObs.pipe(
            switchMap((value: any) => {
                // if we have a tkn query parameter, try to login using it
                if (tkn) {
                    return this.signOut(true).pipe(
                        flatMap(value1 => {
                            return from(this.auth.signInWithCustomToken(tkn))
                        }),
                        map((credential: firebase.auth.UserCredential) => {
                            return credential.user
                        })
                    )
                }
                return of(this.auth.currentUser)
            }),
            this.signInWithUser(),
            take(1)
        )
    }


    private navigate(url: string, extras?: NavigationExtras): void {
        if (!url) {
            return
        }
        this.ngZone.run(() => {
            this.router.navigate([url], extras).then()
        })
    }

    appendAuthorizationHeaders(originalHeaders?: any): Observable<any> {
        const authHeader = 'Authorization';

        if (!originalHeaders) {
            originalHeaders = {}
        }
        originalHeaders['X-Auth-Acl'] = this.config.acl.toString();
        originalHeaders['X-App'] = this.config.app.toString();

        if (originalHeaders && originalHeaders[authHeader]) {
            return of(originalHeaders);
        }

        return this.getIdToken().pipe(
            map(idToken => {

                if (idToken) {
                    if (!originalHeaders) {
                        originalHeaders = {}
                    }
                    originalHeaders[authHeader] = idToken.token;
                }

                return originalHeaders;
            })
        );
    }


    getIdToken(user: firebase.User = this.auth.currentUser): Observable<firebase.auth.IdTokenResult> {
        if (!user) {
            return of(null)
        }
        return from(user.getIdTokenResult())
    }

    activateUser(user: firebase.User): Observable<SignInResult> {
        return from(user.getIdToken(false)).pipe(
            switchMap(idToken => {
                return this.apollo.mutate<Response>({
                    mutation: gql`
                        mutation activate {
                            user(id: "me", op:Update data: {activateAccount: true}) {
                                success
                                message
                                user {
                                    id
                                    email
                                    display_name
                                }
                            }
                        }`,
                    context: {
                        headers: {
                            Authorization: idToken
                        }
                    }
                }).pipe(
                    flatMap(value => {
                        return of(this.auth.currentUser)
                    }),
                    this.signInWithUser(),
                    catchError(err => {
                        if (isUnauthorizedRequestError(err)) {
                            return throwError(SignInError.UnauthorizedRequestError)
                        }
                        return throwError(err);
                    })
                )
            })
        ) as Observable<SignInResult>

    }

    signOut(silent = false): Observable<boolean> {
        return new Observable<boolean>(subscriber => {
            const done = (success: boolean, userChanged: boolean) => {
                if (success && userChanged) {
                    if (!silent) {
                        this.setCurrentSession(null);
                    }
                }
                this.svcPrefs.clearSessionValues();
                subscriber.next(success);
                subscriber.complete()
            };
            if (this.auth.currentUser) {
                this.auth.signOut().then(value => {
                    done(true, true)
                    if (this.config.loginUrl) {
                        this.navigate(this.config.loginUrl)
                    }
                }, reason => {
                    done(false, true)
                })
            } else {
                done(true, false)
            }
        }).pipe(
            switchMap(value => {
                if (value) {
                    return Utils.clearApolloCache(this.apollo).pipe(
                        map(value1 => true)
                    )
                }
                return of(value)
            })
        )
    }

    resetPassword(email: string): Observable<void> {
        let dashboardUrl = secrets.loopDashboardUrl
        if (this.isMerchantDash()) {
            dashboardUrl = secrets.merchantDashboardUrl
        }
        return from(
            this.auth.sendPasswordResetEmail(email, {
                url: dashboardUrl,
            })
        )
    }

    isLoopDash(): boolean {
        return this.config.app == AppName.LoopDashboard
    }

    isHTSubmissionPortal(): boolean {
        return this.config.app == AppName.HtSubmissionPortal
    }

    isMerchantDash(): boolean {
        return this.config.app == AppName.MerchantDashboard
    }

    linkGoogleAccount(emailAddrHint?: string): Observable<firebase.User> {
        const user = this.getFirebaseUser();
        if (!user) {
            return throwError(new SignInError("not logged in"))
        }
        const provider = new firebase.auth.GoogleAuthProvider();
        if (emailAddrHint == '') {
            emailAddrHint = null;
        }

        return from(user.getIdToken(true)).pipe(
            switchMap(value => {
                return from(user.unlink(provider.providerId)).pipe(
                    catchError(err => {
                        return of(true)
                    }),
                    map(value => true),
                    flatMap(value => {
                        return from(
                            user.linkWithPopup(provider)
                        )
                    }),
                    switchMap((value: firebase.auth.UserCredential) => {
                        return from(firebase.auth().signInWithCredential(value.credential))
                    }),
                    flatMap((value: firebase.auth.UserCredential) => {
                        const user = value.user;
                        if (emailAddrHint) {
                            for (const providerData of user.providerData) {
                                if (providerData.providerId === provider.providerId) {
                                    if (emailAddrHint.toLowerCase() !== providerData.email.toLowerCase()) {
                                        return from(user.unlink(provider.providerId)).pipe(
                                            flatMap(value1 => {
                                                return throwError(new SignInError(`Gmail addreess invalid - expecting a login with ${emailAddrHint}`))
                                            })
                                        )
                                    }
                                }
                            }
                        }

                        return of(user);
                    }),
                ) as Observable<firebase.User>
            })
        )
    }

    updatePassword(password?: string): Observable<firebase.User> {
        const firebaseUser = this.getFirebaseUser();
        if (!firebaseUser) {
            return throwError("User unavailable");
        }

        return from(firebaseUser.getIdToken(true)).pipe(
            switchMap(value => {
                return from(firebaseUser.updatePassword(password)).pipe(
                    map(value => {
                        return this.auth.currentUser
                    }),
                    catchError(err => {
                        console.log(err)
                        this.signUp(firebaseUser.email, firebaseUser.email).subscribe(result => {
                            if (result && result.redirect_url) {
                                window.location.href = result.redirect_url;
                                return EMPTY
                            }
                        })
                        return throwError(err)
                    }),
                    flatMap(value => {
                        return from(this.auth.signInWithEmailAndPassword(firebaseUser.email, password))
                    }),
                    map((credential: firebase.auth.UserCredential) => {
                        return credential.user
                    })
                )
            })
        )

    }

    private getFirebaseUser(user?: firebase.User): firebase.User {
        if (!user) {
            user = this.auth.currentUser
        }
        return user;
    }


    private getUserForToken(idToken: IdTokenResult): Observable<AuthSession> {
        let brandFields = '';
        let retailerFields = '';

        if (this.config.loadUserBrands) {
            brandFields = `
                        brands {
                            id
                            name
                            looma_id                                                        
                            childBrands {
                                id
                                name
                                logoUrl
                                products {
                                    id 
                                    name
                                    upc_code
                                    thumb_url
                                }
                            }
                        }            
            `
        }
        if (this.config.loadRetailers) {
            retailerFields = `
                        retailers {
                            id
                            retailer_name                                                                                   
                        }            
            `
        }
        return this.apollo.mutate<Response>({
            mutation: gql`
                mutation auth($app: AppName!) {
                    authenticate(app: $app) {
                        user {
                            rights{
                                devices
                            }
                            email
                            display_name
                            id
                            photo_url
                            roles {
                                id
                                name
                                permissions {
                                    key
                                }
                            }
                        }
${brandFields}
${retailerFields}
                    }
                }`,
            context: {
                headers: {
                    Authorization: idToken.token
                }
            },
            variables: {
                app: this.config.app
            },
        }).pipe(
            map(value => {
                const authData = new AuthenticatedUserData();
                authData.assign(value.data['authenticate'])
                const authSession = AuthSession.from(idToken, authData)
                const firebaseUser = this.auth.currentUser;
                if (firebaseUser) {
                    let photoUrl: string;
                    if (firebaseUser.photoURL) {
                        photoUrl = firebaseUser.photoURL
                    } else if (Array.isArray(firebaseUser.providerData)) {
                        for (const data of firebaseUser.providerData) {
                            if (!data) {
                                continue
                            }
                            if (data.photoURL) {
                                photoUrl = data.photoURL;
                                break
                            }
                        }
                    }
                    authSession.setProfilePhoto(photoUrl);
                }

                return authSession;
            }),
            catchError(err => {
                if (isUnauthorizedRequestError(err)) {
                    return throwError(SignInError.UnauthorizedRequestError)
                }
                return throwError(err);
            })
        );
    }

    private setCurrentSession(newSession: AuthSession) {
        if (!(newSession instanceof AuthSession)) {
            newSession = AuthSession.empty();
        }
        if (!this.currentSession || !AuthSession.sessionsEqual(newSession, this.currentSession)) {
            this.sessionChangedSubject.next(newSession);
        }
    }

    onSignedIn(signedIn: boolean): Observable<boolean> {
        return this.onSessionAvailable().pipe(
            map(value => value.authenticated === signedIn)
        )
    }


    onSessionAvailable(): Observable<AuthSession> {
        return this.sessionChangedSubject.pipe(
            filter(value => !!value),
            take(1)
        )
    }

    onSessionChanged(): Observable<AuthSession> {
        return this.sessionChangedSubject
    }

    onUserChanged(): Observable<User> {
        return this.sessionChangedSubject.pipe(
            filter(value => !!value),
            map(value => value.user),
            distinctUntilChanged((x, y) => {
                return Utils.itemsEqual(x, y)
            })
        )
    }

    get isAuthenticated(): boolean {
        return !!this.currentUser;
    }

    private signInWithUser(): OperatorFunction<firebase.User, SignInResult> {
        return source => {
            return source.pipe(
                switchMap((user: firebase.User) => {
                    if (!user) {
                        return throwError(new SignInError(`no user`))
                    }
                    return from(user.getIdTokenResult(true))
                }),
                switchMap((idTokenResult: IdTokenResult) => {
                    if (!idTokenResult) {
                        return of(SignInResult.error(`couldn't obtain id token`))
                    }
                    return this.getUserForToken(idTokenResult).pipe(
                        map((authSession: AuthSession) => {
                            return SignInResult.success(authSession, idTokenResult);
                        })
                    )
                }),
                catchError(err => {
                    console.error(err)
                    return of(SignInResult.error(err))
                }),
                flatMap((res: SignInResult) => {
                    if (res.success) {
                        return of(res)
                    }
                    return new Observable(subscriber => {
                        const setDone = (v?: any) => {
                            subscriber.next(res);
                            subscriber.complete()
                        };
                        if (this.auth.currentUser) {
                            this.auth.signOut().then(setDone, setDone)
                        } else {
                            setDone()
                        }
                    })
                }),
                tap((res: SignInResult) => {
                    this.setCurrentSession(res.session);
                })
            )
        }
    }

    setSession(session: AuthSession | null) {
        this.setCurrentSession(session)
        this.svcPrefs.clearSessionValues();
    }
}

export class AuthServiceConfig {
    acl: AuthAcl
    app: AppName
    loginUrl: string
    accountSetupUrl: string
    dashboardUrl: string
    loadUserBrands = false
    loadRetailers = false
}

export enum AuthAcl {
    UptimeAccess = 'UptimeAccess',
    LoopAccess = 'LoopAccess',
    PhotoScoringAccess = 'PhotoScoringAccess',
    MerchantAccess = 'UptimeAccess',
    HtSubmissionPortalAccess = 'HtSubmissionPortalAccess',
}

export enum AppName {
    UptimeDashboard = 'UptimeDashboard',
    LoopDashboard = 'LoopDashboard',
    PhotoScoringDashboard = 'PhotoScoringDashboard',
    MerchantDashboard = 'MerchantPlatform',
    HtSubmissionPortal = 'HtSubmissionPortal',
}

export class SignInResult {
    success: boolean;
    session: AuthSession;
    idToken: IdTokenResult;
    error: Error;

    static error(err: Error | string | SignInResult): SignInResult {
        console.warn('error', err)
        if (err instanceof SignInResult) {
            return err;
        }
        if (!err) {
            err = 'unexpected error'
        }
        if (typeof err === 'string') {
            err = new SignInError(err)
        }
        return new SignInResult(null, null, err)
    }

    static success(user: AuthSession, idToken: IdTokenResult): SignInResult {
        return new SignInResult(user, idToken, null);
    }

    constructor(user: AuthSession, idToken: IdTokenResult, error: Error) {
        this.success = !error;
        this.session = user;
        this.idToken = idToken;
        this.error = error;
    }

    get errorMessage(): string {
        if (!this.error) {
            return null
        }
        let msg: string
        if (this.error instanceof SignInError) {
            msg = this.error.message
        } else if (this.error.hasOwnProperty('code')) {
            const fbErr = (this.error as any) as ErrorWithCode;
            msg = fbErr?.message
        }

        return msg || 'Unexpected error'
    }

}


interface ErrorWithCode {
    code: string
    message: string
}


