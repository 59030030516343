import {
    AfterViewInit,
    Attribute,
    ChangeDetectorRef,
    Component,
    Directive,
    ElementRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {GridDataSource} from './grid-data-source';
import {CdkVirtualScrollViewport, VirtualScrollStrategy} from '@angular/cdk/scrolling';
import {Utils} from '@looma/shared/utils';
import {Sort} from '@angular/material/sort';
import {BehaviorSubject, EMPTY, Observable, Subject} from 'rxjs';
import {debounceTime, last, takeUntil} from 'rxjs/operators';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {CdkDrag, CdkDragStart, CdkDropList} from '@angular/cdk/drag-drop';
import {BaseModel} from '@looma/shared/models/base_model';
import {TableColumnSpec} from './table-column';
import {RowActionRegistry} from '../../../shared/row_action_registry';
import {MatMenuTrigger} from '@angular/material/menu';
import {ListRange} from '@angular/cdk/collections';
import {SafeHtml} from "@angular/platform-browser";
import {SortDirection} from "@looma/shared/types/cursor_filter";

export class GridDragDropController<T extends BaseModel> {
    dragDropEnabled = true;
    sortableEnabled = false;
    connectedDropLists: CdkDropList[] = [];
    dragPreviewTemplate: TemplateRef<any>;
    grid: LoomaGridComponent<T>;
    private _dropList: CdkDropList;
    selectedItems: T[] = [];

    constructor() {
        this.dragEnter = this.dragEnter.bind(this);
    }

    init(grid: LoomaGridComponent<T>, dropList: CdkDropList) {
        this.dragPreviewTemplate = grid.dragPreviewTemplate;
        this.grid = grid;
        this._dropList = dropList;
    }

    get dropList(): CdkDropList {

        if (this._dropList) {
            return this._dropList
        }
        if (this.grid && this.grid.dropList) {
            return this.grid.dropList
        }
        return null;
    }

    public setConnectedDropLists(items: CdkDropList[]) {
        if (!Array.isArray(items)) {
            items = [];
        }
        this.connectedDropLists = items;
    }

    onDragStart(ev: CdkDragStart) {

        const src = ev.source;

        const data = ev.source.data as T;
        let selection = [data];
        if (this.grid.dataSource.hasItemsSelected()) {
            const selected = this.grid.dataSource.getSelectedData() as T[];
            const included = !!selected.find(value => value.getId() == data.getId());
            if (included) {
                selection = selected;
            }
        }

        this.selectedItems = selection;

        ev.source.ended.subscribe(_ => {
            this.selectedItems = [];
        });
        this.observerMouseMove(src).subscribe(ev => {

            const availableDropTargets = this.connectedDropLists.concat([src.dropContainer]);
            let targets = availableDropTargets.filter(value => {
                return Utils.mouseEventWithin(ev, value.element)
            });

            if (targets.length > 1) { // this happens if we have multiple stacked drop zones
                let htmlEl = ev.target as HTMLElement;
                LOOKUP:
                    do {
                        for (const t of targets) {
                            if (t.element.nativeElement == htmlEl) {
                                targets = [t];
                                break LOOKUP;
                            }
                        }
                        htmlEl = htmlEl.parentElement;
                        if (!htmlEl) {
                            targets = [];
                            break LOOKUP;
                        }
                    } while (true)
            }

            if (targets.length == 1) {
                const target = targets[0];
                if (target == src.dropContainer) {
                    return
                } else {
                    this.onDropListDrop(selection, target);
                }
            }

        });

    }

    isDragEnabled(ds: GridDataSource<T, any>, row: T): boolean {
        return true;
    }

    // dest is null when the item is dropped outside any connected CdkDropList
    onDropListDrop(src: T[], dest: CdkDropList<any>) {
        console.warn('onDropListDrop', arguments)
    }

    dragEnter(drag: CdkDrag, drop: CdkDropList): boolean {
        return false
    }


    private observerMouseMove(drop: CdkDrag): Observable<MouseEvent> {
        return new Observable<MouseEvent>(subscriber => {

            const target = document.body;

            const listener = (ev) => {
                subscriber.next(ev)
            };

            target.addEventListener('mousemove', listener);

            return () => {
                target.removeEventListener('mousemove', listener);
            }

        }).pipe(
            takeUntil(drop.dropped),
            last()
        );
    }

}

interface RowInfo {
    height: number
    id: string
    offset: number
}

export class DynamicSizeVirtualScrollStrategy implements VirtualScrollStrategy {

    private viewport: CdkVirtualScrollViewport;
    private rowSizeCache = new Map<string, RowInfo>();
    private orderedItemIds: string[] = [];
    scrolledIndexChange = new Subject<number>();
    attachedSubject = new Subject<CdkVirtualScrollViewport>();
    private renderRangeSubject = new BehaviorSubject<ListRange>({start: 0, end: 0});


    constructor(private minItemHeight: number = DEFAULT_ROW_HEIGHT, private renderItemsBefore = 2, private renderItemsAfter = 2) {
    }

    onContentRendered(): void {

    }

    attach(viewport: CdkVirtualScrollViewport): void {
        if (viewport == this.viewport) {
            return
        }
        this.attachedSubject.next(viewport);
        this.viewport = viewport;

        if (!viewport) {
            return
        }

        const changeObserver = new Observable<HTMLElement>(subscriber => {

            const watchedEl = viewport.elementRef.nativeElement.firstChild as HTMLElement;

            const mutationObserver = new MutationObserver(() => {
                subscriber.next(watchedEl)
            });

            mutationObserver.observe(viewport.elementRef.nativeElement.firstChild, {
                attributes: true,
                // characterData: true,
                childList: true,
                subtree: true,
                attributeOldValue: true,
                // characterDataOldValue: true
            });

            return () => {
                mutationObserver.disconnect();
            }
        });

        changeObserver.pipe(
            debounceTime(100),
            takeUntil(this.attachedSubject)
        ).subscribe(rootEl => {
            let changed = false;
            // each child of cdk-virtual-scroll-viewport should have data-row-id and data-row-index attributes defined
            // <div *cdkVirtualFor="let row of dataSource.onData() | async; let index = index;" [attr.data-row-id]="row.getId()" [attr.data-row-index]="index">
            rootEl.childNodes.forEach((el: HTMLElement) => {
                if (!Utils.isFunction(el?.getAttribute)) {
                    return;
                }
                const id = el.getAttribute('data-row-id');
                const idx = parseInt(el.getAttribute('data-row-index'), 10);

                if (!id || isNaN(idx)) {
                    return
                }

                let rowInfo = this.rowSizeCache.get(id);

                const clientHeight = el.clientHeight;

                if (!rowInfo) {
                    rowInfo = {height: clientHeight, offset: 0, id: id};
                    this.rowSizeCache.set(id, rowInfo)
                    changed = true
                }

                if (rowInfo.height != clientHeight) {
                    rowInfo.height = clientHeight;
                    changed = true
                }

                this.orderedItemIds[idx] = id;
            })

            if (changed) {
                this.updateTotalContentSize();
                this.updateRenderedRange()
            }

        })

    }

    private updateRenderedRange() {
        if (!this.viewport) {
            return;
        }

        // TODO handle item removal
        // TODO handle reordering items
        // TODO various other optimizations can be performed as we don't always need to check each row height

        const scrollOffset = this.viewport.measureScrollOffset();
        const viewportSize = this.viewport.getViewportSize();
        const maxOffset = scrollOffset + viewportSize;
        const maxLastVisibleIndex = Math.ceil(maxOffset / this.minItemHeight);

        const dataLength = this.viewport.getDataLength();

        let offset = 0;
        const visibleRange = {startIndex: NaN, endIndex: NaN, startOffset: 0, endOffset: 0}; // contains rows which are partially or fully visible
        for (let i = 0; i < Math.min(dataLength, maxLastVisibleIndex); i++) {

            let itemHeight = this.minItemHeight;
            const itemId = this.orderedItemIds[i];
            if (itemId) {
                const rowInfo = this.rowSizeCache.get(itemId)
                rowInfo.offset = offset
                itemHeight = rowInfo.height
            }

            if (offset > maxOffset) {
                break;
            }

            if (offset + itemHeight >= scrollOffset) {
                if (isNaN(visibleRange.startIndex)) {
                    visibleRange.startIndex = i;
                    visibleRange.startOffset = offset;
                }

                visibleRange.endIndex = i;
                visibleRange.endOffset = offset + itemHeight;
            }

            offset += itemHeight;
        }

        const firstVisibleIndex = visibleRange.startIndex;

        for (let i = this.renderItemsBefore; i > 0 && visibleRange.startIndex > 0; i -= 1) {
            visibleRange.startIndex -= 1;
            visibleRange.startOffset -= this.getItemHeight(visibleRange.startIndex)
        }

        for (let i = this.renderItemsAfter; i > 0 && visibleRange.endIndex < dataLength - 1; i -= 1) {
            visibleRange.endIndex += 1;
            visibleRange.endOffset += this.getItemHeight(visibleRange.endIndex)
        }

        const renderRange: ListRange = {start: visibleRange.startIndex, end: visibleRange.endIndex + 1};
        // console.warn('renderRange', renderRange);
        if (isNaN(renderRange.start) || isNaN(renderRange.end)) {
            return;
        }

        this.viewport.setRenderedRange(renderRange);
        this.viewport.setRenderedContentOffset(visibleRange.startOffset);
        this.scrolledIndexChange.next(firstVisibleIndex);

        const prevRange = this.renderRangeSubject.value;
        if ((prevRange.end != renderRange.end) || (prevRange.start != renderRange.start)) {
            this.renderRangeSubject.next(renderRange);
        }
    }

    private onItemHeightChanged(index: number, newHeight: number) {
        const id = this.orderedItemIds[index];
        if (id && this.rowSizeCache.has(id)) {
            const heightDiff = newHeight - this.rowSizeCache.get(id).height;
            if (heightDiff != 0) {
                for (let i = index; i < this.orderedItemIds.length; i += 1) {
                    const siblingId = this.orderedItemIds[i];
                    if (!siblingId) {
                        break
                    }
                    const siblingSizeInfo = this.rowSizeCache.get(siblingId);
                    if (!siblingSizeInfo) {
                        break
                    }
                    siblingSizeInfo.offset += heightDiff;
                }
            }
        }
    }

    private onItemRemoved(index: number) {
        if (index < this.orderedItemIds.length) {
            const delId = this.orderedItemIds.splice(index, 1)[0]
            const delSizeInfo = this.rowSizeCache.get(delId)
            this.rowSizeCache.delete(delId)
            this.adjustItemOffset(index, delSizeInfo.offset)
        }
    }

    private adjustItemOffset(startIndex: number, offsetDelta: number) {
        if (offsetDelta == 0) {
            return
        }

        for (let i = startIndex; i < this.orderedItemIds.length; i += 1) {
            const id = this.orderedItemIds[i];
            if (!id) {
                break
            }
            const sizeInfo = this.rowSizeCache.get(id);
            if (!sizeInfo) {
                break
            }
            sizeInfo.offset -= offsetDelta;
        }
    }

    private updateTotalContentSize() {
        if (!this.viewport) {
            return;
        }

        let totalHeight = 0;

        for (let i = 0; i < this.viewport.getDataLength(); i++) {
            totalHeight += this.getItemHeight(i);
        }
        this.viewport.setTotalContentSize(totalHeight);
    }

    private getItemHeight(index: number): number {
        const itemId = this.orderedItemIds[index];
        if (itemId) {
            return this.rowSizeCache.get(itemId)?.height || this.minItemHeight;
        }
        return this.minItemHeight;
    }

    private getItemOffset(index: number): number {

        return 0;
        //
        // let offset = 0;
        // const rowInfo = this.rowSizeCache.get(this.orderedItemIds[index]).offset
        // const id = this.orderedItemIds[index];
    }


    detach(): void {
        if (this.viewport) {
            this.viewport = null;
            this.attachedSubject.next(null)
        }
    }

    onRenderRangeChanged(): Observable<ListRange> {
        return this.renderRangeSubject
    }

    onContentScrolled(): void {
        this.updateRenderedRange();
    }

    onDataLengthChanged(): void {
        this.updateTotalContentSize();
        this.updateRenderedRange();
    }

    onRenderedOffsetChanged(): void {
    }

    scrollToIndex(index: number, behavior?: ScrollBehavior): void {
        this.viewport?.scrollToOffset(this.getItemOffset(index), behavior)
    }


}

class ExternalRowActions<T extends BaseModel> {
    registry: RowActionRegistry<T>;
    template: TemplateRef<any>;
    registeredComponents = new WeakMap<LoomaGridComponent<any>, boolean>()

    get isValid(): boolean {
        return !!this.registry && !!this.template;
    }

    register(cmp: LoomaGridComponent<T>): boolean {
        if (this.isValid && !this.registry.isEmpty) {
            const prevRegistered = this.registeredComponents.get(cmp);
            if (!prevRegistered) {
                cmp.registerActionsColumn(this.registry.buttonsCount, this.template)
                this.registeredComponents.set(cmp, true)
                return true
            }
        }
        return false
    }
}

const ROW_ACTION_BUTTON_SIZE = 30;// size in pixels
const DEFAULT_ROW_HEIGHT = 48;

@LifecycleHooks()
@Component({
    selector: 'looma-grid',
    templateUrl: './looma-grid.component.html',
    styleUrls: ['./looma-grid.component.scss'],
})
export class LoomaGridComponent<T extends BaseModel> implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private renderer: Renderer2,
        private elRef: ElementRef<any>,
        public changeDetectorRef: ChangeDetectorRef
    ) {
    }

    @Input('dragDropController') set dragDropController(v: GridDragDropController<T>) {

        if (this._dragDropController == v) {
            return;
        }
        this._dragDropController = v;

        if (this._dropList) {
            v.init(this, this._dropList);
        }
    }

    get dragDropController(): GridDragDropController<T> {
        return this._dragDropController;
    }

    @Input('rowActions') set rowActions(value: RowActionRegistry<T>) {
        this.externalRowActions.registry = value;
        this.externalRowActions.register(this);
    }

    @ViewChild('tmplRowActions', {static: true}) set defaultActionsMenu(tmpl: TemplateRef<any>) {
        this.externalRowActions.template = tmpl;
        this.externalRowActions.register(this);
    }

    extraBodyContentTemplate: TemplateRef<any>

    @Input('dataSource')
    public set dataSource(dataSource: GridDataSource<any, any>) {
        if (this._gridDataSource && (this._gridDataSource != dataSource)) {
            if (this.isViewInit && dataSource) {
                if (this._gridDataSource) {
                    this._gridDataSource.onViewDetached(this)
                }
                this._gridDataSource = dataSource;
                this.externalRowActions.register(this);
                this.ensureActionsColumnAdded()

                setTimeout(() => {
                    dataSource.onViewInit(this)
                }, 0)
            }
        }
        this._gridDataSource = dataSource
    }

    public get dataSource(): GridDataSource<any, any> {
        return this._gridDataSource;
    }

    @ViewChild(CdkDropList, {static: true}) set dropList(value: CdkDropList) {
        this._dropList = value;
        if (this._dragDropController) {
            this._dragDropController.dragPreviewTemplate = this.dragPreviewTemplate;
            this._dragDropController.init(this, value)
        }
    }

    @Input()
    showHeaders = true


    get dropList(): CdkDropList {
        return this._dropList
    }

    get hasExpandableRows(): boolean {
        return !!this.rowExpansionTemplate || !!this.dataSource?.rowExpansionTemplate
    }

    private cellTemplates = new Map<string, TemplateRef<any>>();
    headerTemplates = new Map<string, TemplateRef<any>>();
    dragPreviewTemplate: TemplateRef<any>;
    emptyContentTemplate: TemplateRef<any>;
    private rowActionsColumn: TableColumnSpec;
    externalRowActions = new ExternalRowActions();

    private isViewInit = false;

    private _dropList: CdkDropList;
    private _dragDropController: GridDragDropController<T>;

    private _title: string
    private _titleHtml: SafeHtml

    @Input('title') set title(v: string | SafeHtml) {
        if (Utils.isString(v)) {
            this._title = v.toString()
        } else {
            this._titleHtml = v
        }

    }

    get titleString(): string {
        return this._title
    }

    get titleHtml(): SafeHtml {
        return this._titleHtml
    }

    get hasTitle(): boolean {
        return !!(this._title || this._titleHtml)
    }

    @Input('scrollContent') scrollContent = true;

    @Input('rowExpansionTemplate') rowExpansionTemplate: TemplateRef<any>;
    @Input('headingTemplate') headingTemplate: TemplateRef<any>;
    private _gridDataSource: GridDataSource<any, any>;

    @ViewChild('rootEl', {static: true}) rootEl: ElementRef<any>;
    @ViewChild('scrollViewport', {static: false}) scrollViewport: CdkVirtualScrollViewport;

    @Output('onItemClick') onItemClickSubject = new Subject<T>();

    @Input('rowHeight')
    public rowHeight = DEFAULT_ROW_HEIGHT;

    private visibleItemsCount = NaN;

    @ViewChild('secondaryRowActionMenuTrigger') trigger: MatMenuTrigger;

    ensureActionsColumnAdded() {
        if (!this.dataSource) {
            return
        }
        if (this.rowActionsColumn) {
            const cols = this.dataSource.gridColumns;

            let idx = cols.findIndex(value => value.key == this.rowActionsColumn.key);
            if (idx < 0) {
                idx = cols.length
                cols.push(this.rowActionsColumn);
            }
            cols[idx] = this.rowActionsColumn
            this.dataSource.setColumns(cols);
            this.dataSource.setCellTemplate(this.rowActionsColumn.key, this.cellTemplates.get(this.rowActionsColumn.key))

        }
    }

    ngAfterViewInit(): void {
        if (this.scrollContent !== false) {
            this.onScrollIndexChanged().pipe(
                takeUntil(Utils.onDestroy(this)) as any
            ).subscribe((value: number) => {
                const lastVisible = value + this.visibleItemsCount;
                if (!isNaN(lastVisible)) {
                    if (lastVisible > this.dataSource.data.length - this.visibleItemsCount) {
                        this.dataSource.triggerLoad()
                    }
                }
            });
        }

    }

    ngOnInit(): void {
        this.isViewInit = true;
        if (!this.scrollContent) {
            this.renderer.addClass(this.elRef.nativeElement, 'no_scroll')
        }

        if (this.rowExpansionTemplate) {
            this.dataSource.setItemsExpandable(true);
        }

        this.ensureActionsColumnAdded();

        this.appendStyles();


        for (const k of Array.from(this.cellTemplates.keys())) {
            this.dataSource?.setCellTemplate(k, this.cellTemplates.get(k))
        }
        Utils.onResize(this.rootEl).subscribe(value => {
            if (this.scrollViewport) {
                this.scrollViewport.checkViewportSize();
                this.visibleItemsCount = Math.ceil(value.height / this.rowHeight);
            }
        });

        this.dataSource?.onViewInit(this);


        if (this.rowExpansionTemplate) {
            let height = parseInt(this.rowHeight + '', 10);
            if (isNaN(height)) {
                height = DEFAULT_ROW_HEIGHT
            }
            height = Math.max(height, DEFAULT_ROW_HEIGHT);
            if (this.scrollViewport) {
                this.scrollViewport['_scrollStrategy'] = new DynamicSizeVirtualScrollStrategy(height)
            }
        }

    }

    ngOnDestroy(): void {
        this.dataSource?.onViewDetached(this);
    }

    handleItemClick(item: T, event: any): void {
        this.onItemClickSubject.next(item)
    }

    public onScrollIndexChanged(): Observable<number> {
        if (this.scrollViewport) {
            return this.scrollViewport.scrolledIndexChange
        }
        return EMPTY;
    }

    public scrollToIndex(index: number): void {
        this.scrollViewport?.scrollToIndex(index)
    }

    appendStyles(): void {
        if (!this.dataSource) {
            return;
        }
        const rules = this.dataSource.visibleColumns.map(col => `.mat-column-${col.key}` + `{ flex: 0 0 ${col.width}; }`);
        rules.push(`.mat-cell{ height:${this.rowHeight}px }`);
        Utils.appendCssRulesUntil(Utils.onDestroy(this), this.rootEl, ...rules);
    }

    sortData(sort: Sort): void {
        let sortDir = SortDirection.None
        switch (sort.direction) {
            case "asc":
                sortDir = SortDirection.Asc
                break
            case "desc":
                sortDir = SortDirection.Desc
                break
        }

        this.dataSource.setSort(this.dataSource.getColumn(sort.active), sortDir)
    }

    registerColumnTemplate(columnId: string, tmpl: TemplateRef<any>): void {
        this.cellTemplates.set(columnId, tmpl)
    }

    registerActionsColumn(actionsCount: number, tmpl: TemplateRef<any>): void {
        const cellKey = 'row_actions';
        this.rowActionsColumn = {
            label: '',
            key: cellKey,
            cellTemplate: tmpl,
            width: `${actionsCount * ROW_ACTION_BUTTON_SIZE}px`
        };
        this.registerColumnTemplate(cellKey, tmpl);
    }

    registerColumnHeaderTemplate(columnId: string, tmpl: TemplateRef<any>): void {
        this.headerTemplates.set(columnId, tmpl)
    }

    setDragPreviewTemplate(tpl: TemplateRef<any>) {
        this.dragPreviewTemplate = tpl;
    }

    handleActionClick(event: MouseEvent): void {
        event.stopPropagation();
        if (this.trigger) {
            this.trigger.closeMenu();
        }
    }

    isMaxWidthCell(col: TableColumnSpec) {
        switch (col.alignContent) {
            case "center":
                return true;
        }
        return false;
    }
}

@Directive({
    selector: 'ng-template[extraGridBodyContent]'
})
export class LoomaGridExtraBodyContent {

    constructor(@Attribute('extraGridBodyContent') gridColumn: string, @Host() parent: LoomaGridComponent<any>, @Host() template: TemplateRef<any>) {
        parent.extraBodyContentTemplate = template
    }

}


@Directive({
    selector: 'ng-template[gridColumn]'
})
export class LoomaGridCellDirective {

    constructor(@Attribute('gridColumn') gridColumn: string, @Host() parent: LoomaGridComponent<any>, @Host() template: TemplateRef<any>) {
        parent.registerColumnTemplate(gridColumn, template);
    }

}

@Directive({
    selector: 'ng-template[rowActions]'
})
export class LoomaGridRowActionsDirective {

    constructor(@Attribute('rowActions') rowActionsCount: number, @Host() parent: LoomaGridComponent<any>, @Host() template: TemplateRef<any>) {
        parent.registerActionsColumn(parseInt(rowActionsCount.toString(), 10), template);
    }

}

@Directive({
    selector: 'ng-template[gridColumnHeader]'
})
export class LoomaGridColumnHeaderDirective {

    constructor(@Attribute('gridColumnHeader') gridColumn: string, @Host() parent: LoomaGridComponent<any>, @Host() template: TemplateRef<any>) {
        parent.registerColumnHeaderTemplate(gridColumn, template);
    }
}

@Directive({
    selector: 'ng-template[dragPreview]'
})
export class LoomaGridDragPreviewDirective {

    constructor(@Host() parent: LoomaGridComponent<any>, @Host() template: TemplateRef<any>) {
        parent.setDragPreviewTemplate(template);
    }
}


@Directive({
    selector: 'ng-template[rowExpansionTemplate]'
})
export class LoomaGridRowExpansionTemplateDirective {

    constructor(@Host() private parent: LoomaGridComponent<any>, @Host() private template: TemplateRef<any>) {
        parent.rowExpansionTemplate = template;
    }

    @Input('rowExpansionTemplate') set rowExpansionTemplate(v: boolean) {
        let newValue = true;
        if (Utils.isString(v)) {
            switch (v + '') {
                case 'true':
                    newValue = true;
                    break;
                case 'false':
                    newValue = false;
                    break;
                default:
                    break;
            }
        } else if (typeof v === 'boolean') {
            newValue = v;
        }
        if (!newValue) {
            if (this.parent.rowExpansionTemplate == this.template) {
                this.parent.rowExpansionTemplate = null;
            }
        } else if (this.parent.rowExpansionTemplate != this.template) {
            this.parent.rowExpansionTemplate = this.template
        }
    }
}

@Directive({
    selector: 'ng-template[gridHeading]'
})
export class LoomaGridHeadingDirective {


    constructor(@Host() private parent: LoomaGridComponent<any>, @Host() template: TemplateRef<any>) {
        parent.headingTemplate = template
    }

    @Input('gridTitle') set gridTitle(title: string) {
        if (Utils.isString(title)) {
            title = title.trim();
            if (title == '') {
                title = null;
            }
        }
        this.parent.title = title;
    }
}


@Directive({
    selector: 'ng-template[emptyDataContent]'
})
export class LoomaGridEmptyDataContentDirective {

    constructor(@Host() parent: LoomaGridComponent<any>, @Host() template: TemplateRef<any>) {
        parent.emptyContentTemplate = template;
    }
}
