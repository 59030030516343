import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SurveyDataListComponent} from './survey-data-list/survey-data-list.component';
import {MatCardModule} from "@angular/material/card";
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import {SurveyDataEditComponent} from './survey-data-edit/survey-data-edit.component';
import {DragDropModule} from "@angular/cdk/drag-drop";


@NgModule({
    declarations: [SurveyDataListComponent, SurveyDataEditComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule,
        MatCardModule,
        DragDropModule
    ]
})
export class SurveyModule {
}
