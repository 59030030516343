<h2 mat-dialog-title>{{dialogData.title}}</h2>
<mat-dialog-content>

    <app-autocomplete-search-field
        *ngIf="!hideDeviceSlotSelectionField"
        placeholder="Device Slot"
        [searchObjectType]="'DeviceSlot'"
        [value]="dialogData.deviceSlot"
        selectionMode="single"
        (valueChanged)="onDeviceSlotChanged($event)"></app-autocomplete-search-field>

    <mat-form-field class="full-width" *ngIf="!dialogData.hideDateField">
        <input matInput [matDatepicker]="datePicker" placeholder="Date" autocomplete="off"
               (dateChange)="onDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>

    <div class="image_preview_container">
        <div *ngFor="let img of uploadedFiles" class="image_preview_box">
            <!--<img *ngIf="img.state == UploadStates.UploadSuccess" [src]="img.downloadUrl" />-->
            <mat-progress-bar mode="indeterminate" *ngIf="img.state == UploadStates.Uploading"></mat-progress-bar>
            <a [href]="img.downloadUrl" target="_blank" *ngIf="img.state == UploadStates.UploadSuccess">
                <img [src]="img.downloadUrl"/>
            </a>
        </div>
    </div>


    <button mat-button (click)="openPicker()" color="warn">
        Add Images
    </button>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!canSaveImage" (click)="handleAction('ok')">Ok</button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>