import {EMPTY, Observable} from 'rxjs';
import {Utils} from '@looma/shared/utils';
import {shareReplay} from 'rxjs/operators';


export class ImageLoader {
    constructor(private size=10) {

    }

    private imageCache = new Map<string, ImageCacheEntry>();

    load(...src: string[]): Observable<string> {
        if (!src) {
            return EMPTY;
        }

        if (src && (src.length === 1)) {
            src.unshift(Utils.BLANK_IMAGE)
        }

        const key = src.filter(value => value && (value != '')).join('-').trim();
        if (key === '') {
            return EMPTY;
        }

        if (this.imageCache.has(key)) {
            const entry = this.imageCache.get(key);
            if (!isNaN(entry.loadedAt)) {
                // need to mark this entry as fresh in order to avoid evicting it later
                entry.setDone();
            }
            return entry.source;
        }

        const source: Observable<string> = new Observable(subscriber => {

            subscriber.next(src.shift());

            (function loadNext(self: ImageLoader) {
                if (!src.length) {

                    subscriber.complete();
                    currentEntry.setDone();
                    self.evictEldest(key);
                    return
                }
                let current = src.shift();
                if (current == Utils.BLANK_IMAGE) {
                    current = null
                }
                if (!current) {
                    loadNext(self);
                } else {
                    const img = new Image();
                    img.onload = ev => {
                        subscriber.next(current);
                        loadNext(self);
                    };

                    img.onerror = ev => {
                        currentEntry.setDone();
                        subscriber.complete();
                    };

                    img.src = current;
                }
            })(this);

        }).pipe(
            shareReplay(1) as any
        );

        const currentEntry = new ImageCacheEntry(key, source);
        this.imageCache.set(key, currentEntry);

        return source
    }

    private evictEldest(skipKey?: string) {
        const src = this.imageCache;
        const size = this.size;
        if (src.size > size) {

            const candidates = Array.from(src.values()).filter(value => !isNaN(value.loadedAt) && value.key != skipKey).sort((a, b) => a.loadedAt - b.loadedAt);
            while (candidates.length && (src.size > size)) {
                const c = candidates.shift();
                src.delete(c.key);
            }
        }
    }
}

class ImageCacheEntry {
    loadedAt = NaN;

    constructor(public key: string, public source: Observable<string>) {

    }

    setDone() {
        this.loadedAt = Date.now();
    }

}