import { Component, OnInit } from '@angular/core';
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";
import { NamedValue } from "@looma/shared/types/named_value";
import { SearchableField, SearchFieldCriteria } from "@looma/shared/search";
import gql from "graphql-tag";
import { ApiDataService } from "../../../../services/api-data.service";
import { takeUntil } from "rxjs/operators";
import { Utils } from "@looma/shared/utils";
import { BrandPromoCampaign } from "@looma/shared/models/brand_promo_campaign";
import { ModelListDataSource } from "../../../../layout/components/looma-grid/grid-data-source";
import { Subscription } from "rxjs";

@LifecycleHooks()
@Component({
    selector: 'app-network-health-history-dashboard-tab',
    templateUrl: './network-health-history-dashboard-tab.component.html',
    styleUrls: [ './network-health-history-dashboard-tab.component.scss' ]
})
export class NetworkHealthHistoryDashboardTabComponent implements OnInit{

    programSearchCriteria: SearchFieldCriteria[];
    selectedRetailerId: number = null
    selectedProgramId: number = null

    brandsDataSource = new BrandCampaignsDataSource()

    private loadSub: Subscription

    constructor(
        private svcApi: ApiDataService
    ){
    }

    ngOnInit(): void{
        this.loadData()
    }

    onRetailerFilterChanged(data: NamedValue){
        const retailerId = data ? parseInt(data.value) : null
        if (retailerId) {
            this.programSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, retailerId).asArray();
        }
        this.selectedRetailerId = retailerId
        this.selectedProgramId = null
        this.loadData()
    }

    onProgramFilterChanged(data: NamedValue){
        const programId = data ? parseInt(data.value) : null
        this.selectedProgramId = programId
        this.loadData()
    }

    loadData(){

        Utils.unsubscribe(this.loadSub)

        this.svcApi.rawQuery({
            query: QUERY_DATA,
            variables: {
                retailerId: this.selectedRetailerId,
                programId: this.selectedProgramId,
            }
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            const entries = Utils.getNestedTypedArray(value, BrandPromoCampaign, 'data', 'brandPromoCampaigns')
            this.brandsDataSource.setLocalData(entries)
        })
    }
}

class BrandCampaignsDataSource extends ModelListDataSource<BrandPromoCampaign>{

    constructor(){
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Name',
                    valueReader: (item: BrandPromoCampaign) => {
                        return item.name
                    }
                },
                {
                    key: 'retailer',
                    label: 'Retailer',
                    width: '180px',
                    valueReader: (item: BrandPromoCampaign) => {
                        return item.retailer?.retailer_name
                    }
                },
                {
                    key: 'program',
                    label: 'Program',
                    width: '180px',
                    valueReader: (item: BrandPromoCampaign) => {
                        return item.promoProgram?.name
                    }
                },
                {
                    key: 'allocated_duration',
                    label: 'Allocated duration',
                    width: '200px',
                    valueReader: (item: BrandPromoCampaign) => {
                        const duration = item.uptimeStats?.allocatedPlaybackDuration
                        if (isNaN(duration)) {
                            return '-'
                        }

                        return Math.round(duration/60_000)
                    }
                },
                {
                    key: 'playback_duration',
                    label: 'Playback duration',
                    width: '200px',
                    valueReader: (item: BrandPromoCampaign) => {
                        const duration = item.uptimeStats?.totalPlaybackDuration
                        if (isNaN(duration)) {
                            return '-'
                        }
                        return Math.round(duration/60_000)
                    }
                },
                {
                    key: 'uptime_percent',
                    label: 'Uptime percent',
                    width: '100px',
                    valueReader: (item: BrandPromoCampaign) => {
                        const percent = item.uptimeStats?.uptimePercent
                        if (isNaN(percent)) {
                            return '-'
                        }
                        return percent+'%'
                    }
                },
            ]
        });
    }

}


const QUERY_DATA = gql`
    query loadBrandPromoCampaigns($retailerId: ID, $programId: ID) {
        brandPromoCampaigns(
            filter: {statuses: [Active, Ended], retailerId: $retailerId, programId: $programId, withAllocatedDuration: true}
        ) {
            id
            name
            promoPeriod {
                id
                name
            }
            brandPartner {
                id
                name
            }
            promoProgram {
                id
                name
            }
            uptimeStats {
                storesOpenedDuration
                allocatedPlaybackDuration
                totalPlaybackDuration
                uptimePercent
            }
        }
    }
`