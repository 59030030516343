import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppNavigationService} from '../../navigation.service';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {isMobileBrowser, Utils} from "@looma/shared/utils";
import {AppNavigation, AppNavigationItem} from "../../../shared/app-navigation";


@LifecycleHooks()
@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    private _navItems: AppNavigationItem[];

    @Input()
    set navigation(value: AppNavigationItem[]) {
        this._navItems = value
    }

    get items(): AppNavigationItem[] {
        return this._navItems
    }

    constructor(private svcNav: AppNavigationService) {
    }


    ngOnInit(): void {
        if (!this._navItems) {
            this._navItems = this.svcNav.navigationItems
        }

        // Subscribe to the current navigation changes
        this.svcNav.onNavigationChanged
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(navItems => {
                this._navItems = this.svcNav.navigationItems
            });
    }


}
