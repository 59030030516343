import {BaseModel} from '@looma/shared/models/base_model';
import {BrandPartner} from '@looma/shared/models/brand_partner'
import {MediaFileVariant} from '@looma/shared/models/media_file_variant'

export class MediaFile extends BaseModel {
    id: string;
    displayName: string;
    externalUrl: string;
    filmMaker: string;
    brandPartner: BrandPartner;
    variants: MediaFileVariant[];
    updated_at: Date;

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'brandPartner', BrandPartner);
        this.assignTypedArray(input, 'variants', MediaFileVariant);
        this.assignDate(input, 'updated_at');
        return this;
    }

    isNewRecord(): boolean {
        return !this.id
    }
}

export interface MediaFileInput {
    id?: string
    displayName?: string
    brandPartnerId?: string
    externalUrl?: string
    filmMaker?: string
    vimeoVideoId?: string
}

export class MediaFileOriginalType {

    static readonly Any = new MediaFileOriginalType('Any');
    static readonly Image = new MediaFileOriginalType('Image');
    static readonly Video = new MediaFileOriginalType('Video');

    static readonly ALL: MediaFileOriginalType[] = [MediaFileOriginalType.Any, MediaFileOriginalType.Image, MediaFileOriginalType.Video];

    static lookup(name: any): MediaFileOriginalType {
        if (name instanceof MediaFileOriginalType) {
            return name
        }
        if (typeof name === 'string') {
            for (const item of MediaFileOriginalType.ALL) {
                if (item.name === name) {
                    return item
                }
            }
        }
        return null;
    }

    static byName(name: string | MediaFileOriginalType): MediaFileOriginalType {
        return MediaFileOriginalType.lookup(name);
    }

    constructor(public readonly name: string) {
    }

    toJSON() {
        return this.name;
    }

}


export interface MediaFileFilterCriteria {
    cursor?: string;
    brandPartnerIds?: number[];
    textSearchPattern?: string;
    originalFileType?: MediaFileOriginalType
}
