import {Assignable} from "@looma/shared/types/assignable";
import {Type} from "@angular/core";
import {Observable, OperatorFunction} from "rxjs";
import {map} from "rxjs/operators";
import {BaseModel} from "@looma/shared/models/base_model";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {Utils} from "@looma/shared/utils";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {DocumentNode} from "graphql";
import {ApiResponse} from "@looma/shared/models/api_response";
import {Apollo} from "apollo-angular";
import {ApolloQueryResult, FetchPolicy, QueryOptions} from "@apollo/client/core";

export abstract class RemoteDataService {

    private queryCacheMap = new Map<any, boolean>();

    protected constructor(protected apollo: Apollo) {
    }

    invalidateQueries(...keys: any[]): void {
        for (const k of keys) {
            this.queryCacheMap.delete(k)
        }
    }

    protected setHasQuery(q: any): void {
        this.queryCacheMap.set(q, true)
    }

    protected getFetchPolicy(q: any): FetchPolicy {
        if (this.queryCacheMap.has(q)) {
            return 'cache-first';
        }
        return 'network-only';
    }

    protected assignDataObject<T extends Assignable>(typeOfT: Type<T>, apolloResponseBody: any, ...dataAttrs: string[]): T {
        const data = this.fetchNestedData(apolloResponseBody, ...dataAttrs);
        if (data) {
            return new typeOfT().assign(data)
        }
        return null
    }

    protected mapTypedQueryResponseArray<T extends Assignable>(typeOfT: Type<T>, ...dataAttrs: string[]): OperatorFunction<ApolloQueryResult<Response>, T[]> {
        return source => {
            return source.pipe(
                map(value => {
                    return this.assignDataArray(typeOfT, value, ...dataAttrs);
                })
            )
        }
    }

    protected mapTypedQueryResponseObject<T extends Assignable>(typeOfT: Type<T>, ...dataAttrs: string[]): OperatorFunction<ApolloQueryResult<Response>, T> {
        return source => {
            return source.pipe(
                map(value => {
                    return this.assignDataObject(typeOfT, value, ...dataAttrs);
                })
            )
        }
    }

    protected mapTypedQueryResponseFeed<T extends BaseModel>(typeOfT: Type<T>, ...dataAttrs: string[]): OperatorFunction<ApolloQueryResult<Response>, CursorFeed<T>> {
        return source => {
            return source.pipe(
                map(value => {
                    const feedData = Utils.getNestedObject(value.data, ...dataAttrs);
                    return CursorFeed.create(feedData, typeOfT)
                })
            )
        }
    }

    protected mapTypedMutationResponse<T extends BaseModel>(typeOfT: Type<T>, tAttr: string, ...responseAttrs: string[]): OperatorFunction<ApolloQueryResult<Response>, MutationResponse<T>> {
        return source => {
            return source.pipe(
                map(value => {
                    const respData = Utils.getNestedObject(value, ...responseAttrs);
                    let resp = new MutationResponse<T>();
                    if (respData) {
                        resp = this.readMutationResponse(typeOfT, respData);
                        if (respData.hasOwnProperty(tAttr) && respData[tAttr]) {
                            resp.data = new typeOfT().assign(respData[tAttr])
                        }
                    }
                    return resp;
                })
            )
        }
    }

    protected readMutationResponse<T extends BaseModel>(typeOfT: Type<T>, response: object): MutationResponse<T> {
        const resp = new MutationResponse<T>();
        resp.success = false;
        for (const key of Object.keys(response)) {
            switch (key) {
                case 'success':
                    resp.success = !!response[key];
                    break;
                case 'message':
                    resp.message = response[key] as string;
                    break;
                case 'validationErrors':
                    resp.assignValidationErrors(response[key])
                    break;
                case 'triggeredJobId':
                    resp.triggeredJobId = response[key] + '';
                    break;
                default:
                    if (typeOfT && !resp.data) {
                        const payload = response[key]

                        if (Utils.isObject(payload)) {
                            resp.data = new typeOfT().assign(payload)
                        } else if (Array.isArray(payload)) {
                            resp.dataArray = (payload as any[]).map(value => new typeOfT().assign(value))
                            if (resp.dataArray.length) {
                                resp.data = resp.dataArray[0]
                            }
                        }
                    }
                    break
            }
        }
        return resp;
    }

    protected fetchNestedData(apolloResponseBody: any, ...dataAttrs: string[]): any {
        let data = apolloResponseBody.data;
        for (const attr of dataAttrs) {
            if (data.hasOwnProperty(attr)) {
                data = data[attr]
            } else {
                return null
            }
        }
        return data
    }

    protected assignDataArray<T extends Assignable>(typeOfT: Type<T>, value: any, ...dataAttrs: string[]): T[] {
        let placeholder = value.data;
        for (const attr of dataAttrs) {
            if (placeholder.hasOwnProperty(attr)) {
                placeholder = placeholder[attr]
            } else {
                return []
            }
        }

        if (Array.isArray(placeholder)) {
            return (placeholder as any[]).map(v => {
                return new typeOfT().assign(v)
            })
        }
        return [];
    }

    rawQuery(opts: QueryOptions): Observable<ApolloQueryResult<Response>> {
        return this.apollo.query(opts)
    }

    rawQueryNoCache(opts: QueryOptions) {
        opts.fetchPolicy = 'no-cache';
        return this.rawQuery(opts);
    }

    rawObjectMutate<T extends BaseModel>(mutationQuery: DocumentNode, mutationVars: object, typeOfT: Type<T>): Observable<MutationResponse<T>> {
        return this.apollo.mutate<Response>({
            mutation: mutationQuery,
            variables: mutationVars
        }).pipe(
            this.mapGenericObjectMutationResponse(typeOfT),
        );
    }

    rawMutate<T extends BaseModel>(mutationQuery: DocumentNode, mutationVars: object, dataAttr: string): Observable<ApiResponse> {
        return this.apollo.mutate<Response>({
            mutation: mutationQuery,
            variables: mutationVars
        }).pipe(
            this.mapTypedQueryResponseObject(ApiResponse, dataAttr)
        );
    }

    mutate(mutationQuery: DocumentNode, mutationVars: object) {
        return this.apollo.mutate<Response>({
            mutation: mutationQuery,
            variables: mutationVars
        })
    }

    private mapGenericObjectMutationResponse<T extends BaseModel>(typeOfT: Type<T>): OperatorFunction<ApolloQueryResult<Response>, MutationResponse<T>> {
        return source => {
            return source.pipe(
                map(value => {
                    const data = value.data;
                    const response = data[Object.keys(data)[0]];
                    return this.readMutationResponse(typeOfT, response)
                })
            )
        }
    }

    rawSubscribe<T extends BaseModel>(query: DocumentNode, variables: Record<string, any>, typeOfT: Type<T>): Observable<T> {
        return this.apollo.subscribe<Response>({
            query: query,
            variables: variables,
        }).pipe(
            map(value => {
                const data = value.data;
                const response = data[Object.keys(data)[0]];

                return new typeOfT().assign(response)
            })
        )
    }

}

