import {Component, Inject, OnInit} from '@angular/core';
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {LayoutService} from "../../../../services/layout.service";
import {EMPTY, Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DeviceSlotImageScoreSubmissionTabletInstallationWarning} from "@looma/shared/models/device_slot_image_score_submission";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-device-slot-installation-warnings-dialog',
    templateUrl: './device-slot-installation-warnings-dialog.component.html',
    styleUrls: ['./device-slot-installation-warnings-dialog.component.scss']
})
export class DeviceSlotInstallationWarningsDialogComponent extends ModelEditDialog<DeviceSlot> implements OnInit {

    constructor(public dialogRef: MatDialogRef<DeviceSlotInstallationWarningsDialogComponent>,
                public svcLayout: LayoutService,
                private svcApi: ApiDataService,
                @Inject(MAT_DIALOG_DATA) private dialogData: DeviceSlotInstallationWarningsDialogData) {
        super()
        this.deviceSlot = dialogData.deviceSlot
    }

    deviceSlot: DeviceSlot;
    private selectedWarnings = new Set<string>();

    static open(svcLayout: LayoutService, slot: DeviceSlot): Observable<DeviceSlot> {
        if (!slot?.hasInstallationWarnings) {
            return EMPTY
        }
        const data: DeviceSlotInstallationWarningsDialogData = {
            deviceSlot: slot,
        }

        return svcLayout.openDialogForResult(DeviceSlotInstallationWarningsDialogComponent, {
            data,
        })

    }

    ngOnInit(): void {
    }


    get isSavingData(): boolean {
        return false
    }

    get hasUnsavedChanges(): boolean {
        return this.selectedWarnings.size > 0;
    }

    onSelectionChanged(warn: DeviceSlotImageScoreSubmissionTabletInstallationWarning, ev: any) {
        if (ev.checked) {
            this.selectedWarnings.add(warn.key)
        } else {
            this.selectedWarnings.delete(warn.key);
        }
    }

    showSubmissionImage() {
        const imageUrl = this.deviceSlot.lastScoreSubmission?.deviceSlotImage?.large.url;
        if (imageUrl) {
            window.open(imageUrl)
        }

    }

    onSave(): Observable<MutationResponse<DeviceSlot>> | null {
        if (this.selectedWarnings.size > 0) {
            return this.svcApi.mutateDeviceSlot(MutationOperation.Update, {
                resolveWarnings: Array.from(this.selectedWarnings),
                id: this.deviceSlot.id,
            })
        }
        return EMPTY;
    }
}


export interface DeviceSlotInstallationWarningsDialogData {
    deviceSlot: DeviceSlot
}
