<mat-card class="submission-card" *ngIf="this.submission">
    <mat-card-header>
        <mat-card-title-group>
            <mat-card-title>
                {{ submission?.brandPartner?.name }}
            </mat-card-title>
            <mat-card-subtitle>Brand: {{ submission?.submittedByBrand?.name }}</mat-card-subtitle>
            <img mat-card-sm-image [src]="submission.brandPartner.logoUrl">
        </mat-card-title-group>
    </mat-card-header>

    <mat-card-content>
        <div *ngFor="let product of submission.featuredProducts" class="card-section">
            <div class="warn" *ngIf="!product.uptimeProduct">
                <div style="display: flex; align-items: center">
                    <mat-icon>error_outline</mat-icon>
                    <span>Custom product</span>
                </div>
            </div>
            <div style="display: flex">
                <div class="product-image">
                    <img *ngIf="product.thumbUrl" [src]="product.thumbUrl"
                         alt="{{product.name}} thumbnail">
                </div>

                <div class="product-info">
                    <div class="product-name">{{ product.name }}</div>
                    <div class="product-upc">UPC: {{ product.upc }}</div>
                </div>
            </div>
        </div>

        <div *ngIf="submission?.airtime" class="segments-section">
            <div class="section-header">Airtime</div>
            {{ submission.airtime.segmentName }} <span
            *ngIf="submission.airtime.segmentCost > 0"> - {{ submission.airtime.segmentCost | currency: 'USD' }}</span>
        </div>

        <div *ngIf="submission?.segments && submission?.segments.length > 0" class="segments-section">
            <div class="section-header">Segments</div>
            <ul>
                <li *ngFor="let segment of submission.segments">
                    {{ segment.segmentName }} - {{ segment.segmentCost | currency:'USD' }}
                </li>
            </ul>

            <div class="total-cost">
                Total: {{ getTotalSegmentCost(submission.segments) | currency:'USD' }}
            </div>
        </div>

        <div class="note-section">
            <div style="font-weight: 700">Note</div>
            {{ submission?.note }}
        </div>

        <div class="submission-info">
            <div class="submitted-on">
                <span class="label">Submitted On:</span>
                {{ submission?.createdAt | date:'M/d/yyyy' }}
            </div>
            <div class="submitted-by">
                <span class="label">Submitted By:</span>
                {{ submission?.submittedByUser }}
            </div>
        </div>

        <div class="actions" *ngIf="!brandCampaign">
            <button mat-raised-button (click)="createCampaign(submission)">Create campaign</button>
        </div>

        <div class="campaign-status" *ngIf="brandCampaign" sty>
            Campaign: {{ brandCampaign.name }}
        </div>
    </mat-card-content>
</mat-card>