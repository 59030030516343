import {BaseModel} from '@looma/shared/models/base_model';
import {RetailerPromoSchedule} from '@looma/shared/models/retailer_promo_schedule'
import {RetailerPromoProgram} from '@looma/shared/models/retailer_promo_program'
import {RetailerPromoPeriodProgramAssignment} from "@looma/shared/models/retailer_promo_period_program_assignment";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";
import {DeviceSlotType} from "@looma/shared/models/device_slot_type";
import {RetailerRegion} from "@looma/shared/models/retailer_region";
import {Retailer} from "@looma/shared/models/retailer";
import {Utils} from "@looma/shared/utils";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {PromoPlaylistAssignment} from "@looma/shared/models/promo_playlist_assignment";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {PromoPlaylistMediaBundle} from "@looma/shared/models/PromoPlaylistMediaBundle";

export enum RetailerPromoPeriodStatus {
    Unknown = 0,
    Pending = 1,
    Active = 2,
    Ended = 3
}

const SOON_STARTING_TIME_DIFF = 24 * 60 * 60 * 1000; // 1 day

export class RetailerPromoPeriod extends BaseModel {
    id: string;
    name: string; // AP1, AP2
    startDate: Date;
    endDate: Date;
    activateAt: Date
    promoSchedule: RetailerPromoSchedule;
    promoPrograms: RetailerPromoProgram[];
    programAssignments: RetailerPromoPeriodProgramAssignment[];
    brandCampaigns: BrandPromoCampaign[]
    deviceSlotTypes: DeviceSlotType[]
    retailerRegions: RetailerRegion[]
    deviceSlotSegments: DeviceSlotSegment[]
    programEntries: RetailerPromoPeriodProgramEntry[];
    unAssignedDeviceSlots: DeviceSlot[]
    retailer: Retailer
    status: number;
    merchGuideUrl: string;

    static newPending(): RetailerPromoPeriod {
        const promoPeriod = new RetailerPromoPeriod();
        promoPeriod.id = '-1';
        promoPeriod.status = RetailerPromoPeriodStatus.Pending;
        return promoPeriod
    }

    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'promoSchedule', RetailerPromoSchedule);
        this.assignTypedObject(input, 'retailer', Retailer);
        this.assignTypedArray(input, 'promoPrograms', RetailerPromoProgram);
        this.assignTypedArray(input, 'programAssignments', RetailerPromoPeriodProgramAssignment);
        this.assignTypedArray(input, 'brandCampaigns', BrandPromoCampaign);
        this.assignTypedArray(input, 'deviceSlotTypes', DeviceSlotType);
        this.assignTypedArray(input, 'retailerRegions', RetailerRegion);
        this.assignTypedArray(input, 'deviceSlotSegments', DeviceSlotSegment);

        this.assignTypedArray(input, 'programEntries', RetailerPromoPeriodProgramEntry);
        this.assignTypedArray(input, 'unAssignedDeviceSlots', DeviceSlot);

        this.assignDate(input, 'startDate');
        this.assignDate(input, 'endDate');
        this.assignDate(input, 'activateAt');

        this.status = parseInt(this.status as any as string)
        return this;
    }

    get isSoonStarting(): boolean {
        if (Utils.isDate(this.activateAt)) {
            const diff = this.activateAt.getTime() - Date.now()
            if (diff < 0) {
                return false
            }
            return diff < SOON_STARTING_TIME_DIFF
        }
        return false
    }

    get isEditable(): boolean {
        return this.isPending || this.isActive;
    }

    get isPending(): boolean {
        return this.status === RetailerPromoPeriodStatus.Pending;
    }

    get isActive(): boolean {
        return this.status === RetailerPromoPeriodStatus.Active;
    }

    get isEnded(): boolean {
        return this.status === RetailerPromoPeriodStatus.Ended;
    }

    get statusName(): string {
        if (this.isActive) {
            return 'Active'
        }
        if (this.isEnded) {
            return 'Ended'
        }
        if (this.isPending) {
            return 'Pending';
        }
        return ''
    }

}

export interface RetailerPromoPeriodMutationInput {
    id: string
    name?: string
    promoScheduleId?: string
    promoProgramIds?: string[]
    startDate?: string
    endDate?: string
    openForSubmission?: boolean
    dueDate?: string
    campaignCost?: number
    theme?: string
}

export class RetailerPromoPeriodProgramEntry extends BaseModel {
    promoPeriod: RetailerPromoPeriod
    promoProgram: RetailerPromoProgram
    deviceSlotSegments: DeviceSlotSegment[]
    brandCampaigns: BrandPromoCampaign[]
    playlistAssignments: PromoPlaylistAssignment[]
    playlistMediaBundles: PromoPlaylistMediaBundle[]

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'promoPeriod', RetailerPromoPeriod)
        this.assignTypedObject(input, 'promoProgram', RetailerPromoProgram)
        this.assignTypedArray(input, 'deviceSlotSegments', DeviceSlotSegment)
        this.assignTypedArray(input, 'brandCampaigns', BrandPromoCampaign)
        this.assignTypedArray(input, 'playlistAssignments', PromoPlaylistAssignment)
        this.assignTypedArray(input, 'playlistMediaBundles', PromoPlaylistMediaBundle)
        return this
    }
}

