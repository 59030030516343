import {Component, Input, OnInit} from '@angular/core';
import {MediaContentType} from "@looma/shared/models/media_content";

@Component({
    selector: 'media-content-type-icon',
    templateUrl: './media-content-type-icon.component.html',
    styleUrls: ['./media-content-type-icon.component.scss']
})
export class MediaContentTypeIconComponent implements OnInit {

    MediaContentType = MediaContentType;
    
    constructor() {
    }

    @Input("mediaContentType") mediaContentType: MediaContentType | string
    @Input("title") title: string
    @Input("elevation") elevation = 0

    ngOnInit(): void {
    }

}
