import {
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    OnInit,
    ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {MediaFileVariant} from '@looma/shared/models/media_file_variant';
import {MediaContent} from "@looma/shared/models/media_content";
import {MediaContentVersion} from "@looma/shared/models/media_content_version";

@Injectable({
    providedIn: 'root'
})
export class VideoPlayerService {

    private playerComponentRef: ComponentRef<VideoPlayerComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
        this.createComponent();
    }

    play(url: string, title?: string) {
        this.playerComponentRef.instance.play(url, title || '')
    }

    playContent(variant: MediaFileVariant | MediaContent | MediaContentVersion) {
        if (variant instanceof MediaFileVariant) {
            if (variant && !variant.isEmptyVariant()) {
                let displayName = variant.display_name;
                if (variant.media_file) {
                    displayName = `${variant.media_file.displayName} - ${variant.display_name}`
                }
                this.play(variant.processed_url, displayName);
            }
        } else if (variant instanceof MediaContent) {
            this.playContent(variant.defaultMediaContentVersion)
        } else if (variant instanceof MediaContentVersion) {
            this.play(variant.processedUrl, variant.getDisplayName())
        }

    }

    private createComponent() {
        if (this.playerComponentRef) {
            return
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(VideoPlayerComponent);
        const componentRef = this.playerComponentRef = componentFactory.create(this.injector);
        this.appRef.attachView(componentRef.hostView);

        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent() {
        if (!this.playerComponentRef) {
            return
        }
        this.appRef.detachView(this.playerComponentRef.hostView);
        this.playerComponentRef.destroy();
        this.playerComponentRef = null;
    }
}

@Component({
    selector: 'looma-video-player',
    template: `
        <div class="app_video_player" [class.active]="isActive">
            <div class="player_actions">
                <span class="video-title h2" title="{{videoTitle}}">{{videoTitle}}</span>
                <button (click)="stopPlayback()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <video controls #video></video>
        </div>

    `,
})
export class VideoPlayerComponent implements OnInit {
    @ViewChild('video', {static: true}) videoEl: ElementRef<HTMLVideoElement>;

    isActive = false;
    videoTitle = '';

    playbackEvents = new Subject<VideoPlaybackEvent>();

    ngOnInit(): void {
    }


    play(url: string, title: string) {
        if (!this.videoEl) {
            return
        }
        if (!this.videoEl.nativeElement) {
            return
        }

        const video = this.videoEl.nativeElement;
        this.videoTitle = title;

        if (!url) {
            if (this.isActive) {
                this.isActive = false;
                video.pause();
                video.removeAttribute('src');
                this.notifyState(VideoPlaybackState.Ended);
                return;
            }
        }

        this.isActive = true;
        video.src = url;
        video.load();
        this.notifyState(VideoPlaybackState.Loading);
        video.onloadedmetadata = ev => {
            video.play()
        };

        video.onended = ev => {
            if ((ev.target == video) && (video.src == url)) {
                this.stopPlayback()
            }
        };

        video.onplaying = ev => {
            this.notifyState(VideoPlaybackState.Playing);
        };

        video.onpause = ev => {
            this.notifyState(VideoPlaybackState.Paused);
        };
    }

    stopPlayback() {
        this.play(null, null)
    }

    private notifyState(state: VideoPlaybackState) {
        if (!this.videoEl) {
            return
        }
        if (!this.videoEl.nativeElement) {
            return
        }

        const video = this.videoEl.nativeElement;
        const url = video.getAttribute('src');
        if (!url) {
            return
        }
        this.playbackEvents.next({url, state})
    }

}

export enum VideoPlaybackState {
    Loading = 1,
    Playing = 2,
    Paused = 3,
    Ended = 4,
    Error = 5,
}

export interface VideoPlaybackEvent {
    url: string
    state: VideoPlaybackState
}
