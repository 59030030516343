import {BaseModel} from "@looma/shared/models/base_model";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {SurveyProductEntry} from "@looma/shared/models/survey_product_entry";

export class SurveyVersion extends BaseModel {
    id: string
    name: string
    description: string
    retailerPromoPeriod: RetailerPromoPeriod
    deviceSlotSegment: DeviceSlotSegment
    publishedAt: string
    activatedAt: string
    isDirty: boolean
    status: number;
    firebasePath: string
    homeScreenName: string
    homeScreenUrl: string
    products: SurveyProductEntry[]
    updatedAt: Date;
    createdAt: Date;
    hasContent: boolean
    constructor() {
        super()
        this.status = 1;
    }

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'retailerPromoPeriod', RetailerPromoPeriod)
        this.assignTypedObject(input, 'deviceSlotSegment', DeviceSlotSegment)
        this.assignTypedObject(input, 'previousSurvey', SurveyVersion)
        this.assignTypedArray(input, 'products', SurveyProductEntry)
        this.assignDate(input, 'updatedAt');
        this.assignDate(input, 'createdAt');
        return this
    }

    isPublished(): boolean {
        return this.publishedAt != null && this.firebasePath != null && !this.isActive()
    }

    isActive(): boolean {
        return this.status == 2
    }

    isDraft(): boolean {
        return this.status == 0 && this.publishedAt == null
    }
}
