import {NgModule} from "@angular/core";
import {HumanDurationPipe} from "@looma/shared/pipes/human-duration.pipe";
import {DateSincePipe} from "@looma/shared/pipes/date-since.pipe";

@NgModule({
    imports: [],
    declarations: [
        HumanDurationPipe,
        DateSincePipe
    ],
    exports: [
        HumanDurationPipe,
        DateSincePipe
    ]
})
export class LoomaPipesModule {
}
