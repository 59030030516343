import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {LayoutService} from "../../../../services/layout.service";
import {Observable, Subscription} from "rxjs";
import {DeviceSlotImage, DeviceSlotImageMutationInput} from "@looma/shared/models/device_slot_image";
import {UploadedFileInfo, UploadService, UploadSession, UploadState} from "@looma/shared/services/upload.service";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {NamedValue} from "@looma/shared/types/named_value";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {FormControl} from "@angular/forms";

@LifecycleHooks()
@Component({
    selector: 'app-add-image-dialog',
    templateUrl: './add-image-dialog.component.html',
    styleUrls: ['./add-image-dialog.component.scss']
})
export class AddImageDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private svcUpload: UploadService,
        private dialogRef: MatDialogRef<AddImageDialogComponent>,
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        @Inject(MAT_DIALOG_DATA) public dialogData: AddImageDialogData
    ) {

        this.uploadSession = this.svcUpload.getUploadSession('media_file_splash_session', {
            fileTypes: ['image'],
            multiSelection: false,
            recreate: true
        });

        this.uploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {

            const idx = this.uploadedFiles.findIndex(f => f.firebaseKey == value.firebaseKey);
            if (idx >= 0) {
                this.uploadedFiles[idx] = value;
                this.uploadedFiles = [].concat(this.uploadedFiles);
                this.mutationData.imageStorageRefs = this.uploadedFiles.map(value1 => value1.firebaseKey)
            }

        })

        this.uploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.uploadedFiles.push(value);
        })

        this.hideDeviceSlotSelectionField = dialogData.hideDeviceSlotSelectionField && !!dialogData?.deviceSlot?.id

        this.mutationData = {
            deviceSlotId: dialogData?.deviceSlot?.id,
            date: Utils.formatShortDate(new Date())
        }

        this.dialogData.title = this.dialogData.title || 'New Device Slot Image'

    }

    get canSaveImage(): boolean {
        if (!this.mutationData.deviceSlotId) {
            return false
        }
        if (!this.mutationData.date) {
            return false
        }
        if (!this.mutationData.imageStorageRefs?.length) {
            return false
        }
        if (!this.uploadedFiles.length) {
            return false
        }
        for (const f of this.uploadedFiles) {
            if (f.state != UploadState.UploadSuccess) {
                return false
            }
        }
        if (!Utils.isUnsubscribed(this.saveSub)) {
            return false
        }
        return true
    }

    UploadStates = UploadState
    hideDeviceSlotSelectionField = false

    private uploadSession: UploadSession;
    uploadedFiles: UploadedFileInfo[] = []

    private mutationData: Partial<DeviceSlotImageMutationInput> = {}
    private saveSub: Subscription

    selectedDate = new Date()

    static open(svcLayout: LayoutService, data: AddImageDialogData): Observable<DeviceSlotImage> {
        return svcLayout.openDialogForResult(AddImageDialogComponent, {
            data: data,
        })
    }


    ngOnInit(): void {

    }


    ngOnDestroy(): void {
        this.uploadSession.destroy()
    }

    ngAfterViewInit(): void {

    }

    onDeviceSlotChanged(ev: NamedValue) {
        this.mutationData.deviceSlotId = ev ? ev.stringValue() : null;
    }

    openPicker() {
        this.uploadSession.openPicker()
    }

    onDateSelected(ev: MatDatepickerInputEvent<Date>) {
        const date = ev.value, t = date && date.getTime() || -1
        if (isNaN(t) || t <= 0) {
            this.mutationData.date = null
        } else {
            this.mutationData.date = Utils.formatShortDate(date);
        }
    }

    handleAction(action: 'ok' | 'cancel') {
        switch (action) {
            case 'cancel':
                this.dialogRef.close(null);
                break
            case "ok":
                this.submitCreate();
                break
        }
    }

    private submitCreate() {
        if (!this.canSaveImage) {
            return
        }
        // data.approved = this.deviceSlotImage.approved;
        this.saveSub = this.svcApi.mutateDeviceSlotImage(MutationOperation.Create, this.mutationData).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.dialogRef.close(value.data);
            } else {
                this.svcLayout.showMutationResultMessage(value)
            }
        })
    }

}


export interface AddImageDialogData {
    gqlFields?: string
    deviceSlot?: DeviceSlot
    hideDeviceSlotSelectionField?: boolean
    hideDateField?: boolean
    title?: string
}
