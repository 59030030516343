import {BaseModel} from '@looma/shared/models/base_model';
import {WordFilterable} from '@looma/shared/types/word_filterable';

export class ProductCategory extends BaseModel implements WordFilterable {
    id: number;
    category_name: string;
    sanitized_name: string;

    getPhrases(): string[] {
        return [this.category_name, this.sanitized_name];
    }

    getDisplayName(): string {
        return this.category_name
    }

    getId(): any {
        return this.id
    }
}
