import gql from 'graphql-tag';

export const MUTATION_RESPONSE_FIELDS = `
success
message
triggeredJobId
validationErrors {
    name
    errors
}
`

export const FRAGMENT_API_RESPONSE_FIELDS = gql`
    fragment ApiResponseFields on ApiResponse {
        message
        success
        errors {
            key
            value
        }
        data {
            key
            value
        }
    }
`;


export const MUTATION_SAVE_FILTER = gql`
    mutation saveDeviceFilter($filters: [DeviceFieldFilter!]!, $name: String!){
        saveFilter(filters: $filters, name: $name){
            id
        }
    }
`;


export const MUTATION_SEND_DEVICE_BATCH_COMMAND = gql`
    mutation sendCommand($commandName:String!, $commandArgs:String!, $audience: DeviceSearchCriteria!){
        sendDeviceCommand(commandName: $commandName, commandArgs: $commandArgs, audience: $audience){
            status
            id
            created_at
            expires_at
            target_devices_count
            notified_devices_count
            payload_sent_devices_count
            completed_devices_count
            cmd_name
            cmd_args
        }
    }
`;


export const QUERY_FETCH_DEVICE_LIST = gql`
    query getDevices($criteria: DeviceSearchCriteria) {
        devices(criteria: $criteria) {
            cursor
            data: devices {
                id
                device_id
                asset_tag
                created_at
                updated_at

                remoteDeviceCampaignInfo {
                    activeCampaignName
                    assignedCampaignName
                    activePlaylist
                    assignedPlaylist
                }

                remote_playback_info {
                    active_playlist {
                        id
                        name
                        fb_reference
                    }
                    assigned_playlist {
                        id
                        name
                        fb_reference
                    }
                }

                assignation {
                    id
                    loop_placement
                    device_installed_at
                    store {
                        id
                        retailer_store_num
                        retailer {
                            id
                            retailer_name
                            retailer_id
                        }
                        retailer_region {
                            id
                            region_name
                        }
                        location {
                            id
                            location_name
                            geo_point
                            street_address
                            city
                            state
                            timezone_id
                        }
                    }
                    product_category {
                        id
                        category_name
                        sanitized_name
                    }
                    name
                    kioskDeviceApp {
                        id
                        app_name
                        package_name
                    }
                }
                remote_device_info {
                    last_rebooted_at
                    adjusted_volume
                    player_volume
                }
                remote_playback_info {
                    session_updated_at
                }
                remote_status {
                    playback_status
                    installed_apps_status
                }
                remote_wifi_info {
                    ssid
                    bssid
                    mac_address
                }
            }
        }
    }
`;

export const QUERY_FETCH_DEVICE_FRESH = gql`
    query getDeviceFresh($deviceId: String!) {
        devices(
            criteria: {
                fresh: true
                filters: [{ field: Id, operator: Eq, value: [{ value: $deviceId }] }]
            }
        ) {
            device: devices {
                id
                device_id
                asset_tag
                teamviewer_id

                assignation {
                    id
                    name
                    counter
                    device_installed_at

                    looma_phase
                    mount
                    store_location

                    product_category {
                        id
                        category_name
                    }
                    store {
                        id
                        retailer_region {
                            id
                            region_name
                        }
                        retailer {
                            id
                            retailer_name
                            retailer_id
                        }
                        retailer_store_num
                        location {
                            id
                            location_name
                            geo_point
                            street_address
                            city
                            state
                            timezone_id
                        }
                    }
                }
                remote_playback_info {
                    session_started_at
                    session_track_names
                    session_updated_at
                    session_buffering_time
                }

                remoteDeviceCampaignInfo {
                    activeCampaignName
                    assignedCampaignName
                    activePlaylist
                }

                remote_device_info {
                    last_rebooted_at
                    uptime
                    kiosk_mode_enabled
                    adjusted_volume
                    player_volume
                    last_heartbeat_at
                    timezone_id
                    ethernet_mac_address
                    focused_activity_record
                    updated_at
                }
                remote_status {
                    playback_status
                    heartbeat_status
                    foreground_app_status
                    accelerometer_status
                    battery_status
                    store_status
                    health_status
                    installed_apps_status
                }

                remote_wifi_info {
                    bssid
                    hidden
                    ip
                    rssi
                    signal_level
                    ssid
                    mac_address
                }

                remote_apps_info {
                    device_app {
                        id
                        app_name
                        package_name
                        version_name
                        version_code
                    }
                    version_name
                    version_code
                }

                remote_battery_info {
                    fill
                    health
                    temperature
                    voltage
                    is_charging
                    is_plugged
                }

                remote_location_info {
                    lat
                    lng
                }

                remote_accelerometer_info {
                    X
                    Y
                    Z
                    deviation_x
                    deviation_y
                    deviation_z
                    calibration_threshold
                    isCalibrated
                }

                remote_realtime_stats {
                    beginning_of_day
                    last_rebooted_at
                    timezone_id
                    up_time
                    playback_time
                    buffering_time
                    playback_sessions_count
                    last_session_started_at
                    reboot_since_time
                }

                remoteDeviceConfig{
                    autoRebootEnabled
                    envSwitchEnabled
                    volumeTiersEnabled
                }
            }
        }
    }



`;


export const QUERY_DEVICES_FOR_MAP = gql`
    query devices {
        devices(
            criteria: {
                pageSize:-1,
                filters: [
                    { field: DeviceAssigned, operator: Eq, value: [{ value: "true" }] }
                ]
            }
        ) {
            data: devices {
                id
                device_id
                remote_status {
                    playback_status
                }
                assignation {
                    id
                    name
                    store {
                        id
                        location {
                            id
                            geo_point
                        }
                    }
                }
                remote_location_info {
                    lat
                    lng
                }
            }
        }
    }

`;

export const QUERY_RETAILERS_WITH_CAMPAIGNS_AND_PLAYLISTS = gql`
    query getRetailers{
        retailers{
            id
            retailer_name
            retailer_campaigns{
                id
                name
                start_date
                end_date
                playlists{
                    id
                    name
                    fb_reference
                }
            }
        }
    }
`;

export const QUERY_RETAILERS_FOR_SELECT = gql`
    query getRetailers{
        retailers{
            id
            retailer_name
            retailer_id
        }
    }
`;


const FRAGMENT_RETAILER_FIELDS = gql`
    fragment RetailerFields on Retailer {
        id
        retailer_name
        retailer_id
        website_url
        product_categories {
            id
            category_name
        }
        regions {
            id
            region_name
        }
        location {
            id
            location_name
            city
            state
            street_address
            country_code
        }
    }
`;

export const QUERY_RETAILERS_FEED = gql`
    ${FRAGMENT_RETAILER_FIELDS}
    query getRetailersFeed($filter:RetailerSearchCriteria) {
        retailers_feed(filter:$filter) {
            cursor
            data: retailers {
                ...RetailerFields
            }
        }
    }
`;

export const MUTATION_UPSERT_RETAILER = gql`
    ${FRAGMENT_RETAILER_FIELDS}
    mutation upsertMasterData($input:UpsertMasterDataInput!) {
        result: upsertMasterData(input: $input) {
            ${MUTATION_RESPONSE_FIELDS}
            retailer {
                ...RetailerFields
            }
        }
    }
`;

const FRAGMENT_STORE_FIELDS = gql`
    fragment StoreFields on Store {
        id
        retailer_store_num
        retailer {
            id
            retailer_name
            regions {
                id
                region_name
            }
            product_categories {
                id
                category_name
            }
        }
        retailer_region {
            id
            region_name
        }
        product_categories {
            id
            category_name
        }
        location {
            id
            location_name
            city
            state
            street_address
            country_code
        }
    }
`;

export const QUERY_STORES_FEED = gql`
    ${FRAGMENT_STORE_FIELDS}
    query getStoreFeed($filter: StoreSearchCriteria) {
        stores_feed(criteria: $filter) {
            cursor
            data: stores {
                ...StoreFields
            }
        }
    }
`;

const FRAGMENT_DEVICE_SLOT_FIELDS = gql`
    fragment DeviceSlotFields on DeviceSlot {
        id
        store {
            id
            retailer_store_num
            retailer {
                id
                retailer_name
            }
        }
        product_category {
            id
            category_name
        }
        device {
            device_id
        }
        name
        looma_phase
        store_location
        loop_placement
        temperature
        mount
        loop_x_score
        decor {
            header
            blades
            shelfRibbons
            accentCard
            coolerDecal
            sideLettering
        }
        deviceSlotType {
            id
            name
            retailerPromoProgram {
                id
                name
            }
        }
        lastReceivedImage {
            id
            date
            createdAt
        }
        lastScoreSubmission {
            id
            isValid
            deviceSlotImage {
                id
                date
                createdAt
                thumb: version(name: "thumb") {
                    width
                    height
                    url
                }
                large: version(name: "optimized") {
                    width
                    height
                    url
                }
            }
            tabletInstallationWarnings {
                key
                label
                description
                resolved
            }
        }
        kioskDeviceApp {
            id
            app_name
            package_name
        }
    }

`;

export const QUERY_DEVICE_SLOT_FEED = gql`
    ${FRAGMENT_DEVICE_SLOT_FIELDS}
    query getDeviceSlotsFeed($filter: DeviceSlotSearchCriteria) {
        device_slots(filter: $filter) {
            cursor
            data: device_slots {
                ...DeviceSlotFields
            }
        }
    }
`;


export const QUERY_RETAILERS_WITH_REGIONS_AND_STORES_AND_PRODUCT_CATEGORIES = gql`
    query retailersWithStores{
        retailers{
            id
            retailer_name
            regions{
                id
                region_name
                stores{
                    id
                    retailer_store_num
                }
            }
            product_categories{
                id
                category_name
                sanitized_name
            }
        }
    }
`;

export const QUERY_BRAND_CAMPAIGN_REPORTS_FEED = gql`
    query getBrandCampaigns($filter: BrandCampaignReportFilter) {
        brand_promo_campaign_reports_feed(criteria: $filter) {
            cursor
            data: brandCampaignReports {
                id
                brandPromoCampaign {
                    name
                    brandPartner {
                        id
                        name
                        type
                        childBrands{
                            id
                            name
                            type
                        }
                    }
                    retailer {
                        retailer_name
                    }
                    promoPeriod {
                        name
                    }
                }
                approved                
                cpr30Data
                cpr30PublicUrl
                emailNotificationSentTo
            }
        }
    }
`;

export const MUTATE_BRAND_CAMPAIGN_REPORT = gql`
    mutation mutateBrandCampaign($op: MutationOperation!, $data: BrandCampaignReportInput!) {
        result: mutateCampaignReport(op: $op, data: $data) {
            success
            message
            brandPromoCampaignReport {
                id
                brandPromoCampaign {
                    name
                    brandPartner {
                        id
                        name
                        type
                        childBrands{
                            id
                            name
                            type
                        }
                    }
                    retailer {
                        retailer_name
                    }
                    promoPeriod {
                        name
                    }
                }
                approved                
                emailNotificationSentTo
            }
        }
    }
`;


export const MUTATION_UPDATE_DEVICE = gql`
    ${FRAGMENT_API_RESPONSE_FIELDS}
    mutation updateDevice($deviceId: String!, $data: DeviceInput!){
        updateDevice(id: $deviceId, device: $data){
            ...ApiResponseFields
        }
    }

`;

export const MUTATION_UNASSIGN_DEVICE = gql`
    ${FRAGMENT_API_RESPONSE_FIELDS}
    mutation unassignDevice($deviceId: String!){
        unassignDevice(id: $deviceId){
            ...ApiResponseFields
        }
    }
`;

export const MUTATION_RESTART_REMOTE_CONTROL_APP = gql`
    ${FRAGMENT_API_RESPONSE_FIELDS}
    mutation restartDeviceRemoteControlApp($deviceId: String!){
        restartDeviceRemoteControlApp(id: $deviceId){
            ...ApiResponseFields
        }
    }

`;

export const MUTATION_REPLACE_DEVICE = gql`
    ${FRAGMENT_API_RESPONSE_FIELDS}
    mutation replaceDevice($deviceId: String!, $replacementId: String!){
        replaceDevice(id: $deviceId, replacementId: $replacementId){
            ...ApiResponseFields
        }
    }

`;


export const QUERY_DEVICE_POWER_LOGS = gql`
    query getDevicePowerLogs($deviceId: String!) {
        devices(criteria: {filters: [{field:Id, operator:Eq, value:[{value: $deviceId}] }]}) {
            power_cycle_logs{
                on_at
                charged_at
                discharging_at
                off_at
            }
        }
    }
`;


export const QUERY_GET_CAMPAIGN_UPTIME_STATS = gql`
    query getDevices($campaignId: Int!, $startDate: Date, $endDate: Date) {
        campaign_uptime_stats(campaignId:$campaignId, startDate:$startDate, endDate:$endDate){
            rangeStart,
            rangeEnd,
            upMillis,
            playbackMillis,
            dailyBreakdown{
                date,
                upMillis,
                playbackMillis
            },
            deviceStats{
                ownerDevice{
                    id
                    assignation{
                        id
                        name
                    }
                }
                deviceId
                rangeStart
                rangeEnd
                upMillis
                playbackMillis,
                dailyBreakdown{
                    date
                    upMillis
                    playbackMillis
                }
            }
        }
    }
`;


export const QUERY_SEARCH_DEVICE_FIELDS = gql`
    query searchDeviceFields($field: DeviceFilterableField!, $term:String!) {
        searchDeviceFields(field:$field, term:$term){
            name
            value
        }
    }
`;

export const FRAGMENT_COMMAND_DEVICE_RESULT_FIELDS = gql`
    fragment DeviceCommandResultsFields on DeviceCommandResult {
        status
        message
        device{
            id
            device_id
        }
        attachment{
            content_type
            preview_url
            download_url
        }
    }
`;

export const QUERY_GET_COMMAND_RESULTS = gql`
    ${FRAGMENT_COMMAND_DEVICE_RESULT_FIELDS}
    query getCommandResults($cmdId: ID, $deviceId: ID){
        device_command_results(id: $cmdId, deviceId: $deviceId){
            status
            id
            created_at
            expires_at
            target_device_ids
            target_devices_count
            notified_devices_count
            payload_sent_devices_count
            completed_devices_count
            cmd_name
            cmd_args
            user{
                id
                email
                display_name
            }
            device_results(first: true, deviceId: $deviceId){
                ...DeviceCommandResultsFields
            }
        }
    }
`;


export const QUERY_GET_COMMAND_RESULTS_WITH_DEVICES = gql`
    ${FRAGMENT_COMMAND_DEVICE_RESULT_FIELDS}
    query getCommandResults($cmdId: ID!, $deviceId:ID){
        device_command_results(id:$cmdId){
            device_results(deviceId:$deviceId){
                ...DeviceCommandResultsFields
            }
        }
    }
`;

export const QUERY_NEXT_DEVICE_ASSIGNATION_NUMBER = gql`
    query getNextAssignationNumber($storeId: String!, $productCategoryId: String!) {
        next_device_assignation_number(
            storeId: $storeId
            productCategoryId: $productCategoryId
        )
    }

`;


const FRAGMENT_CAMPAIGN_LIBRARY_FILE_FIELDS = gql`
    fragment RetailerCampaignLibraryFileFields on RetailerCampaignLibraryFile {
        id
        campaign_id
        file_status
        file_name
        error_message
        firebase_job_id
        campaign_id
        brand_partner {
            id
            name
        }
        product_category {
            id
            category_name
            sanitized_name
        }
        versions {
            version_name
            gs_location
            file_info {
                general {
                    format
                    file_size
                }
                video {
                    format
                    duration
                    width
                    height
                }
                image {
                    format
                    width
                    height
                }
            }
        }
    }

`;

export const MUTATION_ADD_CAMPAIGN_LIBRARY_FILE = gql`
    ${FRAGMENT_CAMPAIGN_LIBRARY_FILE_FIELDS}
    mutation addCampaignLibraryFile(
        $campaignId: String!
        $fileName: String!
        $gsFileLocation: String!
    ) {
        addCampaignLibraryFile(
            campaignId: $campaignId
            fileName: $fileName
            gsFileLocation: $gsFileLocation
        ) {
            ...RetailerCampaignLibraryFileFields
        }
    }
`;

const FRAGMENT_CAMPAIGN_FIELDS = gql`
    ${FRAGMENT_CAMPAIGN_LIBRARY_FILE_FIELDS}
    fragment RetailerCampaignFields on RetailerCampaign {
        id
        name
        status
        start_date
        end_date

        retailer {
            id
            retailer_name
            regions {
                id
                region_name
            }
            product_categories {
                id
                category_name
                sanitized_name
            }
        }

        rollouts(id: $rolloutId) {
            id
            version_number
            version_name
            updated_at
            scheduled_release_date
            released_at
            status
            is_valid
            playlists {
                id
                fb_reference
                product_category {
                    id
                    category_name
                }
                retailer_region {
                    id
                    region_name
                }

                campaign_library_files {
                    id
                }
            }
            library_files {
                ...RetailerCampaignLibraryFileFields
            }
        }
        library_files {
            ...RetailerCampaignLibraryFileFields
        }
    }


`;

export const QUERY_GET_CAMPAIGNS = gql`
    query getCampaigns($campaignId: ID) {
        retailer_campaigns(filter:{id: $campaignId}) {
            id
            name
            status
            start_date
            end_date
            retailer {
                id
                retailer_name
            }
            rollouts {
                id
                version_number
                version_name
                updated_at
                scheduled_release_date
                released_at
                status
                is_valid
                playlists {
                    id
                    fb_reference

                    campaign_library_files {
                        id
                    }
                    retailer_region {
                        id
                        region_name
                    }
                    product_category {
                        id
                        category_name
                    }
                }
            }
        }
    }

`;

export const QUERY_GET_LOCATIONS = gql`
    query get_locations{
        locations {
            id
            location_name
            city
            state
        }
    }
`;

export const QUERY_GET_PRODUCT_CATEGORIES = gql`
    query get_locations{
        product_categories {
            id
            category_name
        }
    }
`;


const FRAGMENT_BRAND_PARTNER_FIELDS = gql`
    fragment BrandPartnerFields on BrandPartner {
        id
        name
        looma_id
        type
        parentBrand {
            id
            name
        }
        brandPartner {
            id
            name
            type
            childBrands{
                id
                name
                type
            }
        }
        created_at
        updated_at
        product_categories{
            id
            category_name
            sanitized_name
        }
        logoUrl
    }
`

export const QUERY_GET_BRAND_PARTNERS = gql`
    ${FRAGMENT_BRAND_PARTNER_FIELDS}
    query get_brand_partners{
        brand_partners {
            ...BrandPartnerFields
        }
    }
`;

const FRAGMENT_PRODUCTS_FIELDS = gql`
    fragment BrandProductFields on BrandProduct {
        id
        name
        brand_partner {
            id
            name
            product_categories {
                id
                category_name
            }
            parentBrand {
                id
                name
            }
        }
        retailers {
            id
            retailer_name
        }
        upc_code
        image_url
        thumb_url
        updatedAt
    }
`;

export const MUTATION_UPSERT_PRODUCT = gql`
    ${FRAGMENT_PRODUCTS_FIELDS}
    mutation upsertMasterData($input:UpsertMasterDataInput!) {
        result: upsertMasterData(input: $input) {
            ${MUTATION_RESPONSE_FIELDS}
            brand_product {
                ...BrandProductFields
            }
        }
    }
`;

export const MUTATION_UPSERT_STORE = gql`
    ${FRAGMENT_STORE_FIELDS}
    mutation upsertMasterData($input:UpsertMasterDataInput!) {
        result: upsertMasterData(input: $input) {
            ${MUTATION_RESPONSE_FIELDS}
            store {
                ...StoreFields
            }
        }
    }
`;

export const MUTATION_MUTATE_DEVICE_SLOT = gql`
    ${FRAGMENT_DEVICE_SLOT_FIELDS}
    mutation mutateDeviceSlot($op: MutationOperation!, $data: DeviceSlotMutationInput!) {
        result: mutateDeviceSlot(op: $op, data: $data) {
            ${MUTATION_RESPONSE_FIELDS}
            device_slot {
                ...DeviceSlotFields
            }
        }
    }
`;

export const MUTATION_BULK_IMPORT = gql`
    ${FRAGMENT_API_RESPONSE_FIELDS}
    mutation bulkImport($importContext: String!, $gsFileLocation: String!) {
        bulkImport(importContext: $importContext, gsFileLocation: $gsFileLocation) {
            ...ApiResponseFields
        }
    }
`;

export const MUTATION_CREATE_RETAILER_CAMPAIGN = gql`
    ${FRAGMENT_CAMPAIGN_FIELDS}
    mutation createRetailerCampaign($retailerId: String!, $campaignName: String!, $rolloutId: String, $startDate: Date, $endDate: Date) {
        createRetailerCampaign(retailerId: $retailerId, campaignName: $campaignName, startDate:$startDate, endDate:$endDate){
            ...RetailerCampaignFields
        }
    }
`;

export const MUTATION_CREATE_CAMPAIGN_DRAFT_ROLLOUT = gql`
    ${FRAGMENT_CAMPAIGN_FIELDS}
    mutation createRetailerCampaignDraftRollout($campaignId: String!, $versionName: String!, $parentRolloutId: String!, $rolloutId: String) {
        createRetailerCampaignDraftRollout(campaignId: $campaignId, versionName: $versionName, parentRolloutId:$parentRolloutId){
            ...RetailerCampaignFields
        }
    }
`;

export const MUTATION_DELETE_CAMPAIGN_DRAFT_ROLLOUT = gql`
    ${FRAGMENT_CAMPAIGN_FIELDS}
    mutation deleteRetailerCampaignDraftRollout($campaignId: String!, $childRolloutId: String!, $rolloutId: String) {
        deleteRetailerCampaignDraftRollout(campaignId: $campaignId, childRolloutId: $childRolloutId){
            ...RetailerCampaignFields
        }
    }
`;

export const MUTATION_DELETE_CAMPAIGN = gql`
    mutation deleteRetailerCampaign($campaignId: String!) {
        deleteRetailerCampaign(campaignId: $campaignId){
            id
        }
    }
`;

export const MUTATION_UPDATE_CAMPAIGN_LIBRARY_FILE = gql`
    ${FRAGMENT_CAMPAIGN_LIBRARY_FILE_FIELDS}
    mutation updateCampaignLibraryFile($campaignId: String!, $fileId: String!, $brandPartnerId: String!, $productCategoryId: String!) {
        updateCampaignLibraryFile(campaignId: $campaignId, fileId: $fileId, brandPartnerId: $brandPartnerId, productCategoryId: $productCategoryId) {
            ...RetailerCampaignLibraryFileFields
        }
    }
`;

export const MUTATION_DELETE_CAMPAIGN_LIBRARY_FILE = gql`
    ${FRAGMENT_CAMPAIGN_LIBRARY_FILE_FIELDS}
    mutation deleteCampaignLibraryFile($campaignId: String!, $fileId: String!) {
        deleteCampaignLibraryFile(campaignId: $campaignId, fileId: $fileId) {
            ...RetailerCampaignLibraryFileFields
        }
    }
`;

export const MUTATION_UPDATE_CAMPAIGN_ROLLOUT = gql`
    ${FRAGMENT_CAMPAIGN_FIELDS}
    mutation updateCampaignRollout($campaignId: String!, $childRolloutId: String!, $data: CampaignRolloutInput!,  $rolloutId: String) {
        updateCampaignRollout(campaignId: $campaignId, rolloutId: $childRolloutId, data: $data) {
            ...RetailerCampaignFields
        }
    }
`;

export const QUERY_GET_CAMPAIGN_LIBRARY_FIELD = gql`
    ${FRAGMENT_CAMPAIGN_LIBRARY_FILE_FIELDS}
    query fetchCampaignLibraryFile($campaignId: ID!, $libraryFileId: String!){
        retailer_campaigns(filter:{id: $campaignId}){
            library_files(id:$libraryFileId){
                ...RetailerCampaignLibraryFileFields
            }
        }
    }
`;

export const QUERY_GET_CAMPAIGN_ROLLOUT = gql`
    ${FRAGMENT_CAMPAIGN_FIELDS}
    query fetchCampaignForEdit($campaignId: ID, $rolloutId: String) {
        retailer_campaigns(filter:{id: $campaignId}){
            ...RetailerCampaignFields
        }
    }

`;

// unassigned and reporting devices
export const QUERY_GET_TESTING_DEVICES = gql`
    query getAlphaTestingDevices {
        devices(
            criteria: {
                filters: [
                    {
                        field: DeviceAssigned
                        operator: Eq
                        value: { name: "Not assigned", value: "false" }
                    },{
                        field:PlaybackStatus,
                        operator: Eq,
                        value:{value:"1"}
                    }
                ]
            }
        ) {
            devices {
                id
                device_id
            }
        }
    }
`;

const DEVICE_SLOT_IMAGE_FIELDS = gql`
    fragment DeviceSlotImageFields on DeviceSlotImage {
        id
        date
        approved
        source
        thumb: version(name: "thumb") {
            url
        }
        lastScoreSubmission{
            id
            imageHasCorrectOrientation
            validationStatus
            submittedByUser {
                id
                email
                display_name
            }
        }
        scores {
            id
            brandPartnerId
            score
        }
        large: version(name: "optimized") {
            url
        }
        original: version(name: "original") {
            url
        }
        photos {
            id
            isHidden
            isPrimary
            large: version(name: "optimized") {
                url
            }
            original: version(name: "original") {
                url
            }
            thumb: version(name: "thumb") {
                url
            }
        }
        deviceSlot {
            id
            name
            product_category {
                id
                category_name
            }
            store {
                id
                retailer_store_num
                retailer {
                    id
                    retailer_name
                    retailer_id
                }
                retailer_region {
                    id
                    region_name
                }
            }
        }
    }
`


export const QUERY_FETCH_DEVICE_SLOT_IMAGES = gql`
    ${DEVICE_SLOT_IMAGE_FIELDS}
    query getDeviceSlotImages($filter: DeviceSlotImageFilter!) {
        device_slot_images(filter:$filter) {
            cursor
            images {
                ...DeviceSlotImageFields
            }
        }
    }
`;

export const QUERY_SEARCH_OBJECT = gql`
    query search_object($kind: SearchableObject!, $criteria:[SearchFieldCriteria!]!){
        search_object(kind:$kind, criteria:$criteria){
            name
            hint
            value
        }
    }
`;

export const QUERY_GET_SCORES_AND_BRANDS_FOR_IMAGE = gql`
    query getImageScoresAndBrandsForImage($deviceSlotImageId:ID!){

        device_slot_images(filter:{ids:[$deviceSlotImageId]}){
            images{
                scores{
                    id
                    brandPartnerId
                    score
                }
                targetBrandCampaigns{
                    id
                    brandPartner{
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const MUTATION_MUTATE_DEVICE_SLOT_IMAGE = gql`
    ${DEVICE_SLOT_IMAGE_FIELDS}
    mutation updateDeviceSlotImage($op: MutationOperation!, $data:DeviceSlotImageMutationInput!){
        result: mutateDeviceSlotImage(op: $op, value:$data){
            success
            message
            image: device_slot_image{
                ...DeviceSlotImageFields
            }
        }
    }
`;

const USER_FIELDS_FRAGMENT = gql`
    fragment UserFields on User {
        id
        email
        display_name
        email_notification_sent_at
        last_logged_in_at
        invitationEmail
        roles {
            id
            name
            permissions {
                key
                name
            }
        }
        associations {
            brands{
                id
                name
                looma_id
            }
            retailers {
                id
                retailer_name
                retailer_id
            }
        }
    }

`;

export const QUERY_FETCH_USERS = gql`
    ${USER_FIELDS_FRAGMENT}
    query users($userFilter:UserFilterCriteria){
        users(criteria:$userFilter){
            cursor
            data: users{
                ...UserFields
            }
        }
    }
`;

export const QUERY_FETCH_PET_FORM_QUESTIONS = gql`
    query petFormQuestions{
        petFormQuestions{
            cursor
            data: petFormQuestions{
                id
                name
                hometown
                email
                question
                questionType
                createdAt
                updatedAt
                retailerId
            }
        }
    }
`;

export const QUERY_FETCH_PET_FORM_UPLOADS = gql`
    query petFormUploads{
        petFormUploads{
            cursor
            data: petFormUploads{
                id
                petName
                name
                email
                hometown
                content_url
                content_type
                createdAt
                updatedAt
                retailerId
            }
        }
    }
`;


export const MUTATION_CREATE_USER = gql`
    ${USER_FIELDS_FRAGMENT}
    mutation createUser($data:UserMutationInput) {
        result: user(
            id: "new"
            op: Create
            data: $data
        ) {
            success
            message
            user {
                ...UserFields
            }
        }
    }
`;


export const MUTATION_UPDATE_USER = gql`
    ${USER_FIELDS_FRAGMENT}
    mutation updateUser($id:ID!, $data:UserMutationInput) {
        result: user(
            id: $id
            op: Update
            data: $data
        ) {
            success
            message
            user {
                ...UserFields
            }
        }
    }
`;

export const MUTATION_DELETE_USER = gql`
    ${USER_FIELDS_FRAGMENT}
    mutation deleteUser($id:ID!) {
        result: user(id: $id, op: Delete) {
            success
            message
            user {
                ...UserFields
            }
        }
    }
`;

export const QUERY_FETCH_USER_ROLES = gql`
    query userRoles($criteria:UserRoleFilterCriteria) {
        userRoles(criteria:$criteria) {
            cursor
            data: userRoles {
                id
                name
                description
                permissions {
                    key
                    name
                    groupName
                }
            }
        }
    }
`;

export const QUERY_FETCH_USER_ACCESS_PERMISSIONS = gql`
    query userAccessPermissions {
        userAccessPermissions {
            key
            name
            groupName
        }
    }
`;

export const MUTATION_UPSERT_USER_ROLE = gql`
    mutation upsertUserRole($op: MutationOperation!, $data:UserRoleInput!) {
        result: upsertUserRole(op: $op, data: $data) {
            success
            userRole {
                id
                name
                description
                permissions {
                    key
                    name
                    groupName
                }
            }
        }
    }
`;


export const QUERY_FETCH_MEDIA_FILES_FILM_VARIABLES = gql`
    query media_file_film_variables($id:ID!){
        media_file_film_variables(id:$id){
            cursor
            data: mediaFileFilmVariables {
                id
                film_variable {
                    id
                    name
                }
                variable_value
            }
        }
    }
`;


export const MUTATION_UPLOAD_MEDIA_FILE_FILM_VARIABLES = gql`
    ${FRAGMENT_API_RESPONSE_FIELDS}
    mutation uploadMediaFileVariables(
        $mediaFileId: ID!
        $gsFileLocation: String!
    ) {
        uploadMediaFileVariables(
            mediaFileId: $mediaFileId
            gsFileLocation: $gsFileLocation
        ) {
            ...ApiResponseFields
        }
    }
`;

export const MUTATION_UPDATE_MEDIA_FILE_VARIABLE = gql`
    mutation update_media_file_variable($variableId:ID!, $variableValue: String!, $filmId:ID!) {
        result: updateMediaFileVariable(variableId: $variableId, variableValue: $variableValue, filmId: $filmId) {
            success
            media_file_film_variable {
                id
                variable_value
                film_variable {
                    id
                    name
                }
            }
        }
    }
`;

export const MUTATION_DELETE_MEDIA_FILE_VARIABLE = gql`
    mutation delete_media_file_variable($id:ID!) {
        result: deleteMediaFileVariable(id: $id) {
            message
            success
            media_file_film_variable {
                id
                variable_value
                film_variable {
                    id
                    name
                }
            }
        }
    }
`;

export const MUTATION_MUTATE_DEVICE_SLOT_IMAGE_PHOTO = gql`
    ${DEVICE_SLOT_IMAGE_FIELDS}
    mutation mutateDeviceSlotImagePhoto($deviceSlotImageId:ID!, $imageInput:DeviceSlotImagePhotoMutationInput!){
        mutateDeviceSlotImagePhotos(deviceSlotImageId:$deviceSlotImageId, data:[$imageInput]){
            ${MUTATION_RESPONSE_FIELDS}
            device_slot_image{
                ...DeviceSlotImageFields
            }
        }
    }
`;

export const MUTATION_SET_ACCELEROMETER_THRESHOLD = gql`
    mutation setAccelerometerThreshold($deviceId:ID!, $value: Float!){
        setAccelerometerThreshold(deviceId: $deviceId, value: $value){
            ${MUTATION_RESPONSE_FIELDS}
            device{
                remote_accelerometer_info{
                    calibration_threshold
                }
            }
        }
    }
`;

export const MUTATION_MUTATE_REMOTE_DEVICE_CONFIG = gql`
    mutation mutateRemoteDeviceConfig($deviceId:ID!, $data: RemoteDeviceConfigMutationInput!){
        mutateRemoteDeviceConfig(deviceId: $deviceId, data: $data){
            ${MUTATION_RESPONSE_FIELDS}
            device{
                remoteDeviceConfig{
                    autoRebootEnabled
                    envSwitchEnabled
                    volumeTiersEnabled
                }
            }
        }
    }
`;

export const MUTATION_UPDATE_DEVICE_SLOT_SEGMENT = gql`
    mutation mutateDeviceSlotSegment($op: MutationOperation!, $data:DeviceSlotSegmentInput!) {
        result: mutateDeviceSlotSegment(op: $op, data: $data) {
            ${MUTATION_RESPONSE_FIELDS}
            deviceSlotSegment {
                id
                name
                promoPeriod {
                    id
                    name
                }
                deviceSlots {
                    id
                }
                deviceSlotType {
                    id
                    name
                    retailerPromoProgram {
                        id
                        name
                        deviceSlotTypes {
                            id
                            name
                        }
                    }
                }
                defaultBrandSlotsCount
            }
        }
    }
`;


export const MUTATION_COPY_DEVICE_SLOT_SEGMENT = gql`
    mutation copyDeviceSlotSegments($segments: [ID!]!, $toPromoPeriodId:ID!) {
        result: copyDeviceSlotSegments(segments: $segments, toPromoPeriod: $toPromoPeriodId) {
            success
            retailerPromoPeriod {
                id
                name
                status
                startDate
                endDate
                activateAt
                promoPrograms {
                    id
                    name
                    active
                    deviceSlotTypes {
                        id
                        name
                    }
                }
                deviceSlotSegments {
                    id
                    name
                    deviceSlotType {
                        id
                        name
                        retailerPromoProgram {
                            id
                            name
                            deviceSlotTypes {
                                id
                                name
                            }
                        }
                    }
                    deviceSlots {
                        id
                    }
                    defaultBrandSlotsCount
                }
            }
        }
    }
`;


export const FRAGMENT_MEDIA_CONTENT_FIELDS = gql`
    fragment MediaContentFields on MediaContent {
        id
        contentType
        displayName
        description
        filmMaker
        vimeoVideoId
        isDraft
        brandPartner {
            id
            name
            type
            logoUrl
            childBrands{
                id
                name
                type
            }
            parentBrand{
                id
                name
                type
            }
        }
        retailer {
            id
            retailer_name
        }
        externalUrl
        externalThumbUrl
        externalDownloadUrl
        defaultMediaContentVersion {
            id
            fileName
            thumbnail
            processedUrl
            mediaContent {
                id
                displayName
                contentType
            }
        }
        mediaContentVersions {
            id
            fileName
            thumbnail
            processedUrl
            mediaContent {
                id
                displayName
                contentType
            }
        }
    }
`;
