import {fabric} from "fabric";
import {IObjectOptions} from "fabric/fabric-impl";
import {BehaviorSubject, merge, Observable, of, Subject, Subscription, timer} from "rxjs";
import {deepMerge, ImageSourceType, loadImage, onWindowResize, Rect, Utils} from "@looma/shared/utils";
import {debounce, debounceTime, last, map, startWith} from "rxjs/operators";
import {TextStyleAttrs} from "./types";
import {TemplateImageComponent} from "./template_image_component";
import {TemplateTextComponent} from "./template_text_component";
import {ImageTemplateData, ImageTemplateVariables} from "./templates";


export class SplashTemplateRenderer {
    private canvas: fabric.Canvas
    private defaultObjectOptions: IObjectOptions = {
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
        hasBorders: false,
        selectable: false,
    }

    backgroundImage: TemplateImageComponent
    backgroundBrandingImage: TemplateImageComponent
    logoImage: TemplateImageComponent
    title: TemplateTextComponent

    viewportDimensions = {
        width: 0,
        height: 0,
        zoom: 1,
    }

    constructor(
        private canvasDomElement: HTMLCanvasElement,
        private templateData: ImageTemplateData,
        private templateVars: ImageTemplateVariables = {},
        public width: number = 1920,
        public height: number = 1080) {

        const canvas = this.canvas = new fabric.Canvas(canvasDomElement, {
            width: this.width,
            height: this.height,
            selection: false,
            interactive: false,
        })

        this.viewportDimensions = {
            width: this.width,
            height: this.height,
            zoom: 1
        }

        const fullSizeRect: Rect = {
            left: 0,
            top: 0,
            width: this.width,
            height: this.height
        }

        this.backgroundImage = new TemplateImageComponent(canvas, fullSizeRect, 'center-crop', this.defaultObjectOptions)
        this.backgroundBrandingImage = new TemplateImageComponent(canvas, fullSizeRect, 'none', this.defaultObjectOptions)
        this.backgroundBrandingImage.setSource(this.templateData.brandingImageUrl)

        this.logoImage = new TemplateImageComponent(canvas, this.templateData.logo.rect, 'center-fill', this.defaultObjectOptions)

        const titleStyle: TextStyleAttrs = {}
        deepMerge(titleStyle, this.templateData.title.style)
        this.title = new TemplateTextComponent(canvas, this.templateData.title.rect, titleStyle, this.defaultObjectOptions)

        if (templateVars) {
            this.applyTemplateVars(templateVars)
        }
    }

    setViewportSize(width: number, height: number) {
        const newZoom = Math.min(
            width / this.width,
            height / this.height,
        )
        this.canvas.setDimensions({width: width, height: height})
        this.canvas.setZoom(newZoom)

        this.viewportDimensions = {
            width: width,
            height: height,
            zoom: newZoom
        }
    }

    applyTemplateVars(vars?: ImageTemplateVariables) {
        vars ||= {}

        deepMerge(this.templateVars, vars)

        if (vars.backgroundImageUrl) {
            this.backgroundImage.setSource(vars.backgroundImageUrl)
        }

        const logoVars = vars.logo
        if (logoVars) {
            if (logoVars.scale) {
                this.logoImage.setScale(logoVars.scale)
            }
            if (logoVars.url) {
                this.logoImage.setSource(logoVars.url)
            }
        }
        const titleVars = vars.title
        if (titleVars) {
            if (titleVars.text) {
                this.title.setText(titleVars.text)
            }
            if (titleVars.style) {
                this.title.setStyle(titleVars.style)
            }
        }

    }

    toBlob(): Observable<Blob> {

        return new Observable<Blob>(subscriber => {
            this.runWithoutZoom((done) => {
                this.canvasDomElement.toBlob(blob => {
                    subscriber.next(blob)
                    subscriber.complete()
                    done()
                }, "image/png", 1.0)
            })
        })

    }

    private runWithoutZoom(callback: (done: () => void) => void) {

        const afterRender = () => {
            this.canvas.off('after:render', afterRender)
            callback(() => {
                setTimeout(() => {
                    this.canvas.setZoom(this.viewportDimensions.zoom)
                    this.canvas.setDimensions({
                        width: this.viewportDimensions.width,
                        height: this.viewportDimensions.height
                    })
                }, 100)
            })
        }

        this.canvas.setZoom(1)
        this.canvas.setDimensions({
            width: this.width,
            height: this.height
        })

        this.canvas.on('after:render', afterRender)
    }

    download() {
        this.runWithoutZoom((done) => {
            const link = document.createElement('a')
            done()
            link.download = 'image.png';
            link.href = this.canvasDomElement.toDataURL('image/png', 1.0)
            link.click()
        })
    }

}


function drawGrid(canvas, spacing) {
    for (let i = 0; i < (canvas.width / spacing); i++) {
        canvas.add(new fabric.Line([i * spacing, 0, i * spacing, canvas.height], {
            stroke: '#ccc',
            selectable: false
        }));
    }
    for (let j = 0; j < (canvas.height / spacing); j++) {
        canvas.add(new fabric.Line([0, j * spacing, canvas.width, j * spacing], {
            stroke: '#ccc',
            selectable: false
        }));
    }
}
