import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppCommonModule} from "../../shared/common.module";
import {RouterModule} from "@angular/router";
import {LayoutModule} from "../../layout/layout.module";
import {DndModule} from "ngx-drag-drop";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatBadgeModule} from "@angular/material/badge";
import {
    PromoScheduleEditDialogComponent
} from './components/promo-schedule-edit-dialog/promo-schedule-edit-dialog.component';
import {
    PromoSchedulesForRetailerListComponent
} from './components/promo-schedules-for-retailer-list/promo-schedules-for-retailer-list.component';
import {
    PromoScheduleProgramOverviewComponent
} from './components/promo-schedule-program-overview/promo-schedule-program-overview.component';
import {
    BrandCampaignEditDialogComponent
} from "./components/brand-campaign-edit-dialog/brand-campaign-edit-dialog.component";
import {
    PromoPeriodPlaylistsOverviewComponent
} from './components/promo-period-playlists-overview/promo-period-playlists-overview.component';
import {
    PromoCampaignPlaylistTabComponent
} from './components/promo-campaign-playlist-tab/promo-campaign-playlist-tab.component';
import {
    MediaPlaylistVersionDialogComponent
} from './components/media-playlist-version-dialog/media-playlist-version-dialog.component';
import {
    PromoSubgroupPlaylistScheduleComponent
} from './components/promo-subgroup-playlist-schedule/promo-subgroup-playlist-schedule.component';
import {
    PlaylistScheduleEntryDialogComponent
} from './components/playlist-schedule-entry-dialog/playlist-schedule-entry-dialog.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {SegmentEditDialogComponent} from "./components/segment-edit-dialog/segment-edit-dialog.component";
import {
    SegmentDeviceSlotAssignmentComponent
} from "./components/segment-device-slot-assignment/segment-device-slot-assignment.component";
import {SegmentCopyDialogComponent} from "./components/segment-copy-dialog/segment-copy-dialog.component";
import {MatTreeModule} from "@angular/material/tree";
import {PromoSchedulesListComponent} from './components/promo-schedules-list/promo-schedules-list.component';
import {
    PromoPlaylistMediaBundleDialogComponent
} from './components/promo-playlist-media-bundle-dialog/promo-playlist-media-bundle-dialog.component';
import {GenericModule} from "../generic/generic.module";
import {LoomaComponentsModule} from "@looma/shared/components/looma-components.module";
import {
    PromoPeriodSurveyOverviewComponent
} from './components/promo-period-survey-overview/promo-period-survey-overview.component';
import {SurveyProductEditComponent} from './components/survey-product-edit/survey-product-edit.component';
import {SurveyHomeScreenEditComponent} from './components/survey-home-screen-edit/survey-home-screen-edit.component';
import {ScheduleCalendarComponent} from './components/schedule-calendar/schedule-calendar.component';
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {
    ProgramSubmissionsOverviewComponent
} from './components/program-submissions-overview/program-submissions-overview.component';
import {
    ProgramSubmissionProductsComponent
} from './components/program-subission-products/program-submission-products.component';
import {BrandSubmissionStatusComponent} from './components/brand-submission-status/brand-submission-status.component';
import {
    CampaignSubmissionCardComponent
} from "./components/campaign-submission-card/campaign-submission-card.component";
import {EnrolledProductsComponent} from './components/enrolled-products/enrolled-products.component';
import { WineRecsCampaignGenerationComponent } from './components/wine-recs-campaign-generation/wine-recs-campaign-generation.component';

@NgModule({
    declarations: [
        PromoScheduleEditDialogComponent,
        PromoSchedulesForRetailerListComponent,
        PromoScheduleProgramOverviewComponent,
        BrandCampaignEditDialogComponent,
        PromoPeriodPlaylistsOverviewComponent,
        PromoCampaignPlaylistTabComponent,
        MediaPlaylistVersionDialogComponent,
        PromoSubgroupPlaylistScheduleComponent,
        PlaylistScheduleEntryDialogComponent,
        SegmentEditDialogComponent,
        SegmentDeviceSlotAssignmentComponent,
        SegmentCopyDialogComponent,
        PromoSchedulesListComponent,
        PromoPlaylistMediaBundleDialogComponent,
        PromoPeriodSurveyOverviewComponent,
        SurveyProductEditComponent,
        SurveyHomeScreenEditComponent,
        ScheduleCalendarComponent,
        ProgramSubmissionsOverviewComponent,
        ProgramSubmissionProductsComponent,
        BrandSubmissionStatusComponent,
        CampaignSubmissionCardComponent,
        EnrolledProductsComponent,
        WineRecsCampaignGenerationComponent
    ],
    imports: [
        CommonModule,
        AppCommonModule,
        RouterModule,
        LayoutModule,
        DndModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatTreeModule,
        GenericModule,
        LoomaComponentsModule,
        MatLegacyChipsModule
    ]
})
export class RetailerPromoCampaignsModule {
}
