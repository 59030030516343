import { BaseModel } from '@looma/shared/models/base_model';
import { MediaContent } from "@looma/shared/models/media_content";

export enum MediaContentVersionType {
    loop = "loop",
    clean = "clean",
}

export class MediaContentVersion extends BaseModel {
    id: string;
    fileName: string;
    processedUrl: string;
    originalUrl: string;
    thumbnail: string;
    createdAt: string;
    updatedAt: string;
    mediaContent: MediaContent
    videoDuration: number;

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'mediaContent', MediaContent)
        return this;
    }

    isNewRecord(): boolean {
        return !this.id
    }
    
    getDisplayName(): string {
        return this.mediaContent?.displayName
    }
}
