import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {RetailerPromoSchedule} from "@looma/shared/models/retailer_promo_schedule";
import {RetailerPromoPeriod, RetailerPromoPeriodProgramEntry} from "@looma/shared/models/retailer_promo_periods";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {ApiDataService} from "../../../../services/api-data.service";
import {PlaylistsControllerService} from "../../services/playlist-controller.service";
import {Utils} from "@looma/shared/utils";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {flatMap, takeUntil} from "rxjs/operators";
import {SurveyProductEditComponent} from "../survey-product-edit/survey-product-edit.component";
import {LayoutService} from "../../../../services/layout.service";
import gql from "graphql-tag";
import {SurveyVersion} from "@looma/shared/models/survey_version";
import {SurveyProductEntry} from "@looma/shared/models/survey_product_entry";
import {MatTable} from "@angular/material/table";
import {SurveyContent} from "@looma/shared/models/survey_content";
import {ConfirmDialogComponent} from "@looma/shared/components/confirm-dialog/confirm-dialog.component";
import {SurveyDataListComponent} from "../../../survey/survey-data-list/survey-data-list.component";
import {UploadService} from "@looma/shared/services/upload.service";
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../../services/toast-notification.service";
import {SurveyHomeScreenEditComponent} from "../survey-home-screen-edit/survey-home-screen-edit.component";

@LifecycleHooks()
@Component({
    selector: 'app-promo-period-survey-overview',
    templateUrl: './promo-period-survey-overview.component.html',
    styleUrls: ['./promo-period-survey-overview.component.scss'],
    providers: [PlaylistsControllerService]
})
export class PromoPeriodSurveyOverviewComponent implements OnInit {

    displayedColumns: string[] = ['brand_partner', 'brand_product', 'product_image', 'product_placement_image', 'wine_type', 'wine_pricing', 'tasting_note', 'tasting_note_image', 'actions'];

    @ViewChildren('tbl') tables: QueryList<MatTable<MatTable<SurveyContent>>>;

    title = 'Surveys';
    programEntries: RetailerPromoPeriodProgramEntry[];
    currentProgram: RetailerPromoPeriodProgramEntry;
    segments = [] as DeviceSlotSegment[]

    surveyIndex = new Map<string, SurveyVersion>()

    static open(router: Router, promoSchedule: RetailerPromoSchedule, promoPeriod: RetailerPromoPeriod, prog: RetailerPromoProgram) {
        const extras: NavigationExtras = {}
        if (prog) {
            extras.queryParams = {
                prog: prog.id
            }
        }
        router.navigate([`promo-periods/${promoSchedule.id}-${promoPeriod.id}/surveys`], extras)
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private svcApi: ApiDataService,
        private router: Router,
        public svcLayout: LayoutService,
        private svcUpload: UploadService,
        private svcToastNotif: ToastNotificationService,
        private svcController: PlaylistsControllerService) {
    }

    ngOnInit(): void {
        const programId = this.activatedRoute.snapshot.queryParams["prog"] || null
        if (programId == null) {
            return;
        }

        const id = this.activatedRoute.snapshot.paramMap.get('id') || '';
        const chunks = id.split('-');

        if (chunks.length != 2) {
            return
        }

        const promoScheduleId = parseInt(chunks[0])
        const promoPeriodId = parseInt(chunks[1])
        this.svcApi.rawQueryNoCache({
            query: QUERY_FETCH_PROMO_PERIOD_DATA,
            variables: {
                promoScheduleId: promoScheduleId,
                promoPeriodId: promoPeriodId
            },
        }).subscribe(value => {
            const src = Utils.getNestedObject(value.data, 'retailerPromoSchedules', 'data')

            if (Array.isArray(src)) {
                const promoPeriod = Utils.getNestedTypedObject(src[0], RetailerPromoPeriod, 'promoPeriod')
                this.svcController.init(promoScheduleId, promoPeriod)
                this.programEntries = promoPeriod.programEntries

                const sch = new RetailerPromoSchedule()
                sch.id = promoScheduleId + ''
                promoPeriod.promoSchedule = sch;

                this.programEntries.forEach(e => {
                    e.promoPeriod = promoPeriod
                })

                this.currentProgram = this.programEntries.find(entry => entry.promoProgram.getId() == programId)
                this.title = `${promoPeriod.name} - ${this.currentProgram.promoProgram.name} - Survey`

                this.segments = this.currentProgram.deviceSlotSegments
                this.segments.forEach(segment => {
                    this.loadSurveyForSegment(promoPeriodId, segment.id)
                })
            }
        })
    }

    loadSurveyForSegment(promoPeriodId: number, segmentId: string) {
        this.svcApi.rawQueryNoCache({
            query: QUERY_GET_SURVEY,
            variables: {
                retailerPromoPeriodId: promoPeriodId,
                deviceSlotSegmentId: segmentId
            },
        }).subscribe(value => {
            const surveyInputData = value.data["getSurvey"]
            if (surveyInputData) {
                const survey = new SurveyVersion()
                survey.assign(surveyInputData)
                this.surveyIndex.set(segmentId, survey)
            }
        })
    }

    getSurveyProducts(segmentId: string) {
        const survey = this.surveyIndex.get(segmentId)
        if (!survey) {
            return []
        }
        return survey.products
    }

    getSurveyForSegment(segmentId: string): SurveyVersion {
        return this.surveyIndex.get(segmentId)
    }

    copyToClipboard(content: string){
        if(content){
            navigator.clipboard.writeText(content);
            this.svcLayout.showSnackMessage('Copied to clipboard');
        }
    }

    publishSurvey(segment: DeviceSlotSegment) {
        this.svcLayout.openDialogForResult(ConfirmDialogComponent, {
            data: {
                message: `Are you sure you want to publish survey for segment <strong>${segment.name}</strong>?`
            }
        }).subscribe(confirmed => {
            if (confirmed == "positive") {
                return this.svcApi.rawObjectMutate(MUTATION_PUBLISH_SURVEY, {
                    retailerPromoPeriodId: this.currentProgram.promoPeriod.id,
                    deviceSlotSegmentId: segment.id,
                }, SurveyVersion)
                    .pipe(takeUntil(Utils.onDestroy(this)))
                    .subscribe(result => {
                        if (result.success) {
                            this.surveyIndex.set(segment.id, result.data)
                            this.svcLayout.showSnackMessage(`Survey for segment ${segment.name} published`)
                        } else {
                            this.svcLayout.showMutationResultMessage(result)
                        }
                    })
            }
        })
    }

    canPublish(segment: DeviceSlotSegment): boolean {
        if (segment == null) {
            return false
        }
        const survey = this.getSurveyForSegment(segment.id)
        return survey?.isDirty && survey.hasContent
    }

    onEdit(segment: DeviceSlotSegment, prod: SurveyProductEntry) {
        SurveyProductEditComponent.open(this.svcLayout, {
            programEntry: this.currentProgram,
            deviceSlotSegment: segment,
            surveyProductEntry: prod
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(result => {
            if (!result) {
                return
            }
            const surveyVersion = this.surveyIndex.get(segment.id)
            if (surveyVersion) {
                const products = surveyVersion.products
                const newProducts = products.map(p => {
                    if (p.id == result.id) {
                        return result
                    } else {
                        return p
                    }
                })

                surveyVersion.isDirty = true
                surveyVersion.products = newProducts
                const segmentIdx = this.segments.map(s => s.id).indexOf(segment.id)
                this.tables.toArray()[segmentIdx].renderRows()
            }
        })
    }

    addHtmlSurvey(segment: DeviceSlotSegment): void {
        const session = this.svcUpload.newUploadSession({
            fileTypes: ['application/zip'],
            multiSelection: false,
        })

        let notif: ToastNotification
        session.pickSingleFile().pipe(
            takeUntil(Utils.onDestroy(this)),
            flatMap(uploadedFile => {
                notif = this.svcToastNotif.create({
                    title: segment.name+' survey',
                    description: 'Uploading survey',
                    dismissable: false,
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                })

                this.svcToastNotif.connect(session, null, notif)        

                return session.uploadFile(uploadedFile)
            }),
            flatMap(uploadedFileInfo => {
                notif.update({
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                    description: "Processing survey",
                })
                return this.svcApi.rawObjectMutate(MUTATION_CREATE_HTML_SURVEY, {
                    retailerPromoPeriodId: this.currentProgram.promoPeriod.id,
                    deviceSlotSegmentId: segment.id,
                    storageKey: uploadedFileInfo.firebaseKey
                }, SurveyVersion)
            })
        ).subscribe(result => {
            if (result.success) {
                notif.update({
                    description: "Survey created",
                    dismissable: true,
                    style: ToastNotificationStyle.Success
                })

                this.surveyIndex.set(segment.id, result.data)
                this.surveyIndex = new Map(this.surveyIndex)

            } else {
                notif.dismiss()
                this.svcLayout.showMutationResultMessage(result)
            }
        }, error => {
        }, () => {
            session.destroy()
        })

    }

    addNewProduct(segment: DeviceSlotSegment): void {
        SurveyProductEditComponent.open(this.svcLayout, {
            programEntry: this.currentProgram,
            deviceSlotSegment: segment,
        }).subscribe(result => {
            if (!result) {
                return
            }

            let surveyVersion = this.surveyIndex.get(segment.id)
            if (!surveyVersion) {
                surveyVersion = new SurveyVersion()
                surveyVersion.deviceSlotSegment = segment
                surveyVersion.retailerPromoPeriod = this.currentProgram.promoPeriod
                surveyVersion.products = [].concat(result)
                this.surveyIndex.set(segment.id, surveyVersion)
            } else {
                surveyVersion.products.push(result)
            }

            surveyVersion.isDirty = true

            const segmentIdx = this.segments.map(s => s.id).indexOf(segment.id)
            this.tables.toArray()[segmentIdx].renderRows()
        })
    }

    onDelete(segment: DeviceSlotSegment, element: SurveyProductEntry): void {
        if (!element || !segment) {
            return
        }
        this.svcLayout.openDialogForResult(ConfirmDialogComponent, {
            data: {
                message: `Are you sure you want to remove <strong>${element.brandProduct?.name}</strong> from <strong>${segment.name}</strong>?`
            }
        }).subscribe(confirmed => {
            if (confirmed == "positive") {
                this.svcApi.rawObjectMutate(MUTATION_DELETE_SURVEY_PRODUCT, {
                    id: element.id,
                }, SurveyVersion)
                    .pipe(takeUntil(Utils.onDestroy(this)))
                    .subscribe(result => {
                        if (result.success) {
                            this.svcLayout.showSnackMessage(`Survey product for segment ${segment.name} deleted.`)
                            const surveyVersion = this.surveyIndex.get(segment.id)
                            if (surveyVersion) {
                                const newProducts = surveyVersion.products.filter(p => p.id != element.id)
                                surveyVersion.products = newProducts
                                surveyVersion.isDirty = true
                                this.surveyIndex.set(segment.id, surveyVersion)
                            }
                        } else {
                            this.svcLayout.showMutationResultMessage(result)
                        }
                    })
            }
        })
    }

    manageStaticContent() {
        SurveyDataListComponent.open(this.router,
            this.currentProgram.promoPeriod.promoSchedule,
            this.currentProgram.promoPeriod,
            this.currentProgram.promoProgram
        );
    }

    activateSurvey(segment: DeviceSlotSegment): void {
        this.svcLayout.openDialogForResult(ConfirmDialogComponent, {
            data: {
                message: `Are you sure you want to activate survey for segment <strong>${segment.name}</strong>?`
            }
        }).subscribe(confirmed => {
            if (confirmed == "positive") {
                this.svcApi.rawObjectMutate(MUTATION_ACTIVATE_SURVEY, {
                    retailerPromoPeriodId: this.currentProgram.promoPeriod.id,
                    deviceSlotSegmentId: segment.id,
                }, SurveyVersion)
                    .pipe(takeUntil(Utils.onDestroy(this)))
                    .subscribe(result => {
                        if (result.success) {
                            this.surveyIndex.set(segment.id, result.data)
                            this.svcLayout.showSnackMessage(`Survey for segment ${segment.name} activated`)
                        } else {
                            this.svcLayout.showMutationResultMessage(result)
                        }
                    })
            }
        })
    }

    canActivate(segment: DeviceSlotSegment) {
        if (!segment) {
            return
        }

        const survey = this.getSurveyForSegment(segment.id)
        return survey != null && !survey.isDirty && survey.isPublished() && !survey.isActive() && this.currentProgram.promoPeriod.isActive
    }

    uploadHomePage(segment: DeviceSlotSegment) {
        const survey = this.surveyIndex.get(segment.id)
        if (!survey) {
            return
        }
        SurveyHomeScreenEditComponent.open(this.svcLayout, this.surveyIndex.get(segment.id)).subscribe(result => {
            if (result) {
                console.log(result.isDirty)
                survey.homeScreenName = result.homeScreenName
                survey.homeScreenUrl = result.homeScreenUrl
                survey.isDirty = true

                console.log(survey)
                this.surveyIndex.set(segment.id, survey)
            }
        })
    }
}


const PROMO_PERIOD_FIELDS = `
id
name
status
programEntries {  
  promoProgram {
    id
    name
    kioskDeviceApp {
        id
        app_name
    }
  }
  deviceSlotSegments {
    id
    name
    promoPeriod{
        retailer{
            id
        }
    }
  }
  brandCampaigns {
    id
    name
    promoProgram{
      id
      name
    }
    brandPartner {
      id
      name
    }
    featuredProducts {
      id
      name
    }
    slotAssignments {
      id
      slotIndex
      deviceSlotSegment {
        id
        name
      }
    }
  }
}
`
export const SURVEY_PRODUCT_FIELDS = `
id
description
placementImageUrl
placementImageKey
tastingNoteName
tastingNoteName
tastingNoteImageUrl
tastingNoteImageKey
wineType {
    id
    displayName
    description
    imageUrl
}
winePricing {
    id
    displayName
    description
    imageUrl
}
brandProduct {
    id
    name
    image_url
    upc_code
    brand_partner {
        id
        name
    }
}
mediaContents {
    id
    displayName
    defaultMediaContentVersion {
        id
        fileName
        thumbnail
        processedUrl
        originalUrl
    }
}

brandPromoCampaign {
    id
    name
    featuredProducts {
        id
        name
    }
    brandPartner {
      id
      name
    }
}
`

export const SURVEY_FIELDS = `
id
publishedAt
activatedAt
isDirty
status
firebasePath
homeScreenUrl
homeScreenName
retailerPromoPeriod {
    id
    name
}
deviceSlotSegment {
    id
    name
    promoPeriod{
        retailer{
            id
        }
    }
}
products {
    ${SURVEY_PRODUCT_FIELDS}
}
hasContent
`

export const QUERY_FETCH_PROMO_PERIOD_DATA = gql`
    query fetchDataForPlaylists($promoScheduleId:ID!, $promoPeriodId: ID!) {
        retailerPromoSchedules(filter: { id: $promoScheduleId }) {
            data: retailerPromoSchedules {
                promoPeriod(id: $promoPeriodId) {
                    ${PROMO_PERIOD_FIELDS}
                }
            }
        }
    }
`

export const QUERY_GET_SURVEY = gql`
    query getSurvey($retailerPromoPeriodId:ID!, $deviceSlotSegmentId: ID!) {
        getSurvey(retailerPromoPeriodId:$retailerPromoPeriodId, deviceSlotSegmentId: $deviceSlotSegmentId) {
            ${SURVEY_FIELDS}
        }
    }
`

export const MUTATION_PUBLISH_SURVEY = gql`
    mutation publish($retailerPromoPeriodId:ID!, $deviceSlotSegmentId: ID!) {
        publish(retailerPromoPeriodId:$retailerPromoPeriodId, deviceSlotSegmentId: $deviceSlotSegmentId) {
            success
            message
            validationErrors {
                name
                errors
            }
            promoPeriodSurvey {
                ${SURVEY_FIELDS}
            }
        }
    }
`;


export const MUTATION_ACTIVATE_SURVEY = gql`
    mutation activate($retailerPromoPeriodId:ID!, $deviceSlotSegmentId: ID!) {
        activate(retailerPromoPeriodId:$retailerPromoPeriodId, deviceSlotSegmentId: $deviceSlotSegmentId) {
            success
            message
            validationErrors {
                name
                errors
            }
            promoPeriodSurvey {
                ${SURVEY_FIELDS}
            }
        }
    }
`;

export const MUTATION_DELETE_SURVEY_PRODUCT = gql`
    mutation deleteSurveyProduct($id:ID!) {
        deleteSurveyProduct(id:$id) {
            success
            message
            validationErrors {
                name
                errors
            }
            surveyProductEntry {
                id
            }
        }
    }
`;


const MUTATION_CREATE_HTML_SURVEY = gql`
    mutation uploadHtmlArchive($retailerPromoPeriodId:ID!, $deviceSlotSegmentId: ID!, $storageKey: String!) {
        uploadHtmlArchive(retailerPromoPeriodId:$retailerPromoPeriodId, deviceSlotSegmentId: $deviceSlotSegmentId, storageKey: $storageKey) {
            success
            message
            validationErrors {
                name
                errors
            }
            promoPeriodSurvey {
                ${SURVEY_FIELDS}
            }
        }
    }
`;
