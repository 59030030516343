import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {BrandPartner, BrandPartnerInput} from "@looma/shared/models/brand_partner";
import {ProductCategory} from "@looma/shared/models/product_category";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {NamedValue} from "@looma/shared/types/named_value";
import {Utils} from "@looma/shared/utils";
import {Observable, Subscription} from "rxjs";
import {isBusyUploadState, UploadedFileInfo, UploadState} from "@looma/shared/services/upload.service";
import {map, takeUntil} from "rxjs/operators";
import gql from "graphql-tag";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {AbstractBrandEditDialog} from "../AbstractBrandEditDialog";

@LifecycleHooks()
@Component({
    selector: 'app-brands-edit-dialog',
    templateUrl: './brands-edit-dialog.component.html',
    styleUrls: ['./brands-edit-dialog.component.scss']
})
export class BrandsEditDialogComponent extends AbstractBrandEditDialog
    implements OnInit, OnDestroy {

    constructor(private fb: FormBuilder,
                private dialogRef: MatDialogRef<BrandsEditDialogComponent>,
                private svcApi: ApiDataService,
                private svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) dialogData: BrandsEditDialogData) {
        
        super()
        this.dialogData = dialogData
        this.brand = dialogData.brand

        if (this.brand.parentBrand) {
            this.selectedParentBrand = NamedValue.from(
                this.brand.parentBrand.id,
                this.brand.parentBrand.name,
                this.brand.parentBrand.looma_id,
            )
        }

        let loomaIdSuffix = ''
        if (this.brand.looma_id) {
            const chunks = this.brand.looma_id.split('-')
            loomaIdSuffix = chunks[chunks.length - 1]
        }

        this.form = fb.group({
            name: new FormControl({value: this.brand.name, disabled: false}, [Validators.required]),
            loomaId: new FormControl({value: loomaIdSuffix, disabled: false}, [Validators.required]),
            categoriesFormControl: new FormControl({
                value: this.brand.product_categories,
                disabled: false
            }, [Validators.required])
        });

        if (this.brand.isBrand && !this.selectedParentBrand) {
            this.form.get('loomaId').disable()
        }

    }

    loomaIdInvalidMsg: string;
    loomaIdValidateSubscription: Subscription;

    form: FormGroup;
    brand: BrandPartner;

    categories: ProductCategory[];
    selectedParentBrand: NamedValue;

    private newLogoFirebaseKey: string;

    private upsertBrandPartnerSub: Subscription
    private isUploadingFile = false
    private dialogData: BrandsEditDialogData


    static open(svcLayout: LayoutService, bp: BrandPartner, gqlFields: string): Observable<BrandPartner> {

        const data: BrandsEditDialogData = {
            brand: bp,
            qglFields: gqlFields
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = data;
        dialogConfig.width = '800px';
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        if (!bp.type) {
            throw new Error("can't edit a brand without a type")
        }

        return svcLayout.openDialogForResult(BrandsEditDialogComponent, dialogConfig)
    }

    getSvcApi(): ApiDataService {
        return this.svcApi
    }

    hint(): string {
        return this.selectedParentBrand?.hint
    }

    onLoomaIDAvailable(loomaId: string): void {
        this.brand.looma_id = loomaId
    }

    brandId(): string {
        return this.brand.getStringId()
    }

    get isBusy() {
        return this.isUploadingFile || this.isValidatingLoomaId || !Utils.isUnsubscribed(this.upsertBrandPartnerSub)
    }

    onParentBrandChanged(parentBrand: NamedValue) {
        this.selectedParentBrand = parentBrand
        const loomaIdField = this.form.get('loomaId')
        if (parentBrand) {
            loomaIdField.enable()
            const chunks = (loomaIdField.value || '').split('-')
            this.onLoomaIdSuffixChanged(chunks[chunks.length - 1])
        } else {
            loomaIdField.disable()
            this.brand.looma_id = ''
        }
    }

    canSubmit(): boolean {
        if (this.isBusy) {
            return false
        }
        if (!this.isValid()) {
            return false
        }
        return true
    }

    isValid(): boolean {
        if (!this.form.valid) {
            return false
        }
        if (this.loomaIdInvalidMsg) {
            return false
        }
        if (this.brand.isBrand && !this.selectedParentBrand) {
            return false
        }
        return true
    }

    save(): void {
        if (!this.isValid()) {
            return
        }
        if (this.isBusy) {
            return
        }

        const newRecord = this.brand.isNewRecord();

        const data: BrandPartnerInput = {};
        if (!this.brand.isNewRecord()) {
            data.id = this.brand.id.toString()
        }

        data.name = this.form.get('name').value;
        data.loomaId = this.brand.looma_id;
        data.parentBrandID = this.selectedParentBrand?.getId();
        data.productCategoryIds = this.form.get('categoriesFormControl').value.map(t => t.id);
        if (this.newLogoFirebaseKey) {
            data.logoFirebaseKey = this.newLogoFirebaseKey;
        }

        const action = newRecord ? MutationOperation.Create : MutationOperation.Update;
        this.upsertBrandPartnerSub = this.svcApi.upsertBrandPartner(action, data, this.dialogData.qglFields).subscribe(value => {
            if (value.success) {
                this.dialogRef.close(value.data)
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Unexpected error');
            }
        }, error => {
            this.svcLayout.showSnackMessage('Api error');
        });
    }

    close(): void {
        this.dialogRef.close()
    }

    ngOnInit(): void {
        this.initializeProductCategories();
    }

    ngOnDestroy(): void {
        // No upload session needed
    }

    private initializeProductCategories(): void {
        this.svcApi.getProductCategories().subscribe(categories => {
            this.categories = categories;
        })
    }

    isSmeObj(a: any, b: any): boolean {
        return (a && b) && a.id === b.id;
    }


    handleFileUploaded(data: UploadedFileInfo) {
        this.newLogoFirebaseKey = data.firebaseKey
    }

    handleUploaderStateChanged(state: UploadState) {
        this.isUploadingFile = isBusyUploadState(state)
        if (this.isUploadingFile) {
            this.newLogoFirebaseKey = null
        }
    }

    get isValidatingLoomaId() {
        return !Utils.isUnsubscribed(this.loomaIdValidateSubscription)
    }
}


interface BrandsEditDialogData {
    brand: BrandPartner
    qglFields: string
}


