import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {MediaObserver} from '@angular/flex-layout';

@Injectable({
  providedIn: 'root'
})
export class MediaMatchService {

    constructor(private _observableMedia: MediaObserver){

    }

    onActiveChanged(mediaQuery: string): Observable<boolean>{
        return this._observableMedia.asObservable().pipe(
            map(_ => this._observableMedia.isActive(mediaQuery)),
            distinctUntilChanged()
        )
    }


}
