import {Component, Inject, OnInit} from '@angular/core';
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {
    PromoPlaylistScheduleEntry,
    PromoPlaylistScheduleEntryMutationInput
} from "@looma/shared/models/promo_playlist_schedule_entry";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LayoutService} from "../../../../services/layout.service";
import {ApiDataService} from "../../../../services/api-data.service";
import {Observable} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {PromoPlaylistAssignment} from "@looma/shared/models/promo_playlist_assignment";
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Utils} from "@looma/shared/utils";
import {bufferTime, map} from "rxjs/operators";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-playlist-schedule-entry-dialog',
    templateUrl: './playlist-schedule-entry-dialog.component.html',
    styleUrls: ['./playlist-schedule-entry-dialog.component.scss']
})
export class PlaylistScheduleEntryDialogComponent extends ModelEditDialog<PromoPlaylistScheduleEntry> implements OnInit {

    title: string;
    playlistScheduleEntry: PromoPlaylistScheduleEntry;
    promoPeriod: RetailerPromoPeriod
    availablePlaylistAssignments: PromoPlaylistAssignment[]

    formControls = {
        startDate: new FormControl(),
        endDate: new FormControl(),
        cronExp: new FormControl(null, [Validators.required, cronExpressionValidator]),
        playlist: new FormControl(null, [Validators.required])
    }

    dateControl = new FormGroup({
        startDate: this.formControls.startDate,
        endDate: this.formControls.endDate,
    })


    static open(
        svcLayout: LayoutService,
        data: PlaylistScheduleDialogData
    ): Observable<PromoPlaylistScheduleEntry> {
        return svcLayout.openDialogForResult(PlaylistScheduleEntryDialogComponent, {
            data: data,
        })
    }
    
    constructor(
        public dialogRef: MatDialogRef<PlaylistScheduleEntryDialogComponent>,
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        @Inject(MAT_DIALOG_DATA) private dialogData: PlaylistScheduleDialogData
    ) {
        super()
        this.playlistScheduleEntry = dialogData.schEntry
        this.promoPeriod = dialogData.promoPeriod
        this.availablePlaylistAssignments = dialogData.availablePlaylistAssignments || []
        const selectedPlaylistAssignment = this.availablePlaylistAssignments.find(value => {
            return value.id == this.playlistScheduleEntry.playlistAssignment?.id
        });
        
        
        this.registerFormControlsObject(this.formControls);
        
        this.formControls.playlist.setValue(selectedPlaylistAssignment)
        this.title = this.dialogData.isNewRecord ? 'New Schedule Rule' : 'Edit Schedule Rule'

        this.formControls.startDate.setValue(this.playlistScheduleEntry.startTime)
        this.formControls.endDate.setValue(this.playlistScheduleEntry.endTime)
        this.formControls.cronExp.setValue(this.playlistScheduleEntry.cronExpression)

        const rangeValidator: ValidatorFn = control => {
            const hasStart = Utils.isDate(this.formControls.startDate.value)
            const hasEnd = Utils.isDate(this.formControls.endDate.value)
            if (!hasEnd && !hasStart || hasEnd && hasStart) {
                this.formControls.startDate.setErrors(null)
                return null
            }
            this.formControls.startDate.setErrors({
                invalidDateRange: {
                    message: 'Invalid date range'
                }
            })

        }


        this.dateControl.setValidators([rangeValidator])
        
        Utils.onFormControlsValueChanges(Object.values(this.formControls)).pipe(
            bufferTime(100),
            map(value => {
                const m = new Map<FormControl, { control: FormControl, value: any }>()
                for (const v of value) {
                    m.set(v.control, v)
                }
                return Array.from(m.values())
            })
        ).subscribe(value => {
            for (const v of value) {
                console.warn(v.control, v.value, v.control.errors)
            }

        })
    }

    ngOnInit(): void {
    }

    onSave(): Observable<MutationResponse<PromoPlaylistScheduleEntry>> | null {
        const mutationData: Partial<PromoPlaylistScheduleEntryMutationInput> = {
            op: this.playlistScheduleEntry.isNewRecord() ? MutationOperation.Create : MutationOperation.Update,
            cronExpression: this.formControls.cronExp.value,
            playlistAssignmentId: (this.formControls.playlist.value as PromoPlaylistAssignment).id,
            priority: this.playlistScheduleEntry.priority || 0,
            id: this.playlistScheduleEntry.id
        }

        if (this.formControls.startDate.value && this.formControls.endDate.value) {
            mutationData.startTime = Utils.makeUtcDateString(this.formControls.startDate.value as Date)
            mutationData.endTime = Utils.makeUtcDateString(this.formControls.endDate.value as Date)
        }

        return this.svcApi.mutatePlaylistScheduledEntries(this.dialogData.deviceSlotTypeSubgroupId, mutationData, this.dialogData.gqlFields)
    }

    hasRangeSelected(): boolean {
        return Utils.isDate(this.formControls.startDate.value) || Utils.isDate(this.formControls.endDate.value)
    }

    clearSelectedDateRange() {
        this.formControls.startDate.setValue(null)
        this.formControls.endDate.setValue(null)
    }

}


export interface PlaylistScheduleDialogData {
    deviceSlotTypeSubgroupId: string,
    schEntry: PromoPlaylistScheduleEntry
    promoPeriod: RetailerPromoPeriod
    availablePlaylistAssignments: PromoPlaylistAssignment[]
    gqlFields: string
    isNewRecord: boolean
}

const cronExpressionValidator: ValidatorFn = control => {
    const cronExp = control.value || ''
    if (Utils.isString(cronExp)) {
        const length = cronExp.replace(/ +/g, ' ').trim().split(' ').length
        if (length != 6) {
            return {cronExpLength: {message: 'Cron expression should have 6 fields'}}
        }
        return null
    }
    return {cronExpInvalid: {message: 'Cron expression invalid'}}
}

