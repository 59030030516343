import {Injectable} from "@angular/core";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {RetailerPromoSchedule} from "@looma/shared/models/retailer_promo_schedule";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";
import {
    ApiDataService,
    PromoPlaylistMutationInput,
    PromoPlaylistMutationOp,
    RecordMutationInput
} from "../../../services/api-data.service";
import {LayoutService} from "../../../services/layout.service";
import {EMPTY, Observable} from "rxjs";
import {
    BrandCampaignEditDialogComponent
} from "../components/brand-campaign-edit-dialog/brand-campaign-edit-dialog.component";
import {
    BRAND_CAMPAIGN_GQL_FIELDS
} from "../components/promo-schedule-program-overview/promo-schedule-program-overview.component";
import {flatMap, takeUntil, tap} from "rxjs/operators";
import {PromoPlaylistAssignment} from "@looma/shared/models/promo_playlist_assignment";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import gql from "graphql-tag";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {PromoPlaylistMediaBundle} from "@looma/shared/models/PromoPlaylistMediaBundle";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {
    MediaContentPickerDialogComponent
} from "../../media-content/media-content-picker-dialog/media-content-picker-dialog.component";
import {MediaContentType} from "@looma/shared/models/media_content";
import {
    PromoPlaylistMediaBundleDialogComponent
} from "../components/promo-playlist-media-bundle-dialog/promo-playlist-media-bundle-dialog.component";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {
    SplashImageEditorDialogComponent
} from "../../splash-image-gen/components/splash-image-editor-dialog/splash-image-editor-dialog.component";
import {Utils} from "@looma/shared/utils";
import {Retailer} from "@looma/shared/models/retailer";

@Injectable()
export class PlaylistsControllerService {

    public promoPeriod: RetailerPromoPeriod;
    public promoSchedule: RetailerPromoSchedule

    private brandCampaigns = new Map<number, BrandPromoCampaign>()
    private mediaContentBundles = new Map<number, PromoPlaylistMediaBundle>()

    constructor(
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
    ) {
    }

    init(promoScheduleId: number, promoPeriod: RetailerPromoPeriod) {
        this.promoSchedule = new RetailerPromoSchedule();
        this.promoSchedule.retailer = promoPeriod.retailer
        this.promoSchedule.id = promoScheduleId + '';

        this.setPromoPeriod(promoPeriod)
    }

    registerMediaContentBundles(items: PromoPlaylistMediaBundle[]) {
        for (const item of items) {
            this.mediaContentBundles.set(item.getId(), item)
        }
    }

    private setPromoPeriod(promoPeriod: RetailerPromoPeriod) {
        this.brandCampaigns.clear()
        this.mediaContentBundles.clear()

        this.promoPeriod = promoPeriod
        for (const brandCamp of promoPeriod.brandCampaigns || []) {
            this.registerBrandCampaign(brandCamp)
        }

        for (const entry of promoPeriod.programEntries || []) {
            for (const bc of entry.brandCampaigns || []) {
                this.registerBrandCampaign(bc)
            }
        }

    }

    getMediaContentBundle(mfvId: number): PromoPlaylistMediaBundle {
        if (mfvId + '' == PromoPlaylistMediaBundle.EMPTY.id) {
            return PromoPlaylistMediaBundle.EMPTY;
        }
        return this.mediaContentBundles.get(mfvId);
    }

    getMediaContentBundlesForProgram(program: RetailerPromoProgram) {
        return Array.from(this.mediaContentBundles.values()).filter(value => value.promoProgram?.id == program?.id)
    }

    deleteMediaBundle(mb: PromoPlaylistMediaBundle): Observable<MutationResponse<PromoPlaylistMediaBundle>> {
        return this.svcLayout.onConfirmed('Delete item', `Are you sure you want to delete ${mb.name}`).pipe(
            flatMap(value => {
                return this.svcApi.mutatePromoPlaylistMediaBundle(
                    MutationOperation.Delete, {
                        id: mb.id
                    },
                    PLAYLIST_MEDIA_BUNDLE_GQL_FIELDS
                );
            }),
            tap(resp => {
                if (resp.success) {
                    this.mediaContentBundles.delete(resp.data.getId())
                }
            })
        );
    }

    editMediaBundle(mb: PromoPlaylistMediaBundle): Observable<PromoPlaylistMediaBundle> {
        return PromoPlaylistMediaBundleDialogComponent.open(this.svcLayout, mb, this.promoSchedule.retailer, PLAYLIST_MEDIA_BUNDLE_GQL_FIELDS).pipe(
            tap(mediaBundle => {
                if (mediaBundle) {
                    this.mediaContentBundles.set(mediaBundle.getId(), mediaBundle)
                }
            })
        )
    }

    createMediaBundle(promoPeriod: RetailerPromoPeriod, promoProgram: RetailerPromoProgram): Observable<PromoPlaylistMediaBundle> {
        const availableBrands = new Map<any, BrandPartner>();
        
        for (const camp of this.brandCampaigns.values()) {
            if (camp.promoProgram?.getId() != promoProgram.getId()) {
                continue
            }

            const brandId = camp.brandPartner.getId();
            if (isNaN(brandId) || brandId <= 0) {
                continue;
            }
            availableBrands.set(brandId, camp.brandPartner);
        }

        return MediaContentPickerDialogComponent.open(this.svcLayout, {
            parentBrands: Array.from(availableBrands.values()),
            contentTypes: [MediaContentType.Film, MediaContentType.AncillaryContent]
        }).pipe(
            flatMap(mediaContent => {
                if (!mediaContent) {
                    return EMPTY;
                }

                const rec = new PromoPlaylistMediaBundle();
                rec.brandPartner = mediaContent.brandPartner.parentBrand;
                rec.promoPeriod = promoPeriod;
                rec.promoProgram = promoProgram;
                rec.name = mediaContent.getDisplayName();
                rec.mediaContents = [mediaContent];

                return PromoPlaylistMediaBundleDialogComponent.open(this.svcLayout, rec, this.promoSchedule.retailer, PLAYLIST_MEDIA_BUNDLE_GQL_FIELDS)

            })
        ).pipe(
            tap(mediaBundle => {
                if (mediaBundle) {
                    this.mediaContentBundles.set(mediaBundle.getId(), mediaBundle)
                }
            })
        )
    }

    massAssignSplashScreen(promoPeriod: RetailerPromoPeriod, promoProgram: RetailerPromoProgram) {
        return MediaContentPickerDialogComponent.open(this.svcLayout, {
            contentTypes: [MediaContentType.SplashScreen]
        }).pipe(
            flatMap(mediaContent => {
                if (!mediaContent) {
                    return EMPTY;
                }
                this.svcLayout.showSnackMessage("Saving ...")
                return this.svcApi.massAddMediaContentToPlaylistMediaBundles(promoPeriod.id, promoProgram.id, mediaContent.id, 0, PLAYLIST_MEDIA_BUNDLE_GQL_FIELDS)

            })
        ).pipe(
            tap(resp => {
                if (resp.success) {
                    for (const mediaBundle of resp.dataArray) {
                        this.mediaContentBundles.set(mediaBundle.getId(), mediaBundle)
                    }
                    this.svcLayout.showSnackMessage("Saved")
                } else {
                    this.svcLayout.showMutationResultMessage(resp)
                }
            })
        )
    }

    getBrandCampaign(id: number): BrandPromoCampaign {
        return this.brandCampaigns.get(id)
    }

    private registerBrandCampaign(brandCamp: BrandPromoCampaign) {
        this.brandCampaigns.set(brandCamp.getId(), brandCamp)
    }

    editBrandCampaign(brandCampaign: BrandPromoCampaign): Observable<BrandPromoCampaign> {
        return BrandCampaignEditDialogComponent.open(this.svcLayout, brandCampaign, BRAND_CAMPAIGN_GQL_FIELDS, null).pipe(
            tap(brandCampaign => {
                if (brandCampaign) {
                    this.registerBrandCampaign(brandCampaign);
                }
            })
        )
    }

    submitPlaylistMutation(
        op: PromoPlaylistMutationOp,
        mutationData: RecordMutationInput<PromoPlaylistAssignment, PromoPlaylistMutationInput>[]
    ): Observable<MutationResponse<PromoPlaylistAssignment>[]> {

        let gqlFields = PLAYLIST_ASSIGNMENT_GQL_FIELDS;
        if (op == PromoPlaylistMutationOp.Delete) {
            gqlFields = PLAYLIST_ASSIGNMENT_GQL_FIELDS_FOR_DELETE
        }

        return this.svcApi.mutatePromoPlaylists(op, mutationData, gqlFields).pipe(

        )
    }

}

const PLAYLIST_ASSIGNMENT_GQL_FIELDS_FOR_DELETE = `
id
`

const PLAYLIST_ASSIGNMENT_GQL_FIELDS = `
id
name
flags
isDefault
deviceSlotSegment {
  id
  name
}
playbackEntries {
  loopIndex
  trackIndex
  brandCampaignId
  brandCampaignSlotId
  mediaBundleId
}
mediaPlaylist {
  id
  name
  versions {
    id
    name
    firebasePath
    checksum
    status
  }
}
`

const PLAYLIST_MEDIA_BUNDLE_GQL_FIELDS = `
    id
    name
    promoProgram {
      id
      name
    }
    brandPartner {
      id
      name
      logoUrl
    }
    mediaContents{
      id
      contentType
      displayName
      filmSplashImageUrl
      brandPartner{
        id
        logoUrl
      }
      retailer{
        id
      }
      defaultMediaContentVersion{
        id
        processedUrl
        thumbnail
      }
    }
`

const PROMO_PERIOD_GQL_FIELDS = `
id
name
retailer{
    id
    retailer_name
    retailer_id
}
programEntries {
  playlistMediaBundles{
${PLAYLIST_MEDIA_BUNDLE_GQL_FIELDS}    
  }    
  promoProgram {
    id
    name
    kioskDeviceApp {
        id
        app_name
    }
  }
  deviceSlotSegments {
    id
    name
  }
  brandCampaigns {
    id
    name
    promoProgram{
      id
      name
    }
    brandPartner {
      id
      name
      type
      logoUrl
      childBrands{
        id
        logoUrl
        name
        type
      }
    }
    featuredProducts {
      id
      name
    }
    featuredBrands {
      id
      name
    }
    slotAssignments {
      id
      slotIndex
      deviceSlotSegment {
        id
        name
      }
    }
  }
  playlistAssignments {
    id
    name
    flags
    isDefault
    deviceSlotSegment {
      id
      name
    }
    playbackEntries {
      loopIndex
      trackIndex
      brandCampaignId
      brandCampaignSlotId
      mediaBundleId
    }
    mediaPlaylist {
      id
      name
      versions {
        id
        name
        firebasePath
        checksum
        status
      }
    }
  }
}
`
export const QUERY_FETCH_PLAYLIST_DATA_FOR_PROMO_PERIOD = gql`
    query fetchDataForPlaylists($promoScheduleId:ID!, $promoPeriodId: ID!, $promoProgramId: ID, $includePromoProgram: Boolean!) {
        retailerPromoSchedules(filter: { id: $promoScheduleId }) {
            data: retailerPromoSchedules {
                promoPeriod(id: $promoPeriodId) {
                    ${PROMO_PERIOD_GQL_FIELDS}
                }
            }

        }
        retailerPromoPrograms(filter:{id:$promoProgramId}) @include(if: $includePromoProgram){
            retailerPromoPrograms{
                id
                name
                kioskDeviceApp{
                    id
                }
            }
        }
    }
`
