<div class="page-layout carded fullwidth " style="height: 100%;">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent">
        xxx
    </div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start" fxLayoutAlign="space-between center" style="background-color: #272B37 !important;">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" style="color: white; height: 100%" fxLayout="row" fxLayoutAlign="center stretch">
                <div fxLayout="column" style="height: 100%">
                    <div style="flex:1; display: flex;align-items: center;">
                        <button mat-icon-button class="mr-0 mr-sm-16" style="color: white;" (click)="handleBackPressed()" *ngIf="hasBackPressedListener()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                        <mat-icon class="logo-icon mr-16">
                            {{pageIcon}}
                        </mat-icon>
                        <div>
                            <div class="logo-text h1" style="font-family: ProximaNova-Bold, sans-serif">{{pageTitle}}</div>
                            <div class="logo-text h3" *ngIf="pageSubtitle">{{pageSubtitle}}</div>    
                        </div>
                        
                    </div>
                    <div style="display: flex;align-items: flex-end;" *ngIf="svcPageController.hasTabs">
                        <mat-button-toggle-group class="section_selection_buttons" (change)="svcPageController.activeSection=$event.value">
                            <mat-button-toggle [checked]="svcPageController.activeSection == section"  *ngFor="let section of svcPageController.registerTabs " [value]="section" >{{section.name}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

            </div>
            <!-- / APP TITLE -->

            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="start center" style="position: relative;" >
                <ng-container [ngTemplateOutlet]="svcPageController.headerNavChanged | async"></ng-container>
            </div>


        </div>


        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card" style="position: relative; height:100px; background:#ececee" [class.app-tab-content]="svcPageController.hasTabs" >
            <ng-content></ng-content>
            <ng-container *ngIf="svcPageController.hasTabs"  [ngTemplateOutlet]="(svcPageController?.tabChanged | async)?.template"></ng-container>
        </div>

        
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->


</div>
