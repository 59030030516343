import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {AppNavigationService} from './layout/navigation.service';
import {AppConfigService} from './services/config.service';
import {Utils} from '@looma/shared/utils';
import {LoomaAuthService} from "@looma/shared/auth/components/services/looma-auth.service";
import {AppNavigationHelper} from "./shared/app-navigation";
import {AppNavigationConfig} from "./navigation/navigation";


@LifecycleHooks()
@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private svcNavigation: AppNavigationService,
        private svcAppConfig: AppConfigService,
        private svcAuth: LoomaAuthService,
    ) {
    }

    ngOnInit(): void {
        this.svcAppConfig.onConfigurationChanged()
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // LoomaColor theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        this.svcAuth.onSessionChanged().subscribe(session => {
            if (session && session.user) {
                const navigationItems = JSON.parse(JSON.stringify(AppNavigationConfig.navigationItems))
                const filteredNavItems = AppNavigationHelper.filter(navigationItems,
                    node => {
                        return !node.hasOwnProperty("permission") ||
                            session.user.hasAccessPermission(node.permission);
                    })

                this.svcNavigation.setNavigationItems(filteredNavItems)
                this.navigation = filteredNavItems

            }
        })
    }

    ngOnDestroy(): void {
    }
}
