import {NgModule} from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CdkTableModule} from '@angular/cdk/table';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {ObserversModule} from '@angular/cdk/observers';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DeviceStatusIconComponent} from '../layout/components/device-status-icon/device-status-icon.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularFireAuth, AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {CommonModule} from '@angular/common';
import {
    LoomaGridCellDirective,
    LoomaGridColumnHeaderDirective,
    LoomaGridComponent,
    LoomaGridDragPreviewDirective,
    LoomaGridEmptyDataContentDirective, LoomaGridExtraBodyContent,
    LoomaGridHeadingDirective,
    LoomaGridRowActionsDirective,
    LoomaGridRowExpansionTemplateDirective
} from '../layout/components/looma-grid/looma-grid.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {
    LoomaGridInputFilterComponent
} from '../layout/components/looma-grid-input-filter/looma-grid-input-filter.component';
import {FileAssetViewerComponent} from '../layout/components/media-viewer/file-asset-viewer.component';
import {ToastNotificationsComponent} from '../layout/components/toast-notifications/toast-notifications.component';
import {LoomaGridFilterComponent} from '../layout/components/looma-grid-filter/looma-grid-filter.component';
import {DeviceCommandButtonComponent} from '../layout/components/device-command-button/device-command-button.component';
import {PromptDialogComponent} from '../layout/components/prompt-dialog/prompt-dialog.component';
import {PlacesAutocompleteDirective} from '../layout/directives/places-autocomplete.directive';
import {UploadBottomSheetSelectorComponent, UploadFileInputComponent} from '@looma/shared/services/upload.service';
import {ShellTerminalComponent} from '../layout/components/shell-terminal/shell-terminal.component';
import {AspectRatioGridComponent} from '../layout/components/aspect-ratio-grid/aspect-ratio-grid.component';
import {
    SearchableAutocompleteComponent
} from '../../../../looma-shared/components/searchable-autocomplete/searchable-autocomplete.component';
import {StoreAssignmentComponent} from '../layout/components/store-assignment/store-assignment.component';
import {AngularFireModule} from '@angular/fire/compat';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DescriptionDialogComponent} from '../layout/components/description-dialog/description-dialog.component';
import {VideoPlayerComponent} from '../services/video-player.service';
import {
    AutocompleteSearchFieldComponent
} from '../../../../looma-shared/components/autocomplete-search-field/autocomplete-search-field.component';
import {
    DeviceSlotsAssignmentComponent
} from '../layout/components/device-slots-assignment/device-slots-assignment.component';
import {MatBadgeModule} from '@angular/material/badge';
import {BrowserModule} from "@angular/platform-browser";
import {
    DeviceSlotAssignmentListComponent,
    DeviceSlotAssignmentListFooterDirective
} from "../layout/components/device-slot-assignment-list/device-slot-assignment-list.component";
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {MatLegacySliderModule} from "@angular/material/legacy-slider";
import {GoogleMapsModule} from "@angular/google-maps";


@NgModule({
    exports: [
        // Material Modules
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTableModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatStepperModule,
        CdkTableModule,
        ScrollingModule,
        ObserversModule,
        OverlayModule,
        PlatformModule,

        DragDropModule,

        FlexLayoutModule,

        DescriptionDialogComponent,
        PromptDialogComponent,
        DeviceStatusIconComponent,

        AngularFireAuthModule,
        AngularFirestoreModule,

        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        LoomaGridComponent,
        LoomaGridCellDirective,
        LoomaGridExtraBodyContent,
        LoomaGridDragPreviewDirective,
        LoomaGridColumnHeaderDirective,
        LoomaGridRowActionsDirective,
        LoomaGridRowExpansionTemplateDirective,
        LoomaGridHeadingDirective,
        LoomaGridEmptyDataContentDirective,

        FileAssetViewerComponent,
        ToastNotificationsComponent,
        LoomaGridInputFilterComponent,
        LoomaGridFilterComponent,
        MatCheckboxModule,
        MatChipsModule,
        MatMenuModule,
        DeviceCommandButtonComponent,
        PlacesAutocompleteDirective,
        ShellTerminalComponent,
        AspectRatioGridComponent,
        SearchableAutocompleteComponent,
        StoreAssignmentComponent,
        AutocompleteSearchFieldComponent,
        DeviceSlotsAssignmentComponent,
        DeviceSlotAssignmentListComponent,
        GoogleMapsModule,

        DeviceSlotAssignmentListFooterDirective,
        UploadFileInputComponent
    ],

    declarations: [
        DescriptionDialogComponent,
        DeviceStatusIconComponent,

        LoomaGridComponent,
        LoomaGridCellDirective,
        LoomaGridExtraBodyContent,
        LoomaGridColumnHeaderDirective,
        LoomaGridDragPreviewDirective,
        LoomaGridRowActionsDirective,
        LoomaGridRowExpansionTemplateDirective,
        LoomaGridHeadingDirective,
        LoomaGridEmptyDataContentDirective,

        LoomaGridInputFilterComponent,
        FileAssetViewerComponent,
        ToastNotificationsComponent,
        LoomaGridFilterComponent,
        DeviceCommandButtonComponent,
        PromptDialogComponent,
        PlacesAutocompleteDirective,
        UploadFileInputComponent,
        ShellTerminalComponent,
        AspectRatioGridComponent,
        SearchableAutocompleteComponent,
        StoreAssignmentComponent,
        VideoPlayerComponent,
        AutocompleteSearchFieldComponent,
        DeviceSlotsAssignmentComponent,
        DeviceSlotAssignmentListComponent,

        DeviceSlotAssignmentListFooterDirective
    ],

    imports: [
        DragDropModule,
        AngularFireModule,
        ScrollingModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatListModule,
        MatCheckboxModule,
        MatChipsModule,

        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatSortModule,
        MatProgressSpinnerModule,

        MatDialogModule,
        MatBottomSheetModule,
        MatMenuModule,
        CommonModule,
        MatBadgeModule,
        BrowserModule,

        FlexLayoutModule,
        MatLegacyChipsModule,
        MatLegacySliderModule,
        GoogleMapsModule
    ],
    entryComponents: [
        PromptDialogComponent,
        UploadBottomSheetSelectorComponent,
        UploadFileInputComponent,
    ],
    providers: [
        AngularFireAuth
    ]
})
export class AppCommonModule {
}
