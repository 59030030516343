import {BaseModel} from '@looma/shared/models/base_model';
import {RetailerPromoPeriod} from '@looma/shared/models/retailer_promo_periods'
import {BrandPartner} from '@looma/shared/models/brand_partner'
import {RetailerPromoProgram} from '@looma/shared/models/retailer_promo_program'
import {BrandProduct} from '@looma/shared/models/brand_product'
import {TierGeoPoint} from "../../loop-dashboard/src/app/models/retailer_campaign";
import {BrandPromoCampaignOverview} from "@looma/shared/models/brand_promo_campaign_overview";
import {Retailer} from '@looma/shared/models/retailer';
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {MediaContent, MediaContentType} from "@looma/shared/models/media_content";
import {User} from "@looma/shared/models/user";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {BrandPromoCampaignReport} from "@looma/shared/models/brand_promo_campaign_report";

export class BrandPromoCampaign extends BaseModel {
    id: string;
    name: string;
    isVisible: boolean;
    isPilot: boolean;
    promoPeriod: RetailerPromoPeriod;
    brandPartner: BrandPartner;
    promoProgram: RetailerPromoProgram;
    slotAssignments: BrandPromoCampaignSlotAssignment[];
    featuredProducts: BrandProduct[];
    featuredBrands: BrandPartner[];
    active_tiers_geo_points: TierGeoPoint[];
    overview: BrandPromoCampaignOverview;
    retailer: Retailer;
    mediaContents: MediaContent[];
    contactUsers: User[];
    cprRecipientsUsers: User[];
    attachments: BrandPromoCampaignAttachment[];
    campaignCostDollars: number;
    campaignDiscountDollars: number;
    cprReport: BrandPromoCampaignReport
    uptimeStats: BrandPromoCampaignUptimeStats
    cprEnabled: boolean
    executionReportingEnabled: boolean

    private _donePercent = NaN;

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'promoPeriod', RetailerPromoPeriod);
        this.assignTypedObject(input, 'brandPartner', BrandPartner);
        this.assignTypedObject(input, 'promoProgram', RetailerPromoProgram);
        this.assignTypedObject(input, 'cprReport', BrandPromoCampaignReport);
        this.assignTypedArray(input, 'slotAssignments', BrandPromoCampaignSlotAssignment);
        this.assignTypedArray(input, 'featuredProducts', BrandProduct);
        this.assignTypedObject(input, 'overview', BrandPromoCampaignOverview);
        this.assignTypedObject(input, 'retailer', Retailer);
        this.assignTypedArray(input, 'mediaContents', MediaContent);
        this.assignTypedArray(input, 'contactUsers', User);
        this.assignTypedArray(input, 'cprRecipientsUsers', User);
        this.assignTypedArray(input, 'featuredBrands', BrandPartner);
        return this
    }

    get donePercent(): number {
        if (isNaN(this._donePercent)) {
            this._donePercent = this.calcDonePercent();
        }
        return this._donePercent;
    }

    private calcDonePercent(): number {
        const startedAt = this.promoPeriod.startDate instanceof Date ? this.promoPeriod.startDate.getTime() : NaN;
        const endedAt = this.promoPeriod.endDate instanceof Date ? this.promoPeriod.endDate.getTime() : NaN;
        if (!isNaN(startedAt) && isNaN(endedAt)) {
            return -1
        }
        if (isNaN(startedAt) || isNaN(endedAt) || (startedAt > endedAt)) {
            return 0
        }
        const now = Date.now();
        if (now > endedAt) {
            return 100
        }
        return Math.min(100, Math.max(0, Math.round((now - startedAt) / (endedAt - startedAt) * 100)))
    }

    getFilmsForLoopDisplay(): MediaContent[] {
        return this.mediaContents.filter(item =>
            item.contentType == MediaContentType.Film
        )
    }

    countFilms(): number {
        return this.mediaContents.filter(item => item.contentType == MediaContentType.Film).length
    }

    countAncillaryContent(): number {
        return this.mediaContents.filter(item => item.contentType == MediaContentType.AncillaryContent).length
    }

    countStoresWithTier(tierKey: string): number {
        return this.active_tiers_geo_points.filter(t => t.tier_key == tierKey).length
    }

    getMerchandisingGuideAttachment(): BrandPromoCampaignAttachment {
        return this.attachments?.find(t => t.attachmentType == BrandPromoCampaignAttachmentType.MerchGuide)
    }

    getStoreListAttachment(): BrandPromoCampaignAttachment {
        return this.attachments?.find(t => t.attachmentType == BrandPromoCampaignAttachmentType.StoreList)
    }

    getDeviceSlots(): DeviceSlot[] {
        return this.slotAssignments?.map(t => t.deviceSlotSegment).reduce((acc, val) => acc.concat(val?.deviceSlots), [])
    }

    campaignKey(): string {
        return [this.name.toLowerCase(),
            this.promoProgram?.retailer?.retailer_name?.toLowerCase(),
            this.promoProgram?.retailer?.retailer_id?.toLowerCase(),
            this.brandPartner?.name?.toLowerCase()].join("__")
    }

    getMerchGuide(): BrandPromoCampaignAttachment {
        if (this.attachments == null) {
            return null
        }
        return this.attachments.find(s => s.attachmentType == BrandPromoCampaignAttachmentType.MerchGuide)
    }

}

export class BrandPromoCampaignSlotAssignment extends BaseModel {
    id: string;
    slotIndex: number;

    deviceSlotSegment: DeviceSlotSegment
    deviceSlotSegmentId: string
    brandCampaign: BrandPromoCampaign = null;

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'deviceSlotSegment', DeviceSlotSegment);
        this.assignTypedObject(input, 'brandCampaign', BrandPromoCampaign);
        return this;
    }

    assignmentEquals(other: BrandPromoCampaignSlotAssignment) {
        return this.itemsEqual(this.deviceSlotSegment, other.deviceSlotSegment) && (this.slotIndex == other.slotIndex)
    }

    private itemsEqual<T extends BaseModel>(a: T, b: T): boolean {
        if (!a && !b) {
            return true
        } else if (a && b) {
            return a.getId() == b.getId()
        }
        return false
    }

    getStringId(): string {
        return this.deviceSlotSegmentId || this.deviceSlotSegment?.id
    }
}

export enum BrandPromoCampaignAttachmentType {
    MerchGuide = "MerchGuide",
    Agreement = "Agreement",
    StoreList = "StoreList"
}

export interface BrandPromoCampaignMutationInput {
    id: string
    name?: string
    promoPeriodId?: string
    brandPartnerId?: string
    promoProgramId?: string
    featuredProductIds?: string[]
    featuredBrandIds?: string[]
    contactUserIds?: string[]
    mediaFileVariantIds?: string[]
    slotAssignments?: BrandPromoCampaignSlotAssignmentInput[]
    attachments?: BrandPromoCampaignAttachmentInput[]
    isVisible?: boolean
    isPilot?: boolean
    campaignCostDollars?: number
    campaignDiscountDollars?: number
    submissionId?: string
    cprEnabled?: boolean
    executionReportingEnabled?: boolean
    cprRecipientsUserIds?: string[]
}

export interface BrandPromoCampaignAttachmentInput {
    attachmentType: BrandPromoCampaignAttachmentType
    fileKey: string
    fileName: string
}

export interface BrandPromoCampaignSlotAssignmentInput {
    deviceSlotSegmentId: string
    slotIndex: number
}

export interface BrandPromoCampaignAttachment {
    attachmentType: BrandPromoCampaignAttachmentType
    downloadUrl: string
    fileName: string
}

export interface BrandPromoCampaignUptimeStats {
    storesOpenedDuration: number
    allocatedPlaybackDuration: number
    totalPlaybackDuration: number
    uptimePercent: number
}

