import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersListComponent} from './components/users-list/users-list.component';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import {UserEditDialogComponent} from './components/user-edit-dialog/user-edit-dialog.component';
import {UserRoleListComponent} from './components/user-role-list/user-role-list.component';
import {UserRoleEditComponent} from './components/user-role-edit/user-role-edit.component';
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {MatTreeModule} from "@angular/material/tree";

@NgModule({
  declarations: [UsersListComponent, UserEditDialogComponent, UserRoleListComponent, UserRoleEditComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule,
        MatLegacyChipsModule,
        MatTreeModule
    ],
    entryComponents: [UserEditDialogComponent]
})
export class UsersModule { }
