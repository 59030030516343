<div app-looma-page pageTitle="Custom products" pageIcon="shopping_basket" [headerSideTpl]="headerSideTpl">
    <ng-container *ngIf="dataSource">
        <div style="overflow:auto">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="submission-date">
                    <th mat-header-cell *matHeaderCellDef>Submission date</th>
                    <td mat-cell *matCellDef="let element"> {{ element.submission.createdAt | date: 'MM/dd/yyyy' }}</td>
                </ng-container>

                <ng-container matColumnDef="parent-brand">
                    <th mat-header-cell *matHeaderCellDef> Parent Company</th>
                    <td mat-cell *matCellDef="let element"> {{ element.submission.submittedByBrand.name }}</td>
                </ng-container>

                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef> Brand</th>
                    <td mat-cell *matCellDef="let element">
                        <div>{{ element.submission.brandPartner.name }}</div>
                        <div *ngIf="!element.submission.brandPartner.uptimeBrand" style="color: red; font-weight: bold">
                            Custom brand
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="submitted-by">
                    <th mat-header-cell *matHeaderCellDef> Submitted By</th>
                    <td mat-cell *matCellDef="let element"> {{ element.submission.submittedByUser }}</td>
                </ng-container>

                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef> Product</th>
                    <td mat-cell *matCellDef="let element"> {{ element.name }}</td>
                </ng-container>

                <ng-container matColumnDef="upc">
                    <th mat-header-cell *matHeaderCellDef> UPC</th>
                    <td mat-cell *matCellDef="let element"> {{ element.upc }}</td>
                </ng-container>

                <ng-container matColumnDef="logo">
                    <th mat-header-cell *matHeaderCellDef> Image</th>
                    <td mat-cell *matCellDef="let element">
                        <img *ngIf="element.thumbUrl" [src]="element.thumbUrl" alt="Product Logo"
                             style="width: 70px; height: 70px; object-fit: contain" loading="lazy">
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button *ngIf="canCreateProducts(element)" mat-flat-button (click)="createProduct(element)">New
                            product
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-container>
</div>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button (click)="exportToCsv()" *ngIf="this.dataSource && this.dataSource.length > 0">
            Export
        </button>
        <button mat-raised-button (click)="onProductsClicked()">Products</button>
    </div>
</ng-template>

