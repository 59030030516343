import {Injectable} from "@angular/core";
import {SearchableObject, SearchFieldCriteria} from "@looma/shared/search";
import {Observable} from "rxjs";
import {NamedValue, SearchObject} from "@looma/shared/types/named_value";
import * as queries from "../../uptime-dashboard/src/app/services/queries";
import {Apollo} from "apollo-angular";
import {RemoteDataService} from "@looma/shared/services/remote_data_service";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {Retailer} from "@looma/shared/models/retailer";
import {map} from "rxjs/operators";
import gql from "graphql-tag";
import {BaseModel} from "@looma/shared/models/base_model";

@Injectable({
    providedIn: 'root'
})
export class SearchService extends RemoteDataService {

    constructor(apollo: Apollo) {
        super(apollo)
    }

    searchObject(kind: SearchableObject, criteria: SearchFieldCriteria[]): Observable<SearchObject[]> {
        const c = criteria.map((value: SearchFieldCriteria) => {
            return {field: value.field, operator: value.operator.key, value: value.value}
        });

        return this.apollo.query<Response>({
            query: queries.QUERY_SEARCH_OBJECT,
            variables: {kind: kind, criteria: c},
            fetchPolicy: 'no-cache'
        }).pipe(
            this.mapTypedQueryResponseArray(SearchObject, 'search_object')
        );
    }

    searchModels(kinds: SearchableObject[], criteria: SearchFieldCriteria[]): Observable<BaseModel[]> {
        const c = criteria.map((value: SearchFieldCriteria) => {
            return {field: value.field, operator: value.operator.key, value: value.value}
        });

        return this.apollo.query<Response>({
            query: searchModelsQuery,
            variables: {types: kinds, criteria: c},
            fetchPolicy: 'no-cache'
        }).pipe(
            map(value => {
                const models = value.data['searchModels'] as any[]
                if (Array.isArray(models)) {
                    return models.map(raw => {
                        const modelType = raw['__typename']
                        switch (modelType) {
                            case 'BrandPartner':
                                return new BrandPartner().assign(raw)
                            case 'Retailer':
                                return new Retailer().assign(raw)
                            default:
                                throw new Error(`can't handle model type ${modelType}`)
                        }
                    })
                }
            })
        )
    }
    
}

const searchModelsQuery = gql`
    query ($types: [SearchableObject!]!, $criteria:[SearchFieldCriteria!]!){
        searchModels(types:$types, criteria:$criteria){
            __typename
            ... on BrandPartner {
                name
                id
                parentBrand{
                    id
                    name
                }
            }
        }
    }
`