<div app-looma-page pageTitle="Command Results" pageIcon="settings_remote" >
    <looma-grid [dataSource]="commandResultsDataSource"  (onItemClick)="this.onItemClick($event)">

        <ng-template rowExpansionTemplate let-data="data">
            <mat-table  [dataSource]="loadDeviceResults(data) | async">
                <ng-container cdkColumnDef="deviceId">
                    <mat-header-cell *cdkHeaderCellDef> Device Id </mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                        <a [routerLink]="['/devices',row?.device?.device_id]" style="color: #006064;">{{row?.device?.device_id}}</a>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="status">
                    <mat-header-cell *cdkHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                        {{getStatusName(row?.status)}}
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="message">
                    <mat-header-cell *cdkHeaderCellDef> Message </mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                        {{row?.message}}
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="attachment">
                    <mat-header-cell *cdkHeaderCellDef> Attachment </mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                        <a *ngIf="row?.attachment?.preview_url" href="{{row?.attachment?.preview_url}}" target="_blank" style="color: #006064;">{{getFileName(row?.attachment?.preview_url)}}</a>
                        <span *ngIf="!row?.attachment?.preview_url">None</span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="['deviceId','status','message','attachment']"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: ['deviceId','status','message','attachment']"></mat-row>

            </mat-table>
        </ng-template>

    </looma-grid>
</div>
