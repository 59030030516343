import {Component, OnInit} from '@angular/core';
import {ModelDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {Observable} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {EditItemComponent, ModelListPageComponent} from "../../../../shared/model_list_page_component";
import {MatDialogConfig} from "@angular/material/dialog";
import {StoresEditDialogComponent} from "../stores-edit-dialog/stores-edit-dialog.component";
import {ButtonActions} from "../../../../shared/button_actions";
import {Store, StoreFeedFilter} from "@looma/shared/models/store";
import {Retailer} from "@looma/shared/models/retailer";
import {NamedValue} from "@looma/shared/types/named_value";
import {SearchableField, SearchFieldCriteria} from "@looma/shared/search";
import {CursorFilter} from '@looma/shared/types/cursor_filter';

@LifecycleHooks()
@Component({
    selector: 'app-stores-list',
    templateUrl: './stores-list.component.html',
    styleUrls: ['./stores-list.component.scss']
})
export class StoresListComponent extends ModelListPageComponent<Store, CursorFilter>
    implements OnInit,
        EditItemComponent<Store> {


    retailerRegionSearchCriteria: SearchFieldCriteria;
    selectedRetailerRegion: NamedValue;

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService
    ) {
        super(Store);
    }

    ngOnInit(): void {
    }

    get dataSource(): StoreDataSource {
        return super.dataSource as StoreDataSource;
    }

    initDataSource(): ModelDataSource<Store, CursorFilter> {
        return new StoreDataSource(this.svcApi);
    }

    canEditItem(item: Store): boolean {
        return true;
    }

    performEditItem(item: Store): Observable<Store> {
        const dialogConfig: MatDialogConfig = {
            width: '800px'
        }
        if (item) {
            dialogConfig.data = {
                store: item
            };
        }
        return this.svcLayout.openDialogForResult(StoresEditDialogComponent, dialogConfig);
    }

    addNewRetailer(): void {
        this.performMenuAction(ButtonActions.Edit, new Store())
    }

    searchForStore(ev: InputEvent): void {
        const term = (ev.target as HTMLInputElement).value;
        this.dataSource.applyFilter({
            retailer_store_num: term,
        }, 300)
    }

    onRetailerFilterChanged(data: NamedValue): void {
        const newFilter: Partial<StoreFeedFilter> = {
            retailer_id: null,
            retailer_region_id: null,
        }
        this.retailerRegionSearchCriteria = null;
        this.selectedRetailerRegion = null;
        if (data) {
            newFilter.retailer_id = data.value;
            this.retailerRegionSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, data.value);
        }

        this.dataSource.applyFilter(newFilter);
    }

    onRetailerRegionFilterChanged(data: NamedValue): void {
        this.selectedRetailerRegion = data;
        this.dataSource.applyFilter({retailer_region_id: data ? data.value : null})
    }
}

class StoreDataSource extends ModelDataSource<Store, StoreFeedFilter> {
    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'retailer',
                    label: 'Retailer',
                    valueReader: (item: Store) => {
                        return item.retailer.retailer_name;
                    }
                },
                {
                    key: 'region',
                    label: 'Retailer region',
                    valueReader: (item: Store) => {
                        return item.retailer_region.region_name;
                    }
                },
                {
                    key: 'store_num',
                    label: 'Retailer Store Num',
                    valueReader: (item: Store) => {
                        return item.getPaddedStoreNum();
                    }
                }, {
                    key: 'product_categories',
                    label: 'Product categories',
                    valueReader: (item: Store) => {
                        return item.product_categories.map(p => p.category_name).join(", ");
                    }
                }, {
                    key: 'street',
                    label: 'Street Address',
                    valueReader: (item: Store) => {
                        return item.location.street_address;
                    }
                }, {
                    key: 'city',
                    label: 'City',
                    valueReader: (item) => {
                        return item.location.city;
                    }
                }, {
                    key: 'state',
                    label: 'State',
                    valueReader: (item) => {
                        return item.location.state;
                    }
                }, {
                    key: 'country',
                    label: 'Country',
                    valueReader: (item) => {
                        return item.location.country_code;
                    }
                },
            ]
        });
    }

    loadData(dataFilter: StoreFeedFilter): Observable<CursorFeed<Store>> {
        return this.svcApi.getStoresFeed(dataFilter);
    }

}

