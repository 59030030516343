import {Component, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms"
import {BrandProduct, ProductInput} from "@looma/shared/models/brand_product"
import {NamedValue} from "@looma/shared/types/named_value"
import {MutationOperation} from "@looma/shared/models/mutation_operation"
import {ApiDataService} from "../../../services/api-data.service"
import {BehaviorSubject, Observable, of, Subject, Subscription, throwError} from "rxjs"
import {catchError, distinctUntilChanged, map, startWith, switchMap, takeUntil} from "rxjs/operators"
import {
    UploadedFileInfo,
    UploadFileInputComponent,
    UploadService,
    UploadSession
} from "@looma/shared/services/upload.service"
import firebase from 'firebase/compat/app'
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../services/toast-notification.service"
import {LayoutService} from "../../../services/layout.service"
import {LifecycleHooks} from "@looma/shared/lifecycle_utils"
import {GenericError, Utils} from "@looma/shared/utils";
import {doOnSubscribe} from "../../../rxjs_extensions";

@LifecycleHooks()
@Component({
    selector: 'app-product-edit-form',
    templateUrl: './product-edit-form.component.html',
    styleUrls: ['./product-edit-form.component.scss']
})
export class ProductEditFormComponent implements OnInit, OnDestroy {

    valuesValidSubject = new BehaviorSubject(false)

    @Input()
    set imageFile(imageFile: File) {
        const imageReqId = this._setImageReqId += 1
        this._imageFile = imageFile
        this.imageUrl = null

        if (imageFile) {
            const reader = new FileReader()

            reader.readAsDataURL(imageFile)

            reader.addEventListener('load', () => {
                if (imageReqId == this._setImageReqId) {
                    this.imageUrl = reader.result as string
                }
            })
        }

        this.refreshIsValid()
    }

    constructor(
        private fb: FormBuilder,
        private svcApi: ApiDataService,
        svcUpload: UploadService,
        private svcToastNotif: ToastNotificationService,
        private svcLayout: LayoutService
    ) {

        this.uploadSession = svcUpload.newUploadSession({
            fileTypes: ['image'],
            multiSelection: false
        })

        this.form = this.fb.group({
            name: new FormControl({value: this.product?.name, disabled: false}, [Validators.required]),
            upc: new FormControl({value: this.product?.upc_code, disabled: false}, [Validators.required]),
        })

        this.form.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.refreshIsValid()
        })

        if (!this.product) {
            this.product = new BrandProduct()
        }
    }

    @Input('product')
    set product(productData: BrandProduct | null | undefined) {
        let product: BrandProduct
        this.selectedBrandPartner = null
        this.selectedRetailers = []

        if (productData instanceof BrandProduct) {
            product = productData as BrandProduct
            if (product.brand_partner) {
                this.selectedBrandPartner = NamedValue.from(
                    product.brand_partner.id,
                    product.brand_partner.getDisplayName()
                )
            }

            this.selectedRetailers = product.retailers ? product.retailers.map(v => NamedValue.from(
                v.id,
                v.getDisplayName()
            )) : []
        }

        this._product = product || new BrandProduct()

        this.form.controls['name'].setValue(product?.name)
        this.form.controls['upc'].setValue(product?.upc_code)

    }

    get product() {
        return this._product
    }

    get isBusy() {
        return !Utils.isUnsubscribed(this.fileAddedSubscription, this.uploadSubscription, this.productUpsertSubscription)
    }

    private _product: BrandProduct

    @Input()
    brandPartnerEditable = true

    @Input()
    retailerEditable = true

    @Input()
    allowChangeImage = true

    private _setImageReqId = 1
    private _imageFile: File

    @ViewChild('uploadFileInput', {static: false}) uploadFileInputRef: UploadFileInputComponent;


    @Output()
    onProductSaved = new Subject<BrandProduct>()

    form: FormGroup
    selectedBrandPartner: NamedValue
    selectedRetailers: NamedValue[]
    productUpsertSubscription: Subscription
    uploadSubscription: Subscription
    fileAddedSubscription: Subscription

    isSavingData = false
    isUploadingImage = false
    uploadSession: UploadSession
    imageUrl: string

    _uploadedFiles = new Map<string, UploadFileResult>()

    onBrandPartnerSelected(value: NamedValue): void {
        this.selectedBrandPartner = value
        this.refreshIsValid()
    }

    onRetailerSelected(values: NamedValue[]) {
        this.selectedRetailers = values
        this.refreshIsValid()
    }


    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        Utils.unsubscribe(this.fileAddedSubscription)
        Utils.unsubscribe(this.uploadSubscription)
        Utils.unsubscribe(this.productUpsertSubscription)

        if (this.uploadSession) {
            this.uploadSession.destroy()
        }
    }

    handleLocalFileAdded(file: File) {
        this.imageFile = file
    }

    get isValid() {
        if (!this.form.valid) {
            return false
        }
        if (!this.selectedRetailers.length) {
            return false
        }
        if (!this.selectedBrandPartner) {
            return false
        }
        return true
    }

    refreshIsValid() {
        this.valuesValidSubject.next(this.isValid)
    }

    saveProduct(): Observable<ProductSaveResult> {
        if (!this.isValid) {
            return of({success: false, errorMessage: 'Not all fields are valid'})
        }

        const newRecord = this.product.isNewRecord()
        const data: ProductInput = {}
        if (!this.product.isNewRecord()) {
            data.id = this.product.id.toString()
        }

        data.name = this.form.get('name').value
        data.upcCode = this.form.get('upc').value
        data.brandPartnerId = this.selectedBrandPartner.getId()
        data.retailerIds = this.selectedRetailers.map(v => {
                return v.intValue()
            }
        )

        return this.uploadFileIfPresent(data, this._imageFile).pipe(
            switchMap(data => {
                const action = newRecord ? MutationOperation.Create : MutationOperation.Update
                return this.svcApi.upsertProduct(action, data)
            }),
            map(mutResponse => {
                if (mutResponse.success) {
                    return {
                        success: true,
                        product: mutResponse.data
                    }
                }
                return {
                    success: false,
                    errorMessage: mutResponse.message || 'Unexpected error'
                }
            }),
            catchError(err => {

                let msg = 'Api error'
                if (err instanceof GenericError) {
                    msg = err.message
                }

                return of({success: false, errorMessage: msg})
            }))

    }

    uploadFileIfPresent(productInput: ProductInput, file: File): Observable<ProductInput> {
        if (file) {
            return this.uploadFile(file).pipe(
                switchMap(uploadFileResult => {
                    if (!uploadFileResult.success) {
                        return throwError(() =>
                            new GenericError('Error uploading image')
                        )
                    }
                    productInput.imageFirebaseKey = uploadFileResult.firebaseKey
                    return of(productInput)
                })
            )
        }
        return of(productInput)
    }

    uploadFile(file: File): Observable<UploadFileResult> {
        const key = `${file.type}-${file.size}-${file.name}-${file.lastModified}`
        const prevData = this._uploadedFiles.get(key)
        if (prevData?.success) {
            return of(prevData)
        }

        return new Observable(subscriber => {

            let isUnsubscribed = false, notif: ToastNotification

            const sub = this.uploadSession.uploadFile(UploadedFileInfo.fromFile(file)).pipe(
                doOnSubscribe(() => {
                    notif = this.svcToastNotif.create({
                        title: "Product image upload",
                        description: 'Uploading image',
                        dismissable: false,
                        style: ToastNotificationStyle.Loading,
                        progress: -1,
                    })
                })
            ).subscribe({
                next: (uploadedFileInfo: UploadedFileInfo) => {
                    const storage = firebase.storage()

                    storage.ref(uploadedFileInfo.firebaseKey).getDownloadURL().then(url => {
                        const res = {
                            success: true,
                            file: file,
                            firebaseKey: uploadedFileInfo.firebaseKey,
                            downloadUrl: url
                        }

                        this._uploadedFiles.set(key, res)

                        if (!isUnsubscribed) {
                            subscriber.next(res)
                            subscriber.complete()
                        }
                    })
                },
                error: (error) => {
                    if (!isUnsubscribed) {
                        subscriber.next({success: false, file: file, error: error})
                        subscriber.complete()
                    }
                },
                complete: () => {
                    notif?.dismiss()
                }
            })

            return () => {
                isUnsubscribed = true
                sub.unsubscribe()
            }

        })

    }

    onValidChanged(): Observable<boolean> {
        return this.valuesValidSubject.pipe(
            startWith(this.isValid),
            distinctUntilChanged()
        )
    }

}


interface UploadFileResult {
    success: boolean,
    file?: File,
    error?: Error,
    firebaseKey?: string,
    downloadUrl?: string
}

export interface ProductSaveResult {
    success: boolean,
    product?: BrandProduct,
    errorMessage?: string
}
