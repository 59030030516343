import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataSourceEntry, GridDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {TableColumn} from "../../../../layout/components/looma-grid/table-column";
import {ApiDataService} from "../../../../services/api-data.service";
import {Observable} from "rxjs";
import {DeviceCommandBundleResult, DeviceCommandResult} from "@looma/shared/models/device_command_bundle_result";
import {getCommand} from "@looma/shared/types/device_commands";
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Utils} from '@looma/shared/utils';

@LifecycleHooks()
@Component({
    selector: 'app-command-results-list',
    templateUrl: './command-results-list.component.html',
    styleUrls: ['./command-results-list.component.scss']
})
export class CommandResultsListComponent implements OnInit, OnDestroy {

    @ViewChild('expansionRowTemplate', {static: true}) expansionRowTemplate: TemplateRef<any>;

    commandResultsDataSource: CommandResultDataSource;
    private deviceResultsObs: Map<string, Observable<DeviceCommandResult[]>> = new Map();

    constructor(private svcApi: ApiDataService, private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.commandResultsDataSource = new CommandResultDataSource(this.svcApi);
        this.commandResultsDataSource.setRowExpansionTemplate(this.expansionRowTemplate);
        this.commandResultsDataSource.expandRowId(this.activatedRoute.snapshot.queryParamMap.get('id'));

    }

    ngOnDestroy(): void {
    }

    onItemClick(item: CommandResultEntry): void{
    }

    loadDeviceResults(parent: CommandResultEntry): Observable<DeviceCommandResult[]>{
        if(!this.deviceResultsObs.has(parent.id)){
            const source = this.svcApi.getCommandResultsWithDevices(parent.id).pipe(
                takeUntil(Utils.onDestroy(this))
            );
            this.deviceResultsObs.set(parent.id, source)
        }
        return this.deviceResultsObs.get(parent.id)

    }

    getStatusName(status: any): string{
        return DeviceCommandBundleResult.getStatusName(status)
    }

    getFileName(url: string): string{
        let rawName = '';
        if(url){
            rawName = decodeURIComponent(url.split('/').pop());
            rawName = rawName.split('/').pop();
            const qsPos = rawName.indexOf('?');
            if(qsPos > 0){
                rawName = rawName.substr(0, qsPos)
            }

        }
        return rawName
    }

}


class CommandResultEntry extends DataSourceEntry<DeviceCommandBundleResult>{

    id: string;

    @TableColumn({label: 'Command Name', filterable: true, sortable: true})
    cmdName: string;

    @TableColumn({label: 'Command Args', filterable: true, sortable: true})
    cmdArgs: string;

    @TableColumn({label: 'Issued By', filterable: true, sortable: true})
    issuedByUser: string;

    @TableColumn({label: 'Issued At', filterable: true, sortable: true})
    issuedAt: string;

    @TableColumn({label: 'Expires At', filterable: true, sortable: true})
    expiresAt: string;

    @TableColumn({label: 'Status', filterable: true, sortable: true})
    statusName: string;

    @TableColumn({label: 'Target Devices', filterable: true, sortable: true, width:'120px'})
    targetDevicesCount: number;

    @TableColumn({label: 'Notified Devices', filterable: true, sortable: true, width:'120px'})
    notifiedDevicesCount: number;

    @TableColumn({label: 'Completed Devices', filterable: true, sortable: true, width:'130px'})
    completedDevicesCount: number;

    private data: DeviceCommandBundleResult;

    assign(data: DeviceCommandBundleResult): CommandResultEntry {
        this.data = data;
        this.id = data.id;

        const cmd = getCommand(data.cmd_name);
        if(cmd){
            this.cmdName = cmd.displayMessage;
        }else{
            this.cmdName = data.cmd_name;
        }

        this.cmdArgs = data.cmd_args;
        this.issuedByUser = data.user && data.user.display_name || 'unknown';
        this.issuedAt = data.created_at.toLocaleString();
        this.expiresAt = data.expires_at.toLocaleString();
        this.statusName = DeviceCommandBundleResult.getStatusName(data.status);
        this.targetDevicesCount = data.target_devices_count;
        this.notifiedDevicesCount = data.notified_devices_count;
        this.completedDevicesCount = data.completed_devices_count;
        return this
    }



    getId(): any {
        return this.id;
    }


    getData(): DeviceCommandBundleResult {
        return this.data
    }
}

class CommandResultDataSource extends GridDataSource<DeviceCommandBundleResult, CommandResultEntry>{

    private expandedRowId: string;

    constructor(private svcApi: ApiDataService){
        super(CommandResultEntry);
        this.setLoaderFactory(() => {
            return this.svcApi.watchCommandResults()
        })
    }


    readRecord(data: DeviceCommandBundleResult): CommandResultEntry {
        const entry = new CommandResultEntry().assign(data);
        if(entry.id === this.expandedRowId){
            this.setItemExpanded(entry, true);
        }
        return entry;
    }

    expandRowId(id: string): void{
        this.expandedRowId = id;
    }

}
