import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BrandPromoCampaignReport} from "@looma/shared/models/brand_promo_campaign_report";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-report-share-url',
  templateUrl: './report-share-url.component.html',
  styleUrls: ['./report-share-url.component.scss']
})
export class ReportShareUrlComponent {
    url: string
    reportName: string

    constructor(public dialogRef: MatDialogRef<ReportShareUrlComponent>,
                private snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: ShareComponentData) {
        this.url = data.report.cpr30Url()
        this.reportName = data.report.brandPromoCampaign.name
    }

    copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(() => {
            this.snackBar.open('Copied to clipboard!', '', { duration: 2000 });
        });
    }
}

export interface ShareComponentData {
    report: BrandPromoCampaignReport;
}
