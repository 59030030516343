import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    title: string;
    message: string;

    negativeButtonText = 'Cancel';
    positiveButtonText = 'Ok';

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ConfirmDialogData
    ) {

        this.title = data.title;
        this.message = data.message;
        this.negativeButtonText = data.negativeButtonText || 'Cancel';
        this.positiveButtonText = data.positiveButtonText || 'OK';
    }
    
    ngOnInit(): void {
    }
}

export interface ConfirmDialogData {
    title: string
    message: string
    negativeButtonText?: string
    positiveButtonText?: string
}
