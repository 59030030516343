import {BaseModel} from '@looma/shared/models/base_model';
import {Location, LocationInput} from '@looma/shared/models/location'
import {ProductCategory} from '@looma/shared/models/product_category'
import {WordFilterable} from '@looma/shared/types/word_filterable';
import {BrandPartnerProduct} from '@looma/shared/models/brand_partner_product'

export enum BrandPartnerType{
    ParentCompany = 'ParentCompany',
    Brand = 'Brand'
}

export class BrandPartner extends BaseModel implements WordFilterable{
    id: string;
    name: string;
    looma_id: string;
    product_categories: ProductCategory[];
    products: BrandPartnerProduct[];
    location: Location;
    updated_at: Date;
    logoUrl: string
    parentBrand: BrandPartner
    childBrands: BrandPartner[]
    type: BrandPartnerType
    isArchived: boolean

    assign(obj: any): this{
        super.assign(obj);

        this.assignTypedArray(obj, 'childBrands', BrandPartner);
        this.assignTypedObject(obj, 'parentBrand', BrandPartner);

        this.assignTypedArray(obj, 'product_categories', ProductCategory);
        this.assignTypedArray(obj, 'products', BrandPartnerProduct);
        this.assignTypedObject(obj, 'location', Location);

        this.assignDate(obj, 'updated_at')
        this.childBrands = this.childBrands || []
        for (const child of this.childBrands) {
            child.parentBrand = this
        }

        if (Array.isArray(obj.product_categories)) {
            this.product_categories = (obj.product_categories as any[]).map(value => new ProductCategory().assign(value))
        }
        return this;
    }

    getPhrases(): string[]{
        return [ this.looma_id, this.name ];
    }

    getDisplayName(): string{
        return this.name;
    }

    isNewRecord(): boolean{
        return !this.id
    }

    get isParentCompany(): boolean{
        return this.type == BrandPartnerType.ParentCompany
    }

    get isBrand(): boolean{
        return this.type == BrandPartnerType.Brand
    }

    get isLooma(): boolean{
        return this.name?.toLowerCase() == 'looma'
    }
}

export interface BrandPartnerInput{
    id?: string
    logoFirebaseKey?: string
    loomaId?: string
    productCategoryIds?: string[]
    location?: LocationInput
    name?: string
    websiteUrl?: string
    parentBrandID?: string
    isArchived?: boolean
}


export interface BrandPartnerSearchCriteria{
    id?: string
    query?: string
    querySelfAndChildren?: string
    productCategoryIds?: string[]
    parentBrandId?: string
    cursor?: string
    parentBrand?: boolean
    includeArchived?: boolean
}
