import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import {
    RetailerStoreGroupsListAssignmentComponent
} from "./components/retailer-store-groups-list-assignment/retailer-store-groups-list-assignment.component";
import {
    RetailerStoreGroupsListComponent
} from "./components/retailer-store-groups-list/retailer-store-groups-list.component";
import {StoreGroupsComponent} from "./components/store-groups/store-groups.component";
import { RetailerStoreGroupsMapAssignmentComponent } from './components/retailer-store-groups-map-assignment/retailer-store-groups-map-assignment.component';
import { RetailerStoreGroupsAssignmentComponent } from './components/retailer-store-groups-assignment/retailer-store-groups-assignment.component';

@NgModule({
    declarations: [
        RetailerStoreGroupsListAssignmentComponent,
        RetailerStoreGroupsListComponent,
        StoreGroupsComponent,
        RetailerStoreGroupsMapAssignmentComponent,
        RetailerStoreGroupsAssignmentComponent
    ],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule
    ]
})
export class StoreGroupsModule { }
