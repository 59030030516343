<div style="height:100%; overflow: scroll; background: #ccc">
    <app-aspect-ratio-grid [dataLoader]="mediaContentLoader" [itemTemplate]="tplItem" style="height: 100%; flex:1"
                           [columnsCount]="4" aspectRatio="16:9+50px"></app-aspect-ratio-grid>
</div>

<ng-template #tplItem let-data="data" let-index="index">
    <div class="film" [style.background-image]="getBackgroundThumbImage(data)">
        <div class="header">
            <div style="display: flex; flex: 1; align-items: center; justify-content: space-between">
                <div style="display:flex">
                    <div>
                        <div class="title">
                            <span style="color: darkorange" *ngIf="data.isDraft">Draft: </span>
                            <span>{{ data.displayName }}</span>
                        </div>
                        <div class="details">
                            <div *ngIf="data.brandPartner">{{ data.brandPartner.name }}</div>
                        </div>
                    </div>

                    <div style="padding-left: 10px" *ngIf="!data?.isVisible">
                        <mat-icon style="color: #d7d6d6">visibility_off</mat-icon>
                    </div>
                </div>

                <div style="float: right">
                    <button mat-icon-button [matMenuTriggerFor]="menu"
                            aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="edit(data)">
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="uploadNewVersion(data, loop)">
                            <mat-icon>upload</mat-icon>
                            <span>Upload new version</span>
                        </button>
                        <button mat-menu-item (click)="uploadNewVersion(data, clean)">
                            <mat-icon>upload</mat-icon>
                            <span>Upload clean version</span>
                        </button>
                        <button mat-menu-item (click)="showFilmVariables(data)">
                            <mat-icon>trending_up</mat-icon>
                            <span>Film variables</span>
                        </button>
                        <button mat-menu-item [matMenuTriggerFor]="downloadType">
                            <mat-icon>download</mat-icon>
                            <span>Download</span>
                        </button>
                        <button mat-menu-item (click)="delete(data)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>

                    <mat-menu #downloadType="matMenu">
                        <button mat-menu-item (click)="download(data,'original')">Original</button>
                        <button mat-menu-item (click)="download(data,'processed')">Processed</button>
                    </mat-menu>

                </div>
            </div>
        </div>
        <div style="height: 100%; cursor: pointer" (click)="edit(data)">
        </div>
    </div>
</ng-template>
