import {Component, OnInit} from '@angular/core';
import {ModelDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {Observable} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {EditItemComponent, ModelListPageComponent} from "../../../../shared/model_list_page_component";
import {Retailer, RetailerFeedFilter} from "@looma/shared/models/retailer";
import {MatDialogConfig} from "@angular/material/dialog";
import {RetailersEditDialogComponent} from "../retailers-edit-dialog/retailers-edit-dialog.component";
import {ButtonActions} from "../../../../shared/button_actions";
import {CursorFilter} from '@looma/shared/types/cursor_filter';

@LifecycleHooks()
@Component({
    selector: 'app-retailers-list',
    templateUrl: './retailers-list.component.html',
    styleUrls: ['./retailers-list.component.scss']
})
export class RetailersListComponent extends ModelListPageComponent<Retailer, CursorFilter>
    implements OnInit,
        EditItemComponent<Retailer> {

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService
    ) {
        super(Retailer);
    }

    ngOnInit(): void {
    }

    get dataSource(): RetailerDataSource {
        return super.dataSource as RetailerDataSource;
    }

    initDataSource(): ModelDataSource<Retailer, CursorFilter> {
        return new RetailerDataSource(this.svcApi);
    }

    canEditItem(item: Retailer): boolean {
        return true;
    }

    performEditItem(item: Retailer): Observable<Retailer> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {};
        dialogConfig.width = '800px';
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        if (item) {
            dialogConfig.data = {
                retailer: item
            };
        }
        return this.svcLayout.openDialogForResult(RetailersEditDialogComponent, dialogConfig)
    }

    addNewRetailer(): void {
        this.performMenuAction(ButtonActions.Edit, new Retailer())
    }
}

class RetailerDataSource extends ModelDataSource<Retailer, RetailerFeedFilter> {
    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'retailer_id',
                    label: 'Retailer ID',
                    valueReader: (item: Retailer) => {
                        return item.retailer_id;
                    }
                },
                {
                    key: 'name',
                    label: 'Name',
                    valueReader: (item: Retailer) => {
                        return item.retailer_name;
                    }
                },
                {
                    key: 'product_categories',
                    label: 'Product categories',
                    valueReader: (item: Retailer) => {
                        return item.product_categories.map(p => p.category_name).join(", ");
                    }
                }, {
                    key: 'city',
                    label: 'City',
                    valueReader: (item) => {
                        return item.location.city;
                    }
                }, {
                    key: 'state',
                    label: 'State',
                    valueReader: (item) => {
                        return item.location.state;
                    }
                }, {
                    key: 'country',
                    label: 'Country',
                    valueReader: (item) => {
                        return item.location.country_code;
                    }
                }, {
                    key: 'regions',
                    label: 'Regions',
                    valueReader: (item: Retailer) => {
                        return item.regions.map(p => p.region_name).join(", ");
                    }
                }
            ]
        });
    }

    loadData(dataFilter: RetailerFeedFilter): Observable<CursorFeed<Retailer>> {
        return this.svcApi.getRetailersFeed(dataFilter);
    }

}


