import {Component, Inject} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {UserAccessPermission, UserRole, UserRoleInput} from "@looma/shared/models/user_role";
import {MatSelectionListChange} from "@angular/material/list";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-user-role-edit',
    templateUrl: './user-role-edit.component.html',
    styleUrls: ['./user-role-edit.component.scss']
})
export class UserRoleEditComponent {

    userRole: UserRole;
    form: FormGroup;
    isSavingData: boolean;
    groupedPermissions: Map<string, UserAccessPermission[]>;
    permissionGroups: string[];
    selectedPermissions: string[] = [];

    constructor(private fb: FormBuilder,
                private dialogRef: MatDialogRef<UserRoleEditComponent>,
                private svcApi: ApiDataService,
                private svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) data) {


        this.svcApi.getUserAccessPermissions()
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(result => {
                this.groupedPermissions = Utils.groupBy(result, t => t.groupName);
                this.permissionGroups = Array.from(this.groupedPermissions.keys());
            })

        let userRoleData = {};
        if (data && data.hasOwnProperty('userRole')) {
            userRoleData = data.userRole || {};
            if (userRoleData instanceof UserRole) {
                this.userRole = userRoleData as UserRole;
            }
        } else {
            this.userRole = new UserRole();
        }

        if (!this.userRole.isNewRecord()) {
            this.selectedPermissions = this.userRole.permissions.map(t => t.key);
        }

        this.form = fb.group({
            name: new FormControl({value: this.userRole.name, disabled: false}, [Validators.required]),
            description: new FormControl({value: this.userRole.description, disabled: false}, [Validators.required]),
        });
    }

    save() {
        if (!this.form.valid) {
            return;
        }
        this.isSavingData = true;

        const newRecord = this.userRole.isNewRecord();
        const action = newRecord ? MutationOperation.Create : MutationOperation.Update;

        const data: UserRoleInput = {};
        if (!newRecord) {
            data.id = this.userRole.id.toString()
        }

        data.name = this.form.get('name').value;
        data.description = this.form.get('description').value;
        data.assignedPermissions = this.selectedPermissions;

        this.svcApi.upsertUserRole(action, data)
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(value => {
                if (value.success) {
                    this.dialogRef.close(value.data)
                } else {
                    this.svcLayout.showSnackMessage(value.message || 'Unexpected error');
                }
                this.isSavingData = false;
            }, error => {
                this.svcLayout.showSnackMessage('Api error');
                this.isSavingData = false;
            });
    }

    close(): void {
        this.dialogRef.close()
    }

    isSelected(permission: UserAccessPermission) {
        if (this.userRole.isNewRecord()) {
            return false;
        }
        return this.userRole.permissions.map(t => t.key).includes(permission.key);
    }

    onPermissionSelectionChanged(ev: MatSelectionListChange) {
        for (const opt of ev.options) {
            const permission: UserAccessPermission = opt.value;
            if (opt.selected) {
                this.selectedPermissions.push(permission.key)
            } else {
                const index = this.selectedPermissions.indexOf(permission.key, 0);
                if (index > -1) {
                    this.selectedPermissions.splice(index, 1);
                }
            }
        }
    }
}


