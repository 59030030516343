export enum DeviceUserRight {
    RestartKioskApp = "device.restart_kiosk_app",
    RestartAdminApp = "device.restart_admin_app",
    RecordAudio = "device.record_audio",
    GetLogs = "device.get_logs",
    Reboot = "device.reboot",
    DownloadContent = "device.download_content",
    Screenshot = "device.screenshot",
    ScreenVideo = "device.screen_video",
    CamPhoto = "device.cam_photo",
    ClearCache = "device.clear_cache",
    RestartDisplay = "device.restart_display",
    SetKioskMode = "device.set_kiosk_mode",
    SetVolume = "device.set_volume",
    SetAccelerometer = "device.set_accelerometer",
    SetPlaylist = "device.set_playlist",
    SetAssignation = "device.set_assignation",
    EditMetadata = "device.edit_metadata",
    UploadPhoto = "device.upload_photo",
    SetConfig = "device.set_config",
}
