import {Injectable} from '@angular/core';
import {ApiDataService} from './api-data.service';
import {NotificationConfig, ToastNotificationService, ToastNotificationStyle} from './toast-notification.service';
import {WebNotificationsService} from './web-notifications.service';
import {DeviceCommand} from '@looma/shared/types/device_commands';
import {
    DeviceCommandBundleResult,
    DeviceCommandResult,
    DeviceCommandStatus
} from '@looma/shared/models/device_command_bundle_result';
import {DeviceSearchCriteria} from '@looma/shared/models/device_search';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {Utils} from '@looma/shared/utils';
import {Router} from '@angular/router';
import {filter, map, takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DeviceCommandService {

    private notificationLoadSubs: Map<string, Subscription> = new Map();
    private commandCompletedPipe: Subject<DeviceCommandBundleResult> = new Subject();

    constructor(
        private svcApi: ApiDataService,
        private svcToastNotif: ToastNotificationService,
        private svcWebNotif: WebNotificationsService,
        private router: Router
    ) {

        this.svcWebNotif.onNotification('command_updated').subscribe(value => {
            this.handleNotificationForCommand(value.data['id'])
        })

    }

    onDeviceCommandCompleted(deviceId: string, ...cmds: DeviceCommand[]): Observable<DeviceCommandBundleResult> {
        const dId = parseInt(deviceId);
        const cmdKeys = cmds.map(value => value.key);
        return this.commandCompletedPipe.pipe(
            filter((cmdRes: DeviceCommandBundleResult) => {
                if (cmdKeys.length && (cmdKeys.indexOf(cmdRes.cmd_name) < 0)) {
                    return false
                }
                return cmdRes.isDone && cmdRes.target_device_ids && (cmdRes.target_device_ids.indexOf(dId) >= 0)
            })
        )
    }

    sendCommand(command: DeviceCommand, criteria: DeviceSearchCriteria, commandArg?: string): void {
        this.svcApi.sendCommand(command, criteria, commandArg)
            .subscribe(cmd => {
                this.watchDeviceCommand(cmd, 10_000)
            })
    }

    sendDeviceCommand(deviceId: any, command: DeviceCommand, commandArg?: string): void {
        this.sendCommand(command, DeviceSearchCriteria.forDeviceId(deviceId + ''), commandArg)
    }

    private watchDeviceCommand(cmd: DeviceCommandBundleResult, makeDismissableIn: number=-1): void {
        const notif = this.svcToastNotif.create({
            id: this.getNotificationId(cmd),
            dismissable: false,
            style: ToastNotificationStyle.Loading,
            title: cmd.displayName,
            description: 'Target devices ' + cmd.target_devices_count
        });

        if (makeDismissableIn > 0) {
            timer(makeDismissableIn).pipe(
                takeUntil(notif.onDismissed())
            ).subscribe(value => {
                notif.update({
                    dismissable: true
                })
            })
        }
    }

    private getNotificationId(cmd: DeviceCommandBundleResult | string): string {
        if (cmd['id']) {
            cmd = cmd['id']
        }
        return `command-notif-${cmd}`
    }

    private handleNotificationForCommand(cmdId: string): void {
        if (cmdId) {
            const notifId = this.getNotificationId(cmdId);
            const notif = this.svcToastNotif.getNotification(notifId);
            if (notif && notif.style === ToastNotificationStyle.Loading) {
                // don't update notifications that are not in the loading state
                Utils.unsubscribe(this.notificationLoadSubs.get(cmdId));
                const sub = this.svcApi.getCommandResult(cmdId).pipe(
                    map(value => value[0])
                ).subscribe(this.updateCommandNotification.bind(this));
                this.notificationLoadSubs.set(cmdId, sub);
            }
        }
    }

    private updateCommandNotification(cmd: DeviceCommandBundleResult): void {
        const updates: NotificationConfig = {
            id: this.getNotificationId(cmd.id)
        };

        switch (cmd.status) {
            case DeviceCommandStatus.COMPLETED:
                updates.style = ToastNotificationStyle.Info;
                break;
            case DeviceCommandStatus.SUCCESSS:
                updates.style = ToastNotificationStyle.Success;
                break;
            case DeviceCommandStatus.FAILURE:
                updates.style = ToastNotificationStyle.Error;
                break;
        }

        if (cmd.message && cmd.message !== '') {
            updates.description = cmd.message
        }

        if (cmd.target_devices_count === 1) {
            if (Array.isArray(cmd.device_results)) {
                const deviceResult: DeviceCommandResult = cmd.device_results[0];
                switch (deviceResult.status) {
                    case DeviceCommandStatus.SUCCESSS:
                        updates.style = ToastNotificationStyle.Success;
                        break;
                    case DeviceCommandStatus.FAILURE:
                        updates.style = ToastNotificationStyle.Error;
                        break;
                }
                if (deviceResult.message && deviceResult.message !== '') {
                    updates.description = deviceResult.message
                }
                if (deviceResult.attachment) {
                    updates.actions = updates.actions || [];
                    updates.actions.push({
                        id: 'download', text: 'Download', handler: () => {
                            window.open(deviceResult.attachment.download_url)
                        }
                    })
                }
            }
        } else {
            updates.description = `
Target Count ${cmd.target_devices_count} <br/> 
Notified Count ${cmd.notified_devices_count} <br/>
Running Count ${cmd.payload_sent_devices_count} <br/>
Completed Count ${cmd.completed_devices_count}
`;

            updates.actions = updates.actions || [];
            updates.actions.push({
                id: 'details', text: 'Details', handler: () => {
                    this.router.navigate(['/command-results'], {queryParams: {id: cmd.id}});
                }
            })
        }

        switch (updates.style) {
            case ToastNotificationStyle.Info:
            case ToastNotificationStyle.Success:
            case ToastNotificationStyle.Error:
                updates.description = updates.description || 'Command completed';
                updates.dismissable = true;
                this.commandCompletedPipe.next(cmd);
                break;
        }
        this.svcToastNotif.update(updates);
    }

}
