<div app-looma-page pageTitle="Brands" pageIcon="stars" [headerSideTpl]="headerSideTpl">
    <looma-grid [dataSource]="dataSource" [rowActions]="brandActions" >
        <ng-template gridColumn="logo" let-brand="data">
            <img class="logo_preview" [src]="brand.logoUrl" *ngIf="brand.logoUrl">
            <img class="logo_preview empty" *ngIf="!brand.logoUrl">
        </ng-template>

        <ng-template rowExpansionTemplate let-brand="data">
            <looma-grid *ngIf="brand" [dataSource]="dataSource.getChildBrandsDatasource(brand)" [scrollContent]="false" [showHeaders]="false"
                        [rowActions]="brandActions">
                <ng-template gridColumn="logo" let-brand="data">
                    <img class="logo_preview" [src]="brand.logoUrl" *ngIf="brand.logoUrl">
                    <img class="logo_preview empty" *ngIf="!brand.logoUrl">
                </ng-template>

            </looma-grid>
        </ng-template>

    </looma-grid>
</div>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <div style="width: 400px">
            <app-brands-search-input (onSearch)="searchByText($event)" (onBrandSelected)="handleBrandSelected($event)"></app-brands-search-input>    
        </div>

        <mat-form-field style="width: 200px; " *ngIf="categories?.length > 0">
            <mat-label>Product categories</mat-label>
            <mat-select multiple (selectionChange)="doSearchCategories($event)">
                <mat-option *ngFor="let opt of categories" value="{{opt.id}}">{{opt.category_name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-checkbox
            (change)="setShowArchivedBrands($event.checked)">
            <span style="color: white">Show Archived</span>
        </mat-checkbox>
        
        <button mat-raised-button [matMenuTriggerFor]="addBrandButtonMenu">New</button>
        <mat-menu #addBrandButtonMenu="matMenu">
            <button mat-menu-item (click)="addBrandPartner(BrandPartnerType.Brand)">Brand</button>
            <button mat-menu-item (click)="addBrandPartner(BrandPartnerType.ParentCompany)">Parent Company</button>
        </mat-menu>

        <button mat-raised-button (click)="onCustomBrandsClicked()">Custom brands</button>
    </div>
</ng-template>
