import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {DeviceSlotImage, DeviceSlotImageMutationInput} from "@looma/shared/models/device_slot_image";
import {NamedValue} from "@looma/shared/types/named_value";
import {DeviceSlotImageScore} from "@looma/shared/models/device_slot_image_score";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {Observable, Subscription} from "rxjs";
import {Utils} from "@looma/shared/utils";
import {takeUntil} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {LayoutService} from "../../../../services/layout.service";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-edit-device-slot-image',
    templateUrl: './edit-device-slot-image-dialog.component.html',
    styleUrls: ['./edit-device-slot-image-dialog.component.scss']
})
export class EditDeviceSlotImageDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    deviceSlotImage: DeviceSlotImage;

    scores: DeviceSlotImageScore[] = [];

    private scoresSub: Subscription;

    hasZeroScoreSubmission = false
    approveZeroScores = false
    deviceSlotAssignmentChanged = false

    assignedDeviceSlotId: string

    get isBusy(): boolean {
        return !!(this.scoresSub && !this.scoresSub.closed)
    }

    static open(svcLayout: LayoutService, deviceSlotImage: DeviceSlotImage): Observable<DeviceSlotImage> {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;

        dialogConfig.data = deviceSlotImage;
        dialogConfig.width = '600px';

        return svcLayout.openDialogForResult(EditDeviceSlotImageDialogComponent, dialogConfig)
    }

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<EditDeviceSlotImageDialogComponent>,
        private svcApi: ApiDataService,
        private snackBar: MatSnackBar,
        private svcLayout: LayoutService,
        @Inject(MAT_DIALOG_DATA) data) {

        this.deviceSlotImage = data as DeviceSlotImage;

        const hasZeroScores = this.deviceSlotImage?.lastScoreSubmission?.hasZeroScores;
        if (Utils.isBoolean(hasZeroScores)) {
            this.hasZeroScoreSubmission = hasZeroScores
        }

        this.assignedDeviceSlotId = this.deviceSlotImage?.deviceSlot?.id


    }

    ngOnInit(): void {
        this.svcApi.getScoresAndBrandsForImage(
            this.deviceSlotImage.id,
        ).subscribe(value => {
            this.scores = value
        })

    }


    ngOnDestroy(): void {
    }

    save(): void {

        const data: Partial<DeviceSlotImageMutationInput> = {
            id: this.deviceSlotImage?.id,
            deviceSlotId: this.assignedDeviceSlotId,
        }

        if (data.deviceSlotId != this.deviceSlotImage.deviceSlot.id) {
            this.svcLayout.confirm('Confirm Reassignment', `Please confirm that you want to reassign this image`).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                if(value){
                    data.resendToScore = true;
                    this.submitUpdate(data);
                }
            })
        } else {
            if (this.approveZeroScores && this.hasZeroScoreSubmission) {
                data.approveZeroScores = true
            }
            this.submitUpdate(data)
        }

    }

    private submitUpdate(data?: Partial<DeviceSlotImageMutationInput>) {
        if (!data) {
            data = {}
        }
        data.id = this.deviceSlotImage.id;
        data.date = Utils.formatShortDate(this.deviceSlotImage.date);
        // data.approved = this.deviceSlotImage.approved;
        this.svcApi.mutateDeviceSlotImage(MutationOperation.Update, data).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.dialogRef.close(value.data);
            } else {
                this.snackBar.open(value.message, null, {duration: 5000})
            }
        })
    }

    close(): void {
        this.dialogRef.close()
    }

    onDateSelected(event: MatDatepickerInputEvent<any>): void {
        this.deviceSlotImage.date = event.value as Date;
    }

    ngAfterViewInit(): void {

    }

    onDeviceSlotChanged(ev: NamedValue) {
        this.assignedDeviceSlotId = ev ? ev.stringValue() : null;
        if (this.assignedDeviceSlotId != this.deviceSlotImage.deviceSlot.id) {
            this.deviceSlotAssignmentChanged = true
        }
    }

}
