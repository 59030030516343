<div app-looma-page pageTitle="Edit Device" pageIcon="settings_remote" (onBackPressed)="goBack()" >

    <div class="m-32" *ngIf="deviceAssignation.isEditable">
        <div>
            <mat-form-field style="width: 200px;" class="mr-16">
                <mat-select [disabled]="!canEdit('retailer')" (selectionChange)="onAssignationChanged('retailer',$event)" placeholder="Retailer" [value]="deviceAssignation.retailer" [compareWith]="itemsEqual">
                    <mat-option *ngFor="let retailer of retailersForAssign" [value]="retailer">
                        {{retailer.retailer_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 200px;" class="mr-16">
                <mat-select [disabled]="!canEdit('region')" (selectionChange)="onAssignationChanged('region',$event)" placeholder="Region" [value]="deviceAssignation.region" [compareWith]="itemsEqual">
                    <mat-option *ngFor="let region of deviceAssignation.retailer?.regions || []" [value]="region">
                        {{region.region_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 200px;" class="mr-16">
                <mat-select [disabled]="!canEdit('product_category')" (selectionChange)="onAssignationChanged('product_category',$event)" placeholder="Product Category" [value]="deviceAssignation.productCategory" [compareWith]="itemsEqual">
                    <mat-option *ngFor="let category of deviceAssignation.retailer?.product_categories || []" [value]="category">
                        {{category.category_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field style="width: 200px;" class="mr-16">
                <mat-select [disabled]="!canEdit('store')" (selectionChange)="onAssignationChanged('store',$event)" placeholder="Store" [value]="deviceAssignation.store" [compareWith]="itemsEqual">
                    <mat-option *ngFor="let store of deviceAssignation.region?.stores || []" [value]="store">
                        {{store.retailer_store_num}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 200px;" class="mr-16">
                <input [disabled]="!canEdit('device_number')" matInput placeholder="Device Number" [(ngModel)]="deviceAssignation.deviceNumber">
            </mat-form-field>

        </div>

        <div>
            <mat-form-field  class="mr-16">
                <input matInput placeholder="TeamViewerId ID" [(ngModel)]="deviceAssignation.device.teamviewer_id" >
            </mat-form-field>
        </div>

        <div *ngIf="deviceAssignation.device.assignation">

            <div>
                <mat-form-field  class="mr-16">
                    <input matInput placeholder="Looma Phase" [(ngModel)]="deviceAssignation.device.assignation.looma_phase"/>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field  class="mr-16">
                    <input matInput placeholder="Mount Type" [(ngModel)]="deviceAssignation.device.assignation.mount">
                </mat-form-field>

                <mat-form-field  class="mr-16">
                    <input matInput placeholder="Fixture Type" [(ngModel)]="deviceAssignation.device.assignation.loop_placement">
                </mat-form-field>
            </div>

        </div>

        <button mat-raised-button color="primary" (click)="saveDevice()">Save</button>
    </div>
</div>


