import {BaseModel} from "@looma/shared/models/base_model";
import {DeviceSlotImage} from "@looma/shared/models/device_slot_image";
import {User} from '@looma/shared/models/user';


enum SubmissionStatus {
    Valid = 0,
    ZeroScores = 1,
    FixtureMismatch = 2,
    PoorQuality = 3
}

export class DeviceSlotImageScoreSubmission extends BaseModel {
    id: string;
    deviceSlotImage: DeviceSlotImage;
    isValid: boolean;
    imageHasCorrectOrientation: boolean
    tabletInstallationWarnings: DeviceSlotImageScoreSubmissionTabletInstallationWarning[];
    validationStatus: number
    submittedByUser: User
    private _hasInstallationWarnings = false

    get imageHasGoodQuality(): boolean {
        return this.validationStatus != SubmissionStatus.PoorQuality
    }

    get hasZeroScores(): boolean {
        return this.validationStatus == SubmissionStatus.ZeroScores
    }

    assign(input: any): this {
        delete this.tabletInstallationWarnings
        super.assign(input);
        this.assignTypedObject(input, 'deviceSlotImage', DeviceSlotImage);
        this.assignTypedObject(input, 'submittedByUser', User);

        if (Array.isArray(this.tabletInstallationWarnings)) {
            for (const warn of this.tabletInstallationWarnings) {
                if (!warn.resolved) {
                    this._hasInstallationWarnings = true;
                    break
                }
            }
        }

        return this
    }

    get hasTabletInstallationWarnings(): boolean {
        return this._hasInstallationWarnings;
    }
}

export interface DeviceSlotImageScoreSubmissionTabletInstallationWarning {
    key: string
    label: string
    description: string
    resolved: boolean
}
