<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>

    <mat-form-field style="display: block">
        <input matInput autocomplete="off"
               [(ngModel)]="promoSchedule.name"
               placeholder="Name"/>

    </mat-form-field>

    <mat-form-field style="display: block">
        <input type="number" matInput autocomplete="off"
               [(ngModel)]="promoSchedule.defaultPromoPeriodDurationDays"
               placeholder="Default Promo Period Duration (days)"/>

    </mat-form-field>

    <app-autocomplete-search-field placeholder="Retailer" selectionMode="single" [value]="promoSchedule.retailer" [disabled]="!promoSchedule.isNewRecord()" searchObjectType="Retailer" (valueChanged)="onRetailerSelected($event)"></app-autocomplete-search-field>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="isBusy || !canSave()" (click)="handleAction('ok')">Ok</button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>