import {Component, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {LayoutService} from "../../../services/layout.service";
import {MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {Utils} from "@looma/shared/utils";
import {Subscription} from "rxjs";
import {UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {ProductEditFormComponent} from "../product-edit-form/product-edit-form.component";
import {takeUntil} from "rxjs/operators";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {BrandProduct} from "@looma/shared/models/brand_product";

@LifecycleHooks()
@Component({
    selector: 'app-bulk-product-upload-dialog',
    templateUrl: './bulk-product-upload-dialog.component.html',
    styleUrls: ['./bulk-product-upload-dialog.component.scss']
})
export class BulkProductUploadDialogComponent {
    form: FormGroup
    imageUrl: string

    uploadSession: UploadSession
    queuedFiles: File[] = []
    activeImageFile: File = null
    private _activeFormComponent: ProductEditFormComponent
    isValid = false
    private saveSub: Subscription
    product = new BrandProduct()


    get isBusy(): boolean {
        return !Utils.isUnsubscribed(this.saveSub)
    }

    static open(svcLayout: LayoutService) {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '800px',
            data: {},
        }

        return svcLayout.openDialogForResult(BulkProductUploadDialogComponent, dialogConfig).subscribe()
    }

    constructor(
        private svcUpload: UploadService,
        private svcLayout: LayoutService,
        private dialogRef: MatDialogRef<BulkProductUploadDialogComponent>,
    ) {
        this.uploadSession = svcUpload.getUploadSession('product_image', {
            fileTypes: ['image'],
            multiSelection: false
        });
    }

    @ViewChild(ProductEditFormComponent, {static: false}) set activeFormComponent(v) {
        this._activeFormComponent = v
        this.isValid = false

        if (v) {
            v.onValidChanged().pipe(
                takeUntil(Utils.onDestroy(v))
            ).subscribe(value => {
                this.isValid = value
            })
        }
    }


    onFilesDropped(newFiles: File[]) {
        this.addFiles(newFiles)
    }


    onLocalFilesAdded(ev) {
        const newFiles = Array.prototype.slice.call(ev.target.files)
        this.addFiles(newFiles)
    }

    private addFiles(newFiles: File[]) {

        newFiles = newFiles.filter(f => f.type.startsWith('image'))

        if (newFiles.length) {

            this.queuedFiles = [
                ...this.queuedFiles,
                ...newFiles
            ]
            if (!this.activeImageFile) {
                this.activeImageFile = this.queuedFiles[0]
            }
        }
    }

    hasImageAt(delta: number): boolean {
        return !!this.getQueuedFileAt(delta)
    }

    showImageAt(delta: number) {
        this.activeImageFile = this.getQueuedFileAt(delta)
        this.product = new BrandProduct()
    }

    getQueuedFileAt(delta: number): File {
        const currentIndex = this.queuedFiles.indexOf(this.activeImageFile)
        if (currentIndex < 0) {
            return null
        }
        const nextIndex = currentIndex + delta
        if (nextIndex >= 0 && nextIndex < this.queuedFiles.length) {
            return this.queuedFiles[nextIndex]
        }
        return null
    }

    closeDialog() {
        this.dialogRef.close()
    }

    saveCurrentImage() {
        this.saveSub = this._activeFormComponent.saveProduct().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.svcLayout.showMessage(`Product ${value.product.name} saved`)
                this.moveToNext()
            } else {
                this.svcLayout.showMessage(value.errorMessage || `Error saving product`)
            }
        })
    }

    removeCurrentImage() {
        this.svcLayout.onConfirmed('Remove image', 'Are you sure you want to remove this image?').pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.moveToNext()
        })
    }

    private moveToNext() {
        const currentIndex = this.queuedFiles.indexOf(this.activeImageFile)
        if (currentIndex < 0) {
            return
        }
        this.queuedFiles.splice(currentIndex, 1)
        this.queuedFiles = [
            ...this.queuedFiles
        ]
        this.activeImageFile = this.queuedFiles[currentIndex] || this.queuedFiles[currentIndex - 1] || null
        this.product = new BrandProduct()
    }
}
