import {Rect} from "@looma/shared/utils";
import {TextStyleAttrs} from "./types";
import {Retailer} from "@looma/shared/models/retailer";

export interface ImageTemplateData {
    brandingImageUrl: string,
    logo: {
        rect: Rect,
    },
    title: {
        rect: Rect,
        style: TextStyleAttrs,
    }
}

export interface ImageTemplateVariables {
    backgroundImageUrl?: string,
    logo?: {
        url?: string,
        scale?: number
    }
    title?: {
        text?: string,
        style?: TextStyleAttrs,
    }
}

export function getTemplateDataForRetailer(retailerId: string) {
    switch (retailerId.toString()) {
        case Retailer.LoomaId.HEB:
        case Retailer.ID.HEB:
            return HEB_TEMPLATE_DATA
        case Retailer.LoomaId.HT:
        case Retailer.ID.HT:
            return HT_TEMPLATE_DATA
        case Retailer.LoomaId.KRO:
        case Retailer.ID.KRO:
            return KRO_TEMPLATE_DATA
        case Retailer.LoomaId.LFS:
        case Retailer.ID.LFS:
            return LFS_TEMPLATE_DATA
        case Retailer.LoomaId.SCH:
        case Retailer.ID.SCH:
            return SCH_TEMPLATE_DATA
        default:
            throw new Error("don't have template for retailer")
    }
}

const HEB_TEMPLATE_DATA: ImageTemplateData = {
    brandingImageUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Fbackgrounds%2FHEB.png?alt=media',
    logo: {
        rect: {
            left: 180,
            top: 0,
            width: 476,
            height: 432
        },
    },
    title: {
        rect: {
            left: 103,
            top: 581,
            width: 635,
            height: 421
        },
        style: {
            fontUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Ffonts%2Frockwell-condensed.woff2?alt=media',
            color: 'white',
            fontSize: 80
        },
    },

}

const HT_TEMPLATE_DATA: ImageTemplateData = {
    brandingImageUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Fbackgrounds%2FHT.png?alt=media',
    logo: {
        rect: {
            left: 180,
            top: 0,
            width: 480,
            height: 522
        },
    },
    title: {
        rect: {
            left: 119,
            top: 537,
            width: 602,
            height: 439
        },
        style: {
            fontUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Ffonts%2FJester.ttf?alt=media',
            color: 'white',
            fontSize: 80
        },
    },

}

const KRO_TEMPLATE_DATA: ImageTemplateData = {
    brandingImageUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Fbackgrounds%2FKRO.png?alt=media',
    logo: {
        rect: {
            left: 190,
            top: 0,
            width: 454,
            height: 407
        },
    },
    title: {
        rect: {
            left: 125,
            top: 410,
            width: 584,
            height: 563
        },
        style: {
            fontUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Ffonts%2FGothamRnd-Book.otf?alt=media',
            color: 'white',
            fontSize: 80
        },
    },

}

const LFS_TEMPLATE_DATA: ImageTemplateData = {
    brandingImageUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Fbackgrounds%2FLFS.png?alt=media',
    logo: {
        rect: {
            left: 180,
            top: 0,
            width: 480,
            height: 497
        },
    },
    title: {
        rect: {
            left: 117,
            top: 542,
            width: 604,
            height: 439
        },
        style: {
            fontUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Ffonts%2Fbebas-neue.woff2?alt=media',
            color: 'white',
            fontSize: 80
        },
    },

}

const SCH_TEMPLATE_DATA: ImageTemplateData = {
    brandingImageUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Fbackgrounds%2FSCH.png?alt=media',
    logo: {
        rect: {
            left: 180,
            top: 0,
            width: 480,
            height: 497
        },
    },
    title: {
        rect: {
            left: 100,
            top: 540,
            width: 636,
            height: 461
        },
        style: {
            fontUrl: 'https://firebasestorage.googleapis.com/v0/b/looma-dsn-v3.appspot.com/o/splash-builder%2Ffonts%2FHansief.otf?alt=media',
            color: 'white',
            fontSize: 80
        },
    },

}
