import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DD_ACCEPT_ANY} from "../../../../layout/components/device-slots-assignment/device-slots-assignment.component";
import {
    ItemCollection,
    ItemGridController,
    ItemGridDataSource
} from "../../../../layout/components/device-slot-assignment-list/device_slot_assignment_controller";
import {Store} from "@looma/shared/models/store";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {StoreGroup, StoreGroupMutationInput} from "@looma/shared/models/store_group";
import {LayoutService} from "../../../../services/layout.service";
import {Retailer} from "@looma/shared/models/retailer";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {StoreGroupAssignmentService} from "../../services/store-group-assignment.service";

@LifecycleHooks()
@Component({
    selector: 'app-retailer-store-groups-list-assignment',
    templateUrl: './retailer-store-groups-list-assignment.component.html',
    styleUrls: ['./retailer-store-groups-list-assignment.component.scss']
})
export class RetailerStoreGroupsListAssignmentComponent implements OnInit {
    controller = new StoreGridController()
    retailer: Retailer
    pageTitle = "Store Groups"

    deletedCollections: ItemCollection<Store>[] = []
    groupIds = new Map<string, number>()

    private saveSub: Subscription

    constructor(
        private changeDetector: ChangeDetectorRef,
        private svcLayout: LayoutService,
        private route: ActivatedRoute,
        public svcStoreGroups: StoreGroupAssignmentService
    ) {
    }

    get isBusy() {
        return !Utils.isUnsubscribed(this.saveSub)
    }

    ngOnInit(): void {
        this.svcStoreGroups.getRetailerData(
            this.route.snapshot.paramMap.get('retailer_id')
        ).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.setRetailer(value)
        })
    }

    setRetailer(retailer: Retailer) {
        this.retailer = retailer
        this.setGroups(retailer.storeGroups)
    }

    setGroups(groups: StoreGroup[]) {
        this.groupIds.clear()
        this.deletedCollections = []

        let defaultCollection: ItemCollection<Store>
        const otherCollections: ItemCollection<Store>[] = []
        for (const group of groups) {
            const groupKey = `group-${group.id}`
            if (parseInt(group.id) < 0) {
                defaultCollection = {
                    key: groupKey,
                    items: group.stores,
                    label: group.name,
                }
            } else {
                this.groupIds.set(groupKey, group.getId())
                otherCollections.push({
                    key: groupKey,
                    items: group.stores,
                    label: group.name,
                })
            }
        }
        this.controller.setup(this.changeDetector, defaultCollection, otherCollections)
    }

    addNewStoreGroup() {
        this.svcLayout.prompt("New Store Group", "Group name").pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.addStoreGroup(value)
        })
    }

    private addStoreGroup(groupName) {
        this.controller.addCollection({
            key: `group-${Date.now()}`,
            label: groupName,
            items: [],
            isNewCollection: true,
            acceptedItemGroups: DD_ACCEPT_ANY
        })
    }

    isDefaultDataSource(ds: ItemGridDataSource<Store>) {
        return ds?.dataSourceKey == this.controller?.defaultItemDataSource?.dataSourceKey
    }

    deleteDataSource(ds: ItemGridDataSource<Store>) {
        this.controller.removeCollection(ds.collection)
        if (!ds.collection.isNewCollection) {
            this.deletedCollections.push(ds.collection)
        }
    }

    saveChanges() {
        if (this.isBusy) {
            return
        }
        const data: StoreGroupMutationInput[] = []
        for (const col of this.controller.getCollections()) {
            const groupId = this.groupIds.get(col.key)
            if (groupId > 0 || col.isNewCollection) {
                const op = col.isNewCollection ? MutationOperation.Create : MutationOperation.Update
                data.push({
                    op: op,
                    id: groupId,
                    name: col.label,
                    storeIds: col.items.map(value => value.getId())
                })
            }
        }
        for (const col of this.deletedCollections) {
            data.push({
                id: this.groupIds.get(col.key),
                op: MutationOperation.Delete,
                name: col.label,
                storeIds: col.items.map(value => value.getId())
            })
        }
        
        this.saveSub = this.svcStoreGroups.saveChanges(data).subscribe(value => {
            if (!value.success) {
                this.svcLayout.showMutationResultMessage(value)
            } else {
                this.svcLayout.showMessage("Saved")
                this.setRetailer(value.data)
            }
        })

    }
}

export class StoreGridController extends ItemGridController<Store> {
    initDataSource(collection: ItemCollection<Store>, isDefault: boolean): ItemGridDataSource<Store> {
        if (isDefault) {
            return new StoreDataSource(this, {
                label: collection.label,
                key: collection.key,
                items: [].concat(collection.items),
                acceptedItemGroups: DD_ACCEPT_ANY
            });
        }
        return new StoreDataSource(this, collection)
    }
}

class StoreDataSource extends ItemGridDataSource<Store> {
    constructor(controller: ItemGridController<Store>, collection: ItemCollection<Store>) {
        super(controller, collection, [{
            key: "name",
            label: "Name",
            valueReader: value => value.getDisplayName()
        }]);
    }
}
