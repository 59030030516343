import {BaseModel} from "@looma/shared/models/base_model";
import {PromoPeriodDeviceSlotSubgroup} from "@looma/shared/models/promo_period_device_slot_subgroup";
import {PromoPlaylistAssignment} from "@looma/shared/models/promo_playlist_assignment";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

export class PromoPlaylistScheduleEntry extends BaseModel {
    id: string
    startTime: Date
    endTime: Date
    deviceSlotTypeSubgroup: PromoPeriodDeviceSlotSubgroup
    playlistAssignment: PromoPlaylistAssignment
    cronExpression: string
    priority: number


    assign(input: any): this {
        super.assign(input);
        this.assignDate(input, 'startTime')
        this.assignDate(input, 'endTime')
        this.assignTypedObject(input, 'deviceSlotTypeSubgroup', PromoPeriodDeviceSlotSubgroup)
        this.assignTypedObject(input, 'playlistAssignment', PromoPlaylistAssignment)
        return this
    }
}
export class EvaluatedPromoPlaylistScheduleEntry extends BaseModel{
    playlistScheduleEntry: PromoPlaylistScheduleEntry
    activateAt: Date
    
    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'playlistScheduleEntry', PromoPlaylistScheduleEntry)
        this.assignDate(input, 'activateAt')
        return this
    }
}

export interface PromoPlaylistScheduleEntryMutationInput {
    op: MutationOperation
    id: string
    startTime: string
    endTime: string
    playlistAssignmentId: string
    cronExpression: string
    priority: number
}
