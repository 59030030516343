<div class="mat-table looma-grid-table" [class.with_expanded_items]="dataSource?.hasExpandedItems"
     [class.no_scroll]="!scrollContent" #rootEl
     style="display: flex; flex-direction: column;align-items: stretch; width:100%;">
    <div class="mat-grid-header-row" [class.with-with-heading]="!!headingTemplate" *ngIf="showHeaders">
        <div class="mat-grid-heading" *ngIf="headingTemplate || hasTitle" fxLayout="row" fxLayoutAlign="center center">
            <div class="grid-title ellipsize" fxFlex *ngIf="titleString">{{titleString}}</div>
            <div class="grid-title" fxFlex *ngIf="titleHtml" [innerHTML]="titleHtml"></div>
            <ng-container *ngIf="headingTemplate">
                <ng-content *ngTemplateOutlet="headingTemplate"></ng-content>
            </ng-container>
        </div>

        <div class="mat-header-row" matSort (matSortChange)="sortData($event)">
            <div class="mat-header-cell mat-column-selectionColumn mat-column-headingAction"
                 *ngIf="dataSource?.isSelectionEnabled()">
                <span *ngIf="dataSource.selection "><mat-checkbox [disabled]="dataSource.getItemCount() <= 0"
                                                                  [indeterminate]="dataSource.selection.someSelected"
                                                                  [checked]="!dataSource.selection.noneSelected"
                                                                  (change)="dataSource.selection.toggleAllSelected()"></mat-checkbox></span>
            </div>
            <div class="mat-header-cell mat-column-toggleExpandColumn mat-column-headingAction"
                 *ngIf="hasExpandableRows">

            </div>
            <!--<div *ngIf="dataSource.isSelectionEnabled()"></div>-->
            <div class="mat-header-cell mat-column-{{column.key}}" *ngFor="let column of dataSource?.visibleColumns">
                <ng-container *ngIf="!headerTemplates.has(column.key)">
                    <span *ngIf="column.sortable" mat-sort-header="{{column.key}}">{{column.label}}</span>
                    <span *ngIf="!column.sortable" [class.max_width_cell_content]="isMaxWidthCell(column)"
                          [class.center_align_content]="column.alignContent == 'center'">{{column.label}}</span>
                </ng-container>
                <ng-content
                    *ngTemplateOutlet="headerTemplates.get(column.key); context:{dataSource: dataSource, column: column, label:column.label}"></ng-content>

            </div>
        </div>
    </div>

    <div class="scroll_content_wrapper"
         cdkDropList
         [cdkDropListSortingDisabled]="!(dragDropController?.dragDropEnabled && dragDropController?.sortableEnabled)"
         [cdkDropListEnterPredicate]="dragDropController?.dragEnter"
         [cdkDropListConnectedTo]="dragDropController?.connectedDropLists">

        <div class="empty_grid_content" *ngIf="(dataSource?.onEmptyData() | async) && emptyContentTemplate">
            <ng-container *ngTemplateOutlet="emptyContentTemplate"></ng-container>
        </div>
        
        <div *ngIf="!scrollContent">
            <div *ngFor="let row of dataSource?.onData() | async; let index = index;"
                 [attr.data-row-id]="row.getId()"
                 [attr.data-row-index]="index"
                 cdkDrag
                 [cdkDragData]="row.getData()"
                 [cdkDragDisabled]="dragDropController ? (!dragDropController.dragDropEnabled || !dragDropController?.isDragEnabled(dataSource, row)): true"
                 (cdkDragStarted)="dragDropController.onDragStart($event)">
                <ng-container [ngTemplateOutlet]="tmplRow"
                              [ngTemplateOutletContext]="{dataSource:dataSource, row:row, dragPreviewTemplate: dragPreviewTemplate}"></ng-container>
                <ng-container *ngIf="dragPreviewTemplate">
                    <div *cdkDragPreview>
                        <ng-content
                            *ngTemplateOutlet="dragPreviewTemplate; context:{row: row, dataSource:dataSource, selectedItems: dragDropController?.selectedItems}"></ng-content>
                    </div>
                </ng-container>
            </div>
        </div>

        <cdk-virtual-scroll-viewport *ngIf="scrollContent" itemSize="{{rowHeight}}" #scrollViewport
        >
            <div *cdkVirtualFor="let row of dataSource?.onData() | async; let index = index;"
                 [attr.data-row-id]="row.getId()"
                 [attr.data-row-index]="index"
                 cdkDrag
                 [cdkDragData]="row.getData()"
                 [cdkDragDisabled]="dragDropController ? (!dragDropController.dragDropEnabled || !dragDropController?.isDragEnabled(dataSource, row)): true"
                 (cdkDragStarted)="dragDropController.onDragStart($event)">
                <ng-container [ngTemplateOutlet]="tmplRow"
                              [ngTemplateOutletContext]="{dataSource:dataSource, row:row, dragPreviewTemplate: dragPreviewTemplate}"></ng-container>
                <ng-container *ngIf="dragPreviewTemplate">
                    <div *cdkDragPreview>
                        <ng-content
                            *ngTemplateOutlet="dragPreviewTemplate; context:{row: row, dataSource:dataSource, selectedItems: dragDropController?.selectedItems}"></ng-content>
                    </div>
                </ng-container>
            </div>

        </cdk-virtual-scroll-viewport>

        <div class="extra_body_content" *ngIf="extraBodyContentTemplate">
            <ng-container *ngTemplateOutlet="extraBodyContentTemplate"></ng-container>
        </div>
    </div>
</div>
<ng-content></ng-content>

<ng-template #tmplRow let-dataSource="dataSource" let-row="row" let-dragPreviewTemplate="dragPreviewTemplate">
    <div class="mat-row-wrap" [class.mat-row-wrap-expanded]="dataSource.isItemExpanded(row)"
         [attr.data.row-id]="row.id">

        <div class="mat-row mat-grid-row" [class.selected]="dataSource?.selection?.isItemSelected(row)"
             [class.clickable]="!!rowExpansionTemplate" (click)=" handleItemClick(row, $event)">

            <div class="mat-cell mat-column-selectionColumn mat-column-headingAction"
                 *ngIf="dataSource.isSelectionEnabled()" data-column="selectionColumn">
                <ng-container *ngIf="dataSource.selection">
                    <mat-checkbox (change)="dataSource.selection.toggleSelection(row)"
                                  [checked]="dataSource.selection.isItemSelected(row)"
                                  (click)="$event.stopPropagation()"></mat-checkbox>
                </ng-container>
            </div>
            <div class="mat-cell mat-column-toggleExpandColumn mat-column-headingAction" *ngIf="hasExpandableRows"
                 data-column="toggleExpandColumn">
                <button *ngIf="dataSource.isItemExpandable(row)" mat-icon-button
                        [class.collapsed]="!dataSource.isItemExpanded(row)"
                        [class.expanded]="dataSource.isItemExpanded(row)" title="Toggle Expanded"
                        (click)="dataSource.toggleItemExpanded(row);">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>
            <div class="mat-cell mat-column-{{column.key}}" *ngFor="let column of dataSource.visibleColumns"
                 [attr.data-column]="column.key">
                <ng-container *ngIf="dataSource.selection && column.selectionColumn">
                    <mat-checkbox (change)="dataSource.selection.toggleSelection(row)"
                                  [checked]="dataSource.selection.isItemSelected(row)"
                                  (click)="$event.stopPropagation()"></mat-checkbox>
                </ng-container>
                <ng-content
                    *ngTemplateOutlet="dataSource.getCellTemplate(column); context:{data: row, column: column, dataSource: dataSource}"></ng-content>
                <ng-container *ngIf="!dataSource.getCellTemplate(column)">
                    <span
                        [class.ellipsize]="column.ellipsizeContent !== false"
                        [class.max_width_cell_content]="isMaxWidthCell(column)"
                        [class.center_align_content]="column.alignContent == 'center'"

                    >{{dataSource.getColumnValue(column, row) || row[column.key]}}</span>
                </ng-container>
            </div>

        </div>
        <div *ngIf="hasExpandableRows && dataSource.isItemExpanded(row)" class="mat-row-expansion">
            <div style="background: white">
                <ng-content
                    *ngTemplateOutlet=" rowExpansionTemplate || dataSource.rowExpansionTemplate; context:{data: row}"></ng-content>
            </div>
        </div>


    </div>
</ng-template>


<ng-template #tmplRowActions let-data="data">
    <button mat-icon-button *ngFor="let action of externalRowActions.registry.forItem(data)?.primary"
            [disabled]="!action.available(data)" (click)="action.handler(data.model); handleActionClick($event);"
            title="{{action.label}}">
        <mat-icon>{{action.icon}}</mat-icon>
    </button>
    <button mat-icon-button *ngIf="externalRowActions.registry.forItem(data)?.hasSecondary"
            #secondaryRowActionMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="rowActionMenu"
            [matMenuTriggerData]="{data: data, actions: externalRowActions.registry.forItem(data)?.secondary}"
            title="More" (click)="$event.stopPropagation();">
        <mat-icon>more_vert</mat-icon>
    </button>
</ng-template>

<mat-menu #rowActionMenu="matMenu">
    <ng-template matMenuContent let-data="data">
        <span *ngFor="let action of externalRowActions.registry.forItem(data).secondary">
        <button mat-menu-item *ngIf="action.showAlways || action.available(data)" [disabled]="!action.available(data)"
                (click)="action.handler(data.model); handleActionClick($event);">{{action.label}}</button>    
        </span>

    </ng-template>
</mat-menu>
