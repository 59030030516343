import {Injectable} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {LoomaLayoutService} from '@looma/shared/services/looma-layout.service';
import {ImageLoader} from '@looma/shared/image_loader';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService extends LoomaLayoutService {

    private imageLoader = new ImageLoader(10)

    constructor(
        mediaObserver: MediaObserver,
        snack: MatSnackBar,
        dialog: MatDialog
    ) {
        super(mediaObserver, snack, dialog);
    }

    loadImage(src: string): Observable<string> {
        return this.imageLoader.load(src)
    }

}

