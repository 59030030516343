import {Component, Injectable, OnInit} from '@angular/core';
import {Retailer} from "@looma/shared/models/retailer";
import {ApiDataService} from "../../../../services/api-data.service";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {PreferencesService} from "@looma/shared/services/preferences.service";
import {RetailerStoreGroupsListComponent} from "../retailer-store-groups-list/retailer-store-groups-list.component";
import {Router} from "@angular/router";


@Injectable()
export class StoreGroupsTabControllerService {
    private _activeTab: RetailerStoreGroupsListComponent;

    constructor(
        private svcPrefs: PreferencesService
    ) {
    }

    setActiveTab(tab: RetailerStoreGroupsListComponent) {
        this._activeTab = tab;
        setTimeout(() => {
            this.svcPrefs.setSessionValue('active_store_group_retailer_tab', tab.retailer)
        }, 0)
    }

    getActiveTab(): RetailerStoreGroupsListComponent {
        return this._activeTab;
    }

    isActiveRetailer(ret: Retailer) {
        const selectedRetailer = this.svcPrefs.getSessionValue('active_store_group_retailer_tab') as Retailer;
        return ret && (selectedRetailer?.id == ret?.id);
    }
}

@LifecycleHooks()
@Component({
    selector: 'app-store-groups',
    templateUrl: './store-groups.component.html',
    styleUrls: ['./store-groups.component.scss'],
    providers: [StoreGroupsTabControllerService]
})
export class StoreGroupsComponent implements OnInit {
    retailers: Retailer[] = [];

    constructor(
        private svcApi: ApiDataService,
        public svcTabsController: StoreGroupsTabControllerService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.svcApi.getRetailers().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.retailers = Retailer.getSortedRetailers(value);
        })
    }

    openManageGroups() {
        this.router.navigate(['/store-groups', this.svcTabsController.getActiveTab().retailer.id])
    }

}



