import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AppCommonModule } from '../../shared/common.module';
import { NgxEchartsModule } from "ngx-echarts";
import { DashboardChartsComponent } from './components/dashboard-charts/dashboard-charts.component';
import {
    NetworkHealthHistoryDashboardTabComponent
} from './components/network-health-history-dashboard-tab/network-health-history-dashboard-tab.component';
import { LayoutModule } from "../../layout/layout.module";
import {
    NetworkHealthOverviewDashboardTabComponent
} from './components/network-health-overview-dashboard-tab/network-health-overview-dashboard-tab.component';

@NgModule({
    declarations: [
        DashboardComponent,
        DashboardChartsComponent,
        NetworkHealthHistoryDashboardTabComponent,
        NetworkHealthOverviewDashboardTabComponent
    ],
    imports: [
        AppCommonModule,
        RouterModule,
        NgxEchartsModule,
        LayoutModule,
    ],
    exports     : [
        DashboardComponent

    ]
})

export class DashboardModule{

    iconUrl: {
        url: './assets/icons/baseline-place-24px.svg',
        scaledSize: {
            width: 40,
            height: 60
        }
    };

}
