<div app-looma-page pageTitle="User Roles" pageIcon="verified_user" [headerSideTpl]="headerSideTpl">
    <looma-grid [dataSource]="dataSource">
        <ng-template rowExpansionTemplate let-data="data">
            <div *ngFor="let group of data.permissionGroups()">
                <div style="border: 1px solid #9ad3bc; border-radius: 5px; margin: 10px; padding: 10px">
                    <h3>{{group}}</h3>
                    <ul>
                        <li *ngFor="let permission of data.groupedPermissions().get(group) || []"
                            style="padding-top: 5px">{{permission.name}}</li>
                    </ul>
                </div>
            </div>
        </ng-template>
    </looma-grid>
</div>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button (click)="addNewRole()">Add new Role</button>
    </div>
</ng-template>
