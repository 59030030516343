<h2 mat-dialog-title>{{this.segment.isNewRecord() ? "Create New Segment" : "Edit segment: " + this.segment.name}}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-form-field *ngIf="segment">
        <mat-label>Promo period</mat-label>
        <input matInput formControlName="promoPeriod" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field *ngIf="segment">
        <mat-label>Promo program</mat-label>
        <input matInput formControlName="promoProgram" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Segment name</mat-label>
        <input matInput formControlName="name" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field *ngIf="segment">
        <mat-label>Device slot type</mat-label>
        <mat-select multiple="false" formControlName="deviceSlotTypeFormControl" placeholder="Device slot type"
                    [value]="segment.deviceSlotType" [compareWith]="isSmeObj">
            <mat-option *ngFor="let d of promoProgram.deviceSlotTypes" [value]="d">
                {{d.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Default Brand Campaign Slots Count</mat-label>
        <input matInput type="number" formControlName="defaultBrandSlotsCount" autocomplete="off"/>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!isFormValid()" (click)="handleAction('ok')">Ok</button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>