import {BaseModel} from '@looma/shared/models/base_model';
import {FilmVariable} from '@looma/shared/models/film_variable'

export class MediaFileFilmVariable extends BaseModel {

    id: string;
    film_variable: FilmVariable
    variable_value: string

    assign(obj: any): this {
        super.assign(obj);
        return this;
    }

    isNewRecord(): boolean {
        return !this.id
    }
}
