import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModelColumnSpec, ModelDataSource} from "../../../layout/components/looma-grid/grid-data-source";
import {BrandProduct} from "@looma/shared/models/brand_product";
import {ApiDataService} from "../../../services/api-data.service";
import {Observable, Subscription, switchMap} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {LayoutService} from "../../../services/layout.service";
import {MatDialogConfig} from "@angular/material/dialog";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ProductEditDialogComponent} from "../product-edit-dialog/product-edit-dialog.component";
import {NamedValue} from "@looma/shared/types/named_value";
import {EditItemComponent, ModelListPageComponent} from "../../../shared/model_list_page_component";
import {ButtonActions} from "../../../shared/button_actions";
import {CursorFilter} from '@looma/shared/types/cursor_filter';
import {delay, takeUntil} from "rxjs/operators";
import {exportToCsv, Utils} from "@looma/shared/utils";
import {BulkProductUploadDialogComponent} from "../bulk-product-upload-dialog/bulk-product-upload-dialog.component";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {Retailer} from "@looma/shared/models/retailer";
import {Router} from "@angular/router";

enum ProductAction {
    Archive = 'Archive',
    Unarchive = 'Un-archive',
    ShowDetails = 'Show details',
}

@LifecycleHooks()
@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent extends ModelListPageComponent<BrandProduct, CursorFilter>
    implements EditItemComponent<BrandProduct>, OnInit {

    @ViewChild('upcSearchInput', {static: false}) upcSearchInput: ElementRef<HTMLInputElement>;

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private router: Router,
    ) {
        super(BrandProduct);
        this.addRowAction({
            primary: false,
            key: ProductAction.Archive,
            showAlways: false
        })
        this.addRowAction({
            primary: false,
            key: ProductAction.Unarchive,
            showAlways: false
        })
        this.addRowAction({
            primary: false,
            key: ProductAction.ShowDetails,
            showAlways: false
        })
    }

    showUpcDetailsView = false

    get dataSource(): ProductDataSource {
        return super.dataSource as ProductDataSource;
    }

    isActionAvailable(action: string, entry: BrandProduct): boolean {
        const isArchived = entry.unwrap().isArchived
        let available = false

        switch (action) {
            case ProductAction.Archive:
                available = Utils.isBoolean(isArchived) && !isArchived
                return available
            case ProductAction.Unarchive:
                available = Utils.isBoolean(isArchived) && isArchived
                return available
            case ProductAction.ShowDetails:
                return true
            default:
                return super.isActionAvailable(action, entry);
        }
    }

    performMenuAction(action: string, prod: BrandProduct): boolean {
        switch (action) {
            case ProductAction.Archive:
            case ProductAction.Unarchive:
                this.performArchiveProduct(action, prod)
                return true
            case ProductAction.ShowDetails:
                this.upcSearchInput.nativeElement.value = prod.upc_code
                this.searchByUpc(prod.upc_code, 0)
                break
            default:
                return super.performMenuAction(action, prod);
        }
    }

    private performArchiveProduct(action: ProductAction, prod: BrandProduct,) {

        this.svcLayout.onConfirmed(
            `${action} product`,
            `Are you sure you want to ${action.toLowerCase()} this product?`
        ).pipe(
            switchMap(value => {
                return this.svcApi.upsertProduct(MutationOperation.Update, {
                    id: prod.id,
                    isArchived: action == ProductAction.Archive,
                })
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.dataSource.replaceItem(value.data)
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Unexpected error');
            }
        })

    }

    initDataSource(): ModelDataSource<BrandProduct, CursorFilter> {
        return new ProductDataSource(this.svcApi);
    }

    onUpcSearchInputChanged(event: Event): void {
        Utils.unsubscribe(this.fetchUpcDetailsSub)
        const term = (event.target as HTMLInputElement).value;
        this.searchByUpc(term)
    }

    clearUpcSearchInput() {
        this.upcSearchInput.nativeElement.value = ''
        this.searchByUpc('')
    }

    searchByUpc(code: string, debounceTime = 300) {
        this.dataSource.applyFilter({
            query: code,
        }, 300)
    }

    canEditItem(item: BrandProduct): boolean {
        return true;
    }

    performEditItem(item: BrandProduct): Observable<BrandProduct> {
        const dialogConfig: MatDialogConfig = {
            width: '800px'
        }
        if (item) {
            dialogConfig.data = {
                product: item
            };
        }
        return this.svcLayout.openDialogForResult(ProductEditDialogComponent, dialogConfig);
    }

    addNewProduct(): void {
        this.performMenuAction(ButtonActions.Edit, new BrandProduct())
    }

    bulkUploadProducts() {
        BulkProductUploadDialogComponent.open(this.svcLayout)
    }

    onBrandPartnerFilterChanged(data: NamedValue): void {
        this.dataSource.applyFilter({brandPartnerId: data ? data.value : null})
    }

    setShowArchivedProducts(showArchived: boolean): void {
        this.dataSource.applyFilter({archived: showArchived})
    }


    downloadCsv() {
        this.dataSource.loadAllRecords().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(values => {
            const rows: any[][] = [];
            let idx = 0;

            const cols: ModelColumnSpec<BrandProduct>[] = [
                {
                    label: 'UPC',
                    valueReader: (item: BrandProduct) => {
                        return item.upc_code;
                    }
                }, {
                    label: 'Name',
                    valueReader: (item: BrandProduct) => {
                        return item.getDisplayName();
                    }
                }, {
                    label: 'Brand',
                    valueReader: (item: BrandProduct) => {
                        return item.brand_partner.name;
                    }
                }, {
                    label: 'Retailers',
                    valueReader: (item: BrandProduct) => {
                        return item.retailers?.map(v => v.retailer_name).join(", ");
                    }
                }, {
                    label: 'Image',
                    valueReader: (item: BrandProduct) => {
                        return item.thumb_url;
                    }
                }, {
                    label: 'Last update',
                    valueReader: (item: BrandProduct) => {
                        return item.updatedAt;
                    }
                }]

            const colsCount = cols.length;
            const headers: any[] = new Array(colsCount);
            rows.push(headers);
            for (const col of cols) {
                headers[idx++] = col.label;
            }
            for (const v of values) {
                const row: any[] = new Array(colsCount);
                rows.push(row)
                idx = 0;
                for (const col of cols) {
                    row[idx++] = col.valueReader(v.getData()) || ''
                }
            }
            exportToCsv('products.csv', rows)
        })
    }

    fetchUpcDetailsSub: Subscription
    upcDetailColumnNames: string[]
    upcDetails: {
        retailer: Retailer,
        upcDetails: Map<string, string>
    }[]

    clearUpcDetails() {
        this.upcDetailColumnNames = []
        this.upcDetails = []
        Utils.unsubscribe(this.fetchUpcDetailsSub)
        this.showUpcDetailsView = false
    }

    ngOnInit(): void {
        this.dataSource.onDataAvailable().pipe(
            delay(1),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            const data = this.dataSource.getData()
            this.clearUpcDetails()
            if (data.length == 1) {

                const upcCode = data[0].getData().upc_code

                if (!upcCode) {
                    return
                }

                this.showUpcDetailsView = true

                const colsSet = new Set<string>()

                this.fetchUpcDetailsSub = this.svcApi.fetchUpcDetails(upcCode).pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe(values => {
                    for (const value of values) {
                        for (const key of value.upcDetails.keys()) {
                            colsSet.add(key)
                        }
                    }
                    this.upcDetails = [
                        ...values,
                    ]

                    this.upcDetailColumnNames = Array.from(colsSet).sort()
                })

            }
        })
    }

    get isLoadingUpcDetails() {
        return !Utils.isUnsubscribed(this.fetchUpcDetailsSub)
    }

    onCustomProductsClicked() {
        this.router.navigate(["/custom-products"]).then()
    }
}

interface CsvColumnSpec<T> {
    label: string
    reader: (record: T) => string
}

class ProductDataSource extends ModelDataSource<BrandProduct, ProductFilter> {
    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'imageUrl',
                    label: 'Image',
                    width: '100px',
                    valueReader: (item: BrandProduct) => {
                        return item.thumb_url;
                    }
                },
                {
                    key: 'upcCode',
                    label: 'UPC',
                    valueReader: (item: BrandProduct) => {
                        return item.upc_code;
                    }
                },
                {
                    key: 'name',
                    label: 'Name',
                    valueReader: (item: BrandProduct) => {
                        return item.getDisplayName();
                    }
                }, {
                    key: 'brand_partner',
                    label: 'Brand',
                    valueReader: (item: BrandProduct) => {
                        return item.brand_partner.name;
                    }
                }, {
                    key: 'retailer',
                    label: 'Retailers',
                    valueReader: (item: BrandProduct) => {
                        return item.retailers?.map(v => v.retailer_name).join(", ");
                    }
                },
                {
                    key: 'updatedAt',
                    label: 'Last update',
                    valueReader: (item: BrandProduct) => {
                        return item.updatedAt;
                    }
                },
                {
                    key: 'status',
                    label: 'Status',
                    width: '80px',
                    valueReader: (item: BrandProduct) => {
                        if (Utils.isBoolean(item.isArchived)) {
                            return item.isArchived ? 'Archived' : 'Active'
                        }
                        return ''
                    }
                },
            ]
        });
    }

    loadData(dataFilter: ProductFilter): Observable<CursorFeed<BrandProduct>> {
        return this.svcApi.getProducts(dataFilter);
    }


}

export interface ProductFilter extends CursorFilter {
    cursor: string;
    brandPartnerId: string;
    query: string;
    archived: boolean
}

