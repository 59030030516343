import { BaseModel } from '@looma/shared/models/base_model';
import { WordFilterable } from '@looma/shared/types/word_filterable';
import { Retailer } from "@looma/shared/models/retailer";

export class RetailerDivision extends BaseModel implements WordFilterable {

    name: string;
    retailer: Retailer;

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'retailer', Retailer)

        return this;
    }

}

