import {Component, OnInit} from '@angular/core';
import {EditItemComponent, ModelListPageComponent} from "../../../../shared/model_list_page_component";
import {RetailerPromoProgram, RetailerPromoProgramFeedFilter} from "@looma/shared/models/retailer_promo_program";
import {ModelDataSource, ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {LayoutService} from "../../../../services/layout.service";
import {EMPTY, Observable} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ButtonActions} from "../../../../shared/button_actions";
import {ProgramEditDialogComponent} from "../program-edit-dialog/program-edit-dialog.component";
import {
    ProgramDeviceSlotTypeDialogComponent
} from "../program-device-slot-type-dialog/program-device-slot-type-dialog.component";
import {flatMap, map, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {RowActionRegistry} from "../../../../shared/row_action_registry";
import {Router} from "@angular/router";
import {
    ProgramDeviceSlotTypeAssignmentComponent
} from "../program-device-slot-type-assignment/program-device-slot-type-assignment.component";
import {DeviceSlotType} from "@looma/shared/models/device_slot_type";
import gql from "graphql-tag";
import {ApiDataService} from "../../../../services/api-data.service";
import {BaseModel} from "@looma/shared/models/base_model";
import {Retailer} from "@looma/shared/models/retailer";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {CursorFilter} from '@looma/shared/types/cursor_filter';
import {NamedValue} from "@looma/shared/types/named_value";

const ActionAddDeviceSlotType = 'ActionAddDeviceSlotType';
const ActionSlotTypeAssignment = 'ActionSlotTypeAssignment';

@LifecycleHooks()
@Component({
    selector: 'app-programs-list',
    templateUrl: './programs-list.component.html',
    styleUrls: ['./programs-list.component.scss']
})
export class ProgramsListComponent extends ModelListPageComponent<RetailerPromoProgram, CursorFilter>
    implements EditItemComponent<RetailerPromoProgram>, OnInit {

    deviceSlotTypeActions = new RowActionRegistry<DeviceSlotType>([
        {
            key: this.buttonActions.Edit,
            label: 'Edit',
            handler: item => {
                this.performDeviceSlotTypeAction(this.buttonActions.Edit, item);

            }
        }, {
            key: this.buttonActions.Delete,
            label: 'Delete',
            handler: item => {
                this.performDeviceSlotTypeAction(this.buttonActions.Delete, item);
            },
            available: item => {
                return item.deviceSlotsCount === 0;
            }
        }
    ]);

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private router: Router
    ) {
        super(RetailerPromoProgram);
        this.addRowAction({
            icon: 'add_circle',
            label: 'New Device Slot Type',
            key: ActionAddDeviceSlotType,
            primary: true
        });

        this.addRowAction({
            icon: 'assignment',
            showAlways: false,
            primary: true,
            label: 'Slot Type Assignment',
            key: ActionSlotTypeAssignment,
        });

    }

    get dataSource(): ProgramDataSource {
        return super.dataSource as ProgramDataSource;
    }

    ngOnInit() {
    }

    performDeviceSlotTypeAction(action: string, entry: DeviceSlotType) {
        if (!entry || !entry.retailerPromoProgram) {
            return;
        }
        switch (action) {
            case this.buttonActions.Edit:
                this.editDeviceSlotType(entry.retailerPromoProgram, entry);
                break
            case this.buttonActions.Delete:
                this.svcLayout.confirm('Delete Type', `Are you sure you want to delete ${entry.getDisplayName()}`).pipe(
                    flatMap(value => {
                        if (!value) {
                            return EMPTY;
                        }
                        return this.svcApi.mutateDeviceSlotType(MutationOperation.Delete, {
                            id: entry.id,
                        }, DEVICE_SLOT_TYPE_GQL_FIELDS)
                    })
                ).subscribe(value => {
                    if (!value.success) {
                        this.svcLayout.showSnackMessage(value.message || 'Unexpected Error')
                        return
                    }
                    this.onDeviceSlotTypeChanged(entry.retailerPromoProgram, value.data, true);
                })
                break;
        }
    }

    isActionAvailable(action: string, entry: RetailerPromoProgram): boolean {
        switch (action) {
            case ActionAddDeviceSlotType:
                return true;
            case ActionSlotTypeAssignment:
                if ((entry.deviceSlotTypes || []).length) {
                    return true;
                }
                return false;
        }
        return super.isActionAvailable(action, entry);
    }

    performMenuAction(action: string, promoProgram: RetailerPromoProgram): boolean {
        switch (action) {
            case ActionAddDeviceSlotType:
                this.editDeviceSlotType(promoProgram, new DeviceSlotType())
                return true;
            case ActionSlotTypeAssignment:
                ProgramDeviceSlotTypeAssignmentComponent.open(this.router, promoProgram);
                return true;
        }
        return super.performMenuAction(action, promoProgram);
    }

    private editDeviceSlotType(promoProgram: RetailerPromoProgram, deviceSlotType: DeviceSlotType) {
        promoProgram = BaseModel.unwrap(promoProgram)
        deviceSlotType = BaseModel.unwrap(deviceSlotType)

        if (!promoProgram || !deviceSlotType) {
            return
        }
        ProgramDeviceSlotTypeDialogComponent.open(this.svcLayout, {
            deviceSlotTypeGqlFields: DEVICE_SLOT_TYPE_GQL_FIELDS,
            deviceSlotType: deviceSlotType,
            promoProgram: promoProgram
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.onDeviceSlotTypeChanged(promoProgram, value, false);
        });
    }

    private onDeviceSlotTypeChanged(promoProgram: RetailerPromoProgram, deviceSlotType: DeviceSlotType, isRemoved: boolean) {
        if (!deviceSlotType || !promoProgram) {
            return
        }

        promoProgram.deviceSlotTypes = promoProgram.deviceSlotTypes || [];
        const idx = (promoProgram.deviceSlotTypes).findIndex(value1 => value1.id == deviceSlotType.id);

        if (isRemoved) {
            if (idx < 0) {
                return
            }
            promoProgram.deviceSlotTypes.splice(idx, 1)
        } else {
            if (idx >= 0) {
                promoProgram.deviceSlotTypes[idx] = deviceSlotType;
            } else {
                promoProgram.deviceSlotTypes.push(deviceSlotType);
            }
        }
        this.dataSource.replaceItem(promoProgram);
    }

    initDataSource(): ModelDataSource<RetailerPromoProgram, CursorFilter> {
        return new ProgramDataSource(this.svcApi);
    }

    addNewProgram() {
        this.performMenuAction(ButtonActions.Edit, new RetailerPromoProgram())
    }

    canEditItem(item: RetailerPromoProgram): boolean {
        return true;
    }

    performEditItem(prog: RetailerPromoProgram): Observable<RetailerPromoProgram> {
        return ProgramEditDialogComponent.open(this.svcLayout, prog, PROGRAM_GQL_FIELDS)
    }

    onRetailerFilterChanged(nv: NamedValue) {
        const retailerId = nv ? [nv.value] : []
        this.dataSource.applyFilter({retailerIds: retailerId})
    }

    onProgramNameFilterChanged($event: Event) {
        const term = (event.target as HTMLInputElement).value;
        this.dataSource.applyFilter({query: term})
    }

    clearProgramNameFilter() {
        this.dataSource.applyFilter({query: null})
    }
}


class ProgramDataSource extends ModelDataSource<RetailerPromoProgram, RetailerPromoProgramFeedFilter> {

    private slotTypesDataSources = new Map<string, DeviceTypeDataSource>();

    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'retailer',
                    label: 'Retailer',
                    width: "70px",
                    valueReader: (item: RetailerPromoProgram) => {
                        return item.retailer.retailer_id
                    }
                },
                {
                    key: 'name',
                    label: 'Name',
                    width: "300px",
                    valueReader: (item: RetailerPromoProgram) => {
                        return item.name
                    }
                }, {
                    key: 'product-category',
                    label: 'Category',
                    valueReader: (item: RetailerPromoProgram) => {
                        return item.productCategory?.category_name
                    }
                }, {
                    key: 'flexibility',
                    label: 'Program Flexibility',
                    valueReader: (item: RetailerPromoProgram) => {
                        return item.flexibility
                    }
                }, {
                    key: 'program',
                    label: 'Airtime',
                    valueReader: (item: RetailerPromoProgram) => {
                        return item.airtimeType
                    }
                }, {
                    key: 'campaign_slots',
                    label: 'Campaign Slots',
                    valueReader: (item: RetailerPromoProgram) => {
                        return item.campaignSlotsPerPeriod
                    }
                }, {
                    key: 'max_featured_items',
                    label: 'Max Featured Items',
                    valueReader: (item: RetailerPromoProgram) => {
                        return item.maxFeaturedItemsPerCampaign
                    }
                }, {
                    key: 'kiosk_app',
                    label: 'Active Application',
                    valueReader: (slot: RetailerPromoProgram) => {
                        return slot.kioskDeviceApp?.getDisplayName()
                    }
                }, {
                    key: 'active',
                    label: 'Active',
                    valueReader: (item: RetailerPromoProgram) => {
                        return item.active ? 'Yes' : 'No'
                    }
                }
            ]
        });
    }

    loadData(dataFilter: RetailerPromoProgramFeedFilter | null): Observable<CursorFeed<RetailerPromoProgram>> {
        return this.svcApi.rawQueryNoCache({
            query: PROGRAMS_LIST_QUERY,
            variables: {
                filter: dataFilter
            }
        }).pipe(
            map(value => {
                const rawData = value.data['data'];
                const c = CursorFeed.create(rawData, RetailerPromoProgram, 'retailerPromoPrograms')
                c.data.sort((a, b) => {
                        const retailerNameComparison = a.retailer.retailer_name.localeCompare(b.retailer.retailer_name)
                        if (retailerNameComparison === 0) {
                            return a.name.localeCompare(b.name);
                        }
                        return retailerNameComparison;
                    }
                )
                return c;
            })
        )
    }

    replaceItem(item: RetailerPromoProgram): boolean {
        this.slotTypesDataSources.delete(item.id)
        return super.replaceItem(item);
    }

    getDeviceTypesDataSource(item: RetailerPromoProgram): DeviceTypeDataSource {
        if (!this.slotTypesDataSources.has(item.id)) {
            this.slotTypesDataSources.set(item.id, new DeviceTypeDataSource(item));
        }
        return this.slotTypesDataSources.get(item.id);
    }

    isItemExpandable(entry: RetailerPromoProgram): boolean {
        return (entry.deviceSlotTypes || []).length > 0
    }

}

class DeviceTypeDataSource extends ModelListDataSource<DeviceSlotType> {
    constructor(prog: RetailerPromoProgram) {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Name',
                    valueReader: (item: DeviceSlotType) => {
                        return item.name
                    }
                }, {
                    key: 'brand_slots_count',
                    label: 'Default Brand Slots Count',
                    valueReader: (item: DeviceSlotType) => {
                        return item.defaultBrandSlotsCount
                    }
                }, {
                    key: 'stores_count',
                    label: 'Stores Count',
                    valueReader: (item: DeviceSlotType) => {
                        const regionsCount = (item.retailerRegionIds || []).length;
                        return `${item.storesCount || 0} (${regionsCount} regions)`
                    }
                }, {
                    key: 'device_slots_count',
                    label: 'Device Slots Count',
                    valueReader: (item: DeviceSlotType) => {
                        return item.deviceSlotsCount
                    }
                }
            ]
        });

        const data = prog.deviceSlotTypes || [];

        for (const item of data) {
            item.retailerPromoProgram = prog;
        }

        this.setLocalData(data)
    }

}

const DEVICE_SLOT_TYPE_GQL_FIELDS = `
id
name
defaultBrandSlotsCount
retailerRegionIds
storesCount
deviceSlotsCount
`

const PROGRAM_GQL_FIELDS = `
id
name
active
programType
segments
flexibility
campaignSlotsPerPeriod
coverImageDownloadUrl
programSummaryAttachmentDownloadUrl
distributionRequirement
airtimeType
airtimeSegments
description
costStructure
campaignVisibleInLoop
cprEnabled
interestCollectionEnabled
executionReportingEnabled
productSegmentation
productSegments
numberOfStores
productCategory {
    id
    category_name
}
maxFeaturedItemsPerCampaign
kioskDeviceApp {
    id
    app_name
    package_name
}
retailer{
    id
    retailer_name
    retailer_id
}
deviceSlotTypes{
    ${DEVICE_SLOT_TYPE_GQL_FIELDS}
}
changeoverSettings{
    enabled
    numDays
}
`

const PROGRAMS_LIST_QUERY = gql`
query programs($filter: RetailerPromoProgramFeedFilter){
  data: retailerPromoPrograms(filter: $filter){
    cursor
    retailerPromoPrograms{
      ${PROGRAM_GQL_FIELDS}
    }
  }
}
`;

