import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RetailersListComponent} from './components/retailers-list/retailers-list.component';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import {RetailersEditDialogComponent} from './components/retailers-edit-dialog/retailers-edit-dialog.component';

@NgModule({
  declarations: [RetailersListComponent, RetailersEditDialogComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule
    ],
    entryComponents: [RetailersEditDialogComponent]
})
export class RetailersModule { }
