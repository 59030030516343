import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CampaignReportsListComponent} from './campaign-reports-list/campaign-reports-list.component';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import { CampaignReportEmailDialogComponent } from './campaign-report-email-dialog/campaign-report-email-dialog.component';
import { ReportShareUrlComponent } from './report-share-url/report-share-url.component';

@NgModule({
  declarations: [CampaignReportsListComponent, CampaignReportEmailDialogComponent, ReportShareUrlComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule,
    ]
})
export class CampaignReportsModule { }
