<div app-looma-page pageTitle="Execution Reports" pageIcon="analytics">
    <div style="padding: 16px; overflow: scroll; margin: 0 auto">
        <mat-card>
            <mat-card-title>Execution Report</mat-card-title>

            <div fxLayout="row" fxLayoutGap="16px">
                <app-autocomplete-search-field fxFlex="50%"
                                               showClear="true"
                                               placeholder="Retailer"
                                               [searchObjectType]="'Retailer'"
                                               (valueChanged)="onRetailerSelected($event)">
                </app-autocomplete-search-field>

                <app-autocomplete-search-field fxFlex="50%"
                                               [disabled]="!promoPeriodSearchCriteria"
                                               #promoPeriodSearchField
                                               showClear="true"
                                               placeholder="Promo Period"
                                               [searchObjectType]="'PromoPeriod'"
                                               [searchCriteria]="promoPeriodSearchCriteria"
                                               (valueChanged)="onPromoPeriodSelected($event)">
                </app-autocomplete-search-field>
            </div>

            <mat-card-actions>
                <button mat-button color="warn" [disabled]="isBusy || !selectedPromoPeriod"
                        (click)="triggerGenerateReport()">Generate
                </button>
            </mat-card-actions>

        </mat-card>

        <mat-card style="margin-top: 2em">
            <mat-card-title>Retailer Store List Report</mat-card-title>

            <div fxLayout="row" fxLayoutGap="16px">
                <mat-form-field fxFlex="100%">
                    <mat-select multiple="false"
                                placeholder="Retailer"
                                (valueChange)="onRetailerStoreSelected($event)"
                                [value]="retailers">
                        <mat-option *ngFor="let r of retailers" [value]="r">
                            {{r}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-card-actions>
                <button mat-button color="warn" [disabled]="isBusy || !this.selectedRetailerStore"
                        (click)="triggerGenerateStoreListReport()">Generate
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card style="margin-top: 2em">
            <mat-card-title>Device Slots Image Report</mat-card-title>

            <div fxLayout="row" fxLayoutGap="16px">
                <mat-form-field>
                    <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" [(ngModel)]="startDate">
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
                <div style="padding-top: 20px">to</div>
                <mat-form-field>
                    <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" [(ngModel)]="endDate">
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
                <div *ngIf="(this?.startDate?.getTime() > this?.endDate?.getTime()) && (this.startDate && this.endDate)"
                     style="color: red; padding-top: 20px; font-weight: 500">Start Date Must Be Before End Date.
                </div>
            </div>
            <mat-card-actions>
                <button mat-button color="warn"
                        [disabled]="isBusy || !(this.startDate && this.endDate) || (this.startDate.getTime() > this.endDate.getTime())"
                        (click)="triggerGenerateImagesReport()">Generate
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card style="margin-top: 2em">
            <div style="display: flex; flex-direction: column; width: 1000px">
                <div>
                    <tableau-viz id='tableau-viz'
                                 src='https://prod-useast-b.online.tableau.com/t/theloomaproject/views/AwarenessDashboardWorkbook/AwarenessDashboard'
                                 width='1654' height='1009' hide-tabs toolbar='bottom'></tableau-viz>
                </div>

                <div>
                    <tableau-viz id='tableau-viz'
                                 src='https://prod-useast-b.online.tableau.com/t/theloomaproject/views/CampaignBrandSales/CampaignBrandSalesDashboard'
                                 width='1654' height='1009' hide-tabs toolbar='bottom'></tableau-viz>
                </div>

                <div>
                    <tableau-viz id='tableau-viz'
                                 src='https://prod-useast-b.online.tableau.com/t/theloomaproject/views/looma_company_scorecard/LoomaCompanyDashboard'
                                 width='1654' height='1009' hide-tabs toolbar='bottom'></tableau-viz>
                </div>
            </div>
        </mat-card>
    </div>
</div>

