import {Component} from '@angular/core';
import {StoreGroupAssignmentService, StoreGroupAssignmentViewType} from "../../services/store-group-assignment.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";

@LifecycleHooks()
@Component({
    selector: 'app-retailer-store-groups-assignment',
    templateUrl: './retailer-store-groups-assignment.component.html',
    styleUrls: ['./retailer-store-groups-assignment.component.scss'],
    providers: [StoreGroupAssignmentService]
})
export class RetailerStoreGroupsAssignmentComponent {
    viewType: StoreGroupAssignmentViewType

    constructor(
        public svcStoreGroupAssignment: StoreGroupAssignmentService
    ) {
        this.svcStoreGroupAssignment.onViewTypeChanged().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.viewType = value
        })
    }

}
