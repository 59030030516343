<mat-form-field style="display: block" [appearance]="appearance">
    <mat-chip-list #chipList [disabled]="fieldDisabled" *ngIf="selection.multiSelection">
        <mat-chip *ngFor="let sel of (selection.onChanged | async).values" [selectable]="true" [removable]="true"
                  (removed)="selection.removeValue(sel)">
            {{sel.getDisplayName()}}
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
        </mat-chip>
        <input
            [style.margin-top]="selection.isEmpty ? '-10px' : 'unset'"
            [placeholder]="getPlaceholderText()"
            [readOnly]="readonly"
            #searchInput
            (focus)="focusWatcher.handleEvent($event)"
            (blur)="focusWatcher.handleEvent($event)"
            [formControl]="formControl"
            [matAutocomplete]="searchAutocomplete"
            autocomplete="off"
            [matChipInputFor]="chipList"
            [style]="style"
            [matChipInputAddOnBlur]="false"/>
    </mat-chip-list>
    <input *ngIf="!selection.multiSelection"
           #searchInput
           [placeholder]="getPlaceholderText()"
           matInput
           autocomplete="off"
           [readOnly]="readonly"
           (focus)="focusWatcher.handleEvent($event)"
           (blur)="focusWatcher.handleEvent($event)"
           [formControl]="formControl"
           [style]="style"
           [matAutocomplete]="searchAutocomplete"/>

    <button mat-icon-button matSuffix *ngIf="showClear && !selection.isEmpty" (click)="selection.clear()">
        <mat-icon>clear</mat-icon>
    </button>

</mat-form-field>


<mat-autocomplete #searchAutocomplete="matAutocomplete" (optionSelected)="onOptionSelected($event)"
                  [displayWith]="displayFn">
    <ng-container *ngIf="searchResultsAvailable | async; let searchResult">
        <mat-option *ngIf="searchResult.isEmpty && tplEmptyValue">
            <ng-container
                [ngTemplateOutlet]="tplEmptyValue">
            </ng-container>
        </mat-option>
        <mat-option *ngIf="searchResult.isEmpty && !tplEmptyValue" [disabled]="true">No values available</mat-option>
        
        <ng-container *ngIf="!tplOption">
            <mat-option *ngFor="let res of searchResult.values " [value]="res">
                <span>{{res.getDisplayName()}}</span>
                <span *ngIf="res.hasHint" class="option_hint">{{res.hint}}</span>
            </mat-option>
        </ng-container>
        
        <ng-container *ngIf="tplOption">
            <mat-option *ngFor="let res of searchResult.values " [value]="res">
                <ng-container [ngTemplateOutlet]="tplOption" [ngTemplateOutletContext]="{data:res}"></ng-container>
            </mat-option>
        </ng-container>

    </ng-container>

</mat-autocomplete>
