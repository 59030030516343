<div>
    <div style="display: flex;align-items: center;"  *ngFor="let filter of filterCriteria.filters; let idx = index">
        <app-looma-grid-input-filter [searchCriteria]="filter" (valueChanges)="onItemChanged($event, idx)" [inEditMode]="filter == activeFilterCondition"></app-looma-grid-input-filter>
        <span *ngIf="idx == filterCriteria.filters.length-1" class="small_button add_filter_button mat-elevation-z1 ml-8" (click)="addCriteria()"><mat-icon>add</mat-icon></span>
    </div>

    <button mat-raised-button *ngIf="(!filterCriteria.filters?.length)" (click)="addCriteria()" style="margin: 0 auto; " color="primary">Add New Filter</button>

</div>

