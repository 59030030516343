import {ModelColumnSpec, ModelFilterFunc, ModelListDataSource} from "../looma-grid/grid-data-source";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {SearchFormControl, Utils, YesNoAny, YesNoAnyFormControl} from "@looma/shared/utils";
import {Store} from "@looma/shared/models/store";
import {AsyncSubject, BehaviorSubject, Observable, Subject} from "rxjs";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {debounceTime, takeUntil} from "rxjs/operators";
import {DD_ACCEPT_ANY} from "../device-slots-assignment/device-slots-assignment.component";
import {MatSelectChange} from "@angular/material/select";
import {GridDragDropController, LoomaGridComponent} from "../looma-grid/looma-grid.component";
import {ChangeDetectorRef, Injectable} from "@angular/core";
import {CdkDropList} from "@angular/cdk/drag-drop";
import {BaseModel} from "@looma/shared/models/base_model";

export interface ItemCollection<T> {
    menuOptions?: string[]
    isNewCollection?: boolean
    key: string
    label?: string;
    items: T[]
    extras?: {}
    acceptedItemGroups?: string[] | 'any' // the other DeviceSlotCollection keys from which items can be moved to this DeviceSlotCollection
}

export interface ItemCollectionMenuOptionEvent<T> {
    collection: ItemCollection<T>
    originalEvent: MouseEvent
    option: string
}

export abstract class ItemGridController<T extends BaseModel> {
    defaultItemDataSource: ItemGridDataSource<T>
    itemDataSources: ItemGridDataSource<T>[] = [];

    private changeDetector: ChangeDetectorRef
    private dirtyCollectionsSubject = new BehaviorSubject<Set<ItemCollection<T>>>(new Set<ItemCollection<T>>());
    private menuOptionSelectedSubject = new Subject<ItemCollectionMenuOptionEvent<T>>()
    private invalidateConnectedDropListsSubject = new Subject<boolean>()

    constructor() {
        this.invalidateConnectedDropListsSubject.pipe(
            debounceTime(10)
        ).subscribe(value => {
            this.refreshConnectedDropLists()
        })
    }

    setup(changeDetector: ChangeDetectorRef, unassignedItems: ItemCollection<T>, groupItems: ItemCollection<T>[]) {

        this.defaultItemDataSource = this.initDataSource(unassignedItems, true)

        this.itemDataSources = groupItems.map(collection => {
            let ds = this.itemDataSources.find(ds => ds.dataSourceKey == collection.key);
            if (!ds) {
                ds = this.initDataSource(collection, false);
            }
            return ds
        });
        this.changeDetector = changeDetector
        this.changeDetector.detectChanges();
        this.invalidateConnectedDropLists()
    }

    abstract initDataSource(collection: ItemCollection<T>, isDefault: boolean): ItemGridDataSource<T>

    onMenuOptionSelected(): Observable<ItemCollectionMenuOptionEvent<T>> {
        return this.menuOptionSelectedSubject
    }

    performCollectionMenuOption(collection: ItemCollection<T>, option: string, ev: MouseEvent) {
        this.menuOptionSelectedSubject.next({
            collection: collection,
            option: option,
            originalEvent: ev
        })
    }

    isDefaultCollection(col: ItemCollection<T>): boolean {
        if (this.defaultItemDataSource) {
            return this.defaultItemDataSource.collection.key == col.key
        }
        return false
    }

    unassignSelectedItems(srcSource: ItemGridDataSource<T>) {
        const selected = srcSource.getSelectedItems();
        if (selected) {
            this.moveItems(srcSource, this.defaultItemDataSource, selected);
        }
    }

    moveItems(srcSource: ItemGridDataSource<T>, destSource: ItemGridDataSource<T>, items: T[]) {
        if (srcSource && destSource && (srcSource.dataSourceKey != destSource.dataSourceKey)) {
            if (!this.canMoveItems(items, srcSource, destSource)) {
                return
            }

            const removed = srcSource.removeItems(items);
            if (removed.length) {
                destSource.prependItems(removed);
                srcSource.selection.clear();
                destSource.selection.clear();

                const v = this.dirtyCollectionsSubject.value;
                let changed = false;
                for (const x of [srcSource, destSource]) {
                    if (x == this.defaultItemDataSource) {
                        continue
                    }
                    v.add(x.collection);
                    changed = true;
                }
                if (changed) {
                    this.dirtyCollectionsSubject.next(v);
                }
            }
        }
    }

    canMoveItems(items: T[], srcSource: ItemGridDataSource<T>, destSource: ItemGridDataSource<T>): boolean {
        console.log(srcSource.dataSourceKey)
        return true
    }

    onAssignmentChanged(): Observable<Set<ItemCollection<T>>> {
        return this.dirtyCollectionsSubject
    }

    hasCollection(col: ItemCollection<T>): boolean {
        if (!col) {
            return false
        }
        const existing = this.itemDataSources.find(value => value.dataSourceKey == col.key);
        return !!existing
    }

    getDatasourceForCollection(col: ItemCollection<T>): ItemGridDataSource<T> {
        return this.itemDataSources.find(value => value.dataSourceKey == col?.key);
    }

    addCollection(col: ItemCollection<T>): ItemGridDataSource<T> {
        if (!col) {
            return null
        }
        const existing = this.itemDataSources.find(value => value.dataSourceKey == col.key);
        if (existing) {
            return existing;
        }

        const ds = this.initDataSource(col, false)

        this.itemDataSources = [].concat(this.itemDataSources).concat([ds]);
        this.changeDetector?.detectChanges()
        this.invalidateConnectedDropListsSubject.next(true)
        return ds
    }

    getCollection(key: string): ItemCollection<T> {
        return this.itemDataSources.find(value => value.dataSourceKey == key).collection
    }

    getCollections(): ItemCollection<T>[] {
        return this.itemDataSources.map(value => value.collection)
    }

    removeCollection(col: ItemCollection<T> | string): boolean {
        if (!col) {
            return false
        }
        const obj = col as ItemCollection<T>;
        if (obj.key) {
            col = obj.key
        }
        if (Utils.isString(col)) {
            const removedDs = this.itemDataSources.find(ds => ds.dataSourceKey == col);
            if (removedDs) {
                this.itemDataSources.splice(this.itemDataSources.indexOf(removedDs), 1);
                this.moveItems(removedDs, this.defaultItemDataSource, removedDs.collection.items);
                this.invalidateConnectedDropListsSubject.next(true)
                return true
            }
        }
        return false
    }

    private refreshConnectedDropLists() {
        if (!this.defaultItemDataSource) {
            return
        }

        let allDataSources: ItemGridDataSource<T>[] = [this.defaultItemDataSource];
        allDataSources = allDataSources.concat(this.itemDataSources);

        const preparedDataSources = allDataSources.filter(value => !!value.attachedComponent);

        if (preparedDataSources.length < 2) {
            return
        }

        for (const srcDs of preparedDataSources) {

            const connectedDropLists = preparedDataSources.filter(destDs => {
                if (destDs == srcDs) {
                    return false
                }
                if (srcDs.acceptsItemsFrom(destDs)) {
                    return true
                }
            }).map(value => value.attachedComponent.dropList);

            srcDs.attachedComponent.dragDropController.setConnectedDropLists(connectedDropLists);
        }
    }

    invalidateConnectedDropLists() {
        this.invalidateConnectedDropListsSubject.next(true)
    }

    afterDatasourceViewInit(dataSource: DeviceSlotGridDataSource) {
        this.invalidateConnectedDropLists()
    }
}

class DDController<T extends BaseModel> extends GridDragDropController<T> {

    constructor(private controller: ItemGridController<T>, private dataSource: ItemGridDataSource<T>) {
        super();
    }


    init(grid: LoomaGridComponent<T>, dropList: CdkDropList<any>) {
        super.init(grid, dropList);
    }

    isDragEnabled(ds: ItemGridDataSource<T>, row: T): boolean {
        return true
    }

    onDropListDrop(src: T[], dest: CdkDropList<any>) {
        if (!dest) {
            return
        }
        if (this.controller.defaultItemDataSource.ddController.dropList == dest) {
            this.controller.moveItems(this.dataSource, this.controller.defaultItemDataSource, src)
        } else if (dest && src && src.length) {
            for (const v of this.controller.itemDataSources.values()) {
                if (v.ddController?.grid?.dataSource == v) {
                    if (v.ddController.dropList == dest) {
                        this.controller.moveItems(this.dataSource, v, src);
                        break
                    }
                }

            }
        }

    }
}

const ANY = 'Any';

export class ItemGridDataSource<T extends BaseModel> extends ModelListDataSource<T> {
    public detachedSub = new AsyncSubject();
    public ddController = new DDController(this.controller, this);

    constructor(public controller: ItemGridController<T>, public collection: ItemCollection<T>, columns: ModelColumnSpec<T>[]) {
        super({columns: columns})
        this.setSelectionEnabled(true);
        setTimeout(() => {
            this.setLocalData(collection.items);
        })
    }

    get dataSourceKey(): string {
        return this.collection.key;
    }

    get dataSourceLabel(): string {
        return this.collection.label;
    }

    get hasUnassignableItems(): boolean {
        return !this.controller.isDefaultCollection(this.collection)
    }

    acceptsItemsFrom(other: ItemGridDataSource<T>): boolean {
        if (!other) {
            return false
        }

        if (other.dataSourceKey == this.dataSourceKey) {
            return false
        }

        if (this.controller.isDefaultCollection(this.collection)) {
            return true
        }

        const acceptedItemGroups = this.collection.acceptedItemGroups || DD_ACCEPT_ANY;
        if (acceptedItemGroups == DD_ACCEPT_ANY) {
            return true
        }

        if (Array.isArray(acceptedItemGroups)) {
            return acceptedItemGroups.indexOf(other.dataSourceKey) >= 0
        }

        return false
    }

    removeItems(items: T[]): T[] {

        const removeIds = new Set<number>(items.map(value => value.getId()));

        const remove: T[] = [];
        const keep: T[] = [];
        for (const item of this.collection.items) {
            if (removeIds.has(item.getId())) {
                remove.push(item)
            } else {
                keep.push(item)
            }
        }

        if (remove.length) {
            this.setLocalData(keep);
        }

        return remove;
    }

    prependItems(items: T[]) {
        if (!Array.isArray(items)) {
            return
        }
        this.collection.items = items.concat(this.collection.items)
        this.setLocalData(this.collection.items);
    }

    addItems(items: T[]) {
        this.collection.items = this.collection.items.concat(items)
        this.setLocalData(this.collection.items);
    }

    getSelectedItems(): T[] {
        return this.selection.applySelectionToData(this.collection.items);
    }

    onViewDetached(view: LoomaGridComponent<any>): void {
        super.onViewDetached(view);
        this.detachedSub.next(true);
        this.detachedSub.complete();
    }

    setLocalData(items: T[]) {
        this.collection.items = [].concat(items);
        super.setLocalData(items);
    }
}

export class DeviceSlotGridDataSource extends ItemGridDataSource<DeviceSlot> {
    storesFormControl = new SearchFormControl<Store>();
    installedFormControl = new YesNoAnyFormControl();

    program: RetailerPromoProgram;

    availableMountTypes: string[] = [];
    availableFixtureTypes: string[] = [];
    availableDeviceSlotNumbers: string[] = [];
    availableCategories: string[] = [];
    availableKioskApps: string[] = [];
    availableRegions: string[] = [];

    extraFilters = {
        fixtureType: ANY,
        mountType: ANY,
        kioskApp: ANY,
        categoryName: ANY,
        deviceSlotNumber: ANY,
        region: ANY,
        program: ANY,
    };

    constructor(public controller: ItemGridController<DeviceSlot>, public collection: ItemCollection<DeviceSlot>) {
        super(controller, collection,
            [{
                label: 'Store',
                key: 'store_num',
                valueReader: (item: DeviceSlot) => {
                    return item.store.getPaddedStoreNum()
                }
            }, {
                label: 'Category',
                key: 'category_name',
                valueReader: (item: DeviceSlot) => {
                    return item.product_category?.category_name || 'N/A'
                }
            }, {
                label: 'Nr.',
                key: 'device_slot_counter',
                valueReader: (item: DeviceSlot) => {
                    return this.zeroPad(item.counter, 3)
                }
            }, {
                label: 'Region',
                key: 'region_name',
                width: '60px',
                valueReader: (item: DeviceSlot) => {
                    return item.store.retailer_region.region_name
                }
            }, {
                label: 'App',
                key: 'kiosk_app',
                alignContent: 'center',
                valueReader: (item: DeviceSlot) => {
                    return item.kioskDeviceApp?.app_name || '-'
                }
            }, {
                label: 'Installed',
                key: 'installed',
                width: '120px',
                valueReader: (item: DeviceSlot) => {
                    return item.is_installed ? 'Yes' : 'No'
                }
            }]
        );


        this.storesFormControl.onSelectionChanged().pipe(
            takeUntil(this.detachedSub)
        ).subscribe(value => {
            let fn: ModelFilterFunc<DeviceSlot> = null;
            if (value) {
                fn = slot => {
                    if (!slot.store) {
                        return false
                    }
                    return slot.store.getId() == value.getId()
                }
            }
            this.setLocalFilter('stores_filter', fn)
        });


        this.installedFormControl.valueChanges.pipe(
            takeUntil(this.detachedSub)
        ).subscribe(value => {
            let fn: ModelFilterFunc<DeviceSlot> = null;
            switch (value) {
                case YesNoAny.Yes:
                    fn = slot => {
                        return slot.is_installed;
                    };
                    break;
                case YesNoAny.No:
                    fn = slot => {
                        return !slot.is_installed;
                    };
                    break;
            }
            this.setLocalFilter('installed_filter', fn);
        });


    }

    zeroPad(num, places) {
        const zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    clearLocalFilters(): boolean {
        if (this.hasLocalFilters()) {
            this.installedFormControl.reset();
            this.storesFormControl.reset();
            super.clearLocalFilters();
            this.extraFilters.fixtureType = ANY;
            this.extraFilters.mountType = ANY;
            this.extraFilters.categoryName = ANY;
            this.extraFilters.region = ANY;
            return true
        }
        return false;
    }

    filterByFixtureType(ev: MatSelectChange) {
        const filterKey = 'fixture_type_filter';

        const sel = ev.value as string || ANY;

        if (sel == ANY) {
            this.removeLocalFilter(filterKey)
        } else {
            this.setLocalFilter(filterKey, data => {
                return this.stringifyProp(data.loop_placement) == sel;
            })
        }
        this.extraFilters.fixtureType = sel;
    }

    filterByKioskApp(ev: MatSelectChange) {
        const filterKey = 'kiosk_app';

        const sel = ev.value as string || ANY;

        if (sel == ANY) {
            this.removeLocalFilter(filterKey)
        } else {
            this.setLocalFilter(filterKey, data => {
                return this.stringifyProp(data.kioskDeviceApp?.app_name) == sel
            })
        }
        this.extraFilters.kioskApp = sel;
    }

    filterByMountType(ev: MatSelectChange) {
        const filterKey = 'mount_type_filter';

        const sel = ev.value as string || ANY;

        if (sel == ANY) {
            this.removeLocalFilter(filterKey)
        } else {
            this.setLocalFilter(filterKey, data => {
                return this.stringifyProp(data.mount) == sel
            })
        }
        this.extraFilters.mountType = sel;
    }

    filterByCategory(ev: MatSelectChange) {
        const filterKey = 'category_filter';

        const sel = ev.value as string || ANY;

        if (sel == ANY) {
            this.removeLocalFilter(filterKey)
        } else {
            this.setLocalFilter(filterKey, data => {
                return this.stringifyProp(data.product_category?.category_name) == sel
            })
        }
        this.extraFilters.categoryName = sel;
    }

    filterByDeviceSlotNumber(ev: MatSelectChange) {
        const filterKey = 'device-slot-number';

        const sel = ev.value as string || ANY;

        if (sel == ANY) {
            this.removeLocalFilter(filterKey)
        } else {
            this.setLocalFilter(filterKey, data => {
                return this.stringifyProp(this.zeroPad(data.counter, 3)) == sel
            })
        }
        this.extraFilters.deviceSlotNumber = sel;
    }

    filterByRegion(ev: MatSelectChange) {
        const filterKey = 'region';

        const sel = ev.value as string || ANY;

        if (sel == ANY) {
            this.removeLocalFilter(filterKey)
        } else {
            this.setLocalFilter(filterKey, data => {
                return this.stringifyProp(data.store.retailer_region?.region_name) == sel
            })
        }
        this.extraFilters.region = sel;
    }

    filterByProgram(ev: MatSelectChange, availablePrograms: RetailerPromoProgram[]) {
        const filterKey = 'program';

        const sel = ev.value as string || ANY;

        const slots = availablePrograms.find(value => value.id + '' == sel)?.assignedDeviceSlotIds
        if (!slots) {
            this.removeLocalFilter(filterKey)
        } else {
            this.setLocalFilter(filterKey, data => {
                return slots.includes(data.id + '')
            })
        }
        this.extraFilters.program = sel;
    }

    private stringifyProp(value: string) {
        return (value || '').toLowerCase().trim()
    }

    onViewDetached(view: LoomaGridComponent<any>): void {
        super.onViewDetached(view);
        this.storesFormControl.destroy();
    }

    getSelectedItems(): DeviceSlot[] {
        return this.selection.applySelectionToData(this.collection.items);
    }

    setLocalData(items: DeviceSlot[]): void {
        items = items.sort((a, b) => {
            const aStoreNum = a?.store?.retailer_store_num || 1000
            const bStoreNum = b?.store?.retailer_store_num || 1000
            if (aStoreNum == bStoreNum) {
                return 0
            }
            if (aStoreNum < bStoreNum) {
                return -1
            }
            return 1;
        })

        this.collection.items = [].concat(items);

        const stores: Store[] = [];
        const storeIds = new Set<any>();

        const availableMountTypes = new Set<string>();
        const availableFixtureTypes = new Set<string>();
        const availableCategories = new Set<string>();
        const availableKioskApps = new Set<string>();
        const availableDeviceSlotNumbers = new Set<string>();
        const availableRegions = new Set<string>();

        const makeFilterOptions = (s: Set<string>): string[] => {
            const options = Array.from(s).sort();
            options.unshift(ANY);
            return options;
        }


        for (const deviceSlot of this.collection.items) {
            if (!deviceSlot.store) {
                continue
            }

            const storeId = deviceSlot.store.getId()

            if (!storeIds.has(storeId)) {
                storeIds.add(storeId);
                stores.push(deviceSlot.store);
            }

            const mountType = this.stringifyProp(deviceSlot.mount);
            if (mountType != '') {
                availableMountTypes.add(mountType);
            }

            const fixtureType = this.stringifyProp(deviceSlot.loop_placement);
            if (fixtureType != '') {
                availableFixtureTypes.add(fixtureType)
            }
            const catType = this.stringifyProp(deviceSlot.product_category?.category_name);
            if (catType != '') {
                availableCategories.add(catType)
            }

            const deviceSlotNumber = this.stringifyProp(this.zeroPad(deviceSlot.counter, 3))
            if (deviceSlotNumber != '') {
                availableDeviceSlotNumbers.add(deviceSlotNumber)
            }

            const region = this.stringifyProp(deviceSlot.store?.retailer_region?.region_name);
            if (region != '') {
                availableRegions.add(region)
            }

            const appName = this.stringifyProp(deviceSlot.kioskDeviceApp?.app_name);
            if (appName != '') {
                availableKioskApps.add(appName);
            }

        }

        this.storesFormControl.setData(stores)

        this.availableMountTypes = makeFilterOptions(availableMountTypes);
        this.availableFixtureTypes = makeFilterOptions(availableFixtureTypes);
        this.availableCategories = makeFilterOptions(availableCategories);
        this.availableKioskApps = makeFilterOptions(availableKioskApps);
        this.availableRegions = makeFilterOptions(availableRegions);
        this.availableDeviceSlotNumbers = makeFilterOptions(availableDeviceSlotNumbers);
        super.setLocalData(this.collection.items);

    }
}


@Injectable()
export class DeviceSlotGridController extends ItemGridController<DeviceSlot> {
    initDataSource(collection: ItemCollection<DeviceSlot>, isDefault: boolean): ItemGridDataSource<DeviceSlot> {
        if (isDefault) {
            return new DeviceSlotGridDataSource(this, {
                label: collection.label,
                key: collection.key,
                items: [].concat(collection.items),
                acceptedItemGroups: DD_ACCEPT_ANY
            });
        }
        return new DeviceSlotGridDataSource(this, collection)
    }
}
