import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import gql from "graphql-tag";
import {MUTATION_RESPONSE_FIELDS} from "../../../../services/queries";
import {
    ItemCollection,
    ItemGridController,
    DeviceSlotGridDataSource, DeviceSlotGridController
} from "../../../../layout/components/device-slot-assignment-list/device_slot_assignment_controller";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {RetailerPromoSchedule} from "@looma/shared/models/retailer_promo_schedule";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {filter, switchMap, takeUntil} from "rxjs/operators";
import {getSelectionOption, Utils} from "@looma/shared/utils";
import {DD_ACCEPT_ANY} from "../../../../layout/components/device-slots-assignment/device-slots-assignment.component";
import {
    PromoPeriodDeviceSlotSubgroup,
    PromoPeriodDeviceSlotSubgroupMutationInput
} from "@looma/shared/models/promo_period_device_slot_subgroup";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {PromptDialogData} from "../../../../layout/components/prompt-dialog/prompt-dialog.component";
import {MatSelectionListChange} from "@angular/material/list";
import {PromoPlaylistAssignment} from "@looma/shared/models/promo_playlist_assignment";
import {
    EvaluatedPromoPlaylistScheduleEntry,
    PromoPlaylistScheduleEntry,
    PromoPlaylistScheduleEntryMutationInput
} from "@looma/shared/models/promo_playlist_schedule_entry";
import {moveItemInArray} from "@angular/cdk/drag-drop";
import {
    PlaylistScheduleEntryDialogComponent
} from "../playlist-schedule-entry-dialog/playlist-schedule-entry-dialog.component";
import {ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {DeviceSlot} from "@looma/shared/models/device_slot";

const MenuOptionDelete = 'Delete'
const MenuOptionEdit = 'Edit'
const SubgroupMenuOptions = [MenuOptionEdit, MenuOptionDelete]

const DefaultSubgroupCollectionKey = 'DEFAULT'

@LifecycleHooks()
@Component({
    selector: 'app-promo-subgroup-playlist-schedule',
    templateUrl: './promo-subgroup-playlist-schedule.component.html',
    styleUrls: ['./promo-subgroup-playlist-schedule.component.scss'],
    providers: [{provide: DeviceSlotGridController, multi: false, useClass: DeviceSlotGridController}],
})
export class PromoSubgroupPlaylistScheduleComponent implements OnInit {

    constructor(
        private activatedRoute: ActivatedRoute,
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private changeDetector: ChangeDetectorRef,
        @Inject(DeviceSlotGridController) public controller: DeviceSlotGridController,
        private domSanitizer: DomSanitizer,
    ) {
    }

    get hasDirtyPlaylistSchedules(): boolean {
        for (const x of this.slotEntries) {
            if (x.hasDirtyPlaylistSchedules) {
                return true
            }
        }
        return false
    }

    get hasDirtySlotAssignments(): boolean {
        if (this.deletedSlotEntries.length) {
            return true
        }
        for (const x of this.slotEntries) {
            if (x.hasDirtySlots) {
                return true
            }
        }
        return false
    }


    get isBusy(): boolean {
        return !Utils.isUnsubscribed(this.activeSubscription)
    }

    public evaluatedPlaylistScheduleDataSource = new ModelListDataSource<EvaluatedPromoPlaylistScheduleEntry>({
        columns: [
            {
                key: 'playlist',
                label: 'Playlist',
                valueReader: (item: EvaluatedPromoPlaylistScheduleEntry) => {
                    return item.playlistScheduleEntry?.playlistAssignment?.name
                }
            }, {
                key: 'activate_at',
                label: 'Activate At',
                valueReader: (item: EvaluatedPromoPlaylistScheduleEntry) => {
                    return Utils.formatDate(item.activateAt, 'E, MMM d yyyy HH:mm')
                }
            }
        ]
    })

    data: LoadedData
    pageTitle = 'Device Slot SubGroups'
    scheduleEvalResultTitle: SafeHtml
    pageSubtitle = ''
    private promoPeriod: RetailerPromoPeriod
    private defaultPlaylist: PromoPlaylistAssignment
    private subgroupAssignment: SubgroupAssignment

    private activeSubscription: Subscription

    private deletedSlotEntries: EditableSlotEntry[] = []

    slotEntries: EditableSlotEntry[] = []

    activeSlotEntryChangedSubject = new BehaviorSubject<EditableSlotEntry>(null)

    private evaluatePlaylistSchedulesSubscription: Subscription

    static open(router: Router, schedule: RetailerPromoSchedule, promoPeriod: RetailerPromoPeriod, segment: DeviceSlotSegment) {
        router.navigate([`promo-periods/${schedule.id}-${promoPeriod.id}/promo-subgroup-playlist-schedule/${segment.id}`])
    }

    get activeSlotEntry(): EditableSlotEntry {
        return this.activeSlotEntryChangedSubject.value
    }

    private setActiveSlotEntry(e: EditableSlotEntry) {
        this.activeSlotEntryChangedSubject.next(e)
        this.updatePageSubtitle()
    }

    onOptionSelected(ev: MatSelectionListChange) {
        const entry = getSelectionOption(ev)?.value as EditableSlotEntry
        if (this.slotEntries.indexOf(entry) >= 0) {
            this.setActiveSlotEntry(entry)
        }
        this.controller.invalidateConnectedDropLists()
    }

    isActiveSelection(slotEntry: EditableSlotEntry): boolean {
        return this.activeSlotEntry == slotEntry
    }

    ngOnInit(): void {
        const promoPeriodId = this.activatedRoute.snapshot.paramMap.get('promoPeriodId') || '';
        const segmentId = this.activatedRoute.snapshot.paramMap.get('segmentId') || '';
        const promoPeriodIdChunks = promoPeriodId.split('-');

        if (promoPeriodIdChunks.length == 2) {

            this.subgroupAssignment = {
                promoScheduleId: parseInt(promoPeriodIdChunks[0]),
                promoPeriodId: parseInt(promoPeriodIdChunks[1]),
                deviceSlotSegmentId: parseInt(segmentId),
            }

            this.controller.onAssignmentChanged().pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                this.handleAssignmentChanged(value)
            })

            this.loadSubgroups()

            this.activeSlotEntryChangedSubject.pipe(
                filter(value => !!value),
                switchMap(value => value.onChanged()),
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                this.evaluatePlaylistSchedules()
            })

        }


    }

    private loadSubgroups() {
        if (!this.subgroupAssignment) {
            return
        }

        this.activeSubscription = this.svcApi.rawQueryNoCache({
            query: QUERY_FETCH_DATA,
            variables: {
                promoPeriodId: this.subgroupAssignment.promoPeriodId,
                deviceSlotSegmentId: this.subgroupAssignment.deviceSlotSegmentId,

            },
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            const promoPeriod = Utils.getNestedTypedObject(value.data, RetailerPromoPeriod, 'promoPeriod')
            if (promoPeriod && !promoPeriod.isNewRecord()) {
                const segment = Array.isArray(promoPeriod.deviceSlotSegments) ? promoPeriod.deviceSlotSegments[0] : null;
                if (segment && !segment.isNewRecord()) {
                    this.onDataAvailable(promoPeriod, segment)
                }

            }
        })
    }

    saveChanges() {
        if (this.isBusy) {
            return
        }
        if (this.hasDirtySlotAssignments) {
            this.saveSlotAssignment()
        } else if (this.hasDirtyPlaylistSchedules) {
            this.savePlaylistSchedules()
        }
    }

    get isEvaluatingPlaylistSchedule() {
        return !Utils.isUnsubscribed(this.evaluatePlaylistSchedulesSubscription)
    }

    evaluatePlaylistSchedules() {
        Utils.unsubscribe(this.evaluatePlaylistSchedulesSubscription)

        if (this.activeSlotEntry.subgroup.isNewRecord()) {
            this.evaluatedPlaylistScheduleDataSource.setLocalData([])
            return
        }

        const mutationData = this.activeSlotEntry.getPlaylistScheduleMutationDataForEvaluate()
        this.evaluatePlaylistSchedulesSubscription = this.svcApi.evaluatePlaylistScheduledEntries(this.activeSlotEntry.subgroup.id, mutationData).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            let data: EvaluatedPromoPlaylistScheduleEntry[] = []
            if (value.success) {
                data = value.dataArray
            } else {
                this.svcLayout.showMutationResultMessage(value)
            }
            this.evaluatedPlaylistScheduleDataSource.setLocalData(data)
        }, error => {
            console.error(error)
            this.svcLayout.showSnackMessage('Could not load schedule evaluation results')
        })
    }

    private savePlaylistSchedules() {

        const mutationData = this.activeSlotEntry.getPlaylistScheduleMutationData()
        if (mutationData) {
            this.activeSubscription = this.svcApi.mutatePlaylistScheduledEntries(this.activeSlotEntry.subgroup.id, mutationData, SCHEDULE_ENTRY_GQL_FIELDS).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(mutationResp => {
                if (!mutationResp.success) {
                    this.svcLayout.showMutationResultMessage(mutationResp)
                } else {
                    this.svcLayout.showMessage("Playlist schedules Saved")
                    this.activeSlotEntry.handlePlaylistScheduleMutationResult(mutationResp.dataArray)
                    this.deletedSlotEntries.length = 0;
                }
            })
        }
    }

    private saveSlotAssignment() {
        const mutationData: Partial<PromoPeriodDeviceSlotSubgroupMutationInput>[] = [];

        const allEntries = [].concat(this.slotEntries).concat(this.deletedSlotEntries) as EditableSlotEntry[]

        for (const entry of allEntries) {
            const isDeleted = this.deletedSlotEntries.indexOf(entry) >= 0;

            if (isDeleted && entry.subgroup.isNewRecord()) {
                continue
            }

            const mutationEntry: Partial<PromoPeriodDeviceSlotSubgroupMutationInput> = {
                deviceSlotIds: entry.slotDataSource.getLocalData().map(value => value.id),
                name: entry.subgroup.name,
                id: entry.subgroup.id
            }

            if (isDeleted) {
                mutationEntry.op = MutationOperation.Delete
            } else if (entry.subgroup.isNewRecord()) {
                mutationEntry.op = MutationOperation.Create
            } else {
                mutationEntry.op = MutationOperation.Update
            }

            mutationData.push(mutationEntry)
        }

        const needsRefresh = this.activeSlotEntry?.subgroup?.isNewRecord() || false

        this.activeSubscription = this.svcApi.rawObjectMutate(MUTATION_MUTATE_SUBGROUPS, {
            promoPeriodId: this.data.promoPeriod.id,
            deviceSlotSegmentId: this.data.segment.id,
            data: mutationData
        }, PromoPeriodDeviceSlotSubgroup).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(mutationResp => {

            if (!mutationResp.success) {
                this.svcLayout.showMutationResultMessage(mutationResp)
            } else {

                if (mutationResp.dataArray?.length != this.slotEntries.length) {
                    this.svcLayout.showMessage('An unexpected error has occurred. Please refresh the page', 10000)
                    return
                }

                mutationResp.dataArray.forEach((value, index) => {
                    this.slotEntries[index].setSubgroupAfterSave(value)
                })

                this.svcLayout.showMessage("Subgroups Saved")
                this.deletedSlotEntries.length = 0;
                this.slotEntries = [].concat(this.slotEntries)

                if (needsRefresh) {
                    this.evaluatePlaylistSchedules()
                }
            }
        })

    }

    handleMenuOptionForCurrentEntry(option: 'edit' | 'delete') {
        const slotEntry = this.activeSlotEntry
        if (!slotEntry) {
            return
        }
        switch (option) {
            case 'edit':
                this.editSlotSubgroup(slotEntry.subgroup, slotEntry)
                break
            case 'delete':
                this.svcLayout.onConfirmed('Delete subgroup', `Are you sure you want to delete ${slotEntry.subgroup.name} subgroup?`).pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe(value => {
                    if (this.controller.removeCollection(slotEntry.key)) {
                        if (!slotEntry.subgroup.isNewRecord()) {
                            this.deletedSlotEntries.push(slotEntry)
                        }
                        const prevIndex = this.slotEntries.indexOf(slotEntry)

                        this.slotEntries.splice(prevIndex, 1)
                        this.slotEntries = [].concat(this.slotEntries)
                        const newIdx = Math.max(prevIndex - 1, 0)
                        if (newIdx < this.slotEntries.length) {
                            this.setActiveSlotEntry(this.slotEntries[newIdx])
                        } else {
                            this.setActiveSlotEntry(null)
                        }
                    }
                })
                break
        }
    }

    handleAssignmentChanged(collections: Set<ItemCollection<DeviceSlot>>) {
        if (collections.size == 0) {
            return
        }
        for (const col of collections) {
            const entry = this.getSlotEntry(col)
            if (entry) {
                entry.hasDirtySlots = true
            }
        }
    }

    private updatePageSubtitle() {
        let subtitle = ''
        const data = this.data
        if (data) {
            subtitle = `${data.promoPeriod.name} - ${data.segment.name}`
            if (this.activeSlotEntry) {
                subtitle += ` - Subgroup ${this.activeSlotEntry.subgroup.name}`
            }
        }
        this.pageSubtitle = subtitle
    }

    private onDataAvailable(promoPeriod: RetailerPromoPeriod, deviceSlotSegment: DeviceSlotSegment) {
        this.promoPeriod = promoPeriod;
        this.data = {
            promoPeriod: promoPeriod,
            segment: deviceSlotSegment
        }

        this.defaultPlaylist = deviceSlotSegment.playlistAssignments.find(value => value.isDefault)
        this.slotEntries.length = 0;
        this.updatePageSubtitle()
        let defaultSlotCollection: ItemCollection<DeviceSlot> = null
        const childSubgroups: PromoPeriodDeviceSlotSubgroup[] = []
        const pattern = 'E, MMM d yyyy'
        this.scheduleEvalResultTitle = this.domSanitizer.bypassSecurityTrustHtml(`
Evaluation results<br/>  
<span class="small_hint">between ${Utils.formatDate(promoPeriod.startDate, pattern)}</span> - <span class="small_hint">${Utils.formatDate(promoPeriod.endDate, pattern)}</span>`)

        for (const subgroup of deviceSlotSegment.deviceSlotSubgroups) {
            if (subgroup.isDefault) {
                defaultSlotCollection = {
                    items: subgroup.deviceSlots,
                    key: DefaultSubgroupCollectionKey,
                    label: 'Available Device Slots',
                    acceptedItemGroups: DD_ACCEPT_ANY
                }
            } else {
                childSubgroups.push(subgroup)
            }
        }


        if (defaultSlotCollection) {

            this.controller.setup(this.changeDetector, defaultSlotCollection, [])

            for (const subgroup of childSubgroups) {
                this.registerSubgroup(subgroup)
            }

            if (this.slotEntries.length > 0) {
                this.setActiveSlotEntry(this.slotEntries[0])
            }

        }


    }

    addNewPlaylistScheduleEntry() {
        if (!this.activeSlotEntry) {
            return
        }
        const rec = new PromoPlaylistScheduleEntry()
        const subgroup = this.activeSlotEntry.subgroup
        rec.priority = 1000;
        rec.deviceSlotTypeSubgroup = subgroup
        if (this.activeSlotEntry.defaultPlaylist) {
            rec.playlistAssignment = this.activeSlotEntry.defaultPlaylist
        }
        this.editPlaylistScheduleEntry(rec, true)
    }

    deletePlaylistScheduleEntry(schEntry: PromoPlaylistScheduleEntry) {
        this.svcLayout.onConfirmed('Delete schedule entry', 'Are you sure you want to delete this schedule entry?').pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.activeSlotEntry.removePlaylistScheduleEntry(schEntry)
        })
    }

    editPlaylistScheduleEntry(schEntry: PromoPlaylistScheduleEntry, isNewRecord = false) {

        PlaylistScheduleEntryDialogComponent.open(
            this.svcLayout,
            {
                gqlFields: SCHEDULE_ENTRY_GQL_FIELDS,
                availablePlaylistAssignments: this.data.segment.playlistAssignments,
                promoPeriod: this.promoPeriod,
                schEntry: schEntry,
                deviceSlotTypeSubgroupId: schEntry.deviceSlotTypeSubgroup.id,
                isNewRecord: isNewRecord
            }
        ).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (!value) {
                return
            }
            this.activeSlotEntry.replacePlaylistScheduleEntry(schEntry, value)
        })
    }

    addNewSubgroup() {
        const subgroup = new PromoPeriodDeviceSlotSubgroup()
        subgroup.promoPlaylistScheduledEntries = []
        subgroup.deviceSlots = []
        this.editSlotSubgroup(subgroup, null)
    }

    private registerSubgroup(subgroup: PromoPeriodDeviceSlotSubgroup): EditableSlotEntry {

        const key = Utils.generateUniqueId()
        if (!Array.isArray(subgroup.deviceSlots)) {
            subgroup.deviceSlots = []
        }

        const ds = this.controller.addCollection({
            items: subgroup.deviceSlots,
            key: key,
            acceptedItemGroups: DD_ACCEPT_ANY,
        }) as DeviceSlotGridDataSource

        const entry = new EditableSlotEntry(subgroup, key, this.defaultPlaylist, ds)

        this.slotEntries.push(entry)


        return entry
    }

    private editSlotSubgroup(subgroup: PromoPeriodDeviceSlotSubgroup, ownerEntry: EditableSlotEntry) {
        const isEdit = !subgroup.isNewRecord()
        const dialogData: Partial<PromptDialogData> = {
            title: isEdit ? 'Edit SubGroup' : 'New Subgroup',
            message: 'Subgroup Name',
            initialText: subgroup.name
        }

        this.svcLayout.prompt(dialogData).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (!ownerEntry) {
                ownerEntry = this.registerSubgroup(subgroup)
                this.setActiveSlotEntry(ownerEntry)
                this.controller.invalidateConnectedDropLists()
            }
            if (ownerEntry.setLabel(value)) {
                this.controller.getCollection(ownerEntry.key).label = value
            }
        })
    }

    private getSlotEntry(collection: ItemCollection<DeviceSlot>): EditableSlotEntry {
        return this.slotEntries.find(value => value.key == collection.key)
    }
}


class EditableSlotEntry {
    public hasDirtySlots = false
    private _hasReorderedSchedules = false
    public playlistScheduleEntries: PromoPlaylistScheduleEntry[] = []
    private deletedPlaylistEntries: PromoPlaylistScheduleEntry[] = []

    private onChangedSubject = new BehaviorSubject<EditableSlotEntry>(this)

    get hasDirtyPlaylistSchedules(): boolean {
        return this._hasReorderedSchedules || (this.deletedPlaylistEntries.length > 0)
    }

    constructor(
        public subgroup: PromoPeriodDeviceSlotSubgroup,
        public key: string,
        public defaultPlaylist: PromoPlaylistAssignment,
        public slotDataSource: DeviceSlotGridDataSource
    ) {
        if (defaultPlaylist) {
            this.playlistScheduleEntries = [].concat(subgroup.promoPlaylistScheduledEntries)
        }
    }

    onChanged(): Observable<EditableSlotEntry> {
        return this.onChangedSubject
    }

    setSubgroupAfterSave(subgroup: PromoPeriodDeviceSlotSubgroup) {
        this.subgroup = subgroup
        this._hasReorderedSchedules = false
        this.hasDirtySlots = false
    }

    setLabel(label: string): boolean {
        label = label.trim()
        if (label == '') {
            return false
        }
        if (this.subgroup.name != label) {
            this.subgroup.name = label
            this.hasDirtySlots = true
            return true
        }
        return false
    }

    replacePlaylistScheduleEntry(prev: PromoPlaylistScheduleEntry, current: PromoPlaylistScheduleEntry) {
        let changed = false
        if (prev.isNewRecord()) {
            this.playlistScheduleEntries.push(current)
            changed = true
        } else {
            const idx = this.findItemPosition(current)
            if (idx >= 0) {
                this.playlistScheduleEntries[idx] = current
                changed = true
            }
        }

        if (changed) {
            this.playlistScheduleEntries = this.playlistScheduleEntries.sort((a, b) => a.priority - b.priority)
            this.notifyChanged()
        }
    }

    changePlaylistItemPosition(fromIndex: number, toIndex: number) {
        if (fromIndex != toIndex) {
            moveItemInArray(this.playlistScheduleEntries, fromIndex, toIndex);
            this._hasReorderedSchedules = true
            this.notifyChanged()
        }
    }

    removePlaylistScheduleEntry(schEntry: PromoPlaylistScheduleEntry) {
        const idx = this.findItemPosition(schEntry)
        if (idx >= 0) {
            this.playlistScheduleEntries.splice(idx, 1)
            this.playlistScheduleEntries = [].concat(this.playlistScheduleEntries)
            this.deletedPlaylistEntries.push(schEntry)
            this.notifyChanged()
        }
    }

    private findItemPosition(schEntry: PromoPlaylistScheduleEntry): number {
        return this.playlistScheduleEntries.findIndex(value => value.id == schEntry?.id)
    }

    getPlaylistScheduleMutationData(): Partial<PromoPlaylistScheduleEntryMutationInput>[] {
        const mutationData: Partial<PromoPlaylistScheduleEntryMutationInput>[] = []
        for (const entry of this.deletedPlaylistEntries) {
            const d = this.getScheduledEntryBaseMutationData(entry)
            d.op = MutationOperation.Delete
            mutationData.push(d)
        }
        if (this._hasReorderedSchedules) {
            this.playlistScheduleEntries.forEach((entry, index) => {
                const d = this.getScheduledEntryBaseMutationData(entry)
                d.priority = index + 1
                d.op = MutationOperation.Update

                if (entry.startTime) {

                    d.startTime = Utils.makeUtcDateString(entry.startTime)
                }

                if (entry.endTime) {
                    d.endTime = Utils.makeUtcDateString(entry.endTime)
                }

                mutationData.push(d)
            })
        }

        return mutationData
    }

    getPlaylistScheduleMutationDataForEvaluate(): Partial<PromoPlaylistScheduleEntryMutationInput>[] {
        return this.playlistScheduleEntries.map(value => {
            return this.getScheduledEntryBaseMutationData(value)
        })
    }

    private getScheduledEntryBaseMutationData(schEntry: PromoPlaylistScheduleEntry): Partial<PromoPlaylistScheduleEntryMutationInput> {
        return {
            op: MutationOperation.None,
            cronExpression: schEntry.cronExpression,
            id: schEntry.id || Utils.generateUniqueId(),
            playlistAssignmentId: schEntry.playlistAssignment.id,
            priority: schEntry.priority,
        }
    }

    handlePlaylistScheduleMutationResult(items: PromoPlaylistScheduleEntry[]) {
        let reassign = false
        for (const item of items) {
            const deletedIdx = this.deletedPlaylistEntries.findIndex(value => value.id == item.id)
            if (deletedIdx >= 0) {
                this.deletedPlaylistEntries.splice(deletedIdx, 1)
            } else {
                const idx = this.playlistScheduleEntries.findIndex(value => value.id == item.id)
                if (idx >= 0) {
                    this.playlistScheduleEntries[idx] = item
                } else {
                    this.playlistScheduleEntries.push(item)
                }
                reassign = true
            }
        }
        if (this.deletedPlaylistEntries.length) {
            console.error('still have items that are marked as deleted')
        }

        if (reassign) {
            this.playlistScheduleEntries = [].concat(this.playlistScheduleEntries)
            this._hasReorderedSchedules = false
        }
        this.notifyChanged()
    }

    private notifyChanged() {
        this.onChangedSubject.next(this)
    }
}

interface SubgroupAssignment {
    promoScheduleId: number,
    promoPeriodId: number,
    deviceSlotSegmentId: number,
}


const SCHEDULE_ENTRY_GQL_FIELDS = `
              id
              startTime
              endTime
              deviceSlotTypeSubgroup{
                id
                name
              }
              playlistAssignment{
                id
                name
              }
              cronExpression
              priority
`

const DEVICE_SLOT_SUBGROUP_GQL_FIELDS = `
            id
            name
            isDefault
            promoPlaylistScheduledEntries {
              id
              startTime
              endTime
              cronExpression
              priority
              playlistAssignment {
                id
                name
              }
            }
            promoPlaylistScheduledEntries{
${SCHEDULE_ENTRY_GQL_FIELDS}
            }
            deviceSlots {
              id
              name
              device_installed_at
              device_number
              looma_phase
              mount
              loop_placement
              product_category {
                id
                category_name
              }
              store {
                id
                retailer_store_num
                retailer_region {
                  id
                  region_name
                }
              }
            }
`;

const QUERY_FETCH_DATA = gql`
    query fetchData($promoPeriodId: ID!, $deviceSlotSegmentId: ID!) {
        promoPeriod(id: $promoPeriodId) {
            id
            name
            status
            startDate
            endDate
            deviceSlotSegments(filter: { id: $deviceSlotSegmentId }) {
                id
                name
                playlistAssignments {
                    id
                    name
                    isDefault
                }
                deviceSlotSubgroups {
                    ${DEVICE_SLOT_SUBGROUP_GQL_FIELDS}
                }
            }
        }
    }
`
const MUTATION_MUTATE_SUBGROUPS = gql`
    mutation mutatePromoPeriodDeviceSlotSubgroups(
        $promoPeriodId: ID!
        $deviceSlotSegmentId: ID!
        $data: [PromoPeriodDeviceSlotSubgroupMutationInput!]!
    ) {
        response: mutatePromoPeriodDeviceSlotSubgroups(
            promoPeriodId: $promoPeriodId
            deviceSlotSegmentId: $deviceSlotSegmentId
            data: $data
        ) {
            ${MUTATION_RESPONSE_FIELDS}
            promoPeriodDeviceSlotSubgroups{
                ${DEVICE_SLOT_SUBGROUP_GQL_FIELDS}
            }
        }
    }
`


interface LoadedData {
    promoPeriod: RetailerPromoPeriod
    segment: DeviceSlotSegment
}