import {fabric} from "fabric";
import {deepMerge, Rect, Utils} from "@looma/shared/utils";
import {IObjectOptions} from "fabric/fabric-impl";
import {TemplateComponent} from "./template_component";
import {TextStyleAttrs} from "./types";
import {from, Observable, of} from "rxjs";
import {map} from "rxjs/operators";

export class TemplateTextComponent extends TemplateComponent {

    fabricObject: fabric.Textbox
    text = ''
    private readonly style: TextStyleAttrs


    constructor(private canvas: fabric.Canvas, private bounds: Rect, style: TextStyleAttrs, defaultObjectOptions: IObjectOptions = {}) {
        super()
        this.fabricObject = new fabric.Textbox('', {
            ...defaultObjectOptions,
            left: bounds.left,
            top: bounds.top,
        })
        this.style = {}
        deepMerge(this.style, style)
        
        canvas.add(this.fabricObject)
    }

    setStyle(others: TextStyleAttrs) {
        deepMerge(this.style, others)
        this.requestRefresh()
    }

    setText(newText: string) {
        this.text = newText.replace(/\s*\n\s*/g, '\n')
        this.requestRefresh()
    }

    draw() {
        this.processSub = loadExternalFont(this.style).subscribe(textStyle => {
            const bounds = this.bounds
            const textRect = {
                left: bounds.left,
                top: bounds.top,
                width: bounds.width,
                height: bounds.height,
            }

            const opts: fabric.TextOptions = {
                ...textRect,
                fontFamily: textStyle.fontFamily,
                fontSize: textStyle.fontSize,
                textAlign: textStyle.textAlign || 'center',
                fill: textStyle.color || 'red',
                lineHeight: textStyle.lineHeight || 1,
                fontStyle: textStyle.fontStyle || 'normal',
                fontWeight: textStyle.fontWeight,
                text: this.text
            }

            this.fabricObject.set(opts)

            const numLines = this.text.split('\n').length
            let textWidth = 0
            const textHeight = this.fabricObject.calcTextHeight()
            for (let numLine = 0; numLine < numLines; numLine += 1) {
                textWidth = Math.max(textWidth, this.fabricObject.getLineWidth(numLine))
            }

            this.fabricObject.set({
                left: bounds.left ,
                top: bounds.top + (bounds.height - textHeight) / 2,
            })

            this.canvas.requestRenderAll()
            this.setBusy(false)
        })
    }

}


function stringifyTextStyle(style: TextStyleAttrs) {
    const attrs = []

    if (style?.fontSize) {
        let size: any = style.fontSize
        if (Utils.isNumber(size)) {
            size = `${size}px`
        }
        attrs.push(size)
    }
    if (style?.fontStyle) {
        attrs.push(style.fontStyle)
    }
    if (style?.fontWeight) {
        attrs.push(style.fontWeight)
    }
    if (style.fontFamily) {
        attrs.push(`"${style.fontFamily}"`)
    }
    return attrs.join(' ')
}


function loadExternalFont(props: TextStyleAttrs): Observable<TextStyleAttrs> {
    if (props.fontUrl && !props.fontFamily) {
        return loadFont(props.fontUrl).pipe(
            map(value => {
                props.fontFamily = value.family
                return props
            })
        )
    }
    return of(props)
}


const loadedFonts = new Map<string, FontFace>()

function loadFont(url: string, name = Utils.generateUniqueId()): Observable<FontFace> {

    const existing = loadedFonts.get(url)
    if (existing) {
        return of(existing)
    }

    const fontFace = new FontFace(name, `url(${url})`);

    const p = fontFace.load().then(loadedFace => {
        document.fonts.add(loadedFace)
        loadedFonts.set(url, loadedFace)
        return loadedFace
    })

    return from(p)
}
