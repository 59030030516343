import {ChangeDetectorRef, Component, Inject, Injectable, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {exportToCsv, Utils} from "@looma/shared/utils";
import {
    DeviceSlotGridController,
    DeviceSlotGridDataSource,
    ItemCollection,
    ItemGridController
} from "../../../../layout/components/device-slot-assignment-list/device_slot_assignment_controller";
import {DD_ACCEPT_ANY} from "../../../../layout/components/device-slots-assignment/device-slots-assignment.component";
import {Observable, Subscription} from "rxjs";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {map, takeUntil} from "rxjs/operators";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import gql from "graphql-tag";
import {DeviceSlotSegmentAssignmentLog} from "@looma/shared/models/device_slot_segment_assignment_log";
import {ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {RowActionRegistry} from "../../../../shared/row_action_registry";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";

@Injectable()
class AssignmentController extends DeviceSlotGridController {

}

@LifecycleHooks()
@Component({
    selector: 'app-segment-device-slot-assignment',
    templateUrl: './segment-device-slot-assignment.component.html',
    styleUrls: ['./segment-device-slot-assignment.component.scss'],
    providers: [{provide: ItemGridController, multi: false, useClass: AssignmentController}],
})
export class SegmentDeviceSlotAssignmentComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        public location: Location,
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private changeDetector: ChangeDetectorRef,
        @Inject(ItemGridController) public controller: AssignmentController
    ) {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            this.entryParams = {
                promoPeriodId: id,
            }
        }
        if (!this.entryParams) {
            this.location.back()
        }
    }

    get isBusy(): boolean {
        return !Utils.isUnsubscribed(this.saveSubscription);
    }

    pageTitle = 'Slot Type Assignment'

    entryParams: { promoPeriodId: string } = null;
    dirtyAssignments = new Set<SegmentDeviceSlotItemCollection>();
    availablePrograms: RetailerPromoProgram[]

    deviceSlotSegmentAssignmentLogActions = new RowActionRegistry<DeviceSlotSegmentAssignmentLog>([
        {
            key: 'download',
            label: 'Download',
            icon: 'download',
            primary: true,
            available: item => true,
            handler: item => {
                this.downloadLogs([item])
            }
        }
    ]);


    private saveSubscription: Subscription;

    private assignmentLogDataSources = new Map<string, DeviceSlotSegmentAssignmentLogDataSource>()

    static open(router: Router, period: RetailerPromoPeriod) {
        router.navigate([`promo-periods/${period.id}/type-assignment`])
    }

    downloadLogs(logs: DeviceSlotSegmentAssignmentLog[]) {
        const rows = [
            ["Device Slot", "Store Num", "Segment", "User", "Remaining Slots count", "Timestamp", "Operation"]
        ]

        for (const log of logs) {
            for (const entry of log.entries) {
                let op = ''
                switch (entry.op) {
                    case MutationOperation.Delete:
                        op = 'removed'
                        break
                    case MutationOperation.Update:
                        op = 'updated'
                        break
                    case MutationOperation.Create:
                        op = 'added'
                        break
                    default:
                        op = 'other'
                        break
                }
                rows.push([
                    entry.deviceSlot.name,
                    entry.deviceSlot.store?.getPaddedStoreNum() || '',
                    log.deviceSlotSegment?.name || '',
                    log.user?.email || '',
                    log.remainingCount?.toString() || '',
                    log.createdAt.toLocaleString(),
                    op
                ])
            }
        }

        exportToCsv('assignment_logs.csv', rows)

    }

    setup(defaultDevices: SegmentDeviceSlotItemCollection, collections: SegmentDeviceSlotItemCollection[]) {
        this.controller.setup(this.changeDetector, defaultDevices, collections)
    }

    ngOnInit() {
        if (!this.entryParams) {
            return
        }

        this.loadPromoPeriod(this.entryParams.promoPeriodId).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(promoPeriod => {
            if (promoPeriod) {
                this.pageTitle = `${promoPeriod.promoSchedule?.name} / ${promoPeriod.name} - Device Slot Assignment`
                const retailerId = promoPeriod.promoSchedule.retailer.id

                this.loadProgramsWithAssignedDevSlotIds(retailerId).pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe(value => {
                    this.availablePrograms = value
                })


            }
            this.assignDeviceSlotsFromRetailer(promoPeriod)
        })

    }

    private loadPromoPeriod(promoPeriodId: string): Observable<RetailerPromoPeriod> {
        return this.svcApi.rawQuery({
            query: QUERY_LOAD_PROMO_PERIOD,
            fetchPolicy: "no-cache",
            variables: {
                id: promoPeriodId
            }
        }).pipe(
            map(value => {
                return Utils.getNestedTypedObject(value, RetailerPromoPeriod, 'data', 'retailerPromoPeriod');
            })
        )
    }

    private loadProgramsWithAssignedDevSlotIds(retailerId: number): Observable<RetailerPromoProgram[]> {
        const query = gql`
            query fetchProgramsWithAssignedDevSlotIds($retailerId: ID!) {
                retailerPromoPrograms(filter:{retailerIds:[$retailerId]}){
                    retailerPromoPrograms{
                        id
                        name
                        assignedDeviceSlotIds
                    }
                }
            }

        `
        return this.svcApi.rawQuery({
            query: query,
            fetchPolicy: "no-cache",
            variables: {
                retailerId: retailerId
            }
        }).pipe(
            map(value => {
                return Utils.getNestedTypedArray(value, RetailerPromoProgram, 'data', 'retailerPromoPrograms', 'retailerPromoPrograms');
            })
        )
    }

    assignDeviceSlotsFromRetailer(promoPeriod: RetailerPromoPeriod) {
        const deviceSlotMap = new Map<string, DeviceSlot>();
        for (const deviceSlot of (promoPeriod.unAssignedDeviceSlots || [])) {
            deviceSlotMap.set(deviceSlot.id, deviceSlot)
        }
        const collections = promoPeriod.deviceSlotSegments.map(value => {
            const slots = value.deviceSlots || [];
            const col: SegmentDeviceSlotItemCollection = {
                items: slots,
                key: value.id,
                label: value.getDisplayName(),
                acceptedItemGroups: DD_ACCEPT_ANY,
                assignmentLogs: value.assignmentLogs || []
            };
            return col
        });

        const unnAssignedDevices = Array.from(deviceSlotMap.values());

        const defaultDs: SegmentDeviceSlotItemCollection = {
            items: unnAssignedDevices,
            key: 'UNASSIGNED',
            label: 'Unassigned',
            acceptedItemGroups: DD_ACCEPT_ANY,
            assignmentLogs: []
        }

        this.setup(defaultDs, collections);
        this.controller.onAssignmentChanged().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.handleAssignmentChanged(value)
        })
    }

    handleAssignmentChanged(collections: Set<ItemCollection<DeviceSlot>>) {
        for (const col of collections) {
            this.dirtyAssignments.add(col as SegmentDeviceSlotItemCollection)
        }
    }

    saveAssignment() {
        if (!Utils.isUnsubscribed(this.saveSubscription)) {
            return
        }

        const data = Array.from(this.dirtyAssignments).map(col => {
            const x: DeviceTypeAssignationInput = {
                segmentId: col.key,
                deviceSlotIds: col.items.map(value => value.id)
            };
            return x;
        });

        this.saveSubscription = this.svcApi.rawObjectMutate(MUTATION_ASSIGN_SLOTS, {
            data: data
        }, null).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.dirtyAssignments = new Set();
                this.svcLayout.showSnackMessage('Values Saved')
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Error saving values')
            }
        })

    }

    downloadAllLogs() {
        let allLogs: DeviceSlotSegmentAssignmentLog[] = []
        for (const col of this.controller.itemDataSources) {
            const logs = (col.collection as SegmentDeviceSlotItemCollection)?.assignmentLogs || []
            allLogs = allLogs.concat(logs)
        }
        this.downloadLogs(allLogs)
    }

    getAssignmentLogsDataSource(col: DeviceSlotGridDataSource): DeviceSlotSegmentAssignmentLogDataSource {
        const key = col.collection.key
        if (!this.assignmentLogDataSources.has(key)) {
            const logs = (col.collection as SegmentDeviceSlotItemCollection)?.assignmentLogs || []
            const ds = new DeviceSlotSegmentAssignmentLogDataSource(logs)
            this.assignmentLogDataSources.set(key, ds)
        }
        return this.assignmentLogDataSources.get(key)

    }

}

interface DeviceTypeAssignationInput {
    segmentId: string
    deviceSlotIds: string[]
}

const FRAGMENT_DEVICE_SLOT_FIELDS = gql`
    fragment DeviceSlotFields on DeviceSlot {
        id
        name
        counter
        device_installed_at
        device_number
        looma_phase
        product_category{
            id
            category_name
        }
        store {
            id
            retailer_store_num
            retailer_region {
                id
                region_name
            }
        }
        kioskDeviceApp {
            id
            app_name
            package_name
        }
    }
`;

const QUERY_LOAD_PROMO_PERIOD = gql`
    ${FRAGMENT_DEVICE_SLOT_FIELDS}
    query promoPeriod($id: ID!) {
        retailerPromoPeriod(id: $id) {
            id
            name
            promoSchedule {
                name
                retailer {
                    id
                    retailer_name
                    retailer_id
                }
            }
            unAssignedDeviceSlots {
                ...DeviceSlotFields
            }
            deviceSlotSegments {
                id
                name
                deviceSlots {
                    ...DeviceSlotFields
                }
                assignmentLogs {
                    createdAt
                    changedCount
                    remainingCount
                    deviceSlotSegment{
                        id
                        name
                    }
                    user{
                        id
                        email
                    }

                    entries {
                        op
                        deviceSlot {
                            id
                            name
                            store{
                                id
                                retailer_store_num
                            }
                        }
                    }
                }
            }
        }
    }
`;

const MUTATION_ASSIGN_SLOTS = gql`
    mutation assign($data: [DeviceSlotToSegmentAssignationInput!]!){
        assignDeviceSlotsToSegments(data:$data){
            success
            message
        }
    }
`


interface SegmentDeviceSlotItemCollection extends ItemCollection<DeviceSlot> {
    assignmentLogs: DeviceSlotSegmentAssignmentLog[]
}

class DeviceSlotSegmentAssignmentLogDataSource extends ModelListDataSource<DeviceSlotSegmentAssignmentLog> {

    constructor(logs: DeviceSlotSegmentAssignmentLog[]) {
        super({
            columns: [{
                key: 'date',
                label: 'Date',
                valueReader: (item: DeviceSlotSegmentAssignmentLog): string => {
                    return item.createdAt.toLocaleString()
                }
            }, {
                key: "changed_count",
                label: 'Changed',
                valueReader: (item: DeviceSlotSegmentAssignmentLog): string => {
                    return item.changedCount + ''
                },
                width: '60px',
            }]
        })

        this.setLocalData(logs)
    }

}