import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeviceSlotsListComponent} from './components/device-slots-list/device-slots-list.component';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import {DeviceSlotsEditDialogComponent} from './components/device-slots-edit-dialog/device-slots-edit-dialog.component';
import {DeviceSlotInstallationWarningsDialogComponent} from './components/device-slot-installation-warnings-dialog/device-slot-installation-warnings-dialog.component';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [DeviceSlotsListComponent, DeviceSlotsEditDialogComponent, DeviceSlotInstallationWarningsDialogComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule,
        RouterModule
    ],
    entryComponents: [DeviceSlotsEditDialogComponent]
})
export class DeviceSlotsModule { }
