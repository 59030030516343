<h2 mat-dialog-title>{{ this.store.isNewRecord() ? "Create store" : "Edit store " + this.store.retailer_store_num }}</h2>
<mat-dialog-content [formGroup]="form">

    <app-autocomplete-search-field
        placeholder="Retailer"
        [searchObjectType]="'Retailer'"
        [value]="selectedRetailer"
        [disabled]="!store.isNewRecord()"
        selectionMode="single"
        (valueChanged)="onRetailerSelectionChanged($event)"></app-autocomplete-search-field>


    <app-autocomplete-search-field
        placeholder="Retailer Division"
        [allowNewValues]="true"
        [searchObjectType]="'RetailerDivision'"
        selectionMode="single"
        [showClear]="true"
        [value]="selectedRetailerDivision"
        [disabled]="!retailerDivisionSearchCriteria"
        (valueChanged)="onRetailerDivisionChanged($event)"></app-autocomplete-search-field>

    <mat-form-field>
        <mat-label>Store Num</mat-label>
        <input matInput formControlName="name" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Priority Level</mat-label>
        <mat-select formControlName="operationsPriorityFormControl">
            <mat-option *ngFor="let priority of availableStorePriorities; index as idx" [value]="idx">
                {{ priority }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-select formControlName="retailerRegionFormControl" placeholder="Retailer region"
                    [value]="store.retailer_region" [compareWith]="isSmeObj" [disabled]="!selectedRetailer">
            <mat-option *ngFor="let l of selectedRetailer?.regions" [value]="l">
                {{ l.region_name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-select
            multiple="true"
            formControlName="categoriesFormControl"
            placeholder="Product categories"
            [value]="store.product_categories"
            [compareWith]="isSmeObj"
            [disabled]="!selectedRetailer">
            <mat-option *ngFor="let l of selectedRetailer?.product_categories" [value]="l">
                {{ l.category_name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Location</mat-label>
        <input matInput (addressChanged)="onLocationSelected($event)" appPlacesAutocomplete class="input"
               formControlName="locationsFormControl" autocomplete="off"/>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleAction('ok')" [disabled]="isBusy || !form.valid">
        Save
    </button>
    <button mat-button (click)="handleAction('cancel')" [disabled]="isBusy">cancel</button>
</mat-dialog-actions>