import {Component, OnInit} from '@angular/core';
import {ApiDataService} from "../../../services/api-data.service";
import {LayoutService} from "../../../services/layout.service";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {RetailerPromoPeriodSubmission} from "@looma/shared/models/retailer_promo_period_submission";
import gql from "graphql-tag";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {
    CreateCustomProductDialogComponent
} from "../create-custom-product-dialog/create-custom-product-dialog.component";

@LifecycleHooks()
@Component({
    selector: 'app-custom-products',
    templateUrl: './custom-products.component.html',
    styleUrls: ['./custom-products.component.scss']
})
export class CustomProductsComponent implements OnInit {

    displayedColumns: string[] = ['submission-date', 'submitted-by', 'parent-brand', 'brand', 'product', 'upc', 'logo', 'action'];

    dataSource: CustomProductSubmission[]

    constructor(
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.svcApi.rawQuery({
            query: FETCH_SUBMISSIONS_WITH_CUSTOM_PRODUCTS,
            fetchPolicy: "no-cache",
        }).pipe(map(result => result.data["customProducts"]))
            .subscribe(result => {
                const submissions: RetailerPromoPeriodSubmission[] = []
                result.forEach(item => {
                        const r = new RetailerPromoPeriodSubmission()
                        r.assign(item)
                        submissions.push(r)
                    }
                )

                this.dataSource = []
                submissions.forEach(s => {
                    s.featuredProducts.filter(p => !p.uptimeProduct).map(p => {
                        const c: CustomProductSubmission = {
                            upc: p.upc,
                            thumbUrl: p.thumbUrl,
                            name: p.name,
                            submission: s,
                        }
                        return c
                    }).forEach(c => {
                        this.dataSource.push(c)
                    })
                })

                this.dataSource = this.dataSource.sort((a, b) => a.upc?.localeCompare(b.upc))
            })
    }

    onProductsClicked() {
        this.router.navigate(["/brand-products"]).then()
    }

    createProduct(element: CustomProductSubmission) {
        CreateCustomProductDialogComponent.open(this.svcLayout, element).subscribe(
            result => {
                this.dataSource = [...this.dataSource.filter(f => f.upc != element.upc && f.name != element.name)]
                this.svcLayout.showSnackMessage(`Product ${element.name} (UPC: ${element.upc}) created`)
            })
    }

    canCreateProducts(element: CustomProductSubmission) {
        if (!element.submission.brandPartner.uptimeBrand) {
            return false;
        }
        return true;
    }

    exportToCsv(): void {
        const csvData = this.convertToCsv(this.dataSource);
        const blob = new Blob([csvData], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = url;
        a.download = 'custom_products.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    convertToCsv(data: CustomProductSubmission[]): string {
        const columns = ["Submission date", "Submitted By", "Parent Company", "Brand", "Product", "UPC", "Image"]
        const header = columns.join(',');
        const rows = data.map(row => {
            const r = [row.submission.createdAt, 
                row.submission.submittedByUser, 
                row.submission.submittedByBrand.name, 
                row.submission.brandPartner.name, 
                row.name, 
                row.upc, 
                row.submission.getSubmissionThumbUrl()
            ]
            return r.join(",")
        });
        return `${header}\n${rows.join('\n')}`;
    }

}

export interface CustomProductSubmission {
    name: string
    upc: string
    thumbUrl: string
    submission: RetailerPromoPeriodSubmission
}

const FETCH_SUBMISSIONS_WITH_CUSTOM_PRODUCTS = gql`
    query customProducts {
        customProducts {
            id
            createdAt
            brandPartner {
                id
                name                
                logoUrl
                uptimeBrand
            }
            schedule {
                id
                retailerPromoProgram {
                    id
                    retailer {
                        id
                        retailer_name
                    }
                }
            }            
            submittedByBrand {
                id
                name
                looma_id
                product_categories {
                    id
                    category_name
                    sanitized_name
                }
            }
            featuredProducts {
                id
                name
                upc
                thumbUrl
                uptimeProduct
            }
            submittedByUser
        }
    }
`

