<h2 mat-dialog-title>{{this.userRole.isNewRecord() ? "Create User Role" : "Edit " + this.userRole.name + " role"}}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput rows="4" formControlName="description" placeholder="Enter description"></textarea>
    </mat-form-field>
    
    <mat-tab-group *ngIf="permissionGroups">
        <mat-tab *ngFor="let group of permissionGroups" label="{{group}}">
            <mat-selection-list (selectionChange)="onPermissionSelectionChanged($event)">
                <mat-list-option *ngFor="let permission of groupedPermissions.get(group) || []"
                                 [selected]="isSelected(permission)"
                                 [value]="permission">
                    {{permission.name}}
                </mat-list-option>
            </mat-selection-list>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isSavingData || !form.valid">Save</button>
    <button mat-button (click)="close()" [disabled]="isSavingData">cancel</button>
</mat-dialog-actions>