import {BaseModel} from "@looma/shared/models/base_model";
import {ModelDataSource} from "../layout/components/looma-grid/grid-data-source";
import {EMPTY, Observable} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {Utils} from "@looma/shared/utils";
import {LayoutService} from "../services/layout.service";
import {flatMap, takeUntil} from "rxjs/operators";
import {Directive, OnDestroy, Type, ViewChild} from "@angular/core";
import {ButtonActions} from "./button_actions";
import {LoomaGridComponent} from "../layout/components/looma-grid/looma-grid.component";
import {RowAction, RowActionRegistry} from "./row_action_registry";
import {CursorFilter} from '@looma/shared/types/cursor_filter';


@Directive()
export abstract class ModelListPageComponent<T extends BaseModel, Q extends CursorFilter> implements OnDestroy {
    private _dataSource: ModelDataSource<T, Q>;

    private rowActions = new RowActionRegistry();

    buttonActions = ButtonActions;

    abstract svcLayout: LayoutService;

    protected constructor(protected modelType: Type<T>) {
        if (InterfaceFeatureSpec.All.view.isImplementedBy(this)) {
            this.addRowAction({
                primary: true,
                key: this.buttonActions.View
            })
        }
        if (InterfaceFeatureSpec.All.edit.isImplementedBy(this)) {
            this.addRowAction({
                primary: false,
                key: this.buttonActions.Edit
            })
        }
        if (InterfaceFeatureSpec.All.delete.isImplementedBy(this)) {
            this.addRowAction({
                primary: false,
                key: this.buttonActions.Delete
            })
        }
        
    }

    @ViewChild(LoomaGridComponent, {static: true}) set gridComponent(view: LoomaGridComponent<T>) {
        if (view) {
            view.dataSource = this.dataSource;
            view.rowActions = this.rowActions;
        }
    }


    addRowAction(action: RowAction<T>) {
        this.addRowActions([action]);
    }

    addRowActions(actions: RowAction<T>[]) {
        actions.forEach(action => {
            const key = action.key;
            if (typeof action.handler != 'function') {
                action.handler = (item: T) => {
                    return this.performMenuAction(key, item)
                };
            }
            action.available = (item: T): boolean => this.isActionAvailable(key, item)
        });
        this.rowActions.addItems(actions);
    }

    get dataSource(): ModelDataSource<T, Q> {
        if (!this._dataSource) {
            this._dataSource = this.initDataSource();
            this._dataSource.onItemReplaced().pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                this.rowActions.invalidateItem(value?.newItem)
            })
        }
        return this._dataSource;
    }

    abstract initDataSource(): ModelDataSource<T, Q>;

    isActionAvailable(action: string, entry: T): boolean {
        const interfaceAction = InterfaceFeatureSpec.byActionName(action);
        if (!interfaceAction) {
            return false;
        }
        if (!interfaceAction.isImplementedBy(this)) {
            return false;
        }

        return interfaceAction.invokeCanPerform(this, entry);

    }

    // returns true if the action is handled
    performMenuAction(action: string, entry: T): boolean {
        if (!this.isActionAvailable(action, entry)) {
            return false;
        }
        const interfaceAction = InterfaceFeatureSpec.byActionName(action);
        switch (action) {
            case ButtonActions.Edit:
                const res = interfaceAction.invokePerform(this, entry) as Observable<T>;
                const isNewRecord = entry.isNewRecord();
                res.pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe(value => {
                    if (value) {
                        if (isNewRecord && !value.isNewRecord()) {
                            this.dataSource.addItem(value)
                        } else {
                            this.dataSource.replaceItem(value)
                        }
                    }
                });
                return true;
            case ButtonActions.Delete:
                this.svcLayout.confirm('Delete', 'Are you sure you want to delete this item?').pipe(
                    flatMap(value => {
                        if (!value) {
                            return EMPTY;
                        }
                        return interfaceAction.invokePerform(this, entry) as Observable<MutationResponse<T>>
                    }),
                    takeUntil(Utils.onDestroy(this))
                ).subscribe(value => {
                    if (value.success) {
                        this.dataSource.removeItem(value.data)
                    } else {
                        const msg = value.message || 'Unexpected Error';
                        this.svcLayout.showMutationResultMessage(value)
                        // this.svcLayout.showSnackMessage(msg);
                    }
                });
                return true;
            case ButtonActions.View:
                interfaceAction.invokePerform(this, entry);
                return true;
        }

        return false;
    }

    createNewItem() {
        this.performMenuAction(this.buttonActions.Edit, new this.modelType())
    }

    ngOnDestroy(): void {
    }


}

// constants for the function names defined by the interfaces below

class InterfaceFeatureSpec {

    static All: { [key: string]: InterfaceFeatureSpec } = {
        edit: new InterfaceFeatureSpec(ButtonActions.Edit, 'canEditItem', 'performEditItem'),
        view: new InterfaceFeatureSpec(ButtonActions.View, 'canViewItem', 'performViewItem'),
        delete: new InterfaceFeatureSpec(ButtonActions.Delete, 'canDeleteItem', 'performDeleteItem'),
    };


    static byActionName(buttonAction: string): InterfaceFeatureSpec {
        for (const key in InterfaceFeatureSpec.All) {
            const value = InterfaceFeatureSpec.All[key] as InterfaceFeatureSpec;
            if (value.actionName == buttonAction) {
                return value
            }
        }
        return null;
    }

    constructor(public actionName: string,
                public canPerformActionFnName: string,
                public performActionFnName: string) {
    }

    isImplementedBy(target: any): boolean {
        return !!target && Utils.isFunction(target[this.canPerformActionFnName]) && Utils.isFunction(target[this.performActionFnName])
    }

    invokeCanPerform<T extends BaseModel>(target: any, item: T): boolean {
        if (!this.isImplementedBy(target)) {
            return false;
        }
        return this.invokeFunction(this.canPerformActionFnName, target, item);
    }

    invokePerform<T extends BaseModel>(target: any, item: T): any {
        if (!this.isImplementedBy(target)) {
            return false;
        }
        return this.invokeFunction(this.performActionFnName, target, item);
    }

    private invokeFunction<T extends BaseModel>(fnName: string, target: any, item: T): any {
        const fn = target[fnName] as (x: T) => any;
        if (fn) {
            return fn.apply(target, [item]);
        }
    }
}


export interface EditItemComponent<T extends BaseModel> {
    performEditItem(item: T): Observable<T>

    canEditItem(item: T): boolean
}

export interface DeleteItemComponent<T extends BaseModel> {
    performDeleteItem(item: T): Observable<MutationResponse<T>>

    canDeleteItem(item: T): boolean
}

export interface ViewItemComponent<T extends BaseModel> {
    performViewItem(item: T)

    canViewItem(item: T);
}
