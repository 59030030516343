import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {RetailerPromoProgram, RetailerPromoProgramMutationInput} from "@looma/shared/models/retailer_promo_program";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {FormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {Observable} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import gql from "graphql-tag";
import {NamedValue} from "@looma/shared/types/named_value";
import {map} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {Retailer} from "@looma/shared/models/retailer";
import {DocumentNode} from "graphql";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {DeviceApp} from "@looma/shared/models/device_app";
import {MatSelectChange} from "@angular/material/select";
import {
    isBusyUploadState,
    UploadedFileInfo,
    UploadService,
    UploadSession,
    UploadState
} from "@looma/shared/services/upload.service";
import {ToastNotificationService} from "../../../../services/toast-notification.service";

@LifecycleHooks()
@Component({
    selector: 'app-program-edit-dialog',
    templateUrl: './program-edit-dialog.component.html',
    styleUrls: ['./program-edit-dialog.component.scss']
})
export class ProgramEditDialogComponent extends ModelEditDialog<RetailerPromoProgram> implements OnInit, OnDestroy {

    selectedKioskApp = DeviceApp.LoopPlayerApp
    readonly KioskApps = DeviceApp.KioskApps;
    readonly programTypesMap = RetailerPromoProgram.programTypesMap
    readonly availableFlexibilities = RetailerPromoProgram.availableFlexibilities
    readonly availableAirtimeTypes = RetailerPromoProgram.availableAirtimeTypes
    readonly availableCostStructures = RetailerPromoProgram.availableCostStructures
    readonly availableProductSegmentation = RetailerPromoProgram.availableProductSegmentation

    selectedProductCategory: NamedValue

    programSummaryUploadSession: UploadSession

    program: RetailerPromoProgram;
    title: string;
    isUploadingImage: boolean;
    imageUrl: string;
    hasUploadsInProgress = false;
    imageFirebaseKey: string
    programSummaryUrl: string
    programSummaryFirebaseKey: string;

    segments: string[] = []
    newSegment = '';

    airtimeSegments: string[] = []
    newAirtimeSegment = ''
    newProductSegment = ''
    productSegments: string[] = []

    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<ProgramEditDialogComponent>,
                private svcApi: ApiDataService,
                public svcLayout: LayoutService,
                private svcUpload: UploadService,
                private svcToastNotif: ToastNotificationService,
                @Inject(MAT_DIALOG_DATA) private dialogData: ProgramEditDialogData) {
        super();
        if (dialogData && dialogData.program) {
            this.program = dialogData.program
            this.selectedKioskApp = this.program.kioskDeviceApp
        } else {
            this.program = new RetailerPromoProgram();
        }

        if (this.program?.productCategory) {
            this.selectedProductCategory = this.program.productCategory.namedValue()
        }

        if (this.program?.coverImageDownloadUrl) {
            this.imageUrl = this.program.coverImageDownloadUrl
        }

        if (this.program?.programSummaryAttachmentDownloadUrl) {
            this.programSummaryUrl = this.program.programSummaryAttachmentDownloadUrl
        }

        if (this.program.segments) {
            this.segments = this.program.segments
        }

        if (this.program.airtimeSegments) {
            this.airtimeSegments = this.program.airtimeSegments
        }

        this.productSegments = this.program.productSegments ? this.program.productSegments : []

        if (this.program.isNewRecord()) {
            this.program.active = true;
        }

        const psUs = this.programSummaryUploadSession = this.svcUpload.getUploadSession(`promo_programs_info`, {
            fileTypes: ['pdf'],
            multiSelection: false,
            recreate: true
        });

        this.programSummaryUploadSession.onFileUploaded().subscribe(result => {
            this.programSummaryUrl = result.downloadUrl
            this.programSummaryFirebaseKey = result.firebaseKey
        })

        Utils.onDestroy(this).subscribe(value => {
            psUs.destroy();
        })

        this.program.changeoverSettings ||= {
            enabled: false,
            numDays: 1
        }
        this.program.changeoverSettings.numDays ||= 1
    }

    static open(svcLayout: LayoutService, program: RetailerPromoProgram, programGqlFields: string): Observable<RetailerPromoProgram> {
        const dialogData: ProgramEditDialogData = {
            program: program,
            programGqlFields: programGqlFields,
        };


        return svcLayout.openDialogForResult(ProgramEditDialogComponent, {
            width: "800px",
            data: dialogData
        })
    }

    ngOnInit() {
        if (this.program.isNewRecord()) {
            this.title = 'New Program'
        } else {
            this.title = `Edit ${this.program.name}`
        }
    }

    ngOnDestroy(): void {
    }

    onRetailerSelected(value: NamedValue) {
        this.program.retailer = null;
        if (value) {
            this.wrap(
                this.svcApi.rawQuery({query: FETCH_RETAILER, variables: {id: value.value}}).pipe(
                    map(resp => {
                        const items = Utils.getNestedTypedArray(resp, Retailer, 'data', 'retailers');
                        if (Array.isArray(items) && items.length == 1) {
                            return items[0];
                        }
                        return null;
                    })
                )).subscribe(retailer => {
                if (retailer) {
                    this.program.retailer = retailer;
                }
            });

        }
    }

    isSameObj(a: any, b: any): boolean {
        if (typeof a === "string" && typeof b === "string") {
            return a === b;
        }
        return (a && b) && a.id === b.id;
    }

    canSave(): boolean {
        return !this.isBusy && !!(this.program && this.program.retailer);
    }

    onSave(): Observable<MutationResponse<RetailerPromoProgram>> | null {
        if (!this.program.name) {
            return null;
        }

        this.program.name = this.program.name.trim();
        if (this.program.name == '') {
            return null;
        }

        if (!this.canSave()) {
            return null;
        }

        const segments = this.program.flexibility == RetailerPromoProgram.availableFlexibilities.Segmented ? this.segments : []
        const airtimeSegments = this.program.airtimeType == RetailerPromoProgram.availableAirtimeTypes.Flexible ? this.airtimeSegments : []
        const productSegments = this.productSegments ? this.productSegments : []

        console.log(productSegments)

        const data: RetailerPromoProgramMutationInput = {
            id: "",
            active: this.program.active,
            name: this.program.name,
            retailerId: this.program.retailer.id + '',
            kioskDeviceAppId: this.selectedKioskApp.id,
            programType: this.program.programType,
            flexibility: this.program.flexibility,
            campaignSlotsPerPeriod: this.program.campaignSlotsPerPeriod,
            maxFeaturedItemsPerCampaign: this.program.maxFeaturedItemsPerCampaign,
            productCategoryId: this.selectedProductCategory.value,
            coverImageFirebaseKey: this.imageFirebaseKey,
            programSummaryFirebaseKey: this.programSummaryFirebaseKey,
            distributionRequirement: this.program.distributionRequirement,
            airtimeType: this.program.airtimeType,
            airtimeSegments: airtimeSegments,
            segments: segments,
            description: this.program.description,
            costStructure: this.program.costStructure,
            campaignVisibleInLoop: this.program.campaignVisibleInLoop,
            cprEnabled: this.program.cprEnabled,
            interestCollectionEnabled: this.program.interestCollectionEnabled,
            executionReportingEnabled: this.program.executionReportingEnabled,
            changeoverEnabled: this.program.changeoverSettings.enabled,
            changeoverNumDays: this.program.changeoverSettings.numDays,
            productSegmentation: this.program.productSegmentation,
            productSegments: productSegments,
            numberOfStores: this.program.numberOfStores,
        };

        if (!this.program.isNewRecord()) {
            data.id = this.program.id
        }

        return this.svcApi.rawObjectMutate(this.getMutationQuery(), {
            promoProgramInput: data
        }, RetailerPromoProgram,)
    }

    getMutationQuery(): DocumentNode {
        const op = this.program.isNewRecord() ? MutationOperation.Create : MutationOperation.Update;
        return gql`
            mutation mutateRetailerPromoProgram(
                $promoProgramInput: RetailerPromoProgramMutationInput!
            ) {
                response: mutateRetailerPromoProgram(op: ${op}, data: $promoProgramInput) {
                    success
                    message
                    retailerPromoProgram {
                        ${this.dialogData.programGqlFields}
                    }
                }
            }
        `
    }

    onKioskAppSelected(item: NamedValue) {
        const app = item ? DeviceApp.KioskApps.find(app => app.id == item.value) : null
        this.selectedKioskApp = app ? app : DeviceApp.LoopPlayerApp
    }

    onFlexibilityChanged(event: MatSelectChange) {
        if (event) {
            this.program.flexibility = event.value;
        }
    }

    onProgramTypeChanged(event: MatSelectChange) {
        if (event) {
            this.program.programType = event.value;
        }
    }

    onProductCategorySelected(ev: NamedValue) {
        this.selectedProductCategory = ev;
    }

    handleFileUploaded(ev: UploadedFileInfo) {
        if (ev.firebaseKey) {
            this.imageFirebaseKey = ev.firebaseKey
        }
        this.isUploadingImage = false
    }

    handleUploaderStateChanged(state: UploadState) {
        if (isBusyUploadState(state) && !this.isUploadingImage) {
            this.isUploadingImage = true
        } else {
            this.isUploadingImage = false
        }
    }

    openFilePicker() {
        this.programSummaryUploadSession.openPicker()
    }

    addSegment(): void {
        const segmentValue = this.newSegment.trim();
        if (segmentValue && !this.segments.includes(segmentValue)) {
            this.segments.push(segmentValue);
            this.newSegment = '';
        }
    }

    addAirtimeSegment(): void {
        const segmentValue = this.newAirtimeSegment.trim();
        if (segmentValue && !this.airtimeSegments.includes(segmentValue)) {
            this.airtimeSegments.push(segmentValue);
            this.newAirtimeSegment = '';
        }
    }

    removeSegment(index: number, segmentType: 'segment' | 'airtime' | 'product'): void {
        switch (segmentType) {
            case "airtime":
                this.airtimeSegments.splice(index, 1);
                break
            case "segment":
                this.segments.splice(index, 1);
                break
            case "product":
                this.productSegments.splice(index, 1);
                break
        }
    }

    onAirtimeTypeChanged(event: MatSelectChange) {
        if (event) {
            this.program.airtimeType = event.value;
        }
    }

    onCostStructureChanged(event: MatSelectChange) {
        if (event) {
            this.program.costStructure = event.value;
        }
    }

    onProductSegmentationTypeChanged(event: MatSelectChange) {
        if (event) {
            this.program.productSegmentation = event.value;
            console.log(this.program.productSegmentation)
        }
    }

    addAProductSegment(): void {
        const segmentValue = this.newProductSegment.trim();
        if (segmentValue && !this.productSegments.includes(segmentValue)) {
            this.productSegments.push(segmentValue);
            this.newProductSegment = '';
        }
    }
}

export interface ProgramEditDialogData {
    program: RetailerPromoProgram
    programGqlFields: string
}


const FETCH_RETAILER = gql`
    query getRetailer($id:String!){
        retailers(id:$id){
            id
            retailer_id
            retailer_name
            product_categories{
                id
                category_name
            }
            regions{
                id
                region_name
            }
        }
    }
`;
