import { BaseModel } from "@looma/shared/models/base_model";
import { Utils } from "@looma/shared/utils";

export class RemoteDeviceDiskInfo extends BaseModel{
    available: number
    total: number

    usedPercent(){
        if (this.total > 0) {
            return Utils.round((this.total - this.available) / this.total * 100, 2)
        }
        return NaN
    }
}
