<div style="display: flex; flex-direction: column; flex:1; height: 100%; background: white">
    <looma-grid *ngIf="dataSource" [dataSource]="dataSource" class="device_slot_list"
                [class.slot_list]="dataSource.hasUnassignableItems" [dragDropController]="dataSource.ddController">
        <ng-template dragPreview let-deviceSlot="row" let-dataSource="dataSource" let-selectedItems="selectedItems">
            <div>
                <div *ngFor="let deviceSlot of selectedItems" class="dragged_device_slot_item">
                    <div>{{deviceSlot.name}}</div>
                </div>
            </div>
        </ng-template>

        <ng-template gridHeading [gridTitle]="dataSource.collection.label">
            <button mat-icon-button [matMenuTriggerFor]="collectionMenu" *ngIf="dataSource.collection.menuOptions">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #collectionMenu="matMenu">
                <button mat-menu-item *ngFor="let option of dataSource.collection.menuOptions"
                        (click)="performCollectionMenuOption(option, $event)">
                    <span>{{option}}</span>
                </button>
            </mat-menu>
        </ng-template>

        <ng-template gridColumnHeader="installed" let-dataSource="dataSource" let-label="label">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                <span fxFlex>{{label}}</span>
                <div style="display: flex">
                    <button class="small filter_active_badge" mat-icon-button (click)="openFilters($event)">
                        <mat-icon matBadgeColor="warn" [matBadge]="dataSource.hasLocalFilters() ? '!' : null"
                                  matBadgePosition="above after">filter_list
                        </mat-icon>
                    </button>
                    <button class="small" mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button *ngIf="dataSource.hasUnassignableItems" mat-menu-item
                                [disabled]="!dataSource.hasItemsSelected()" (click)="unassignSelectedItems()">
                            <span>Unassign</span>
                        </button>

                    </mat-menu>
                </div>
            </div>
        </ng-template>

        <ng-template gridColumn="name" let-data="data">
            <div>
                {{data.name}}
            </div>
        </ng-template>
    </looma-grid>
    <ng-container *ngIf="footerTemplate" [ngTemplateOutlet]="footerTemplate"></ng-container>
</div>

<ng-template #tplDeviceSlotFilter>
    <div class="filters_dialog mat-elevation-z3" fxLayout="column">
        <mat-form-field>
            <mat-label>Store Number</mat-label>

            <input type="number"
                   placeholder="Enter text"
                   matInput
                   [value]="dataSource.storesFormControl?.selection?.getDisplayName()"
                   [formControl]="dataSource.storesFormControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="dataSource.storesFormControl.getDisplayText">
                <mat-option *ngFor="let store of dataSource.storesFormControl.stream | async" [value]="store">
                    {{store.getDisplayName()}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Installed</mat-label>
            <mat-select [formControl]="dataSource.installedFormControl">
                <mat-option *ngFor="let option of dataSource.installedFormControl.options"
                            [value]="option">{{option}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Category</mat-label>
            <mat-select (selectionChange)="dataSource.filterByCategory($event)"
                        [value]="dataSource.extraFilters.categoryName">
                <mat-option *ngFor="let option of dataSource.availableCategories"
                            [value]="option">{{option}}</mat-option>
            </mat-select>

        </mat-form-field>

        <mat-form-field>
            <mat-label>Device Slot Nr.</mat-label>
            <mat-select (selectionChange)="dataSource.filterByDeviceSlotNumber($event)"
                        [value]="dataSource.extraFilters.deviceSlotNumber">
                <mat-option *ngFor="let option of dataSource.availableDeviceSlotNumbers"
                            [value]="option">{{option}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Region</mat-label>
            <mat-select (selectionChange)="dataSource.filterByRegion($event)"
                        [value]="dataSource.extraFilters.region">
                <mat-option *ngFor="let option of dataSource.availableRegions" [value]="option">{{option}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Fixture Type</mat-label>
            <mat-select (selectionChange)="dataSource.filterByFixtureType($event)"
                        [value]="dataSource.extraFilters.fixtureType">
                <mat-option *ngFor="let option of dataSource.availableFixtureTypes"
                            [value]="option">{{option}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Mount Type</mat-label>
            <mat-select (selectionChange)="dataSource.filterByMountType($event)"
                        [value]="dataSource.extraFilters.mountType">
                <mat-option *ngFor="let option of dataSource.availableMountTypes"
                            [value]="option">{{option}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>App</mat-label>
            <mat-select (selectionChange)="dataSource.filterByKioskApp($event)"
                        [value]="dataSource.extraFilters.kioskApp">
                <mat-option *ngFor="let option of dataSource.availableKioskApps"
                            [value]="option">{{option}}</mat-option>
            </mat-select>
        </mat-form-field>
        
        
        <mat-form-field *ngIf="retailerPromoPrograms">
            <mat-label>Program</mat-label>
            <mat-select (selectionChange)="dataSource.filterByProgram($event, retailerPromoPrograms)"
                        [value]="dataSource.extraFilters.program">
                <mat-option *ngFor="let option of retailerPromoPrograms"
                            [value]="option.id">{{option.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-button [disabled]="!dataSource.hasLocalFilters()" (click)="dataSource.clearLocalFilters()">Clear
        </button>
    </div>
</ng-template>