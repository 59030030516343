<div style="display: flex;  flex: 1 1; height: 100%">
    <div class="statsHolder" style="flex: 1">

        <div style="margin:16px; display: flex; flex: 1" class="mat-elevation-z2">
            <looma-grid [dataSource]="statsDataSource">
                <ng-template rowExpansionTemplate let-parent="data">
                    <looma-grid [showHeaders]="false" [dataSource]="statsDataSource.getChildDataSource(parent)"
                                [scrollContent]="false">
                        <ng-template rowExpansionTemplate let-child="data">
                            <looma-grid [showHeaders]="false" [dataSource]="statsDataSource.getChildDataSource(child)"
                                        [scrollContent]="false">

                            </looma-grid>
                        </ng-template>
                    </looma-grid>
                </ng-template>

            </looma-grid>
        </div>
    </div>
    <div style="flex:1; display: flex; align-items: stretch">
        <google-map #devicesMap style="flex:1" width="100%" height="100%">
            <map-marker #marker="mapMarker" *ngFor="let group of deviceGroups; let idx = index"
                        [position]="{lat:group.lat, lng:group.lng}" [icon]="group.markerIcon"
                        (mapClick)="onMarkerClick(idx, marker,group)">
                <map-info-window>
                    <div style="max-width: 360px">
                        <button style="width: 180px;" *ngFor="let device of group?.devices" mat-button
                                [routerLink]="['/devices', device.device_id]">
                            <device-status-icon
                                [status]="device?.remote_status_info?.playback_status || 0"></device-status-icon>
                            {{ device.assignation.name }}
                        </button>
                    </div>
                </map-info-window>

            </map-marker>

        </google-map>

    </div>
</div>
