import {Component, Inject} from '@angular/core';
import {LayoutService} from "../../../../services/layout.service";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {RetailerPromoPeriodSubmission} from "@looma/shared/models/retailer_promo_period_submission";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractBrandEditDialog} from "../AbstractBrandEditDialog";
import {ApiDataService} from 'projects/uptime-dashboard/src/app/services/api-data.service';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ProductCategory} from "@looma/shared/models/product_category";
import gql from "graphql-tag";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {isBusyUploadState, UploadedFileInfo, UploadState} from "@looma/shared/services/upload.service";

@LifecycleHooks()
@Component({
    selector: 'app-create-custom-band-dialog',
    templateUrl: './create-custom-band-dialog.component.html',
    styleUrls: ['./create-custom-band-dialog.component.scss']
})
export class CreateCustomBandDialogComponent extends AbstractBrandEditDialog {

    isBusy: boolean;
    submission: RetailerPromoPeriodSubmission

    form: FormGroup;
    loomaIdInvalidMsg: string;
    isValidatingLoomaId: any;

    categories: ProductCategory[]
    private newLogoFirebaseKey: string;
    private isUploadingFile = false
    loomaId: string

    constructor(private dialogRef: MatDialogRef<CreateCustomBandDialogComponent>,
                private fb: FormBuilder,
                private svcApi: ApiDataService,
                @Inject(MAT_DIALOG_DATA) data: CreateCustomBandDialogData) {
        super()
        if (data) {
            this.submission = data.submission
        }

        const loomaIdSuffix = ""

        this.form = fb.group({
            parentCompany: new FormControl({
                value: this.submission.submittedByBrand.name,
                disabled: true
            }, [Validators.required]),
            loomaId: new FormControl({value: loomaIdSuffix, disabled: false}, [Validators.required]),
            categoriesFormControl: new FormControl({
                value: this.submission.submittedByBrand.product_categories,
                disabled: false
            }, [Validators.required])
        });
    }

    static open(svcLayout: LayoutService, s: RetailerPromoPeriodSubmission): Observable<BrandPartner> {
        const data: CreateCustomBandDialogData = {
            submission: s,
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = data;
        dialogConfig.width = '800px';
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        return svcLayout.openDialogForResult(CreateCustomBandDialogComponent, dialogConfig)
    }

    create() {
        const input: CreateCustomBrandInput = {
            submissionId: this.submission.id,
            loomaId: this.loomaId,
            logoFirebaseKey: this.newLogoFirebaseKey,
            productCategories: this.form.get('categoriesFormControl').value.map(t => t.id)

        }
        this.svcApi.mutate(MUTATION_CREATE_CUSTOM_BRAND, {
            input: input
        }).pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(result => {
                if (result) {
                    this.dialogRef.close(result)
                }
            })
    }

    canSubmit() {
        if (!this.form.valid || this.loomaIdInvalidMsg) {
            return false
        }
        return true
    }

    close() {
        this.dialogRef.close()
    }

    getSvcApi(): ApiDataService {
        return this.svcApi
    }

    hint(): string {
        return this.submission.submittedByBrand.looma_id
    }

    brandId(): string {
        return ""
    }

    onLoomaIDAvailable(loomaId: string): void {
        this.loomaId = loomaId
    }

    handleFileUploaded(data: UploadedFileInfo) {
        this.newLogoFirebaseKey = data.firebaseKey
    }

    handleUploaderStateChanged(state: UploadState) {
        this.isUploadingFile = isBusyUploadState(state)
        if (this.isUploadingFile) {
            this.newLogoFirebaseKey = null
        }
    }
}

interface CreateCustomBandDialogData {
    submission: RetailerPromoPeriodSubmission
}

interface CreateCustomBrandInput {
    submissionId: string
    loomaId: string
    logoFirebaseKey: string
    productCategories: string[]
}


const MUTATION_CREATE_CUSTOM_BRAND = gql`
    mutation createBrandFromSubmission($input: CreateCustomBrandInput!) {
        createBrandFromSubmission(input: $input) {
            id
            name
        }        
    }
`
