import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";
import { BaseCursorLoader } from "@looma/shared/cursor_loader";
import { MediaFileFilterCriteria } from "@looma/shared/models/media_file";
import { ApiDataService } from "../../../services/api-data.service";
import { BehaviorSubject, Observable } from "rxjs";
import { CursorFeed } from "@looma/shared/cursor_feed";
import { debounceTime, map, takeUntil } from "rxjs/operators";
import {
    AllMediaContentTypes,
    MediaContent,
    MediaContentFilterCriteria,
    MediaContentType
} from "@looma/shared/models/media_content";
import { FRAGMENT_MEDIA_CONTENT_FIELDS } from "../../../services/queries";
import gql from "graphql-tag";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { VideoPlayerService } from "../../../services/video-player.service";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { BrandPartner } from "@looma/shared/models/brand_partner";
import { NamedValue } from "@looma/shared/types/named_value";
import { FormControl } from "@angular/forms";
import { Utils } from "@looma/shared/utils";
import { MatSelectChange } from "@angular/material/select";
import { LayoutService } from "../../../services/layout.service";
import {
    AutocompleteSearchFieldComponent
} from "@looma/shared/components/autocomplete-search-field/autocomplete-search-field.component";
import { SearchableField, SearchFieldCriteria } from "@looma/shared/search";
import Timeout = NodeJS.Timeout;

@LifecycleHooks()
@Component({
    selector: 'app-media-content-picker-dialog',
    templateUrl: './media-content-picker-dialog.component.html',
    styleUrls: [ './media-content-picker-dialog.component.scss' ]
})
export class MediaContentPickerDialogComponent implements OnInit{

    constructor(private dialogRef: MatDialogRef<MediaContentPickerDialogComponent>,
                private svcApi: ApiDataService,
                private svcVideoPlayer: VideoPlayerService,
                private domSanitizer: DomSanitizer,
                @Inject(MAT_DIALOG_DATA) public dialogData: MediaContentSelectDialogData){

        const criteria: MediaContentFilterCriteria = {};


        this.searchSubject = new BehaviorSubject<MediaContentFilterCriteria>(criteria);
        this.mediaFileLoader = new MediaContentLoader(svcApi, this.searchSubject);

        this.initSelection()
    }

    private readonly searchSubject: BehaviorSubject<MediaContentFilterCriteria>;
    mediaFileLoader: MediaContentLoader;

    textSearchControl = new FormControl();

    selectedMediaContent: MediaContent;

    selectionOptions: SelectionOptions

    @ViewChild('selectedChildBrandComp') selectedChildBrandComp: AutocompleteSearchFieldComponent;

    private applyFilterTimeout: Timeout


    static open(svcLayout: LayoutService, dialogData?: MediaContentSelectDialogData): Observable<MediaContent>{
        return svcLayout.openDialogForResult(MediaContentPickerDialogComponent, {
            data: dialogData
        }).pipe(
            map(value => value as MediaContent)
        )
    }

    initSelection(){
        const parentBrands = this.dialogData?.parentBrands
        this.selectionOptions = {
            parentBrandSelectable: true,
            contentTypeSelectable: true,
        }
        if (parentBrands) {
            this.selectionOptions.availableParentBrands = parentBrands
        }

        const availableMediaContentTypes: string[] = this.dialogData?.contentTypes || AllMediaContentTypes
        if (availableMediaContentTypes.length > 1) {
            availableMediaContentTypes.unshift(ANY)
        }
        this.selectionOptions.availableMediaContentTypes = availableMediaContentTypes

        const filters: MediaContentFilterCriteria = {}
        if (parentBrands) {
            console.warn('parentBrands', parentBrands)
            filters.parentBrandIds = parentBrands.map(value => value.getStringId())
            if (parentBrands.length == 1) {
                if (!parentBrands[0].isLooma) {
                    this.selectionOptions.parentBrandSelectable = false
                }
                this.setSelectedParentBrand(parentBrands[0].namedValue())
            }
        }
        if (availableMediaContentTypes.length == 1) {
            this.selectionOptions.selectedContentType = availableMediaContentTypes[0]
            this.selectionOptions.contentTypeSelectable = false
        }
        filters.contentTypes = availableMediaContentTypes.filter(value => value != ANY)

        this.applyFilter(filters)
    }

    ngOnInit(): void{
        this.textSearchControl.valueChanges.pipe(
            takeUntil(Utils.onDestroy(this)),
            debounceTime(100)
        ).subscribe(value => {
            this.applyFilter({ textSearchPattern: value });
        })
    }

    private applyFilter(values: MediaContentFilterCriteria){
        this.selectedMediaContent = null;
        const newFilters: MediaContentFilterCriteria = {};
        Object.assign(newFilters, this.searchSubject.value);
        for (const key of Object.keys(values)) {
            let newValue = values[key];
            if (typeof newValue === 'string') {
                newValue = newValue.trim();
                if (newValue == '') {
                    newValue = null;
                }
            }
            if (!newValue) {
                delete newFilters[key]
            } else {
                newFilters[key] = newValue;
            }
        }
        clearTimeout(this.applyFilterTimeout)
        this.applyFilterTimeout = setTimeout(() => {
            this.searchSubject.next(newFilters);
        }, 1)

    }

    onParentBrandSelected(value: NamedValue){
        this.setSelectedParentBrand(value)
    }

    setSelectedParentBrand(value: NamedValue){
        this.selectionOptions.availableChildBrands = null
        this.selectionOptions.selectedParentBrand = value

        if (this.selectionOptions.availableParentBrands) {
            const availableChildBrands = this.selectionOptions.availableChildBrands = (this.selectionOptions.availableParentBrands || []).find(parentBrand => {
                return parentBrand?.getStringId() == value?.getId()
            })?.childBrands
            if (!availableChildBrands?.length) {
                this.selectionOptions.availableChildBrands = null
            }
        }
        if (this.selectedChildBrandComp) {
            this.selectedChildBrandComp.clear()
        }

        this.selectionOptions.childBrandSearchCriteria = null
        if (value) {
            this.selectionOptions.childBrandSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.ParentId, value.getId()).asArray();
        }

        this.applyFilter({
            parentBrandIds: value ? [ value.getId() ] : null,
            childBrandIds: null
        })
    }

    onChildBrandSelected(value: NamedValue){
        this.applyFilter({
            childBrandIds: value ? [ value.getId() ] : null
        })
    }

    onContentTypeSelected(selection: MatSelectChange): void{
        this.setSelectedContentType(selection?.value)
    }

    setSelectedContentType(mediaContentType: string){
        let selectedContentTypes: string[]
        switch (mediaContentType) {
            case MediaContentType.SplashScreen:
            case MediaContentType.Film:
            case MediaContentType.AncillaryContent:
                selectedContentTypes = [ mediaContentType ]
                break;
            case ANY:
                selectedContentTypes = this.selectionOptions.availableMediaContentTypes.filter(value => value != ANY)
                break
        }
        if (selectedContentTypes?.length) {
            this.selectionOptions.selectedContentType = selectedContentTypes[0]
        } else {
            this.selectionOptions.selectedContentType = null
        }

        this.applyFilter({ contentTypes: selectedContentTypes })
    }

    handleAction(action: 'ok' | 'cancel'): void{
        switch (action) {
            case 'cancel':
                this.dialogRef.close(null);
                break;
            case 'ok':
                if (this.selectedMediaContent) {
                    this.dialogRef.close(this.selectedMediaContent);
                }
        }
    }

    selectMediaContent(item: MediaContent): void{
        this.selectedMediaContent = item;
    }


    getBackgroundThumbImage(file: MediaContent): SafeStyle{
        const imageUrl = file?.defaultMediaContentVersion?.thumbnail || Utils.BLANK_IMAGE
        return this.domSanitizer.bypassSecurityTrustStyle(` url(${imageUrl})`);
    }

    playItem(file: MediaContent): void{
        this.svcVideoPlayer.playContent(file)
    }
}

export interface MediaContentSelectDialogData{
    parentBrands?: BrandPartner[]
    contentTypes?: MediaContentType[]
}

const MEDIA_CONTENT_GQL_QUERY = gql`
    ${FRAGMENT_MEDIA_CONTENT_FIELDS}
    query mediaContentsForPicker($criteria: MediaContentFilterCriteria!){
        data: mediaContents(criteria:$criteria){
            cursor
            mediaContents {
                ...MediaContentFields
            }
        }
    }
`

class MediaContentLoader extends BaseCursorLoader<MediaContent>{

    private filters: MediaContentFilterCriteria;

    constructor(private svcApi: ApiDataService, obs: Observable<MediaFileFilterCriteria>){
        super();

        obs.subscribe(value => {
            this.filters = value;
            this.invalidate()
        })
    }

    next(startFrom: string): Observable<CursorFeed<MediaContent>>{
        const filters: MediaFileFilterCriteria = {};
        Object.assign(filters, this.filters);
        if (startFrom) {
            Object.assign(filters, { cursor: startFrom });
        }
        return this.svcApi.rawQuery({
            query: MEDIA_CONTENT_GQL_QUERY,
            variables: {
                criteria: this.filters,
            }
        }).pipe(
            map(value => {
                const rawData = value.data['data'] as any;
                return CursorFeed.create(rawData, MediaContent, 'mediaContents');
            })
        )
    }


}


class SelectionOptions{
    availableParentBrands?: BrandPartner[]
    availableChildBrands?: BrandPartner[]
    availableMediaContentTypes?: string[]

    parentBrandSelectable: boolean
    contentTypeSelectable: boolean

    selectedChildBrand?: NamedValue;
    selectedParentBrand?: NamedValue;
    selectedContentType?: string

    childBrandSearchCriteria?: SearchFieldCriteria[]


}

const ANY = "Any";
