export const secrets = {
    firebase:{
        apiKey: 'AIzaSyD9yiuhMv8wZqNHLInxT6AN55gYP16nBUk',
        authDomain: 'looma-dsn-v3.firebaseapp.com',
        databaseURL: 'https://looma-dsn-v3.firebaseio.com',
        projectId: 'looma-dsn-v3',
        storageBucket: 'looma-dsn-v3.appspot.com',
        messagingSenderId: '928324239368',
        appId: '1:928324239368:web:439b4e9e7a864367'
    },
    google:{
        apiKey: 'AIzaSyDJtMjC1PjgCaRVuJ5ONqHgna6fUnlilqM',
        clientId: '928324239368-q4ra11dssjdgl6rv4r8gb5okp9684fqu.apps.googleusercontent.com',
    },
    apiEndpoint: 'https://api.theloomaproject.com/api',
    wsEndpoint: 'wss://api.theloomaproject.com/ws',
    uploadPath: 'tmp_uploads/production',
    loopDashboardUrl: 'https://loop-dashboard.theloomaproject.com',
    uptimeDashboardUrl:'https://uptime.theloomaproject.com',
    merchantDashboardUrl: `https://merchant-platform.theloomaproject.com`,
}
