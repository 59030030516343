import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AsyncSubject, Subject} from 'rxjs';
import {delay, filter, take, takeUntil} from 'rxjs/operators';
import {PerfectScrollbarDirective} from '../../directives/perfect-scrollbar.directive';
import {User} from '@looma/shared/models/user';
import {AppConfigService} from '../../../services/config.service';
import {AppNavigationService} from '../../navigation.service';
import {AppSidebarService} from '../sidebar/sidebar.service';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';


@Component({
    selector     : 'navbar',
    templateUrl  : './navbar.component.html',
    styleUrls    : ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavBarComponent implements OnInit, OnDestroy{
    appConfig: any;
    navigation: any;
    currentUser: User;
    roleNames: string;
    
    // Private
    private _appPerfectScrollbar: PerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: AppConfigService,
        private navigationService: AppNavigationService,
        private _fuseSidebarService: AppSidebarService,
        private authService: LoomaAuthService,
        private _router: Router
    ){
        // Set the private defaults
        this._unsubscribeAll = new AsyncSubject();

        authService.onSessionChanged().pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(value => {
            this.currentUser = value?.user;
            this.roleNames = this.currentUser?.roles.map(t => t.name).join(",")
        });

    }

    @ViewChild(PerfectScrollbarDirective,{static: true})
    set directive(theDirective: PerfectScrollbarDirective){
        if ( !theDirective ){
            return;
        }

        this._appPerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this.navigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._appPerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        const activeNavItem: any = document.querySelector('navbar .nav-link.active');

                        if ( activeNavItem ){
                            const activeItemOffsetTop       = activeNavItem.offsetTop,
                                  activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
                                  scrollDistance            = activeItemOffsetTop - activeItemOffsetParentTop - (48 * 3) - 168;

                            this._appPerfectScrollbar.scrollToTop(scrollDistance);
                        }
                    });
                }
            );
    }


    ngOnInit(): void{
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._fuseSidebarService.getSidebar('navbar') ){
                        this._fuseSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Subscribe to the config changes
        this._fuseConfigService.onConfigurationChanged()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.appConfig = config;
            });

    }


    ngOnDestroy(): void{
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

    }


    toggleSidebarOpened(): void{
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }


    toggleSidebarFolded(): void{
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

}
