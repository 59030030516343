<h2 mat-dialog-title>{{dialogTitle}}</h2>
<mat-dialog-content>

    <div style="width:800px; background: white; display: flex; flex-direction: column">

        <mat-form-field style="display: block">
            <input matInput
                   autocomplete="off"
                   [(ngModel)]="mediaContentName"
                   placeholder="Name"/>
        </mat-form-field>

        <app-autocomplete-search-field placeholder="Parent Company"
                                       searchObjectType="ParentBrand"
                                       [value]="playlistMediaBundle.brandPartner"
                                       [disabled]="true"
                                       selectionMode="single"
                                       class="full-width"></app-autocomplete-search-field>

        <div class="media_contents" cdkDropList cdkDropListOrientation="horizontal"
             (cdkDropListDropped)="handleReorder($event)">
            <div class="media_frame mat-elevation-z3"
                 *ngFor="let mediaContent of playlistMediaBundle.mediaContents; let i = index" cdkDrag>
                <div class="image_preview"
                     [style.background-image]="sanitizeImageUrl(mediaContent.defaultMediaContentVersion.thumbnail)">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="drag_handle" cdkDragHandle>
                            <mat-icon>drag_handle</mat-icon>
                        </div>
                        <media-content-type-icon [elevation]="1"
                                                 [mediaContentType]="mediaContent.contentType"></media-content-type-icon>

                        <button mat-icon-button class="generate_splash_button" (click)="generateSplash(mediaContent)"
                                *ngIf="mediaContent.contentType == MediaContentType.Film">
                            <mat-icon>perm_media</mat-icon>
                        </button>
                        <div fxFlex></div>
                        <button mat-icon-button (click)="removeMediaContent(i)" [disabled]="!canRemoveMediaContent(i)"
                                color="warn">
                            <mat-icon class="delete_icon">close</mat-icon>
                        </button>
                    </div>
                    <div>

                    </div>

                </div>
                <div style="padding-left: 8px;" fxLayout="row" fxLayoutAlign="start center" class="full-width"
                     fxLayoutGap="4px">

                    <div fxFlex="">{{mediaContent.displayName}}</div>
                    <button mat-icon-button (click)="playItem(mediaContent)">
                        <mat-icon>play_arrow</mat-icon>
                    </button>
                </div>
            </div>
        </div>


    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <div fxLayout="row" class="full-width">
        <button mat-button [matMenuTriggerFor]="menuAddMediaContent">
            <mat-icon>add</mat-icon>
            Add Media Content
        </button>
        <mat-menu #menuAddMediaContent="matMenu" yPosition="above">
            <button mat-menu-item (click)="addItem(MediaContentType.Film)">Film</button>
            <button mat-menu-item (click)="addItem(MediaContentType.SplashScreen)">Splash Screen</button>
            <button mat-menu-item (click)="addItem(MediaContentType.AncillaryContent)">Shopper Ed Slides</button>
        </mat-menu>
        <div fxFlex></div>
        <button mat-button (click)="handleAction('cancel')">cancel</button>
        <button mat-raised-button color="primary" [disabled]="isBusy" (click)="handleAction('ok')">Save</button>
    </div>

</mat-dialog-actions>