import {Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {Location} from '@looma/shared/models/location';
import {Subject, Subscription, timer} from "rxjs";
import {delayWhen, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";

declare var google: any;

@LifecycleHooks()
@Directive({
    selector: '[appPlacesAutocomplete]'
})
export class PlacesAutocompleteDirective implements OnInit, OnDestroy {

    private isDirty = false
    private clearSub: Subscription

    @Output() addressChanged = new Subject<Location>();

    @Input() countryFilter: string[]

    constructor(private elRef: ElementRef) {
    }

    ngOnInit(): void {
        const autocomplete = new google.maps.places.Autocomplete(this.elRef.nativeElement);
        if (this.countryFilter && this.countryFilter.length > 0) {
            autocomplete.setComponentRestrictions({country: this.countryFilter});
        }
        autocomplete.addListener('place_changed', () => {
            Utils.unsubscribe(this.clearSub)
            this.handleChangeEvent(new MapPlace().assign(autocomplete.getPlace()))
        })
        this.elRef.nativeElement.addEventListener('blur', (ev) => {
            if (this.isDirty) {
                // avoid race conditions between "place_changed" and "blur" events
                this.clearSub = timer(100).pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe(value => {
                    this.notifyChanged(null)
                })
            }
        })

        const self = this
        this.elRef.nativeElement.addEventListener('keydown', function onKeyDown(ev) {
            ev.target.removeEventListener('keydown', onKeyDown)
            self.isDirty = true
        })
    }

    ngOnDestroy() {
        this.addressChanged.complete()
    }

    private handleChangeEvent(place: MapPlace): void {
        const location = this.readLocation(place);
        if (location) {
            this.notifyChanged(location);
        }
    }

    private readLocation(place: MapPlace): Location {

        if (place) {
            const loc = new Location();

            loc.street_address = place.formatted_address;

            const streetName = place.findAddressComponent('route')
            const streetNum = place.findAddressComponent('street_number')

            if (streetName) {
                loc.street_address = streetName.short_name
                if (streetNum) {
                    loc.street_address = streetNum.short_name +' '+ streetName.short_name
                }
            } else if (place.formatted_address) {
                loc.street_address = place.formatted_address.split(',')[0].trim()
            }

            const state = place.findAddressComponent('administrative_area_level_1', 'political')
            if (state) {
                loc.state = state.short_name;
            }

            const country = place.findAddressComponent('country', 'political');
            if (country) {
                if (country.short_name === 'us') {
                    loc.country_code = 'USA';
                } else {
                    loc.country_code = country.short_name;
                }
            }

            const city = place.findAddressComponent('locality', 'political') || place.findAddressComponent('sublocality', 'political');
            if (city) {
                loc.city = city.long_name
            }

            const zip = place.findAddressComponent('postal_code');
            if (zip) {
                loc.zip_code = zip.long_name
            }

            const geoLocation = place.geometry && place.geometry.location || null;
            if (geoLocation) {
                loc.geo_point = [geoLocation.lat(), geoLocation.lng()]
            }
            console.warn(loc)
            return loc
        }
    }

    private notifyChanged(l: Location) {
        this.addressChanged.next(l)
    }
}


export class AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}


class MapPlace {
    address_components: AddressComponent[];
    formatted_address: string;
    icon: string;
    id: string;
    name: string;
    types: string[];
    url: string;
    geometry: Geometry;

    assign(obj: any): this {
        console.warn('assign', obj)
        if (obj.hasOwnProperty('address_components')) {
            const dest: any = this;
            Object.assign(dest, obj);
        }
        return this;
    }


    get country(): AddressComponent {
        return this.findAddressComponent('country', 'political')
    }

    get city(): AddressComponent {
        return this.findAddressComponent('locality', 'political')
    }

    get streetNumber() {
        return this.findAddressComponent('street_number')
    }

    get streetName() {
        return this.findAddressComponent('route')
    }

    findAddressComponent(...chunks: Array<string>): AddressComponent {
        if (Array.isArray(this.address_components)) {
            for (const comp of this.address_components) {
                if (this.isComponentOfType(comp, chunks)) {
                    return comp;
                }
            }
        }
        return null;
    }

    isComponentOfType(comp: AddressComponent, types: Array<string>): boolean {
        for (const chunk of types) {
            if (comp.types && (comp.types.indexOf(chunk) < 0)) {
                return false
            }
        }
        return true
    }

}

class Geometry {
    location: { lat: () => number, lng: () => number }
}

