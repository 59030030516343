import {Injectable} from '@angular/core';
import {AsyncSubject, merge, Observable, of, Subject} from "rxjs";
import {catchError, filter, flatMap, map, take, takeWhile, tap, timeout} from "rxjs/operators";
import {secrets} from '@looma/config/secrets';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    private readonly conn: WsConnection;

    constructor(
        private svcAuth: LoomaAuthService
    ) {
        this.conn = new WsConnection(svcAuth)
    }

    onMessage(): Observable<RemoteMessage>{
        return this.conn.onMessage()
    }

    sendMessage(msg: RemoteMessage): Observable<boolean> {
        return this.conn.send(msg)
    }

    onPeerDeviceConnected(deviceId: string): Observable<DeviceMessagingConnection>{
        let sessionId: string = null;

        return this.onMessage().pipe(
            filter<RemoteMessage>(msg => {
                if(msg.type === 'session/create' && isSessionMessage(msg)){
                    if(msg.data && msg.data['device_id'] === deviceId){
                        return true
                    }
                }
                return false
            }),
            take(1),
            flatMap((connectMsg: RemoteMessage) => {
                const msg = new RemoteMessage({
                    type:connectMsg.type,
                    data: connectMsg.data,
                    to:connectMsg.from,
                    counter:0,
                    sid:connectMsg.sid
                });
                return this.sendMessage(msg).pipe(
                    flatMap(sent => {
                        if(!sent){
                            return of(null)
                        }
                        sessionId = msg.sid;
                        return of(new DeviceMessagingConnection(this.conn, connectMsg))
                    })
                )
            })
        )
    }

    sendRequireConfirmation(msg: RemoteMessage, ttl: number=2000): Observable<RemoteMessage | null>{
        const requestId = (Date.now()).toString();
        msg.data = msg.data || {};
        msg.data['request_id'] = requestId;
        return this.sendMessage(msg).pipe(
            flatMap(value => {
                if(!value){
                    return of(null)
                }
                return this.onMessage().pipe(
                    filter((reply: RemoteMessage) => {
                        return reply.data['reply_id'] === requestId
                    }),
                    take(1),
                    timeout(ttl),
                    catchError(err => {console.error(err); return of(null)}),
                    take(1)
                );
            })
        );
    }

}

export interface RemoteMessageParams {
    sid?: string;
    counter?: number;
    type?: string;
    data?: {};
    from?: string;
    to?: string;
}

export class RemoteMessage implements RemoteMessageParams{
    sid: string;
    counter: number;
    type: string;
    data: {};
    from: string;
    to: string;

    static from(ev: MessageEvent | RemoteMessageParams): RemoteMessage | null{
        let params: RemoteMessageParams = null;
        if(ev instanceof MessageEvent || ev.hasOwnProperty('data') && (typeof ev.data === 'string')){
            const json = (ev as {data: string}).data;
            try {
                params = JSON.parse(json);
            }catch (e) {return null}
        }
        return new RemoteMessage(params)
    }

    constructor(props?: RemoteMessageParams) {
        if(props){
            this.assignValue(props,'sid', 'string');
            this.assignValue(props,'counter', 'number');
            this.assignValue(props,'type', 'string');
            this.assignValue(props,'data', 'object');
            this.assignValue(props,'from', 'string');
            this.assignValue(props,'to', 'string');
        }
    }


    private assignValue(src: any, propName: string, propType: string): void{
        if(src && src.hasOwnProperty(propName)){
            if(typeof src[propName] === propType){
                this[propName] = src[propName]
            }
        }
    }

    getDataString(key: string, valueIfMissing: string): string{
        if(!this.data || !this.data.hasOwnProperty(key)){
            return valueIfMissing
        }
        const v = this.data[key];
        if(!v){
            return valueIfMissing
        }
        return v.toString()
    }

    getDataBoolean(key: string, valueIfMissing: boolean): boolean{
        switch (this.getDataString(key, valueIfMissing.toString())) {
            case 'true':
                return true;
            case 'false':
                return false;
            default:
                return valueIfMissing
        }
    }


}


class WsConnection{

    private msgs: Subject<RemoteMessage> = new Subject();
    private _conn: Observable<RegisteredConnection>;

    constructor(private svcAuth: LoomaAuthService){
        console.error('WsConnection')
    }

    getConnection(): Observable<RegisteredConnection>{


        if(!this._conn){

            const subject = new AsyncSubject<RegisteredConnection>();
            this._conn = subject.asObservable();

            this.svcAuth.getIdToken().pipe(
                take(1)
            ).subscribe((idTkn) => {

                const ws = new WebSocket(`${secrets.wsEndpoint}/${idTkn.token}`);
                ws.onclose = ev => {
                    console.error('WS CLOSE', ev);
                    this.msgs.complete()
                };

                ws.onerror = ev => {
                    console.error('WS ERR', ev);
                    this.msgs.error(ev)
                };

                ws.onmessage = ev => {
                    const msg = RemoteMessage.from(ev);
                    if(msg){
                        if(msg.type === '_register'){
                            subject.next(new RegisteredConnection(msg.data['id'], ws));
                            subject.complete()
                        }else{
                            this.msgs.next(msg)
                        }

                    }

                };

            });

        }

        return this._conn
    }

    send(msg: RemoteMessage): Observable<boolean> {
        return this.getConnection().pipe(
            map(conn => {
                const json = JSON.stringify(msg);

                conn.conn.send(json);
                return true
            })
        )
    }


    onMessage(): Observable<RemoteMessage>{
        return this.getConnection().pipe(
            flatMap(value => {
                return this.msgs
            })
        )
    }


}


export class DeviceMessagingConnection{
    private sendMessageCounter = 1;
    private receiveMessageCounter: number;
    private readonly peerId: string;
    readonly sessionId: string;
    private rtcDataSubject = new Subject<RemoteMessage>();
    private rtcDataChannel: RTCDataChannel = null;

    constructor(private wsConn: WsConnection, connectionMessage: RemoteMessage) {
        this.receiveMessageCounter = connectionMessage.counter;
        this.sessionId = connectionMessage.sid;
        this.peerId = connectionMessage.from;
    }

    sendMessageDirect(type: string | RemoteMessage, data?: any): Observable<boolean>{
        const msg = this.createMessage(type, data);
        if(!msg){
            return of(false)
        }
        if(this.rtcDataChannel){
            this.rtcDataChannel.send(JSON.stringify(msg))
            return of(true)
        }
        return this.sendMessageIndirect(msg)
    }

    sendMessageIndirect(type: string | RemoteMessage, data?: any): Observable<boolean>{
        const msg = this.createMessage(type, data);
        if(!msg){
            return of(false)
        }
        return this.wsConn.send(msg)
    }

    private createMessage(type: string | RemoteMessage, data?: any): RemoteMessage{
        let msg: RemoteMessage = null;
        if(type instanceof RemoteMessage){
            msg = type
        }else{
            msg = new RemoteMessage({
                type: type,
                to:this.peerId,
                sid:this.sessionId,
                counter: ++this.sendMessageCounter,
                data: data
            });
        }
        return msg
    }

    onMessage(): Observable<RemoteMessage>{
        return merge(
            this.wsConn.onMessage(),
            this.rtcDataSubject
        ).pipe(
            filter<RemoteMessage>(msg => {
                return isSessionMessage(msg) && msg.from === this.peerId /*&& msg.counter > this.receiveMessageCounter*/
            }),
            takeWhile(msg => {
                return msg.sid === this.sessionId
            }),
            tap(msg => {
                this.receiveMessageCounter = msg.counter
            })
        );

    }

    close(): Observable<boolean>{
        return this.sendMessageIndirect('close')
    }

    setDataChannel(ch: RTCDataChannel): void{
        if(this.rtcDataChannel != null){
            this.rtcDataChannel.close();
            this.rtcDataChannel = null;
        }
        this.rtcDataChannel = ch;

        if(ch == null){
            return
        }

        ch.onopen = ev => {
            if(!this.rtcDataChannel){
                this.rtcDataChannel = ch;
            }
        };

        ch.onmessage = ev => {

            if(this.rtcDataChannel === ch){
                console.warn('DATACHAN MSG', ev.data);
                const msg = RemoteMessage.from(ev);
                if(msg){
                    msg.from = this.peerId;
                    this.rtcDataSubject.next(msg)
                }
            }
        };

        ch.onclose = ev => {
            if(this.rtcDataChannel === ch){
                this.rtcDataChannel = null
            }
        }
    }


}

const isSessionMessage = (msg: RemoteMessage): boolean => {
    return (typeof msg.sid === 'string') && (typeof msg.counter === 'number')
};


class RegisteredConnection{
    constructor(public clientId: string, public conn: WebSocket) {
    }

}
