import {AsyncSubject} from "rxjs";

const componentRefs: WeakMap<object, ComponentWatcher> = new WeakMap();
const lifecycleDecoratorSym = Symbol('__ng__lifecycle_decorated');

interface ComponentDefinition {
    ngOnInit(): void;
    ngOnDestroy(): void;
}

const getCmpDefinition = <T>(type: any): (ComponentDefinition | null) => {
    const lookupProps = [`ɵcmp`,'ɵdir'];
    if(type){
        for(const prop of lookupProps){
            if (!!type[prop]){
                return type[prop]
            }
        }
    }
    return null
};


function createDecorator(originalFn: any, decoratorFn: any): any {
    return function(): void {
        const args = Array.prototype.slice.call(arguments);
        if (typeof originalFn === 'function') {
            try {
                return originalFn.apply(this, args);
            } finally {
                decoratorFn.apply(this, arguments);
            }
        }
    };
}

class ComponentWatcher {
    private _destroySubject: AsyncSubject<any>;
    private _initSubject: AsyncSubject<any>;

    constructor() {
    }

    get initSubject(): AsyncSubject<any> {
        this._initSubject = this._initSubject || new AsyncSubject<any>();
        return this._initSubject;
    }

    get destroySubject(): AsyncSubject<any> {
        this._destroySubject = this._destroySubject || new AsyncSubject<any>();
        return this._destroySubject;
    }

    onInit() {
        if (this._initSubject) {
            this._initSubject.next(true);
            this._initSubject.complete()
        }
    }

    onDestroy() {
        if (this._destroySubject) {
            this._destroySubject.next(true);
            this._destroySubject.complete()
        }
    }
}

export function LifecycleHooks(): any {
    // tslint:disable-next-line:only-arrow-functions
    return function(target, propertyKey: string, _: PropertyDescriptor): void {
        const cmp = getCmpDefinition(target);
        if(!cmp){
            return;
        }

        if (cmp[lifecycleDecoratorSym]) {
            return
        }
        const emptyFn = () => {};
        
        const proto = target.prototype

        const ngOnInit = proto.ngOnInit || emptyFn;
        const ngOnDestroy = proto.ngOnDestroy || emptyFn;

        proto.ngOnDestroy = createDecorator(ngOnDestroy, function() {
            const ref = componentRefs.get(this);
            if (ref) {
                ref.onDestroy();
            }
        });

        proto.ngOnInit = createDecorator(ngOnInit, function() {
            const ref = componentRefs.get(this);
            if (ref) {
                ref.onInit();
            }
        });

        cmp[lifecycleDecoratorSym] = true;


    };
}

export function getComponentWatcher(instance: object): ComponentWatcher {

    const cmp = instance ? getCmpDefinition(instance.constructor) || null : null;
    if (!cmp) {
        return
    }

    if (!cmp[lifecycleDecoratorSym]) {
        // throw new Error('component not decorated')
        return
    }

    let watcher = componentRefs.get(instance);
    if (watcher) {
        return watcher
    }

    watcher = new ComponentWatcher();
    componentRefs.set(instance, watcher);


    return watcher;
}

const DESTROY_FN = () => {}