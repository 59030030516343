import {Component, ComponentRef, Inject, Injectable, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/compat/storage';
import {BehaviorSubject, defer, EMPTY, from, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {bufferTime, catchError, filter, flatMap, last, map, take, takeUntil, takeWhile} from 'rxjs/operators';
import {FileProgressData, Utils} from '@looma/shared/utils';
import {DOCUMENT} from '@angular/common';
import firebase from 'firebase/compat/app';
import {DomService} from './dom.service';
import {UploadTaskSnapshot} from '@angular/fire/compat/storage/interfaces';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {secrets} from '@looma/config/secrets';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';
import {
    GcpFilePickerComponent,
    GCPStorageItem
} from "@looma/shared/components/gcp-file-picker/gcp-file-picker.component";
import {LoomaLayoutService} from "@looma/shared/services/looma-layout.service";
import {ParallelExecutor} from "@looma/shared/services/parallel_executor";
import {EnvironmentProvider} from "@looma/shared/services/environment-provider.service";
import {uuidv4} from "@firebase/util";
import {doOnSubscribe} from "../../uptime-dashboard/src/app/rxjs_extensions";


export enum UploadState {
    Idle = 1,
    FetchBlob = 2,
    Uploading = 3,
    UploadSuccess = 4,
    // room for anything else
    Failure = 100,
    Canceled = 101,
}

export function isBusyUploadState(state: UploadState): boolean {
    switch (state) {
        case UploadState.FetchBlob:
        case UploadState.Uploading:
            return true
    }
    return false
}


@Injectable({
    providedIn: 'root'
})
export class UploadService {

    private activeSessions = new Map<string, UploadSession>();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private _bottomSheet: MatBottomSheet,
        private svcDom: DomService,
        private afStorage: AngularFireStorage,
        private svcAuth: LoomaAuthService,
        private envSvc: EnvironmentProvider,
    ) {
    }

    getUploadSession(contextName: string, cfg: SessionConfig): UploadSession {
        let session = this.activeSessions.get(contextName);
        if (session) {
            if (Utils.isBoolean(cfg?.recreate) && cfg.recreate) {
                session.destroy()
            } else {
                return session;
            }
        }

        session = this.newUploadSession(cfg, contextName)
        this.activeSessions.set(contextName, session)
        return session
    }

    newUploadSession(cfg: SessionConfig, name = null): UploadSession {
        if (typeof cfg.multiSelection !== 'boolean') {
            cfg.multiSelection = true
        }

        if (!cfg.baseUploadPath) {
            cfg.baseUploadPath = `${secrets.uploadPath}/${this.svcAuth.currentUser.id}`.replace(/\/{2,}/g, '/')
        }

        return new UploadSession(
            this,
            this.afStorage,
            this.svcDom,
            this._bottomSheet,
            name,
            cfg
        )
    }

    destroySession(key: string): void {
        this.activeSessions.delete(key);
    }
}

export class UploadSession {

    private fileInput: ComponentRef<UploadFileInputComponent>;
    public queue: UploadQueue;

    constructor(
        private parent: UploadService,
        private afStorage: AngularFireStorage,
        private svcDom: DomService,
        private bottomSheet: MatBottomSheet,
        private context: string,
        private sessionCfg: SessionConfig,
    ) {
        this.queue = new UploadQueue(afStorage, sessionCfg.baseUploadPath)

    }

    get hasPendingUploads(): boolean {
        return this.queue.pendingUploads > 0
    }

    pickSingleFile(): Observable<UploadedFileInfo> {
        const cfg: UploadConfig = {
            context: this.context,
            fileTypes: this.sessionCfg.fileTypes,
            session: this,
            multiSelection: false
        };
        const ref = this.bottomSheet.open(UploadBottomSheetSelectorComponent, {
            data: cfg,
        });

        return this.onFileAdded().pipe(
            takeUntil(ref.afterDismissed()),
            take(1),
        )
    }

    openPicker(): void {
        const cfg: UploadConfig = {
            context: this.context,
            fileTypes: this.sessionCfg.fileTypes,
            session: this,
            multiSelection: this.sessionCfg.multiSelection
        };
        this.bottomSheet.open(UploadBottomSheetSelectorComponent, {
            data: cfg,
        });
    }

    addBlob(blob: Blob, name = 'uploaded_file'): void {
        if (!blob) {
            return
        }
        const size = blob.size
        const fData: FileProgressData = {total: size, loaded: size, blob: blob}
        const fInfo = new UploadedFileInfo('manual', name, size, blob.type, of(fData))
        this.addFiles([fInfo])
    }

    addFiles(files: UploadedFileInfo[]): void {
        this.queue.add(files)
    }

    setFileInput(cmp: ComponentRef<UploadFileInputComponent>): void {
        this.fileInput = cmp;
    }

    destroy(): void {
        this.svcDom.removeComponentFromBody(this.fileInput);
        this.parent.destroySession(this.context);
        this.queue.destroy()
    }

    getStateName(state: UploadState): string {
        switch (state) {
            case UploadState.Idle:
                return 'Idle';
            case UploadState.FetchBlob:
                return 'Fetching file data';
            case UploadState.Uploading:
                return 'Uploading';
            case UploadState.Failure:
                return 'Failure';
            case UploadState.UploadSuccess:
                return 'Upload Success';
            case UploadState.Canceled:
                return 'Canceled';
            default:
                return 'Unknown';

        }
    }

    get isBusy() {
        for (const file of this.queue.files) {
            switch (file?.state) {
                case UploadState.FetchBlob:
                case UploadState.Uploading:
                    return true
            }
        }
        return false
    }

    onFileUploaded(): Observable<UploadedFileInfo> {
        return this.queue.onFileUploaded;
    }

    onFileAdded(): Observable<UploadedFileInfo> {
        return this.queue.onFileAdded;
    }

    uploadFile(file: UploadedFileInfo): Observable<UploadedFileInfo> {
        return this.onFileUploaded().pipe(
            filter(value => value.fileId == file.fileId),
            take(1),
            doOnSubscribe(() => {
                this.addFiles([file])
            })
        )
    }

}

@Component({
    selector: 'upload-file-input',
    template: `
        <input type="file" #file style="position:absolute; top: -1000000px; left:-1000000px; width:1px; height:1px;"
               (change)="handleLocalFilesAdded($event)"
               [attr.multiple]="multiSelection ? true : null"
               accept="{{mimeTypes}}"/>
    `,
})
export class UploadFileInputComponent implements OnInit, OnDestroy {
    @ViewChild('file', {static: true}) fileEl;

    mimeTypes: string;
    private browserFilesPipe = new Subject<File>();

    @Input()
    multiSelection = true;

    ngOnInit(): void {

    }

    setFileTypes(fileTypes: string[]): void {
        this.mimeTypes = Utils.getMimeTypeExtensions(fileTypes);
    }

    handleLocalFilesAdded(event): void {
        for (const fileData of Array.prototype.slice.call(event.target.files)) {
            this.browserFilesPipe.next(fileData)
        }
        event.target.value = null
    }

    ngOnDestroy(): void {
        this.browserFilesPipe.complete()
    }

    @Output('onLocalFilesAdded')
    get onLocalFilesAdded(): Observable<File> {
        return this.browserFilesPipe
    }

    onFilesAdded(): Observable<UploadedFileInfo[]> {
        return this.browserFilesPipe.pipe(
            map(fileData => UploadedFileInfo.fromFile(fileData)),
            bufferTime(500),
            filter(buffer => buffer.length > 0), // rxjs emits empty arrays... wtf?
        ) as Observable<UploadedFileInfo[]>
    }

    openPicker(): void {
        this.fileEl.nativeElement.click();
    }
}


@LifecycleHooks()
@Component({
    selector: 'upload-bottom-sheet-selector',

    template: `
        <mat-nav-list>
            <a href="javascript:;" mat-list-item (click)="browseFiles('local')">
                <span mat-line style="padding-right: 10px">Local</span>
                <span mat-line>Add files from your device</span>
            </a>

            <a href="javascript:;" mat-list-item (click)="browseFiles('cloud')">
                <span mat-line style="padding-right: 10px">Google Cloud</span>
                <span mat-line>Add files from Google Cloud</span>
            </a>
        </mat-nav-list>
    `
})
export class UploadBottomSheetSelectorComponent implements OnInit, OnDestroy {


    private fileInput: UploadFileInputComponent;

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public config: UploadConfig,
                private _bottomSheetRef: MatBottomSheetRef<UploadBottomSheetSelectorComponent>,
                private afStorage: AngularFireStorage,
                private svcAuth: LoomaAuthService,
                private svcDom: DomService,
                private envSvc: EnvironmentProvider,
                private svcLayout: LoomaLayoutService,
    ) {
    }


    browseFiles(which: string): void {
        switch (which) {
            case 'local':
                this.fileInput.openPicker();
                break;
            // case 'drive':
            //     this.getFilesFromDrive().subscribe(value => this.handleFilesAdded(value));
            //     break;
            case 'cloud':
                this.getFilesFromGCP().subscribe(value => this.handleGcpFile(value));
                break;
            default:
                this._bottomSheetRef.dismiss();
                return;
        }

    }


    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        // we're attaching the file input component to the dom root element as we don't want to loose the file reference once
        //      this component is destroyed ( the file component needs to remain attached to the dom )
        const fileInputRef = this.svcDom.appendComponentToBody(UploadFileInputComponent);
        this.config.session.setFileInput(fileInputRef);
        this.fileInput = fileInputRef.instance;
        this.fileInput.setFileTypes(this.config.fileTypes);
        this.fileInput.multiSelection = this.config.multiSelection;

        this.fileInput.onFilesAdded().subscribe(value => {
            this.handleFilesAdded(value)
        });

    }

    private handleFilesAdded(files: UploadedFileInfo[]): void {
        this.config.session.addFiles(files);
        this._bottomSheetRef.dismiss();
    }

    private getFilesFromGCP(): Observable<GCPStorageItem> {
        return GcpFilePickerComponent.open(this.svcLayout, this.afStorage, {
            afStorage: this.afStorage,
            bucket: this.envSvc.getUptimeTransferBucket(),
            folder: "/uptime-transfers",
        })
    }

    private handleGcpFile(value: GCPStorageItem) {
        if (!value) {
            return;
        }
        this._bottomSheetRef.dismiss();
        const fData: FileProgressData = {total: value.size, loaded: value.size};
        const uploadInfo = new UploadedFileInfo("gcp", value.name, value.size, value.contentType, of(fData))
        uploadInfo.firebaseKey = value.gsFullPath
        this.config.session.queue.onFileAdded.next(uploadInfo)

        const progress = Utils.makePercent(value.size, value.size)
        uploadInfo.onProgressPipe.next({type: 'upload', progress: progress});
        uploadInfo.onStateChanged.next(UploadState.UploadSuccess)
    }
}

export interface SessionConfig {
    fileTypes: string[]
    baseUploadPath?: string
    multiSelection?: boolean
    recreate?: boolean
}

export interface UploadConfig {
    fileTypes: string[]
    context: string
    session: UploadSession
    multiSelection?: boolean
}

export interface UploadProgress {
    type: 'download' | 'upload'
    progress: number
}


export class UploadedFileInfo {

    private firebaseUploadTask: AngularFireUploadTask;

    public state: UploadState = UploadState.Idle;

    public onProgressPipe = new BehaviorSubject<UploadProgress>(null);
    public onStateChanged = new BehaviorSubject<UploadState>(this.state);
    private subscriptions: Subscription[] = [];

    public firebaseKey: string;
    public downloadUrl: string
    public errorMessage: string;

    private isDestroyed = false

    private uploadExecutor = new ParallelExecutor(3);
    public extra = new Map<string, any>();

    public readonly fileId = uuidv4()

    static fromFile(fileData: File, provider = 'browser'): UploadedFileInfo {
        const size = fileData.size
        const fData: FileProgressData = {total: size, loaded: size, blob: fileData}
        return new UploadedFileInfo(provider, fileData.name, size, fileData.type, of(fData))
    }

    constructor(public provider: string, public fileName: string, public fileSize: number, public contentType: string, private fileDataSource: Observable<FileProgressData>) {
    }

    get onProgress(): Observable<UploadProgress> {
        return this.onProgressPipe.pipe(
            filter(value => !!value)
        )
    }

    upload(key: string, fileRef: AngularFireStorageReference): Observable<UploadedFileInfo> {
        return this.performRetrieveFile(key).pipe(
            flatMap(value => {
                return this.performUpload(fileRef, value);
            }),
            catchError(err => {
                this.setError(Utils.extractErrorMessage(err, 'Upload Error'));
                return of(this);
            }),
        )
    }

    private performRetrieveFile(firebaseKey: string): Observable<Blob> {

        return defer(() => {
            if (!this.setState(UploadState.FetchBlob, UploadState.Idle)) {
                return EMPTY
            }

            this.firebaseKey = firebaseKey;

            return this.fileDataSource.pipe(
                takeWhile((fileData: FileProgressData) => {
                    let progress = 0;
                    if (fileData.total > 0) {
                        progress = Utils.makePercent(fileData.loaded, fileData.total)
                    }
                    this.onProgressPipe.next({type: 'download', progress: progress});
                    return !fileData.blob
                }, true),
                last(),
                flatMap((fileData: FileProgressData) => {
                    if (!fileData.blob) {
                        return throwError('error while downloading file')
                    }

                    return of(fileData.blob)
                })
            )
        })
    }

    private performUpload(fileRef: AngularFireStorageReference, fileData: Blob): Observable<UploadedFileInfo> {
        const uploadedFileInfo = this as UploadedFileInfo; // linter goes nuts without this
        const source: Observable<UploadedFileInfo> = defer(() => {
            if (!this.setState(UploadState.Uploading, UploadState.FetchBlob)) {
                return EMPTY
            }
            this.firebaseUploadTask = fileRef.put(fileData);

            return this.firebaseUploadTask.snapshotChanges().pipe(
                map((value: UploadTaskSnapshot) => {
                    const self = this;

                    switch (value.state) {
                        case firebase.storage.TaskState.CANCELED:
                            this.setState(UploadState.Canceled);
                            break;
                        case firebase.storage.TaskState.ERROR:
                            this.setError('Upload error');
                            break;
                        case firebase.storage.TaskState.RUNNING:
                            const progress = Utils.makePercent(value.bytesTransferred, value.totalBytes);
                            this.onProgressPipe.next({type: 'upload', progress: progress});
                            if (progress === 100) {
                                const maxTries = 4;
                                (function checkFileUploaded(numTry: number): void {
                                    if (self.state === UploadState.Uploading) {
                                        // doing this because the success state is never set on the firebse task (probably a bug )

                                        fileRef.getMetadata().subscribe(value1 => {
                                            fileRef.getDownloadURL().subscribe(url => {
                                                self.downloadUrl = url;
                                                self.setState(UploadState.UploadSuccess, UploadState.Uploading);
                                            })
                                        }, _ => {
                                            if (numTry < maxTries) {
                                                numTry += 1;
                                                setTimeout(() => {
                                                    checkFileUploaded(numTry)
                                                }, (maxTries - numTry) * 500)
                                            } else {
                                                self.setError('Upload failure');
                                            }
                                        })
                                    }
                                })(0);
                            }
                            break;
                        case firebase.storage.TaskState.SUCCESS:
                            if (self.state != UploadState.UploadSuccess) {
                                if (!this.downloadUrl) {
                                    fileRef.getDownloadURL().subscribe(url => {
                                        self.downloadUrl = url;
                                        self.setState(UploadState.UploadSuccess, UploadState.Uploading);
                                    })
                                } else {
                                    this.setState(UploadState.UploadSuccess, UploadState.Uploading);
                                }
                            }
                            break
                    }
                    return uploadedFileInfo

                }),
                last(),
            )

        });

        return this.uploadExecutor.wrap(source);
    }


    private setState(newState: UploadState, prevState?: UploadState | undefined): boolean {
        if (prevState && this.state !== prevState) {
            return false;
        }

        if (this.isDestroyed) {
            return false;
        }

        if (this.state < newState) {
            switch (newState) {
                case UploadState.Failure:
                case UploadState.Canceled:
                    break;
            }
            this.onStateChanged.next(this.state = newState);
            return true;
        }
        return false;
    }

    cancel(): void {
        if (this.setState(UploadState.Canceled)) {
            this.markCompleted();
        }
    }

    destroy(): void {
        if (!this.isDestroyed) {
            this.markCompleted();
            this.isDestroyed = true
        }
    }

    private setError(message: string, fromState?: UploadState): void {
        if (this.setState(UploadState.Failure, fromState || this.state)) {
            this.errorMessage = message;
            this.markCompleted();
        }
    }

    private markCompleted(): void {
        if (this.firebaseUploadTask) {
            this.firebaseUploadTask.cancel();
            this.firebaseUploadTask = null;
        }
        this.onStateChanged.complete();
        let sub: Subscription = null;

        // tslint:disable-next-line:no-conditional-assignment
        while ((sub = this.subscriptions.pop())) {
            if (!sub.closed) {
                sub.unsubscribe();
            }
        }
    }

    withDownloadURL(): Observable<{ uploadedFileInfo: UploadedFileInfo, downloadUrl: string }> {
        const storage = firebase.storage(), uploadedFileInfo = this;

        return from(
            storage.ref(this.firebaseKey).getDownloadURL()
        ).pipe(
            map(obj => {
                return {
                    uploadedFileInfo: uploadedFileInfo,
                    downloadUrl: obj as string
                }
            })
        )
    }

}


export class UploadQueue {
    files: UploadedFileInfo[] = [];
    onChanged = new Subject<UploadQueue>();
    onFileUploaded = new Subject<UploadedFileInfo>();
    onFileAdded = new Subject<UploadedFileInfo>();
    uploadCounter = 0;
    pendingUploads = 0

    constructor(private afStorage: AngularFireStorage, private baseUploadPath: string) {
    }

    add(files: UploadedFileInfo[]): void {
        if (files && files[Symbol.iterator]) {
            // ok
        } else {
            return
        }

        for (const file of files) {
            this.uploadCounter += 1;
            this.pendingUploads += 1

            const uploadId = `${Date.now()}-${Math.random().toString(36)}-${this.uploadCounter}`;
            const key = `${this.baseUploadPath}/${uploadId}`;
            const fileRef = this.afStorage.ref(key);

            this.files.unshift(file);


            file.upload(key, fileRef).subscribe();

            this.onFileAdded.next(file);

            file.onStateChanged.subscribe(value => {
                if (value === UploadState.UploadSuccess) {
                    this.onFileUploaded.next(file)
                    this.remove(file);
                }
                this.pendingUploads -= 1
            })
        }

        this.onChanged.next(this);

    }

    remove(file: UploadedFileInfo): void {
        const idx = this.files.indexOf(file);
        if (idx >= 0) {
            this.files.splice(idx, 1);
            file.destroy();
            this.onChanged.next(this);
        }
    }

    destroy(): void {
        this.onFileUploaded.complete();
        this.onFileAdded.complete();

        for (const file of this.files) {
            file.destroy();
        }
        this.files.length = 0;

    }
}
