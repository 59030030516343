import {
    Component,
    ElementRef, Inject,
    NgZone,
    OnDestroy,
    OnInit, Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core'
import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayConfig,
    OverlayRef,
    PositionStrategy, ViewportRuler
} from "@angular/cdk/overlay"
import {TemplatePortal} from "@angular/cdk/portal"
import {MatFormField} from "@angular/material/form-field"
import {ESCAPE, hasModifierKey, UP_ARROW} from "@angular/cdk/keycodes"
import {fromEvent, merge, Observable, of, Subject, Subscription} from "rxjs"
import {debounceTime, distinctUntilChanged, filter, map, take, takeUntil} from "rxjs/operators"
import {FormControl} from "@angular/forms"
import {getSelectionOption, Utils} from "@looma/shared/utils"
import {LifecycleHooks} from "@looma/shared/lifecycle_utils"
import {DOCUMENT} from "@angular/common"
import {ApiDataService} from "../../../../services/api-data.service";
import {SearchService} from "@looma/shared/services/search.sevice";
import {BaseModel} from "@looma/shared/models/base_model";
import {SearchableObject, SearchFieldCriteria} from "@looma/shared/search";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {MatSelectionListChange} from "@angular/material/list";


type SearchResultData = {
    searchText?: string
    state: 'none' | 'empty' | 'loading' | 'ready',
    parentBrands?: BrandPartner[]
    childBrands?: BrandPartner[]
}

@LifecycleHooks()
@Component({
    selector: 'app-brands-search-input',
    templateUrl: './brands-search-input.component.html',
    styleUrls: ['./brands-search-input.component.scss']
})
export class BrandsSearchInputComponent implements OnInit, OnDestroy {
    private overlayRef: OverlayRef | null
    private portal: TemplatePortal

    private positionStrategy: FlexibleConnectedPositionStrategy

    private viewportSubscription: Subscription
    private closingActionsSubscription: Subscription
    private searchSubscription: Subscription

    private readonly closeKeyEventStream = new Subject<void>()

    private overlayAttached = false

    @ViewChild(MatFormField, {static: true}) formFieldRef: MatFormField
    @ViewChild(TemplateRef, {static: true}) templateRef: TemplateRef<any>
    @ViewChild('inputElement', {static: true}) inputElementRef: ElementRef<HTMLInputElement>

    @Output('onSearch') searchSubject = new Subject()
    @Output('onBrandSelected') brandSelectedSubject = new Subject()

    searchInput = new FormControl()


    searchResultData: SearchResultData = {
        state: 'none'
    }

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private viewportRuler: ViewportRuler,
        private zone: NgZone,
        @Inject(DOCUMENT) private document: any,
        private svcSearch: SearchService
    ) {
    }

    ngOnInit(): void {

        window['svcSearch'] = this.svcSearch

        this.searchInput.valueChanges
            .pipe(
                takeUntil(Utils.onDestroy(this)),
                debounceTime(300),
                distinctUntilChanged()
            ).subscribe(searchText => {
            this.searchByText(searchText)
        })

    }

    searchByText(text: string) {
        this.attachOverlay()
        this.searchSubject.next(text)

        Utils.unsubscribe(this.searchSubscription)
        if (text == '') {
            this.searchResultData.state = 'none'
            return
        }

        this.searchResultData.state = 'loading'

        this.searchSubscription = this.svcSearch.searchModels(
            [SearchableObject.BrandPartner, SearchableObject.ParentBrand],
            [SearchFieldCriteria.newTextContainsCriteria(text)]
        ).pipe(
            map(brands => {
                const res: SearchResultData = {
                    state: 'empty',
                    searchText: text,
                    parentBrands: [],
                    childBrands: []
                }

                for (const b of brands) {
                    res.state = 'ready'
                    const brand = b as BrandPartner
                    if (brand.parentBrand) {
                        res.childBrands.push(brand)
                    } else {
                        res.parentBrands.push(brand)
                    }
                }
                return res
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.searchResultData = value
        })

    }

    private attachOverlay(): void {
        let overlayRef = this.overlayRef

        if (!overlayRef) {
            this.portal = new TemplatePortal(this.templateRef, this.viewContainerRef, {
                id: this.formFieldRef?.getLabelId(),
            })
            overlayRef = this.overlay.create(new OverlayConfig({
                positionStrategy: this.getOverlayPosition(),
                width: this.getPanelWidth()
            }))
            this.overlayRef = overlayRef
            this.handleOverlayEvents(overlayRef)
            this.viewportSubscription = this.viewportRuler.change().subscribe(() => {
                if (overlayRef) {
                    overlayRef.updateSize({width: this.getPanelWidth()})
                }
            })
        } else {
            // Update the trigger, panel width and direction, in case anything has changed.
            this.positionStrategy.setOrigin(this.getConnectedElement())
            overlayRef.updateSize({width: this.getPanelWidth()})
        }

        if (overlayRef && !overlayRef.hasAttached()) {
            overlayRef.attach(this.portal)
            this.closingActionsSubscription = this.subscribeToClosingActions()
        }

        this.overlayAttached = true
    }

    private getOverlayPosition(): PositionStrategy {
        const strategy = this.overlay
            .position()
            .flexibleConnectedTo(this.getConnectedElement())
            .withFlexibleDimensions(false)
            .withPush(false)

        strategy.withPositions([
            {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
            {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
        ])

        this.positionStrategy = strategy

        return strategy
    }


    private getConnectedElement(): ElementRef<HTMLElement> {
        return this.formFieldRef.getConnectedOverlayOrigin()
    }

    private getPanelWidth(): number {
        const width = this.getConnectedElement().nativeElement.getBoundingClientRect().width
        return width
    }

    private handleOverlayEvents(overlayRef: OverlayRef) {
        overlayRef.keydownEvents().subscribe(event => {
            if (
                (event.keyCode === ESCAPE && !hasModifierKey(event)) ||
                (event.keyCode === UP_ARROW && hasModifierKey(event, 'altKey'))
            ) {
                this.closeKeyEventStream.next()
                event.stopPropagation()
                event.preventDefault()
            }
        })

        overlayRef.outsidePointerEvents().subscribe()
    }

    ngOnDestroy() {
        this.viewportSubscription?.unsubscribe()
        this.destroyPanel()
        this.closeKeyEventStream.complete()
    }

    private destroyPanel(): void {
        if (this.overlayRef) {
            this.closePanel()
            this.overlayRef.dispose()
            this.overlayRef = null
        }
    }

    openPanel(): void {
        this.attachOverlay()
    }

    closePanel(): void {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach()
            this.closingActionsSubscription.unsubscribe()
        }
    }

    private getOutsideClickStream(): Observable<any> {
        return merge(
            fromEvent(this.document, 'click') as Observable<MouseEvent>,
            fromEvent(this.document, 'auxclick') as Observable<MouseEvent>,
            fromEvent(this.document, 'touchend') as Observable<TouchEvent>,
        ).pipe(
            filter(event => {

                const inputEl = this.inputElementRef.nativeElement

                // If we're in the Shadow DOM, the event target will be the shadow root, so we have to
                // fall back to check the first element in the path of the click event.
                const clickTarget = getEventTarget<HTMLElement>(event)
                const formField = this.formFieldRef ? this.formFieldRef._elementRef.nativeElement : null

                return (
                    this.overlayAttached &&
                    clickTarget !== inputEl &&
                    // Normally focus moves inside `mousedown` so this condition will almost always be
                    // true. Its main purpose is to handle the case where the input is focused from an
                    // outside click which propagates up to the `body` listener within the same sequence
                    // and causes the panel to close immediately (see #3106).
                    this.document.activeElement !== inputEl &&
                    (!formField || !formField.contains(clickTarget)) &&
                    !!this.overlayRef &&
                    !this.overlayRef.overlayElement.contains(clickTarget)
                )
            }),
        )
    }


    private subscribeToClosingActions(): Subscription {

        const closingActions = merge(
            this.closeKeyEventStream,
            this.getOutsideClickStream(),
            this.overlayRef
                ? this.overlayRef.detachments().pipe(filter(() => this.overlayAttached))
                : of(),
        ).pipe(
        )

        return closingActions.subscribe(value => {
            this.closePanel()
        })

    }

    handleInputFocus() {
        this.attachOverlay()
    }

    handleSelectionChanged(ev: MatSelectionListChange) {
        const bp = getSelectionOption(ev)?.value as BrandPartner
        if(bp instanceof BrandPartner){
            this.brandSelectedSubject.next(bp)
            this.closeKeyEventStream.next()
        }
    }

}


function getEventTarget<T extends EventTarget>(event: Event): T {
    return (event.composedPath ? event.composedPath()[0] : event.target) as T | null
}