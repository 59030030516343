import {BaseModel} from '@looma/shared/models/base_model';

export class RemoteDeviceInfo extends BaseModel {
    last_rebooted_at: Date;
    uptime: number;
    kiosk_mode_enabled: boolean;
    adjusted_volume: number;
    player_volume: number;
    last_heartbeat_at: Date;
    timezone_id: string;
    ethernet_mac_address: string;
    focused_activity_record: string;
    updated_at: Date;

    last_heartbeat_ago: number;

    androidVersion?: {
        major: number
        minor: number
        patch: number
    }

    assign(obj: any): this {
        super.assign(obj);
        this.assignDate(obj, 'last_heartbeat_at');
        this.assignDate(obj, 'last_rebooted_at');
        this.assignDate(obj, 'updated_at');

        if (this.last_heartbeat_at) {
            this.last_heartbeat_ago = (Date.now() - this.last_heartbeat_at.getTime()) / 1000;
        }

        return this;
    }
}
