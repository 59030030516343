import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiDataService} from "../../../../services/api-data.service";
import {
    DeviceSlotImage,
    DeviceSlotImageFilter,
    DeviceSlotImageMutationInput
} from "@looma/shared/models/device_slot_image";
import {Observable, Subject} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {debounceTime, flatMap, takeUntil} from "rxjs/operators";
import {SearchableField, SearchableObject, SearchFieldCriteria, SearchFieldCriteriaBundle} from "@looma/shared/search";
import {LayoutService} from "../../../../services/layout.service";
import {
    EditDeviceSlotImageDialogComponent
} from "../edit-device-slot-image-dialog/edit-device-slot-image-dialog.component";
import {StoreAssignmentComponent} from "../../../../layout/components/store-assignment/store-assignment.component";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {BaseCursorLoader} from "@looma/shared/cursor_loader";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Utils, YesNoAny} from '@looma/shared/utils';
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {AddImageDialogComponent} from "../add-image-dialog/add-image-dialog.component";
import {
    AutocompleteSearchFieldComponent
} from "../../../../../../../looma-shared/components/autocomplete-search-field/autocomplete-search-field.component";
import {NamedValue} from "@looma/shared/types/named_value";

@LifecycleHooks()
@Component({
    selector: 'app-images-list',
    templateUrl: './device-slot-images-list.component.html',
    styleUrls: ['./device-slot-images-list.component.scss']
})
export class DeviceSlotImagesListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild("promoPeriodSearch") set promoPeriodSearch(v: AutocompleteSearchFieldComponent) {
        if (this._promoPeriodSearch != v) {
            this._promoPeriodSearch = v
            v.fieldDisabled = true;
            v.valueChanged.pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value => {
                if (this._searchCriteria) {
                    this._searchCriteria.removeAll(SearchableField.PromoPeriodId)
                    if (value instanceof NamedValue) {
                        this._searchCriteria.set(SearchableField.PromoPeriodId, value)
                    }
                    this.filter.setValue(this._searchCriteria)
                }
            })
        }

    }

    constructor(
        public svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private sanitization: DomSanitizer
    ) {
        this.imagesLoader = new DeviceSlotImageLoader(this.svcApi, this.filter.onChanged().pipe(
            takeUntil(Utils.onDestroy(this)) as any,
            debounceTime(100) as any,
        ))
    }

    imagesLoader: DeviceSlotImageLoader;
    private filter = new LocalDeviceSlotImageFilter();

    SearchableObject = SearchableObject;

    @ViewChild(StoreAssignmentComponent) storeAssignmentCmp;

    private _promoPeriodSearch: AutocompleteSearchFieldComponent

    private _selectedRetailerId: string

    private _searchCriteria: SearchFieldCriteriaBundle

    ngOnInit(): void {
    }

    addDeviceSlotImage() {
        AddImageDialogComponent.open(this.svcLayout, {gqlFields: ''}).subscribe(value => {
            if (value instanceof DeviceSlotImage) {
                this.imagesLoader.prependItem(value)
            }
        })
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
        this.storeAssignmentCmp.onChanged().pipe(
            takeUntil(Utils.onDestroy(this)) as any
        ).subscribe((value: SearchFieldCriteriaBundle) => {
            const retailerId = value.get(SearchableObject.Retailer)?.value;
            const promoPeriodSearch = this._promoPeriodSearch;
            if (promoPeriodSearch) {
                if (this._selectedRetailerId != retailerId) {
                    promoPeriodSearch.clearInput()
                    this._selectedRetailerId = retailerId;
                }
                promoPeriodSearch.fieldDisabled = !retailerId;
                if (retailerId) {
                    const criteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, retailerId)
                    promoPeriodSearch.searchCriteria = criteria
                } else {
                    promoPeriodSearch.searchCriteria = null;
                }
            }
            this._searchCriteria = value;
            this.filter.setValue(value)
        })
    }

    editSlotImage(img: DeviceSlotImage): void {
        EditDeviceSlotImageDialogComponent.open(this.svcLayout, img).subscribe(value => {
            if (value instanceof DeviceSlotImage) {
                this.imagesLoader.replaceItem(value)
            }
        })
    }

    onImageSourceFilterChanged(sourceName: any) {
        this.filter.setImageSource(sourceName);
    }

    onSelectionChanged(what: string, value: YesNoAny): void {
        this.filter.setYesNoAny(value, what);
    }

    onImageQualityChanged(quality: "Poor" | "Acceptable"): void {
        this.filter.setImageQuality(quality);
    }

    deleteSlotImage(img: DeviceSlotImage): void {
        this.svcLayout.onConfirmed(`Delete image`, 'Are you sure you want to delete this image').pipe(
            flatMap(value => {
                const d: Partial<DeviceSlotImageMutationInput> = {
                    id: img.id,
                }
                return this.svcApi.mutateDeviceSlotImage(MutationOperation.Delete, d)

            })
        ).subscribe(value => {
            if (value.success) {
                this.imagesLoader.removeItem(value.data)
            }
        })
    }

    getBackgroundThumbImage(img: DeviceSlotImage): SafeStyle {
        return this.sanitization.bypassSecurityTrustStyle(` url(${img.thumb.url})`);
    }
}

class DeviceSlotImageLoader extends BaseCursorLoader<DeviceSlotImage> {

    private filters = {};

    constructor(private svcApi: ApiDataService, obs: Observable<LocalDeviceSlotImageFilter>) {
        super();

        obs.subscribe(value => {
            this.filters = value.data;
            this.invalidate()
        })
    }

    next(startFrom: string): Observable<CursorFeed<DeviceSlotImage>> {
        const filters: any = {};
        Object.assign(filters, this.filters);
        if (startFrom) {
            Object.assign(filters, {cursor: startFrom});
        }
        return this.svcApi.getDeviceSlotImages(filters)
    }


}

class LocalDeviceSlotImageFilter {
    data: Partial<DeviceSlotImageFilter> = {};
    changes = new Subject<LocalDeviceSlotImageFilter>();
    private imageSource = 'Any';

    setValue(value: SearchFieldCriteriaBundle): void {
        this.putValue(value, SearchableObject.Retailer, "retailerIds");
        this.putValue(value, SearchableObject.RetailerRegion, "retailerRegionIds");
        this.putValue(value, SearchableObject.ProductCategory, "productCategoryIds");
        this.putValue(value, SearchableObject.Store, "storeIds");
        this.putValue(value, SearchableObject.PromoPeriod, "promoPeriodIds");
        this.changes.next(this)
    }

    private putValue(value: SearchFieldCriteriaBundle, objType: SearchableObject, fieldName: string): void {
        const v = value.get(objType);
        if (v) {
            this.data[fieldName] = [v.value]
        } else {
            delete this.data[fieldName]
        }
        this.changes.next(this)
    }

    setYesNoAny(value: YesNoAny, fieldName: string): void {
        switch (value) {
            case YesNoAny.Yes:
                this.data[fieldName] = true;
                break;
            case YesNoAny.No:
                this.data[fieldName] = false;
                break;
            default:
                delete this.data[fieldName]

        }
        this.changes.next(this)
    }

    setImageQuality(value: "Poor" | "Acceptable" | "High"): void {
        switch (value) {
            case "Poor":
                this.data.imageQuality = "poor"
                break;
            case "Acceptable":
                this.data.imageQuality = "acceptable"
                break;
            case "High":
                this.data.imageQuality = "high"
                break;
        }
        this.changes.next(this)
    }

    setImageSource(source: string) {
        switch (source) {
            case 'Any':
            case 'sas-retail':
            case 'device-magic':
            case 'wiser':
            case 'jacent':
            case 'typeform':
            case 'cloud-storage':
            case 'uptime':
                break;
            default:
                return
        }
        if (this.imageSource != source) {
            this.imageSource = source;
            if (source == 'Any') {
                delete this.data['sources']
            } else {
                this.data['sources'] = [source]
            }
            this.changes.next(this)
        }

    }


    onChanged(): Observable<LocalDeviceSlotImageFilter> {
        return this.changes
    }
}
