import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfigService} from '../../services/config.service';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';
import {LifecycleHooks} from '@looma/shared/lifecycle_utils';
import {Utils} from '@looma/shared/utils';
import {LayoutService} from '../../services/layout.service';
import {SplashScreenService} from '@looma/shared/services/splash-screen.service';

@LifecycleHooks()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    constructor(
        private svcAppConfig: AppConfigService,
        private authService: LoomaAuthService,
        private router: Router,
        private svcLayout: LayoutService,
        private svcSplash: SplashScreenService
    ) {

        this.svcAppConfig.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                }
            }
        };


    }

    ngOnInit(): void {
    }

    performLogin(): void{
        this.svcSplash.show(this);
        this.authService.signInWithGoogle().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            
            if(!value.success){
                this.svcSplash.hide(this)
                this.svcLayout.showSnackMessage(value.errorMessage || 'Unexpected looma-login error')
            }else{
                setTimeout(() => {
                    this.svcSplash.hide(this)
                }, 1000)
                this.router.navigateByUrl(this.authService.config.dashboardUrl)
            }
        })
    }

    ngOnDestroy(): void {
    }

}
