import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiDataService} from "../../../services/api-data.service";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {flatMap, takeUntil} from "rxjs/operators";
import {RemoteJobService} from "@looma/shared/services/remote-job.service";
import {ApiResponse} from "@looma/shared/models/api_response";
import {throwError} from "rxjs";
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../services/toast-notification.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Utils} from '@looma/shared/utils';

@LifecycleHooks()
@Component({
    selector: 'app-bulk-import',
    templateUrl: './bulk-import.component.html',
    styleUrls: ['./bulk-import.component.scss']
})
export class BulkImportComponent implements OnInit, OnDestroy {

    uploadSession: UploadSession;
    private fileContextMap: Map<UploadedFileInfo, UploadContext> = new Map();
    private pickerContextName: string;

    constructor(
        private svcApi: ApiDataService,
        svcUpload: UploadService,
        private svcRemoteJob: RemoteJobService,
        private svcNotif: ToastNotificationService,
    ) {
        this.uploadSession = svcUpload.getUploadSession('bulk_import', {
            fileTypes: ['csv'],
            multiSelection: false,
        });

        this.uploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            const d = this.fileContextMap.get(value);
            if (d) {
                this.fileContextMap.delete(value);
                this.submitBulkImport(d, value);
            }

        });

        this.uploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe((file: UploadedFileInfo) => {
            if (this.pickerContextName) {

                const notif = this.svcNotif.create({
                    title: this.getDisplayName(this.pickerContextName),
                    description: 'Preparing',
                    dismissable: false,
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                });


                this.fileContextMap.set(file, {key: this.pickerContextName, notification: notif});
                this.pickerContextName = null;

                file.onProgress.subscribe(data => {
                    let desc = 'Preparing';
                    let progress = -1;
                    if (data) {
                        progress = data.progress;
                        if (progress >= 100) {
                            progress = -1
                        }
                        if (data.type === 'download') {
                            desc = 'Getting file data'
                        } else if (data.type === 'upload') {
                            desc = 'Uploading file'
                        }
                    }


                    notif.update({
                        progress: progress,
                        description: desc,
                    })
                })
            }

        })
    }

    private submitBulkImport(ctx: UploadContext, f: UploadedFileInfo): void {
        ctx.notification.update({
            progress: -1,
            description: 'Preparing',
        });
        this.svcApi.bulkImport(ctx.key, f.firebaseKey).pipe(
            takeUntil(Utils.onDestroy(this)),
            flatMap((value: ApiResponse) => {
                if (!value.success) {
                    const msg = value.message || 'Error submitting job';
                    return throwError(msg)
                }
                return ctx.notification.watchJob(value.data.firebase_job_id, (job, toast) => {
                    let msg = job.message
                    let logs = job.getExtra('output_logs')
                    if (typeof logs === 'string') {
                        logs = logs.trim();
                        const blob = new Blob([logs], {type: 'text/plain'});
                        const fileURL = URL.createObjectURL(blob);
                        msg += `<br/><a href="${fileURL}" download="logs.txt">Output logs</a>`
                    }

                    toast.update({
                        description: msg,
                        style: ToastNotificationStyle.Success,
                        dismissable: true
                    })
                })
            })
        ).subscribe(value => {

        }, error1 => {

        });


    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    openPicker(ctx: string): void {
        this.pickerContextName = ctx;
        this.uploadSession.openPicker()
    }

    getDisplayName(pickerContext: string): string {
        switch (pickerContext) {
            case 'devices':
                return 'Importing Devices';
            case 'stores':
                return 'Importing Stores';
            case 'store_scores':
                return 'Importing Scores';
        }
        return ''
    }

}


interface UploadContext {
    key: string
    notification?: ToastNotification
}
