import { BaseModel } from '@looma/shared/models/base_model';
import { CursorFilter } from "@looma/shared/types/cursor_filter";
import { MediaContentVersion } from "@looma/shared/models/media_content_version";

export class MediaPlaylist extends BaseModel {
    id: string;
    name: string;
    versions: MediaPlaylistVersion[]
    version: MediaPlaylistVersion;

    activeVersion: MediaPlaylistVersion
    draftVersion: MediaPlaylistVersion
    latestVersion: MediaPlaylistVersion


    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'version', MediaPlaylistVersion);

        this.assignTypedObject(input, 'activeVersion', MediaPlaylistVersion);
        this.assignTypedObject(input, 'draftVersion', MediaPlaylistVersion);
        this.assignTypedObject(input, 'latestVersion', MediaPlaylistVersion);

        this.assignTypedArray(input, 'versions', MediaPlaylistVersion);
        if (Array.isArray(this.versions)) {
            for (const version of this.versions) {
                switch (version.status) {
                    case MediaPlaylistStatus.Active:
                        this.activeVersion = version;
                        break
                    case MediaPlaylistStatus.Draft:
                        this.draftVersion = version
                        this.draftVersion = version;
                        break
                }
            }
        }
        return this;
    }
}

export enum MediaPlaylistStatus {
    Unknown = 0,
    Draft = 1,
    Active = 2,
    Archived = 3
}

export class MediaPlaylistVersion extends BaseModel {
    id: string;
    name: string;
    firebasePath: string;
    entriesChecksum: string;
    checksum: string;
    status: MediaPlaylistStatus;
    playlistEntries: MediaPlaylistVersionEntry[]
    


    assign(input: any): this {
        super.assign(input);
        this.assignTypedArray(input, 'playlistEntries', MediaPlaylistVersionEntry);
        return this
    }
}

export class MediaPlaylistVersionEntry extends BaseModel {
    playlistIndex: number;
    mediaContentVersion: MediaContentVersion;

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'mediaContentVersion', MediaContentVersion);
        return this
    }
}


export interface MediaPlaylistMutationInput {
    id: string
    name: string
    entries: { mediaContentVersionId: number }[]
}


export interface MediaPlaylistFilter extends CursorFilter {
    cursor: string;
    id: string;
    query: string;
    standalone: boolean
}
