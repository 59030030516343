import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, observable, Observable} from "rxjs";

@Component({
    selector: 'lib-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {

    @Input("playList") playList: BehaviorSubject<VideoReference[]>;

    @ViewChild('videoA', {static: true}) videoA: ElementRef<HTMLVideoElement>;
    @ViewChild('videoB', {static: true}) videoB: ElementRef<HTMLVideoElement>;

    videoPlayers: VideoWrapper[];

    videoName: string
    isPlaying = false

    constructor() {
    }

    ngOnInit(): void {
        this.playList.subscribe(playList => {
            const playerA = new VideoWrapper(this.videoA, playList)
            const playerB = new VideoWrapper(this.videoB, playList)
            playerA.setOther(playerB)
            playerB.setOther(playerA)
            playerA.onPlayingFile.subscribe(ev => {
                this.isPlaying = true
                this.videoName = ev;
            })
            playerA.onPlaylistEnded.subscribe(ev => {
                this.isPlaying = false
            })
            
            playerB.onPlayingFile.subscribe(ev => {
                this.isPlaying = true
                this.videoName = ev;
            })
            playerB.onPlaylistEnded.subscribe(ev => {
                this.isPlaying = false
            })
            
            this.videoPlayers = [playerA, playerB]
        })
    }

    ngAfterViewInit() {
    }

    playPlaylist() {
        const firstPlayer = this.videoPlayers[0]
        firstPlayer.initialize(0).show().start()
    }

    stopPlayer() {
        this.videoPlayers.forEach(v => v.stop())
    }
}

class VideoWrapper {
    video: ElementRef<HTMLVideoElement>;
    other: VideoWrapper;
    currentPlaylistIdx: number;
    playList: VideoReference[];
    onPlayingFile: BehaviorSubject<string> = new BehaviorSubject<string>(null)
    onPlaylistEnded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)

    constructor(video: ElementRef<HTMLVideoElement>, playList: VideoReference[]) {
        this.video = video;
        this.playList = playList;

        video.nativeElement.style.display = 'none';
        video.nativeElement.muted = false;
        video.nativeElement.preload = 'auto';
        video.nativeElement.autoplay = false;

        video.nativeElement.onended = _ => {
            this.hide()
            if (this.other.currentPlaylistIdx < this.playList.length) {
                this.other.show().start()
            } else {
                this.onPlaylistEnded.next(true)
            }
        }
    }
    
    stop() {
        this.video.nativeElement.pause()
        this.hide()
        this.currentPlaylistIdx = 0;
        this.onPlaylistEnded.next(true)
    }

    setOther(player: VideoWrapper): void {
        this.other = player
    }

    show(): VideoWrapper {
        this.video.nativeElement.style.display = 'block';
        return this
    }

    hide(): VideoWrapper {
        this.video.nativeElement.style.display = 'none';
        return this;
    }

    initialize(playListIdx: number): VideoWrapper {
        this.currentPlaylistIdx = playListIdx;
        const src = this.playList[playListIdx].srcUrl;
        this.video.nativeElement.src = src;
        return this;
    }

    preload(playListIdx: number): void {
        this.currentPlaylistIdx = playListIdx;
        if (playListIdx >= this.playList.length) {
            return
        }
        const src = this.playList[playListIdx].srcUrl;
        this.video.nativeElement.src = src;
    }


    start(): void {
        if (this.currentPlaylistIdx > this.playList.length - 1) {
            return
        }

        this.other.preload(this.currentPlaylistIdx + 1)

        this.onPlayingFile.next(this.playList[this.currentPlaylistIdx].filmName)
        this.video.nativeElement.play().then();
    }
}

export interface VideoReference {
    srcUrl: string
    filmName?: string
}
