<div app-looma-page style="height: 100%">
    <ng-template [pageTab]="'Network Health Overview'">
        <app-network-health-overview-dashboard-tab></app-network-health-overview-dashboard-tab>
    </ng-template>
    <ng-template [pageTab]="'Network Health History'">
        <app-network-health-history-dashboard-tab></app-network-health-history-dashboard-tab>
    </ng-template>
</div>


