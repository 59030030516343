<div app-looma-page pageTitle="Users" pageIcon="person" [headerSideTpl]="headerSideTpl">
    <looma-grid [dataSource]="dataSource">
        <ng-template rowExpansionTemplate let-data="data">
            <div style="padding: 8px">
                <div class="property">
                    <div class="property_name">Account activated at</div>
                    <div class="property_value">{{getLastLoggedInAt(data.account_activated_at)}}</div>
                </div>
                <div class="property">
                    <div class="property_name">Last login at</div>
                    <div class="property_value">{{getLastLoggedInAt(data.last_logged_in_at)}}</div>
                </div>

                <div class="property" *ngIf="data.associations.brands.length > 0">
                    <div class="property_name">Brands</div>
                    <div class="property_value">
                        <mat-chip-list>
                            <mat-chip *ngFor="let brand of  data.associations.brands || []">{{brand.name}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>

                <div class="property" *ngIf="data.associations.childBrands.length > 0">
                    <div class="property_name">Child Brands</div>
                    <div class="property_value">
                        <mat-chip-list>
                            <mat-chip
                                *ngFor="let childBrand of  data.associations.childBrands || []">{{childBrand.name}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>

                <div class="property" *ngIf="data.associations.retailers.length > 0">
                    <div class="property_name">Retailers</div>
                    <div class="property_value">
                        <mat-chip-list>
                            <mat-chip
                                *ngFor="let retailer of  data.associations.retailers || []">{{retailer.retailer_name}}</mat-chip>
                        </mat-chip-list>

                    </div>
                </div>
            </div>
        </ng-template>
        
        <ng-template gridColumn="inviteSentAt" let-data="data">
            <div>
                {{getEmailNotification(data) | date: "mm/dd/YYYY HH:mm"}}
            </div>
        </ng-template>

        <ng-template gridColumn="status" let-data="data">
            <div
                [style.color]="data.status === 'Pending' ? 'orange' :
                       (data.status === 'Active' ? 'green' :
                       (data.status === 'SignUpRequested' ? 'red' :
                       (data.status === 'PendingPasswordSetup' ? 'blue' : 'inherit')))">
                {{ data.status === 'SignUpRequested' ? 'Sign Up Requested - ' + data.requestedAccessFrom :
                (data.status === 'PendingPasswordSetup' ? 'Pending Password Setup - ' + data.requestedAccessFrom : data.status) }}
            </div>
        </ng-template>
    </looma-grid>
</div>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <mat-form-field>
            <mat-label>Search by Name or Email</mat-label>
            <input autocomplete="off" matInput fxFlex (input)="searchFyNameOrEmail($event)"/>
        </mat-form-field>

        <app-autocomplete-search-field showClear="true" placeholder="Brand" searchObjectType="BrandPartner"
                                       (valueChanged)="onBrandPartnerFilterChanged($event)" selectionMode="single"
                                       style="width: 300px; padding-right: 20px"></app-autocomplete-search-field>

        <app-autocomplete-search-field showClear="true" placeholder="Retailer" searchObjectType="Retailer"
                                       (valueChanged)="onRetailerFilterChanged($event)" selectionMode="single"
                                       style="width: 300px; padding-right: 20px"></app-autocomplete-search-field>
        <button mat-raised-button (click)="addNewUser()">Add new user</button>
    </div>
</ng-template>