<div app-looma-page pageTitle="Store Images" pageIcon="insert_photo" [headerSideTpl]="headerSideTpl">
    <app-aspect-ratio-grid [dataLoader]="imagesLoader" [itemTemplate]="tplItem" style="height: 100%; flex:1"
                           [columnsCount]="4" aspectRatio="16:9+60px"></app-aspect-ratio-grid>
</div>

<ng-template #headerSideTpl>
    <mat-form-field style="width: 150px;margin-right:16px;">
        <mat-label>Source</mat-label>
        <mat-select value="Any" (valueChange)="onImageSourceFilterChanged($event)">
            <mat-option
                *ngFor="let opt of ['Any','sas-retail','device-magic','wiser', 'jacent', 'cloud-storage', 'typeform', 'uptime']"
                value="{{opt}}">{{opt}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100px;margin-right:16px;">
        <mat-label>Approved</mat-label>
        <mat-select value="Any" (valueChange)="onSelectionChanged('approved',$event)">
            <mat-option *ngFor="let opt of ['Any','Yes','No']" value="{{opt}}">{{opt}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100px;margin-right:16px;">
        <mat-label>Scored</mat-label>
        <mat-select value="Any" (valueChange)="onSelectionChanged('scores',$event)">
            <mat-option *ngFor="let opt of ['Any','Yes','No']" value="{{opt}}">{{opt}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100px;margin-right:16px;">
        <mat-label>Quality</mat-label>
        <mat-select value="Any" (valueChange)="onImageQualityChanged($event)">
            <mat-option *ngFor="let opt of ['High', 'Acceptable', 'Poor']" value="{{opt}}">{{opt}}</mat-option>
        </mat-select>
    </mat-form-field>

    <app-store-assignment
        [objectTypes]="[SearchableObject.Retailer, SearchableObject.ProductCategory, SearchableObject.Store]"></app-store-assignment>


    <app-autocomplete-search-field #promoPeriodSearch style="width: 150px; margin-right: 16px;"
                                   [showClear]="true"
                                   [searchObjectType]="SearchableObject.PromoPeriod"
                                   [placeholder]="SearchableObject.PromoPeriod.label">
    </app-autocomplete-search-field>

    <button mat-raised-button (click)="addDeviceSlotImage()">Add Image</button>

</ng-template>


<ng-template #tplItem let-data="data" let-index="index">
    <mat-card class="image_tile mat-elevation-z3" [attr.data.device-slot-image-id]="data.id">
        <div class="img" [style.background-image]="getBackgroundThumbImage(data)">
            <div class="versions" fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex style="color: white; font-weight: 500">{{data.source}}</span>
                <a target="_blank" href="{{data.original.url}}">original</a>
                <a target="_blank" href="{{data.large.url}}">optimized</a>
            </div>
        </div>
        <div class="bottom" fxLayout="row" fxLayoutAlign="space-between center">
            <button *ngIf="data.getWarnings()" mat-icon-button matTooltip="{{data.getWarnings()}}"
                    matTooltipPosition="above" (click)="editSlotImage(data)">
                <mat-icon color="warn">warning</mat-icon>
            </button>
            <div class="ml-16" fxFlex>
                <span class="h3 font-weight-500">{{data.displayName()}}<br/></span>
                <span class="mat-card-subtitle m-0">{{data.date |  date:'mediumDate'}}</span>
                <span class="mat-card-subtitle m-0"
                      *ngIf="data?.lastScoreSubmission?.submittedByUser">{{data?.lastScoreSubmission?.submittedByUser?.display_name}}</span>
            </div>
            <div class="actions">
                <button mat-icon-button matTooltip="View Photos" [routerLink]="['/device-slot-images', data.id]">
                    <mat-icon>collections</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Edit" (click)="editSlotImage(data)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Delete" (click)="deleteSlotImage(data)" style="color: red">
                    <mat-icon>remove_circle</mat-icon>
                </button>
            </div>
        </div>
    </mat-card>
</ng-template>
