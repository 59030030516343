<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center" *ngIf="currentUser">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="navbar-toggle-button" (click)="setSidebarOpened(true)"
                    fxShow fxHide.xl>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" fxShow fxHide.xl></div>
            <div class="px-8 px-md-16">
            </div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" [src]="currentUser.photo_url" tabindex="-1">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{ currentUser.display_name }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" overlapTrigger="false">
                <button mat-menu-item class="" (click)="performLogout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
