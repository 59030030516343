import {Component, Inject, OnInit} from '@angular/core';
import {LayoutService} from "../../../../services/layout.service";
import {Observable, Subscription} from "rxjs";
import {SurveyVersion} from "@looma/shared/models/survey_version";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../../services/toast-notification.service";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import firebase from 'firebase/compat/app';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import gql from "graphql-tag";
import {SurveyProductEntry} from "@looma/shared/models/survey_product_entry";

@LifecycleHooks()
@Component({
    selector: 'app-survey-home-screen-edit',
    templateUrl: './survey-home-screen-edit.component.html',
    styleUrls: ['./survey-home-screen-edit.component.scss']
})
export class SurveyHomeScreenEditComponent implements OnInit {

    surveyVersion: SurveyVersion
    isBusy = false
    form: FormGroup
    firebaseKey: string

    private readonly uploadSession: UploadSession;
    private uploadSubscription: Subscription;
    private fileAddedSubscription: Subscription;
    private uploadToastNotif: ToastNotification;
    isUploading: boolean;

    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<SurveyHomeScreenEditComponent>,
                public svcLayout: LayoutService,
                private svcApi: ApiDataService,
                private svcUpload: UploadService,
                private svcToastNotif: ToastNotificationService,
                @Inject(MAT_DIALOG_DATA) data: SurveyHomeScreenEditData) {

        this.surveyVersion = data.surveyVersion

        this.form = fb.group({
            homeScreenName: new FormControl({value: this.surveyVersion?.homeScreenName, disabled: false}, [Validators.required]),
        });

        this.uploadSession = this.svcUpload.getUploadSession('survey_home_screen', {
            fileTypes: ['html'],
            multiSelection: false
        });

        this.fileAddedSubscription = this.uploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                this.uploadToastNotif = this.svcToastNotif.create({
                    title: "Survey home screen upload",
                    description: 'Uploading',
                    dismissable: false,
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                });
                this.isUploading = true;
            });

        this.uploadSubscription = this.uploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                const storage = firebase.storage();
                this.firebaseKey = file.firebaseKey
                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.surveyVersion.homeScreenUrl = url
                    this.dismissNotification();
                });
            }, error => {
                this.svcLayout.showSnackMessage('File upload error');
                this.dismissNotification();
            });
    }

    static open(svcLayout: LayoutService, surveyVersion: SurveyVersion): Observable<SurveyVersion> {
        const data: SurveyHomeScreenEditData = {
            surveyVersion: surveyVersion,
        };
        return svcLayout.openDialogForResult(SurveyHomeScreenEditComponent, {
            data: data,
            width: '800px'
        })
    }

    ngOnInit(): void {
    }

    onSave() {
        this.isBusy = true
        this.svcApi.rawObjectMutate(MUTATION_UPLOAD_HOME_SCREEN, {
            surveyId: this.surveyVersion.id,
            homeScreenName: this.form.get("homeScreenName").value,
            docFirebaseKey: this.firebaseKey
        }, SurveyProductEntry)
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(result => {
                this.isBusy = false
                if (result.success) {
                    this.dialogRef.close(result.data)
                } else {
                    this.svcLayout.showMutationResultMessage(result)
                }
            })
    }

    onCancel() {
        this.dialogRef.close()
    }

    private dismissNotification(): void {
        if (this.uploadToastNotif) {
            this.uploadToastNotif.dismiss();
            this.uploadToastNotif = null;
        }
        this.isUploading = false;
    }

    openFilePicker() {
        this.uploadSession.openPicker()
    }
}

export interface SurveyHomeScreenEditData {
    surveyVersion: SurveyVersion
}


export const MUTATION_UPLOAD_HOME_SCREEN = gql`
mutation uploadHomePage($surveyId: ID!, $docFirebaseKey: String, $homeScreenName: String!) {
    uploadHomePage(surveyId: $surveyId, homeScreenName: $homeScreenName, docFirebaseKey: $docFirebaseKey) {
          success
          message
          validationErrors {
            name
            errors
          }
          promoPeriodSurvey {
            id        
            isDirty
            firebasePath
            homeScreenUrl
            homeScreenName        
          }  
    }
}
 `;
