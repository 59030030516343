import {BaseModel} from "@looma/shared/models/base_model";
import {SurveyContent} from "@looma/shared/models/survey_content";
import {MediaContent} from "@looma/shared/models/media_content";
import {DeviceSlotType} from "@looma/shared/models/device_slot_type";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";
import {BrandProduct} from "@looma/shared/models/brand_product";

export class SurveyProductEntry extends BaseModel {
    id: string
    name: string
    description: string
    tastingNoteName: string
    tastingNoteImageUrl: string
    tastingNoteImageKey: string
    brandProduct: BrandProduct
    brandPromoCampaign: BrandPromoCampaign
    wineType: SurveyContent
    winePricing: SurveyContent
    placementImageUrl: string
    placementImageKey: string
    mediaContents: MediaContent[]

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'brandProduct', BrandProduct);
        this.assignTypedObject(input, 'brandPromoCampaign', BrandPromoCampaign);
        this.assignTypedObject(input, 'wineType', SurveyContent);
        this.assignTypedObject(input, 'winePricing', SurveyContent);
        this.assignTypedArray(input, 'deviceSlotTypes', DeviceSlotType);
        this.assignTypedArray(input, 'mediaContentIds', MediaContent);
        return this
    }
}

export interface SurveyProductEntryInput {
    id?: string
    retailerPromoPeriodId: string
    deviceSlotSegmentId: string
    wineTypeId?: string
    winePricingId: string
    tastingNoteName: string
    brandProductId: string
    brandCampaignId: string
    description: string
    mediaContentIds: string[]
    tastingNoteFirebaseImageKey?: string
    placementImageFirebaseImageKey?: string
}
