import {Component, Inject, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ModelEditDialog} from "../../../shared/model_edit_dialog";
import {LayoutService} from "../../../services/layout.service";
import {ApiDataService} from "../../../services/api-data.service";
import {
    SurveyContent,
    SurveyContentInput,
    SurveyContentReference,
    SurveyContentType
} from "@looma/shared/models/survey_content";
import {Observable, Subscription} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import firebase from 'firebase/compat/app';
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../services/toast-notification.service";
import gql from "graphql-tag";
import {MUTATION_RESPONSE_FIELDS} from "../../../services/queries";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {BaseModel} from "@looma/shared/models/base_model";

@LifecycleHooks()
@Component({
    selector: 'app-survey-data-edit',
    templateUrl: './survey-data-edit.component.html',
    styleUrls: ['./survey-data-edit.component.scss']
})
export class SurveyDataEditComponent extends ModelEditDialog<SurveyContent> implements OnInit {

    surveyContent: SurveyContent;
    surveyContentIndex: Map<SurveyContentType, SurveyContent[]>;
    imageUrl: string;

    form: FormGroup;

    isUploadingImage: boolean;
    isSavingData = false;

    private uploadToastNotif: ToastNotification;
    private newImageFirebaseKey: string;
    private readonly uploadSession: UploadSession;
    private uploadSubscription: Subscription;
    private fileAddedSubscription: Subscription;

    static open(svcLayout: LayoutService, surveyContent: SurveyContent, surveyContentIndex: Map<SurveyContentType, SurveyContent[]>): Observable<SurveyContent> {
        const data: SurveyDataEditDialogData = {
            surveyContent: surveyContent,
            surveyContentIndex: surveyContentIndex
        }
        const dialogConfig: MatDialogConfig = {
            width: '800px',
            data: data,
        }

        return svcLayout.openDialogForResult(SurveyDataEditComponent, dialogConfig)
    }

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<SurveyDataEditComponent>,
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private svcUpload: UploadService,
        private svcToastNotif: ToastNotificationService,
        @Inject(MAT_DIALOG_DATA) data: SurveyDataEditDialogData
    ) {
        super();

        this.surveyContent = data.surveyContent;
        this.surveyContentIndex = data.surveyContentIndex
        this.imageUrl = this.surveyContent.imageUrl

        this.uploadSession = svcUpload.getUploadSession('survey_content_image', {
            fileTypes: ['image'],
            multiSelection: false
        });

        this.form = fb.group({
            mealTypes: new FormControl({value: this.surveyContent.references, disabled: false},),
            displayName: new FormControl({
                value: this.surveyContent?.displayName,
                disabled: false
            }, [Validators.required]),
            contentKey: new FormControl({
                value: this.surveyContent?.contentKey,
                disabled: !this.surveyContent.isNewRecord()
            }, [Validators.required]),
            description: new FormControl({value: this.surveyContent?.description, disabled: false},),
        });

        this.fileAddedSubscription = this.uploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                this.uploadToastNotif = this.svcToastNotif.create({
                    title: "Survey content image upload",
                    description: 'Uploading',
                    dismissable: false,
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                });
                this.isUploadingImage = true;
            });

        this.uploadSubscription = this.uploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                const storage = firebase.storage();
                this.newImageFirebaseKey = file.firebaseKey;

                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.imageUrl = url;
                    this.dismissNotification();
                });
            }, error => {
                this.svcLayout.showSnackMessage('Update error');
                this.isSavingData = false;
                this.dismissNotification();
            });
    }

    ngOnInit(): void {
    }

    onSave(): Observable<MutationResponse<SurveyContent>> {
        if (this.surveyContent.isNewRecord() && !this.newImageFirebaseKey) {
            this.svcLayout.showMessage("Please upload an image")
            return
        }

        const references: SurveyContent[] = this.form.get("mealTypes")?.value
        const referencesToSave = references?.map(r => {
            return {
                surveyContentType: r.surveyContentType,
                id: r.id,
            } as SurveyContentReference
        })

        const orderIdx = this.surveyContent.isNewRecord() ? -1 : this.surveyContent.orderIdx;
        
        let contentKey = this.surveyContent.contentKey
        if(this.surveyContent.isNewRecord()){
            contentKey = this.form.get("contentKey").value
        }
        
        const data: SurveyContentInput = {
            id: this.surveyContent.id,
            surveyContentType: this.surveyContent.surveyContentType,
            displayName: this.form.get("displayName").value,
            description: this.form.get("description")?.value,
            imageFirebaseKey: this.newImageFirebaseKey,
            retailerPromoPeriodId: this.surveyContent.retailerPromoPeriodId,
            retailerPromoProgramId: this.surveyContent.retailerPromoProgramId,
            contentKey: contentKey,
            references: referencesToSave,
            orderIdx: orderIdx,
        }

        const op = this.surveyContent.isNewRecord() ? MutationOperation.Create : MutationOperation.Update;
        return this.svcApi.rawObjectMutate(MUTATION_UPSERT_SURVEY_CONTENT, {
            op: op,
            input: data
        }, SurveyContent).pipe(takeUntil(Utils.onDestroy(this)))
    }

    uploadImage(): void {
        this.uploadSession.openPicker();
    }

    isSameObj(a: any, b: any): boolean {
        return (a && b) && a === b;
    }

    private dismissNotification(): void {
        if (this.uploadToastNotif) {
            this.uploadToastNotif.dismiss();
            this.uploadToastNotif = null;
        }
        this.isUploadingImage = false;
    }

    getMealTypes(): SurveyContent[] {
        return this.surveyContentIndex.get(SurveyContentType.MealType)
    }

    isWineTypeSelection() {
        return this.surveyContent.surveyContentType == SurveyContentType.WineType
    }

    itemsEqual(a: BaseModel, b: BaseModel): boolean {
        if (a && b) {
            if (a.constructor === b.constructor) {
                return a.getId() === b.getId()
            }
        }
        return a === b
    }
}

export interface SurveyDataEditDialogData {
    surveyContent: SurveyContent
    surveyContentIndex: Map<SurveyContentType, SurveyContent[]>
}

export const MUTATION_UPSERT_SURVEY_CONTENT = gql`
mutation upsertSurveyContent($op:MutationOperation!, $input:SurveyContentInput!) {
  result: upsertSurveyContent(op:$op, input: $input) {
    ${MUTATION_RESPONSE_FIELDS}    
    surveyContent {
      id
      retailerPromoPeriodId
      retailerPromoProgramId
      surveyContentType
      displayName
      contentKey
      description    
      imageUrl
      orderIdx
      references {
        id
        displayName
        surveyContentType
      }
    }
  }
}
 `;
