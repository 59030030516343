import {BaseModel} from '@looma/shared/models/base_model';
import {BrandPartner} from '@looma/shared/models/brand_partner'
import {Retailer} from "@looma/shared/models/retailer";
import {MediaContentVersion, MediaContentVersionType} from "@looma/shared/models/media_content_version";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";

export enum MediaContentType{
    Film = "Film",
    SplashScreen = "SplashScreen",
    AncillaryContent = "AncillaryContent"
}

export const AllMediaContentTypes = [
    MediaContentType.Film,
    MediaContentType.SplashScreen,
    MediaContentType.AncillaryContent
]

export class MediaContent extends BaseModel{

    public static contentTypes = [
        { id: MediaContentType.Film, name: "Films" },
        { id: MediaContentType.SplashScreen, name: "Splash Screens" },
        { id: MediaContentType.AncillaryContent, name: "Shopper Ed Slides" },
    ]

    id: string;
    displayName: string;
    contentType: MediaContentType;
    externalUrl: string;
    externalThumbUrl: string;
    externalDownloadUrl: string;
    description: string;
    filmMaker: string;
    vimeoVideoId: string;
    storyType: string;
    slideType: string
    storyIqScore: number
    featuredProduct: string;
    isDraft: boolean;
    isVisible: boolean;
    filmShareUrl: string;
    brandPartner: BrandPartner;
    retailer: Retailer;
    defaultMediaContentVersion: MediaContentVersion;
    cleanMediaContentVersion: MediaContentVersion;
    mediaContentVersions: MediaContentVersion[];
    cleanVersions: MediaContentVersion[];
    featuredBrandCampaigns: BrandPromoCampaign[];
    updatedAt: Date;
    createdAt: Date;
    externalKey: string

    filmSplashImageUrl: string;

    public static mediaContentTypes(): string[]{
        return Object.values(MediaContentType);
    }

    assign(input: any): this{
        super.assign(input);
        this.assignTypedObject(input, 'brandPartner', BrandPartner);
        this.assignTypedObject(input, 'retailer', Retailer);
        this.assignTypedObject(input, 'defaultMediaContentVersion', MediaContentVersion);
        this.assignTypedObject(input, 'cleanMediaContentVersion', MediaContentVersion);
        this.assignTypedArray(input, 'mediaContentVersions', MediaContentVersion);
        this.assignTypedArray(input, 'cleanVersions', MediaContentVersion);
        this.assignTypedArray(input, 'featuredBrandCampaigns', BrandPromoCampaign);
        this.assignDate(input, 'updatedAt');
        this.assignDate(input, 'createdAt');
        return this;
    }

    get latestFeaturedBrandCampaign(): BrandPromoCampaign{
        return Array.isArray(this.featuredBrandCampaigns) && this.featuredBrandCampaigns.length ? this.featuredBrandCampaigns[0] : null
    }

    isNewRecord(): boolean{
        return !this.id
    }

    isFilm(): boolean{
        return this.contentType == MediaContentType.Film
    }

    isShopperEdSlide(): boolean{
        return this.contentType == MediaContentType.AncillaryContent
    }

    isSplashScreen(): boolean{
        return this.contentType == MediaContentType.SplashScreen
    }
}

export interface UploadMediaContentInput{
    mediaContentId: string,
    fileName: string,
    gsFileLocation: string,
    contentType: MediaContentType,
    filmTypeEnum: MediaContentVersionType,
    retailerId: string,
    brandId: string,
    isGenerated: boolean
}

export interface MediaContentFilterCriteria{
    cursor?: string;
    textSearchPattern?: string;

    parentBrandIds?: string[]
    childBrandIds?: string[]
    contentTypes?: string[];
}

export interface MediaContentInput{
    id: string;
    contentType?: string;
    displayName: string;
    filmMaker: string;
    brandPartnerId: string;
    retailerId?: string;
    description?: string;
    vimeoVideoId?: string;
    storyType?: string;
    slideType?: string;
    featuredProduct?: string;
    storyIqScore?: string;
    thumbnailImageStorageKey?: string;
    splashImageStorageKey?: string;
    isVisible?: boolean;
}

export const StoryTypes = [
    'N/A',
    'Human / Personal',
    'Origin / History',
    'Product Story',
    'Product Ed',
    'Process',
    'Place / Community',
    'Social Impact / Cause',
    'Culture / Lifestyle'
];
