import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Device} from '@looma/shared/models/device';
import {debounceTime, delay, distinctUntilChanged, filter, flatMap, take, takeUntil} from 'rxjs/operators';
import {appAnimations} from '@looma/shared/animations';
import {FormControl} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {TableColumn, TableColumnSpec} from '../../../../layout/components/looma-grid/table-column';
import {DataSourceEntry, GridDataSource} from '../../../../layout/components/looma-grid/grid-data-source';
import {LoomaGridComponent} from '../../../../layout/components/looma-grid/looma-grid.component';
import {ToastNotificationService} from '../../../../services/toast-notification.service';
import {Observable} from 'rxjs';
import {LoomaGridFilterComponent} from '../../../../layout/components/looma-grid-filter/looma-grid-filter.component';
import {
    DeviceFieldSearchCondition,
    DeviceFilterableField,
    DeviceSearchCriteria
} from '@looma/shared/models/device_search';
import {LayoutService} from '../../../../services/layout.service';
import {ApiDataService} from '../../../../services/api-data.service';
import {DeviceCommand} from '@looma/shared/types/device_commands';
import {NamedValue} from '@looma/shared/types/named_value';
import {DeviceCommandService} from '../../../../services/device-command.service';
import {FilterConditionOperator} from '@looma/shared/search';
import {BaseCursorLoader} from '@looma/shared/cursor_loader';
import {CursorFeed} from '@looma/shared/cursor_feed';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {exportToCsv, Utils} from '@looma/shared/utils';
import {PreferencesService} from '@looma/shared/services/preferences.service';
import Timeout = NodeJS.Timeout;

const PREFS_KEY_VISIBLE_COLUMNS = 'devices.visible_columns_prefs';
const PREFS_KEY_ACTIVE_FILTER = 'devices.list.active_filter';

@LifecycleHooks()
@Component({
    selector: 'app-devices',
    templateUrl: './devices-list.component.html',
    styleUrls: ['./devices-list.component.scss'],
    animations: appAnimations,
    providers: []
})
export class DevicesListComponent implements OnInit, AfterViewInit, OnDestroy {

    searchInput: FormControl;

    updateVisibleColumnsTimeout: Timeout;

    gridDataSource: DeviceDataSource;

    filterVisible = false;

    lastReceivedSearchCriteria: DeviceSearchCriteria = new DeviceSearchCriteria();

    constructor(private apollo: Apollo,
                private svcPrefs: PreferencesService,
                private svcToastNotifications: ToastNotificationService,
                private svcLayout: LayoutService,
                private svcApi: ApiDataService,
                private svcDeviceCommands: DeviceCommandService
    ) {
        this.searchInput = new FormControl('');
    }

    @ViewChild(LoomaGridComponent, {static: true}) devicesGrid;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @ViewChild(LoomaGridFilterComponent, {static: true}) devicesFilterComponent;


    ngAfterViewInit(): void {
        this.gridDataSource.onDataAvailable().pipe(
            delay(100),
            takeUntil(Utils.onDestroy(this)),
            take(1)
        ).subscribe(_ => {
            const scrollIndex = this.svcPrefs.getSessionValue('device_list_scroll', -1);
            if (scrollIndex > 0) {
                this.devicesGrid.scrollToIndex(scrollIndex)
            }

            this.devicesGrid.onScrollIndexChanged().subscribe(newIndex => {
                this.svcPrefs.setSessionValue('device_list_scroll', newIndex);
            });
        });

    }


    ngOnInit(): void {

        this.gridDataSource = new DeviceDataSource(this.svcApi, this.svcPrefs, this.svcLayout.isActiveMediaQuery(['sm', 'xs']));


        this.gridDataSource.dataLoader.onInvalidate().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.devicesGrid?.scrollToIndex(0);
        })

        this.setFilter(this.getSavedFilter());

        this.searchInput.valueChanges
            .pipe(
                takeUntil(Utils.onDestroy(this)),
                debounceTime(300),
                distinctUntilChanged()
            ).subscribe(searchText => {
            this.setFilter(searchText, false);
        });

        const prevFilters = this.svcPrefs.getSessionValue(PREFS_KEY_ACTIVE_FILTER, new DeviceSearchCriteria());
        if (prevFilters) {
            prevFilters.text = this.getSavedFilter()
            this.onSearchCriteriaChanged(prevFilters, false)
        }

    }

    ngOnDestroy(): void {
    }

    getSavedFilter(): string {
        return (this.svcPrefs.getSessionValue('device_list_filter') || '').toString();
    }

    setFilter(filterText: string, updateInput?: boolean): void {
        if (typeof updateInput !== 'boolean') {
            updateInput = true;
        }
        this.svcPrefs.setSessionValue('device_list_filter', filterText);
        if (this.gridDataSource) {
            this.gridDataSource.setTextFilter(filterText);
        }
        if (updateInput) {
            this.searchInput.setValue(filterText)
        }
    }

    onColumnSelectionChanged(column: TableColumnSpec, event: MouseEvent): void {
        clearTimeout(this.updateVisibleColumnsTimeout);

        this.updateVisibleColumnsTimeout = setTimeout(() => {
            this.updateVisibleColumns()
        }, 100)


    }

    onColumnSortDrop(event: CdkDragDrop<string[]>): void {
        this.gridDataSource.getData()
        this.gridDataSource.handleColumnMoved(event.previousIndex, event.currentIndex);
    }

    updateVisibleColumns(): void {
        this.gridDataSource.resetVisibleColumns();
        this.gridDataSource.saveUserPrefs()
    }


    onSearchCriteriaChanged(criteria: DeviceSearchCriteria, fromUser: boolean): void {
        this.svcPrefs.setSessionValue(PREFS_KEY_ACTIVE_FILTER, criteria);
        this.gridDataSource.setFilterCriteria(criteria);
        this.lastReceivedSearchCriteria = criteria;

        if (!fromUser) {
            this.devicesFilterComponent.setFilterCriteria(criteria);
        }

    }

    clearSearchCriteria(): void {
        this.devicesFilterComponent.clearFilters();
        this.gridDataSource.setFilterCriteria(new DeviceSearchCriteria())
    }

    saveCriteria(criteria: DeviceSearchCriteria): void {
        this.svcLayout.prompt('Create Device Group', 'Device Group Name').pipe(
            filter(value => value && value.trim() !== ''),
            flatMap(groupName => this.svcApi.saveFilter(groupName, criteria))
        ).subscribe(value => {
        })

    }

    invokeCommand(cmd: DeviceCommand): void {
        let criteria: DeviceSearchCriteria;
        const selection = this.gridDataSource.selection.getSelection();
        let audienceSize = 0;
        if (selection.just) {
            criteria = new DeviceSearchCriteria();
            const condition = new DeviceFieldSearchCondition();
            condition.deviceField = DeviceFilterableField.Id;
            condition.operator = FilterConditionOperator.In;
            condition.values = selection.just.map(value => {
                return NamedValue.from(value + '', value + '')
            });
            criteria.filters.push(condition);
            audienceSize = selection.just.length;
        } else {
            criteria = this.lastReceivedSearchCriteria.clone();
            audienceSize = this.gridDataSource.getItemCount();
            if (selection.except) {
                const condition = new DeviceFieldSearchCondition();
                condition.deviceField = DeviceFilterableField.Id;
                condition.operator = FilterConditionOperator.NotIn;
                condition.values = selection.except.map(value => {
                    return NamedValue.from(value + '', value + '')
                });
                criteria.filters.push(condition);
                audienceSize -= selection.except.length;
            }

        }

        if (audienceSize <= 0) {
            return
        }

        this.svcLayout.confirm('Sending command', cmd.confirmationMessage(audienceSize)).subscribe(value => {
            if (value) {
                this.svcDeviceCommands.sendCommand(cmd, criteria)
            }
        });
    }

    downloadCsv() {
        this.gridDataSource.loadAllRecords().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(values => {
            const rows: any[][] = [];
            let idx = 0;
            const colsCount = this.gridDataSource.visibleColumns.length;
            const headers: any[] = new Array(colsCount);
            rows.push(headers);
            for (const col of this.gridDataSource.visibleColumns) {
                let label = col.label;
                switch (col.key) {
                    case 'deviceStatus':
                        label = 'Status';
                        break;
                    default:
                        break;
                }
                headers[idx++] = label;
            }
            for (const v of values) {
                const row: any[] = new Array(colsCount);
                rows.push(row)
                idx = 0;
                for (const col of this.gridDataSource.visibleColumns) {
                    row[idx++] = col.formatToPrimitive(v[col.key], v)
                }
            }

            exportToCsv('devices.csv', rows)
        })
    }

}


class DeviceEntry extends DataSourceEntry<Device> {

    @TableColumn({
        label: '',
        customizable: false,
        width: '40px',
        flags: TableColumn.FLAG_CUSTOMIZABLE | TableColumn.FLAG_FILTERABLE,
        formatToPrimitive: (fieldValue) => {
            switch (fieldValue) {
                case 1:
                    return 'green'
                case 2:
                    return 'yellow'
                case 3:
                    return 'orange'
                case 4:
                default:
                    return 'red'
            }
        }

    })
    deviceStatus: number;

    @TableColumn({label: 'Device Name', filterable: true, width: '150px'})
    deviceName: string;

    @TableColumn({label: 'Android ID', filterable: true, width: '150px'})
    deviceId: string;

    @TableColumn({label: 'Wifi Mac Address', filterable: true, visible: false, width: '150px'})
    assetTag: string;

    @TableColumn({label: 'Retailer', filterable: true, width: '40px'})
    retailerName: string;

    @TableColumn({label: 'Active Application', filterable: true, width: '100px', alignContent: 'center'})
    kioskModeAppName: string;

    @TableColumn({label: 'Assigned Campaign', filterable: true, width: '170px',})
    assignedCampaignName: string;

    @TableColumn({label: 'Active Campaign', filterable: true, width: '170px',})
    activeCampaignName: string;

    @TableColumn({label: 'Active Playlist', filterable: true, visible: true, width: '100px',})
    activePlaylistReference: string;

    @TableColumn({label: 'Assigned Playlist', filterable: true, visible: false, width: '100px',})
    assignedPlaylistReference: string;

    retailerStringId: string;

    @TableColumn({label: 'Store #', filterable: true, width: '80px', alignContent: 'center'})
    retailerStoreNumber: string;

    @TableColumn({label: 'Street', width: '250px'})
    retailerStoreStreet: string;

    @TableColumn({label: 'City', width: '150px'})
    retailerStoreCity: string;

    @TableColumn({label: 'State', width: '50px'})
    retailerStoreState: string;

    @TableColumn({label: 'Region', filterable: true, width: '40px', alignContent: 'center'})
    loomaRegion: string;

    @TableColumn({label: 'Category', filterable: true, width: '80px', alignContent: 'center'})
    productCategoryName: string;

    @TableColumn({label: 'SSID', filterable: true, visible: true, width: '100px'})
    ssid: string;


    @TableColumn({
        label: 'Last Reported', width: '120px', formatToPrimitive: (fieldValue, parentObject) => {
            return (new Date(Date.now() - (parentObject as DeviceEntry).secondsSincePlaybackReported)).toLocaleString()
        }
    })
    lastPlaybackReportedAt: Date;

    @TableColumn({
        label: 'Last Heartbeat', width: '120px',
    })
    lastHeartbeatAt: string;

    @TableColumn({
        label: 'Last Rebooted', width: '120px', visible: false, formatToPrimitive: (fieldValue, parentObject) => {
            return (new Date(Date.now() - (parentObject as DeviceEntry).secondsSinceRebooted)).toLocaleString()
        }
    })
    lastRebootedAt: Date;

    @TableColumn({label: 'Outdated apps', visible: false, width: '80px', alignContent: 'center',})
    outdatedAppsCount: number;

    @TableColumn({label: 'Installed', visible: false, width: '60px', alignContent: 'center'})
    isInstalled: boolean;

    @TableColumn({label: 'Volume', visible: false, width: '60px', alignContent: 'center'})
    playerVolume = '-';

    @TableColumn({label: 'Current volume', visible: true, width: '50px', alignContent: 'center'})
    adjustedVolume: number;

    @TableColumn({label: 'Auto reboot', visible: true, width: '50px', alignContent: 'center'})
    autoReboot: string;

    @TableColumn({label: 'Disk Usage', visible: true, width: '70px', alignContent: 'center'})
    diskUsage: string;

    @TableColumn({label: 'Screen Size', visible: true, width: '80px', alignContent: 'center'})
    screenSize: string;

    @TableColumn({label: 'Android Version', visible: true, width: '70px', alignContent: 'center'})
    androidVersion: string;

    secondsSincePlaybackReported: number;
    secondsSinceRebooted: number;

    isAssigned: boolean;
    id: string;

    private data: Device;

    assign(device: Device): DeviceEntry {
        this.id = device.id;
        this.deviceId = device.device_id;
        this.isAssigned = !!device.assignation;
        this.assetTag = device.remote_wifi_info?.mac_address

        this.ssid = device.remote_wifi_info?.ssid

        this.retailerStoreStreet = '-'
        this.retailerStoreCity = '-'
        this.retailerStoreState = '-'

        if (this.isAssigned) {
            const assignation = device.assignation;
            const store = assignation.store;
            const retailer = store.retailer;

            this.deviceName = device.assignation.name;
            this.productCategoryName = device.assignation.product_category.category_name;
            this.retailerStringId = retailer.retailer_id;
            this.retailerName = retailer.retailer_id;
            if (store.retailer_store_num > 0) {
                this.retailerStoreNumber = store.getPaddedStoreNum();
            }
            this.loomaRegion = store.retailer_region.region_name;
            // Hack to parse out street names which embed the full address.
            const street = device.assignation?.store?.location?.street_address;
            if (street != null) {
                const result = street.split(',');
                this.retailerStoreStreet = result[0];
            } else {
                this.retailerStoreStreet = " ";
            }
            this.retailerStoreCity = device.assignation?.store?.location?.city;
            this.retailerStoreState = device.assignation?.store?.location?.state;
        }
        if (device.remote_device_info) {
            const info = device.remote_device_info;
            if (info.last_rebooted_at) {
                this.lastRebootedAt = info.last_rebooted_at;
                this.secondsSinceRebooted = Date.now() - this.lastRebootedAt.getTime();
            }

            this.adjustedVolume = info.adjusted_volume
        }


        if (device.remote_playback_info) {
            this.lastRebootedAt = device.remote_playback_info.session_updated_at;
            this.secondsSincePlaybackReported = device.remote_playback_info.session_updated_ago * 1000;
        }

        this.lastHeartbeatAt = '-'
        if (device.remote_device_info.last_heartbeat_at) {
            this.lastHeartbeatAt = Utils.formatDate(device.remote_device_info.last_heartbeat_at, 'short')
        }

        if (device.remote_status_info) {
            this.deviceStatus = device.remote_status_info.playback_status;
            this.outdatedAppsCount = device.remote_status_info.installed_apps_status;
        }

        if (device.remoteDeviceCampaignInfo) {
            this.assignedCampaignName = device.remoteDeviceCampaignInfo.assignedCampaignName || "-";
            this.activeCampaignName = device.remoteDeviceCampaignInfo.activeCampaignName || "-";
            this.activePlaylistReference = device.remoteDeviceCampaignInfo.activePlaylist || "-";
            this.assignedPlaylistReference = device.remoteDeviceCampaignInfo.assignedPlaylist || "-";
        }

        this.isInstalled = device.assignation && device.assignation.is_installed || false;


        if (device.remote_device_info) {
            this.playerVolume = this.makeStringPercent(device.remote_device_info.player_volume)
        }

        this.kioskModeAppName = device.assignation?.kioskDeviceApp?.app_name || '-'

        this.autoReboot = device.remoteDeviceConfig.autoRebootEnabled ? 'Yes' : 'No'
        this.diskUsage = device.diskInfo ? device.diskInfo.usedPercent() + '%' : '-'
        this.screenSize = device.screenInfo && device.screenInfo.width * device.screenInfo.height > 0 ? `${device.screenInfo.width}x${device.screenInfo.height}` : '-'
        this.androidVersion = device.remote_device_info.androidVersion && (device.remote_device_info.androidVersion.major > 0) ? `${device.remote_device_info.androidVersion.major}` : '-'

        return this;
    }

    private makeStringPercent(num: number): string {
        if (num >= 0 && num <= 1) {
            num = Math.round(num *= 100)
            return `${num}%`
        }

        return '-'
    }


    getId(): string {
        return this.id;
    }

    getData(): Device {
        return this.data;
    }

}

interface SavedColumnInfo {
    key: string
    visible: boolean
}


class DeviceLoader extends BaseCursorLoader<Device> {
    private filterCriteria: DeviceSearchCriteria = new DeviceSearchCriteria();

    constructor(private svcApiData: ApiDataService) {
        super()
    }

    next(startFrom: string, pageSize: number = NaN): Observable<CursorFeed<Device>> {
        const filterCriteria = this.filterCriteria.clone();
        filterCriteria.cursor = startFrom;
        if (!isNaN(pageSize)) {
            filterCriteria.pageSize = pageSize;
        }
        return this.svcApiData.getDeviceList(filterCriteria)
    }

    setFilterCriteria(filterCriteria: DeviceSearchCriteria): void {
        this.filterCriteria = filterCriteria;
        this.invalidate()
    }

    setTextFilter(text: string) {
        const searchText = (text || '').trim();
        if ((this.filterCriteria.text || '') != text) {
            const filterCriteria = this.filterCriteria.clone();
            filterCriteria.text = searchText;
            filterCriteria.cursor = null;
            this.setFilterCriteria(filterCriteria);
        }

    }


}


class DeviceDataSource extends GridDataSource<Device, DeviceEntry> {

    private localFilter = '';
    private localFilteredData: DeviceEntry[] = null;
    dataLoader: DeviceLoader;

    constructor(protected svcApiData: ApiDataService, private svcPrefs: PreferencesService, private isMobile) {
        super(DeviceEntry);

        let originalColumns = this.gridColumns;

        const stickyColumns = originalColumns.filter(value => !value.customizable);

        let visibleColumnInfo: SavedColumnInfo[] = svcPrefs.getStoredValue(PREFS_KEY_VISIBLE_COLUMNS, null);
        if (isMobile) {
            const visibleCols = ['deviceStatus', 'deviceId', 'deviceName']
            visibleColumnInfo = this.gridColumns.map(value => {
                return {
                    key: value.key,
                    visible: visibleCols.indexOf(value.key) >= 0
                }
            })
        }
        if (visibleColumnInfo) {
            let newColumns: TableColumnSpec[] = [];
            const colsMap: Map<string, TableColumnSpec> = new Map();
            for (const col of originalColumns) {
                colsMap.set(col.key, col);
            }
            for (const info of visibleColumnInfo) {

                if (colsMap.has(info.key)) {
                    const col = colsMap.get(info.key);
                    col.visible = info.visible;
                    newColumns.push(col);
                    colsMap.delete(info.key);
                }

            }
            if (colsMap.size > 0) {
                const missingColumns = Array.from(colsMap.values());
                missingColumns.sort(a => originalColumns.indexOf(a));
                newColumns = newColumns.concat(missingColumns)
            }
            if (stickyColumns.length) {
                for (const col of stickyColumns) {
                    newColumns.splice(newColumns.indexOf(col), 1)
                }
                newColumns = stickyColumns.concat(newColumns)
            }


            originalColumns = newColumns
        }

        super.setColumns(originalColumns);
        this.setSelectionEnabled(!isMobile);
        this.dataLoader = new DeviceLoader(this.svcApiData);
        this.setDataLoader(this.dataLoader);
    }

    setFilterCriteria(criteria: DeviceSearchCriteria): void {
        this.dataLoader.setFilterCriteria(criteria)
    }

    setTextFilter(text: string): void {
        this.dataLoader.setTextFilter(text);
    }

    handleColumnMoved(previousIndex: number, currentIndex: number): void {
        const col = this.customizableColumns[previousIndex], refCol = this.customizableColumns[currentIndex];
        let after = false;
        if (currentIndex > previousIndex) {
            after = true;
        }
        this.moveColumn(col, refCol, after);
        this.saveUserPrefs()
    }

    saveUserPrefs(): void {
        const values = this.gridColumns.map(value => {
            return {visible: value.visible, key: value.key} as SavedColumnInfo
        });

        this.svcPrefs.setStoredValue(PREFS_KEY_VISIBLE_COLUMNS, values);
    }

    hasEmptySelection(): boolean {
        return !this.hasNonEmptySelection();
    }

    hasNonEmptySelection(): boolean {
        if (!this.selection) {
            return false
        }
        const itemCount = this.getItemCount();
        if (itemCount === 0) {
            return false
        }
        const sel = this.selection.getSelection();
        if (!sel) {
            return false
        }
        if (Array.isArray(sel.just) && sel.just.length > 0) {
            return true
        }
        if (Array.isArray(sel.except) && (sel.except.length < itemCount)) {
            return true
        }
        return false;

    }

    readRecord(data: Device): DeviceEntry {
        return new DeviceEntry().assign(data);
    }

    getData(): DeviceEntry[] {
        return this.localFilteredData || super.getData();
    }

    protected notifyDataChanged(reassign = false): void {
        if (this.localFilter === '') {
            this.localFilteredData = null;
        } else {
            const filterableColumns = this.visibleColumns.filter(value => value.filterable);
            this.localFilteredData = super.getData().filter(value => {
                for (const column of (filterableColumns)) {
                    if (this.getStringValue(value, column).toLocaleLowerCase().indexOf(this.localFilter) >= 0) {
                        return true
                    }
                }
                return false
            });
        }

        if (reassign) {
            reassign = !this.localFilteredData
        }

        super.notifyDataChanged(reassign)
    }


    private getStringValue(entry: DataSourceEntry<any>, column: TableColumnSpec): string {
        if (entry && entry.hasOwnProperty(column.key)) {
            const v = entry[column.key];
            if (v instanceof Date) {
                return (Date.now() - v.getTime()).toString(10);
            }
            if (v.toLocaleString) {
                return v.toLocaleString()
            } else if (v.toString) {
                return v.toString()
            }
        }
        return ''
    }

}
