import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import gql from "graphql-tag";
import {ApiDataService} from "../../../../services/api-data.service";
import {
    BrandSubmissionStatus,
    RetailerPromoPeriodSubmissionSchedule
} from "@looma/shared/models/retailer_promo_period_submission_schedule";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {BaseModel} from "@looma/shared/models/base_model";

@Component({
    selector: 'app-brand-submission-status',
    templateUrl: './brand-submission-status.component.html',
    styleUrls: ['./brand-submission-status.component.scss']
})
export class BrandSubmissionStatusComponent implements OnInit {

    displayedColumns: string[] = ['brand', 'status', 'numSubmissions', 'totalValue', 'menu'];

    dataSource: BrandSubmissionStatusDataSource
    promoProgram: RetailerPromoProgram
    schedulesOpenedForSubmission: RetailerPromoPeriodSubmissionSchedule[]

    items: BrandScheduleSubmissionsEntry[] = []

    constructor(private activatedRoute: ActivatedRoute,
                private svcApi: ApiDataService,) {
    }

    ngOnInit(): void {
        const promoProgramId = this.activatedRoute.snapshot.paramMap.get("promoProgramId") || null
        if (promoProgramId == null) {
            return;
        }

        this.svcApi.rawQuery({
            query: QUERY_BRAND_SUBMISSIONS,
            fetchPolicy: 'no-cache',
            variables: {
                promoProgramId: promoProgramId
            }
        }).subscribe(result => {
            if (result) {
                const data = result.data["brandSubmissionsStatus"]
                const r = new BrandSubmissionStatus()
                r.assign(data)

                r.submissionStatuses.sort((a, b) => {
                    const statusOrder = ['SubmissionsIn', 'PendingSubmissions', 'DeclinedParticipation'];
                    const statusIndexA = statusOrder.indexOf(a.status);
                    const statusIndexB = statusOrder.indexOf(b.status);
                    if (statusIndexA !== statusIndexB) {
                        return statusIndexA - statusIndexB;
                    }
                    return a.brandPartner.name.localeCompare(b.brandPartner.name);
                });

                this.promoProgram = r.promoProgram
                this.schedulesOpenedForSubmission = r.openedSchedules

                this.items = r.submissionStatuses.map(statusEntry => {
                    const submissionsCnt = statusEntry.submissions?.length | 0

                    const totalCampaignCost = statusEntry.submissions?.reduce(
                        (accumulator, submission) =>
                            accumulator + submission.campaignCost, 0
                    );


                    const submissionSchedules = this.schedulesOpenedForSubmission.map(s => {
                        const submissionsForSchedule = statusEntry.submissions?.filter(submission => submission.schedule.id == s.id)
                        const totalCampaignCost = submissionsForSchedule.reduce(
                            (accumulator, submission) =>
                                accumulator + submission.campaignCost, 0
                        );
                        return new SubmissionScheduleInfo(s.id,
                            s.retailerPromoPeriod.name,
                            submissionsForSchedule?.length,
                            totalCampaignCost
                        );
                    })

                    return new BrandScheduleSubmissionsEntry(statusEntry.brandPartner.id,
                        statusEntry.brandPartner.name,
                        statusEntry.status, submissionsCnt,
                        totalCampaignCost, submissionSchedules)
                })

                this.dataSource = new BrandSubmissionStatusDataSource(this.items)
            } else {
                this.dataSource = new BrandSubmissionStatusDataSource([])
            }
        })

    }

    getStatusName(status: string): string {
        switch (status) {
            case 'SubmissionsIn':
                return 'Submissions In';
            case 'PendingSubmissions':
                return 'Pending Submissions';
            case 'DeclinedParticipation':
                return 'Declined Participation';
            default:
                return status;
        }
    }

    convertToCSV(entries: BrandScheduleSubmissionsEntry[]): string {
        const headers = [
            "Brand Name",
            "Status",
            "Campaign Submissions Count",
            "Promo Period Name",
            "Submission Count",
            "Total Submission Value"
        ];
        const csvContent: string[] = [];
        csvContent.push(headers.join(','));

        for (const entry of entries) {
            for (const promoPeriod of entry.promoPeriods) {
                const row = [];
                row.push(entry.brandName);
                row.push(entry.status);
                row.push(entry.campaignSubmissionsCnt.toString());
                row.push(promoPeriod.promoPeriodName);
                row.push(promoPeriod.submissionCount.toString());
                row.push(promoPeriod.totalSubmissionValue.toString());
                csvContent.push(row.join(','));
            }
        }

        return csvContent.join('\n');
    }

    export() {
        const csv = this.convertToCSV(this.items);
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'exported_data.csv';  // Set the file name for the download

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

}

const QUERY_BRAND_SUBMISSIONS = gql`
query brandSubmissionsStatus($promoProgramId: ID!) {
    brandSubmissionsStatus(promoProgramId: $promoProgramId) {
        promoProgram {
          id
          name
        }
        openedSchedules {
            id            
            retailerPromoPeriod {
                id
                name
            }
        }
        submissionStatuses {
          brandPartner {
            id
            name
          }
          status
          submissions {
            submissionType
            id
            brandPartner {
              id
              name
            }
            schedule {
               id
            }
            featuredProducts {
              id
              name
              upc
              thumbUrl
            }
            note
            campaignCost
            segments {
              segmentName
              segmentCost
            }
            airtime {
              segmentName
              segmentCost
            }
          }
          schedule {
            id
            submissions(submissionType: "default") {
              id              
              brandPartner {
                id
                name
              }
            }
          }
        }
    }
}
`

class BrandSubmissionStatusDataSource extends ModelListDataSource<BrandScheduleSubmissionsEntry> {

    private submissionsDataSource = new Map<string, ScheduleDataSource>();

    public static formatNumberUSWithPrefix(value: number): string {
        return "$" + new Intl.NumberFormat('en-US', {style: 'decimal'}).format(value);
    }

    constructor(data: BrandScheduleSubmissionsEntry[]) {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Brand name',
                    valueReader: (item: BrandScheduleSubmissionsEntry) => {
                        return item.brandName
                    }
                }, {
                    key: 'status',
                    label: 'Status',
                    valueReader: (item: BrandScheduleSubmissionsEntry) => {
                        return item.status
                    }
                },
                {
                    key: 'campaign-submissions',
                    label: 'Campaign Submissions',
                    valueReader: (item: BrandScheduleSubmissionsEntry) => {
                        return item.campaignSubmissionsCnt
                    }
                },
                {
                    key: 'campaign-cost-total',
                    label: 'Total Campaign Costs',
                    valueReader: (item: BrandScheduleSubmissionsEntry) => {
                        return BrandSubmissionStatusDataSource.formatNumberUSWithPrefix(item.totalCampaignCost)
                    }
                },
            ]
        });
        this.setLocalData(data)
    }

    getSubmissionsDataSource(data: BrandScheduleSubmissionsEntry) {
        const id = data.getId() + ""
        if (!this.submissionsDataSource.has(id)) {
            this.submissionsDataSource.set(id, new ScheduleDataSource(data));
        }
        return this.submissionsDataSource.get(id);
    }

}

class ScheduleDataSource extends ModelListDataSource<SubmissionScheduleInfo> {
    constructor(brandSubmission: BrandScheduleSubmissionsEntry) {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Name',
                    valueReader: (item: SubmissionScheduleInfo) => {
                        return item.promoPeriodName
                    }
                },
                {
                    key: 'submissions',
                    label: 'Submissions',
                    valueReader: (item: SubmissionScheduleInfo) => {
                        return item.submissionCount
                    }
                },
                {
                    key: 'campaign-cost',
                    label: 'Total Submissions Value',
                    valueReader: (item: SubmissionScheduleInfo) => {
                        return BrandSubmissionStatusDataSource.formatNumberUSWithPrefix(item.totalSubmissionValue)
                    }
                },
            ]
        });

        this.setLocalData(brandSubmission.promoPeriods)
    }

}

export class BrandScheduleSubmissionsEntry extends BaseModel {

    id: string
    brandName: string
    status: string
    campaignSubmissionsCnt: number
    totalCampaignCost: number
    promoPeriods: SubmissionScheduleInfo[]


    constructor(id: string,
                brandName: string,
                status: string,
                campaignSubmissionsCnt: number,
                totalCampaignCost: number,
                promoPeriods: SubmissionScheduleInfo[]) {
        super();
        this.id = id;
        this.brandName = brandName;
        this.status = status;
        this.campaignSubmissionsCnt = campaignSubmissionsCnt;
        this.totalCampaignCost = totalCampaignCost;
        this.promoPeriods = promoPeriods;
    }
}


export class SubmissionScheduleInfo extends BaseModel {
    id: string
    promoPeriodName: string
    submissionCount: number
    totalSubmissionValue: number

    constructor(id: string, promoPeriodName: string, submissionCount: number, totalSubmissionValue: number) {
        super();
        this.id = id;
        this.promoPeriodName = promoPeriodName;
        this.submissionCount = submissionCount;
        this.totalSubmissionValue = totalSubmissionValue;
    }

    getId(): number {
        return Number(this.id);
    }
}
