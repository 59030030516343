import {AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {merge, of, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, flatMap, map, startWith, take, takeUntil} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {FormControl} from '@angular/forms';
import {MatMenuTrigger} from '@angular/material/menu';
import {ApiDataService} from '../../../services/api-data.service';
import {DeviceFieldSearchCondition, DeviceFilterableField} from '@looma/shared/models/device_search';
import {NamedValue} from '@looma/shared/types/named_value';
import {Utils} from '@looma/shared/utils';
import {ConditionInputType, FilterConditionOperator} from '@looma/shared/search';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";

@LifecycleHooks()
@Component({
    selector: 'app-looma-grid-input-filter',
    templateUrl: './looma-grid-input-filter.component.html',
    styleUrls: ['./looma-grid-input-filter.component.scss']
})
export class LoomaGridInputFilterComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('filterInput', {static: true})
    filterInput: ElementRef;

    @ViewChild(MatMenuTrigger, {static: true}) trigger: MatMenuTrigger;

    availableOptions: NamedValue[] = [];

    searchInput: FormControl =  new FormControl('');

    @Input('inEditMode')
    inEditMode = false;

    get criteriaDeviceField(): DeviceFilterableField{
        return this.searchCriteria.deviceField
    }

    get criteriaOperator(): FilterConditionOperator{
        return this.searchCriteria.operator
    }

    get criteriaSelectedValues(): NamedValue[]{
        if(!this.searchCriteria.values){
            this.searchCriteria.values = []
        }
        return this.searchCriteria.values;
    }

    allFields = DeviceFilterableField.ALL.filter(value => !!value.displayName);

    loadingState = LoadingState.Idle;

    constructor(@Inject(DOCUMENT) private doc: any, private svcApi: ApiDataService) { }

    valueChangedSubscription: Subscription;

    @Input('searchCriteria')
    searchCriteria: DeviceFieldSearchCondition = new DeviceFieldSearchCondition();


    @Output('valueChanges')
    valueChanges: Subject<DeviceFieldSearchCondition> = new Subject();

    get hasValue(): boolean{
        if(this.criteriaOperator){
            switch (this.criteriaOperator.inputType) {
                case ConditionInputType.SingleSelection:
                case ConditionInputType.MultiSelection:
                    return this.criteriaSelectedValues.length > 0;
                case ConditionInputType.Text:
                    return this.filterInput.nativeElement.value.trim() !== '';
            }
        }
    }

    get hasInputVisible(): boolean{
        if(!this.inEditMode){
            return false
        }
        if(!this.criteriaOperator){
            return false
        }
        if(this.criteriaDeviceField.allowedValues && this.criteriaDeviceField.allowedValues.length > 0){
            return false
        }
        return true
    }

    ngOnInit(): void {


        const measureText = this.getTextMeasurer();

        this.searchInput.valueChanges.subscribe(value => {
            const el = this.filterInput.nativeElement;
            el.style.width = measureText(value)+'px';
        });

        const options = [];
        this.setItems(options);


        merge(
            this.trigger.menuClosed.pipe(map(value => false)),
            this.trigger.menuOpened.pipe(map(value => true))
        ).pipe(
            debounceTime(100),
            distinctUntilChanged(),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if(!value){
                this.confirmSelection()
            }
        });

    }

    setDeviceField(field: DeviceFilterableField): void{
        if(!this.inEditMode){
            this.setInEditMode(true, true);
            return;
        }
        if(this.criteriaDeviceField !== field){
            this.searchCriteria.deviceField = field;
            if(field){

                this.criteriaSelectedValues.length = 0;
                this.availableOptions.length = 0;
                this.searchCriteria.operator = null;

                if(field.operators.length === 1){
                    this.setOperator(field.operators[0]);
                }
            }

            this.requestFocus()
        }
    }

    ngAfterViewInit(): void {
        if(this.inEditMode){
            this.requestFocus()
        }
    }


    onFocusChanged(event: any): void{
        if(this.inEditMode && this.isFocused()){
            this.trigger.openMenu();
            if(!this.criteriaDeviceField){
                this.trigger.menuClosed.pipe(
                    debounceTime(100),
                    take(1),

                ).subscribe(value => {
                    if(!this.criteriaDeviceField){
                        this.setInEditMode(false, false);
                    }
                })

            }

        }
    }

    isFocused(): boolean{
        return this.doc.activeElement === this.filterInput.nativeElement
    }

    toggleSelection(option: NamedValue, fromUser: boolean): void{
        if(!this.criteriaOperator){
            return
        }
        if(this.criteriaOperator.inputType === ConditionInputType.MultiSelection){
            const idx = this.criteriaSelectedValues.indexOf(option);
            if(idx < 0){
                this.criteriaSelectedValues.push(option);
            }else{
                this.criteriaSelectedValues.splice(idx, 1);
            }
            if(fromUser){
                this.requestFocus()
            }
        }else if(this.criteriaOperator.inputType === ConditionInputType.SingleSelection){
            this.criteriaSelectedValues[0] = option;
            this.setInEditMode(false, false);
        }

    }


    hasSelection(option: NamedValue): boolean{
        return this.criteriaSelectedValues.indexOf(option) >= 0
    }


    setItems(options: NamedValue[]): void{
        options = options.map(opt => {
            const existing = this.criteriaSelectedValues.find(value => value.value === opt.value);
            if(existing){
                return existing;
            }
            return opt;
        });
        this.availableOptions = [].concat(options)
    }

    requestFocus(): void{
        if(!this.inEditMode){
            return;
        }
        setTimeout(() => {
            const el = this.filterInput.nativeElement;
            el.focus();
            el.setSelectionRange(0, el.value.length);
        }, 0)

    }

    private getTextMeasurer(): (text: string) => number{
        const canvas = this.doc.createElement('canvas');
        const styles = window.getComputedStyle(this.filterInput.nativeElement);
        const context = canvas.getContext('2d');
        context.font = styles.getPropertyValue('font-size')+' '+ styles.getPropertyValue('font-family');

        return text => {
            const metrics = context.measureText(text);
            return metrics.width;
        }

    }

    setInEditMode(newInEditMode: boolean, fromUser: boolean): boolean{
        if(this.inEditMode !== newInEditMode){
            this.inEditMode = newInEditMode;
            if(newInEditMode && this.criteriaOperator === FilterConditionOperator.Contains && this.criteriaSelectedValues.length > 0){
                const text = this.criteriaSelectedValues[0].value;
                this.criteriaSelectedValues.length = 0;
                this.searchInput.setValue(text);
                return;
            }
            if(newInEditMode && this.searchCriteria.isEmpty){
                // this.trigger.openMenu();
            }

            if(!newInEditMode){
                this.searchInput.setValue('');

                const criteria = new DeviceFieldSearchCondition();
                criteria.deviceField = this.criteriaDeviceField;
                criteria.operator = this.criteriaOperator;
                criteria.values = this.criteriaSelectedValues;

                this.valueChanges.next(criteria);

            }else{
                this.requestFocus();
                this.listenForInputChanges();
            }
            return true;
        }
        if(this.inEditMode){
            if(!this.isFocused()){
                this.requestFocus()
            }


        }else if(!this.inEditMode){
            this.availableOptions = [].concat(this.criteriaSelectedValues);
            this.searchInput.setValue('')
        }
        return false
    }


    setOperator(operator: FilterConditionOperator): void{
        if(!this.inEditMode){
            this.setInEditMode(true, true);
            return ;
        }

        if(operator === this.criteriaOperator){
            return
        }
        this.criteriaSelectedValues.length = 0;
        this.availableOptions.length = 0;
        this.searchInput.setValue('');

        this.searchCriteria.operator = operator;
        this.requestFocus();

        if(this.criteriaDeviceField.allowedValues){
            this.loadingState = LoadingState.Loaded;
            this.availableOptions = [].concat(this.criteriaDeviceField.allowedValues)
        }else{
            this.listenForInputChanges();

        }
    }

    private listenForInputChanges(): void{
        this.loadingState = LoadingState.Idle;
        if(this.criteriaDeviceField && this.criteriaOperator){
            Utils.unsubscribe(this.valueChangedSubscription);

            this.valueChangedSubscription = this.searchInput.valueChanges.pipe(
                startWith(''),
                debounceTime(500),
                flatMap(value => {
                    if(value === ''){
                        if(this.criteriaSelectedValues.length){
                            return of(this.criteriaSelectedValues)
                        }
                    }
                    this.loadingState = LoadingState.Loading;
                    return this.svcApi.searchDeviceFields(this.criteriaDeviceField, value)
                })
            ).subscribe(value => {
                this.setItems(value);
                if(this.availableOptions.length){
                    this.loadingState = LoadingState.Loaded;
                }else{
                    this.loadingState = LoadingState.Empty
                }

            });
        }
    }

    confirmSelection(): void{
        if(this.criteriaOperator === FilterConditionOperator.Contains){
            const value = this.filterInput.nativeElement.value.trim();
            if(value !== ''){
                this.criteriaSelectedValues[0] = NamedValue.from(value, value);
            }


        }
        this.setInEditMode(false, true)
    }

    clearSelection(): void{
        this.searchCriteria = new DeviceFieldSearchCondition();
        this.valueChanges.next(this.searchCriteria)
    }

    ngOnDestroy(): void {
    }

}


enum LoadingState{
    Idle = 'idle',
    Loading = 'loading',
    Loaded = 'loaded',
    Empty = 'empty',
}
