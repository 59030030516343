import { BaseModel } from "@looma/shared/models/base_model";
import { Utils } from "@looma/shared/utils";

export class RemoteDeviceScreenInfo extends BaseModel{
    width: number
    height: number
    rotation: number
    density: number

}
