<h2 mat-dialog-title>{{this.surveyContent.isNewRecord() ? "Create new " + this.surveyContent.surveyContentType : "Edit content"}}</h2>
<mat-dialog-content [formGroup]="form">
    <div style="text-align: center; width: 100%; padding: 20px">
        <div>
            <img [src]="imageUrl" style="max-width: 300px; height: auto"/>
        </div>
        <div style="padding:10px">
            <button mat-raised-button (click)="uploadImage()" [disabled]="isUploadingImage">Upload image
            </button>
        </div>
    </div>

    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="displayName" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Content Key</mat-label>
        <input matInput formControlName="contentKey" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.isWineTypeSelection()">
        <mat-label>Meal types</mat-label>
        <mat-select formControlName="mealTypes" multiple [compareWith]="itemsEqual">
            <mat-option *ngFor="let mealType of getMealTypes()" [value]="mealType">{{mealType.displayName}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput rows="7" formControlName="description" placeholder="Enter description"></textarea>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleAction('ok')" [disabled]="isBusy">
        Save
    </button>
    <button mat-button (click)="handleAction('cancel')" [disabled]="isBusy">cancel</button>
</mat-dialog-actions>
