import {defer, Observable, OperatorFunction, Subscriber} from 'rxjs';
import {publishLast, refCount, take} from 'rxjs/operators';

export function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) =>  Observable<T> {
    return function inner(source: Observable<T>): Observable<T> {
        return defer(() => {
            onSubscribe();

            return source;
        });
    };
}

export function deferReplayLast<T>( onSubscribe: (subscriber: Subscriber<T>) => void): Observable<T>{
    return new Observable<T>(subscriber => {
        onSubscribe(subscriber)
    }).pipe(
        publishLast(),
        refCount(),
    );

}


export function firstOrDefault<T>(defaultValue: T): OperatorFunction<T, T> {
    return function<T>(source: Observable<T>) {
        return new Observable<T>(subscriber => {
            let valueSent = false;
            source.subscribe(value => {
                valueSent = true;
                subscriber.next(value)
            }, error => {
                valueSent = true;
                subscriber.error(error);
            }, () => {
                if(!valueSent){
                    subscriber.next(defaultValue as any)
                }
            });
            return () => {
               subscriber.unsubscribe()
            }
        }).pipe(
            take(1)
        )
    }
}
