import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {Utils} from '@looma/shared/utils';
import {COLUMN_REGISTRY, TableColumnSpec} from './table-column';
import {TemplateRef, Type} from '@angular/core';
import {delay, distinctUntilChanged, filter, map, skip, switchMap, take, takeUntil} from 'rxjs/operators';
import {LoomaGridComponent} from './looma-grid.component';
import {BaseCursorLoader, CursorDataProvider, ListCursorLoader} from '@looma/shared/cursor_loader';
import {CursorFeed} from '@looma/shared/cursor_feed';
import {BaseModel} from '@looma/shared/models/base_model';
import {SafeHtml, SafeStyle} from '@angular/platform-browser';
import {Identifiable} from '@looma/shared/types/identifiable';
import {ColumnSortInfo, CursorFilter, SortDirection} from '@looma/shared/types/cursor_filter';

export interface IDataSourceEntry<T> extends Identifiable {
    assign(data: any): void;

    getValue(key: string): string | SafeHtml;

    getData(): T;
}


export type ModelValueReaderFunc<T extends BaseModel> = (value: T) => string | SafeStyle;
type ModelValues = { [key: string]: string | SafeHtml };

export abstract class DataSourceEntry<T extends BaseModel> implements IDataSourceEntry<T> {

    static createModelProxyEntry<T extends BaseModel, Q extends T & IDataSourceEntry<T>>(model: T, modelValues: ModelValues): Q {
        // forwarding all properties to the model
        modelValues = modelValues || {};

        const proxy = new Proxy(model as object, {
            get: function(target: object, property: string | number | symbol, receiver: any): any {
                if (property == 'model') {
                    return model;
                }
                if (modelValues.hasOwnProperty(property)) {
                    return modelValues[property.toString()]
                }
                return Reflect.get(target, property, receiver);
            }
        });

        proxy['unwrap'] = () => {
            return model
        };

        // IDataSourceEntry impl
        proxy['assign'] = () => {
        };

        proxy['getValue'] = (key: string) => {
            return proxy[key];
        };

        proxy['getData'] = (key: string) => {
            return model;
        };

        return proxy as Q;
    }

    abstract assign(data: any): void;

    getValue(key: string): string | SafeHtml {
        if (this.hasOwnProperty(key)) {
            return String(this[key]);
        }
        return null;
    }


    abstract getId(): any

    getData(): T {
        return this['model']
    }
}

class ArrayLoader<T extends BaseModel> extends BaseCursorLoader<T> {
    private sourceObs: Observable<CursorFeed<T>>;

    constructor(private source: () => Observable<T[]> | T[]) {
        super()
    }

    next(startFrom: string): Observable<CursorFeed<T>> {
        if (!this.sourceObs) {
            let s = this.source();
            if (Array.isArray(s)) {
                s = of(s)
            }
            this.sourceObs = s.pipe(
                map((value: T[]) => {
                    const feed = new CursorFeed();
                    feed.data = value;
                    return feed;
                })
            ) as Observable<CursorFeed<T>>;
        }
        return this.sourceObs;
    }

}


export abstract class GridDataSource<Q extends BaseModel, T extends IDataSourceEntry<Q>> {

    protected constructor(columnType: Type<T> | TableColumnSpec[]) {
        if (typeof columnType === 'function') {
            this.registeredColumns = COLUMN_REGISTRY.getColumns(columnType);
        } else if (Array.isArray(columnType)) {
            this.registeredColumns = columnType;
        } else {
            this.registeredColumns = [];
        }

        this.attachedComponentSubject.pipe(
            skip(1)
        ).subscribe(view => {

            const loader = this.getLoader();
            if (loader) {
                loader.setActive(!!view);
            }

            if (!view) {
                return
            }
            const triggered = this.dataProvider.next();
            this.dataProvider.onDataAvailable().pipe(
                takeUntil(this.attachedComponentSubject.pipe( // stop whenever this datasource is attached to another view or detached from any view
                    skip(1)
                ))
            ).subscribe(value => {

                const newRecords = value.data.map(value1 => this.readRecord(value1));
                if (value.replace) {
                    this.loadedData = newRecords
                } else {
                    this.loadedData = this.loadedData.concat(newRecords)
                }
                this.notifyDataChanged(false);

            });
            view.appendStyles();
        })

    }

    get gridColumns(): TableColumnSpec[] {
        return this.registeredColumns;
    }

    get visibleColumns(): TableColumnSpec[] {
        if (!this._visibleColumns) {
            this._visibleColumns = this.gridColumns.filter(value => value.visible !== false);
        }
        return this._visibleColumns;
    }

    get customizableColumns(): TableColumnSpec[] {
        this._customizableColumns = this._customizableColumns || [].concat(this.registeredColumns.filter(value => value.customizable));
        return this._customizableColumns;
    }

    get data(): T[] {
        return this.getData();
    }

    get attachedComponent(): LoomaGridComponent<Q> {
        return this.attachedComponentSubject.value;
    }

    get hasExpandedItems(): boolean {
        if (!this.expandedRows) {
            return false
        }
        return this.expandedRows.size > 0;
    }

    private static instanceIdCounter = 0;

    protected loadedData: T[] = [];
    protected registeredColumns: TableColumnSpec[];
    protected dataSubject: BehaviorSubject<T[]> = new BehaviorSubject(null);
    protected itemReplacedSubject: BehaviorSubject<{ oldItem: Q, newItem: Q }> = new BehaviorSubject(null);
    private cellTemplates: Map<TableColumnSpec, TemplateRef<any>> = new Map();
    public rowExpansionTemplate: TemplateRef<any>;
    private _visibleColumns: TableColumnSpec[];
    private _customizableColumns: TableColumnSpec[];
    protected dataProvider = new CursorDataProvider<Q>();

    private expandedRows: Set<string>;
    private attachedComponentSubject = new BehaviorSubject<LoomaGridComponent<any>>(null);

    selection: ListSelection;

    instanceId = GridDataSource.nextInstanceId();

    private static nextInstanceId(): string {
        return (this.instanceIdCounter++) + ''
    }


    setSort(column: TableColumnSpec, dir: SortDirection) {
    }

    onData(): Observable<T[]> {
        return this.dataSubject.pipe(
            filter(value => Array.isArray(value))
        )
    }

    onItemReplaced(): Observable<{ oldItem: Q, newItem: Q }> {
        return this.itemReplacedSubject
    }

    onEmptyData(): Observable<boolean> {
        return this.onData().pipe(
            map(value => !value.length),
            distinctUntilChanged()
        );
    }

    getData(): T[] {
        return this.loadedData;
    }

    setLoaderFactory(fn: () => Q[] | Observable<Q[]>): void {
        this.dataProvider.setLoader(new ArrayLoader(fn))
    }

    setDataLoader(loader: BaseCursorLoader<Q>): void {
        this.dataProvider.setLoader(loader)
    }

    getLoader(): BaseCursorLoader<Q> {
        return this.dataProvider.getLoader();
    }

    onDataAvailable(): Observable<boolean> {
        return this.dataProvider.onDataAvailable().pipe(
            map(value => true)
        )
    }

    getColumn(key: string): TableColumnSpec {
        return this.gridColumns.find(value => value.key === key)
    }

    onViewInit(view: LoomaGridComponent<any>): void {
        this.attachedComponentSubject.next(view);
    }

    onViewDetached(view: LoomaGridComponent<any>): void {
        if (this.attachedComponent == view) {
            this.attachedComponentSubject.next(null);
        }
    }

    moveColumn(col: TableColumnSpec, refCol: TableColumnSpec, after: boolean): void {
        const newColumns = [].concat(this.gridColumns);
        newColumns.splice(newColumns.indexOf(col), 1);
        const refColIndex = newColumns.indexOf(refCol);

        if (after) {
            newColumns.splice(refColIndex + 1, 0, col)
        } else {
            newColumns.splice(refColIndex, 0, col)
        }

        this.registeredColumns = newColumns;
        this._visibleColumns = null;
        this._customizableColumns = null;
    }

    public setColumns(cols: TableColumnSpec[]): void {
        this.registeredColumns = [].concat(cols);
        this._visibleColumns = null;
        this._customizableColumns = null;
    }

    public setSelectionEnabled(newEnabled: boolean): boolean {
        const oldEnabled = this.selection != null;
        if (oldEnabled !== newEnabled) {
            if (newEnabled) {
                this.selection = new ListSelection();
            } else {
                this.selection = null;
            }
            this.setColumns(this.registeredColumns);
            return true
        }
        return false
    }

    public clearSelection(): void {
        if (this.selection != null) {
            this.selection.clear()
        }
    }

    public isSelectionEnabled(): boolean {
        return !!this.selection;
    }

    public resetVisibleColumns(): void {
        this._visibleColumns = null;
    }

    public refresh(): void {
        this.getLoader().invalidate();
        this.triggerLoad()
    }

    abstract readRecord(data: Q): T;

    getColumnValue(column: TableColumnSpec, data: T): any {
        return data.getValue(column.key) || data[column.key];
    }

    triggerLoad(): boolean {
        return this.dataProvider.next();
    }

    public setCellTemplate(column: TableColumnSpec | string, tpl: TemplateRef<any>): void {
        const columnKey = ((column as TableColumnSpec)?.key || column) as string

        if (Utils.isString(columnKey)) {
            const col = this.gridColumns.find(value => value.key == columnKey);
            if (!col) {
                return
            }
            this.cellTemplates.set(col as TableColumnSpec, tpl)
        }

    }

    public setRowExpansionTemplate(tpl: TemplateRef<any>): void {
        this.rowExpansionTemplate = tpl;
        this.setItemsExpandable(!!tpl);
    }

    public hasCellTemplate(column: TableColumnSpec): boolean {
        return this.cellTemplates.has(column)
    }

    public getCellTemplate(column: TableColumnSpec): TemplateRef<any> {
        return this.cellTemplates.get(column)
    }

    addItem(item: Q, prepend = true): void {
        if (!item) {
            return
        }
        const data = this.readRecord(item);
        if (prepend) {
            this.loadedData.unshift(data);
        } else {
            this.loadedData.push(data)
        }
        this.notifyDataChanged(true);
    }

    replaceItem(newItem: Q): boolean {
        const pos = this.getItemPosition(newItem);
        if (pos >= 0) {
            const oldItem = this.loadedData[pos]
            this.loadedData[pos] = this.readRecord(newItem);
            this.notifyDataChanged(true);
            this.itemReplacedSubject.next({
                oldItem: oldItem.getData(),
                newItem: newItem,
            })
            return true;
        }
        return false
    }

    removeItem(item: Q): boolean {
        const pos = this.getItemPosition(item);
        if (pos >= 0) {
            this.setItemExpanded(item.getId(), false);
            this.loadedData.splice(pos, 1);
            this.notifyDataChanged(true);
            return true
        }
        return false;
    }

    protected notifyDataChanged(reassign = false): void {
        if (reassign) {
            this.loadedData = [].concat(this.loadedData)
        }
        this.dataSubject.next(this.data)
    }

    getItem(item: Q): T {
        const pos = this.getItemPosition(item);
        if (pos >= 0) {
            return this.loadedData[pos];
        }
        return null;
    }

    getItemPosition(item: Q): number {
        const instanceIndex = this.loadedData.indexOf(item as any as T);
        if (instanceIndex >= 0) {
            return instanceIndex;
        }
        const lookupId = this.readRecord(item).getId();
        return this.loadedData.findIndex(value => value.getId() == lookupId)
    }

    public getItemCount(): number {
        const d = this.data;
        return d && d.length || 0;
    }

    public hasItemsSelected(): boolean {
        if (!this.selection) {
            return false;
        }
        if (this.getData().length == 0) {
            return false;
        }
        return this.selection.allSelected || this.selection.someSelected;
    }


    getSelectedData(): T[] {
        return this.selection.applySelectionToData(this.getData())
    }

    public setItemsExpandable(newEnabled: boolean): void {
        const wasExpandable = !!this.expandedRows;
        if (newEnabled != wasExpandable) {
            if (newEnabled) {
                this.expandedRows = new Set<string>();
            } else {
                this.expandedRows = null;
            }
        }
    }

    isItemExpanded(entry: T | Q | string | number): boolean {
        if (!this.expandedRows) {
            return false;
        }
        return this.expandedRows.has(this.getItemId(entry))
    }

    isItemExpandable(entry: T | Q): boolean {
        return true
    }

    setItemExpanded(entry: T | Q | string | number, expanded: boolean): void {
        if (!this.expandedRows) {
            return;
        }
        const id = this.getItemId(entry);
        const wasExpanded = this.isItemExpanded(id);
        if (wasExpanded != expanded) {
            if (expanded) {
                this.expandedRows.add(id)
            } else {
                this.expandedRows.delete(id)
            }
        }
    }

    toggleItemExpanded(item: T | Q | string | number): void {
        this.setItemExpanded(item, !this.isItemExpanded(item));
    }

    private getItemId(item: T | Q | string | number): string {
        return Utils.getId(item);
    }

    loadAllRecords(): Observable<T[]> {
        this.dataProvider.cancelLoad();
        if (this.dataProvider.hasNext) {
            this.dataProvider.next(-1);
            return this.dataProvider.onDataAvailable().pipe(
                skip(1),
                take(1),
                map(_ => this.getData())
            )
        }
        return of(this.getData())
    }

}


export enum ListSelectionMode {
    All,
    None
}

export interface UserSelection {
    except?: any[]
    just?: any[]
}

export class ListSelection {
    private selectionMode = ListSelectionMode.None;

    private selectedItems: Set<object> = new Set();
    private selectionChanged: Subject<ListSelection> = new Subject();

    public isItemSelected<T extends Identifiable>(item: T): boolean {
        const contains = this.selectedItems.has(item.getId());
        switch (this.selectionMode) {
            case ListSelectionMode.All:
                return !contains;
            case ListSelectionMode.None:
                return contains;
        }
        return false;
    }

    public applySelectionToData<T extends Identifiable>(data: T[]): T[] {
        return data.filter(value => this.isItemSelected(value))
    }

    public toggleSelection<T extends Identifiable>(item: T): boolean {
        const id = item.getId();
        if (this.selectedItems.has(id)) {
            this.selectedItems.delete(id);
        } else {
            this.selectedItems.add(id);
        }
        this.notifyChanged();
        return true;
    }

    public toggleAllSelected(): void {
        switch (this.selectionMode) {
            case ListSelectionMode.None:
                if (this.selectedItems.size === 0) {
                    this.selectionMode = ListSelectionMode.All;
                }
                break;
            case ListSelectionMode.All:
                this.selectionMode = ListSelectionMode.None;
                break;
        }
        this.selectedItems.clear();
        this.notifyChanged();
    }

    public selectAll(): void {
        this.selectionMode = ListSelectionMode.All;
        this.notifyChanged()
    }

    public get allSelected(): boolean {
        return (this.selectionMode === ListSelectionMode.All) && (this.selectedItems.size === 0);
    }

    public get someSelected(): boolean {
        return this.selectedItems.size !== 0;
    }

    public get noneSelected(): boolean {
        return (this.selectionMode === ListSelectionMode.None) && (this.selectedItems.size === 0);
    }

    protected notifyChanged(): void {
        this.selectionChanged.next(this)
    }

    public onSelectionChanged(): Observable<ListSelection> {
        return this.selectionChanged;
    }

    public getSelection(): UserSelection {
        switch (this.selectionMode) {
            case ListSelectionMode.None:
                return {just: Array.from(this.selectedItems)};
            case ListSelectionMode.All:
                return {except: Array.from(this.selectedItems)};
        }
    }

    public clear(): void {
        if (!this.noneSelected) {
            this.selectedItems.clear();
            this.selectionMode = ListSelectionMode.None;
            this.notifyChanged();
        }
    }

}

class FilterLoader<Q extends BaseModel, R extends CursorFilter> extends BaseCursorLoader<Q> {
    private filterData: R = {} as R;
    private pendingFilter: R = {} as R;
    private filterSubject = new Subject<{ data: R, delay: number }>()

    constructor(private obsFactory: (filter: R) => Observable<CursorFeed<Q>>) {
        super()

        this.filterSubject.pipe(
            switchMap(value => {
                return of(value.data).pipe(
                    delay(value.delay)
                )
            })
        ).subscribe(value => {
            if (this.isActive) {
                this.filterData = JSON.parse(JSON.stringify(value));
                this.invalidate()
            }
        })
    }

    // applies the values of newFilter to the existing filter
    // deletes all properties from the filter which are null or undefined

    // if the filter value comes from a text input, then debounceTime should be > 0
    applyFilter(newFilter: Partial<R>, debounceTime: number = 0): boolean {
        const filterData = this.mergePendingFilter(newFilter);
        if (filterData) {
            if (debounceTime < 0) {
                debounceTime = 0
            }
            this.filterSubject.next({data: filterData, delay: debounceTime})
            return true
        }
        return false
    }

    setFilter(dataFilter: Partial<R>): boolean {
        return this.applyFilter(dataFilter, 0)
    }

    private mergePendingFilter(newFilter: Partial<R>): R {
        if (!newFilter) {
            return null
        }
        let newFilterData = Object.assign({}, this.pendingFilter || {cursor: ''}) as R;
        newFilterData = Object.assign(newFilterData, newFilter);

        for (const key of Object.keys(newFilterData)) {
            const value = newFilterData[key];
            if (Utils.isNullOrUndefined(value)) {
                delete newFilterData[key]
            }
        }
        if (Utils.deepEqual(newFilterData, this.pendingFilter)) {
            return null
        }
        this.pendingFilter = newFilterData
        return newFilterData
    }

    next(startFrom: string): Observable<CursorFeed<Q>> {
        const filterData = JSON.parse(JSON.stringify((this.filterData || {}))) as R;
        if (startFrom) {
            filterData.cursor = startFrom;
        }
        return this.obsFactory(filterData)
    }


}

export abstract class CursorDataSource<Q extends BaseModel, T extends IDataSourceEntry<Q>, R extends CursorFilter> extends GridDataSource<Q, T> {
    protected constructor(columnType: Type<T> | TableColumnSpec[]) {
        super(columnType);
        this.dataProvider.setLoader(new FilterLoader(this.loadData.bind(this)))
    }

    abstract loadData(dataFilter: R): Observable<CursorFeed<Q>>

    setFilter(dataFilter: Partial<R>): boolean {
        return this.getLoader().setFilter(dataFilter);
    }

    applyFilter(partialFilter: Partial<R>, debounceTime: number = 1): boolean {
        return this.getLoader().applyFilter(partialFilter, debounceTime);
    }

    getLoader(): FilterLoader<Q, R> {
        return super.getLoader() as FilterLoader<Q, R>;
    }

    setSort(column: TableColumnSpec, dir: SortDirection) {
        const sortInfo: ColumnSortInfo[] = [];
        if (column && dir && (dir != SortDirection.None)) {
            sortInfo.push({
                column: column.key,
                direction: dir
            })
        }

        const filter = {
            sort: sortInfo,
        }

        this.getLoader().applyFilter(filter as any as Partial<R>)
    }
}


export interface ModelColumnSpec<T extends BaseModel> extends TableColumnSpec {
    valueReader?: ModelValueReaderFunc<T>;
}

export abstract class ModelDataSource<T extends BaseModel, Q extends CursorFilter> extends CursorDataSource<T, IDataSourceEntry<T>, Q> {

    private valueReaders: { [key: string]: ModelValueReaderFunc<T> } = {};

    constructor(config: {
        columns: ModelColumnSpec<T>[],
        loader?: BaseCursorLoader<T>
    }) {
        super(config.columns);

        for (const col of config.columns) {
            if (col.valueReader) {
                this.valueReaders[col.key] = col.valueReader;
            }
        }

        if (config.loader) {
            this.dataProvider.setLoader(config.loader)
        }
    }

    readRecord(model: T): IDataSourceEntry<T> {
        const modelValues: ModelValues = {};
        for (const key in this.valueReaders) {
            modelValues[key] = this.valueReaders[key](model);
        }

        return DataSourceEntry.createModelProxyEntry(model, modelValues);
    }
}

// item is removed from the results if result is false
export type ModelFilterFunc<T> = (data: T) => boolean;

export class ModelListDataSource<T extends BaseModel> extends ModelDataSource<T, CursorFilter> {

    constructor(config: { columns: ModelColumnSpec<T>[] }) {
        super({
            columns: config.columns,
            loader: new ListCursorLoader<T>([])
        });

    }

    private localFilters = new Map<string, ModelFilterFunc<T>>();
    private unfilteredData: T[] = [];

    static create<T extends BaseModel>(columns: ModelColumnSpec<T>[], data: T[]): ModelListDataSource<T> {
        const instance = new ModelListDataSource<T>({columns: columns});
        instance.setLocalData(data);
        return instance
    }

    setLocalData(items: T[]): void {
        this.unfilteredData = [].concat(items);
        this.runLocalFilters();
    }

    getLocalData(): T[] {
        return this.unfilteredData
    }

    getLocalLoader(): ListCursorLoader<T> {
        return super.getLoader() as any as ListCursorLoader<T>;
    }

    setLocalFilter(name: string, fn: ModelFilterFunc<T>): void {
        if (!fn) {
            this.removeLocalFilter(name);
            return;
        }
        this.localFilters.set(name, fn);
        this.runLocalFilters()
    }

    removeLocalFilter(...names: string[]): void {
        let changed = false;
        for (const n of names) {
            if (this.localFilters.has(n)) {
                this.localFilters.delete(n);
                changed = true;
            }
        }
        if (changed) {
            this.runLocalFilters();
        }
    }

    clearLocalFilters(): boolean {
        if (this.hasLocalFilters()) {
            this.localFilters.clear();
            this.runLocalFilters();
            return true;
        }
        return false;
    }

    hasLocalFilters(): boolean {
        return this.localFilters.size > 0;
    }

    private runLocalFilters(): void {
        this.getLocalLoader().setItems(this.getLocalFilteredData())
    }

    private getLocalFilteredData(): T[] {
        if (!this.unfilteredData.length) {
            return []
        } else if (!this.hasLocalFilters()) {
            return [].concat(this.unfilteredData)
        }

        return this.unfilteredData.filter(value => {
            for (const filterFn of this.localFilters.values()) {
                if (!filterFn(value)) {
                    return false
                }
            }
            return true
        })
    }

    loadData(dataFilter: CursorFilter): Observable<CursorFeed<T>> {
        return undefined;
    }

    removeItem(item: T): boolean {
        if (super.removeItem(item)) {
            const idx = this.unfilteredData.findIndex(value => value.getId() == item.getId())
            if (idx >= 0) {
                this.unfilteredData.splice(idx, 1)
            }
            return true
        }
        return false
    }

    notifyUpdated() {
        this.notifyDataChanged()
    }

}
