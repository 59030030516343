import {BaseModel} from '@looma/shared/models/base_model';

export class RemoteRealtimeStats extends BaseModel {
    beginning_of_day: Date;
    last_rebooted_at: Date;
    timezone_id: string;
    up_time: number;
    down_time: number;
    playback_time: number;
    playback_down_time: number;
    buffering_time: number;
    playback_sessions_count: number;
    last_session_started_at: Date;
    reboot_since_time: number;


    assign(obj: any): this {
        super.assign(obj);
        this.assignDate(obj, 'beginning_of_day');
        this.assignDate(obj, 'last_rebooted_at');
        this.assignDate(obj, 'last_session_started_at');

        if (this.reboot_since_time > 0) {
            this.down_time = this.reboot_since_time - this.up_time;
            this.playback_down_time = this.reboot_since_time - this.playback_time;
        }

        return this;
    }
}
