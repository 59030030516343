<ng-template let-dataSource="dataSource" #tplStoreList>
    <looma-grid *ngIf="dataSource" [dataSource]="dataSource" fxFill class="device_slot_list"
                [title]="dataSource.dataSourceLabel"
                [class.slot_list]="dataSource.hasUnassignableItems" [dragDropController]="dataSource.ddController">
        <ng-template dragPreview let-dataSource="dataSource" let-selectedItems="selectedItems">
            <div>
                <div *ngFor="let store of selectedItems" class="dragged_item">
                    <div>{{store.getDisplayName()}}</div>
                </div>
            </div>
        </ng-template>

        <ng-template gridHeading>
            <div *ngIf="!isDefaultDataSource(dataSource)">
                <button color="warn" mat-icon-button (click)="deleteDataSource(dataSource)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

        </ng-template>


    </looma-grid>
</ng-template>


<div app-looma-page pageTitle="Store Groups" [pageSubtitle]="retailer?.retailer_name" pageIcon="shopping_cart">
    <ng-template pageHeaderNav>
        <div style="display: flex;gap: 16px">
            <button style="color: white" mat-button (click)="svcStoreGroups.setActiveViewType('map')">Map View</button>
            <button mat-raised-button (click)="addNewStoreGroup()">New Group</button>
            <button mat-raised-button color="warn" (click)="saveChanges()" [disabled]="isBusy">Save</button>
        </div>
    </ng-template>

    <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
        <div style="flex: 1; display: flex; position: relative;">
            <div
                style="display: flex; overflow-y: hidden; position: absolute; top:0; left:0; right:10px; bottom:0; padding:8px 0 ; gap:10px;">
                <div class="mat-elevation-z3" *ngFor="let ds of controller.itemDataSources"
                     style="height:100%; min-width: 300px;flex:1">
                    <ng-container [ngTemplateOutlet]="tplStoreList"
                                  [ngTemplateOutletContext]="{dataSource:ds}"></ng-container>
                </div>
            </div>

        </div>
        <div style="z-index: 1; min-width: 500px !important; max-width: 25%; " class="mat-elevation-z3">
            <ng-container [ngTemplateOutlet]="tplStoreList"
                          [ngTemplateOutletContext]="{dataSource:controller.defaultItemDataSource}"></ng-container>
        </div>

    </div>
</div>




