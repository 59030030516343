<div class="item" (click)="setInEditMode(true, true)" [class.editable]="inEditMode" [class.text-editable]="hasInputVisible">


    <span  class="field-name chip mat-elevation-z1"  (click)="setDeviceField(null)">{{criteriaDeviceField?.displayName || 'Select Field'}}</span>
    <span class="selected-operator chip  mat-elevation-z1" *ngIf="criteriaOperator" (click)="setOperator(null)"> {{criteriaOperator.name}}</span>
    <span #menuTrigger  [matMenuTriggerFor]="suggestionMenu" style="position:absolute; bottom:0;left:0;"></span>
    <div class="selected_options_list">
        <span *ngFor="let option of criteriaSelectedValues; let first=first; let last = last">
            <span class="selected-option  " [class.editable]="inEditMode" [class.last]="last" >{{option.name}}</span>
            <span *ngIf="!last" class="option-delimiter"></span>
        </span>
    </div>


    <input [attr.placeholder]="criteriaOperator?.inputType === 'text' ? 'Enter text' : 'Search'" [attr.disabled]="inEditMode ? null : ''" [formControl]="searchInput" type="text" #filterInput matInput (blur)="onFocusChanged($event)" (focus)="onFocusChanged($event)" style="width:1px">

    <!--<mat-icon *ngIf="inEditMode && hasValue && (criteriaOperator?.inputType === 'multi_selection' || criteriaOperator?.inputType === 'text')" style="color:green" (click)="$event.stopPropagation();confirmSelection()">check_circle</mat-icon>-->

    <mat-menu #suggestionMenu="matMenu" >
        <!--<div >
            <div class="p-8" *ngFor="let option of filter.fetchOptions() | async">
                <mat-checkbox [checked]="hasSelection(option)" (change)="toggleSelection(option, true)" (click)="$event.stopPropagation()">
                    {{option.displayName}}
                </mat-checkbox>
            </div>

        </div>-->

        <div *ngIf="!criteriaDeviceField">
            <div class="p-8" *ngFor="let field of allFields" (click)="setDeviceField(field)">{{field.displayName}}</div>
        </div>

        <div *ngIf="criteriaDeviceField && criteriaOperator?.inputType !== 'text'">

            <div *ngIf="!criteriaOperator">
                <div class="p-8" *ngFor="let operator of criteriaDeviceField.operators" (click)="setOperator(operator)">{{operator.name}}</div>
            </div>

            <div *ngIf="criteriaOperator">
                <div *ngIf="loadingState == 'idle'" class="p-8">Enter search term</div>
                <div *ngIf="loadingState == 'empty'" class="p-8">No Results Found</div>

                <div *ngIf="loadingState == 'loading'" style="height:40px; display:flex; align-items: center">
                    <mat-progress-spinner mode="indeterminate" [diameter]="24" style="margin: 0 auto" ></mat-progress-spinner>
                </div>

                <div *ngIf="loadingState == 'loaded'">
                    <div *ngIf="criteriaOperator?.inputType === 'multi_selection'">
                        <div class="p-8" *ngFor="let option of availableOptions">
                            <mat-checkbox *ngIf="criteriaOperator?.inputType === 'multi_selection'" [checked]="hasSelection(option)" (change)="toggleSelection(option, true)" (click)="$event.stopPropagation()">
                                {{option.name}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="criteriaOperator?.inputType === 'single_selection'">
                        <div  class="p-8" *ngFor="let option of availableOptions" (click)="toggleSelection(option, true)">
                            {{option.name}}
                        </div>
                    </div>


                </div>

            </div>

        </div>

    </mat-menu>

    <span  class="small_button remove_filter_button mat-elevation-z1 ml-8" (click)="clearSelection()"><mat-icon>clear</mat-icon></span>

</div>