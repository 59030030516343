<div app-looma-page [pageTitle]="pageTitle">

    <div style="display: flex; flex-direction: row; height: 100%; ">
        <div style="flex: 1; display: flex; position: relative;">
            <div
                style="display: flex; overflow-y: hidden; position: absolute; top:0; left:0; right:10px; bottom:0; padding:8px 0 ; gap:10px;">
                <div class="mat-elevation-z3 slot_list" *ngFor="let ds of controller.itemDataSources" fxFlex
                     style="height:100%;">
                    <app-device-slot-assignment-list [dataSource]="ds" [retailerPromoPrograms]="availablePrograms">
                        <ng-template slot-assignment-list-footer>
                            <div class="device_logs">
                                <looma-grid [rowActions]="deviceSlotSegmentAssignmentLogActions"
                                            [dataSource]="getAssignmentLogsDataSource(ds)">
                                    <ng-template emptyDataContent>
                                        <div style="text-align: center; padding:16px">No logs</div>
                                    </ng-template>

                                    <ng-template gridColumnHeader="date">
                                        <span style="font-size: 1em; font-weight: 500">Assignment logs</span>
                                    </ng-template>

                                </looma-grid>
                            </div>
                        </ng-template>
                    </app-device-slot-assignment-list>
                </div>
            </div>

        </div>
        <div style="z-index: 1; min-width: 500px !important; max-width: 25%; " class="mat-elevation-z3">
            <app-device-slot-assignment-list
                [retailerPromoPrograms]="availablePrograms"
                [dataSource]="controller.defaultItemDataSource">
            </app-device-slot-assignment-list>
        </div>

    </div>
    f
    <ng-template pageHeaderNav>
        <button mat-raised-button (click)="saveAssignment()" [disabled]="isBusy || (dirtyAssignments.size == 0)"
                class="mr-16">Save
        </button>
        <button mat-raised-button (click)="downloadAllLogs()">Download logs</button>
    </ng-template>
</div>
