import {Component, Inject, OnInit} from '@angular/core';
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LayoutService} from "../../../../services/layout.service";
import {Observable} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ApiDataService} from "../../../../services/api-data.service";
import {DeviceSlotType, DeviceSlotTypeMutationInput} from "@looma/shared/models/device_slot_type";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-program-device-slot-type-dialog',
    templateUrl: './program-device-slot-type-dialog.component.html',
    styleUrls: ['./program-device-slot-type-dialog.component.scss']
})
export class ProgramDeviceSlotTypeDialogComponent extends ModelEditDialog<DeviceSlotType> implements OnInit {

    constructor(public dialogRef: MatDialogRef<ProgramDeviceSlotTypeDialogComponent>,
                public svcLayout: LayoutService,
                private svcApi: ApiDataService,
                @Inject(MAT_DIALOG_DATA) private dialogData: ProgramDeviceSlotTypeDialogData) {
        super();

        this.deviceSlotType = dialogData.deviceSlotType;
    }

    deviceSlotType: DeviceSlotType;
    title = '';

    static open(svcLayout: LayoutService, data: ProgramDeviceSlotTypeDialogData): Observable<DeviceSlotType> {
        return svcLayout.openDialogForResult(ProgramDeviceSlotTypeDialogComponent, {
            data,
        })
    }


    ngOnInit() {
        this.title = this.deviceSlotType.isNewRecord() ? 'New Device Slot Type' : `Edit ${this.deviceSlotType.name}`;
    }

    onSave(): Observable<MutationResponse<DeviceSlotType>> {

        const data: DeviceSlotTypeMutationInput = {
            id: "",
        };

        let op = MutationOperation.Create;

        if (!this.deviceSlotType.isNewRecord()) {
            data.id = this.deviceSlotType.id;
            op = MutationOperation.Update;
        }

        data.defaultBrandSlotsCount = this.deviceSlotType.defaultBrandSlotsCount;
        data.name = this.deviceSlotType.name.trim();
        data.retailerPromoProgramId = this.dialogData.promoProgram.id;


        return this.svcApi.mutateDeviceSlotType(op, data, this.dialogData.deviceSlotTypeGqlFields)

    }

    canSave(): boolean {
        return (this.deviceSlotType.name != '') && (this.deviceSlotType.defaultBrandSlotsCount > 0);
    }


}


export interface ProgramDeviceSlotTypeDialogData {
    deviceSlotType: DeviceSlotType
    promoProgram: RetailerPromoProgram
    deviceSlotTypeGqlFields: string
}
