import {NgModule} from '@angular/core';
import {DeviceSlotImagesListComponent} from './components/device-slot-images-list/device-slot-images-list.component';
import {AppCommonModule} from "../../shared/common.module";
import {RouterModule} from "@angular/router";
import {LayoutModule} from "../../layout/layout.module";
import {EditDeviceSlotImageDialogComponent} from './components/edit-device-slot-image-dialog/edit-device-slot-image-dialog.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {EditDeviceSlotImagePhotoDialogComponent} from "./components/edit-device-slot-image-photo-dialog/edit-device-slot-image-photo-dialog.component";
import {DeviceSlotImagePhotosListComponent} from './components/device-slot-image-photos-list/device-slot-image-photos-list.component';
import {LoomaComponentsModule} from "@looma/shared/components/looma-components.module";
import {AddImageDialogComponent} from './components/add-image-dialog/add-image-dialog.component';

@NgModule({
  declarations: [DeviceSlotImagesListComponent, EditDeviceSlotImageDialogComponent, EditDeviceSlotImagePhotoDialogComponent, DeviceSlotImagePhotosListComponent, AddImageDialogComponent],
  imports: [
      AppCommonModule,
      RouterModule,
      LayoutModule,
      FlexLayoutModule,
      LoomaComponentsModule
  ],
    entryComponents:[
        
    ]
})
export class DeviceSlotImagesModule { }
