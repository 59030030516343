import {BaseModel} from '@looma/shared/models/base_model';
import {Utils} from '@looma/shared/utils';

export class RemoteBatteryInfo extends BaseModel {
    fill: number;
    health: string;
    temperature: number;
    voltage: number;
    is_charging: boolean;
    is_plugged: boolean;


    addProperties(values: Map<string, any>): void {
        values.set('Fill', this.fill + '%');
        values.set('Health', this.health);
        values.set('Temperature', Utils.round(this.temperature, 2) + ' ℃');
        values.set('Voltage', Utils.round(this.voltage, 2) + ' V');
        values.set('Is Charging', this.is_charging ? 'Yes' : 'No');
        values.set('Is Plugged', this.is_plugged ? 'Yes' : 'No');

    }
}
