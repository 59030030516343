import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Utils} from "@looma/shared/utils";
import {combineLatest, from, Observable, of, zip} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {map, mergeMap, takeUntil, toArray} from "rxjs/operators";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {LoomaLayoutService} from "@looma/shared/services/looma-layout.service";
import firebase from "firebase/compat/app";


@LifecycleHooks()
@Component({
    selector: 'lib-gcp-file-picker',
    templateUrl: './gcp-file-picker.component.html',
    styleUrls: ['./gcp-file-picker.component.css']
})
export class GcpFilePickerComponent<T extends GCPStorageItem> implements OnInit {

    static BACK = "..."

    displayedColumns = ['icon', 'name', 'size', 'type', 'date']
    dataSource: GCPStorageItem[]
    bucketName: string
    root: string
    afStorage: firebase.storage.Storage
    isLoading = false

    public static open(svcLayout: LoomaLayoutService, afStorage: AngularFireStorage, config: GcpFilePickerConfig): Observable<GCPStorageItem> {
        return svcLayout.openDialogForResult(GcpFilePickerComponent, {
            minWidth: "800px",
            data: {
                folder: config.folder,
                bucket: config.bucket,
                afStorage: afStorage
            }
        })
    }

    constructor(
        public dialogRef: MatDialogRef<GcpFilePickerComponent<T>>,
        @Inject(MAT_DIALOG_DATA) config: GcpFilePickerConfig
    ) {
        this.root = config.folder
        this.afStorage = config.afStorage.storage.app.storage(config.bucket)
    }

    onItemSelected(element: GCPStorageItem) {
        if (element.objectType == GCPStorageItem.FOLDER) {
            this.loadFolder(`${this.root}/${element.name}`)
        } else {
            this.dialogRef.close(element)
        }
    }

    ngOnInit(): void {
        this.loadFolder(this.root)
    }

    private loadFolder(path: string) {
        if (!path) {
            return
        }
        this.isLoading = true

        if (path.endsWith(GcpFilePickerComponent.BACK)) {
            path = path.replace("/" + GcpFilePickerComponent.BACK, "")
        }

        this.dataSource = []
        const ref = this.afStorage.ref(path)

        from(ref.listAll().then(bucketContent => {
            const prefixes = bucketContent.prefixes.map(p => new GCPStorageItem(p.name, "folder"))
            const items = bucketContent.items

            if (path != this.root) {
                prefixes.push(new GCPStorageItem(GcpFilePickerComponent.BACK, "folder"))
            }

            const prefixesObs: Observable<GCPStorageItem[]> = from(prefixes).pipe(toArray())

            let itemsObs = of([] as GCPStorageItem[])
            if (items.length > 0) {
                itemsObs =
                    combineLatest(items.map(item => {
                        return from(item.getMetadata()).pipe(
                            map(r =>
                                new GCPStorageItem(
                                    item.name,
                                    "file",
                                    item.fullPath,
                                    r.size,
                                    r.contentType,
                                    new Date(r.timeCreated), item)
                            ),
                        )
                    }))
            }

            return zip(prefixesObs, itemsObs).pipe(map(res => [].concat(...res)))
        })).pipe(mergeMap(obs => obs),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(items => {
            this.isLoading = false
            this.bucketName = ref.toString()
            if (items && items.length > 0) {
                this.dataSource = items
            } else {
                this.dataSource = []
            }
        })
    }

    onPreview(element: GCPStorageItem) {
        element.getDownloadUrl().then(url => {
            window.open(url, "_blank");
        })
    }
}

export interface GcpFilePickerConfig {
    bucket: string
    folder: string
    afStorage: AngularFireStorage
}

export class GCPStorageItem {

    static FOLDER = "folder"

    constructor(public name: string,
                public objectType: "file" | "folder",
                public gsFullPath?: string,
                public size?: number,
                public contentType?: string,
                public uploadedAt?: Date,
                public reference?: firebase.storage.Reference) {
    }

    get displayName(): string {
        if (this.objectType == GCPStorageItem.FOLDER && this.name != GcpFilePickerComponent.BACK) {
            return this.name + "/"
        }
        return this.name
    }

    get objectTypeDisplayName(): string {
        if (this.objectType == GCPStorageItem.FOLDER) {
            return GCPStorageItem.FOLDER
        }
        return this.contentType ? this.contentType : "-"
    }

    get sizeInMB(): string {
        if (!this.size) {
            return ""
        }
        return Utils.formatBytes(this.size)
    }

    getDownloadUrl(): Promise<string> {
        return this.reference.getDownloadURL()
    }
}

