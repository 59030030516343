import {BaseModel} from '@looma/shared/models/base_model';
import {Utils} from '@looma/shared/utils';
import {ButtonActions} from './button_actions';

export interface RowAction<T extends BaseModel> {
    key: string,
    label?: string,
    icon?: string,
    primary?: boolean
    showAlways?: boolean // show item even if it's not available - it will be shown as a disabled button
    handler?: (item: T) => any
    available?: (item: T) => any
}

export class RowActionRegistry<T extends BaseModel> {
    private registeredActions: RowAction<T>[] = [];
    private itemActions = new Map<any, RowActionCollection<T>>();
    private primaryCount = 0;
    private secondaryCount = 0;

    constructor(actions?: RowAction<T>[]) {
        if (Array.isArray(actions)) {
            this.setItems(actions)
        }
    }


    setItems(actions: RowAction<T>[]) {
        this.primaryCount = this.secondaryCount = 0;
        this.itemActions.clear();
        this.registeredActions.length = 0;
        this.addItems(actions)
    }

    addItem(actions: RowAction<T>) {
        this.addItems([actions]);
    }

    addItems(actions: RowAction<T>[]) {
        for (const action of actions) {
            action.label = action.label || action.key;

            if (!Utils.isFunction(action.handler)) {
                action.handler = _ => null
            }

            if (!Utils.isFunction(action.available)) {
                action.available = _ => false
            }

            if (action.primary && !action.icon) {
                switch (action.key) {
                    case ButtonActions.Delete:
                        action.icon = 'clear';
                        break;
                    case ButtonActions.View:
                        action.icon = 'details';
                        break;
                    case ButtonActions.Edit:
                        action.icon = 'edit';
                        break;
                    default:
                        action.icon = 'error';
                        break;
                }
            } else {
                if (!Utils.isBoolean(action.showAlways)) {
                    action.showAlways = true;
                }
            }
            if (action.primary) {
                this.primaryCount += 1;
            } else {
                this.secondaryCount += 1;
            }

            this.registeredActions.push(action);
        }

    }

    forItem(item: T): RowActionCollection<T> | null {
        const id = item.getId();
        if (!this.itemActions.has(id)) {
            let primary: RowAction<T>[] = null;
            let secondary: RowAction<T>[] = null;
            let assignedActions: RowActionCollection<T> = null;
            this.registeredActions.forEach(action => {
                let canAdd = action.available(item);
                if (!canAdd) {
                    canAdd = action.showAlways;
                }
                if (canAdd) {
                    if (action.primary) {
                        primary = primary || [];
                        primary.push(action)
                    } else {
                        secondary = secondary || [];
                        secondary.push(action)
                    }
                }
            });

            if (primary || secondary) {
                assignedActions = new RowActionCollection(primary || [], secondary || [])
            }
            this.itemActions.set(id, assignedActions);
        }
        return this.itemActions.get(id);
    }

    invalidateItem(item: T) {
        const id = item?.getId()
        this.itemActions.delete(id)
    }

    invalidate(item: T) {
        this.itemActions.delete(item.getId());
    }

    get isEmpty(): boolean {
        return this.buttonsCount == 0;
    }

    get buttonsCount(): number {
        return this.primaryCount + (this.secondaryCount > 0 ? 1 : 0);
    }
}

class RowActionCollection<T extends BaseModel> {

    constructor(public primary: RowAction<T>[], public secondary: RowAction<T>[]) {
    }

    get hasPrimary(): boolean {
        return !!this.primary.length
    }

    get hasSecondary(): boolean {
        return !!this.secondary.length
    }

    get isEmpty(): boolean {
        return !this.hasPrimary && !this.hasSecondary
    }
}
