import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {BoundingRect, Utils} from '@looma/shared/utils';
import {BaseModel} from '@looma/shared/models/base_model';
import {takeUntil} from 'rxjs/operators';
import {LayoutService} from '../../../services/layout.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
    BaseCursorLoader,
    CursorDataBatch,
    CursorDataProvider,
    CursorItemOpType,
    LoaderItemOp
} from '@looma/shared/cursor_loader';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";


@LifecycleHooks()
@Component({
    selector: 'app-aspect-ratio-grid',
    templateUrl: './aspect-ratio-grid.component.html',
    styleUrls: ['./aspect-ratio-grid.component.scss']
})
export class AspectRatioGridComponent<T extends BaseModel> implements OnInit, OnDestroy, AfterViewInit {

    private cellSizeStylesheet: HTMLStyleElement;
    private styleSheetEl: HTMLStyleElement;
    private _columnsCount = 3;
    private cellWidthPercent = 33;
    private isAttached = false;
    private cellPaddingCssRule: string;
    cellBoundingRect: BoundingRect;
    viewportHeight: number;
    visibleRowsCount: number;
    private dataProvider = new CursorDataProvider<T>();
    private scrollbarWidth: number;

    datasourceRows: T[][] = [];

    @ViewChild('proxyEl', {static: true}) proxyEl: ElementRef;
    @ViewChild('rootEl', {static: true}) rootEl: ElementRef;
    @ViewChild('virtualScrollEl', {static: true}) virtualScrollEl: CdkVirtualScrollViewport;
    @ViewChild('scrollMeasureEl', {static: true}) scrollMeasureEl: ElementRef;

    @Input('aspectRatio')
    set aspectRatio(value: string) {
        const rule = getAspectRatioCssPaddingRule(value);
        if (rule && rule !== this.cellPaddingCssRule) {
            this.cellPaddingCssRule = rule;
            this.refreshCssRules();
        }
    }

    @Input('columnsCount')
    set columnsCount(value: number) {
        const perc = makePercent(100 / value);
        if (!isNaN(perc) && this.cellWidthPercent !== perc) {
            this.cellWidthPercent = perc;
            this._columnsCount = value;
            this.refreshCssRules();
        }
    }

    get columnsCount(): number {
        return this._columnsCount;
    }

    @Input('dataLoader')
    set dataLoader(value: BaseCursorLoader<T>) {
        this.datasourceRows = [];
        this.dataProvider.setLoader(value);

        if (value) {
            if (this.isAttached) {
                this.dataProvider.next();
            }
            value.onItemMutate().pipe(
                takeUntil(Utils.onDestroy(this)) as any
            ).subscribe((v: LoaderItemOp<T>) => {
                this.performItemOp(v);
            })
        }

    }


    @Input('itemTemplate')
    itemTemplate: TemplateRef<any>;

    constructor(
        private svcLayout: LayoutService,
        private changeDetector: ChangeDetectorRef) {
        this.cellPaddingCssRule = getAspectRatioCssPaddingRule('16:9');

        this.dataProvider.onDataAvailable().pipe(
            takeUntil(Utils.onDestroy(this)) as any
        ).subscribe((value: CursorDataBatch<T>) => {

            if (value.replace) {
                this.clearData()
            }
            this.addData(value.data);

        });
    }


    private performItemOp(item: LoaderItemOp<T>): void {
        let data: T[] = [];
        let changed = false;
        if (item.op == CursorItemOpType.Add) {
            changed = true
            data = Utils.flatten(this.datasourceRows)
            if (item.position === 0) {
                // prepend
                data.unshift(item.item)
            } else if (item.position == -1) {
                // append
                data.push(item.item)
            } else if (item.position < data.length) {
                data.splice(item.position, 0, item.item)
            } else {
                changed = false
            }
        } else {
            for (const row of this.datasourceRows) {
                for (const rowItem of row) {
                    if (Utils.itemsEqual(rowItem, item.item)) {
                        switch (item.op) {
                            case CursorItemOpType.Remove:
                                changed = true
                                continue;
                            case CursorItemOpType.Replace:
                                data.push(item.item);
                                changed = true
                                continue
                        }
                    } else {
                        data.push(rowItem)
                    }
                }
            }
        }

        if (changed) {
            this.datasourceRows.length = 0;
            this.addData(data);
        }

    }

    private addData(items: any[]): void {
        let row = null;

        if (this.datasourceRows.length > 0) {
            const lastRow = this.datasourceRows[this.datasourceRows.length - 1];
            if (lastRow.length < this.columnsCount) {
                row = lastRow;
            }
        }
        for (const item of items) {
            if (!row || row.length === this.columnsCount) {
                row = [];
                this.datasourceRows.push(row);
            }
            row.push(item)
        }
        this.datasourceRows = [].concat(this.datasourceRows)
    }

    private clearData(): void {
        this.datasourceRows.length = 0
    }

    ngOnInit(): void {
        this.isAttached = true;
        this.refreshCssRules();

        this.dataProvider.next();
    }

    ngOnDestroy(): void {
        this.isAttached = false;
    }

    ngAfterViewInit(): void {

        const scrollEl = this.scrollMeasureEl.nativeElement as HTMLElement;
        this.scrollbarWidth = scrollEl.offsetWidth - scrollEl.clientWidth;

        Utils.onResize(this.proxyEl).pipe(
            takeUntil(Utils.onDestroy(this)) as any
        ).subscribe((value: BoundingRect) => {
            value.width -= Math.ceil(this.scrollbarWidth / this.columnsCount);
            this.cellBoundingRect = value;
            this.syncVisibleRowCount();

            this.cellSizeStylesheet = this.removeCssRules(this.cellSizeStylesheet);
            this.cellSizeStylesheet = Utils.appendCssRules(this.rootEl, `
            .cell_size{ width:${value.width}px; height:${value.height}px; padding:8px; }
            `,`
            .cell_width{ width:${value.width}px; }
            `)

        });

        Utils.onResize(this.virtualScrollEl.elementRef).pipe(
            takeUntil(Utils.onDestroy(this)) as any
        ).subscribe((value: BoundingRect) => {
            this.viewportHeight = value.height;
            this.syncVisibleRowCount();
        });

        this.virtualScrollEl.scrolledIndexChange.subscribe(newIndex => {
            const lastVisible = newIndex + this.visibleRowsCount;
            if (lastVisible && lastVisible >= this.datasourceRows.length - 1) {
                const triggered = this.dataProvider.next();
            }
        });
    }

    private removeCssRules(style: HTMLStyleElement): HTMLStyleElement {
        if (style) {
            style.parentNode.removeChild(style);
        }
        return style;
    }

    private syncVisibleRowCount(): void {
        if (this.cellBoundingRect && !isNaN(this.cellBoundingRect.height + this.viewportHeight)) {
            const newVisibleCount = Math.ceil(this.viewportHeight / this.cellBoundingRect.height)
            if (this.visibleRowsCount != newVisibleCount) {
                this.visibleRowsCount = newVisibleCount;
                this.changeDetector.detectChanges();
            }

        }
    }

    private refreshCssRules(): void {
        if (!this.isAttached) {
            return
        }

        this.removeCssRules(this.styleSheetEl);
        this.styleSheetEl = Utils.appendCssRules(this.rootEl,
            `.cell{flex-basis:${this.cellWidthPercent}%}`,
            `.aspectratio{padding-top: ${this.cellPaddingCssRule};}`
        );
    }


}

function getAspectRatioCssPaddingRule(value: string): string {
    const matches = value.match(/^(?<width>\d+):(?<height>\d+)((?<op>\+|\-)(?<offset>\d+)(?<unit>px|em))?$/) as { groups: {} };
    if (matches) {
        const width = parseFloat(matches.groups['width']),
            height = parseFloat(matches.groups['height']),
            offsetOp = matches.groups['op'],
            offsetSize = parseInt(matches.groups['offset'], 10),
            offsetUnit = matches.groups['unit'],
            ratio = makePercent(height / width * 100);

        if (isNaN(ratio)) {
            return null
        }
        let rule = `${ratio}%`;
        if (!isNaN(offsetSize) && offsetUnit && offsetOp) {
            rule = `calc(${rule} ${offsetOp} ${offsetSize}${offsetUnit})`;
        }
        return rule
    }
    return null
}

function makePercent(ratio: number): number {
    if (ratio <= 0 || ratio > 100 || isNaN(ratio)) {
        return NaN
    }
    return Utils.round(ratio, 2)
}







