<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="General">
            <div style="width: 200px; height:200px;">
                <app-image-tile-uploader
                    [imageUrl]="imageUrl"
                    [texts]="{empty:'Click to upload cover image', nonEmpty:'Click to change cover image'}"
                    (onFileUploaded)="handleFileUploaded($event)"
                    (onUploadStateChanged)="handleUploaderStateChanged($event)"
                ></app-image-tile-uploader>
            </div>

            <app-autocomplete-search-field [disabled]="!program.isNewRecord()" placeholder="Retailer"
                                           [value]="program?.retailer?.namedValue()" searchObjectType="Retailer"
                                           selectionMode="single"
                                           appearance="outline"
                                           (valueChanged)="onRetailerSelected($event)"></app-autocomplete-search-field>

            <mat-form-field style="display: block" appearance="outline">
                <mat-label>Program Name</mat-label>
                <input matInput
                       [(ngModel)]="program.name"
                       placeholder="Name"/>
            </mat-form-field>

            <app-autocomplete-search-field
                placeholder="Product Category"
                [readonly]="!program.isNewRecord()"
                [showClear]="program.isNewRecord()"
                [value]="selectedProductCategory"
                searchObjectType="ProductCategory"
                selectionMode="single"
                appearance="outline"
                (valueChanged)="onProductCategorySelected($event)">
            </app-autocomplete-search-field>

            <app-autocomplete-search-field
                placeholder="Active Application"
                [readonly]="!program.isNewRecord()"
                [showClear]="program.isNewRecord()"
                [value]="selectedKioskApp"
                [searchValues]="KioskApps"
                appearance="outline"
                (valueChanged)="onKioskAppSelected($event)">
            </app-autocomplete-search-field>

            <div style="display: flex; flex-direction: column; gap: 15px">
                <div class="mat-form-field" appearance="outline">
                    <mat-slide-toggle [(ngModel)]="program.active">Active</mat-slide-toggle>
                </div>

                <div class="mat-form-field" appearance="outline">
                    <mat-slide-toggle [(ngModel)]="program.campaignVisibleInLoop">Campaign visible in Loop™ Dashboard?
                    </mat-slide-toggle>
                </div>

                <div class="mat-form-field" appearance="outline">
                    <mat-slide-toggle [(ngModel)]="program.cprEnabled">CPR Enabled?</mat-slide-toggle>
                </div>

                <div class="mat-form-field" appearance="outline">
                    <mat-slide-toggle [(ngModel)]="program.executionReportingEnabled">Execution Reporting Enabled?
                    </mat-slide-toggle>
                </div>

                <div class="mat-form-field" appearance="outline">
                    <mat-slide-toggle [(ngModel)]="program.changeoverSettings.enabled">Changeover Enabled?
                    </mat-slide-toggle>
                </div>

                <div class="mat-form-field" appearance="outline">
                    <mat-slide-toggle [(ngModel)]="program.interestCollectionEnabled">Interest Collection Enabled?
                    </mat-slide-toggle>
                </div>

                <mat-form-field style="display: block" appearance="outline" *ngIf="program.changeoverSettings.enabled">
                    <mat-label>Changeover number of days</mat-label>
                    <input matInput
                           [(ngModel)]="program.changeoverSettings.numDays"
                           type="number" min="1"
                           placeholder="Number of days"/>
                </mat-form-field>

            </div>
        </mat-tab>

        <mat-tab label="Interest Collection Management">
            <mat-form-field appearance="outline">
                <mat-label>Campaign Cost Structure</mat-label>
                <mat-select
                    [value]="program?.costStructure" [compareWith]="isSameObj"
                    (selectionChange)="onCostStructureChanged($event)">
                    <mat-option *ngFor="let item of availableCostStructures | keyvalue"
                                [value]="item.key">{{ item.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Program Type</mat-label>
                <mat-select
                    [value]="program?.programType" [compareWith]="isSameObj"
                    (selectionChange)="onProgramTypeChanged($event)">
                    <mat-option *ngFor="let item of programTypesMap | keyvalue"
                                [value]="item.key">{{ item.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Program Flexibility</mat-label>
                <mat-select
                    [value]="program?.flexibility" [compareWith]="isSameObj"
                    (selectionChange)="onFlexibilityChanged($event)">
                    <mat-option *ngFor="let item of availableFlexibilities | keyvalue"
                                [value]="item.key">{{ item.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="program?.flexibility == 'Segmented'">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Enter a segment</mat-label>
                    <input matInput [(ngModel)]="newSegment">
                    <button mat-button *ngIf="newSegment" matSuffix (click)="addSegment()">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-form-field>

                <mat-list>
                    <mat-list-item *ngFor="let segment of segments; let i = index">
                        <div class="segment-container">
                            <span class="segment-name">{{ segment }}</span>
                            <button mat-icon-button (click)="removeSegment(i, 'segment')">
                                <mat-icon>remove_circle</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Airtime</mat-label>
                <mat-select
                    [value]="program?.airtimeType" [compareWith]="isSameObj"
                    (selectionChange)="onAirtimeTypeChanged($event)">
                    <mat-option *ngFor="let item of availableAirtimeTypes | keyvalue"
                                [value]="item.key">{{ item.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="program?.airtimeType == 'Flexible'">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Enter an airtime segment</mat-label>
                    <input matInput [(ngModel)]="newAirtimeSegment">
                    <button mat-button *ngIf="newAirtimeSegment" matSuffix (click)="addAirtimeSegment()">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-form-field>

                <mat-list>
                    <mat-list-item *ngFor="let segment of airtimeSegments; let i = index">
                        <div class="segment-container">
                            <span class="segment-name">{{ segment }}</span>
                            <button mat-icon-button (click)="removeSegment(i, 'airtime')">
                                <mat-icon>remove_circle</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Campaign Slots per Period</mat-label>
                <input matInput type="number"
                       [(ngModel)]="program.campaignSlotsPerPeriod"
                       min="0"
                />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Number of stores</mat-label>
                <input matInput type="number"
                       [(ngModel)]="program.numberOfStores"
                       min="0"
                />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Max Featured Items per Campaign</mat-label>
                <input matInput
                       type="number"
                       [(ngModel)]="program.maxFeaturedItemsPerCampaign"
                       min="0"/>
                <mat-icon matSuffix>percentage</mat-icon>
            </mat-form-field>


            <mat-form-field appearance="outline">
                <mat-label>Distribution Requirement</mat-label>
                <input matInput type="number"
                       [(ngModel)]="program.distributionRequirement"
                       min="0" max="101"
                />
                <mat-icon matSuffix>percentage</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Product segmentation</mat-label>
                <mat-select
                    [value]="program?.productSegmentation" [compareWith]="isSameObj"
                    (selectionChange)="onProductSegmentationTypeChanged($event)">
                    <mat-option *ngFor="let item of availableProductSegmentation | keyvalue"
                                [value]="item.key">{{ item.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <div *ngIf="program?.productSegmentation == 'ProductSegments'">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Enter a product segment</mat-label>
                    <input matInput [(ngModel)]="newProductSegment">
                    <button mat-button *ngIf="newProductSegment" matSuffix (click)="addAProductSegment()">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-form-field>

                <mat-list>
                    <mat-list-item *ngFor="let segment of productSegments; let i = index">
                        <div class="segment-container">
                            <span class="segment-name">{{ segment }}</span>
                            <button mat-icon-button (click)="removeSegment(i, 'product')">
                                <mat-icon>remove_circle</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Description</mat-label>
                <textarea style="height: 150px" matInput [(ngModel)]="program.description"
                          placeholder="Enter program description"></textarea>
            </mat-form-field>

            <div class="attachment_row">
                <div fxFlex="100%">
                    <span style="margin-right: 16px;" *ngIf="!programSummaryUrl">Program Summary</span>
                    <a *ngIf="programSummaryUrl"
                       [href]="programSummaryUrl"
                       target="_blank">Program Summary</a>
                </div>
                <button mat-icon-button [disabled]="isBusy || hasUploadsInProgress"
                        (click)="openFilePicker()">
                    <mat-icon>file_upload</mat-icon>
                </button>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
    <button [disabled]="!canSave()" mat-raised-button color="primary" (click)="handleAction('ok')">Ok</button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>