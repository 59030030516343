import {AfterViewInit, Component, ElementRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LifecycleHooks} from '@looma/shared/lifecycle_utils';
import {BehaviorSubject, Observable} from 'rxjs';
import * as SvgPanZoom from 'svg-pan-zoom';
import {ImageSource, Utils} from '@looma/shared/utils';
import {filter, switchMap} from 'rxjs/operators';
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";

@LifecycleHooks()
@Component({
    selector: 'looma-zoomable-image',
    templateUrl: './looma-zoomable-image.component.html',
    styleUrls: ['./looma-zoomable-image.component.scss']
})
export class LoomaZoomableImageComponent implements OnInit, AfterViewInit {

    private imageSourceSubject = new BehaviorSubject<ImageSource>(null)

    public panZoom: SvgPanZoom.Instance;

    @Input() label: string;
    
    @ViewChild('svgPreview', {static: true}) el: ElementRef<SVGElement>

    constructor(
    ) {
    }

    ngOnInit(): void {
    }

    @Input() set imageUrl(value: string) {
        this.imageSourceSubject.next(new ImageSource(value))
    }

    @Input() set imageSource(value: ImageSource) {
        if(value instanceof ImageSource){
            this.imageSourceSubject.next(value)    
        }else{
            this.imageSourceSubject.next(new ImageSource(Utils.BLANK_IMAGE))
        }
        
    }
    
    get canRotateImage(): boolean{
        const source = this.imageSourceSubject.value;
        if(!source){
            return false
        }
        if(source.isBusy){
            return false
        }
        return true;
    }


    setZoom(delta: number) {
        switch (delta) {
            case 0:
                this.panZoom.reset();
                break
            case 1:
                this.panZoom.zoomIn();
                break
            case -1:
                this.panZoom.zoomOut()
                break
        }
    }
    
    reset(){
        this.setZoom(0);
        const source = this.imageSourceSubject.value;
        if (source) {
            source.resetRotation()
        }
    }

    rotate(clockwise: boolean) {
        const source = this.imageSourceSubject.value;
        if (source) {
            source.rotate(clockwise)
        }
    }

    onImageAvailable(): Observable<string> {
        return this.imageSourceSubject.pipe(
            filter(value => value instanceof ImageSource),
            switchMap(value => {
                return value.onUrlChanged()
            })
        )
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.panZoom = svgPanZoom(this.el.nativeElement, {
                maxZoom: 4,
                minZoom: 0,
                fit: false,
            });

            this.panZoom.setMinZoom(1);
        })
    }

}
