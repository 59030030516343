import {NamedValue} from '@looma/shared/types/named_value';
import {BaseModel} from '@looma/shared/models/base_model';
import {FilterConditionOperator} from '@looma/shared/search';

export class DeviceSearchCriteria extends BaseModel {
    id: number;
    name: string;
    cursor: string;
    text: string;
    pageSize: number;
    filters: DeviceFieldSearchCondition[] = [];

    static forDeviceId(...args: string[]): DeviceSearchCriteria {
        if (args.length === 0) {
            return this.forDeviceId('-1')
        }
        const condition = new DeviceFieldSearchCondition();
        condition.deviceField = DeviceFilterableField.Id;
        condition.operator = args.length > 1 ? FilterConditionOperator.In : FilterConditionOperator.Eq;
        condition.values = args.map(value => {
            return NamedValue.from(value + '', value + '')
        });
        const criteria = new DeviceSearchCriteria();
        criteria.filters.push(condition);
        return criteria
    }

    clone(): DeviceSearchCriteria {
        const mirror = new DeviceSearchCriteria();
        const data = JSON.parse(JSON.stringify(this));
        mirror.assign(data);
        return mirror;
    }

    toPlainObject(): any {
        return {
            pageSize: this.pageSize,
            cursor: this.cursor,
            text: this.text,
            filters: this.filters.map(filter => {
                return {
                    field: filter.deviceField.key,
                    operator: filter.operator.key,
                    value: filter.values.map(value => {
                        return {value: value.value, name: value.name}
                    }),
                }
            }),
        }
    }
}


export class DeviceFieldSearchCondition {
    deviceField: DeviceFilterableField;
    operator: FilterConditionOperator;
    values: NamedValue[];

    get isEmpty(): boolean {
        return !this.deviceField || !this.operator || !this.values || (this.values.length === 0)
    }
}


export class DeviceFilterableField {

    static readonly Id = new DeviceFilterableField('Id', {
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly DeviceId = new DeviceFilterableField('DeviceId', {
        displayName: 'Android ID',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn, FilterConditionOperator.Contains, FilterConditionOperator.NotContains]
    });
    static readonly DeviceAssetTag = new DeviceFilterableField('DeviceAssetTag', {
        displayName: 'Wifi Mac Address',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn, FilterConditionOperator.Contains, FilterConditionOperator.NotContains]
    });
    static readonly Retailer = new DeviceFilterableField('Retailer', {
        displayName: 'Retailer',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly RetailerRegion = new DeviceFilterableField('RetailerRegion', {
        displayName: 'Region',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly ProductCategory = new DeviceFilterableField('ProductCategory', {
        displayName: 'Product Category',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly Store = new DeviceFilterableField('Store', {
        displayName: 'Store',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly AssignedCampaign = new DeviceFilterableField('AssignedCampaign', {
        displayName: 'Assigned Campaign',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly ActiveCampaign = new DeviceFilterableField('ActiveCampaign', {
        displayName: 'Active Campaign',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly AssignedPlaylist = new DeviceFilterableField('AssignedPlaylist', {
        displayName: 'Assigned Playlist',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly ActivePlaylist = new DeviceFilterableField('ActivePlaylist', {
        displayName: 'Active Playlist',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly PlaybackStatus = new DeviceFilterableField('PlaybackStatus', {
        displayName: 'Playback Status',
        allowedValues: [
            NamedValue.from('-1', 'Inconsistent'),
            NamedValue.from('1', 'Reporting Now'),
            NamedValue.from('2', 'Warn 10 minutes'),
            NamedValue.from('3', 'Warn 1 hour'),
            NamedValue.from('4', 'Warn 3 hours'),
            NamedValue.from('5', 'Warn 1 day'),
            NamedValue.from('0', 'Unknown'),
        ],
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn]
    });
    static readonly InstalledAppsStatus = new DeviceFilterableField('InstalledAppsStatus', {
        displayName: 'Apps Status',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn],
        allowedValues: [
            NamedValue.from('outdated', 'Outdated'),
            NamedValue.from('up_to_date', 'Up to date'),
        ]
    });
    static readonly BatteryStatus = new DeviceFilterableField('BatteryStatus', {
        displayName: 'Battery Status',
        operators: [FilterConditionOperator.Eq, FilterConditionOperator.NotEq, FilterConditionOperator.In, FilterConditionOperator.NotIn],
        allowedValues: [
            NamedValue.from('1', 'Up'),
            NamedValue.from('2', 'Charging'),
            NamedValue.from('3', 'Charged'),
            NamedValue.from('4', 'Discharging'),
            NamedValue.from('5', 'Discharged'),
        ]
    });

    static readonly DeviceAssigned = new DeviceFilterableField('DeviceAssigned', {
        displayName: 'Is Assigned',
        operators: [FilterConditionOperator.Eq],
        allowedValues: [
            NamedValue.from('true', 'Assigned'),
            NamedValue.from('false', 'Unassigned'),
        ]
    });

    static readonly DeviceInstalled = new DeviceFilterableField('DeviceInstalled', {
        displayName: 'Is Installed',
        operators: [FilterConditionOperator.Eq],
        allowedValues: [
            NamedValue.from('true', 'Installed'),
            NamedValue.from('false', 'Not Installed'),
        ]
    });

    static readonly DeviceNamedFilter = new DeviceFilterableField('DeviceNamedFilter', {
        displayName: 'Device Group',
        operators: [FilterConditionOperator.Eq],
    });

    static readonly ALL = [
        DeviceFilterableField.Id,
        DeviceFilterableField.DeviceId,
        DeviceFilterableField.DeviceAssetTag,
        DeviceFilterableField.Retailer,
        DeviceFilterableField.RetailerRegion,
        DeviceFilterableField.ProductCategory,
        DeviceFilterableField.Store,
        DeviceFilterableField.AssignedCampaign,
        DeviceFilterableField.ActiveCampaign,
        DeviceFilterableField.AssignedPlaylist,
        DeviceFilterableField.ActivePlaylist,
        DeviceFilterableField.PlaybackStatus,
        DeviceFilterableField.InstalledAppsStatus,
        DeviceFilterableField.BatteryStatus,
        DeviceFilterableField.DeviceAssigned,
        DeviceFilterableField.DeviceInstalled,
        DeviceFilterableField.DeviceNamedFilter,

    ];


    operators: FilterConditionOperator[];
    allowedValues: NamedValue[] | null | undefined;
    displayName: string;

    constructor(public key: string, cfg: {
        operators: FilterConditionOperator[],
        allowedValues?: NamedValue[],
        displayName?: string,
    }) {
        Object.assign((this as any), cfg)
    }

    static ByKey(name: string): DeviceFilterableField | null {
        for (const mem of DeviceFilterableField.ALL) {
            if (mem.key === name) {
                return mem
            }
        }
        return null
    }
}

