<div style="width: 100%; height: 100%; padding-bottom: 16px" fxLayout="column">
    <mat-card>
        <div style="padding-bottom: 10px" *ngIf="!isPlaying">
            <button mat-raised-button color="accent" (click)="playPlaylist()">
                <mat-icon>play_arrow</mat-icon>
                Play playlist
            </button>
        </div>

        <div style="padding-bottom: 10px" *ngIf="isPlaying">
            <button mat-raised-button color="accent" (click)="stopPlayer()">
                <mat-icon>stop</mat-icon>
                Stop
            </button>
        </div>

        <div
            style="font-size: 16px; background-color: #b1d8b7; color: #2f5233;padding-bottom: 5px; padding-top: 5px; padding-left: 5px; width: 600px"
            *ngIf="isPlaying">
            <span style="font-weight: 500">{{videoName}}</span></div>
        <div>
            <video #videoA preload="auto" controls style="width: 600px">
                <source type="video/mp4"/>
            </video>
            <video #videoB preload="auto" controls style="width: 600px">
                <source type="video/mp4"/>
            </video>
        </div>
    </mat-card>
</div>
