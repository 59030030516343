import {Injectable} from "@angular/core";
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private snackBar: MatSnackBar) {
    }

    openSnackBarWithDuration(action: string, message: string, durationInSeconds: number): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(message, action, {
            duration: durationInSeconds * 1000,
        });
    }

    open(action: string, message: string): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(message, action, {
            duration: 0
        });
    }
}

