import { Component, ElementRef, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { NamedValue, SearchObject } from '@looma/shared/types/named_value';
import { SearchableObject, SearchableObjectType, SearchFieldCriteria } from '@looma/shared/search';
import { Utils } from '@looma/shared/utils';
import { debounceTime, distinctUntilChanged, filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BaseModel } from '@looma/shared/models/base_model';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { WordFilterable } from '@looma/shared/types/word_filterable';
import { SearchService } from "@looma/shared/services/search.sevice";

type selectionModeType = 'single' | 'multi';

@LifecycleHooks()
@Component({
    selector: 'app-autocomplete-search-field',
    templateUrl: './autocomplete-search-field.component.html',
    styleUrls: [ './autocomplete-search-field.component.scss' ]
})
export class AutocompleteSearchFieldComponent implements OnInit, OnDestroy{

    formControl = new FormControl();
    focusWatcher = new FocusWatcher(this);
    private searchExternal = new Subject<string>();
    searchResultsAvailable: Observable<SearchResult>;

    private searchSource: SearchableSource;
    selection = new Selection(this);

    private acceptValue = true;
    private _fieldDisabled = false;

    constructor(
        svcApi: SearchService
    ){
        this.searchSource = new SearchableSource(svcApi);
    }

    @Input('placeholder') placeholder: string;
    @Input('readonly') readonly: boolean;
    @Input('appearance') appearance: MatFormFieldAppearance = 'fill';

    @Input('allowNewValues') allowNewValues = false

    @Input('showClear') showClear;
    @Input('style') style;

    @Input('clearOn') set clearOn(value: Observable<any>){
        if(value){
            value.pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(value1 => this.clear())
        }
    }

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;


    @Input('value') set value(value: NamedValue | NamedValue[] | BaseModel | BaseModel[]){
        // accept incoming values only if the field wasn't previously focused
        if (!this.acceptValue) {
            return
        }

        let values = [];
        if (Array.isArray(value)) {
            values = value;
        } else {
            values = [ value ]
        }

        const opts: NamedValue[] = values.map(item => {
            if (item instanceof BaseModel) {
                return item.namedValue()
            } else if (item instanceof NamedValue) {
                return item
            } else {
                return null
            }
        }).filter(value1 => !!value1);

        this.selection.setValue(opts, false);
        if (!this.selection.multiSelection) {
            this.formControl.setValue(this.selection.value);
        }
    }

    getValue(): NamedValue{
        return this.selection.value
    }

    get formControlValue(): NamedValue | NamedValue[]{
        return this.formControl.value
    }

    @Input('searchObjectType') set searchObjectType(value: SearchableObjectType){
        this.searchSource.setSearchObjectType(value)
    }

    get searchObjectType(): SearchableObjectType{
        return this.searchSource.searchObjectType
    }

    @Input('searchCriteria') set searchCriteria(value: SearchFieldCriteria[] | SearchFieldCriteria){
        this.searchSource.setBaseCriteria(value);
    }

    @Input('searchValues') set searchValues(value: WordFilterable[]){
        if (Array.isArray(value)) {
            this.searchSource.setSearchData(value);
        }
    }

    private _tplEmptyValue: TemplateRef<any>
    @Input('tplEmptyValue') set tplEmptyValue(value: TemplateRef<any>){
        this._tplEmptyValue = value
    }

    get tplEmptyValue(): TemplateRef<any>{
        return this._tplEmptyValue
    }

    private _tplOption: TemplateRef<any>
    @Input('tplOption') set tplOption(value: TemplateRef<any>){
        this._tplOption = value
    }

    get tplOption(): TemplateRef<any>{
        return this._tplOption
    }

    @Input('disabled') set fieldDisabled(v: boolean){
        if (v) {
            this.formControl.disable()
        } else {
            this.formControl.enable()
        }
        this._fieldDisabled = v;
    }

    get fieldDisabled(): boolean{
        return this._fieldDisabled;
    }

    @Input('selectionMode') set selectionMode(value: selectionModeType){
        switch (value) {
            case 'multi':
            case 'single':
                this.selection.multiSelection = value == 'multi';
                break
        }
    }

    getPlaceholderText(): string{
        return this.placeholder
    }

    @Output('valueChanged') get valueChanged(): Observable<NamedValue | NamedValue[]>{
        return this.selection.onChanged.pipe(
            skip(1),
            map(selection => {
                if (selection.multiSelection) {
                    return [].concat(selection.values)
                } else {
                    return selection.value
                }
            }),
        )
    }

    onOptionSelected(ev: MatAutocompleteSelectedEvent){
        this.selection.addValue(ev.option.value as NamedValue);
        if (this.selection.multiSelection) {
            setTimeout(() => {
                this.focusWatcher.focus();
                this.formControl.setValue('');
            }, 10);
            if (this.searchInput) {
                this.searchInput.nativeElement.value = '';
            }

        } else {
            this.focusWatcher.blur();
        }

    }

    clear(){
        this.selection.clear()
    }


    ngOnInit(){
        this.searchResultsAvailable = merge(
            this.formControl.valueChanges.pipe(
                tap(x => {
                    if (x instanceof NamedValue) {
                        setTimeout(() => {
                            this.focusWatcher.blur()
                        }, 0)
                    }
                }),
                debounceTime(300),
            ),
            this.searchExternal
        ).pipe(
            filter(value => typeof value === 'string'),
            map(value => ((value || '')).trim()),
            filter(_ => this.focusWatcher.isFocused),
            switchMap((value: string) => {
                if (!this.selection.multiSelection) {
                    const v = this.selection.value;
                    if (v && v.getDisplayName() != value) {
                        this.selection.clear();
                    }
                }
                return this.searchSource.search(value)
            }),
            switchMap(options => {
                // refreshing the shown values whenever the user selects or removes a value
                return merge(
                    of(true),
                    this.selection.onChanged.pipe(
                        map(_ => true)
                    )
                ).pipe(
                    debounceTime(10),
                    map(value1 => {
                        return this.selection.trimSelection(options)
                    })
                )
            }),
            takeUntil(Utils.onDestroy(this)),
        ).pipe(
            map(searchResult => {
                const v = this.formControl.value as string
                if (this.allowNewValues && searchResult.isEmpty && v && v.length) {
                    const obj = new SearchObject()
                    obj.value = '-1'
                    obj.name = v.trim()
                    return new SearchResult([ obj ])
                }
                return searchResult
            })
        )

        this.focusWatcher.onFocusChanged().subscribe(isFocused => {
            const v = this.formControl.value;
            if (!isFocused) {
                this.acceptValue = true;
                if (!this.selection.multiSelection) {
                    if (!(v instanceof NamedValue)) {
                        this.clearInput();
                    }
                } else {
                    this.clearInput()

                }
            } else {
                this.acceptValue = false;
                const v = this.formControl.value;
                if (v instanceof NamedValue) {
                    this.searchExternal.next(v.getDisplayName())
                } else {
                    this.searchExternal.next('')
                }
            }
        });

    }


    clearInput(): void{
        this.formControl.setValue('');
        this.focusWatcher.clearValue();
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
        }
    }

    displayFn(a: NamedValue): string{
        return a ? a.name : '';
    }

    ngOnDestroy(): void{
    }

}


class FocusWatcher{

    focusSub = new Subject<boolean>();
    isFocused: boolean;
    htmlEl: HTMLInputElement;

    get focusedElement(): HTMLInputElement | null{
        if (this.isFocused) {
            return this.htmlEl
        }
        return null
    }

    constructor(private parent: AutocompleteSearchFieldComponent){
    }

    onFocusChanged(): Observable<boolean>{
        return this.focusSub.pipe(
            takeUntil(Utils.onDestroy(this.parent)),
            debounceTime(100),
            distinctUntilChanged(),
            tap(x => {
                this.isFocused = x
            })
        )
    }


    handleEvent(ev: FocusEvent){
        let newFocused = false;
        switch (ev.type) {
            case 'focus':
            case 'blur':
                newFocused = ev.type == 'focus';
                break;
            default:
                return
        }
        this.htmlEl = ev.target as HTMLInputElement;
        this.focusSub.next(newFocused);
    }

    blur(){
        if (this.htmlEl) {
            this.htmlEl.blur()
        }
    }

    focus(){
        if (this.htmlEl) {
            this.htmlEl.focus()
        }
    }

    clearValue(){
        if (this.htmlEl) {
            this.htmlEl.value = ''
        }
    }

}


class SearchableSource{

    private objType: SearchableObject;
    private baseCriteria: SearchFieldCriteria[] = [];
    private localSearch: LocalSearch;

    constructor(private svcApi: SearchService){
    }

    setSearchData(data: WordFilterable[]){
        if (!data) {
            this.localSearch = null;
        } else {
            this.localSearch = new LocalSearch(data);
        }
    }

    setSearchObjectType(kind: SearchableObjectType){
        this.objType = SearchableObject.lookup(kind);
    }

    get searchObjectType(): SearchableObjectType{
        return this.objType
    }

    setBaseCriteria(v: SearchFieldCriteria | SearchFieldCriteria[]){
        this.baseCriteria.length = 0;
        if (v instanceof SearchFieldCriteria) {
            v = [ v ];
        }
        if (Array.isArray(v)) {
            this.baseCriteria = this.baseCriteria.concat(v);
        }
    }


    search(query: string): Observable<SearchResult>{
        return this.doSearch(query).pipe(
            map(value => {
                return SearchResult.from(value)
            })
        )
    }

    private doSearch(query: string): Observable<SearchObject[]>{
        if (this.localSearch) {
            return this.localSearch.search(query) as Observable<SearchObject[]>
        }

        if (!this.objType) {
            return of([])
        }

        let criteria = [ SearchFieldCriteria.newTextContainsCriteria(query) ];
        if (this.baseCriteria.length) {
            criteria = criteria.concat(this.baseCriteria)
        }
        return this.svcApi.searchObject(this.objType, criteria);
    }


}

class LocalSearch{

    private searchPipe = new BehaviorSubject<string>('');
    private resultStream: Observable<NamedValue[]>;

    constructor(private data: WordFilterable[]){
        this.resultStream = Utils.filterByWords(of(data), this.searchPipe).pipe(
            map(searchResults => searchResults.map(res => {
                return NamedValue.from(res.getId(), res.getDisplayName());
            }))
        )
    }

    search(query: string): Observable<NamedValue[]>{
        this.searchPipe.next(query);
        return this.resultStream.pipe(
            take(1)
        )
    }
}

class Selection{
    values: NamedValue[] = [];
    onChanged = new BehaviorSubject<Selection>(this);
    multiSelection: boolean;

    constructor(private parent: AutocompleteSearchFieldComponent){

    }


    get value(): NamedValue{
        return this.values[0];
    }

    get isEmpty(): boolean{
        return this.values.length == 0
    }

    addValue(value: NamedValue){
        if (!value) {
            return
        }
        if (this.hasValue(value)) {
            return
        }
        if (this.multiSelection) {
            this.values.push(value)
        } else {
            this.values = [ value ]
        }
        this.onChanged.next(this);
    }

    removeValue(value: NamedValue): boolean{
        value = this.findValue(value);
        if (value) {
            this.values.splice(this.values.indexOf(value), 1);
            this.onChanged.next(this);
            return true;
        }
        return false;
    }

    setValue(value: NamedValue | NamedValue[], notifyChanged = true){
        if (!value) {
            return
        }
        if (value instanceof NamedValue) {
            value = [ value ];
        }
        this.values = value;
        if (notifyChanged) {
            this.onChanged.next(this)
        }

    }

    hasValue(value: NamedValue): boolean{
        if (!value) {
            return false
        }
        return !!this.values.find(existing => existing.getId() == value.getId())
    }

    private findValue(lookup: NamedValue): NamedValue{
        if (!lookup) {
            return null;
        }
        return this.values.find(existing => existing.getId() == lookup.getId());
    }

    clear(){
        if (this.values.length) {
            this.values.length = 0;
            this.parent.clearInput();
            setTimeout(() => {
                this.parent.focusWatcher.blur();
            }, 0);
            this.onChanged.next(this);

        }
    }

    trimSelection(v: NamedValue[] | SearchResult): SearchResult{
        let values: SearchObject[] = [];
        if (v instanceof SearchResult) {
            values = v.values;
        } else if (Array.isArray(v) && (v.length)) {
            values = v as SearchObject[]
        } else {
            return SearchResult.EMPTY;
        }
        return SearchResult.from(values.filter(value => !this.hasValue(value)))
    }


}

class SearchResult{

    static EMPTY = new SearchResult([])

    static from(values: SearchObject[]): SearchResult{
        if (!Array.isArray(values) || (values.length == 0)) {
            return SearchResult.EMPTY
        }
        return new SearchResult(values)
    }

    constructor(public readonly values: SearchObject[]){
    }

    get isEmpty(): boolean{
        return this.values.length == 0;
    }
}
