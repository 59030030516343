import {BaseModel} from "@looma/shared/models/base_model";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {User} from "@looma/shared/models/user";

export class DeviceSlotSegmentAssignmentLog extends BaseModel {
    createdAt: Date
    entries: DeviceSlotSegmentAssignmentLogEntry[]
    changedCount: number
    remainingCount: number
    deviceSlotSegment: DeviceSlotSegment
    user: User

    assign(input: any): this {
        super.assign(input)
        this.assignDate(input, 'createdAt')
        
        this.assignTypedObject(input, 'deviceSlotSegment', DeviceSlotSegment)
        this.assignTypedObject(input, 'user', User)

        const entries: DeviceSlotSegmentAssignmentLogEntry[] = []
        if (Array.isArray(input?.entries)) {
            for (const entry of input?.entries) {
                let devSlot: DeviceSlot = null
                if (entry?.deviceSlot) {
                    devSlot = new DeviceSlot().assign(entry?.deviceSlot)
                }
                entries.push({
                    op: entry.op,
                    deviceSlot: devSlot,
                    deviceSlotSegment: this.deviceSlotSegment
                })
            }
        }

        this.entries = entries

        return this
    }
}

export interface DeviceSlotSegmentAssignmentLogEntry {
    op: MutationOperation
    deviceSlot: DeviceSlot
    deviceSlotSegment: DeviceSlotSegment
}