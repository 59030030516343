<div app-looma-page pageTitle="Products" pageIcon="shopping_basket" [headerSideTpl]="headerSideTpl">
    <looma-grid [dataSource]="dataSource">
        <ng-template gridColumn="imageUrl" let-data="data">
            <a [href]="data.thumb_url" target="_blank" *ngIf="data.thumb_url">
                <img [src]="data.image_url" style="width: 48px; height: 48px; object-fit: contain"/>
            </a>
        </ng-template>
        <ng-template extraGridBodyContent>
            <div class="extraGridBodyContent" *ngIf="showUpcDetailsView"
                 style="position: absolute; top: 60px; left:0; right: 0; bottom: 0; padding: 16px; display: flex; overflow: auto; flex-wrap: wrap; justify-content: space-evenly">
                <div style="margin: 0 auto; align-self: center" *ngIf="isLoadingUpcDetails">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>
                <div style="margin: 0 auto; align-self: center" *ngIf="!isLoadingUpcDetails && !upcDetails?.length">
                    UPC not found
                </div>
                <div class="px-16 mb-32" *ngFor="let upcEntry of upcDetails">

                    <h2 style="margin: 16px 0 0 0 ">{{ upcEntry.retailer.retailer_name }}</h2>
                    <div class="py-8 bb h-48" fxLayout="row" fxLayoutAlign="space-between center"
                         *ngFor="let colName of upcDetailColumnNames">
                        <span class="h4" style="margin-right: 32px">{{ colName }}</span>
                        <span>{{ upcEntry.upcDetails.get(colName) }}</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </looma-grid>
</div>

<ng-template #headerSideTpl>
    <div style="display: flex; gap:16px; align-items: center">
        <mat-form-field>
            <mat-label>Search by UPC or Name</mat-label>
            <input #upcSearchInput autocomplete="off" matInput fxFlex (input)="onUpcSearchInputChanged($event)"/>
            <button matSuffix mat-icon-button aria-label="Clear" (click)="clearUpcSearchInput()"
                    *ngIf="upcSearchInput?.value != ''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <app-autocomplete-search-field showClear="true" placeholder="Brand" searchObjectType="BrandPartner"
                                       (valueChanged)="onBrandPartnerFilterChanged($event)" selectionMode="single"
                                       style="width: 300px;"></app-autocomplete-search-field>

        <mat-checkbox
            (change)="setShowArchivedProducts($event.checked)">
            <span style="color: white">Show Archived</span>
        </mat-checkbox>

        <button mat-raised-button (click)="addNewProduct()">Add Product</button>
        <button mat-raised-button (click)="bulkUploadProducts()">Bulk Upload</button>
        <button mat-raised-button (click)="downloadCsv()">Download</button>
        <button mat-raised-button (click)="onCustomProductsClicked()">Custom products</button>
    </div>
</ng-template>