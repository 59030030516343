import {BaseModel} from '@looma/shared/models/base_model';
import {RemotePlaylistTrack} from '@looma/shared/models/remote_playlist_track'
import {ProductCategory} from '@looma/shared/models/product_category'
import {RetailerRegion} from '@looma/shared/models/retailer_region'

export class RemotePlaylist extends BaseModel {
    id: number;
    name: string;
    fb_reference: string;
    fb_reference_short: string;
    tracks: RemotePlaylistTrack[];
    campaign_id: number;
    product_category_id: number;
    retailer_region_id: number;
    product_category: ProductCategory;
    retailer_region: RetailerRegion;
    campaign_library_file_ids: number[];


    assign(obj: any): this {
        super.assign(obj);

        this.assignDate(obj, 'session_updated_at');
        this.assignDate(obj, 'session_started_at');
        this.assignNested(obj, 'product_category', new ProductCategory());
        this.assignNested(obj, 'retailer_region', new RetailerRegion());

        if (Array.isArray(obj.tracks)) {
            this.tracks = obj.tracks.map(rawTrack => new RemotePlaylistTrack().assign(rawTrack))
        }

        if (this.fb_reference) {
            this.fb_reference_short = this.fb_reference.replace('server/data/tablets/playlists/', '')
        }

        if (Array.isArray(obj.campaign_library_file_ids)) {
            this.campaign_library_file_ids = obj.campaign_library_file_ids;
        } else {
            this.campaign_library_file_ids = [];
        }

        return this;
    }


}
