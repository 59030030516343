<div app-looma-page pageTitle="Brand Submission Status - {{promoProgram?.name}}" pageIcon="checklist">
    <looma-grid [dataSource]="dataSource" *ngIf="dataSource">
        <ng-template gridColumn="status" let-data="data">
            <span class="status-badge"
                  [ngClass]="{
                              'status-submissions-in': data.status === 'SubmissionsIn',
                              'status-pending-submissions': data.status === 'PendingSubmissions'
                          }">
                        {{getStatusName(data.status)}}
                    </span>
        </ng-template>

        <ng-template rowExpansionTemplate let-data="data">
            <looma-grid [dataSource]="dataSource.getSubmissionsDataSource(data)" [scrollContent]="false">
            </looma-grid>
        </ng-template>
    </looma-grid>


    <ng-template pageHeaderNav>
        <button mat-raised-button color="primary" (click)="export()">
            <mat-icon>cloud_download</mat-icon>
            Download CSV
        </button>
    </ng-template>
</div>
