import {BaseModel} from '@looma/shared/models/base_model';
import {ProductCategory} from '@looma/shared/models/product_category'
import {RetailerRegion} from '@looma/shared/models/retailer_region'
import {RetailerCampaign} from '@looma/shared/models/retailer_campaign'
import {WordFilterable} from '@looma/shared/types/word_filterable';
import {Store} from '@looma/shared/models/store'
import {Location, LocationInput} from '@looma/shared/models/location'
import {RetailerPromoProgram} from '@looma/shared/models/retailer_promo_program'
import {NamedValue} from '@looma/shared/types/named_value';
import {CursorFilter} from '@looma/shared/types/cursor_filter';
import {StoreGroup} from "@looma/shared/models/store_group";
import {RetailerDivision} from "@looma/shared/models/retailer_division";

export class Retailer extends BaseModel implements WordFilterable {
    static LoomaId = {
        LOOMA: 'LOO',
        GS: 'GS',
        LFS: 'LFS',
        KRO: 'KRO',
        HT: 'HT',
        HEB: 'HEB',
        SCH: 'SCH',
    }

    static ID = {
        LOOMA: '5',
        GS: '3',
        LFS: '2',
        KRO: '8',
        HT: '1',
        HEB: '4',
        SCH: '7',
    }

    id: number;
    retailer_id: string;
    retailer_name: string;
    website_url: string;
    product_categories?: ProductCategory[];
    regions: RetailerRegion[];
    divisions: RetailerDivision[];
    promoPrograms: RetailerPromoProgram[];
    location: Location;
    retailer_campaigns: RetailerCampaign[];
    stores: Store[];
    storeGroups: StoreGroup[];
    offlineAlertsDisabled: boolean

    static getSortedRetailers(retailers: Retailer[]): Retailer[] {
        // remove Looma from the list of retailers
        // sort the retailers alphabetically 
        // put gelson's at the end
        retailers = retailers.filter(ret => ret.retailer_id !== Retailer.LoomaId.LOOMA)

        const gsIndex = retailers.findIndex(value => value.retailer_id == this.LoomaId.GS);
        let gs: Retailer = null;
        if (gsIndex >= 0) {
            gs = retailers.splice(gsIndex, 1)[0]
        }

        retailers = retailers.sort((a, b) => (a.retailer_id || '').localeCompare(b.retailer_id || ''))
        if (gs) {
            retailers.push(gs)
        }
        return retailers;
    }

    static from(data: NamedValue): Retailer {
        if (!data) {
            return null
        }
        const r = new Retailer();
        r.id = data.intValue();
        r.retailer_name = data.getDisplayName();
        return r;
    }

    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'location', Location);
        this.assignTypedArray(input, 'product_categories', ProductCategory);

        if (Array.isArray(input.retailer_campaigns)) {
            this.retailer_campaigns = input.retailer_campaigns.map(x => (new RetailerCampaign()).assign(x))
        } else {
            this.retailer_campaigns = []
        }

        if (Array.isArray(input.regions)) {
            this.regions = input.regions.map(x => (new RetailerRegion()).assign(x))
        } else {
            this.regions = [];
        }

        this.assignTypedArray(input, 'stores', Store);
        this.assignTypedArray(input, 'promoPrograms', RetailerPromoProgram);
        this.assignTypedArray(input, 'storeGroups', StoreGroup);
        this.assignTypedArray(input, 'divisions', RetailerDivision);

        return this;
    }

    getPhrases(): string[] {
        return [this.retailer_name];
    }

    getDisplayName(): string {
        return this.retailer_name;
    }

    regionNames(): string[] {
        if (!this.regions) {
            return [];
        }
        return this.regions.map(r => r.region_name);
    }
}

export interface RetailerInput {
    id?: string
    ids?: string[]
    name?: string
    retailer_id?: string
    region_ids?: string[]
    product_category_ids?: string[]
    location?: LocationInput
    website_url?: string
    image_firebase_key?: string
    offlineAlertsDisabled?: boolean
}

export interface RetailerFeedFilter extends CursorFilter {
    id?: string
}
