<h2 mat-dialog-title>Copy segments</h2>
<ul>
    <li>Schedule: {{schedule.name}}</li>
    <li>Promo period: {{period.name}}</li>
    <li>Program: {{program.name}}</li>
</ul>

Select segments to be copied:
<mat-dialog-content>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <mat-checkbox (change)="onSelectionChanged(node,$event)">{{node.name}}</mat-checkbox>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            {{node.name}}
        </mat-tree-node>
    </mat-tree>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSave()" [disabled]="!canCopy()">Copy</button>
    <button mat-button (click)="onCancel()">cancel</button>
</mat-dialog-actions>
