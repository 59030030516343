import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {LoadingButtonComponent} from '@looma/shared/components/loading-button/loading-button.component';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from '@looma/shared/components/confirm-dialog/confirm-dialog.component';
import {LoomaZoomableImageComponent} from "@looma/shared/components/zoomable-image/looma-zoomable-image.component";
import {FlexModule} from "@angular/flex-layout";
import {MatTooltipModule} from "@angular/material/tooltip";
import {VideoPlayerComponent} from './video-player/video-player.component';
import {MatCardModule} from "@angular/material/card";
import { GcpFilePickerComponent } from './gcp-file-picker/gcp-file-picker.component';
import {MatTableModule} from "@angular/material/table";
import {MatMenuModule} from "@angular/material/menu";
import {MatDialogModule} from "@angular/material/dialog";
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        FlexModule,
        MatTooltipModule,
        MatCardModule,
        MatTableModule,
        MatMenuModule,
        MatDialogModule,
    ],
    declarations: [
        LoadingButtonComponent,
        ConfirmDialogComponent,
        LoomaZoomableImageComponent,
        VideoPlayerComponent,
        GcpFilePickerComponent,
        DropdownButtonComponent
    ],
    exports: [
        LoadingButtonComponent,
        LoomaZoomableImageComponent,
        VideoPlayerComponent,
        DropdownButtonComponent,
    ]
})
export class LoomaComponentsModule {
}
