import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SearchableObjectType} from '@looma/shared/search';
import {NamedValue} from '@looma/shared/types/named_value';
import {BaseModel} from '@looma/shared/models/base_model';
import {Utils} from "@looma/shared/utils";

@Component({
    selector: 'app-prompt-dialog',
    templateUrl: './prompt-dialog.component.html',
    styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent implements OnInit {

    textValue = '';
    namedValue: NamedValue;

    inputType = 'text'

    constructor(
        public dialogRef: MatDialogRef<PromptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public config: PromptDialogData) {

        config.negativeButtonText = config.negativeButtonText || 'Cancel';
        config.positiveButtonText = config.positiveButtonText || 'Ok';

        if (Utils.isString(config.initialText)) {
            this.textValue = config.initialText
        }

        if (config.type == 'number') {
            this.inputType = 'number'
        }
    }


    ngOnInit(): void {

    }

    cancel(): void {
        this.dialogRef.close(null)
    }

    closeWithResult(): void {
        let value: any = null;
        switch (this.config.type) {
            case 'text':
                value = this.textValue.trim();
                break;
            case 'search':
                value = this.namedValue;
                break;
            case 'number':
                value = parseFloat(this.textValue.trim())
                if (!isNaN(value)) {
                    if (value % 1 == 0) { // this is an int
                        value = parseInt(value)
                    }
                }
        }
        this.dialogRef.close(value)
    }

    handleValueChanged(v: NamedValue) {
        this.namedValue = v;
    }

    get hasValue(): boolean {
        switch (this.config.type) {
            case 'text':
                return this.textValue.trim() != '';
            case 'search':
                return !!this.namedValue;
            case 'number':
                return !isNaN(parseFloat(this.textValue.trim()))
        }
        return false
    }

}

export interface PromptDialogSearchConfig {
    objectType: SearchableObjectType
    values?: BaseModel[]
}

export interface PromptDialogData {
    title: string;
    message: string;
    type: 'text' | 'search' | 'number';
    initialText?: string;
    positiveButtonText?: string;
    negativeButtonText?: string;
    searchConfig?: PromptDialogSearchConfig
}
