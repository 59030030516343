<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
    <div *ngIf="playlistFirebasePath" style="padding: 16px 0; display: flex; align-items: center; gap: 8px">
        <div>
            <span style="font-weight: 500">Firebase Location </span><br/>{{playlistFirebasePath}}
        </div>
        <div style="flex:1"></div>
        <button mat-raised-button color="accent" (click)="sendPlaylistToDevice()">Send to device</button>
        <button mat-raised-button color="accent" (click)="getPlaylistLink()">Get link</button>
    </div>

    <div>
        <lib-video-player [playList]="playListFilesSubject"></lib-video-player>
    </div>

    <looma-grid *ngIf="dataSource" [dataSource]="dataSource" [scrollContent]="false">
        <ng-template rowActions="1" let-item="data">
            <mat-icon style="cursor: pointer;" (click)="playFile(item)">play_arrow</mat-icon>
        </ng-template>

        <ng-template gridColumn="content_type" let-playlistVersionEntry="data">
            <media-content-type-icon
                [mediaContentType]="playlistVersionEntry.mediaContentVersion?.mediaContent?.contentType"
                [title]="playlistVersionEntry.mediaContentVersion?.mediaContent?.displayName"></media-content-type-icon>
        </ng-template>

    </looma-grid>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>