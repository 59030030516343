import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoresListComponent} from './components/stores-list/stores-list.component';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import {StoresEditDialogComponent} from './components/stores-edit-dialog/stores-edit-dialog.component';

@NgModule({
    declarations: [StoresListComponent, StoresEditDialogComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule
    ],
    exports: [
        StoresListComponent
    ],
    entryComponents: [StoresEditDialogComponent]
})
export class StoresModule { }
