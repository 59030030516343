import {Component} from '@angular/core';
import {ModelDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {User, UserMutationData} from "@looma/shared/models/user";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {EMPTY, Observable} from "rxjs";
import {ApiDataService} from "../../../../services/api-data.service";
import {Utils} from "@looma/shared/utils";
import {LayoutService} from "../../../../services/layout.service";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {ColumnSortInfo, CursorFilter} from '@looma/shared/types/cursor_filter';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {
    DeleteItemComponent,
    EditItemComponent,
    ModelListPageComponent
} from "../../../../shared/model_list_page_component";
import {UserEditDialogComponent} from "../user-edit-dialog/user-edit-dialog.component";
import {NamedValue} from "@looma/shared/types/named_value";
import {ButtonActions} from "../../../../shared/button_actions";
import {flatMap, takeUntil} from "rxjs/operators";

@LifecycleHooks()
@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent extends ModelListPageComponent<User, CursorFilter>
    implements EditItemComponent<User>, DeleteItemComponent<User> {

    constructor(
        private svcApi: ApiDataService,
        public svcLayout: LayoutService) {
        super(User)
    }

    performDeleteItem(item: User): Observable<MutationResponse<User>> {
        return this.svcApi.deleteUser(item.id);
    }

    canDeleteItem(item: User): boolean {
        return true;
    }

    performEditItem(item: User): Observable<User> {
        return UserEditDialogComponent.open(this.svcLayout, item);
    }

    canEditItem(item: User): boolean {
        return true;
    }

    initDataSource(): ModelDataSource<User, CursorFilter> {
        return new UserDataSource(this.svcApi);
    }

    get dataSource(): UserDataSource {
        return super.dataSource as UserDataSource;
    }

    searchFyNameOrEmail($event: Event) {
        const term = (event.target as HTMLInputElement).value;
        this.dataSource.applyFilter({
            query: term,
        }, 300)
    }

    onBrandPartnerFilterChanged(data: NamedValue) {
        const bpIds = data ? [parseInt(data.value)] : []
        this.dataSource.applyFilter({brandPartnerIds: bpIds})
    }

    onRetailerFilterChanged(data: NamedValue) {
        const retailerId = data ? [parseInt(data.value)] : []
        this.dataSource.applyFilter({retailerIds: retailerId})
    }

    addNewUser(): void {
        this.performMenuAction(ButtonActions.Edit, new User())
    }

    getEmailNotification(data: User): string {
        return data.email_notification_sent_at;
    }

    sendEmailNotification(user: User) {
        this.svcLayout.confirm('Resend Invitation', `Are you sure you want to resend the invitation to ${user.email}?`).pipe(
            takeUntil(Utils.onDestroy(this)),
            flatMap(value => {
                if (value) {
                    let emailType: string
                    switch (user.invitationEmail) {
                        case 'sales': {
                            emailType = 'sales'
                            break
                        }
                        case 'content': {
                            emailType = 'content'
                            break
                        }
                        case 'none': {
                            emailType = 'none'
                            break
                        }
                    }

                    const userData: UserMutationData = {invitationEmail: emailType};
                    return this.svcApi.updateUser(user.id, userData)
                } else {
                    return EMPTY
                }
            })
        ).subscribe(resp => {
            if (resp.data.invitationEmail == 'none') {
                this.svcLayout.showSnackMessage('No email sent.')
            } else {
                this.svcLayout.showSnackMessage(resp.success ? 'Email Sent' : resp.message || 'Email send failure');
            }
        });
    }

    getLastLoggedInAt(lastLoggedInAt: Date): string {
        return lastLoggedInAt ? Utils.formatDate(lastLoggedInAt, 'short') : "-"
    }
}

class UserFilter implements CursorFilter {
    cursor: string;
    query: string;
    brandPartnerIds: number[];
    retailerIds: number[];
    sort: ColumnSortInfo[]
}

class UserDataSource extends ModelDataSource<User, UserFilter> {
    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Name',
                    width: '200px',
                    sortable: true,
                    valueReader: (item: User) => {
                        return item.display_name;
                    }
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true,
                    width: '300px',
                    valueReader: (item: User) => {
                        return item.email;
                    }
                },
                {
                    key: 'roles',
                    label: 'Roles',
                    valueReader: (item: User) => {
                        return item.roles.map(r => r.name).join(", ");
                    }
                },
                {
                    key: 'inviteSentAt',
                    label: 'Email invitation',
                    valueReader: (item: User) => {
                        return item.email_notification_sent_at;
                    }
                },
                {
                    key: 'status',
                    label: 'Status',
                    valueReader: (item: User) => {
                        return item.status;
                    }
                },
            ]
        });
    }

    loadData(dataFilter: UserFilter): Observable<CursorFeed<User>> {
        return this.svcApi.getUsers(dataFilter);
    }
}
