import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductListComponent} from './product-list/product-list.component';
import {AppCommonModule} from "../../shared/common.module";
import {LayoutModule} from "../../layout/layout.module";
import {ProductEditDialogComponent} from './product-edit-dialog/product-edit-dialog.component';
import {BulkProductUploadDialogComponent} from "./bulk-product-upload-dialog/bulk-product-upload-dialog.component";
import {ProductEditFormComponent} from './product-edit-form/product-edit-form.component';
import {CustomProductsComponent} from './custom-products/custom-products.component';
import {
    CreateCustomProductDialogComponent
} from './create-custom-product-dialog/create-custom-product-dialog.component';

@NgModule({
    declarations: [ProductListComponent, ProductEditDialogComponent, BulkProductUploadDialogComponent, ProductEditFormComponent, CustomProductsComponent, CreateCustomProductDialogComponent, CreateCustomProductDialogComponent],
    imports: [
        CommonModule,
        LayoutModule,
        AppCommonModule
    ],
    entryComponents: [ProductEditDialogComponent]
})
export class ProductsModule {
}
