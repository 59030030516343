import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../services/api-data.service";
import {LayoutService} from "../../../services/layout.service";
import {Observable} from "rxjs";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {CustomProductSubmission} from "../custom-products/custom-products.component";
import {isBusyUploadState, UploadedFileInfo, UploadState} from "@looma/shared/services/upload.service";
import {NamedValue} from "@looma/shared/types/named_value";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import gql from "graphql-tag";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";

@LifecycleHooks()
@Component({
    selector: 'app-create-custom-product-dialog',
    templateUrl: './create-custom-product-dialog.component.html',
    styleUrls: ['./create-custom-product-dialog.component.scss']
})
export class CreateCustomProductDialogComponent implements OnInit {

    form: FormGroup;
    submission: CustomProductSubmission
    isBusy: boolean;
    selectedRetailers: NamedValue[];
    private newProductImageFirebaseKey: string;
    private isUploadingFile = false

    static open(svcLayout: LayoutService, s: CustomProductSubmission): Observable<BrandPartner> {
        const data: CreateCustomProductDialogData = {
            submission: s,
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = data;
        dialogConfig.width = '800px';
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        return svcLayout.openDialogForResult(CreateCustomProductDialogComponent, dialogConfig)
    }

    constructor(private dialogRef: MatDialogRef<CreateCustomProductDialogComponent>,
                private fb: FormBuilder,
                private svcApi: ApiDataService,
                @Inject(MAT_DIALOG_DATA) data: CreateCustomProductDialogData) {
        if (data) {
            this.submission = data.submission
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            brand: new FormControl({
                value: this.submission.submission.brandPartner.name,
                disabled: true
            }, [Validators.required]),
            name: new FormControl({value: this.submission.name, disabled: true}, [Validators.required]),
            upc: new FormControl({value: this.submission.upc, disabled: true}, [Validators.required]),
        });

        this.selectedRetailers = [NamedValue.from(
            this.submission.submission.schedule.retailerPromoProgram.retailer.id,
            this.submission.submission.schedule.retailerPromoProgram.retailer.retailer_name
        )]
    }

    create() {
        const input: CreateCustomProductInput = {
            submissionId: this.submission.submission.id,
            productName: this.form.get("name").value,
            productUpc: this.form.get("upc").value,
            productImageFirebaseKey: this.newProductImageFirebaseKey,
            retailerIds: this.selectedRetailers.map(r => r.value),
            brandPartnerId: this.submission.submission.brandPartner.id,
        }

        this.svcApi.mutate(MUTATION_CREATE_CUSTOM_PRODUCT, {
            input: input
        }).pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(result => {
                if (result) {
                    this.dialogRef.close(result)
                }
            })

    }

    canSubmit() {
        return !this.isUploadingFile;
    }

    close() {
        this.dialogRef.close()
    }

    handleFileUploaded(data: UploadedFileInfo): void {
        if (data) {
            this.newProductImageFirebaseKey = data.firebaseKey
        }
    }

    handleUploaderStateChanged(state: UploadState): void {
        this.isUploadingFile = isBusyUploadState(state)
        if (this.isUploadingFile) {
            this.newProductImageFirebaseKey = null
        }
    }

    onRetailerSelected(values: NamedValue[]): void {
        this.selectedRetailers = [...values]
    }
}

interface CreateCustomProductDialogData {
    submission: CustomProductSubmission
}

interface CreateCustomProductInput {
    submissionId: string
    brandPartnerId: string
    productUpc: string
    productName: string
    retailerIds: string[]
    productImageFirebaseKey: string
}

const MUTATION_CREATE_CUSTOM_PRODUCT = gql`
    mutation createProductFromSubmission($input: CreateCustomProductInput!) {
        createProductFromSubmission(input: $input) {
            id
            name
        }        
    }
`
