import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {NamedValue} from "@looma/shared/types/named_value";
import {User, UserMutationData} from "@looma/shared/models/user";
import {forkJoin, Observable, Subscription} from "rxjs";
import {Utils} from "@looma/shared/utils";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {LayoutService} from "../../../../services/layout.service";
import {UserAccessPermission, UserRole} from "@looma/shared/models/user_role";
import {map, takeUntil} from "rxjs/operators";
import {MatSelectionListChange} from "@angular/material/list";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {SearchableField, SearchFieldCriteria} from "@looma/shared/search";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import gql from "graphql-tag";

interface BrandNode {
    id: string
    name: string;
    isChecked: boolean
    children?: BrandNode[];
}

interface FlatBrandNode {
    expandable: boolean;
    name: string;
    level: number;
    isChecked: boolean;
    id: string;
}

@LifecycleHooks()
@Component({
    selector: 'app-user-edit-dialog',
    templateUrl: './user-edit-dialog.component.html',
    styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit, OnDestroy {

    form: FormGroup;
    selectedBrands: NamedValue[] = [];
    selectedChildBrands: NamedValue[] = [];
    selectedRetailers: NamedValue[] = [];
    selectedPrograms: NamedValue[] = [];
    allUserRole: UserRole[] = [];
    selectedUserRoles: UserRole[] = [];
    selectedStoreGroups: NamedValue[] = [];

    currentUser: User;
    isSavingData = false;

    UserRole = UserRole

    private brandSearchSubscription: Subscription;

    storeGroupSearchCriteria: SearchFieldCriteria
    programSearchCriteria: SearchFieldCriteria;

    treeControl = new FlatTreeControl<FlatBrandNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        UserEditDialogComponent.transformer,
        node => node.level,
        node => node.expandable,
        node => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    TREE_DATA: BrandNode[] = [];

    static open(svcLayout: LayoutService, user: User): Observable<User> {
        if (!(user instanceof User)) {
            user = new User()
        }
        const dialogData: UserEditDialogData = {
            user: user
        }

        return svcLayout.openDialogForResult(UserEditDialogComponent, {
            data: dialogData,
            width: '1000px'
        })
    }

    static transformer = (node: BrandNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            id: node.id,
            level: level,
            isChecked: node.isChecked
        };
    }

    constructor(private fb: FormBuilder,
                private dialogRef: MatDialogRef<UserEditDialogComponent>,
                private svcApi: ApiDataService,
                private svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) data: UserEditDialogData) {

        const user = this.currentUser = data?.user;
        if (!user) {
            this.close();
            return
        }

        const brands = this.currentUser?.associations?.brands
        const childBrands = this.currentUser?.associations?.childBrands
        const retailers = this.currentUser?.associations?.retailers
        const storeGroups = this.currentUser?.associations?.storeGroups
        const programs = this.currentUser?.associations?.programs

        if (!this.currentUser.isNewRecord()) {
            this.selectedUserRoles = this.currentUser.roles;
        }

        this.storeGroupSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.AssignableUserId, this.currentUser.id || 0)
        this.programSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, this.currentUser.associations?.retailers[0]?.id || 0)

        if (Array.isArray(childBrands)) {
            for (const brand of childBrands) {
                this.selectedChildBrands.push(NamedValue.from(brand.id + '', brand.name, brand.looma_id))
            }
        }

        if (Array.isArray(brands)) {
            for (const brand of brands) {
                this.selectedBrands.push(NamedValue.from(brand.id + '', brand.name, brand.looma_id))
            }
            this.loadChildBrandsSelection(this.selectedBrands, true, this.selectedChildBrands)
        }

        if (Array.isArray(retailers)) {
            for (const retailer of retailers) {
                this.selectedRetailers.push(NamedValue.from(retailer.id + '', retailer.retailer_name, retailer.retailer_id))
            }
        }

        if (Array.isArray(storeGroups)) {
            for (const group of storeGroups) {
                this.selectedStoreGroups.push(group.namedValue())
            }
        }

        if (Array.isArray(programs)) {
            for (const p of programs) {
                this.selectedPrograms.push(p.namedValue())
            }
        }

        this.form = fb.group({
            display_name: new FormControl({
                value: this.currentUser.display_name,
                disabled: false
            }, [Validators.required]),
            email: new FormControl({
                value: this.currentUser.email,
                disabled: !this.currentUser.isNewRecord()
            }, [Validators.required, Validators.email]),
            is_administrator: new FormControl({value: false, disabled: !this.currentUser.isNewRecord()}),
            send_sales_invite_email: new FormControl({value: false, disabled: !this.canSendInvitationEmail()}),
            send_content_invite_email: new FormControl({value: false, disabled: !this.canSendInvitationEmail(),}),
            freshdeskUserId: new FormControl({value: this.currentUser?.metadata?.freshdeskUserId, disabled: false}),
        });
    }

    hasChild = (_: number, node: FlatBrandNode) => node.expandable;
    isParent = (_: number, node: FlatBrandNode) => this.treeControl.isExpandable(node);

    findParentNode(childNode: FlatBrandNode): FlatBrandNode | null {
        const nodeIndex = this.treeControl.dataNodes.indexOf(childNode);
        for (let i = nodeIndex - 1; i >= 0; i--) {
            if (this.treeControl.dataNodes[i].level < childNode.level) {
                return this.treeControl.dataNodes[i];
            }
        }
        return null;
    }

    toggleChildren(node: FlatBrandNode): void {
        if (!node.isChecked) {
            let parentNode = this.findParentNode(node);
            while (parentNode) {
                parentNode.isChecked = true;
                parentNode = this.findParentNode(parentNode);
            }
        }
        node.isChecked = !node.isChecked;

        // If it's a parent node, propagate the selection change to children
        if (node.expandable) {
            const descendants = this.treeControl.getDescendants(node);
            descendants.forEach(child => child.isChecked = node.isChecked);
        }
    }

    canSendInvitationEmail(): boolean {
        return (this.currentUser.isNewRecord() ||
            this.currentUser.isSignUpRequested() ||
            this.currentUser.isPendingPasswordSetup()) && !this.currentUser.isAdmin()
    }

    removeBrand(brand: NamedValue): void {
        if (!brand) {
            return
        }
        const newSelection = this.selectedBrands.filter(sel => sel.value !== brand.value)
        if (newSelection.length !== this.selectedBrands.length) {
            this.selectedBrands = newSelection;
        }
    }

    removeRetailer(retailer: NamedValue): void {
        if (!retailer) {
            return
        }
        const newSelection = this.selectedRetailers.filter(sel => sel.value !== retailer.value)
        if (newSelection.length !== this.selectedRetailers.length) {
            this.selectedRetailers = newSelection;
        }
    }

    ngOnDestroy(): void {
        Utils.unsubscribe(this.brandSearchSubscription);
    }

    save(): void {
        if (!this.form.valid) {
            return;
        }

        const data: UserMutationData = {
            roleIds: this.selectedUserRoles.map(t => t.id)
        };

        data.name = this.form.get('display_name').value;


        if (this.currentUser.isNewRecord() ||
            this.currentUser.isSignUpRequested() ||
            this.currentUser.isPendingPasswordSetup()) {

            console.log("here")

            data.email = this.form.get('email').value;
            data.isAdministrator = this.form.get('is_administrator').value;

            console.log(this.form.get('send_sales_invite_email').value)

            switch (true) {
                case this.form.get('send_sales_invite_email').value: {
                    data.invitationEmail = 'sales';
                    break;
                }
                case this.form.get('send_content_invite_email').value: {
                    data.invitationEmail = 'content'
                    break;
                }
                default:
                    data.invitationEmail = 'none'
            }
        }


        if (this.canAssociateRetailers()) {
            data.brandPartnerIds = [];
            data.retailerIds = [];
            data.childBrandIds = [];

            const brands = this.getSelectedBrands()
            if (brands && brands.length) {
                for (const v of brands) {
                    // root brands
                    if (v.level == 0) {
                        data.brandPartnerIds.push(v.id);
                    } else {
                        data.childBrandIds.push(v.id);
                    }
                }
            }

            if (this.selectedRetailers && this.selectedRetailers.length) {
                for (const v of this.selectedRetailers) {
                    data.retailerIds.push(v.value);
                }
            }
        }

        if (this.canAssociatePrograms()) {
            data.programIds = [];
            data.retailerIds = [];

            if (this.selectedPrograms && this.selectedPrograms.length) {
                for (const v of this.selectedPrograms) {
                    data.programIds.push(v.value);
                }
            }

            if (this.selectedRetailers && this.selectedRetailers.length) {
                for (const v of this.selectedRetailers) {
                    data.retailerIds.push(v.value);
                }
            }
        }

        const isTechnician = this.hasSelectedRole(UserRole.DeviceTechnician)

        if (!isTechnician) {
            this.selectedStoreGroups = []
        }
        data.storeGroupIds = this.selectedStoreGroups.map(value => value.value)

        let obs: Observable<MutationResponse<User>>;
        if (this.currentUser.isNewRecord()) {
            obs = this.svcApi.createUser(data);
        } else {
            obs = this.svcApi.updateUser(this.currentUser.id, data);
        }

        data.metadata = {
            ...(this.currentUser.metadata || {})
        }
        data.metadata.freshdeskUserId = null
        if (isTechnician) {
            data.metadata.freshdeskUserId = this.form.get('freshdeskUserId').value
        }

        this.isSavingData = true;
        obs.subscribe(value => {
            if (value.success) {
                this.dialogRef.close(value.data)
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Unexpected error');
            }
            this.isSavingData = false;
        }, error => {
            this.svcLayout.showSnackMessage('Api error');
            this.isSavingData = false;
        })
    }

    close(): void {
        this.dialogRef.close()
    }

    ngOnInit(): void {
        this.svcApi.getUserRoles().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(result => {
            this.allUserRole = result.data;
        })
    }

    onBrandSelectionChanged(brands: NamedValue[]): void {
        this.loadChildBrandsSelection(brands, false)
        this.selectedBrands = brands
    }

    loadChildBrandsSelection(brands: NamedValue[], filterAvailableBrands: boolean = true, availableChildBrands: NamedValue[] = []) {
        this.TREE_DATA = []
        if (!brands || brands.length == 0) {
            this.dataSource.data = this.TREE_DATA
            return
        }

        const observables = [];

        brands.forEach(b => {
            observables.push(this.getBrand(b.value));
        })

        forkJoin(observables).subscribe(results => {
            results.forEach(result => {
                const brand = result.data[0]
                this.TREE_DATA.push({
                    id: brand.id,
                    name: brand.name,
                    isChecked: true,
                    children: brand.childBrands.map(r => {
                        const isChecked = filterAvailableBrands ? availableChildBrands.find(s => s.value === r.id) : true
                        return {
                            id: r.id,
                            name: r.name,
                            isChecked: isChecked
                        }
                    })
                })
            });

            this.dataSource.data = this.TREE_DATA
        });
    }

    onRetailerSelectionChanged(retailers: NamedValue | NamedValue[]): void {
        if (Array.isArray(retailers)) {
            this.selectedRetailers = retailers
        } else {
            this.selectedRetailers = [retailers]
            if (this.canAssociatePrograms()) {
                this.programSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, retailers.value)
            }
        }
    }

    onStoreGroupSelectionChanged(storeGroups: NamedValue[]) {
        this.selectedStoreGroups = storeGroups
    }

    canAssociateBrands() {
        if (this.currentUser.isAdmin()) {
            return false;
        }
        const permissions = this.selectedUserRoles.reduce((acc, role) => acc.concat(role.permissions.map(permission => permission.key)), []);
        return permissions.includes(UserAccessPermission.LoopDashboard.CanAssociateWithBrand.key) ||
            permissions.includes(UserAccessPermission.AppAccess.HtSubmissionPortal.key);
    }


    canAssociateRetailers() {
        if (this.canAssociateBrands()) {
            return true;
        }

        if (this.canAssociatePrograms()) {
            return true;
        }
    }

    onUserRoleSelected(ev: MatSelectionListChange): void {
        for (const opt of ev.options) {
            const role: UserRole = opt.value;
            if (opt.selected) {
                this.selectedUserRoles.push(role)
            } else {
                const index = this.selectedUserRoles.map(t => t.id).indexOf(role.id, 0);
                if (index > -1) {
                    this.selectedUserRoles.splice(index, 1);
                }
            }
        }
    }

    isSelected(role: UserRole): boolean {
        if (this.currentUser.isNewRecord()) {
            return false;
        }
        return this.currentUser.roles.map(t => t.id).includes(role.id);
    }

    checkBoxToggle() {
        switch (true) {
            case this.form.get('send_sales_invite_email').value: {
                this.form.controls.send_content_invite_email.disable();
                break;
            }
            case this.form.get('send_content_invite_email').value: {
                this.form.controls.send_sales_invite_email.disable();
                break;
            }
            default:
                this.form.controls.send_sales_invite_email.enable();
                this.form.controls.send_content_invite_email.enable();
        }
    }

    hasSelectedRole(role: UserRole) {
        return !!this.selectedUserRoles?.find(value => value.id == role?.id)
    }

    canAssociatePrograms() {
        return !!this.selectedUserRoles?.find(value => value.permissions?.find(p => p.key == UserAccessPermission.AppAccess.MerchantDashboard.key))
    }

    onProgramSelectionChanged(values: NamedValue[]) {
        this.selectedPrograms = values
    }

    getBrand(brandId: string) {
        return this.svcApi.rawQuery({
            query: FETCH_BRANDS_QUERY,
            fetchPolicy: "no-cache",
            variables: {
                criteria: {
                    id: brandId
                }
            }
        }).pipe(
            map(value => {
                const rawData = value.data['brands'] as any;
                return CursorFeed.create(rawData, BrandPartner, 'brand_partners')
            })
        );
    }

    getSelectedBrands(): FlatBrandNode[] {
        return this.treeControl?.dataNodes?.filter(node => node.isChecked);
    }
}

export interface UserEditDialogData {
    user: User
}

const FETCH_BRANDS_QUERY = gql`
    query fetchBrands($criteria:BrandPartnerSearchCriteria!){
        brands(criteria: $criteria) {
            brand_partners {
                id
                name
                childBrands {
                    id
                    name
                }
            }
        }
    }`
