<div style="display: flex; flex-direction: row; height: 100%; ">
    <div style="flex: 1; display: flex; position: relative;">
        <div
            style="display: flex; overflow-y: hidden; position: absolute; top:0; left:0; right:10px; bottom:0; padding:8px 0 ; gap:10px;">
            <div class="mat-elevation-z3 slot_list" *ngFor="let ds of controller.itemDataSources" fxFlex
                 style="height:100%;">
                <app-device-slot-assignment-list [dataSource]="ds"></app-device-slot-assignment-list>
            </div>
        </div>

    </div>
    <div style="z-index: 1; min-width: 500px !important; max-width: 25%; " class="mat-elevation-z3">
        <app-device-slot-assignment-list
            [dataSource]="controller.defaultItemDataSource"></app-device-slot-assignment-list>
    </div>

</div>



