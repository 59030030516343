import {BaseModel} from "@looma/shared/models/base_model";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {PromoPlaylistScheduleEntry} from "@looma/shared/models/promo_playlist_schedule_entry";

export class PromoPeriodDeviceSlotSubgroup extends BaseModel {
    id: string;
    name: string;
    isDefault: boolean;
    deviceSlots: DeviceSlot[];
    promoPlaylistScheduledEntries: PromoPlaylistScheduleEntry[]

    assign(input: any): this {
        super.assign(input);
        this.assignTypedArray(input, 'deviceSlots', DeviceSlot)
        this.assignTypedArray(input, 'promoPlaylistScheduledEntries', PromoPlaylistScheduleEntry)
        return this;
    }
}

export interface PromoPeriodDeviceSlotSubgroupMutationInput{
    op: MutationOperation
    id: string | number
    name: string
    deviceSlotIds: string[]
}
