<div app-looma-page pageTitle="Schedule calendar" pageIcon="event_note"
     style="display: block; height: 100%; width: 100%" [headerSideTpl]="headerSideTpl">

    <div *ngIf="promoPeriods"
         style="overflow-y: scroll; overflow-x: scroll; width: 100%; margin: 20px; background-color: white; border-radius: 10px">
        <table style="border-collapse: collapse; width: 100%">
            <thead>
            <th style="text-align: left">Promo period</th>
            <th style="text-align: left">Start date</th>
            <th style="text-align: left">End date</th>
            <th style="text-align: left">Segment</th>
            </thead>
            <tbody>
            <tr *ngFor="let item of promoPeriods" style="border: 1pt solid rgba(0, 0, 0, 0.12);">
                <td style="vertical-align: top;">
                    <div style="display: flex; flex-direction: column; gap: 7px">
                        <div
                            style="width: 100px; font-size: 14px; font-weight: bold">{{ item.retailerPromoPeriod.name }}
                        </div>

                        <div style="display: flex; flex-direction: row;">
                            <div style="cursor: pointer">
                                <mat-icon (click)="uploadMerchGuide(item)">upload</mat-icon>
                            </div>
                            <div *ngIf="item.retailerPromoPeriod.merchGuideUrl">
                                <a [href]="item.retailerPromoPeriod.merchGuideUrl" target="_blank">Merch guide</a>
                            </div>
                            <div *ngIf="!item.retailerPromoPeriod.merchGuideUrl">
                                No merch guide
                            </div>
                        </div>
                    </div>
                </td>
                <td style="vertical-align: top;">
                    <div
                        style="width: 100px; font-size: 14px">{{ item.retailerPromoPeriod.startDate | date:'MMM d, yyyy' }}
                    </div>
                </td>
                <td style="vertical-align: top;">
                    <div
                        style="width: 100px; font-size: 14px">{{ item.retailerPromoPeriod.endDate | date:'MMM d, yyyy' }}
                    </div>
                </td>
                <td>
                    <div *ngFor="let segment of item.segments"
                         style="display: flex; padding-bottom: 10px; margin-top: 10px; border-bottom: 1px solid rgba(0, 0, 0, 0.3)">
                        <div style="font-size: 14px; width: 200px; font-weight: 500; letter-spacing: 1px">
                            {{ segment.deviceSlotSegmentName }}
                        </div>

                        <div style="display: flex;">
                            <div
                                style="width: 200px; cursor:pointer; border-left: 1px solid rgba(0, 0, 0, 0.3); padding: 10px; vertical-align: top; min-height: 150px; display: flex; gap: 10px; flex-direction: column; justify-content: space-between"
                                *ngFor="let d of ' '.repeat(maxBrandCount).split(''), let x = index"
                                [style.backgroundColor]="
                                    (getCampaign(segment.campaigns, x)?.status === 'selected') ? '#bae3bd' :
                                    (getCampaign(segment.campaigns, x)?.status === 'tentative') ? '#ede8c4' :
                                    (getCampaign(segment.campaigns, x)?.status === 'declined') ? '#e2baba' :
                                ''">

                                <div (click)="onCampaignClicked(getCampaign(segment.campaigns, x))"
                                     *ngIf="getCampaign(segment.campaigns, x)?.isSubmission">
                                    <div
                                        style="font-weight: bold">{{ getCampaignBrandPartner(segment.campaigns, x) }}
                                    </div>
                                    <div>{{ getCampaign(segment.campaigns, x)?.name }}</div>
                                </div>

                                <div *ngIf="getCampaign(segment.campaigns, x)?.brandCampaign"
                                     style="padding: 10px;background-color: navy; color: white; border-radius: 5px"
                                     (click)="openBrandCampaign(getCampaign(segment.campaigns, x)?.brandCampaign)">
                                    {{ getCampaign(segment.campaigns, x)?.brandCampaign.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>


<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <app-autocomplete-search-field showClear="true" placeholder="Retailer"
                                       searchObjectType="Retailer"
                                       (valueChanged)="onRetailerSelected($event)" selectionMode="single"
                                       style="width: 300px; padding-right: 20px"></app-autocomplete-search-field>

        <app-autocomplete-search-field [disabled]="!retailer" showClear="true"
                                       placeholder="Program"
                                       searchObjectType="PromoProgram" #promoProgramSearchField
                                       [searchCriteria]="promoProgramSearchCriteria"
                                       (valueChanged)="onPromoProgramSelected($event)"
                                       selectionMode="single"
                                       style="width: 300px; padding-right: 20px"></app-autocomplete-search-field>

        <mat-form-field style="width: 200px; " *ngIf="years?.length > 0">
            <mat-label>Year</mat-label>
            <mat-select (selectionChange)="showCalendar($event)" [disabled]="!program" #yearSelection>
                <mat-option *ngFor="let opt of years" value="{{opt}}">{{ opt }}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-raised-button (click)="downloadCampaignSubmissionsCSV()"
                [disabled]="!isExportEnabled()">
            <mat-icon>download</mat-icon>
            Export
        </button>
    </div>
</ng-template>