import {Component, OnInit, ViewChild} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {NamedValue} from "@looma/shared/types/named_value";
import {SearchableField, SearchFieldCriteria} from "@looma/shared/search";
import {
    AutocompleteSearchFieldComponent
} from "../../../../../../looma-shared/components/autocomplete-search-field/autocomplete-search-field.component";
import {ApiDataService} from "../../../services/api-data.service";
import {EMPTY, Subscription} from "rxjs";
import {Utils} from "@looma/shared/utils";
import gql from "graphql-tag";
import {MUTATION_RESPONSE_FIELDS} from "../../../services/queries";
import {switchMap, takeUntil} from "rxjs/operators";
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../services/toast-notification.service";
import {Retailer} from "@looma/shared/models/retailer";
import { HttpClient } from '@angular/common/http';

@LifecycleHooks()
@Component({
    selector: 'app-generic-reports',
    templateUrl: './generic-reports.component.html',
    styleUrls: ['./generic-reports.component.scss']
})
export class GenericReportsComponent implements OnInit {

    promoPeriodSearchCriteria: SearchFieldCriteria[]
    retailers: string[]

    selectedPromoPeriod: NamedValue
    selectedRetailer: NamedValue
    selectedRetailerStore: string
    selectedRetailerId: number
    startDate: Date;
    endDate: Date;
    private genReportSubscription: Subscription
    private activeNotif: ToastNotification

    @ViewChild("promoPeriodSearchField") promoPeriodSearchField: AutocompleteSearchFieldComponent

    constructor(private svcApi: ApiDataService, private svcNotif: ToastNotificationService, private http: HttpClient) {
        this.retailers = [
            "Harris Teeter",
            "HEB",
            "Schnucks",
            "Lowes Foods",
            "Kroger"
        ]
    }

    ngOnInit(): void {
    }

    onRetailerSelected(ev: NamedValue) {
        this.selectedRetailer = ev
        this.promoPeriodSearchField.clearInput();
        if (ev) {
            this.promoPeriodSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, ev.value).asArray();
        } else {
            this.promoPeriodSearchCriteria = null;
        }
        this.selectedPromoPeriod = null;
        console.warn('onRetailerSelected', ev)
    }

    onPromoPeriodSelected(ev: NamedValue) {
        this.selectedPromoPeriod = ev
    }

    onRetailerStoreSelected(ev: string) {
        this.selectedRetailerStore = ev
        switch (this.selectedRetailerStore) {
            case "Harris Teeter": {
                this.selectedRetailerId = 1
                break
            }
            case "HEB": {
                this.selectedRetailerId = 4
                break
            }
            case "Lowes Foods": {
                this.selectedRetailerId = 2
                break
            }
            case "Schnucks": {
                this.selectedRetailerId = 7
                break
            }
            case "Kroger": {
                this.selectedRetailerId = 8
                break
            }
        }
    }

    triggerGenerateReport() {

        Utils.unsubscribe(this.genReportSubscription)

        if (this.activeNotif) {
            this.activeNotif.dismiss()
        }

        const notif = this.activeNotif = this.svcNotif.create({
            title: `Generating ${this.selectedRetailer.name} report`,
            description: this.selectedPromoPeriod.name,
            dismissable: false,
            style: ToastNotificationStyle.Loading
        })


        this.genReportSubscription = this.svcApi.rawObjectMutate(gql`
            mutation scheduleGenerateExecutionReport(
                $reportInput: ReportInput!
            ) {
                generateExecutionReport(
                    reportInput: $reportInput,
                ) {
                    ${MUTATION_RESPONSE_FIELDS}
                }
            }
        `, {
            reportInput: {
                reportType: "Execution",
                retailerId: this.selectedRetailer?.value,
                promoPeriodId: this.selectedPromoPeriod?.value,
                brandPromoCampaignId: "",
                startDate: "",
                endDate: ""
            }
        }, Retailer).pipe(
            takeUntil(Utils.onDestroy(this)),
            switchMap(value => {
                if (value.success) {
                    return notif.watchJob(value.triggeredJobId)
                } else {
                    notif.update({
                        style: ToastNotificationStyle.Error,
                        description: value.message || 'Unexpected error'
                    })

                    return EMPTY
                }

            })
        ).subscribe(job => {
            const downloadUrl = job.getExtra('download_url')

            if (job.isSuccess) {
                notif.update({
                    title: `Report ${this.selectedRetailer?.name} ${this.selectedPromoPeriod?.name} generated`,
                    actions: [{
                        id: 'download',
                        text: 'Download',
                        handler: () => {
                            const retailer = `${this.selectedRetailer?.name}-${this.selectedPromoPeriod?.name}-Execution-Report`
                            const filename = retailer.replace(/\s+/g, '-');
                            this.triggerDownload(downloadUrl, filename)
                        }
                    }]

                })
            }

        })
    }

    triggerGenerateStoreListReport() {
        Utils.unsubscribe(this.genReportSubscription)

        if (this.activeNotif) {
            this.activeNotif.dismiss()
        }

        const notif = this.activeNotif = this.svcNotif.create({
            title: `Generating Store List Report`,
            dismissable: false,
            style: ToastNotificationStyle.Loading
        })


        this.genReportSubscription = this.svcApi.rawObjectMutate(gql`
            mutation scheduleGenerateExecutionReport(
                $reportInput: ReportInput!
            ) {
                generateExecutionReport(
                    reportInput: $reportInput,
                ) {
                    ${MUTATION_RESPONSE_FIELDS}
                }
            }
        `, {
            reportInput: {
                reportType: "genericStoreList",
                retailerId: this?.selectedRetailerId,
                promoPeriodId: "",
                brandPromoCampaignId: "",
                startDate: "",
                endDate: ""
            }
        }, Retailer).pipe(
            takeUntil(Utils.onDestroy(this)),
            switchMap(value => {
                if (value.success) {
                    return notif.watchJob(value.triggeredJobId)
                } else {
                    notif.update({
                        style: ToastNotificationStyle.Error,
                        description: value.message || 'Unexpected error'
                    })

                    return EMPTY
                }

            })
        ).subscribe(job => {
            const downloadUrl = job.getExtra('download_url');

            if (job.isSuccess) {
                notif.update({
                    title: `${this.selectedRetailerStore} Report Generated`,
                    actions: [{
                        id: 'download',
                        text: 'Download',
                        handler: () => {
                            const retailer = `${this.selectedRetailerStore}-Store-List`
                            const filename = retailer.replace(/\s+/g, '-');
                            this.triggerDownload(downloadUrl, filename)
                        }
                    }]

                })
            }

        })
    }
    
    triggerGenerateImagesReport() {
        Utils.unsubscribe(this.genReportSubscription)
        const endDateInclusive = new Date(this.endDate)
        endDateInclusive.setDate(this.endDate.getDate() + 1)
        if (this.activeNotif) {
            this.activeNotif.dismiss()
        }

        const notif = this.activeNotif = this.svcNotif.create({
            title: `Generating Images Report`,
            dismissable: false,
            style: ToastNotificationStyle.Loading
        })


        this.genReportSubscription = this.svcApi.rawObjectMutate(gql`
            mutation scheduleGenerateExecutionReport(
                $reportInput: ReportInput!
            ) {
                generateExecutionReport(
                    reportInput: $reportInput,
                ) {
                    ${MUTATION_RESPONSE_FIELDS}
                }
            }
        `, {
            reportInput: {
                reportType: "imagesReport",
                retailerId: "1",
                promoPeriodId: "1",
                brandPromoCampaignId: 1,
                startDate: this.startDate.toISOString(),
                endDate: endDateInclusive.toISOString()
            }
        }, Retailer).pipe(
            takeUntil(Utils.onDestroy(this)),
            switchMap(value => {
                if (value.success) {
                    return notif.watchJob(value.triggeredJobId)
                } else {
                    notif.update({
                        style: ToastNotificationStyle.Error,
                        description: value.message || 'Unexpected error'
                    })

                    return EMPTY
                }

            })
        ).subscribe(job => {
            const downloadUrl = job.getExtra('download_url');

            if (job.isSuccess) {
                notif.update({
                    title: `Images Report Generated`,
                    actions: [{
                        id: 'download',
                        text: 'Download',
                        handler: () => {
                            this.triggerDownload(downloadUrl, "Images-Report")
                        }
                    }]

                })
            }

        })
    }
    
    triggerDownload(downloadUrl: string, filename: string) {
        this.http.get(downloadUrl, { responseType: 'blob' })
            .subscribe(blob => {
                // Create a temporary link to download the file
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.display = 'none';
                a.href = url;
                a.download = filename; 
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            });
    }
    
    get isBusy(): boolean {
        return !Utils.isUnsubscribed(this.genReportSubscription)
    }
    
}
