import {Component, Inject, OnInit} from '@angular/core';
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {DeviceSlotSegment, DeviceSlotSegmentInput} from "@looma/shared/models/device_slot_segment";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {Observable} from "rxjs";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-segment-edit-dialog',
    templateUrl: './segment-edit-dialog.component.html',
    styleUrls: ['./segment-edit-dialog.component.scss']
})
export class SegmentEditDialogComponent extends ModelEditDialog<DeviceSlotSegment> implements OnInit {

    form: FormGroup;
    segment: DeviceSlotSegment
    promoProgram: RetailerPromoProgram
    
    constructor(
        public dialogRef: MatDialogRef<SegmentEditDialogComponent>,
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: SegmentEditDialogData) {
        super();
        
        this.segment = data.deviceSlotSegment
        this.promoProgram = data.program
        
        this.form = fb.group({
            name: new FormControl({value: this.segment?.name, disabled: false}, [Validators.required]),
            promoPeriod: new FormControl({value: this.segment.promoPeriod.name, disabled: true},),
            promoProgram: new FormControl({value: this.promoProgram.name, disabled: true},),
            defaultBrandSlotsCount: new FormControl({
                value: this.segment?.defaultBrandSlotsCount,
                disabled: false
            }, [Validators.required]),
            deviceSlotTypeFormControl: new FormControl({
                value: this.segment?.deviceSlotType,
                disabled: false
            }, [Validators.required]),
        });
    }


    static open(svcLayout: LayoutService, program: RetailerPromoProgram, segment: DeviceSlotSegment): Observable<DeviceSlotSegment> {
        const data: SegmentEditDialogData = {
            program: program,
            deviceSlotSegment: segment
        };
        return svcLayout.openDialogForResult(SegmentEditDialogComponent, {
            data: data,
            width: '800px'
        })
    }

    ngOnInit(): void {
    }

    onSave(): Observable<MutationResponse<DeviceSlotSegment>> {
        const data: DeviceSlotSegmentInput = {
            id: this.segment.isNewRecord() ? null : this.segment.id,
            name: this.form.get("name").value,
            promoPeriodId: this.segment.promoPeriod.id,
            deviceSlotTypeId: this.form.get("deviceSlotTypeFormControl").value.id,
            defaultBrandSlotsCount: this.form.get("defaultBrandSlotsCount").value,
        }
        const op = this.segment.isNewRecord() ? MutationOperation.Create : MutationOperation.Update
        return this.svcApi.mutateDeviceSlotSegment(op, data)
    }

    isFormValid(): boolean {
        return this.form.valid;
    }

    isSmeObj(a: any, b: any): boolean {
        return (a && b) && a.id === b.id;
    }
}


export interface SegmentEditDialogData {
    deviceSlotSegment: DeviceSlotSegment
    program: RetailerPromoProgram
}
