import {Component, Injectable} from '@angular/core';
import {PreferencesService} from "@looma/shared/services/preferences.service";
import {MediaContentTypeListComponent} from "../media-content-type-list/media-content-type-list.component";
import {ApiDataService} from "../../../services/api-data.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {NamedValue} from "@looma/shared/types/named_value";
import {MediaContentVersionType} from "@looma/shared/models/media_content_version";
import {RemoteJobService} from "@looma/shared/services/remote-job.service";

@Injectable()
export class MediaContentTabControllerService {
    private _activeTab: MediaContentTypeListComponent;

    constructor(private svcPrefs: PreferencesService) {
    }

    setActiveTab(tab: MediaContentTypeListComponent) {
        this._activeTab = tab;
        setTimeout(() => {
            this.svcPrefs.setSessionValue('active_media_content_tab', "a")
        }, 0)
    }

    getActiveTab(): MediaContentTypeListComponent {
        return this._activeTab;
    }
}

@LifecycleHooks()
@Component({
    selector: 'app-media-contents-list',
    templateUrl: './media-contents-list.component.html',
    styleUrls: ['./media-contents-list.component.scss'],
    providers: [MediaContentTabControllerService],
})
export class MediaContentsListComponent {

    contentTypes = [
        {id: "Film", name: "Films"},
        {id: "SplashScreen", name: "Splash Screens"},
        {id: "AncillaryContent", name: "Shopper Ed Slides"},
    ]

    constructor(public svcApi: ApiDataService,
                public svcScheduleTabs: MediaContentTabControllerService) {
    }

    uploadNewFilm(): void {
        this.svcScheduleTabs.getActiveTab().openUploadPicker(null, MediaContentVersionType.loop);
    }

    searchByFilmNameOrFilmMaker(event: Event) {
        const term = (event.target as HTMLInputElement).value;
        this.svcScheduleTabs.getActiveTab().searchByFilmNameOrFilmMaker(term);
    }

    searchByBrandPartner(namedValue: NamedValue) {
        if (namedValue) {
            this.svcScheduleTabs.getActiveTab().searchByBrandPartner(namedValue.value);
        }
    }
}
