import {BaseModel} from '@looma/shared/models/base_model';
import {MediaFileInfo} from '@looma/shared/models/media_file_info'
import {secrets} from '@looma/config/secrets';

export class RetailerCampaignLibraryFileVersion extends BaseModel {
    version_name: string;
    gs_location: string;
    file_info: MediaFileInfo;

    assign(input: any): this {
        super.assign(input);
        this.assignNested(input, 'file_info', new MediaFileInfo());
        return this;
    }

    get downloadUrl(): string {
        return `https://firebasestorage.googleapis.com/v0/b/${secrets.firebase.projectId}/o/${encodeURIComponent(this.gs_location)}?alt=media`
    }
}
