import {BaseModel} from '@looma/shared/models/base_model';

export class RemoteStatusInfo extends BaseModel {
    playback_status: number;
    heartbeat_status: number;
    foreground_app_status: number;
    accelerometer_status: number;
    battery_status: number;
    store_status: number;
    health_status: number;
    installed_apps_status: number;
}
