import {BaseModel} from '@looma/shared/models/base_model';
import {RetailerPromoPeriod} from '@looma/shared/models/retailer_promo_periods'
import {MediaPlaylist} from '@looma/shared/models/media_playlist'
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";


export enum RetailerPromoPeriodPlaylistAssignmentFlag {
    MissingAssignation = (1 << 0),
    DraftNeedsPublish = (1 << 1),
    DraftNeedsRepublish = (1 << 2),
    DraftCanActivate = (1 << 3),

}

export class PromoPlaylistAssignment extends BaseModel {
    id: string
    name: string

    retailerPromoPeriod: RetailerPromoPeriod;
    retailerPromoPeriodId: string;

    deviceSlotSegmentId: string
    deviceSlotSegment: DeviceSlotSegment

    playbackEntries: PromoPlaylistAssignmentSlot[]

    flags: number;

    mediaPlaylist: MediaPlaylist
    isDefault: boolean

    assign(input: any): this {
        input = input || {}
        super.assign(input);

        this.assignTypedObject(input, 'retailerPromoPeriod', RetailerPromoPeriod);
        this.assignTypedObject(input, 'mediaPlaylist', MediaPlaylist);
        this.assignTypedObject(input, 'deviceSlotSegment', DeviceSlotSegment);

        if (!Array.isArray(input.playbackEntries)) {
            this.playbackEntries = [];
        }

        if (this.deviceSlotSegment) {
            this.deviceSlotSegmentId = this.deviceSlotSegment.getStringId();
        }

        if (this.retailerPromoPeriod) {
            this.retailerPromoPeriodId = this.retailerPromoPeriod.getStringId();
        }

        if (isNaN(this.flags)) {
            this.flags = 0
        }

        return this
    }


    hasFlag(flag: RetailerPromoPeriodPlaylistAssignmentFlag): boolean {
        return (this.flags & flag) == flag;
    }
}

export interface PromoPlaylistAssignmentSlot {
    loopIndex: number;
    trackIndex: number;
    brandCampaignId: number;
    brandCampaignSlotId: number;
    mediaFileVariantId: number;
    mediaBundleId: number;
}
