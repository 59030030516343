import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiDataService} from "../../../services/api-data.service";
import {LayoutService} from "../../../services/layout.service";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../services/toast-notification.service";
import {BrandProduct, ProductInput} from "@looma/shared/models/brand_product";
import {NamedValue} from "@looma/shared/types/named_value";
import {map, takeUntil, tap} from "rxjs/operators";
import firebase from 'firebase/compat/app';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {Utils} from '@looma/shared/utils';
import {Observable, of, Subscription} from "rxjs";

enum UpcState {
    Unknown,
    Found,
    NotFound
}

@LifecycleHooks()
@Component({
    selector: 'app-product-edit-dialog',
    templateUrl: './product-edit-dialog.component.html',
    styleUrls: ['./product-edit-dialog.component.scss']
})
export class ProductEditDialogComponent implements OnInit, OnDestroy {

    constructor(private fb: FormBuilder,
                private dialogRef: MatDialogRef<ProductEditDialogComponent>,
                private svcApi: ApiDataService,
                private svcLayout: LayoutService,
                private svcUpload: UploadService,
                private svcToastNotif: ToastNotificationService,
                @Inject(MAT_DIALOG_DATA) data: ProductEditDialogData,
    ) {

        this.uploadSession = svcUpload.newUploadSession({
            fileTypes: ['image'],
            multiSelection: false
        });

        let productData = {};

        this.brandPartnerEditable = getBoolean(data, 'brandEditable', true)
        this.retailerEditable = getBoolean(data, 'retailerEditable', true)

        if (data?.product) {
            productData = data.product || {};
            if (productData instanceof BrandProduct) {
                this.product = productData as BrandProduct;
                this.imageUrl = this.product?.image_url;
                if (this.product.brand_partner) {
                    this.selectedBrandPartner = NamedValue.from(
                        this.product.brand_partner.id,
                        this.product.brand_partner.getDisplayName()
                    );
                }

                this.selectedRetailers = this.product.retailers ? this.product.retailers.map(v => NamedValue.from(
                    v.id,
                    v.getDisplayName()
                )) : []
            }
            if (this.product.upc_code) {
                this.checkedUpcs.set(this.product.upc_code, UpcState.Found)
            }
        } else {
            this.product = new BrandProduct();
        }

        this.form = fb.group({
            name: new FormControl({value: this.product.name, disabled: false}, [Validators.required]),
            upc: new FormControl({value: this.product.upc_code, disabled: false}, [Validators.required]),
        });

        this.uploadSubscription = this.uploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                const storage = firebase.storage();
                this.newImageFirebaseKey = file.firebaseKey;

                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.imageUrl = url;
                    this.dismissNotification();
                });
            }, error => {
                this.svcLayout.showSnackMessage('Update error');
                this.isSavingData = false;
                this.dismissNotification();
            });


        this.fileAddedSubscription = this.uploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                this.uploadToastNotif = this.svcToastNotif.create({
                    title: "Product image upload",
                    description: 'Uploading',
                    dismissable: false,
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                });
                this.isUploadingImage = true;
            });
    }

    get isValidatingUpc() {
        return !Utils.isUnsubscribed(this.validateUpcSub)
    }

    get hasDirtyUpc() {
        return this.form.get('upc').value != this.product.upc_code
    }

    brandPartnerEditable = true
    retailerEditable = true


    form: FormGroup;
    product: BrandProduct;
    isSavingData = false;
    imageUrl: string;
    isUploadingImage: boolean;
    selectedBrandPartner: NamedValue;
    selectedRetailers: NamedValue[];

    private readonly uploadSession: UploadSession;
    private newImageFirebaseKey: string;
    private uploadToastNotif: ToastNotification;
    private uploadSubscription: Subscription;
    private fileAddedSubscription: Subscription;
    private productUpsertSubscription: Subscription;

    private validateUpcSub: Subscription

    static open(svcLayout: LayoutService, data: ProductEditDialogData = {}): Observable<BrandProduct> {
        const dialogConfig: MatDialogConfig = {
            width: '800px',
            data: data || {}
        }
        return svcLayout.openDialogForResult(ProductEditDialogComponent, dialogConfig);
    }

    uploadImage(): void {
        this.uploadSession.openPicker();
    }

    ngOnInit(): void {
    }

    checkedUpcs = new Map<string, UpcState>()

    ngOnDestroy(): void {
        if (this.fileAddedSubscription) {
            this.fileAddedSubscription.unsubscribe();
        }
        if (this.uploadSubscription) {
            this.uploadSubscription.unsubscribe();
        }
        if (this.productUpsertSubscription) {
            this.productUpsertSubscription.unsubscribe();
        }
        if (this.uploadSession) {
            this.uploadSession.destroy()
        }
    }

    save(): void {
        if (!this.form.valid) {
            return;
        }

        const newRecord = this.product.isNewRecord();
        const data: ProductInput = {};
        if (!this.product.isNewRecord()) {
            data.id = this.product.id.toString()
        }
        this.isSavingData = true;

        data.name = this.form.get('name').value;
        data.upcCode = this.form.get('upc').value;
        data.brandPartnerId = this.selectedBrandPartner.getId();
        data.retailerIds = this.selectedRetailers.map(v => {
                return v.intValue()
            }
        );
        data.imageFirebaseKey = this.newImageFirebaseKey;

        const action = newRecord ? MutationOperation.Create : MutationOperation.Update;
        this.productUpsertSubscription = this.svcApi.upsertProduct(action, data).subscribe(value => {
            if (value.success) {
                this.dialogRef.close(value.data)
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Unexpected error');
            }
            this.isSavingData = false;
        }, error => {
            this.svcLayout.showSnackMessage('Api error');
            this.isSavingData = false;
        });
    }

    close(): void {
        this.dialogRef.close()
    }

    private dismissNotification(): void {
        if (this.uploadToastNotif) {
            this.uploadToastNotif.dismiss();
            this.uploadToastNotif = null;
        }
        this.isUploadingImage = false;
    }

    isSame(a: any, b: any): boolean {
        return (a && b) && a === b;
    }

    onBrandPartnerSelected(value: NamedValue): void {
        this.selectedBrandPartner = value;
    }

    onRetailerSelected(values: NamedValue[]) {
        this.selectedRetailers = values
    }

    validateUpc() {
        this.cancelValidateUpc()
        const upcCode = (this.form.get('upc').value as string)?.trim()
        this.validateUpcSub = this.getUpcValidState(upcCode).subscribe(value => {
        })
    }

    private getUpcValidState(upcCode: string): Observable<UpcState> {
        const prev = this.checkedUpcs.get(upcCode)
        if (prev) {
            return of(prev)
        }
        return this.svcApi.fetchUpcDetails(upcCode).pipe(
            takeUntil(Utils.onDestroy(this))
        ).pipe(
            map(value => {
                const state = value?.length ? UpcState.Found : UpcState.NotFound
                this.checkedUpcs.set(upcCode, state)
                return state
            }),
        )
    }

    cancelValidateUpc() {
        Utils.unsubscribe(this.validateUpcSub)
    }

    get isCurrentUpcNotFound() {
        const upcCode = (this.form.get('upc').value as string)?.trim()
        return this.checkedUpcs.get(upcCode) == UpcState.NotFound
    }

    get isBusy() {
        return this.isSavingData || this.isValidatingUpc
    }
}


export interface ProductEditDialogData {
    product?: BrandProduct,
    brandEditable?: boolean
    retailerEditable?: boolean
}


function getBoolean<T>(src: T, prop: keyof T, defaultValue: boolean): boolean {
    if (src && (typeof src[prop]) === 'boolean') {
        return !!src[prop]
    }
    return defaultValue
}
