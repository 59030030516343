<div class="root_elem" style="">
    <div style="display: flex; flex-direction: column;">
        <div style="display: flex; gap:16px">
            <div style="width: 200px">
                <app-autocomplete-search-field showClear="true" placeholder="Retailer"
                                               (valueChanged)="onRetailerFilterChanged($event)" selectionMode="single"
                                               searchObjectType="Retailer"></app-autocomplete-search-field>
            </div>
            <div style="width: 200px">
                <app-autocomplete-search-field showClear="true" placeholder="Program" [disabled]="!selectedRetailerId"
                                               [searchCriteria]="programSearchCriteria"
                                               (valueChanged)="onProgramFilterChanged($event)" selectionMode="single"
                                               searchObjectType="PromoProgram"></app-autocomplete-search-field>
            </div>
            
        </div>
        <looma-grid [dataSource]="brandsDataSource"></looma-grid>
    </div>
    <app-dashboard-charts></app-dashboard-charts>
</div>
