<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
    <div style="width:800px; background: white; display: flex; flex-direction: column">
        <mat-form-field style="display: block">
            <input matInput
                   autocomplete="off"
                   [(ngModel)]="deviceSlotType.name"
                   placeholder="Name"/>

        </mat-form-field>

        <mat-form-field style="display: block">
            <input matInput
                   autocomplete="off"
                   type="number"
                   [(ngModel)]="deviceSlotType.defaultBrandSlotsCount"
                   placeholder="Default Brand Campaign Slots Count"/>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button [disabled]="!canSave()" mat-raised-button color="primary" (click)="handleAction('ok')">Ok</button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>