import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {IDisposable, Terminal} from 'xterm';
import {AsyncSubject, Observable, Subject, Subscriber} from 'rxjs';
import {BoundingRect, Utils} from '@looma/shared/utils';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";

@LifecycleHooks()
@Component({
    selector: 'app-shell-terminal',
    templateUrl: './shell-terminal.component.html',
    styleUrls: ['./shell-terminal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ShellTerminalComponent implements OnInit, OnDestroy, AfterViewInit {

    term: Terminal;

    private activePrompt: EditablePrompt = null;

    private eventListeners: IDisposable[] = [];

    private cancelSubject = new Subject<boolean>();

    @ViewChild('terminal', {static: true}) terminalEl: ElementRef;


    @Output('created') onCreated = new AsyncSubject<ShellTerminalComponent>();

    @Output('destroyed') onDestroyed = new AsyncSubject<ShellTerminalComponent>();

    constructor() {
    }

    ngOnInit(): void {
        const term = this.term = new Terminal({
            cursorBlink: true,
            scrollback: Number.MAX_SAFE_INTEGER,
            theme:{
                background:'#1E2129',
            }
        });

        let disposable = term.onData(e => {
            this.handleTermData(e)
        });
        this.eventListeners.push(disposable);

        disposable = term.onResize(e => {
        });
        this.eventListeners.push(disposable);


        this.term.open(this.terminalEl.nativeElement);

        this.term.write(Ansi.ENABLE_INSERT_MODE);

    }

    read(prompt: string, continuation: string= '> '): Observable<string>{
        return new Observable<string>(subscriber => {
            if(this.activePrompt){
                this.activePrompt.cancel()
            }
            const createdPrompt = this.activePrompt = new EditablePrompt(this, prompt, continuation, subscriber);
            this.setCursorEnabled(true);

            return () => {
                createdPrompt.cancel();
                if(this.activePrompt === createdPrompt){
                    this.activePrompt = null
                }
            }
        })
    }

    onCancel(): Observable<boolean>{
        return this.cancelSubject.asObservable();
    }

    setCursorEnabled(enabled: boolean): void{
        this.term.setOption('cursorStyle', enabled ? 'block' : 'bar');
        this.term.setOption('cursorBlink', enabled);
    }

    ngOnDestroy(): void {
        this.onDestroyed.next(this);
        this.onDestroyed.complete();
        for(const item of this.eventListeners){
            item.dispose()
        }
        this.term.dispose();
    }

    ngAfterViewInit(): void {
        const term = this.term;

        const renderCoordinator = this.readNestedProperties(this.term, '_core','_renderCoordinator');
        const cellWidth = parseInt(this.readNestedProperties(renderCoordinator,'dimensions','actualCellWidth'), 10);
        const cellHeight = parseInt(this.readNestedProperties(renderCoordinator,'dimensions','actualCellHeight'), 10);


        if(!isNaN(cellWidth+cellHeight)){
            Utils.onResize(this.terminalEl).pipe(
                debounceTime(500) as any,
                takeUntil(Utils.onDestroy(this)) as any
            ).subscribe((value: BoundingRect) => {

                const el = this.terminalEl.nativeElement as HTMLElement;
                const styles = getComputedStyle(el);

                const getDimension: (str: string) =>number = (str: string) => {
                    return parseInt(str, 10) || 0
                };

                const cols = Math.floor((value.width-100-getDimension(styles.paddingLeft)-getDimension(styles.paddingRight))/cellWidth);
                const rows = Math.floor((value.height-getDimension(styles.paddingTop)-getDimension(styles.paddingBottom))/cellHeight);

                if(term.cols !== cols || term.rows !== rows){
                    if(typeof renderCoordinator.clear === 'function'){
                        (renderCoordinator as {clear: () => void}).clear()
                    }

                    term.resize(cols, rows)
                }
            })
        }

        this.onCreated.next(this);
        this.onCreated.complete();
    }

    fitBounds(): void{

    }

    private readNestedProperties(src: any, ...args: string[]): any{
        for(const arg of args){
            if(!src){
                break
            }
            src = src[arg]
        }
        return src
    }

    handleTermData(data: string): void {

        // If this looks like a pasted input, expand it
        if (data.length > 3 && data.charCodeAt(0) !== 0x1b) {
            const normData = data.replace(/[\r\n]+/g, '\r');
            Array.from(normData).forEach(c => this.handleData(c));
        } else {
            this.handleData(data);
        }
    }

    private moveCursorX(direction: number, delta: number=1): void{
        let ansi: Ansi = null;
        if(direction > 0){
            ansi = Ansi.RIGHT;
        }else if(direction < 0){
            ansi = Ansi.LEFT;
        }

        if(ansi){
            for(let i=0;i<delta;i++){
                this.term.write(ansi.toString())
            }
        }
    }

    handleData(data): void {
        if(this.activePrompt){
            const prompt = this.activePrompt;
            const ord = data.charCodeAt(0);

            // Handle ANSI escape sequences
            if (ord === 0x1b) {
                switch (data.substr(1)) {
                    case '[A': // Up arrow

                        break;

                    case '[B': // Down arrow

                        break;

                    case '[D': // Left Arrow
                        prompt.move(-1);
                        break;

                    case '[C': // Right Arrow
                        prompt.move(1);
                        break;

                    case '[3~': // Delete
                        prompt.delete(1);
                        break;

                    case '[F': // End
                        prompt.moveToEnd();
                        break;

                    case '[H': // Home
                        prompt.moveToHome();
                        break;

                    case 'b': // ALT + LEFT

                        break;

                    case 'f': // ALT + RIGHT

                        break;

                    case '\x7F': // CTRL + BACKSPACE

                        break;
                }

                // Handle special characters
            } else if (ord < 32 || ord === 0x7f) {
                switch (data) {
                    case '\r': // ENTER
                        if(prompt.onNewLine()){
                            this.activePrompt = null;
                            this.setCursorEnabled(false);
                        }
                        break;

                    case '\x7F': // BACKSPACE
                        prompt.delete(-1);
                        break;

                    case '\t': // TAB
                        break;

                    case Ansi.CTRL_C: // CTRL+C
                        this.term.write('^C\r\n');
                        prompt.cancel();
                        break;
                }

            } else {
                this.activePrompt.appendEscaped(data)
            }
        }else if(data === Ansi.CTRL_C){// CTRL+C
            this.term.write('^C\r\n');
            this.cancelSubject.next(true)
        }

    }

    public writeError(text: string): ShellTerminalComponent{
        this.term.write(`\x1B[0;3;31m${text}\x1B[0m`);
        return this;
    }

    public writePlain(text: string): ShellTerminalComponent{
        return this.write(text);
    }

    writePrompt(text: string): ShellTerminalComponent{
        return this.write(`\x1B[1;1;36m${text}\x1B[0m`);
    }

    private writeColor(color: number, text: string): ShellTerminalComponent{
        if(text.length === 0){
            return
        }
        return this.write(`\x1B[1;1;${color}m${text}\x1B[0m`)
    }

    private write(text: string): ShellTerminalComponent{
        this.term.write(text);
        return this;
    }
}

// https://github.com/xtermjs/xterm.js/blob/08197598142db7dc52bb3b78ffdeef9b3cdee477/src/Terminal.test.ts#L433
enum Ansi {
    NEWLINE ='\n',
    CARRIAGE_RETURN = '\r',
    CTRL_C = '\x03',
    CTRL_D = '\x04',
    CTRL_H = '\x08',
    BACKSPACE = '\x7f',
    CTRL_L = '\x0c',
    CTRL_T = '\x14',
    CTRL_B = '\x02',
    CTRL_F = '\x06',
    CTRL_P = '\x10',
    CTRL_N = '\x0e',
    CTRL_U = '\x15',
    CTRL_K = '\x0b',
    CTRL_A = '\x01',
    CTRL_E = '\x05',
    CTRL_W = '\x17',
    CTRL_Y = '\x19',

    META_B = '\x1bb',
    META_LEFT = '\x1bB',
    META_F = '\x1bf',
    META_RIGHT = '\x1bF',

    LEFT = '\x1b[D',
    RIGHT = '\x1b[C',
    UP = '\x1b[A',
    DOWN = '\x1b[B',

    DELETE = '\x1b[P',

    END = '\x1b[F',
    HOME = '\x1b[H',

    INSERT = '\x1b[2~',

    ENABLE_INSERT_MODE = '\x1b[4h',


}

namespace Ansi{
    export function moveCursorToPosition(pos: number): string {
        if(pos <=0){
            return Ansi.CARRIAGE_RETURN;
        }
        return `\x1b[0G\x1b[${pos-1}C`
    }
}


interface TextLine {
    prefix: string
    content: string
}

class EditablePrompt{
    isDone = false;
    private textLines: TextLine[] = [];
    private cursorPosition: number;
    private term: Terminal;
    constructor(private parent: ShellTerminalComponent, public startPrompt: string, public continuationPrompt: string, private subscriber: Subscriber<string>){
        this.term = parent.term;
        this.addTextLine(startPrompt, '')
    }


    onNewLine(): boolean{ // returns true if it's done

        this.assertNotDone();
        const lineText = this.term.buffer.getLine(this.term.buffer.cursorY).translateToString().trim();
        this.term.write('\n\r');
        if(lineText === '\\' || /[^\\]+\\$/.test(lineText)){
            this.addTextLine(this.continuationPrompt);
            return false
        }

        this.setResult(this.text());

        return true
    }

    cancel(): void{
        this.setResult(null)
    }

    delete(dir: number): boolean{
        this.assertNotDone();
        const currentLine = this.textLines[this.textLines.length-1];
        if(dir > 0){
            // delete key
            if(this.cursorPosition < currentLine.content.length){
                this.term.write(Ansi.DELETE);
                currentLine.content = currentLine.content.substr(0, this.cursorPosition) + currentLine.content.substr(this.cursorPosition+1);
                return true
            }

        }else if(dir < 0){
            // backspace key
            if(this.move(-1)){
                return this.delete(1)
            }
        }
        return false
    }

    moveToHome(): void{

    }

    moveToEnd(): void{
        this.term.write(Ansi.END)
    }

    moveAbs(position: number): void{

    }

    move(dir: number): boolean{
        this.assertNotDone();
        const currentLine = this.textLines[this.textLines.length-1];
        if(dir > 0){
            if(this.cursorPosition < currentLine.content.length){
                this.cursorPosition += 1;
                const buf = this.term.buffer;
                if(buf.cursorX === this.term.cols-1){
                    this.term.write(Ansi.CARRIAGE_RETURN);
                    this.term.write(Ansi.DOWN);
                }else{
                    this.term.write(Ansi.RIGHT);
                }
                return true
            }
        }else if(dir < 0){
            if(this.cursorPosition > 0){
                this.cursorPosition -= 1;
                const buf = this.term.buffer;
                if(buf.cursorX === 0){
                    // move cursor to the end of the line above
                    this.term.write(Ansi.UP);
                    this.term.write(Ansi.moveCursorToPosition(this.term.cols));
                }else{
                    this.term.write(Ansi.LEFT)
                }
                return true
            }

        }
        return false
    }

    text(): string{
        return this.textLines.map(value => value.content.trim()).filter(value => value.length > 0).join('\n')
    }

    appendEscaped(data: string): void{
        const currentLine = this.textLines[this.textLines.length-1];
        if(this.cursorPosition === currentLine.content.length-1){
            currentLine.content += data
        }else{
            currentLine.content = currentLine.content.substr(0, this.cursorPosition)+data+currentLine.content.substr(this.cursorPosition+1)
        }
        this.write(data);
    }


    private setResult(text: string | null): void{
        if(!this.isDone){
            this.isDone = true;
            if(!this.subscriber.closed){
                this.subscriber.next(text);
            }
        }
    }

    private assertNotDone(): void{
        if(this.isDone){
            throw new Error('prompt done')
        }
    }

    private addTextLine(prefix: string, content?: string): void{
        const line: TextLine = {
            prefix,
            content:content || ''
        };
        this.textLines.push(line);
        this.cursorPosition = 0;

        this.parent.writePrompt(line.prefix).writePlain(line.content);

    }

    private write(text: string): EditablePrompt{
        this.cursorPosition += text.length;
        this.parent.writePlain(text);
        return this
    }

}
