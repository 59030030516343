<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
    <app-autocomplete-search-field
        placeholder="Parent Company"
        selectionMode="single"
        [value]="this.brandCampaign.brandPartner"
        [disabled]="!this.brandCampaign.isNewRecord() || this.campaignSubmission"
        searchObjectType="ParentBrand"
        (valueChanged)="onParentBrandSelected($event)">
    </app-autocomplete-search-field>

    <app-autocomplete-search-field
        placeholder="Featured Brands"
        selectionMode="multi"
        [value]="brandCampaign.featuredBrands"
        [disabled]="!childBrandSearchCriteria || this.campaignSubmission"
        [searchCriteria]="childBrandSearchCriteria"
        searchObjectType="BrandPartner"
        (valueChanged)="onChildBrandSelected($event)">
    </app-autocomplete-search-field>

    <mat-form-field style="display: block">
        <input matInput autocomplete="off"
               [(ngModel)]="brandCampaign.name"
               placeholder="Name"/>
    </mat-form-field>

    <app-autocomplete-search-field
        placeholder="Featured Products"
        selectionMode="multi"
        [value]="selectedProducts"
        [disabled]="!productSearchCriteria || !brandCampaign.brandPartner"
        searchObjectType="BrandProduct"
        [searchCriteria]="productSearchCriteria"
        [tplEmptyValue]="tplNoProducts"
        [tplOption]="tplProductOption"
        (valueChanged)="onProductSelectionChanged($event)">
    </app-autocomplete-search-field>

    <div style="display: flex; align-items: center; gap: 20px; align-items: flex-start">
        <mat-form-field style="flex-grow: 1;">
            <mat-label>Campaign cost</mat-label>
            <input matInput autocomplete="off"
                   [(ngModel)]="brandCampaign.campaignCostDollars"
                   placeholder="Campaign Cost"/>
            <mat-icon matSuffix>attach_money</mat-icon>
        </mat-form-field>

        <mat-form-field style="flex-grow: 1;">
            <mat-label>Discount</mat-label>
            <input matInput autocomplete="off"
                   [(ngModel)]="brandCampaign.campaignDiscountDollars"
                   placeholder="Discount"/>
            <mat-icon matSuffix>attach_money</mat-icon>
        </mat-form-field>

        <div
            style="height: 100%; font-size: 15px; 
            font-weight: 600; background-color: green; color: white; border-radius: 5px;
            padding: 5px; box-sizing: border-box;">
            <div>
                Cost
            </div>
            <div>
                {{ ((brandCampaign.campaignCostDollars || 0) - (brandCampaign.campaignDiscountDollars || 0)) | currency: "USD" }}
            </div>
        </div>
    </div>


    <app-autocomplete-search-field
        placeholder="Contacts"
        selectionMode="multi"
        [value]="selectedUsers"
        [disabled]="!brandCampaign.brandPartner"
        searchObjectType="User"
        [searchCriteria]="contactsSearchCriteria"
        (valueChanged)="onUserSelectionChanged($event)">
    </app-autocomplete-search-field>

    <section class="example-section">
        <h4>CPR Email Recipients:</h4>
        <p *ngFor="let p of this.selectedUsers">
            <mat-checkbox (change)="onCprEmailRecipientChanged($event, p)" [checked]="isUserSelectedForCPR(p)">{{ p.display_name }} - {{ p.email }}</mat-checkbox>
        </p>
    </section>

    <ng-template #tplNoProducts>
        <button mat-button color="primary" (click)="addNewProduct()">Add new product</button>
    </ng-template>

    <ng-template #tplProductOption let-productSearchObject="data">
        <div style="display: flex; flex-direction: row; align-items: center">
            <mat-icon *ngIf="productSearchObject.getBooleanExtra('outdated_warning')" color="warn"
                      matTooltip="Product has not been updated in the last 12 months">warning
            </mat-icon>
            <div style="flex:1">
                <div>{{ productSearchObject.getDisplayName() }}</div>
                <div class="option_hint">{{ productSearchObject.hint }}</div>
            </div>
        </div>
    </ng-template>

    <div style="margin-bottom: 32px;">
        <h3>Attachments</h3>
        <div fxLayout="column">
            <!--            <ng-container-->
            <!--                [ngTemplateOutlet]="attachmentRow"-->
            <!--                [ngTemplateOutletContext]="{attachmentType:BrandPromoCampaignAttachmentType.Agreement, label:'Campaign Agreement'}">-->
            <!--            </ng-container>-->
            <ng-container
                [ngTemplateOutlet]="attachmentRow"
                [ngTemplateOutletContext]="{attachmentType:BrandPromoCampaignAttachmentType.MerchGuide, label:'Merchandising Guide'}">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="attachmentRow"
                [ngTemplateOutletContext]="{attachmentType:BrandPromoCampaignAttachmentType.StoreList, label:'Store List'}">
            </ng-container>

        </div>
    </div>

    <div style="display: flex; flex-direction: column; gap: 15px">
        <div class="mat-form-field" appearance="outline">
            <mat-slide-toggle [(ngModel)]="brandCampaign.isVisible">Campaign visible in Loop™ Dashboard?
            </mat-slide-toggle>
        </div>

        <div class="mat-form-field" appearance="outline">
            <mat-slide-toggle [(ngModel)]="brandCampaign.cprEnabled">CPR Enabled?</mat-slide-toggle>
        </div>

        <div class="mat-form-field" appearance="outline">
            <mat-slide-toggle [(ngModel)]="brandCampaign.executionReportingEnabled">Execution Reporting Enabled?
            </mat-slide-toggle>
        </div>

        <div class="mat-form-field" appearance="outline">
            <mat-slide-toggle [(ngModel)]="brandCampaign.isPilot">Pilot Campaign?
            </mat-slide-toggle>
        </div>

    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="isBusy || !canSave()" (click)="handleAction('ok')">Ok</button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>

<ng-template #attachmentRow let-attachmentType="attachmentType" let-label="label">
    <div class="attachment_row">
        <div fxFlex="100%">
            <span style="margin-right: 16px;">{{ label }}</span>
            <a *ngIf="getAttachment(attachmentType)" [href]="getAttachment(attachmentType).downloadUrl"
               target="_blank">{{ getAttachment(attachmentType).fileName }}</a>
        </div>
        <button mat-icon-button [disabled]="isBusy || hasUploadsInProgress" (click)="openFilePicker(attachmentType)">
            <mat-icon>file_upload</mat-icon>
        </button>
        <button mat-icon-button color="warn" *ngIf="getAttachment(attachmentType)"
                (click)="deleteAttachment(attachmentType)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</ng-template>