<h2 mat-dialog-title>{{this.deviceSlot.isNewRecord() ? "Create Device Slot" : "Edit " + this.deviceSlot.name}}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="General">
            <app-autocomplete-search-field placeholder="Retailer"
                                           [showClear]="deviceSlot.isNewRecord()"
                                           [searchObjectType]="'Retailer'"
                                           [value]="selectedRetailer"
                                           [disabled]="!deviceSlot.isNewRecord()"
                                           (valueChanged)="onRetailerSelected($event)">

            </app-autocomplete-search-field>

            <mat-form-field *ngIf="!this.deviceSlot.isNewRecord()">
                <mat-label>name</mat-label>
                <input matInput formControlName="name" autocomplete="off"/>
            </mat-form-field>

            <app-autocomplete-search-field 
                                           placeholder="Active Application"
                                           [readonly]="!deviceSlot.isNewRecord()"
                                           [showClear]="deviceSlot.isNewRecord()"
                                           [disabled]="!deviceSlot.isNewRecord() || isLoadingRetailerDate"
                                           [value]="selectedKioskApp"
                                           [searchValues]="KioskApps"
                                           (valueChanged)="onKioskAppSelected($event)">
            </app-autocomplete-search-field>

            <mat-form-field>
                <mat-label>Product Category</mat-label>
                <mat-select [disabled]="!selectedRetailer" formControlName="productCategory"
                            [value]="deviceSlot?.product_category" [compareWith]="isSameObj">
                    <mat-option *ngFor="let pc of availableCategories" [value]="pc">{{pc.getDisplayName()}}</mat-option>
                </mat-select>
            </mat-form-field>

            <app-autocomplete-search-field
                showClear="true"
                [disabled]="!selectedRetailer"
                placeholder="Store"
                [searchObjectType]="'Store'"
                [searchCriteria]="storeSearchCriteria"
                [value]="selectedStore"
                (valueChanged)="onStoreSelected($event)">
            </app-autocomplete-search-field>

            <app-autocomplete-search-field *ngIf="needsSlotTypeAssignment"
                placeholder="Program"
                showClear="true"
                [disabled]="!selectedRetailer || isLoadingRetailerDate"
                [value]="selectedProgram"
                [searchValues]="availableRetailerPrograms"
                (valueChanged)="onProgramSelected($event)">
            </app-autocomplete-search-field>

            <app-autocomplete-search-field *ngIf="needsSlotTypeAssignment"
                placeholder="Device Slot Type"
                showClear="true"
                [disabled]="!selectedProgram || isLoadingRetailerDate"
                [value]="selectedDeviceSlotType"
                [searchValues]="selectedProgram?.deviceSlotTypes"
                (valueChanged)="onDeviceSlotTypeSelected($event)">
            </app-autocomplete-search-field>

            

            <mat-form-field>
                <mat-label>Looma Phase</mat-label>
                <mat-select formControlName="loomaPhase" [value]="deviceSlot.looma_phase" [compareWith]="isSameObj">
                    <mat-option *ngFor="let phase of loomaPhasesOpts" [value]="phase">{{phase}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-tab>


        <mat-tab label="Properties">
            <mat-form-field>
                <mat-label>Store Location</mat-label>
                <mat-select formControlName="storeLocation" [value]="deviceSlot.store_location"
                            [compareWith]="isSameObj">
                    <mat-option *ngFor="let storeLocation of storeLocationsOpts"
                                [value]="storeLocation">{{storeLocation}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Loop Placement</mat-label>
                <mat-select formControlName="loopPlacement" [value]="deviceSlot.loop_placement"
                            [compareWith]="isSameObj">
                    <mat-option *ngFor="let loopPlacement of loopPlacementsOpts"
                                [value]="loopPlacement">{{loopPlacement}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Temperature</mat-label>
                <mat-select formControlName="temperature" [value]="deviceSlot.temperature" [compareWith]="isSameObj">
                    <mat-option *ngFor="let temperature of temperatureOpts"
                                [value]="temperature">{{temperature}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Mount</mat-label>
                <mat-select formControlName="mount" [value]="deviceSlot.mount" [compareWith]="isSameObj">
                    <mat-option *ngFor="let mount of mountOpts" [value]="mount">{{mount}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Loop X Score</mat-label>
                <mat-select formControlName="loopXScore" [value]="deviceSlot.loop_x_score" [compareWith]="isSameObj">
                    <mat-option *ngFor="let x of loopXOpts" [value]="x">{{x}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-tab>

        <mat-tab label="Decor/HX">
            <mat-form-field>
                <mat-label>Header</mat-label>
                <mat-select formControlName="decorHeader" [compareWith]="isSameObj">
                    <mat-option *ngFor="let header of decorHeaderOpts" [value]="header">{{header}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Blades</mat-label>
                <mat-select formControlName="decorBlades" [compareWith]="isSameObj">
                    <mat-option *ngFor="let i of yesNoOpts" [value]="i">{{i}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Shelf Ribbons</mat-label>
                <mat-select formControlName="decorShelfRibbons" [compareWith]="isSameObj">
                    <mat-option *ngFor="let i of yesNoOpts" [value]="i">{{i}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Accent Card</mat-label>
                <mat-select formControlName="decorAccentCard" [compareWith]="isSameObj">
                    <mat-option *ngFor="let i of yesNoOpts" [value]="i">{{i}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Cooler Decal</mat-label>
                <mat-select formControlName="decorCoolerDecal" [compareWith]="isSameObj">
                    <mat-option *ngFor="let i of coolerDecalOpts" [value]="i">{{i}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Side Lettering</mat-label>
                <mat-select formControlName="decorSideLettering" [compareWith]="isSameObj">
                    <mat-option *ngFor="let i of sideLetteringOpts" [value]="i">{{i}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-tab>
    </mat-tab-group>


</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleAction('ok')" [disabled]="isSavingData || !isFormValid">
        Save
    </button>
    <button mat-button (click)="handleAction('cancel')" [disabled]="isSavingData">cancel</button>
</mat-dialog-actions>