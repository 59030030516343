import {BaseModel} from '@looma/shared/models/base_model';
import {Type} from '@angular/core';
import {Utils} from '@looma/shared/utils';

export class CursorFeed<T extends BaseModel>{
    cursor: string;
    data: T[];

    static forElements<T extends BaseModel>(data: T[] | T| null){
        if(!data){
            data = []
        }else if(!Array.isArray(data)){
            data = [data]
        }
        const feed = new CursorFeed<T>();
        feed.data = data;
        return feed;
    }

    static create<T extends BaseModel>(cursorData: any, typeOfT: Type<T>, dataKey='data'): CursorFeed<T>{
        const feed = new CursorFeed<T>();
        feed.data = [];
        if(cursorData){
            if(cursorData.hasOwnProperty('cursor')){
                feed.cursor = cursorData.cursor
            }
            feed.data = Utils.getNestedTypedArray(cursorData, typeOfT, dataKey)
        }
        if(!Array.isArray(feed.data)){
            feed.data = [];
        }
        return feed
    }

    firstItem(): T{
        if(Array.isArray(this.data) && this.data.length){
            return this.data[0]
        }
        return null
    }


}
