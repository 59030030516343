import {Component, OnInit} from '@angular/core';
import {
    DeleteItemComponent,
    EditItemComponent,
    ModelListPageComponent
} from "../../../../shared/model_list_page_component";
import {CursorFilter} from "@looma/shared/types/cursor_filter";
import {UserRole} from "@looma/shared/models/user_role";
import {LayoutService} from "../../../../services/layout.service";
import {ApiDataService} from "../../../../services/api-data.service";
import {ModelDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {Observable} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {ButtonActions} from "../../../../shared/button_actions";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {MatDialogConfig} from "@angular/material/dialog";
import {UserRoleEditComponent} from "../user-role-edit/user-role-edit.component";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {MutationOperation} from "@looma/shared/models/mutation_operation";

@LifecycleHooks()
@Component({
    selector: 'app-user-role-list',
    templateUrl: './user-role-list.component.html',
    styleUrls: ['./user-role-list.component.scss']
})
export class UserRoleListComponent extends ModelListPageComponent<UserRole, CursorFilter>
    implements EditItemComponent<UserRole>, DeleteItemComponent<UserRole>, OnInit {

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService
    ) {
        super(UserRole);
    }

    get dataSource(): UserRoleDataSource {
        return super.dataSource as UserRoleDataSource;
    }

    initDataSource(): ModelDataSource<UserRole, CursorFilter> {
        return new UserRoleDataSource(this.svcApi);
    }

    canEditItem(item: UserRole): boolean {
        return true;
    }

    performEditItem(item: UserRole): Observable<UserRole> {
        const dialogConfig: MatDialogConfig = {
            width: '800px'
        }
        if (item) {
            dialogConfig.data = {
                userRole: item
            };
        }
        return this.svcLayout.openDialogForResult(UserRoleEditComponent, dialogConfig);
    }

    ngOnInit(): void {
    }

    addNewRole(): void {
        this.performMenuAction(ButtonActions.Edit, new UserRole())
    }

    canDeleteItem(item: UserRole): boolean {
        return true;
    }

    performDeleteItem(item: UserRole): Observable<MutationResponse<UserRole>> {
        return this.svcApi.upsertUserRole(MutationOperation.Delete, {id: item.id});
    }
}

class UserRoleDataSource extends ModelDataSource<UserRole, UserRoleFilter> {
    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Role name',
                    valueReader: (item: UserRole) => {
                        return item.name;
                    }
                },
                {
                    key: 'description',
                    label: 'Description',
                    valueReader: (item: UserRole) => {
                        return item.description;
                    }
                }
            ]
        });
    }

    loadData(dataFilter: UserRoleFilter): Observable<CursorFeed<UserRole>> {
        return this.svcApi.getUserRoles(dataFilter);
    }

}

export interface UserRoleFilter extends CursorFilter {
    cursor: string;
    id: string;
}

