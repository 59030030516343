import {Component, Inject, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LayoutService} from "../../../../services/layout.service";
import {ApiDataService} from "../../../../services/api-data.service";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {Observable} from "rxjs";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {RetailerPromoSchedule} from "@looma/shared/models/retailer_promo_schedule";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";


@LifecycleHooks()
@Component({
    selector: 'app-segment-copy-dialog',
    templateUrl: './segment-copy-dialog.component.html',
    styleUrls: ['./segment-copy-dialog.component.scss']
})
export class SegmentCopyDialogComponent implements OnInit {

    schedule: RetailerPromoSchedule
    program: RetailerPromoProgram
    period: RetailerPromoPeriod
    selectedSegments: string[] = []

    static open(svcLayout: LayoutService, promoPeriod: RetailerPromoPeriod,
                program: RetailerPromoProgram, promoSchedule: RetailerPromoSchedule): Observable<RetailerPromoPeriod> {
        const data: SegmentCopyData = {
            promoPeriod: promoPeriod,
            program: program,
            promoSchedule: promoSchedule,
        };
        return svcLayout.openDialogForResult(SegmentCopyDialogComponent, {
            data: data,
            width: '800px'
        })
    }

    constructor(
        public dialogRef: MatDialogRef<SegmentCopyDialogComponent>,
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        @Inject(MAT_DIALOG_DATA) private data: SegmentCopyData
    ) {
        this.schedule = data.promoSchedule
        this.program = data.program
        this.period = data.promoPeriod
        this.dataSource.data = this.schedule.promoPeriods
            .filter(p => p.deviceSlotSegments?.length > 0)
            .map(p => new TreeNode(p.id, p.name, p.deviceSlotSegments.filter(
                x => x.deviceSlotType.retailerPromoProgram?.id == this.program.id).map(s =>
                    new TreeNode(s.id, s.name, [])
                ))
            )
    }

    ngOnInit(): void {
    }

    private transformer = (node: TreeNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            node: node,
        };
    }

    onSave() {
        this.svcApi.copyDeviceSlotSegments(this.selectedSegments, this.period.id).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.svcLayout.showMessage(`${this.selectedSegments.length} device slots segments copied to promo period '${this.period.name}'`)
                this.dialogRef.close(value.data)
            } else {
                this.svcLayout.showMutationResultMessage(value)
            }
        })
    }

    onCancel() {
        this.dialogRef.close()
    }

    treeControl = new FlatTreeControl<FlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this.transformer,
        node => node.level,
        node => node.expandable,
        node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: FlatNode) => node.expandable;

    onSelectionChanged(treeNode: FlatNode, event: MatCheckboxChange) {
        if (event.checked == true) {
            this.selectedSegments.push(treeNode.node.id)
        } else {
            const index = this.selectedSegments.indexOf(treeNode.node.id, 0);
            if (index > -1) {
                this.selectedSegments.splice(index, 1);
            }
        }
    }

    canCopy(): boolean {
        return this.selectedSegments.length > 0;
    }
}

export interface SegmentCopyData {
    promoPeriod: RetailerPromoPeriod
    program: RetailerPromoProgram
    promoSchedule: RetailerPromoSchedule
}

export class TreeNode {
    id: string;
    name: string;
    children?: TreeNode[];

    constructor(id: string, name: string, children: TreeNode[]) {
        this.name = name;
        this.children = children;
        this.id = id;
    }
}

interface FlatNode {
    node: TreeNode
    expandable: boolean;
    name: string;
    level: number;
}