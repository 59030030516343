import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BulkImportComponent} from './bulk-import/bulk-import.component';
import {LayoutModule} from "../../layout/layout.module";
import {MatButtonModule} from "@angular/material/button";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FlexModule} from "@angular/flex-layout";
import {UploadComponent} from './upload/upload.component';
import {DeviceSelectionDialogComponent} from './device-selection-dialog/device-selection-dialog.component';
import {MediaContentTypeIconComponent} from './media-content-type-icon/media-content-type-icon.component';

@NgModule({
    declarations: [BulkImportComponent, UploadComponent, DeviceSelectionDialogComponent, MediaContentTypeIconComponent],
    imports: [
        CommonModule,
        LayoutModule,
        MatButtonModule,
        FlexModule,
        MatProgressBarModule,
        MatIconModule,
        MatDialogModule,
        MatListModule,
        MatDatepickerModule,
    ],
    exports: [
        MediaContentTypeIconComponent
    ],
    entryComponents: [DeviceSelectionDialogComponent]
})
export class GenericModule { }
