import {AfterViewInit, Component, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {from, Observable, Subject} from 'rxjs';
import {flatMap, map, takeUntil} from 'rxjs/operators';
import {SearchableAutocompleteComponent} from '../../../../../../looma-shared/components/searchable-autocomplete/searchable-autocomplete.component';
import {NamedValue} from '@looma/shared/types/named_value';
import {SearchableField, SearchableObject, SearchFieldCriteria, SearchFieldCriteriaBundle} from '@looma/shared/search';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Utils} from '@looma/shared/utils';

@LifecycleHooks()
@Component({
    selector: 'app-store-assignment',
    templateUrl: './store-assignment.component.html',
    styleUrls: ['./store-assignment.component.scss']
})
export class StoreAssignmentComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChildren(SearchableAutocompleteComponent) searchFields: QueryList<SearchableAutocompleteComponent>;

    @Input('objectTypes')
    objectTypes: SearchableObject[] = [
        SearchableObject.Retailer,
        SearchableObject.RetailerRegion,
        SearchableObject.ProductCategory,
        SearchableObject.Store];

    @Input('layoutOrientation')
    layoutOrientation: 'horizontal' | 'vertical' = 'horizontal';

    @Input('chained')
    chained = false;

    @Input('value') value: SearchFieldCriteriaBundle = null;

    private filterController: FilterController;
    private filterChanges = new Subject<SearchFieldCriteriaBundle>();

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.filterController = new FilterController(this, this.searchFields.toArray(), this.filterChanges)
        })
    }

    ngOnDestroy(): void {
    }

    getCriteria(): SearchFieldCriteriaBundle {
        return this.filterController && this.filterController.getCriteria()
    }

    @Output('onChanged') onChanged(): Observable<SearchFieldCriteriaBundle> {
        return this.filterChanges
    }
}


class FilterController {

    private fieldsMap = new Map<SearchableObject, SearchableAutocompleteComponent>();

    constructor(private parent: StoreAssignmentComponent, private fields: SearchableAutocompleteComponent[], private filterChanges: Subject<SearchFieldCriteriaBundle>) {

        for (const t of this.parent.objectTypes) {
            const field = fields.find(value => value.searchObjectType === t);
            if (field) {
                this.fieldsMap.set(t, field)
            }
        }

        if (this.parent.value) {
            for (const field of fields) {
                field.control.setValue(this.parent.value.get(field.searchObjectType))
            }
        }


        from(Array.from(this.fieldsMap.values())
        ).pipe(
            flatMap((field: SearchableAutocompleteComponent) => field.onSelectionChanged.pipe(
                    map(value => ({field: field, value: value}))
                )
            ),
            takeUntil(Utils.onDestroy(parent))
        ).subscribe(value => {
            this.onValueChanged(value.field, value.value)
        });

        if (!this.parent.value || !this.parent.value.isEmpty) {
            this.refresh()
        }

    }

    private onValueChanged(field: SearchableAutocompleteComponent, value: NamedValue): void {
        switch (field.searchObjectType) {
            case SearchableObject.Retailer:
                this.setFieldValue(SearchableObject.RetailerRegion, null);
                this.setFieldValue(SearchableObject.Store, null);
                this.setFieldValue(SearchableObject.ProductCategory, null);
                break;
            case SearchableObject.RetailerRegion:
                this.setFieldValue(SearchableObject.Store, null);
                break;
            case SearchableObject.ProductCategory:
                break;
            case SearchableObject.Store:
                break
        }

        this.refresh();
        this.filterChanges.next(this.getCriteria())
    }

    private refresh(): void {
        const retailer = this.getFieldValue(SearchableObject.Retailer);
        const region = this.getFieldValue(SearchableObject.RetailerRegion);
        const productCategory = this.getFieldValue(SearchableObject.ProductCategory);

        this.setFieldEnabled(SearchableObject.RetailerRegion, !!retailer);
        this.setFieldEnabled(SearchableObject.Store, !!retailer);
        this.setFieldEnabled(SearchableObject.ProductCategory, !!retailer);

        if (retailer) {
            const retailerCriteria = [SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, retailer.value)];
            const storeCriteria = [].concat(retailerCriteria);

            this.setCriteria(SearchableObject.RetailerRegion, retailerCriteria);
            this.setCriteria(SearchableObject.ProductCategory, retailerCriteria);

            if (region) {
                storeCriteria.push(SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerRegionId, region.value));
            }
            this.setCriteria(SearchableObject.Store, storeCriteria);
        }

        if (this.parent.chained) {
            let disableNext = false;
            for (const objType of this.parent.objectTypes) {
                if (!disableNext) {
                    this.setFieldEnabled(objType, true);
                    if (!this.getFieldValue(objType)) {
                        disableNext = true
                    }
                } else {
                    this.setFieldEnabled(objType, false)
                }
            }
        }
    }

    getCriteria(): SearchFieldCriteriaBundle {
        const bundle = new SearchFieldCriteriaBundle();
        for (const x of this.fields) {
            const value = x.control.value as NamedValue;
            if (value) {
                switch (x.searchObjectType) {
                    case SearchableObject.Retailer:
                    case SearchableObject.RetailerRegion:
                    case SearchableObject.Store:
                    case SearchableObject.ProductCategory:
                        if (value && value.value) {
                            bundle.add(x.searchObjectType, value)
                        }
                }

            }
        }
        return bundle
    }

    private setFieldEnabled(objType: SearchableObject, enabled: boolean): void {
        const field = this.fieldsMap.get(objType);
        if (field) {
            field.enabled = enabled
        }
    }

    private setFieldValue(objType: SearchableObject, value: NamedValue): void {
        const field = this.fieldsMap.get(objType);
        if (field) {
            field.control.setValue(value)
        }
    }

    private setCriteria(objType: SearchableObject, criteria: SearchFieldCriteria[]): void {
        const field = this.fieldsMap.get(objType);
        if (field) {
            field.searchCriteria = criteria
        }
    }

    private getFieldValue(objType: SearchableObject): NamedValue {
        const field = this.fieldsMap.get(objType);
        if (field) {
            return field.control.value as NamedValue
        }
        return null
    }

}


