import {Component, OnDestroy, OnInit} from '@angular/core';
import {UploadService, UploadSession} from '@looma/shared/services/upload.service';
import {flatMap, takeUntil} from 'rxjs/operators';
import {ToastNotificationService} from '../../../../services/toast-notification.service';
import {Observable, of, throwError} from 'rxjs';
import {ApiDataService} from '../../../../services/api-data.service';
import {ActivatedRoute} from '@angular/router';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {
    DeleteItemComponent,
    EditItemComponent,
    ModelListPageComponent
} from '../../../../shared/model_list_page_component';
import {ModelDataSource} from '../../../../layout/components/looma-grid/grid-data-source';
import {MediaFileFilmVariable} from '@looma/shared/models/media_file_film_variable';
import {LayoutService} from '../../../../services/layout.service';
import {CursorFeed} from '@looma/shared/cursor_feed';
import {MutationResponse} from '@looma/shared/types/mutation_response';
import {MatDialogConfig} from '@angular/material/dialog';
import {MediaFileFilmVariablesEditDialogComponent} from '../media-file-film-variables-edit-dialog/media-file-film-variables-edit-dialog.component';
import {Utils} from '@looma/shared/utils';
import {CursorFilter} from '@looma/shared/types/cursor_filter';

@LifecycleHooks()
@Component({
    selector: 'app-media-file-film-variables',
    templateUrl: './media-file-film-variables.component.html',
    styleUrls: ['./media-file-film-variables.component.scss']
})
export class MediaFileFilmVariablesComponent extends ModelListPageComponent<MediaFileFilmVariable, CursorFilter>
    implements EditItemComponent<MediaFileFilmVariable>, DeleteItemComponent<MediaFileFilmVariable>, OnInit, OnDestroy {

    private readonly csvUploadSession: UploadSession = this.svcUpload.getUploadSession('media_file_film_variables', {
        fileTypes: ['csv'],
        multiSelection: false
    });
    
    constructor(private svcUpload: UploadService,
                private svcToastNotif: ToastNotificationService,
                private svcApi: ApiDataService,
                public svcLayout: LayoutService,
                private route: ActivatedRoute,) {

        super(MediaFileFilmVariable);
    }

    ngOnInit(): void {
        this.svcToastNotif.connect(this.csvUploadSession, file => {
                return this.svcApi.uploadMediaFileFilmVariables(this.filmId, file.firebaseKey).pipe(
                    takeUntil(Utils.onDestroy(this)),
                    flatMap(value => {
                        if (!value.success) {
                            return throwError(value.errors)
                        }
                        return of(value.data.firebase_job_id)
                    })
                )
            }
        ).subscribe(value => {
            if (value.job.isSuccess) {
                this.dataSource.refresh()
            } else {
                console.error(value)
            }
        })
    }

    ngOnDestroy(): void {
        this.csvUploadSession.destroy();
    }

    uploadFilmVariables(): void {
        this.csvUploadSession.openPicker();
    }

    private get filmId(): string {
        return this.route.snapshot.paramMap.get('id');
    }

    initDataSource(): ModelDataSource<MediaFileFilmVariable, CursorFilter> {
        return new MediaContentFilmVariableDataSource(this.svcApi, this.filmId);
    }

    get dataSource(): MediaContentFilmVariableDataSource {
        return super.dataSource as MediaContentFilmVariableDataSource;
    }

    canEditItem(item: MediaFileFilmVariable): boolean {
        return true;
    }

    performEditItem(item: MediaFileFilmVariable): Observable<MediaFileFilmVariable> {
        const dialogConfig: MatDialogConfig = {
            width: '500px'
        }
        if (item) {
            dialogConfig.data = {
                mediaFileFilmVariable: item,
                filmId: this.filmId,
            };
        }
        return this.svcLayout.openDialogForResult(MediaFileFilmVariablesEditDialogComponent, dialogConfig);
    }

    canDeleteItem(item: MediaFileFilmVariable): boolean {
        return true;
    }

    performDeleteItem(item: MediaFileFilmVariable): Observable<MutationResponse<MediaFileFilmVariable>> {
        return this.svcApi.deleteMediaFileFilmVariable(item.id);
    }
}


class MediaContentFilmVariableDataSource extends ModelDataSource<MediaFileFilmVariable, CursorFilter> {
    constructor(private svcApi: ApiDataService, private id: string) {
        super({
            columns: [
                {
                    key: 'variableName',
                    label: 'Variable',
                    valueReader: (item: MediaFileFilmVariable) => {
                        return item.film_variable.name;
                    }
                },
                {
                    key: 'variableValue',
                    label: 'Value',
                    valueReader: (item: MediaFileFilmVariable) => {
                        return item.variable_value;
                    }
                }
            ]
        });
    }

    loadData(dataFilter: CursorFilter): Observable<CursorFeed<MediaFileFilmVariable>> {
        return this.svcApi.getFilmVariables(this.id);
    }
}

