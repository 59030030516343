import {Component, OnInit} from '@angular/core';
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import gql from "graphql-tag";
import {RetailerPromoPeriodSubmission} from "@looma/shared/models/retailer_promo_period_submission";
import {CreateCustomBandDialogComponent} from "../create-custom-band-dialog/create-custom-band-dialog.component";
import {ProductCategory} from "@looma/shared/models/product_category";

@Component({
    selector: 'app-custom-brands',
    templateUrl: './custom-brands.component.html',
    styleUrls: ['./custom-brands.component.scss']
})
export class CustomBrandsComponent implements OnInit {

    displayedColumns: string[] = ['submission-date', 'submitted-by', 'parent-brand', 'brand', 'logo', 'action'];

    dataSource: RetailerPromoPeriodSubmission[]
    categories: ProductCategory[];

    constructor(
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.svcApi.rawQuery({
            query: FETCH_SUBMISSIONS_WITH_CUSTOM_BRANDS,
            fetchPolicy: "no-cache",
        }).pipe(map(result => result.data["customBrands"]))
            .subscribe(result => {
                this.dataSource = []
                result.forEach(item => {
                        const r = new RetailerPromoPeriodSubmission()
                        r.assign(item)
                        this.dataSource.push(r)
                    }
                )
            })
    }

    onBrandsClicked() {
        this.router.navigate(['/brands']).then();
    }

    createBrand(submission: RetailerPromoPeriodSubmission) {
        if (!submission) {
            return
        }
        CreateCustomBandDialogComponent.open(this.svcLayout, submission).subscribe(result => {
            if (result) {
                this.dataSource = [...this.dataSource.filter(f => f.id != submission.id)]
                this.svcLayout.showSnackMessage(`Brand ${submission.brandPartner.name} created`)
            }
        })
    }
}

const FETCH_SUBMISSIONS_WITH_CUSTOM_BRANDS = gql`
    query fetchCustomBrands {
        customBrands {
            id
            createdAt
            brandPartner {
                id
                name                
                logoUrl
            }
            submittedByBrand {
                id
                name
                looma_id
                product_categories {
                    id
                    category_name
                    sanitized_name
                }
            }
            submittedByUser
        }
    }
`
