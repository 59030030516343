<div mat-dialog-title style="display: flex; align-items: center">
    <span>Bulk product uploader</span>
    <div style="flex: 1"></div>
    <div class="navigation" *ngIf="activeImageFile">
        <button mat-icon-button [disabled]="isBusy || !hasImageAt(-1)" (click)="showImageAt(-1)">
            <mat-icon>chevron_left</mat-icon>
        </button>

        <button mat-icon-button [disabled]="isBusy || !hasImageAt(1)" (click)="showImageAt(1)">
            <mat-icon>chevron_right</mat-icon>
        </button>
        <div class="label">
            {{ queuedFiles.indexOf(activeImageFile) + 1 }} / {{ queuedFiles.length }}
        </div>
    </div>
</div>
<mat-dialog-content>
    <div style="position: relative">
        <app-searchable-autocomplete #brandSearch="objectSearchAutoComplete"
                                     [objectType]="'BrandPartner'"></app-searchable-autocomplete>


        <div *ngIf="!activeImageFile">
            <div id="file_uploader" appFileDd (fileDropped)="onFilesDropped($event)">
                <mat-icon>upload</mat-icon>
                <span class="hint">Drop images here or click to upload</span>
                <input type="file" #fileInput
                       (change)="onLocalFilesAdded($event)"
                       multiple="multiple"
                       accept="image/*"/>
            </div>
        </div>


        <div *ngIf="activeImageFile">
            <app-product-edit-form [imageFile]="activeImageFile" [allowChangeImage]="false"
                                   [product]="product"></app-product-edit-form>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div *ngIf="activeImageFile" style="display: flex; width: 100%; ">
        <button mat-button (click)="closeDialog()" [disabled]="isBusy">close</button>
        <div style="flex:1"></div>
        <button mat-raised-button color="warn" (click)="removeCurrentImage()" [disabled]="isBusy">Delete</button>
        <button mat-raised-button color="primary" (click)="saveCurrentImage()" [disabled]="isBusy || !isValid">Save
        </button>
    </div>
    <div *ngIf="!activeImageFile" style="display: flex">
        <button mat-button (click)="closeDialog()" [disabled]="isBusy">close</button>
    </div>


</mat-dialog-actions>

