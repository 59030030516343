<div id="login" fxLayout="column">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form" >
            <div class="logo">
                <img src="assets/images/logos/looma.svg">
            </div>
            <div class="title">LOGIN TO YOUR ACCOUNT</div>
            <button mat-button class="google" (click)="performLogin()">
                Log in with Google
            </button>
        </div>
    </div>
</div>