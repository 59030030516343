import {Component, ElementRef, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import {ToastNotification, ToastNotificationService, ToastNotificationStyle} from '../../../services/toast-notification.service';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {Utils} from '@looma/shared/utils';

@LifecycleHooks()
@Component({
    selector: 'app-toast-notifications',
    templateUrl: './toast-notifications.component.html',
    styleUrls: ['./toast-notifications.component.scss']
})

export class ToastNotificationsComponent implements OnInit, OnDestroy {

    _notifications: ToastNotification[];

    @ViewChildren('notifBody')
    private bodyRef: ElementRef<any>;

    constructor(private svcToastNotifications: ToastNotificationService) {

    }

    ngOnInit(): void{
        this.getNotifications().subscribe(value => {
            this._notifications = value
        })
    }



    getNotifications(): Observable<ToastNotification[]>{
        return this.svcToastNotifications.getNotifications().pipe(
            takeUntil(Utils.onDestroy(this))
        )
    }

    ngOnDestroy(): void {

    }


    onNotificationActionClick(actionId: string, notification: ToastNotification): void{
        const action = notification.actions.find(value => value.id === actionId);
        if(action && action.handler){
            action.handler()
        }
    }

    getCssClass(notif: ToastNotification): string{
        return 'style-'+notif.style.toString()
    }

    getIconName(notif: ToastNotification): string{
        switch (notif.style) {
            case ToastNotificationStyle.Error:
                return 'error';
            case ToastNotificationStyle.Info:
                return 'info';
            case ToastNotificationStyle.Success:
                return 'check_circle';
            case ToastNotificationStyle.Warn:
                return 'warning';
            case ToastNotificationStyle.Completed:
                return 'done_all';
            default:
                return '';
        }
    }


    getSpinnerMode(notif: ToastNotification): ProgressSpinnerMode{
        switch (notif.style) {
            case ToastNotificationStyle.Loading:
                if(isNaN(notif.progress) || notif.progress < 0){
                    return 'indeterminate'
                }
                return 'determinate'
        }
        return null
    }

    hasIconStatus(notif: ToastNotification): boolean{
        return !this.getSpinnerMode(notif);
    }

    hasDismissableNotifications(): boolean{
        return !!this._notifications.find(value => value.dismissable)
    }

    clearDismissableNotifications(): void{
        this._notifications.filter(value => value.dismissable).forEach(value => value.dismiss())
    }


}
