import {BaseModel} from '@looma/shared/models/base_model';
import {ProductCategory} from '@looma/shared/models/product_category'
import {WordFilterable} from '@looma/shared/types/word_filterable';
import {BrandPartner} from '@looma/shared/models/brand_partner'

export class BrandPartnerProduct extends BaseModel implements WordFilterable {
    id: number;
    upc_code: string;
    brandPartnerId: number;
    brandPartner: BrandPartner;
    name: string;
    productCategory: ProductCategory;
    productCategoryId: number;
    thumb_url: string;

    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'brandPartner', BrandPartner);
        this.assignTypedObject(input, 'productCategory', ProductCategory);
        return this;
    }

    getDisplayName(): string {
        return this.name;
    }

    getPhrases(): string[] {
        return [this.name, this.upc_code];
    }
}
