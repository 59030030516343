import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiDataService} from "../../../../services/api-data.service";
import {
    MUTATE_SCHEDULE_PREFERRED_PRODUCTS,
    QUERY_PROGRAM_SUBMISSION_SCHEDULE,
    RetailerPromoPeriodSubmissionSchedule,
    SubmissionScheduleCriteria
} from "@looma/shared/models/retailer_promo_period_submission_schedule";
import {map, startWith, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {
    ItemCollection,
    ItemGridController,
    ItemGridDataSource
} from "../../../../layout/components/device-slot-assignment-list/device_slot_assignment_controller";
import {DD_ACCEPT_ANY} from "../../../../layout/components/device-slots-assignment/device-slots-assignment.component";
import {BrandProduct} from "@looma/shared/models/brand_product";
import {LayoutService} from "../../../../services/layout.service";
import {TemplatePortal} from "@angular/cdk/portal";
import {Overlay} from "@angular/cdk/overlay";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {Observable, of} from "rxjs";
import {FormControl} from "@angular/forms";

@LifecycleHooks()
@Component({
    selector: 'app-program-subission-products',
    templateUrl: './program-submission-products.component.html',
    styleUrls: ['./program-submission-products.component.scss']
})
export class ProgramSubmissionProductsComponent implements OnInit {

    schedule: RetailerPromoPeriodSubmissionSchedule

    controller = new BrandProductController();
    isBusy = false;

    defaultCollection: ItemCollection<BrandProduct>
    otherCollections: ItemCollection<BrandProduct>[]

    selectedProducts: PreferredProductsSegmentInput[] = []
    
    

    @ViewChild('tplFilter') tplFilter: TemplateRef<any>;

    bpControl = new FormControl();
    productControl = new FormControl();
    
    allBrandPartners: BrandPartner[] = []
    allProducts: BrandProduct[] = []
    
    bpFilteredOptions!: Observable<BrandPartner[]>;
    productFilteredOptions!: Observable<BrandProduct[]>;

    constructor(private activatedRoute: ActivatedRoute,
                private svcApi: ApiDataService,
                private svcLayout: LayoutService,
                private viewContainerRef: ViewContainerRef,
                private overlay: Overlay,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        const scheduleId = this.activatedRoute.snapshot.paramMap.get("submissionId") || null
        if (scheduleId == null) {
            return;
        }

        this.bpFilteredOptions = this.bpControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? this._filterBp(value) : this.allBrandPartners.slice())
        );

        this.productFilteredOptions = this.productControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? this._filterProducts(value) : this.allProducts.slice())
        );
        
        const criteria: SubmissionScheduleCriteria = {
            id: scheduleId
        };

        this.svcApi.rawQuery({
            query: QUERY_PROGRAM_SUBMISSION_SCHEDULE, variables: {
                criteria: criteria
            }
        }).pipe(takeUntil(Utils.onDestroy(this))).subscribe(result => {
            this.schedule = new RetailerPromoPeriodSubmissionSchedule()
            this.schedule.assign(result.data["programSubmissionSchedule"])

            this.svcApi.getProducts({
                productCategoryId: this.schedule.retailerPromoProgram?.productCategory?.id
            }).subscribe(result => {
                const allProducts: BrandProduct[] = []
                this.schedule.preferredProduct?.segments?.forEach(segment => {
                    segment.products?.forEach(p => {
                        allProducts.push(p)
                    })
                })
                
                allProducts.forEach(p => {
                    this.allBrandPartners.push(p.brand_partner)
                })
                
                const availableProducts = result.data.filter(p => {
                    return !allProducts?.find(preferredProduct => preferredProduct.id === p.id)
                }).sort((a, b) => a.brand_partner.name.localeCompare(b.brand_partner.name))

                this.allProducts = [].concat(availableProducts)
                
                this.allBrandPartners = availableProducts.reduce((acc, p) => {
                    if (!acc.some(bp => bp.id === p.brand_partner.id)) { 
                        acc.push(p.brand_partner);
                    }
                    return acc;
                }, []);
                

                this.defaultCollection = {
                    key: "default",
                    items: availableProducts,
                    label: "Available products",
                }

                let segments = this.schedule.retailerPromoProgram.productSegments
                if (!segments || segments.length == 0) {
                    segments = ["default segment"]
                }

                const groups = segments.map(s => {
                    const segment = this.schedule.preferredProduct?.segments?.find(x => {
                        const sName = x.segmentName == "default" ? "default segment" : x.segmentName
                        return sName === s
                    })
                    let brandProducts: BrandProduct[] = []
                    if (segment && segment.products?.length > 0) {
                        brandProducts = segment.products.map(p => {
                            const newProduct = new BrandProduct()
                            newProduct.assign(p)
                            return newProduct
                        })
                    }

                    const sortedProducts = brandProducts.sort((a,b) => a.brand_partner.name.localeCompare(b.brand_partner.name))

                    return {
                        key: s,
                        items: sortedProducts,
                        label: s,
                    }
                })

                this.otherCollections = [...groups]

                this.controller.setup(this.changeDetector, this.defaultCollection, this.otherCollections)
                this.controller.onAssignmentChanged().pipe(takeUntil(Utils.onDestroy(this)))
                    .subscribe(r => {
                        if (r) {
                            this.selectedProducts = []
                            this.controller.itemDataSources.forEach(ds => {
                                let key = ds.dataSourceKey
                                if (key == "default segment") {
                                    key = "default"
                                }
                                const productIds = ds.data.map(x => x.getData().id)
                                this.selectedProducts.push({
                                    segmentName: key,
                                    productIds: productIds,
                                })
                            })
                        }
                    })
            })
        })
    }

    saveAssignment() {
        this.svcApi.rawObjectMutate(MUTATE_SCHEDULE_PREFERRED_PRODUCTS,
            {
                submissionScheduleId: this.schedule.id,
                input: {
                    segments: this.selectedProducts
                },
            }, RetailerPromoPeriodSubmissionSchedule).subscribe(result => {
            if (result.success) {
                this.svcLayout.showSnackMessage("Preferred products saved")
            } else {
                this.svcLayout.showMutationResultMessage(result)
            }
        })
    }

    openFilters($event: MouseEvent) {
        const portal = new TemplatePortal(this.tplFilter, this.viewContainerRef, {});
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().flexibleConnectedTo(event.target as HTMLElement).withPositions([{
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
            }]),
            hasBackdrop: true,
            backdropClass: 'transparent'
        });
        overlayRef.backdropClick().subscribe(() => overlayRef.detach());
        overlayRef.attach(portal);
    }

    private _filterBp(value: string): BrandPartner[] {
        const filterValue = value.toLowerCase();
        return this.allBrandPartners.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    private _filterProducts(value: string): BrandProduct[] {
        const filterValue = value.toLowerCase();
        return this.allProducts.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    bpDisplayFn(item: BrandPartner): string {
        return item && item.name ? item.name : '';
    }

    productDisplayFn(item: BrandProduct): string {
        return item.brand_partner.name + " " + item.name + " " + item.upc_code
    }
}


class BrandProductDataSource extends ItemGridDataSource<BrandProduct> {
    constructor(controller: ItemGridController<BrandProduct>, collection: ItemCollection<BrandProduct>) {
        super(controller, collection, [{
            key: "brand",
            label: "Brand",
            valueReader: value => value.brand_partner.name
        }, {
            key: "name",
            label: "Name",
            valueReader: value => value.name
        }, {
            key: "upc_code",
            label: "UPC",
            valueReader: value => value.upc_code
        }, {
            key: "parent-company",
            label: "Parent Company",
            valueReader: value => value.brand_partner.parentBrand?.name
        }, {
            key: "product-category",
            label: "Category",
            valueReader: value => value.brand_partner.product_categories.map(s => s.category_name).join(",")
        }, ]);
    }
}

export class BrandProductController extends ItemGridController<BrandProduct> {
    initDataSource(collection: ItemCollection<BrandProduct>, isDefault: boolean): ItemGridDataSource<BrandProduct> {
        if (isDefault) {
            return new BrandProductDataSource(this, {
                label: collection.label,
                key: collection.key,
                items: [].concat(collection.items),
                acceptedItemGroups: DD_ACCEPT_ANY
            });
        }
        return new BrandProductDataSource(this, collection)
    }
}

export interface PreferredProductsSegmentInput {
    segmentName: string
    productIds: string[]
}
