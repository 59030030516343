import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {Location} from "@angular/common";
import gql from "graphql-tag";
import {Observable, Subscription} from "rxjs";
import {map, takeUntil} from "rxjs/operators";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {Utils} from "@looma/shared/utils";
import {ApiDataService} from "../../../../services/api-data.service";
import {
    DD_ACCEPT_ANY,
    DeviceSlotsAssignmentComponent
} from "../../../../layout/components/device-slots-assignment/device-slots-assignment.component";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {LayoutService} from "../../../../services/layout.service";
import {
    ItemCollection
} from "../../../../layout/components/device-slot-assignment-list/device_slot_assignment_controller";

@LifecycleHooks()
@Component({
    selector: 'app-program-device-slot-type-assignment',
    templateUrl: './program-device-slot-type-assignment.component.html',
    styleUrls: ['./program-device-slot-type-assignment.component.scss']
})
export class ProgramDeviceSlotTypeAssignmentComponent implements OnInit {

    constructor(
        private activatedRoute: ActivatedRoute,
        public location: Location,
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
    ) {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            const chunks = id.split('-');
            if (chunks.length == 2) {
                this.entryParams = {
                    retailerId: chunks[0],
                    programId: chunks[1],
                }
            }
        }
        if (!this.entryParams) {
            this.location.back()
        }
    }

    get isBusy(): boolean {
        return !Utils.isUnsubscribed(this.saveSubscription);
    }

    pageTitle = 'Slot Type Assignment'

    entryParams: { programId: string, retailerId: string } = null;
    dirtyAssignments = new Set<ItemCollection<DeviceSlot>>();

    @ViewChild(DeviceSlotsAssignmentComponent) component: DeviceSlotsAssignmentComponent;

    private saveSubscription: Subscription;

    static open(router: Router, prog: RetailerPromoProgram) {
        router.navigate([`promo-programs/${prog.retailer.id}-${prog.id}/type-assignment`])
    }

    ngOnInit() {
        if (!this.entryParams) {
            return
        }
        this.loadProgram(this.entryParams.programId).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value) {
                this.pageTitle = `${value.name} - Slot Type Assignment`
            }
            this.assignDeviceSlotsFromRetailer(value)
        })
    }

    private loadProgram(programId: string): Observable<RetailerPromoProgram> {
        return this.svcApi.rawQuery({
            query: QUERY_LOAD_PROGRAM,
            variables: {
                id: programId
            }
        }).pipe(
            map(value => {
                const programs = Utils.getNestedTypedArray(value, RetailerPromoProgram, 'data', 'retailerPromoPrograms', 'retailerPromoPrograms');
                return programs[0]
            })
        )
    }

    assignDeviceSlotsFromRetailer(program: RetailerPromoProgram) {

        const deviceSlotMap = new Map<string, DeviceSlot>();
        for (const deviceSlot of (program.unAssignedDeviceSlots || [])) {
            deviceSlotMap.set(deviceSlot.id, deviceSlot)
        }

        const collections = program.deviceSlotTypes.map(value => {
            const slots = value.deviceSlots || [];
            const col: ItemCollection<DeviceSlot> = {
                items: slots,
                key: value.id,
                label: value.getDisplayName(),
                acceptedItemGroups: DD_ACCEPT_ANY
            };
            return col
        });


        const unnAssignedDevices = Array.from(deviceSlotMap.values());

        const defaultDs: ItemCollection<DeviceSlot> = {
            items: unnAssignedDevices,
            key: 'UNASSIGNED',
            label: 'Unassigned',
            acceptedItemGroups: DD_ACCEPT_ANY
        }

        this.component.setup(defaultDs, collections);
        this.component.controller.onAssignmentChanged().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.handleAssignmentChanged(value)
        })

    }

    handleAssignmentChanged(collections: Set<ItemCollection<DeviceSlot>>) {
        for (const col of collections) {
            this.dirtyAssignments.add(col)
        }
    }

    saveAssignment() {
        if (!Utils.isUnsubscribed(this.saveSubscription)) {
            return
        }

        const data = Array.from(this.dirtyAssignments).map(col => {
            const x: DeviceTypeAssignationInput = {
                deviceSlotTypeId: col.key,
                deviceSlotIds: col.items.map(value => value.id)
            };
            return x;
        });


        this.saveSubscription = this.svcApi.rawObjectMutate(MUTATION_ASSIGN_SLOTS, {
            data: data
        }, null).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.dirtyAssignments = new Set<ItemCollection<DeviceSlot>>();
                this.svcLayout.showSnackMessage('Values Saved')
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Error saving values')
            }
        })

    }

}

interface DeviceTypeAssignationInput {
    deviceSlotTypeId: string
    deviceSlotIds: string[]
}

const FRAGMENT_DEVICE_SLOT_FIELDS = gql`
fragment DeviceSlotFields on DeviceSlot {
  id
  name
  counter
  device_installed_at
  device_number
  looma_phase
  product_category{
    id
    category_name
  }
  store {
    id
    retailer_store_num
    retailer_region {
      id
      region_name
    }
  }
    kioskDeviceApp {
        id
        app_name
        package_name
    }
}
`;

const QUERY_LOAD_PROGRAM = gql`
${FRAGMENT_DEVICE_SLOT_FIELDS}
query programs($id:ID!){
  retailerPromoPrograms(filter:{id:$id}){
    cursor
    retailerPromoPrograms {
      id
      name
      retailer {
        id
        retailer_name
      }
      unAssignedDeviceSlots {
        ...DeviceSlotFields
      }
      deviceSlotTypes {
        id
        name
        defaultBrandSlotsCount
        retailerRegionIds
        storesCount
        deviceSlotsCount
        deviceSlots {
          ...DeviceSlotFields
        }
      }
        changeoverSettings{
            enabled
            numDays
        }
    }
  }
}
`;

const MUTATION_ASSIGN_SLOTS = gql`
mutation assign($data: [DeviceTypeAssignationInput!]!){
  assignDeviceSlotTypes(data:$data){
    success
    message
  }
}
`;
