<div app-looma-page
     pageTitle="Program Submissions - {{this.retailerPromoPeriod?.retailer.retailer_name}} / {{this.retailerPromoProgram?.name}} / {{this.retailerPromoPeriod?.name}}"
     pageIcon="perm_contact_calendar" style="height: 100%" [headerSideTpl]="headerSideTpl">

    <div style="padding: 20px; overflow-y: scroll">
        <mat-card>
            <mat-card-content>
                <mat-accordion multi>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Submission schedule details
                            </mat-panel-title>
                            <mat-panel-description>
                                <div *ngIf="retailerPromoPeriodSubmissionSchedule">
                                    <div style="display: flex; align-items: center;">
                                        <mat-chip
                                            *ngIf="retailerPromoPeriodSubmissionSchedule.openForSubmission && !retailerPromoPeriodSubmissionSchedule.isDeadlineDue()"
                                            class="opened-for-submission">Open
                                            for
                                            submission
                                        </mat-chip>
                                        <mat-chip
                                            *ngIf="!retailerPromoPeriodSubmissionSchedule.openForSubmission || retailerPromoPeriodSubmissionSchedule.isDeadlineDue()"
                                            class="closed-for-submission">Closed for submissions
                                        </mat-chip>
                                        <div style="margin-left: 10px; margin-right: 10px;">|</div>
                                        <div>Due
                                            date: {{ retailerPromoPeriodSubmissionSchedule.dueDate | date: 'M/dd/yyyy' }}
                                        </div>
                                        <div style="margin-left: 10px; margin-right: 10px;">|</div>
                                        <div>Campaign
                                            cost: {{ retailerPromoPeriodSubmissionSchedule.campaignCost | currency: 'USD' }}
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <form [formGroup]="form" *ngIf="form">
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: column">
                                    <div class="form-field-container">
                                        <mat-label>Open for Submissions</mat-label>
                                        <mat-slide-toggle formControlName="openForSubmissions"></mat-slide-toggle>
                                    </div>

                                    <div class="form-field-container">
                                        <mat-label>Ready for review</mat-label>
                                        <mat-slide-toggle formControlName="readyForReview"></mat-slide-toggle>
                                    </div>

                                    <div class="form-field-container">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Due Date</mat-label>
                                            <input matInput [matDatepicker]="picker" formControlName="dueDate"
                                                   [min]="today">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-field-container">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Campaign Cost ($)</mat-label>
                                            <input matInput type="number" formControlName="campaignCost">
                                        </mat-form-field>
                                    </div>

                                    <div class="form-field-container">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Theme</mat-label>
                                            <input matInput type="text" formControlName="theme"
                                                   [disabled]="!isThematic">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>

                    <mat-expansion-panel *ngIf="displaySegmentsForm()">
                        <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight">
                            <mat-panel-title>
                                Segments
                            </mat-panel-title>

                            <mat-panel-description>
                                <div style="display: flex; flex-direction: column">
                                    <div *ngFor="let segmentGroup of segmentGroups">
                                        <div style="display: flex; flex-direction: row">
                                            <div style="margin-right: 50px">{{ segmentGroup.groupName }}</div>

                                            <div *ngFor="let s of segmentGroup.segments; let isLast = last"
                                                 style="display: flex; flex-direction: row">
                                                <div>{{ s.segmentName }} - {{ s.segmentCost | currency: 'USD' }}</div>
                                                <div *ngIf="!isLast" style="margin-left: 10px; margin-right: 10px">|
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div style="display: flex;">
                            <div *ngFor="let group of segmentGroups" style="margin-right: 30px">
                                <h3>{{ group.groupName }}</h3>

                                <div *ngFor="let s of group.segments" class="segment-container">
                                    <label class="segment-label">{{ s.segmentName }}</label>
                                    <input type="number"
                                           placeholder="Value in dollars"
                                           [(ngModel)]="s.segmentCost"
                                           (ngModelChange)="onSegmentChange()"
                                           class="segment-input"
                                           [disabled]="!this.form.get('openForSubmissions')?.value">
                                </div>
                            </div>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <div style="margin-top: 10px">
                    <button mat-raised-button color="primary" [disabled]="!canSave()" (click)="doSave()"
                            style="width: 200px">Save
                    </button>
                </div>


                <div *ngIf="retailerPromoPeriod?.status == 3" class="promo-alert">
                    Submission schedule editing is unavailable as the promo period has ended.
                </div>
            </mat-card-content>
        </mat-card>

        <h2>{{ retailerPromoPeriodSubmissionSchedule?.submissions?.length || 0 }} campaigns submitted</h2>

        <div *ngIf="dataSource && retailerPromoPeriodSubmissionSchedule">
            <app-campaign-submission-card *ngFor="let submission of dataSource"
                                          [submission]="submission"></app-campaign-submission-card>
        </div>
    </div>
</div>

<ng-template #headerSideTpl>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onPreferredProductsClicked()">
            <mat-icon>shopping_basket</mat-icon>
            <span>Preferred Products</span>
        </button>
        <button mat-menu-item (click)="onBrandsClicked()">
            <mat-icon>stars</mat-icon>
            <span>Brand Submission Status</span>
        </button>
        <button mat-menu-item (click)="onEnrollmentClicked()">
            <mat-icon>stars</mat-icon>
            <span>Product Enrollment Status</span>
        </button>
    </mat-menu>
</ng-template>