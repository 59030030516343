import { Component, OnInit } from '@angular/core';
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";
import gql from "graphql-tag";
import { filter, map, takeUntil, tap } from "rxjs/operators";
import { Utils } from "@looma/shared/utils";
import { RetailerProgramDeviceStatEntry } from "../../../../models/RetailerProgramDeviceStatEntry";
import { DashboardStatsFilter } from "../../types/stat_entry";
import { ApiDataService } from "../../../../services/api-data.service";
import { NamedValue } from "@looma/shared/types/named_value";
import { SearchableField, SearchFieldCriteria } from "@looma/shared/search";
import { Observable, of, Subject, Subscription } from "rxjs";
import { Retailer } from "@looma/shared/models/retailer";
import { RetailerRegion } from "@looma/shared/models/retailer_region";
import { RetailerDivision } from "@looma/shared/models/retailer_division";

type retailerFieldFilter = 'program' | 'region' | 'division'

@LifecycleHooks()
@Component({
    selector: 'app-dashboard-charts',
    templateUrl: './dashboard-charts.component.html',
    styleUrls: [ './dashboard-charts.component.scss' ]
})
export class DashboardChartsComponent implements OnInit{
    chartOptions: any
    chartInstance: any

    selectedChartType = DashboardChartType.UptimePercentage
    selectedRetailerId: number = null
    selectedRetailerFieldFilters: Partial<Record<retailerFieldFilter, number>> = {}

    AvailableDashboardChartTypes = AvailableDashboardChartTypes
    DashboardChartType = DashboardChartType
    loadedEntries: RetailerProgramDeviceStatEntry[] = []
    retailerFieldsSearchCriteria: SearchFieldCriteria[];

    retailerChangedPipe = new Subject<any>()

    startDate: Date
    endDate: Date

    loadDataSub: Subscription

    chartDescription: string


    constructor(private svcApi: ApiDataService){
    }

    ngOnInit(): void{
        this.loadData()
    }

    onSelectedChartTypeChanged(ev: any){
        this.refreshChartData(true)
    }

    onRetailerFilterChanged(data: NamedValue){
        this.availableRegions = null
        this.availableDivisions = null
        this.selectedRegions = []
        this.selectedDivisions = []

        const retailerId = data ? parseInt(data.value) : null
        if (retailerId) {
            this.retailerFieldsSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, retailerId).asArray();
            this.loadRetailerRegionsAndDivisions(retailerId).pipe(
                takeUntil(Utils.onDestroy(this))
            ).subscribe(ret => {
                if (retailerId == this.selectedRetailerId) {
                    this.availableRegions = ret.regions
                    this.availableDivisions = ret.divisions
                }
            })
        }
        this.retailerChangedPipe.next(retailerId)
        this.selectedRetailerId = retailerId
        this.selectedRetailerFieldFilters = {}
        this.loadData()
    }

    availableRegions: RetailerRegion[]
    availableDivisions: RetailerDivision[]

    selectedRegions: RetailerRegion[] = []
    selectedDivisions: RetailerDivision[] = []

    retailersWithRegionsAndDivisions = new Map<number, Retailer>()

    isSmeObj(a: any, b: any): boolean{
        return (a && b) && a.id === b.id;
    }

    onSelectedRegionOrDivisionChanged(ev: any){
        this.loadData()
    }

    loadRetailerRegionsAndDivisions(retailerId: number): Observable<Retailer>{
        const prev = this.retailersWithRegionsAndDivisions.get(retailerId)
        if (prev) {
            return of(prev)
        }
        return this.svcApi.rawQuery({
            query: QUERY_RETAILER_REGIONS_AND_DIVISIONS,
            variables: { retailerId: retailerId }
        }).pipe(
            takeUntil(Utils.onDestroy(this)),
            map(value => {
                const ret = Utils.getNestedTypedArray(value, Retailer, 'data', 'retailers_feed', 'retailers')[0]
                if (ret) {
                    ret.id = retailerId
                }
                return ret
            }),
            filter(x => !!x),
            tap(ret => this.retailersWithRegionsAndDivisions.set(retailerId, ret))
        )
    }

    onRetailerFieldFilterChanged(data: NamedValue, field: retailerFieldFilter){
        const recordId = data ? parseInt(data.value) : null
        this.selectedRetailerFieldFilters[field] = recordId
        this.loadData()
    }

    get selectedRetailerHasDivisions(){
        return this.selectedRetailerId + '' == Retailer.ID.KRO
    }

    handleDateChanged(which: 'start' | 'end'){
        this.loadData()
    }

    private loadData(){

        const filter: DashboardStatsFilter = {}
        if (this.selectedRetailerId) {
            filter.retailerId = this.selectedRetailerId
            for (const key of (Object.keys(this.selectedRetailerFieldFilters) as retailerFieldFilter[])) {
                const value = this.selectedRetailerFieldFilters[key]
                switch (key) {
                    case 'program':
                        filter.programId = value
                        break

                }
            }
            if (this.selectedDivisions?.length) {
                filter.divisionIds = this.selectedDivisions.map(value => value.getId())
            }
            if (this.selectedRegions?.length) {
                filter.regionIds = this.selectedRegions.map(value => value.getId())
            }
        }

        if (this.startDate) {
            filter.startDate = Utils.formatShortDate(this.startDate)
        }
        if (this.endDate) {
            filter.endDate = Utils.formatShortDate(this.endDate)
        }
        Utils.unsubscribe(this.loadDataSub)
        this.loadDataSub = this.svcApi.rawQuery({
            query: QUERY_DATA,
            variables: { filter: filter }
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
                this.loadedEntries = Utils.getNestedTypedArray(value, RetailerProgramDeviceStatEntry, 'data', 'retailerProgramDeviceStats')
                this.refreshChartData()
                this.refreshChartDescription()
            }
        )
    }

    getEchartsOptions(): any{
        if (!this.chartOptions) {
            this.chartOptions = this.initEchartsOptions()
        }
        return this.chartOptions
    }

    onChartInit(e: any): void{
        this.chartInstance = e
    }

    refreshChartDescription(){
        if (this.selectedChartType == DashboardChartType.UptimePercentage && this.startDate && this.loadedEntries?.length) {
            const entries = this.loadedEntries || []
            let totalNum = 0
            for (const entry of entries) {
                totalNum += entry.globalOpenHoursOnlinePercent || 0
            }
            const totalPercent = Utils.round(totalNum * 100 / entries.length, 2)
            this.chartDescription = `Average Uptime (store open hours): ${totalPercent}%`
        } else {
            this.chartDescription = null
        }
    }

    refreshChartData(resetZoom = true){
        const entries = this.loadedEntries || []
        let data = null
        let series: any[]
        switch (this.selectedChartType) {
            case DashboardChartType.DevicesOffline:
                data = entries.map(value =>
                    [ value.ts, value.offlineCount ]
                )
                break
            case DashboardChartType.DevicesOnline:
                data = entries.map(value =>
                    [ value.ts, value.onlineCount ]
                )
                break
            case DashboardChartType.DevicesInstalled:
                data = entries.map(value =>
                    [ value.ts, value.installedCount ]
                )
                break
            case DashboardChartType.StoreCount:
                data = entries.map(value =>
                    [ value.ts, value.storesCount ]
                )
                break
            case DashboardChartType.UptimePercentage:
                series = [ {
                    type: 'line',
                    symbol: 'none',
                    sampling: 'average',
                    itemStyle: {
                        color: 'rgb(255, 70, 131)'
                    },
                    name: DashboardChartType.UptimePercentage,
                    data: entries.map(value => {
                            let v = 0
                            if (value.installedCount > 0) {
                                v = Utils.round(value.onlineCount * 100.0 / value.installedCount, 2)
                            }
                            return [ value.ts, v ]
                        }
                    )
                }, {
                    type: 'line',
                    symbol: 'none',
                    sampling: 'average',
                    itemStyle: {
                        color: 'rgb(70,122,255)'
                    },
                    name: DashboardChartType.OpenHoursUptimePercentage,
                    data: entries.map(value => {
                            let v = 0
                            if (value.globalOpenHoursOnlinePercent > 0) {
                                v = Utils.round(value.globalOpenHoursOnlinePercent * 100.0, 2)
                            }
                            return [ value.ts, v ]
                        }
                    )
                }, {
                    type: 'line',
                    symbol: 'none',
                    sampling: 'average',
                    itemStyle: {
                        color: 'rgb(255,153,70)'
                    },
                    name: DashboardChartType.WholeDayUptimePercentage,
                    data: entries.map(value => {
                            let v = 0
                            if (value.globalDayOnlinePercent > 0) {
                                v = Utils.round(value.globalDayOnlinePercent * 100.0, 2)
                            }
                            return [ value.ts, v ]
                        }
                    )
                } ]

                break
            default:
                data = []
                break
        }

        const opts = this.getEchartsOptions()

        if (data) {
            series = [ {
                type: 'line',
                symbol: 'none',
                sampling: 'average',
                itemStyle: {
                    color: 'rgb(255, 70, 131)'
                },
                name: this.selectedChartType,
                data: data
            } ]
        }

        opts.series = series
        if (entries.length && resetZoom) {

            if (this.selectedChartType == DashboardChartType.UptimePercentage && this.startDate) {
                const firstTs = entries[0].ts
                opts.dataZoom[0].startValue = firstTs
            } else {
                const lastTs = entries[entries.length - 1].ts
                opts.dataZoom[0].startValue = lastTs - 1000 * 60 * 60 * 8
            }


        }
        if (this.chartInstance) {
            this.chartInstance.setOption(opts, true)
        }
    }

    initEchartsOptions(): any{

        const data = []

        return {
            grid: {
                left: 40,
                top: 10,
                right: 40,
                bottom: 60
            },
            tooltip: {
                trigger: 'axis',
                position: pt => [ pt[0], '10%' ]
            },
            title: {},
            toolbox: {},
            xAxis: {
                type: "time",
                boundaryGap: false
            },
            yAxis: {
                type: 'value',
                boundaryGap: [ 0, '10%' ]
            },
            dataZoom: [
                {
                    id: 'dataZoomX',
                    type: 'slider',
                    xAxisIndex: [ 0 ],
                    filterMode: 'filter',   // Set as 'filter' so that the modification
                                            // of window of xAxis will effect the
                                            // window of yAxis.
                    startValue: 0,//toDate - 1000 * 60 * 60 * 8
                }, {
                    type: 'inside',
                    start: 0,
                    end: 100
                },

            ],
            series: [
                {
                    type: 'line',
                    symbol: 'none',
                    sampling: 'average',
                    itemStyle: {
                        color: 'rgb(255, 70, 131)'
                    },

                    data: data
                }
            ]
        }
    }


}

enum DashboardChartType{
    UptimePercentage = 'Uptime Percentage',
    OpenHoursUptimePercentage = 'Average uptime (open hours)',
    WholeDayUptimePercentage = 'Average uptime (whole day)',
    DevicesInstalled = 'Devices Installed',
    DevicesOnline = 'Devices Online',
    DevicesOffline = 'Devices Offline',
    StoreCount = 'Store Count',
}

const AvailableDashboardChartTypes = [
    DashboardChartType.UptimePercentage,
    DashboardChartType.DevicesInstalled,
    DashboardChartType.DevicesOnline,
    DashboardChartType.DevicesOffline,
    DashboardChartType.StoreCount,
]

const QUERY_DATA = gql`
    query getData($filter: RetailerProgramDeviceStatFilter){
        retailerProgramDeviceStats(scope:Series, filter: $filter){
            ts
            storesCount
            slotsCount
            installedCount
            assignedCount
            onlineCount
            offlineCount
            globalOpenHoursOnlinePercent
            globalDayOnlinePercent
        }
    }
`

const QUERY_RETAILER_REGIONS_AND_DIVISIONS = gql`
    query getRetailerRegionsAndDivisions($retailerId:ID!){
        retailers_feed(filter:{id:$retailerId}){
            retailers{
                regions{
                    id
                    region_name
                }
                divisions{
                    id
                    name
                }
            }
        }
    }
`