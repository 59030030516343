import {BaseModel} from "@looma/shared/models/base_model";
import {DeviceSlotType} from "@looma/shared/models/device_slot_type";
import {PromoPeriodDeviceSlotSubgroup} from "@looma/shared/models/promo_period_device_slot_subgroup";
import {PromoPlaylistAssignment} from "@looma/shared/models/promo_playlist_assignment";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {DeviceSlotSegmentAssignmentLog} from "@looma/shared/models/device_slot_segment_assignment_log";

export class DeviceSlotSegment extends BaseModel {
    id: string
    name: string
    deviceSlotType: DeviceSlotType
    defaultBrandSlotsCount: number
    deviceSlotSubgroups: PromoPeriodDeviceSlotSubgroup[]
    playlistAssignments: PromoPlaylistAssignment[];
    promoPeriod: RetailerPromoPeriod;
    deviceSlots: DeviceSlot[];
    assignmentLogs: DeviceSlotSegmentAssignmentLog[]

    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'deviceSlotType', DeviceSlotType)
        this.assignTypedArray(input, 'deviceSlotSubgroups', PromoPeriodDeviceSlotSubgroup)
        this.assignTypedArray(input, 'playlistAssignments', PromoPlaylistAssignment)
        this.assignTypedObject(input, 'promoPeriod', RetailerPromoPeriod)
        this.assignTypedArray(input, 'deviceSlots', DeviceSlot);
        this.assignTypedArray(input, 'assignmentLogs', DeviceSlotSegmentAssignmentLog);
        this.assignmentLogs.forEach(value => value.deviceSlotSegment = this)
        return this
    }
}


export interface DeviceSlotSegmentInput {
    id?: string
    name?: string
    promoPeriodId?: string
    deviceSlotTypeId?: string
    defaultBrandSlotsCount?: string
    deviceSlotIds?: string[]
}
