import {BaseModel} from '@looma/shared/models/base_model';

export class GeneralFileInfo extends BaseModel {
    format: string;
    file_size: number;
}

export class ImageFileInfo extends BaseModel {
    format: string;
    width: number;
    height: number;
    rotation: number
}

export class VideoFileInfo extends BaseModel {
    id: number;
    format: string;
    duration: number;
    frame_count: number;
    width: number;
    height: number;
}

export class AudioFileInfo extends BaseModel {
    id: number;
    format: string;
    duration: number;
    frame_count: number;
    bit_rate: number;
    sampling_rate: number;
    frame_rate: number;
    channels: number

}

export class MediaFileInfo extends BaseModel {
    general: GeneralFileInfo;
    video: VideoFileInfo;
    audio: AudioFileInfo;
    image: ImageFileInfo;

    assign(input: any): this {
        super.assign(input);
        this.assignNested(input, 'general', new GeneralFileInfo());
        this.assignNested(input, 'video', new VideoFileInfo());
        this.assignNested(input, 'audio', new AudioFileInfo());
        this.assignNested(input, 'image', new ImageFileInfo());
        return this
    }
}
