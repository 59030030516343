import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModelDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {BrandPartner, BrandPartnerSearchCriteria, BrandPartnerType} from "@looma/shared/models/brand_partner";
import {BrandsEditDialogComponent} from "../brands-edit-dialog/brands-edit-dialog.component";
import {ProductCategory} from "@looma/shared/models/product_category";
import {MatSelectChange} from "@angular/material/select";
import {Observable, switchMap} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {ListCursorLoader} from "@looma/shared/cursor_loader";
import {debounceTime, distinctUntilChanged, map, takeUntil} from "rxjs/operators";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {FormControl} from "@angular/forms";
import {Utils} from '@looma/shared/utils';
import gql from "graphql-tag";
import {RowActionRegistry} from "../../../../shared/row_action_registry";
import {ButtonActions} from "../../../../shared/button_actions";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {Router} from "@angular/router";

@LifecycleHooks()
@Component({
    selector: 'app-brands-list',
    templateUrl: './brands-list.component.html',
    styleUrls: ['./brands-list.component.scss']
})
export class BrandsListComponent implements OnInit, OnDestroy {

    categories: ProductCategory[];
    dataSource: BrandDataSource;
    searchInput: FormControl;

    ButtonActions = ButtonActions;
    BrandPartnerType = BrandPartnerType

    brandActions = new RowActionRegistry<BrandPartner>([
        {
            key: this.ButtonActions.Edit,
            label: 'Edit',
            available: item => true,
            handler: bp => {
                this.editBrandPartner(bp)
            }
        },
        {
            key: this.ButtonActions.Archive,
            label: 'Archive',
            showAlways: false,
            available: bp => Utils.isBoolean(bp.isArchived) && !bp.isArchived,
            handler: bp => {
                this.setBrandArchived(bp, this.ButtonActions.Archive)
            }
        },
        {
            key: this.ButtonActions.UnArchive,
            label: 'Un-archive',
            showAlways: false,
            available: bp => Utils.isBoolean(bp.isArchived) && bp.isArchived,
            handler: bp => {
                this.setBrandArchived(bp, this.ButtonActions.UnArchive)
            }
        }
    ]);

    constructor(
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private router: Router,
    ) {
        this.dataSource = new BrandDataSource(this.svcApi);
        this.searchInput = new FormControl();
    }

    ngOnInit(): void {
        this.initializeProductCategories();
        this.searchInput.valueChanges
            .pipe(
                takeUntil(Utils.onDestroy(this)),
                debounceTime(300),
                distinctUntilChanged()
            ).subscribe(searchText => {
            this.searchByText(searchText);
        });
    }

    ngOnDestroy(): void {
    }

    addBrandPartner(type: BrandPartnerType): void {
        const bp = new BrandPartner()
        switch (type) {
            case BrandPartnerType.Brand:
            case BrandPartnerType.ParentCompany:
                bp.type = type
                break
            default:
                return
        }
        this.openEditDialog(bp)
    }

    setBrandArchived(bp: BrandPartner, action: string): void {
        let archive = false
        switch (action) {
            case ButtonActions.Archive:
                archive = true
                break
            case ButtonActions.UnArchive:
                archive = false
                break
            default:
                return
        }
        this.svcLayout.onConfirmed(
            `${action} brand`,
            `Are you sure you want to ${action.toLowerCase()} this brand?`
        ).pipe(
            switchMap(value => {
                return this.svcApi.upsertBrandPartner(MutationOperation.Update, {
                    id: bp.id,
                    isArchived: archive,
                }, BRAND_FOR_MUTATION_FIELDS)
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.addOrReplaceBrand(bp, value.data)

            } else {
                this.svcLayout.showSnackMessage(value.message || 'Unexpected error');
            }
        })
    }

    editBrandPartner(bp: BrandPartner): void {
        this.openEditDialog(bp);
    }

    openEditDialog(bp: BrandPartner): void {
        BrandsEditDialogComponent.open(this.svcLayout, bp, BRAND_FOR_MUTATION_FIELDS).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(brand => {
            if (brand instanceof BrandPartner) {
                this.addOrReplaceBrand(bp, brand)
            }
        });
    }

    private addOrReplaceBrand(originalBrand: BrandPartner, updatedBrand: BrandPartner) {
        const isNewBrand = originalBrand.isNewRecord()
        if (updatedBrand.parentBrand) {
            const parentId = updatedBrand.parentBrand.getId()
            const dsParentBrand = this.dataSource.getData().find(parentBrand => parentBrand.getId() === parentId)?.getData()

            if (!dsParentBrand) {
                return
            }

            dsParentBrand.childBrands = dsParentBrand.childBrands || []

            if (originalBrand.isNewRecord()) {
                dsParentBrand.childBrands.push(updatedBrand)
                updatedBrand.parentBrand = dsParentBrand
            } else if (originalBrand.parentBrand && (originalBrand.parentBrand?.id != updatedBrand.parentBrand?.id)) {

                const oldParent = this.dataSource.getData().find(parentBrand => parentBrand.getId() === originalBrand.parentBrand?.getId())?.getData()
                const newParent = this.dataSource.getData().find(parentBrand => parentBrand.getId() === updatedBrand.parentBrand?.getId())?.getData()
                if (oldParent) {
                    const idx = oldParent.childBrands.findIndex(value1 => value1.getId() == updatedBrand.getId())
                    if (idx >= 0) {
                        oldParent.childBrands.splice(idx, 1)
                        oldParent.childBrands = [].concat(oldParent.childBrands)
                    }
                    this.dataSource.invalidateChildBrandsDatasource(oldParent)
                }
                if (newParent) {
                    newParent.childBrands ||= []
                    newParent.childBrands.push(updatedBrand)
                    updatedBrand.parentBrand = newParent
                    this.dataSource.invalidateChildBrandsDatasource(newParent)
                }
            } else {
                const idx = dsParentBrand.childBrands.findIndex(value1 => value1.getId() == updatedBrand.getId())
                if (idx >= 0) {
                    dsParentBrand.childBrands[idx] = updatedBrand
                    updatedBrand.parentBrand = dsParentBrand
                }
            }
            this.dataSource.invalidateChildBrandsDatasource(dsParentBrand)

        } else {
            this.dataSource.invalidateChildBrandsDatasource(updatedBrand)
            if (isNewBrand) {
                this.dataSource.addItem(updatedBrand)
            } else {
                this.dataSource.replaceItem(updatedBrand);
            }
        }

        this.brandActions.invalidateItem(updatedBrand)
    }

    private initializeProductCategories(): void {
        this.svcApi.getProductCategories().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(categories => {
            this.categories = categories;
        })
    }

    setShowArchivedBrands(includeArchived: boolean) {
        this.dataSource.setFilter({
            includeArchived: includeArchived
        })
    }

    doSearchCategories(event: MatSelectChange): void {
        const filter: Partial<BrandPartnerSearchCriteria> = {
            productCategoryIds: event.value
        }
        this.dataSource.setFilter(filter);
    }

    searchByText(searchText: string): void {
        const filter: Partial<BrandPartnerSearchCriteria> = {
            querySelfAndChildren: searchText
        }
        this.dataSource.setFilter(filter)
    }

    handleBrandSelected(bp: BrandPartner) {
        this.dataSource.setItemExpanded(bp.parentBrand || bp, true)
    }

    onCustomBrandsClicked() {
        this.router.navigate(['/custom-brands']).then();
    }
}


class BrandDataSource extends ModelDataSource<BrandPartner, BrandPartnerSearchCriteria> {

    private childrenBrandDataSources = new Map<number, BrandDataSource>()

    constructor(private svcApi: ApiDataService) {
        super({
            columns: [
                {
                    key: 'logo',
                    label: '',
                    width: '60px',
                    valueReader: (item: BrandPartner) => {
                        return item.logoUrl
                    }
                },
                {
                    key: 'looma_id',
                    label: 'Looma ID',
                    valueReader: (item: BrandPartner) => {
                        return item.looma_id
                    }
                }, {
                    key: 'Name',
                    label: 'Name',
                    valueReader: (item: BrandPartner) => {
                        return item.name
                    }
                },
                {
                    key: 'category',
                    label: 'Category',
                    valueReader: (item: BrandPartner) => {
                        return (item.product_categories || []).map(t => t.category_name).join(", ");
                    }
                }, {
                    key: 'status',
                    label: 'Status',
                    width: '80px',
                    valueReader: (bp: BrandPartner) => {
                        if (Utils.isBoolean(bp.isArchived)) {
                            return bp.isArchived ? 'Archived' : 'Active'
                        }
                        return ''
                    }
                },
            ]
        });
    }

    loadData(dataFilter: BrandPartnerSearchCriteria): Observable<CursorFeed<BrandPartner>> {
        dataFilter = {
            ...dataFilter || {},
            parentBrand: true
        }
        return this.svcApi.rawQuery({
            query: FETCH_BRANDS_QUERY,
            fetchPolicy: "no-cache",
            variables: {
                criteria: dataFilter
            }
        }).pipe(
            map(value => {
                const rawData = value.data['brands'] as any;
                return CursorFeed.create(rawData, BrandPartner, 'brand_partners')
            })
        );
    }

    isItemExpandable(entry: BrandPartner): boolean {
        return (entry.childBrands || []).length > 0
    }

    invalidateChildBrandsDatasource(parentBrand: BrandPartner) {
        const key = parentBrand.getId()
        const ds = this.childrenBrandDataSources.get(key)
        if (ds) {
            const loader = ds.getLoader() as any as ListCursorLoader<BrandPartner>
            loader.setItems(parentBrand.childBrands)
        }
    }

    getChildBrandsDatasource(parentBrand: BrandPartner) {
        const key = parentBrand.getId()
        if (!this.childrenBrandDataSources.has(key)) {
            const ds = new BrandDataSource(this.svcApi)
            ds.setDataLoader(new ListCursorLoader(parentBrand.childBrands))
            this.childrenBrandDataSources.set(key, ds)
        }
        return this.childrenBrandDataSources.get(key)
    }

}

const BRAND_FIELDS = `
id
name
looma_id
logoUrl
type
isArchived
product_categories {
    id
    category_name
}
`

const BRAND_WITH_CHILDREN_FIELDS = `
${BRAND_FIELDS}
childBrands {
    ${BRAND_FIELDS}
}
`

const BRAND_FOR_MUTATION_FIELDS = `
${BRAND_WITH_CHILDREN_FIELDS}
parentBrand{
    id
}
`

const FETCH_BRANDS_QUERY = gql`
    query fetchBrands($criteria:BrandPartnerSearchCriteria!){
        brands(criteria: $criteria) {
            brand_partners {
                ${BRAND_WITH_CHILDREN_FIELDS}
            }
        }
    }
`
