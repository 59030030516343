import {BaseModel} from '@looma/shared/models/base_model';

export class RemoteWifiInfo extends BaseModel {
    bssid: string;
    hidden: boolean;
    ip: string;
    rssi: number;
    signal_level: number;
    ssid: string;
    mac_address: string;

    addProperties(values: Map<string, any>): void {
        values.set('SSID', this.ssid);
        values.set('Wifi Mac Address', this.mac_address);
        values.set('IP', this.ip);
        values.set('Signal Level', this.signal_level);
    }
}
