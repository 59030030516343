import {Component, Inject, OnInit} from '@angular/core';
import {DeviceSlotImagePhoto} from "@looma/shared/models/device_slot_image_photo";
import {DeviceSlotImage} from "@looma/shared/models/device_slot_image";
import {LayoutService} from "../../../../services/layout.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ApiDataService} from "../../../../services/api-data.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageSource, Utils} from "@looma/shared/utils";
import {ModelEditDialog} from "../../../../shared/model_edit_dialog";
import {Observable} from "rxjs";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {takeUntil} from "rxjs/operators";

@LifecycleHooks()
@Component({
    selector: 'app-edit-device-slot-image-photo-dialog',
    templateUrl: './edit-device-slot-image-photo-dialog.component.html',
    styleUrls: ['./edit-device-slot-image-photo-dialog.component.scss']
})
export class EditDeviceSlotImagePhotoDialogComponent extends ModelEditDialog<DeviceSlotImage> implements OnInit {

    constructor(
        private svcApi: ApiDataService,
        public svcLayout: LayoutService,
        public dialogRef: MatDialogRef<EditDeviceSlotImagePhotoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: EditDeviceSlotImagePhotoDialogData
    ) {
        super()
        this.imageSource = new ImageSource(this.dialogData.deviceSlotImagePhoto.large.url)
    }

    imageSource: ImageSource;
    isPrimaryImage: boolean
    private rotationAngle = 0;
    hasChanges = false

    static open(svcLayout: LayoutService, deviceSlotImage: DeviceSlotImage, photo: DeviceSlotImagePhoto): Observable<DeviceSlotImage> {
        const data: EditDeviceSlotImagePhotoDialogData = {
            deviceSlotImagePhoto: photo,
            deviceSLotImage: deviceSlotImage
        }

        return svcLayout.openDialogForResult(EditDeviceSlotImagePhotoDialogComponent, {
            data,
            width: "800px",
            minHeight: "600px",
        })

    }

    onSave(): Observable<MutationResponse<DeviceSlotImage>> | null {
        return this.svcApi.mutateDeviceSlotImagePhoto(this.dialogData.deviceSLotImage, {
            deviceSlotImagePhotoId: this.dialogData.deviceSlotImagePhoto.id,
            rotation: this.rotationAngle,
            isPrimary: this.isPrimaryImage,
        })
    }

    ngOnInit(): void {
        this.isPrimaryImage = this.dialogData.deviceSlotImagePhoto.isPrimary;
        
        this.imageSource.onRotationChanged().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.rotationAngle = value
            this.refreshHashChanges();
        })
    }

    onPrimaryChanged(){
        this.refreshHashChanges()
    }
    
    private refreshHashChanges(){
        this.hasChanges = this.checkHasChanges() 
    }
    
    private checkHasChanges(): boolean{
        if (this.rotationAngle != 0) {
            return true
        }
        if(this.isPrimaryImage != this.dialogData.deviceSlotImagePhoto.isPrimary){
            return true
        }
        return false
    }


}

export interface EditDeviceSlotImagePhotoDialogData {
    deviceSlotImagePhoto: DeviceSlotImagePhoto
    deviceSLotImage: DeviceSlotImage
}
