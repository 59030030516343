import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NamedValue} from "@looma/shared/types/named_value";
import {MatSelect, MatSelectChange} from "@angular/material/select";
import firebase from 'firebase/compat/app';
import {SearchableField, SearchFieldCriteria} from "@looma/shared/search";
import {
    AutocompleteSearchFieldComponent
} from "@looma/shared/components/autocomplete-search-field/autocomplete-search-field.component";
import {ApiDataService} from "../../../../services/api-data.service";
import {
    ToastNotification,
    ToastNotificationService,
    ToastNotificationStyle
} from "../../../../services/toast-notification.service";
import {map, switchMap, takeUntil} from "rxjs/operators";
import gql from "graphql-tag";
import {MatOption} from "@angular/material/core";
import {
    RetailerYearSchedules,
    ScheduledCampaign,
    SchedulePromoPeriodEntry
} from "@looma/shared/models/retailer_year_schedule";
import {EMPTY, Subscription} from "rxjs";
import {Utils} from "@looma/shared/utils";
import {MUTATION_RESPONSE_FIELDS} from "@looma/shared/services/queries";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {HttpClient} from "@angular/common/http";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {MatDialog} from "@angular/material/dialog";
import {CampaignSubmissionCardComponent} from "../campaign-submission-card/campaign-submission-card.component";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";
import {BrandCampaignEditDialogComponent} from "../brand-campaign-edit-dialog/brand-campaign-edit-dialog.component";
import {BRAND_CAMPAIGN_GQL_FIELDS} from "../promo-schedule-program-overview/promo-schedule-program-overview.component";
import {LayoutService} from "../../../../services/layout.service";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";

@LifecycleHooks()
@Component({
    selector: 'app-schedule-calendar',
    templateUrl: './schedule-calendar.component.html',
    styleUrls: ['./schedule-calendar.component.scss']
})
export class ScheduleCalendarComponent implements OnInit, OnDestroy {

    private genReportSubscription: Subscription
    private activeNotif: ToastNotification

    years = [2021, 2022, 2023, 2024, 2025]
    retailer: NamedValue | null
    program: NamedValue | null
    selectedYear: string | null

    promoProgramSearchCriteria: SearchFieldCriteria[]

    private readonly uploadSession: UploadSession;

    @ViewChild("promoProgramSearchField") promoProgramSearchField: AutocompleteSearchFieldComponent
    @ViewChild("yearSelection") yearSelection: MatSelect

    private uploadSubscription: Subscription;
    private fileAddedSubscription: Subscription;

    promoPeriods: SchedulePromoPeriodEntry[]
    maxBrandCount = 0

    newMerchGuideFirebaseKey: string
    private uploadToastNotif: ToastNotification;

    currentPromoPeriodUpload: SchedulePromoPeriodEntry

    constructor(private svcApi: ApiDataService,
                private svcNotif: ToastNotificationService,
                private http: HttpClient,
                private svcLayout: LayoutService,
                private svcUpload: UploadService,
                private svcToastNotif: ToastNotificationService,
                private dialog: MatDialog) {

        this.uploadSession = svcUpload.newUploadSession({
            fileTypes: ['pdf'],
            multiSelection: false
        });

        this.uploadSubscription = this.uploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                const storage = firebase.storage();
                this.newMerchGuideFirebaseKey = file.firebaseKey;

                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.svcApi.mutate(MUTATION_UPLOAD_MERCH_GUIDE, {
                        retailerPromoPeriodID: this.currentPromoPeriodUpload.retailerPromoPeriod.id,
                        retailerPromoProgramID: this.program.value,
                        merchGuideUrl: url,
                    }).pipe(takeUntil(Utils.onDestroy(this)))
                        .subscribe(result => {
                            if (result) {
                                this.svcLayout.showSnackMessage('Merch guide uploaded successfully');
                                this.currentPromoPeriodUpload.retailerPromoPeriod.merchGuideUrl = url
                                this.dismissNotification();
                                this.currentPromoPeriodUpload = null
                            }
                        })
                });
            }, error => {
                this.svcLayout.showSnackMessage('Update error');
                this.dismissNotification();
            });


        this.fileAddedSubscription = this.uploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this)))
            .subscribe((file: UploadedFileInfo) => {
                this.uploadToastNotif = this.svcToastNotif.create({
                    title: "Merch guide upload",
                    description: 'Uploading',
                    dismissable: false,
                    style: ToastNotificationStyle.Loading,
                    progress: -1,
                });
            });
    }

    ngOnDestroy(): void {
        if (this.uploadSession) {
            this.uploadSession.destroy()
        }
        if (this.fileAddedSubscription) {
            this.fileAddedSubscription.unsubscribe()
        }
    }

    ngOnInit(): void {
    }

    onRetailerSelected(retailer: NamedValue) {
        this.retailer = retailer

        if (this.promoProgramSearchField != null) {
            this.promoProgramSearchField.clearInput()
        }

        if (retailer) {
            this.promoProgramSearchCriteria = SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, retailer.value).asArray();
        } else {
            this.promoProgramSearchCriteria = null;
        }
        this.program = null;
        this.selectedYear = null;
    }

    onPromoProgramSelected(program: NamedValue) {
        this.program = program
        this.yearSelection.options.forEach((data: MatOption) => data.deselect());
        this.selectedYear = null;
    }

    showCalendar(evt: MatSelectChange) {
        if (!(this.retailer && evt.value && this.program)) {
            return
        }

        this.promoPeriods = []

        this.selectedYear = evt.value

        this.svcApi.rawQuery({
            query: FETCH_SCHEDULE_CALENDAR,
            fetchPolicy: "no-cache",
            variables: {
                filter: {
                    retailerId: this.retailer.value,
                    programId: this.program.value,
                    year: evt.value
                },
                programID: this.program.value
            }
        }).pipe(
            map(value => {
                const rawData = value.data['yearlySchedule'] as any;
                const r = (new RetailerYearSchedules()).assign(rawData)
                return r
            })
        ).subscribe(result => {
            const res = result.scheduleEntries.pop()
            this.promoPeriods = res.promoPeriodEntries.sort((a, b) =>
                a.retailerPromoPeriod.startDate.getTime() - b.retailerPromoPeriod.startDate.getTime()
            )
            this.promoPeriods.forEach(i => i.segments?.filter(s => s.campaigns).forEach(s => {
                if (s.campaigns.length > this.maxBrandCount) {
                    this.maxBrandCount = s.campaigns.length
                }
            }))
        })
    }

    getCampaign(campaigns: ScheduledCampaign[], idx: number): ScheduledCampaign | null {
        if (!campaigns) {
            return null
        }
        if (idx >= campaigns.length) {
            return null
        }
        return campaigns[idx];
    }

    getCampaignBrandPartner(campaigns: ScheduledCampaign[], idx: number): string | null {
        if (!campaigns) {
            return null
        }
        if (idx >= campaigns.length) {
            return null
        }
        return campaigns[idx].brandPartnerName;
    }

    downloadCampaignSubmissionsCSV() {
        if (!this.program || !this.selectedYear) {
            return
        }

        Utils.unsubscribe(this.genReportSubscription)

        if (this.activeNotif) {
            this.activeNotif.dismiss()
        }

        const notif = this.activeNotif = this.svcNotif.create({
            title: `Generating ${this.program.name} submissions report for year ${this.selectedYear}`,
            dismissable: false,
            style: ToastNotificationStyle.Loading
        })

        this.genReportSubscription = this.svcApi.mutate(
            gql`
            mutation generateCampaignSubmissionReport(
                $programId: Int!, $year: String!
            ) {
                generateCampaignSubmissionReport(
                    programId: $programId,
                    year: $year,
                ) {
                    ${MUTATION_RESPONSE_FIELDS}
                }
            }`, {
                programId: this.program.value,
                year: this.selectedYear,
            }
        ).pipe(
            takeUntil(Utils.onDestroy(this)),
            switchMap(result => {
                if (result) {
                    const mutationResponse: MutationResponse<any> = result.data["generateCampaignSubmissionReport"] as MutationResponse<any>
                    if (mutationResponse.success) {
                        return notif.watchJob(mutationResponse.triggeredJobId)
                    } else {
                        notif.update({
                            style: ToastNotificationStyle.Error,
                            description: mutationResponse.message || 'Unexpected error'
                        })
                        return EMPTY
                    }
                }
            })
        ).subscribe(job => {
            const downloadUrl = job.getExtra('download_url')
            if (job.isSuccess) {
                notif.update({
                    title: `Report ${this.program?.name} generated`,
                    actions: [{
                        id: 'download',
                        text: 'Download',
                        handler: () => {
                            const retailer = `${this.program?.name}-${this.selectedYear}-Campaign-Submissions`
                            const filename = retailer.replace(/\s+/g, '-');
                            this.triggerDownload(downloadUrl, filename)
                        }
                    }]

                })
            }
        })
    }

    triggerDownload(downloadUrl: string, filename: string) {
        this.http.get(downloadUrl, {responseType: 'blob'})
            .subscribe(blob => {
                // Create a temporary link to download the file
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.display = 'none';
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            });
    }

    isExportEnabled() {
        return this.selectedYear != null && this.program != null;
    }

    onCampaignClicked(campaign: ScheduledCampaign) {
        if (!campaign) {
            return
        }
        this.dialog.open(CampaignSubmissionCardComponent, {
            width: '800px',
            data: {
                submissionId: campaign.id
            }
        }).afterClosed().subscribe(submission => {
            if (!submission) {
                return
            }

            const promoPeriodId = submission.schedule.retailerPromoPeriod.id
            const promoPeriod = this.promoPeriods.find(p => {
                return p.retailerPromoPeriod.id == promoPeriodId
            })
            const newSegments = promoPeriod.segments.map(s => {
                const index = s.campaigns.map(c => c.id).indexOf(campaign.id)
                if (index >= 0) {
                    s.campaigns.splice(index, 1)
                }
                return s;
            })
            promoPeriod.segments = newSegments
        });
    }

    openBrandCampaign(brandCampaign: BrandPromoCampaign | undefined) {
        if (!brandCampaign) {
            return
        }

        BrandCampaignEditDialogComponent.open(this.svcLayout, brandCampaign, BRAND_CAMPAIGN_GQL_FIELDS).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
        })
    }

    uploadMerchGuide(item: SchedulePromoPeriodEntry) {
        if (item) {
            this.currentPromoPeriodUpload = item
            this.uploadSession.openPicker();
        }
    }

    private dismissNotification(): void {
        if (this.uploadToastNotif) {
            this.uploadToastNotif.dismiss();
            this.uploadToastNotif = null;
        }
    }
}

const FETCH_SCHEDULE_CALENDAR = gql`
query yearlySchedule($filter: YearlyScheduleFilter!, $programID: ID!) {
  yearlySchedule(filter: $filter) {
    scheduleEntries {
      year
      promoPeriodEntries {
        retailerPromoPeriod {
          id
          name
          startDate
          endDate
          merchGuideUrl(programID: $programID)
        }
        segments {
          deviceSlotSegmentId
          deviceSlotSegmentName
          campaigns {
            id
            name
            brandPartnerName        
            status
            isSubmission                
            brandCampaign {
                ${BRAND_CAMPAIGN_GQL_FIELDS}                               
            }
          }
        }
      }
    }
  }
}
`

const MUTATION_UPLOAD_MERCH_GUIDE = gql`
    mutation uploadMerchGuide($retailerPromoPeriodID: ID!, $retailerPromoProgramID: ID!, $merchGuideUrl: String!) {
        uploadMerchGuide(retailerPromoPeriodID: $retailerPromoPeriodID, retailerPromoProgramID: $retailerPromoProgramID, merchGuideUrl: $merchGuideUrl) {
            id
        }        
    }
`


