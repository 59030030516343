<div app-looma-page pageTitle="Media Content" style="height: 100%" [headerSideTpl]="tplHeader">
    <ng-template *ngFor="let c of contentTypes" [pageTab]="c.name">
        <app-media-content-type-list [contentType]="c.id"></app-media-content-type-list>
    </ng-template>
</div>

<ng-template #tplHeader>
    <mat-form-field style="width: 300px; padding-right: 10px">
        <mat-label>Film name or film maker</mat-label>
        <input autocomplete="off" matInput fxFlex (input)="searchByFilmNameOrFilmMaker($event)"/>
    </mat-form-field>
    
    <app-autocomplete-search-field showClear="true" placeholder="Brand" searchObjectType="BrandPartner"
                                   (valueChanged)="searchByBrandPartner($event)" selectionMode="single"
                                   style="width: 300px; padding-right: 20px"></app-autocomplete-search-field>

    <button mat-raised-button color="warn" (click)="uploadNewFilm()">
        Upload content
    </button>
</ng-template>
