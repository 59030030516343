<div mat-dialog-title >
    <div style="margin: 0"> Installation Warnings</div>
    <h3 style="margin: 0;">{{deviceSlot?.name}}</h3>
   
</div>
<mat-dialog-content>

    
    
    <div *ngFor="let warn of deviceSlot.tabletInstallationWarnings" fxLayout="row" class="warn_line" fxLayoutGap="16px">
        <mat-checkbox [checked]="warn.resolved" [disabled]="warn.resolved"
                      (change)="onSelectionChanged(warn,$event)"></mat-checkbox>
        <div>{{warn.description}}</div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleAction('ok')"
            [disabled]="!hasUnsavedChanges || isSavingData ">
        Resolve Selected
    </button>
    <button mat-button (click)="handleAction('cancel')" [disabled]="isBusy">cancel</button>
    <button mat-button (click)="showSubmissionImage()" color="warn">View Image</button>
    
</mat-dialog-actions>