import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '@looma/shared/utils';

@Pipe({
    name: 'humanDuration'
})
export class HumanDurationPipe implements PipeTransform {

    transform(rawSeconds: any, args?: any): any {

        let strArgs = 'h:m:s';
        if(Utils.isString(args)){
            strArgs = args;
        }

        if (isNaN(rawSeconds) || rawSeconds <= 0){
            return '';
        }

        const secs = parseInt(rawSeconds, 10),
            hours   = Math.floor(secs / 3600),
            minutes = Math.floor((secs - (hours * 3600)) / 60),
            seconds = secs - (hours * 3600) - (minutes * 60);

        return strArgs.replace('h', this.pad(hours)).replace('m', this.pad(minutes)).replace('s', this.pad(seconds));

    }

    pad(no: number): string{
        return no.toString().padStart(2, '0');
    }



}
