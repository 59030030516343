<h2 mat-dialog-title>{{ this.product.isNewRecord() ? "Create Product" : "Edit " + this.product.name }}</h2>

<mat-dialog-content [formGroup]="form">
    <app-searchable-autocomplete #brandSearch="objectSearchAutoComplete"
                                 [objectType]="'BrandPartner'"></app-searchable-autocomplete>

    <div style="text-align: center; width: 100%; padding: 20px">
        <div>
            <img [src]="imageUrl" style="max-width: 300px; height: auto"/>
        </div>
        <div style="padding:10px">
            <button mat-raised-button (click)="uploadImage()" [disabled]="isUploadingImage">Upload product image
            </button>
        </div>
    </div>

    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" autocomplete="off"/>
    </mat-form-field>


    <mat-form-field appearance="fill">
        <mat-label>UPC</mat-label>
        <input matInput formControlName="upc" autocomplete="off" (blur)="validateUpc()" (focus)="cancelValidateUpc()"/>
        <mat-spinner *ngIf="isValidatingUpc" diameter="20" matSuffix style="margin-right: 16px"></mat-spinner>
    </mat-form-field>
    <div style="margin-top: -24px; margin-bottom: 16px; margin-left: 16px" *ngIf="isCurrentUpcNotFound">
        <mat-error>UPC not found</mat-error>
    </div>


    <app-autocomplete-search-field
        placeholder="Brand"
        [searchObjectType]="'BrandPartner'"
        [value]="selectedBrandPartner"
        selectionMode="single"
        [disabled]="!brandPartnerEditable"
        (valueChanged)="onBrandPartnerSelected($event)"></app-autocomplete-search-field>

    <app-autocomplete-search-field
        placeholder="Retailer"
        [searchObjectType]="'Retailer'"
        [value]="selectedRetailers"
        selectionMode="multi"
        [disabled]="!retailerEditable"
        (valueChanged)="onRetailerSelected($event)"></app-autocomplete-search-field>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isBusy || !form.valid">Save</button>
    <button mat-button (click)="close()" [disabled]="isBusy">cancel</button>
</mat-dialog-actions>