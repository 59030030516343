import { NamedValue } from '@looma/shared/types/named_value';
import { Utils } from "@looma/shared/utils";

export enum ConditionInputType {
    Text = 'text',
    SingleSelection = 'single_selection',
    MultiSelection = 'multi_selection',
}

export class FilterConditionOperator {
    static readonly Eq = new FilterConditionOperator('Eq', 'Is', ConditionInputType.SingleSelection);
    static readonly NotEq = new FilterConditionOperator('NotEq', 'Is Not', ConditionInputType.SingleSelection);
    static readonly Contains = new FilterConditionOperator('Contains', 'Contains', ConditionInputType.Text);
    static readonly NotContains = new FilterConditionOperator('NotContains', 'Does not contain', ConditionInputType.Text);
    static readonly In = new FilterConditionOperator('In', 'Any Of', ConditionInputType.MultiSelection);
    static readonly NotIn = new FilterConditionOperator('NotIn', 'None Of', ConditionInputType.MultiSelection);
    static readonly AllOf = new FilterConditionOperator('AllOf', 'All Of', ConditionInputType.MultiSelection);
    static readonly AnyOf = new FilterConditionOperator('AnyOf', 'Any Of', ConditionInputType.MultiSelection);

    static readonly ALL = [
        FilterConditionOperator.Eq,
        FilterConditionOperator.NotEq,
        FilterConditionOperator.Contains,
        FilterConditionOperator.NotContains,
        FilterConditionOperator.In,
        FilterConditionOperator.NotIn,
        FilterConditionOperator.AllOf,
        FilterConditionOperator.AnyOf,
    ];

    static ByKey(name: string): FilterConditionOperator | null {
        for (const mem of FilterConditionOperator.ALL) {
            if (mem.key === name) {
                return mem
            }
        }
        return null
    }

    constructor(public key: string, public name: string, public inputType: ConditionInputType) {

    }

    toString(): string {
        return this.key;
    }
}

class NamedObject {
    protected readonly name: string;

    constructor(name: string) {
        this.name = name
    }

    toString(): string {
        return this.name
    }

    toJSON(): string {
        return this.name
    }

}

export class SearchableField extends NamedObject {
    static readonly Id = new SearchableField('Id');
    static readonly RetailerId = new SearchableField('RetailerId');
    static readonly RetailerRegionId = new SearchableField('RetailerRegionId');
    static readonly ProductCategoryId = new SearchableField('ProductCategoryId');
    static readonly DeviceSlotId = new SearchableField('DeviceSlotId');
    static readonly StoreId = new SearchableField('StoreId');
    static readonly BrandId = new SearchableField('BrandId');
    static readonly ParentId = new SearchableField('ParentId');
    static readonly ProductId = new SearchableField('ProductId');
    static readonly DeviceSlotImageId = new SearchableField('DeviceSlotImageId');
    static readonly PromoPeriodId = new SearchableField('PromoPeriodId');
    static readonly Text = new SearchableField('Text');
    static readonly AssignableUserId = new SearchableField('AssignableUserId');

    static readonly ALL = [
        SearchableField.RetailerId,
        SearchableField.RetailerRegionId,
        SearchableField.ProductCategoryId,
        SearchableField.BrandId,
        
        SearchableField.StoreId,
        SearchableField.DeviceSlotImageId,
        SearchableField.ProductId,
        SearchableField.Text,
        SearchableField.Id,
        SearchableField.ParentId,
        SearchableField.PromoPeriodId,
        SearchableField.AssignableUserId,
    ];

    public readonly fkField: SearchableField;

    static byName(name: any): SearchableField {
        name = name + '';
        for (const item of SearchableField.ALL) {
            if (item.name === name) {
                return item
            }
        }
        return null
    }

    private constructor(name) {
        super(name)
    }
}

export type SearchableObjectType =
    SearchableObject
    | 'Retailer'
    | 'RetailerRegion'
    | 'RetailerDivision'
    | 'ProductCategory'
    | 'BrandPartner'
    | 'ParentBrand'
    | 'Store'
    | 'StoreGroup'
    | 'Device'
    | 'DeviceSlot'
    | 'DeviceSlotImage'
    | 'BrandProduct'
    | 'BrandPartnerProduct'
    | 'PromoProgram'
    | 'PromoPeriod'
    | 'MediaFileVariant'
    | 'PromoPlaylistMediaBundle'
    | 'User';


export class SearchableObject extends NamedObject {

    private constructor(name, fkField: SearchableField, label?: string) {
        super(name);
        this.fkField = fkField;
        this.label = label || name;
    }

    static readonly Retailer = new SearchableObject('Retailer', SearchableField.RetailerId);
    static readonly RetailerRegion = new SearchableObject('RetailerRegion', SearchableField.RetailerRegionId, 'Region');
    static readonly ProductCategory = new SearchableObject('ProductCategory', SearchableField.ProductCategoryId, 'Product Category');
    static readonly BrandPartner = new SearchableObject('BrandPartner', SearchableField.BrandId, 'Brand');
    static readonly ParentBrand = new SearchableObject('ParentBrand', SearchableField.BrandId, 'Parent Company');
    static readonly BrandPartnerProduct = new SearchableObject('BrandPartnerProduct', SearchableField.BrandId, 'Brand Product');
    static readonly Store = new SearchableObject('Store', SearchableField.StoreId);
    static readonly StoreGroup = new SearchableObject('StoreGroup', SearchableField.Id);
    static readonly Device = new SearchableObject('Device', SearchableField.Id);
    static readonly DeviceSlotImage = new SearchableObject('DeviceSlotImage', SearchableField.DeviceSlotImageId, 'Device Slot Image');
    static readonly DeviceSlot = new SearchableObject('DeviceSlot', SearchableField.DeviceSlotId, 'Device Slot');
    static readonly BrandProduct = new SearchableObject('BrandProduct', SearchableField.ProductId);
    static readonly PromoProgram = new SearchableObject('PromoProgram', SearchableField.Id, 'Program');
    static readonly MediaFileVariant = new SearchableObject('MediaFileVariant', SearchableField.Id, 'Media File Variant');
    static readonly BrandPromoCampaign = new SearchableObject('BrandPromoCampaign', SearchableField.Id, 'Brand Promo Campaign');
    static readonly PromoPeriod = new SearchableObject('PromoPeriod', SearchableField.PromoPeriodId, 'Promo Period');
    static readonly User = new SearchableObject('User', SearchableField.Id, 'User');
    static readonly RetailerDivision = new SearchableObject('RetailerDivision', SearchableField.Id, 'Division');

    static readonly ALL = [
        SearchableObject.Retailer,
        SearchableObject.RetailerRegion,
        SearchableObject.ProductCategory,
        SearchableObject.Store,
        SearchableObject.StoreGroup,
        SearchableObject.DeviceSlot,
        SearchableObject.DeviceSlotImage,
        SearchableObject.BrandProduct,
        SearchableObject.BrandPartner,
        SearchableObject.ParentBrand,
        SearchableObject.PromoProgram,
        SearchableObject.PromoPeriod,
        SearchableObject.BrandPromoCampaign,
        SearchableObject.User,
        SearchableObject.RetailerDivision,
    ];

    public readonly fkField: SearchableField;
    public readonly label: string;

    static lookup(value: SearchableObjectType) {
        if (value instanceof SearchableObject) {
            return value
        }
        return SearchableObject.byName(value);
    }

    static byType(value: SearchableObjectType): SearchableObject {
        return SearchableObject.lookup(value);
    }

    static byName(name: any): SearchableObject {
        name = name + '';
        return SearchableObject.ALL.find(value => value.name == name);
    }
}


export class SearchFieldCriteria {

    static newTextContainsCriteria(text: string): SearchFieldCriteria {
        return new SearchFieldCriteria(SearchableField.Text, FilterConditionOperator.Contains, [NamedValue.from(text)])
    }

    static newEqualsCriteria(field: SearchableField, id: number | string | NamedValue): SearchFieldCriteria {
        if (!(id instanceof NamedValue)) {
            id = NamedValue.from(id + '')
        }
        return new SearchFieldCriteria(field, FilterConditionOperator.Eq, [id])
    }

    static newInCriteria(field: SearchableField, ids: any[]): SearchFieldCriteria {

        const values: NamedValue[] = [];
        if (Array.isArray(ids)) {
            for (const id of ids) {
                if ((id as any) instanceof NamedValue) {
                    values.push(id)
                } else if (typeof id == 'string' || typeof id === 'number') {
                    values.push(NamedValue.from(id))
                }
            }
        }

        return new SearchFieldCriteria(field, FilterConditionOperator.In, values)
    }

    constructor(public field: SearchableField, public operator: FilterConditionOperator, public value: NamedValue[]) {

    }

    asArray(): SearchFieldCriteria[] {
        return [this];
    }
}

export class SearchFieldCriteriaBundle {
    public filters: SearchFieldCriteria[] = [];

    get(field: SearchableField | SearchableObject | SearchableObjectType): NamedValue {
        if (Utils.isString(field)) {
            const searchObjectType = SearchableObject.lookup(field as SearchableObjectType);
            if (searchObjectType) {
                field = searchObjectType
            }
        }
        if (field instanceof SearchableObject) {
            field = field.fkField
        }
        for (const f of this.filters) {
            if ((f.field === field) && (f.operator === FilterConditionOperator.Eq)) {
                if (f.value && f.value.length) {
                    return f.value[0]
                }
            }
        }
        return null
    }


    add(field: SearchableField | SearchableObject, value: number | string | NamedValue): void {
        if (field instanceof SearchableObject) {
            field = field.fkField
        }
        if (!(value instanceof NamedValue)) {
            value = NamedValue.from(value + '')
        }
        this.filters.push(new SearchFieldCriteria(field, FilterConditionOperator.Eq, [value]))
    }

    set(field: SearchableField | SearchableObject, value: number | string | NamedValue): void {
        this.removeAll(field)
        this.add(field, value)
    }

    removeAll(field: SearchableField | SearchableObject) {
        if (field instanceof SearchableObject) {
            field = field.fkField
        }

        this.filters = this.filters.filter(value => value.field != field)
    }

    get isEmpty(): boolean {
        return !this.filters || this.filters.length === 0
    }
}
