import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DomService {

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
    ) {
    }

    appendComponentToBody<T>(component: Type<T>): ComponentRef<T> {
        // create a component reference
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

        // attach component to the appRef so that so that it will be dirty checked.
        this.applicationRef.attachView(componentRef.hostView);

        // get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);

        return componentRef;
    }

    removeComponentFromBody(componentRef: ComponentRef<any>): void {
        if (componentRef) {
            this.applicationRef.detachView(componentRef.hostView);
            componentRef.destroy();
        }
    }
}
