import {Type} from '@angular/core';
import {NamedValue} from '@looma/shared/types/named_value';
import {NamedIdentifiable} from '@looma/shared/types/identifiable';
import {WordFilterable} from '@looma/shared/types/word_filterable';
import {Assignable} from '@looma/shared/types/assignable';
import {Utils} from '@looma/shared/utils';

const displayNameProps = ['name', 'display_name', 'displayName'];


export abstract class BaseModel extends Assignable implements NamedIdentifiable, WordFilterable {

    static unwrap<T extends BaseModel>(instance: T): T {
        while (instance && Utils.isFunction(instance.unwrap)) {
            if (instance instanceof BaseModel) {
                return instance
            }
            const newInstance = (instance as T).unwrap();
            if ((newInstance === instance)) {
                return instance
            }
            instance = newInstance;
        }
    }

    // deprecated . Use assignTypedObject instead
    assignNested(src: any, srcPropertyName: string, dest: BaseModel, thisPropertyName?: string): this {
        if (src && src.hasOwnProperty(srcPropertyName)) {
            if (src[srcPropertyName] != null && typeof src[srcPropertyName] !== 'undefined') {
                dest.assign(src[srcPropertyName]);
                this[thisPropertyName || srcPropertyName] = dest;
            }

        }
        return this;
    }

    assignTypedObject<T extends BaseModel>(src: any, srcPropertyName: string, typeofT: Type<T>, thisPropertyName?: string): this {
        if (src && src.hasOwnProperty(srcPropertyName)) {
            if (src[srcPropertyName] != null && typeof src[srcPropertyName] !== 'undefined') {
                this[thisPropertyName || srcPropertyName] = new typeofT().assign(src[srcPropertyName]);
            }

        }
        return this;
    }

    assignTypedArray<T extends BaseModel>(src: any, srcPropertyName: string, typeofT: Type<T>, thisPropertyName?: string): this {
        if (!src) {
            return this;
        }

        thisPropertyName = thisPropertyName || srcPropertyName;

        this[thisPropertyName] = [];
        if (Array.isArray(src[srcPropertyName])) {
            this[thisPropertyName] = (src[srcPropertyName] as any[]).map(value => {
                return new typeofT().assign(value)
            })
        }

        return this
    }

    assignDate(src: any, srcPropertyName: string, thisPropertyName?: string): this {
        let d: Date | undefined;

        if (src && src.hasOwnProperty(srcPropertyName) && src[srcPropertyName] !== null) {
            const parsed = new Date(src[srcPropertyName]);
            if (!isNaN(parsed.getTime())) {
                d = parsed;
            }
        }

        this[thisPropertyName || srcPropertyName] = d;

        return this;
    }


    getId(): number {
        const strId = this.getStringId();
        if (strId != '') {
            return parseInt(this['id'], 10)
        }
        return NaN;
    }

    getStringId(): string {
        const obj = this as any
        if (obj.hasOwnProperty('id')) {
            const id = obj.id
            if (Utils.isNumber(id) || Utils.isString(id)) {
                return id + ''
            }
        }
        return ''
    }

    getNestedValue(...paths: string[]): any {
        let obj = this as any;
        let prop = paths.shift();
        for (; obj && prop; obj = obj[prop], prop = paths.shift()) {
            if (!obj.hasOwnProperty(prop)) {
                obj = null;
            }
        }
        return obj
    }

    getDisplayName(): string {
        for (const prop of displayNameProps) {
            if (this.hasOwnProperty(prop)) {
                return this[prop] + ''
            }
        }
        return undefined;
    }

    getPhrases(): string[] {
        return [this.getDisplayName()];
    }

    isNewRecord(): boolean {
        const id = this.getId();
        return isNaN(id) || id <= 0;
    }


    namedValue(): NamedValue {
        let v = namedValues.get(this);
        if (!v) {
            v = NamedValue.from(this.getId(), this.getDisplayName());
            namedValues.set(this, v);
        }
        return v;
    }

    unwrap(): this {
        return this
    }

    clone(): any {
        const raw = JSON.parse(JSON.stringify(this));
        const instance = new (this.constructor as Type<any>)();
        instance.assign(raw);
        return instance
    }

}

const namedValues = new WeakMap<BaseModel, NamedValue>();
