import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MediaContentsListComponent} from './media-contents-list/media-contents-list.component';
import {MediaContentTypeListComponent} from './media-content-type-list/media-content-type-list.component';
import {AppCommonModule} from "../../shared/common.module";
import {RouterModule} from "@angular/router";
import {LayoutModule} from "../../layout/layout.module";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatBadgeModule} from "@angular/material/badge";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTreeModule} from "@angular/material/tree";
import {MediaContentEditComponent} from './media-content-edit/media-content-edit.component';
import {GenericModule} from "../generic/generic.module";
import {MediaContentPickerDialogComponent} from "./media-content-picker-dialog/media-content-picker-dialog.component";


@NgModule({
  declarations: [MediaContentsListComponent, MediaContentTypeListComponent, MediaContentEditComponent, MediaContentPickerDialogComponent],
    imports: [
        CommonModule,
        AppCommonModule,
        RouterModule,
        LayoutModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatTreeModule,
        GenericModule
    ]
})
export class MediaContentModule {
}
