import {Component, OnInit} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {LayoutService} from "../../../services/layout.service";
import {ApiDataService} from "../../../services/api-data.service";
import {VideoPlayerService} from "../../../services/video-player.service";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {ToastNotificationService} from "../../../services/toast-notification.service";
import {Observable, Subscription} from "rxjs";
import gql from "graphql-tag";
import {
    MediaPlaylist,
    MediaPlaylistMutationInput,
    MediaPlaylistStatus,
    MediaPlaylistVersionEntry
} from "@looma/shared/models/media_playlist";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {ActivatedRoute} from "@angular/router";
import {moveItemInArray} from "@angular/cdk/drag-drop";
import {MediaPlaylistVersionDialogComponent} from "../../retailer-promo-campaigns/components/media-playlist-version-dialog/media-playlist-version-dialog.component";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {MediaContentVersion} from "@looma/shared/models/media_content_version";
import {MediaContentPickerDialogComponent} from "../../media-content/media-content-picker-dialog/media-content-picker-dialog.component";

@LifecycleHooks()
@Component({
    selector: 'app-media-playlist-assignment',
    templateUrl: './media-playlist-assignment.component.html',
    styleUrls: ['./media-playlist-assignment.component.scss']
})
export class MediaPlaylistAssignmentComponent implements OnInit {

    playlistEntries: MediaPlaylistVersionEntry[] = [];
    mediaPlaylist: MediaPlaylist
    hasDirtyAssignment: boolean
    private playlistMutateSubscription: Subscription

    pageTitle = ''

    private readonly uploadSession: UploadSession = this.svcUpload.getUploadSession('media_playlist_session', {
        fileTypes: ['image', 'video'],
        multiSelection: false
    });

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private svcVideoPlayer: VideoPlayerService,
        private svcUpload: UploadService,
        private svcToastNotif: ToastNotificationService,
        private activatedRoute: ActivatedRoute,
        private domSanitizer: DomSanitizer,
    ) {

    }

    ngOnInit(): void {

        const id = this.activatedRoute.snapshot.paramMap.get('id') || '';

        if (id != '') {
            this.svcApi.rawQuery({
                query: FETCH_PLAYLIST_GQL_QUERY,
                variables: {
                    playlistId: id
                }
            }).subscribe(value => {
                const feed = CursorFeed.create(value.data['mediaPlaylists'], MediaPlaylist, 'data');
                this.setPlaylist(feed.data[0])
            })
        }


    }

    addLibraryItem() {
        MediaContentPickerDialogComponent.open(this.svcLayout).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (!value) {
                return;
            }
            this.addPlaylistItems(value.defaultMediaContentVersion)
        });
    }

    uploadItem() {
        this.uploadSession.openPicker()
    }

    private createPlaylistEntryForVersion(id: string | number) {

    }

    private addPlaylistItems(...versions: MediaContentVersion[]): boolean {
        let changed = false
        for (const version of versions) {
            if (version instanceof MediaContentVersion) {
                if (isNaN(parseInt(version.id))) {
                    continue
                }
                const entry = new MediaPlaylistVersionEntry()
                entry.mediaContentVersion = version
                this.playlistEntries.push(entry)
                changed = true
                this.hasDirtyAssignment = true
            }
        }

        return changed
    }

    get isPublishAvailable(): boolean {
        if (!this.mediaPlaylist) {
            return false
        }
        if (!Utils.isUnsubscribed(this.playlistMutateSubscription)) {
            return false
        }
        if (this.hasDirtyAssignment) {
            return false
        }
        if (!this.playlistEntries.length) {
            return false
        }
        if (this.mediaPlaylist?.latestVersion?.status == MediaPlaylistStatus.Draft) {
            return true
        }
        return false
    }

    publishMediaPlaylist() {
        this.subscribeToPlaylistMutation(
            this.svcApi.rawObjectMutate(PUBLISH_PLAYLIST, {playlistId: this.mediaPlaylist.id}, MediaPlaylist), "Playlist Published"
        )
    }

    get isSaveAvailable(): boolean {
        if (!this.mediaPlaylist) {
            return false
        }
        if (!Utils.isUnsubscribed(this.playlistMutateSubscription)) {
            return false
        }
        if (!this.hasDirtyAssignment) {
            return false
        }

        return true
    }

    saveMediaPlaylist() {
        const data: Partial<MediaPlaylistMutationInput> = {
            id: this.mediaPlaylist.getStringId(),
            entries: this.playlistEntries.map(value => {
                return {mediaContentVersionId: value.mediaContentVersion.getId()}
            })
        }
        
        this.subscribeToPlaylistMutation(
            this.svcApi.mutateMediaPlaylist(MutationOperation.Update,  data, MEDIA_PLAYLIST_GQL_FRAGMENT),
            "Playlist Saved"
        )

    }

    private subscribeToPlaylistMutation(src: Observable<MutationResponse<MediaPlaylist>>, successMessage: string) {
        this.playlistMutateSubscription = src.pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.setPlaylist(value.data)
                this.svcLayout.showSnackMessage(successMessage)
            } else {
                this.svcLayout.showMutationResultMessage(value)
            }
        }, error => {
            this.svcLayout.showSnackMessage('Unexpected error')
        });
    }

    changePlaylistPosition(fromIndex: number, toIndex: number) {
        if (fromIndex != toIndex) {
            moveItemInArray(this.playlistEntries, fromIndex, toIndex);
            this.hasDirtyAssignment = true
        }
    }

    removePlaylistItem(entry: MediaPlaylistVersionEntry) {
        const idx = this.playlistEntries.indexOf(entry)
        if (idx >= 0) {
            this.playlistEntries.splice(idx, 1)
            this.playlistEntries = [].concat(this.playlistEntries)
            this.hasDirtyAssignment = true
        }
    }

    playPlaylistEntry(entry: MediaPlaylistVersionEntry) {
        this.svcVideoPlayer.play(entry.mediaContentVersion.processedUrl)
    }

    private setPlaylist(pl: MediaPlaylist) {
        this.mediaPlaylist = pl;
        this.playlistEntries = this.mediaPlaylist?.latestVersion?.playlistEntries || [];
        this.hasDirtyAssignment = false
        this.pageTitle = `Playlist ${pl.name}`
    }

    viewActivePlaylist() {
        if (this.mediaPlaylist?.activeVersion?.id) {
            MediaPlaylistVersionDialogComponent.open(this.svcLayout, this.mediaPlaylist.id, this.mediaPlaylist.activeVersion.id)
        }
    }

    getBackgroundThumbImage(entry: MediaPlaylistVersionEntry): SafeStyle {
        const imageUrl = entry?.mediaContentVersion?.thumbnail || Utils.BLANK_IMAGE;

        return this.domSanitizer.bypassSecurityTrustStyle(` url(${imageUrl})`);
    }


}

const MEDIA_PLAYLIST_GQL_FRAGMENT = gql`
fragment MediaPlaylistFields on MediaPlaylist {
  id
  name
  activeVersion {
    id
    firebasePath
  }
  latestVersion {
    id
    name
    checksum
    status
    firebasePath
    playlistEntries {
      id
      playlistIndex
      mediaContentVersion {
        id
        fileName
        thumbnail
        processedUrl
        mediaContent{
          id
          displayName
          contentType
        }
      }
    }
  }
}

`

const FETCH_PLAYLIST_GQL_QUERY = gql`
${MEDIA_PLAYLIST_GQL_FRAGMENT}
query fetchMediaPlaylists($playlistId:ID!){
  mediaPlaylists(filter:{standalone:true, id:$playlistId}){
    cursor
    data: mediaPlaylists{
      ...MediaPlaylistFields
    }
  }
}
`

const PUBLISH_PLAYLIST = gql`
${MEDIA_PLAYLIST_GQL_FRAGMENT}
mutation publish($playlistId:ID!){
  publishMediaPlaylist(playlistId: $playlistId) {
    success
    message
    mediaPlaylist {
      ...MediaPlaylistFields
    }
  }
}
`;
