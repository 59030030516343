import {Injectable} from '@angular/core';
import {Apollo, QueryRef} from 'apollo-angular';
import {from, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Device} from '@looma/shared/models/device';
import {HttpClient} from '@angular/common/http';
import {Utils} from '@looma/shared/utils';
import {BrandPartner, BrandPartnerInput} from '@looma/shared/models/brand_partner';
import {Retailer, RetailerFeedFilter, RetailerInput} from '@looma/shared/models/retailer';
import {ApiResponse} from '@looma/shared/models/api_response';
import {DevicePowerCycleLog} from '@looma/shared/models/device_power_cycle_log';
import {NamedValue} from '@looma/shared/types/named_value';
import {DeviceFilterableField, DeviceSearchCriteria} from '@looma/shared/models/device_search';
import {DeviceCommand} from '@looma/shared/types/device_commands';
import {DeviceCommandBundleResult, DeviceCommandResult} from '@looma/shared/models/device_command_bundle_result';
import * as queries from './queries';
import {MUTATION_RESPONSE_FIELDS} from './queries';
import {ProductCategory} from '@looma/shared/models/product_category';
import {Store, StoreFeedFilter, StoreMutationInput} from '@looma/shared/models/store';
import {RetailerCampaignLibraryFile} from '@looma/shared/models/retailer_campaign_library_file';
import {RetailerCampaign} from '@looma/shared/models/retailer_campaign';
import {CursorFeed} from '@looma/shared/cursor_feed';
import {
    DeviceSlotImage,
    DeviceSlotImageFilter,
    DeviceSlotImageMutationInput
} from '@looma/shared/models/device_slot_image';
import {DeviceSlotImageScore} from '@looma/shared/models/device_slot_image_score';
import {MutationResponse} from '@looma/shared/types/mutation_response';
import {User, UserMutationData} from '@looma/shared/models/user';
import {MutationOperation} from '@looma/shared/models/mutation_operation';
import {BrandProduct, ProductInput} from '@looma/shared/models/brand_product';
import {DocumentNode} from 'graphql';
import {DeviceSlotType, DeviceSlotTypeMutationInput} from '@looma/shared/models/device_slot_type';
import gql from 'graphql-tag';
import {DeviceSlot, DeviceSlotMutationInput, DeviceSlotSearchCriteria} from '@looma/shared/models/device_slot';
import {RetailerPromoSchedule, RetailerPromoScheduleMutationInput} from '@looma/shared/models/retailer_promo_schedule';
import {RetailerPromoPeriod, RetailerPromoPeriodMutationInput} from '@looma/shared/models/retailer_promo_periods';
import {BrandPromoCampaign, BrandPromoCampaignMutationInput} from '@looma/shared/models/brand_promo_campaign';
import {PromoPlaylistAssignment} from '@looma/shared/models/promo_playlist_assignment';
import {MediaFileFilmVariable} from '@looma/shared/models/media_file_film_variable';
import {LoomaAuthService} from '@looma/shared/auth/components/services/looma-auth.service';
import {DeviceSlotImagePhotoMutationInput} from "@looma/shared/models/device_slot_image_photo";
import {
    BrandCampaignReportFilter
} from "../main/campaign-reports/campaign-reports-list/campaign-reports-list.component";
import {
    BrandPromoCampaignReport,
    BrandPromoCampaignReportInput
} from "@looma/shared/models/brand_promo_campaign_report";
import {MediaPlaylist, MediaPlaylistMutationInput} from "@looma/shared/models/media_playlist";
import {UserAccessPermission, UserRole, UserRoleInput} from "@looma/shared/models/user_role";
import {UserRoleFilter} from "../main/users/components/user-role-list/user-role-list.component";
import {
    EvaluatedPromoPlaylistScheduleEntry,
    PromoPlaylistScheduleEntry,
    PromoPlaylistScheduleEntryMutationInput
} from "@looma/shared/models/promo_playlist_schedule_entry";
import {DeviceSlotSegment, DeviceSlotSegmentInput} from "@looma/shared/models/device_slot_segment";
import {
    PromoPlaylistMediaBundle,
    PromoPlaylistMediaBundleMutationInput
} from "@looma/shared/models/PromoPlaylistMediaBundle";
import {RemoteDataService} from "@looma/shared/services/remote_data_service";
import {StringPair} from "../../../../loop-dashboard/src/app/models/string_pair";

type NumberOrString = number | string

@Injectable({
    providedIn: 'root'
})
export class ApiDataService extends RemoteDataService {

    static ID_ANY = 'NaN';
    static ID_NONE = '-1';

    constructor(
        apollo: Apollo,
        private http: HttpClient,
        private authService: LoomaAuthService
    ) {
        super(apollo)
    }

    public setMyMessagingToken(token: string): Observable<boolean> {
        const userData: UserMutationData = {messagingToken: token};
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPDATE_USER,
            variables: {id: 'me', data: userData}
        }).pipe(
            this.mapTypedMutationResponse(User, 'user', 'data', 'result'),
            map((value: MutationResponse<User>) => value.success)
        );
    }

    getDeviceList(criteria: DeviceSearchCriteria | null | undefined): Observable<CursorFeed<Device>> {
        return this.apollo.query<Response>({
            query: queries.QUERY_FETCH_DEVICE_LIST,
            variables: {
                criteria: (criteria || new DeviceSearchCriteria()).toPlainObject()
            }
        }).pipe(
            map(v => {
                const rawData = Utils.getNestedObject(v, 'data', 'devices');
                const c = CursorFeed.create(rawData, Device, 'data')
                return c;
            })
        )
    }

    watchCommandResults(): Observable<DeviceCommandBundleResult[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_COMMAND_RESULTS,
        }).pipe(
            this.mapTypedQueryResponseArray(DeviceCommandBundleResult, 'device_command_results')
        )
    }

    getCommandResult(cmdId?: string, deviceId?: string): Observable<DeviceCommandBundleResult[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_COMMAND_RESULTS,
            variables: {
                cmdId: cmdId,
                deviceId: deviceId,
            },
            fetchPolicy: 'no-cache'
        }).pipe(
            this.mapTypedQueryResponseArray(DeviceCommandBundleResult, 'device_command_results')
        )
    }

    getCommandResultsWithDevices(cmdId: string, deviceId?: string): Observable<DeviceCommandResult[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_COMMAND_RESULTS_WITH_DEVICES,
            variables: {cmdId: cmdId, deviceId: deviceId}
        }).pipe(
            map(value => {
                return new DeviceCommandResult().assign(value.data['device_command_results'][0])['device_results']
            })
        )
    }

    queryDevice(deviceId: string): QueryRef<Response> {
        return this.apollo.watchQuery<Response>({
            query: queries.QUERY_FETCH_DEVICE_FRESH,
            variables: {deviceId: deviceId},
            fetchPolicy: 'network-only'
        });
    }

    queryDevicePowerCycleLogs(deviceId: string): Observable<DevicePowerCycleLog[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_DEVICE_POWER_LOGS,
            variables: {deviceId: deviceId}
        }).pipe(
            map(value => {
                return new Device().assign(value.data['devices'][0]).power_cycle_logs
            })
        )
    }

    sendCommand(command: DeviceCommand, criteria: DeviceSearchCriteria, commandArg?: string): Observable<DeviceCommandBundleResult> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_SEND_DEVICE_BATCH_COMMAND,
            variables: {commandName: command.key, commandArgs: commandArg || '', audience: criteria.toPlainObject()}
        }).pipe(
            this.mapTypedQueryResponseObject(DeviceCommandBundleResult, 'sendDeviceCommand')
        );
    }

    sendDeviceCommand(device: Device, command: DeviceCommand, commandArg?: string): Observable<DeviceCommandBundleResult> {
        return this.sendCommand(command, DeviceSearchCriteria.forDeviceId(device.id), commandArg)
    }

    saveFilter(name: string, criteria: DeviceSearchCriteria): Observable<any> {

        const filters = criteria.filters.map(filter => {
            return {
                field: filter.deviceField.key,
                operator: filter.operator.key,
                value: filter.values.map(value => {
                    return {value: value.value, name: value.name}
                }),
            }
        });


        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_SAVE_FILTER,
            variables: {name: name, filters: filters}
        }).pipe(
            map(value => value.data['saveFilter'] as { id: string }),
        );
    }

    queryDevicesForMap(): Observable<Device[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_DEVICES_FOR_MAP,
        }).pipe(
            this.mapTypedQueryResponseArray(Device, 'devices', 'data')
        );
    }

    getProductCategories(): Observable<ProductCategory[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_PRODUCT_CATEGORIES,
        }).pipe(
            this.mapTypedQueryResponseArray(ProductCategory, 'product_categories')
        );
    }

    getBrandPartners(filter?: any): Observable<BrandPartner[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_BRAND_PARTNERS,
            variables: {criteria: filter}
        }).pipe(
            this.mapTypedQueryResponseArray(BrandPartner, 'brand_partners')
        );
    }

    upsertBrandPartner(operation: MutationOperation, data: BrandPartnerInput, brandGqlFields: string): Observable<MutationResponse<BrandPartner>> {
        const input = {
            operation: operation,
            brandPartnerInput: data
        };

        const mutation = gql`
            mutation upsertMasterData($input:UpsertMasterDataInput!) {
                result: upsertMasterData(input: $input) {
                    ${MUTATION_RESPONSE_FIELDS}
                    brand_partner {
                        ${brandGqlFields}
                    }
                }
            }
        `
        return this.apollo.mutate<Response>({
            mutation: mutation,
            variables: {input: input},
        }).pipe(
            this.mapTypedMutationResponse(BrandPartner, 'brand_partner', 'data', 'result'),
        );
    }

    getProducts(productSearchCriteria: any): Observable<CursorFeed<BrandProduct>> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_PRODUCTS,
            variables: {criteria: productSearchCriteria},
        }).pipe(
            map(value => {
                    const rawData = value.data['products'] as any;
                    return CursorFeed.create(rawData, BrandProduct, 'data')
                }
            ))
    }

    upsertProduct(operation: MutationOperation, data: ProductInput): Observable<MutationResponse<BrandProduct>> {
        const input = {
            operation: operation,
            brandProductInput: data
        }
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPSERT_PRODUCT,
            variables: {input: input},
        }).pipe(
            this.mapTypedMutationResponse(BrandProduct, 'brand_product', 'data', 'result'),
        );
    }

    upsertRetailer(operation: MutationOperation, data: RetailerInput): Observable<MutationResponse<Retailer>> {
        const input = {
            operation: operation,
            retailerInput: data
        }
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPSERT_RETAILER,
            variables: {input: input},
        }).pipe(
            this.mapTypedMutationResponse(Retailer, 'retailer', 'data', 'result'),
        );
    }

    getRetailers(): Observable<Retailer[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_RETAILERS_FOR_SELECT,
        }).pipe(
            this.mapTypedQueryResponseArray(Retailer, 'retailers')
        );
    }

    getUsers(userFilter: any): Observable<CursorFeed<User>> {
        return this.apollo.query<Response>({
            query: queries.QUERY_FETCH_USERS,
            variables: {userFilter: userFilter}
        }).pipe(
            map(value => {
                const rawData = value.data['users'] as any;
                return CursorFeed.create(rawData, User, 'data')
            })
        )
    }

    getUserRoles(criteria?: UserRoleFilter): Observable<CursorFeed<UserRole>> {
        return this.rawQuery({
            query: queries.QUERY_FETCH_USER_ROLES,
            variables: {criteria: criteria},
        }).pipe(
            this.mapTypedQueryResponseFeed(UserRole, 'userRoles'),
        )
    }

    upsertUserRole(action: MutationOperation, data: UserRoleInput): Observable<MutationResponse<UserRole>> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPSERT_USER_ROLE,
            variables: {
                op: action,
                data: data,
            },
        }).pipe(
            this.mapTypedMutationResponse(UserRole, 'userRole', 'data', 'result'),
        );
    }

    getUserAccessPermissions(): Observable<UserAccessPermission[]> {
        return this.rawQuery({
            query: queries.QUERY_FETCH_USER_ACCESS_PERMISSIONS,
        }).pipe(
            this.mapTypedQueryResponseArray(UserAccessPermission, 'userAccessPermissions'),
        )
    }

    getFilmVariables(id: string): Observable<CursorFeed<MediaFileFilmVariable>> {
        return this.apollo.query<Response>({
            query: queries.QUERY_FETCH_MEDIA_FILES_FILM_VARIABLES,
            variables: {id: id},
            fetchPolicy: 'no-cache'
        }).pipe(
            map(value => {
                    const rawData = value.data['media_file_film_variables'] as any;
                    return CursorFeed.create(rawData, MediaFileFilmVariable, 'data')
                }
            ))
    }

    uploadMediaFileFilmVariables(id: string, gsFileLocation: string): Observable<ApiResponse> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPLOAD_MEDIA_FILE_FILM_VARIABLES,
            variables: {mediaFileId: id, gsFileLocation: gsFileLocation},
        }).pipe(
            this.mapTypedQueryResponseObject(ApiResponse, 'uploadMediaFileVariables')
        );
    }

    updateMediaFileFilmVariable(variableId: string, variableValue: string, filmId: string, filmType: string): Observable<MutationResponse<MediaFileFilmVariable>> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPDATE_MEDIA_FILE_VARIABLE,
            variables: {variableId: variableId, variableValue: variableValue, filmId: filmId, filmType: filmType},
        }).pipe(
            this.mapTypedMutationResponse(MediaFileFilmVariable, 'media_file_film_variable', 'data', 'result'),
        );
    }

    deleteMediaFileFilmVariable(id: string): Observable<MutationResponse<MediaFileFilmVariable>> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_DELETE_MEDIA_FILE_VARIABLE,
            variables: {id: id},
        }).pipe(
            this.mapTypedMutationResponse(MediaFileFilmVariable, 'media_file_film_variable', 'data', 'result'),
        );
    }

    createUser(data: UserMutationData): Observable<MutationResponse<User>> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_CREATE_USER,
            variables: {data: data},
        }).pipe(
            this.mapTypedMutationResponse(User, 'user', 'data', 'result'),
        );
    }

    updateUser(id: string, data: UserMutationData): Observable<MutationResponse<User>> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPDATE_USER,
            variables: {id: id, data: data},
        }).pipe(
            this.mapTypedMutationResponse(User, 'user', 'data', 'result'),
        );
    }

    deleteUser(id: string): Observable<MutationResponse<User>> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_DELETE_USER,
            variables: {id: id},
        }).pipe(
            this.mapTypedMutationResponse(User, 'user', 'data', 'result'),
        );
    }

    getRetailersWithRegionsAndStoresAndProductCategories(): Observable<Retailer[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_RETAILERS_WITH_REGIONS_AND_STORES_AND_PRODUCT_CATEGORIES,
        }).pipe(
            this.mapTypedQueryResponseArray(Retailer, 'retailers')
        );
    }


    updateDevice(device: Device, data: Partial<DeviceUpdateData>): Observable<ApiResponse> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPDATE_DEVICE,
            // $deviceId: Int!, $storeId: Int!, retailerId: Int!, retailerRegionId: Int!, productCategoryId:Int!, deviceNumber:Int!
            variables: {
                deviceId: device.id,
                data: data,
            }
        }).pipe(
            this.mapTypedQueryResponseObject(ApiResponse, 'updateDevice')
        );
    }

    unassignDevice(device: Device): Observable<ApiResponse> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UNASSIGN_DEVICE,
            variables: {
                deviceId: device.id
            }
        }).pipe(
            this.mapTypedQueryResponseObject(ApiResponse, 'unassignDevice')
        );
    }

    restartRemoteControlApp(deviceId: string): Observable<ApiResponse> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_RESTART_REMOTE_CONTROL_APP,
            variables: {
                deviceId: deviceId
            }
        }).pipe(
            this.mapTypedQueryResponseObject(ApiResponse, 'restartDeviceRemoteControlApp')
        );
    }

    replaceDevice(device: Device, replacementId): Observable<ApiResponse> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_REPLACE_DEVICE,
            variables: {
                deviceId: device.id,
                replacementId: replacementId,
            }
        }).pipe(
            this.mapTypedQueryResponseObject(ApiResponse, 'replaceDevice')
        );
    }


    searchDeviceFields(field: DeviceFilterableField, term: string): Observable<NamedValue[]> {
        if (!field) {
            return of([])
        }
        return this.apollo.query<Response>({
            query: queries.QUERY_SEARCH_DEVICE_FIELDS,
            variables: {field: field.key, term: term},
            fetchPolicy: 'no-cache'
        }).pipe(
            this.mapTypedQueryResponseArray(NamedValue, 'searchDeviceFields')
        );
    }


    getDeviceNextAssignationNumber(store: Store, cat: ProductCategory): Observable<number> {
        return this.apollo.query<Response>({
            query: queries.QUERY_NEXT_DEVICE_ASSIGNATION_NUMBER,
            variables: {storeId: store.id + '', productCategoryId: cat.id + ''},
            fetchPolicy: 'no-cache'
        }).pipe(
            map(value => {
                return value.data['next_device_assignation_number'] as number;
            })
        );
    }

    getDeviceSlotImages(filter: DeviceSlotImageFilter): Observable<CursorFeed<DeviceSlotImage>> {
        return this.apollo.query<Response>({
            query: queries.QUERY_FETCH_DEVICE_SLOT_IMAGES,
            variables: {filter: filter},
            fetchPolicy: 'no-cache'
        }).pipe(
            map(value => {
                const rawData = value.data['device_slot_images'] as any;
                return CursorFeed.create(rawData, DeviceSlotImage, 'images')
            })
        );
    }

    mutateDeviceSlotImagePhoto(deviceSlotImage: DeviceSlotImage, mutationInput: DeviceSlotImagePhotoMutationInput) {
        return this.rawObjectMutate(queries.MUTATION_MUTATE_DEVICE_SLOT_IMAGE_PHOTO, {
            deviceSlotImageId: deviceSlotImage.id,
            imageInput: mutationInput,
        }, DeviceSlotImage);
    }

    mutateDeviceSlotSegment(op: MutationOperation, input: DeviceSlotSegmentInput) {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPDATE_DEVICE_SLOT_SEGMENT,
            variables: {op: op, data: input},
        }).pipe(
            this.mapTypedMutationResponse(DeviceSlotSegment, 'deviceSlotSegment', 'data', 'result'),
        );
    }

    copyDeviceSlotSegments(segmentIds: string[], toPromoPeriodId: string) {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_COPY_DEVICE_SLOT_SEGMENT,
            variables: {segments: segmentIds, toPromoPeriodId: toPromoPeriodId},
        }).pipe(
            this.mapTypedMutationResponse(RetailerPromoPeriod, 'toPromoPeriodId', 'data', 'result'),
        );
    }

    loadCampaignLibraryFile(campaignId: NumberOrString, libraryFileId: NumberOrString): Observable<RetailerCampaignLibraryFile> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_CAMPAIGN_LIBRARY_FIELD,
            variables: {campaignId: campaignId + '', libraryFileId: libraryFileId + ''},
        }).pipe(
            map(value => {
                const x = new RetailerCampaign().assign(value.data['retailer_campaigns'][0]);
                return x.library_files[0];
            })
        )
    }

    addCampaignLibraryFile(campaignId: any, fileName: string, gsFileLocation: string): Observable<RetailerCampaignLibraryFile> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_ADD_CAMPAIGN_LIBRARY_FILE,
            variables: {campaignId: campaignId + '', fileName: fileName, gsFileLocation: gsFileLocation},
        }).pipe(
            this.mapTypedQueryResponseObject(RetailerCampaignLibraryFile, 'addCampaignLibraryFile')
        );
    }

    updateCampaignLibraryFile(campaignId: number, fileId: number, brandPartnerId: string, productCategoryId: number): Observable<RetailerCampaignLibraryFile> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPDATE_CAMPAIGN_LIBRARY_FILE,
            variables: {
                campaignId: campaignId + '',
                fileId: fileId + '',
                brandPartnerId: brandPartnerId + '',
                productCategoryId: productCategoryId + ''
            },
        }).pipe(
            this.mapTypedQueryResponseObject(RetailerCampaignLibraryFile, 'updateCampaignLibraryFile')
        );
    }

    createRetailerCampaign(retailerId: NumberOrString, campaignName: string, startAt: Date, endAt: Date): Observable<RetailerCampaign> {
        const startAtStr = startAt ? startAt.toJSON() : null;
        const endDateStr = endAt ? endAt.toJSON() : null;

        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_CREATE_RETAILER_CAMPAIGN,
            variables: {retailerId: retailerId, campaignName: campaignName, startDate: startAtStr, endDate: endDateStr},
        }).pipe(
            map(value => {
                return this.assignDataObject(RetailerCampaign, value, 'createRetailerCampaign');
            })
        );
    }

    deleteRetailerCampaign(campaignId: NumberOrString): Observable<RetailerCampaign> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_DELETE_CAMPAIGN,
            variables: {campaignId: campaignId},
        }).pipe(
            map(value => {
                return this.assignDataObject(RetailerCampaign, value, 'deleteRetailerCampaign');
            })
        );
    }

    createRetailerCampaignDraftRollout(campaignId: NumberOrString, parentRolloutId: NumberOrString, versionName: string): Observable<RetailerCampaign> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_CREATE_CAMPAIGN_DRAFT_ROLLOUT,
            variables: {campaignId: campaignId, parentRolloutId: parentRolloutId, versionName: versionName},
        }).pipe(
            map(value => {
                return this.assignDataObject(RetailerCampaign, value, 'createRetailerCampaignDraftRollout');
            })
        );
    }

    deleteRetailerCampaignDraftRollout(campaignId: NumberOrString, rolloutId: NumberOrString): Observable<RetailerCampaign> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_DELETE_CAMPAIGN_DRAFT_ROLLOUT,
            variables: {campaignId: campaignId, childRolloutId: rolloutId},
        }).pipe(
            map(value => {
                return this.assignDataObject(RetailerCampaign, value, 'deleteRetailerCampaignDraftRollout');
            })
        );
    }

    loadCampaigns(campaignId?: NumberOrString, rolloutId?: NumberOrString): Observable<RetailerCampaign[]> {
        const policy = this.getFetchPolicy(queries.QUERY_GET_CAMPAIGNS);
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_CAMPAIGNS,
            variables: {
                campaignId: this.getStringIdVariable(campaignId),
                rolloutId: rolloutId,
            },
            fetchPolicy: policy
        }).pipe(
            this.mapTypedQueryResponseArray(RetailerCampaign, 'retailer_campaigns'),
            tap(x => this.setHasQuery(queries.QUERY_GET_CAMPAIGNS))
        );
    }

    loadCampaignRollout(campaignId: NumberOrString, rolloutId: NumberOrString): Observable<RetailerCampaign> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_CAMPAIGN_ROLLOUT,
            variables: {
                campaignId: this.getStringIdVariable(campaignId),
                rolloutId: this.getStringIdVariable(rolloutId),
            },
            fetchPolicy: 'no-cache'
        }).pipe(
            this.mapTypedQueryResponseArray(RetailerCampaign, 'retailer_campaigns'),
            map(value => Array.isArray(value) && value[0] || null),
            tap(x => this.setHasQuery(queries.QUERY_GET_CAMPAIGN_ROLLOUT))
        );
    }

    updateCampaignRollout(campaignId: any, rolloutId: any, data: CampaignUpdateInput): Observable<RetailerCampaign> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPDATE_CAMPAIGN_ROLLOUT,
            variables: {campaignId: campaignId + '', childRolloutId: rolloutId + '', data: data},
        }).pipe(
            map(value => {
                const d = value.data['updateCampaignRollout'] as any;
                return new RetailerCampaign().assign(d);
            }),
            tap(x => {
                this.invalidateQueries(queries.QUERY_GET_CAMPAIGN_ROLLOUT, queries.QUERY_GET_CAMPAIGNS)
            })
        );
    }

    bulkImport(ctx: string, gsFileLocation: string): Observable<ApiResponse> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_BULK_IMPORT,
            variables: {importContext: ctx, gsFileLocation: gsFileLocation},
        }).pipe(
            map(value => {
                const d = value.data['bulkImport'] as any;
                return new ApiResponse().assign(d);
            })
        );
    }

    getRetailersFeed(filter: RetailerFeedFilter): Observable<CursorFeed<Retailer>> {
        return this.rawQueryNoCache({
            query: queries.QUERY_RETAILERS_FEED,
            variables: {filter: filter},
        }).pipe(
            this.mapTypedQueryResponseFeed(Retailer, 'retailers_feed'),
        )
    }

    getStoresFeed(filter: StoreFeedFilter): Observable<CursorFeed<Store>> {
        return this.rawQueryNoCache({
            query: queries.QUERY_STORES_FEED,
            variables: {filter: filter},
        }).pipe(
            this.mapTypedQueryResponseFeed(Store, 'stores_feed'),
        )
    }

    upsertStore(operation: MutationOperation, data: StoreMutationInput): Observable<MutationResponse<Store>> {
        const input = {
            operation: operation,
            storeInput: data
        }
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_UPSERT_STORE,
            variables: {input: input},
        }).pipe(
            this.mapTypedMutationResponse(Store, 'store', 'data', 'result'),
        );
    }

    getBrandCampaignReports(filter: BrandCampaignReportFilter): Observable<CursorFeed<BrandPromoCampaignReport>> {
        return this.rawQueryNoCache({
            query: queries.QUERY_BRAND_CAMPAIGN_REPORTS_FEED,
            variables: {filter: filter},
        }).pipe(
            this.mapTypedQueryResponseFeed(BrandPromoCampaignReport, 'brand_promo_campaign_reports_feed'),
        )
    }

    approveCampaignReport(id: string): Observable<BrandPromoCampaignReport> {
        return this.apollo.mutate<Response>({
            mutation: queries.APPROVE_BRAND_CAMPAIGN_REPORT,
            variables: {
                id: id,
            },
        }).pipe(
            map(value => {
                return this.assignDataObject(BrandPromoCampaignReport, value, 'approve');
            })
        );
    }

    refreshCampaignReport(id: string): Observable<BrandPromoCampaignReport> {
        return this.apollo.mutate<Response>({
            mutation: queries.REFRESH_BRAND_CAMPAIGN_REPORT,
            variables: {
                id: id,
            },
        }).pipe(
            map(value => {
                return this.assignDataObject(BrandPromoCampaignReport, value, 'refresh');
            })
        );
    }

    sendNotificationEmailCampaignReport(id: number, emailRecipients: string[]): Observable<BrandPromoCampaignReport> {
        return this.apollo.mutate<Response>({
            mutation: queries.SEND_EMAIL_FOR__BRAND_CAMPAIGN_REPORT,
            variables: {
                id: id,
                emailRecipients: emailRecipients
            },
        }).pipe(
            map(value => {
                return this.assignDataObject(BrandPromoCampaignReport, value, 'sendNotificationEmail');
            })
        );
    }


    getDeviceSlotsFeed(filter: Partial<DeviceSlotSearchCriteria>): Observable<CursorFeed<DeviceSlot>> {
        return this.apollo.query<Response>({
            query: queries.QUERY_DEVICE_SLOT_FEED,
            variables: {filter: filter},
            fetchPolicy: 'no-cache'
        }).pipe(
            map(value => {
                const rawData = value.data['device_slots'];
                return CursorFeed.create(rawData, DeviceSlot, 'data');
            })
        )
    }

    mutateDeviceSlot(operation: MutationOperation, slotData: DeviceSlotMutationInput): Observable<MutationResponse<DeviceSlot>> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_MUTATE_DEVICE_SLOT,
            variables: {
                op: operation,
                data: slotData
            },
        }).pipe(
            this.mapTypedMutationResponse(DeviceSlot, 'device_slot', 'data', 'result'),
        );
    }

    getTestingDevices(): Observable<Device[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_TESTING_DEVICES,
            fetchPolicy: 'no-cache'
        }).pipe(
            this.mapTypedQueryResponseArray(Device, 'devices', 'devices'),
        );
    }

    mutateDeviceSlotImage(op: MutationOperation, data: Partial<DeviceSlotImageMutationInput>): Observable<MutationResponse<DeviceSlotImage>> {
        return this.apollo.mutate<Response>({
            mutation: queries.MUTATION_MUTATE_DEVICE_SLOT_IMAGE,
            variables: {data: data, op: op},
        }).pipe(
            this.mapTypedMutationResponse(DeviceSlotImage, 'image', 'data', 'result'),
        );
    }

    mutateDeviceSlotType(op: MutationOperation, data: DeviceSlotTypeMutationInput, gqlFields: string): Observable<MutationResponse<DeviceSlotType>> {
        const gqlQuery = gql`
            mutation mutateDeviceSlotType($data: DeviceSlotTypeMutationInput!) {
                mutateDeviceSlotType(op: ${op} data: $data) {
                    ${MUTATION_RESPONSE_FIELDS}
                    deviceSlotType {
                        ${gqlFields}
                    }
                }
            }
        `;
        return this.rawObjectMutate(gqlQuery, {
            data: data,
        }, DeviceSlotType);
    }

    mutatePromoSchedule(op: MutationOperation, data: RetailerPromoScheduleMutationInput, gqlFields?: string): Observable<MutationResponse<RetailerPromoSchedule>> {
        if (gqlFields == '') {
            gqlFields = 'id'
        }
        const gqlQuery = gql`
            mutation mutateRetailerPromoSchedule(
                $data: RetailerPromoScheduleMutationInput!
            ) {
                response: mutateRetailerPromoSchedule(op: ${op}, data: $data) {
                    ${MUTATION_RESPONSE_FIELDS}
                    retailerPromoSchedule {
                        ${gqlFields}
                    }
                }
            }
        `;
        return this.rawObjectMutate(gqlQuery, {
            data: data,
        }, RetailerPromoSchedule);
    }

    mutatePromoPeriod(op: MutationOperation, data: RetailerPromoPeriodMutationInput, gqlFields?: string): Observable<MutationResponse<RetailerPromoPeriod>> {
        if (!gqlFields) {
            gqlFields = 'id'
        }
        const gqlQuery = gql`
            mutation mutateRetailerPromoSchedule(
                $data: RetailerPromoPeriodMutationInput!
            ) {
                response: mutateRetailerPromoPeriod(op: ${op}, data: $data) {
                    ${MUTATION_RESPONSE_FIELDS}
                    retailerPromoPeriod {
                        ${gqlFields}
                    }
                }
            }
        `;
        return this.rawObjectMutate(gqlQuery, {
            data: data,
        }, RetailerPromoPeriod);
    }

    mutateBrandPromoCampaign(op: MutationOperation, data: BrandPromoCampaignMutationInput, gqlFields?: string): Observable<MutationResponse<BrandPromoCampaign>> {
        if (!gqlFields) {
            gqlFields = 'id'
        }
        const gqlQuery = gql`
            mutation mutateBrandPromoCampaign(
                $data: BrandPromoCampaignMutationInput!
            ) {
                response: mutateBrandPromoCampaign(op: ${op}, data: $data) {
                    ${MUTATION_RESPONSE_FIELDS}
                    brandPromoCampaign {
                        ${gqlFields}
                    }
                }
            }
        `;
        return this.rawObjectMutate(gqlQuery, {
            data: data,
        }, BrandPromoCampaign);
    }

    mutatePromoPlaylists(
        op: PromoPlaylistMutationOp,
        data: RecordMutationInput<PromoPlaylistAssignment, PromoPlaylistMutationInput>[],
        gqlFields?: string
    ): Observable<MutationResponse<PromoPlaylistAssignment>[]> {
        if (!gqlFields) {
            gqlFields = 'id'
        }

        const recordsMap = new Map<string, PromoPlaylistAssignment>()
        const variables: object = {};
        const mutations: string[] = [];
        const mutationsArgs: string[] = [];
        let idx = 0;
        for (const dataEntry of data) {
            idx += 1;
            const mutationKey = `mutation_${idx}`
            const dataKey = `data_${mutationKey}`
            recordsMap.set(mutationKey, dataEntry.record)

            const mutation = `
${mutationKey} :mutatePromoPlaylist(op: ${op}, data: $${dataKey}){
    ${MUTATION_RESPONSE_FIELDS}
    promoPlaylistAssignment {
      ${gqlFields}
    }
}    
`
            mutations.push(mutation)
            mutationsArgs.push(`$${dataKey}:PromoPlaylistMutationInput!`)

            variables[dataKey] = dataEntry.mutationInput
        }

        const mutationQuery = `
mutation mutatePromoPlaylist(${mutationsArgs.join(',')}){
  ${mutations.join('\n')}
}
`
        return this.apollo.mutate<Response>({
            mutation: gql(mutationQuery),
            variables: variables,
        }).pipe(
            map(value => {
                const mutationResponses: MutationResponse<PromoPlaylistAssignment>[] = [];
                const data = value.data || {};
                for (const key of recordsMap.keys()) {
                    const originalRecord = recordsMap.get(key)
                    let resp = new MutationResponse<PromoPlaylistAssignment>();
                    if (data.hasOwnProperty(key)) {
                        resp = this.readMutationResponse(PromoPlaylistAssignment, data[key])
                    } else {
                        resp.success = false;
                        resp.message = 'Unexpected response'
                    }
                    if (!resp.success) {
                        resp.data = originalRecord
                    }

                    mutationResponses.push(resp);
                }
                return mutationResponses
            })
        )
    }


    mutatePromoPlaylistMediaBundle(
        op: MutationOperation,
        data: Partial<PromoPlaylistMediaBundleMutationInput>,
        gqlFields: string
    ): Observable<MutationResponse<PromoPlaylistMediaBundle>> {
        const query = gql`
            mutation mutatePromoPlaylistMediaBundle($op:MutationOperation!, $data:PromoPlaylistMediaBundleMutationInput!){
                mutatePromoPlaylistMediaBundle(op:$op, data: $data){
                    ${MUTATION_RESPONSE_FIELDS}
                    promoPlaylistMediaBundle{
                        ${gqlFields}
                    }
                }
            }
        `
        return this.rawObjectMutate(query, {data: data, op: op}, PromoPlaylistMediaBundle)
    }

    massAddMediaContentToPlaylistMediaBundles(
        promoPeriodId: string,
        promoProgramId: string,
        mediaContentId: string,
        preferredPosition: number,
        gqlFields: string,
    ): Observable<MutationResponse<PromoPlaylistMediaBundle>> {
        const query = gql`
            mutation mutatePromoPlaylistMediaBundle($data:MassAddMediaContentToPlaylistMediaBundlesInput!){
                massAddMediaContentToPlaylistMediaBundles(data: $data){
                    ${MUTATION_RESPONSE_FIELDS}
                    promoPlaylistMediaBundles{
                        ${gqlFields}
                    }
                }
            }
        `
        const mutData = {
            promoPeriodId: promoPeriodId,
            promoProgramId: promoProgramId,
            mediaContentId: mediaContentId,
            preferredPosition: preferredPosition,
        }

        return this.rawObjectMutate(query, {data: mutData}, PromoPlaylistMediaBundle)
    }

    mutateMediaPlaylist(op: MutationOperation, data: Partial<MediaPlaylistMutationInput>, fieldsFragment: DocumentNode): Observable<MutationResponse<MediaPlaylist>> {
        const fields = Utils.extractGqlFragmentName(fieldsFragment)
        const query = gql`
            ${fieldsFragment}
            mutation mutateMediaPlaylist($op:MutationOperation!, $data:MediaPlaylistMutationInput!){
                mutateMediaPlaylist(op:$op, data: $data){
                    ${MUTATION_RESPONSE_FIELDS}
                    mediaPlaylist{
                        ...${fields}
                    }
                }
            }
        `
        return this.rawObjectMutate(query, {data: data, op: op}, MediaPlaylist)
    }

    evaluatePlaylistScheduledEntries(
        deviceSlotTypeSubgroupId: string,
        data: Partial<PromoPlaylistScheduleEntryMutationInput>[],
    ): Observable<MutationResponse<EvaluatedPromoPlaylistScheduleEntry>> {

        const mutation = gql`
            mutation evaluatePromoPlaylistScheduleEntries(
                $deviceSlotTypeSubgroupId: ID!
                $data: [PromoPlaylistScheduleEntryMutationInput!]!
            ) {
                evaluatePromoPlaylistScheduleEntries(
                    deviceSlotTypeSubgroupId: $deviceSlotTypeSubgroupId
                    data: $data
                ) {
                    success
                    evaluatedPromoPlaylistScheduleEntries{
                        id
                        activateAt
                        playlistScheduleEntry{
                            id
                            playlistAssignment{
                                id
                                name
                            }
                        }
                    }
                }
            }
        `
        return this.rawObjectMutate(mutation, {
            deviceSlotTypeSubgroupId: deviceSlotTypeSubgroupId,
            data: data
        }, EvaluatedPromoPlaylistScheduleEntry)

    }

    mutatePlaylistScheduledEntries(
        deviceSlotTypeSubgroupId: string,
        data: Partial<PromoPlaylistScheduleEntryMutationInput> | Partial<PromoPlaylistScheduleEntryMutationInput>[],
        gqlFields: string
    ): Observable<MutationResponse<PromoPlaylistScheduleEntry>> {

        if (!Array.isArray(data)) {
            data = [data]
        }

        const mutation = gql`
            mutation mutatePromoPlaylistScheduleEntries($deviceSlotTypeSubgroupId:ID!, $data: [PromoPlaylistScheduleEntryMutationInput!]!){
                mutatePromoPlaylistScheduleEntries(deviceSlotTypeSubgroupId:$deviceSlotTypeSubgroupId, data:$data){
                    ${MUTATION_RESPONSE_FIELDS}
                    promoPlaylistScheduleEntries{
                        ${gqlFields}
                    }
                }
            }
        `
        return this.rawObjectMutate(mutation, {
            deviceSlotTypeSubgroupId: deviceSlotTypeSubgroupId,
            data: data
        }, PromoPlaylistScheduleEntry)

    }

    private getStringIdVariable(id: NumberOrString): string {
        if (typeof id === 'undefined') {
            return undefined
        }
        const str = id + '';
        switch (str) {
            case ApiDataService.ID_NONE:
                return '-1';
            case ApiDataService.ID_ANY:
                return null;
            default:
                return id + ''
        }
    }


    private getAuthorizationHeaders(requestHeaders?: { [header: string]: string }): Observable<{
        [header: string]: string
    }> {
        if (!requestHeaders) {
            requestHeaders = {}
        }

        return from(this.authService.appendAuthorizationHeaders()).pipe(
            map(authHeaders => {
                console.warn('Auth', authHeaders && authHeaders['Authorization']);
                Object.assign(requestHeaders, authHeaders);
                return requestHeaders
            })
        )
    }

    getScoresAndBrandsForImage(
        deviceSlotImageId: string
    ): Observable<DeviceSlotImageScore[]> {
        return this.apollo.query<Response>({
            query: queries.QUERY_GET_SCORES_AND_BRANDS_FOR_IMAGE,
            fetchPolicy: 'no-cache',
            variables: {
                deviceSlotImageId: deviceSlotImageId,
            }
        }).pipe(
            this.mapTypedQueryResponseArray(DeviceSlotImage, 'device_slot_images', 'images'),
            map(value => {
                const bpMap = new Map<string, DeviceSlotImageScore>();
                if (Array.isArray(value)) {
                    const deviceSlotImage = value[0] as DeviceSlotImage

                    if (deviceSlotImage) {
                        if (Array.isArray(deviceSlotImage.targetBrandCampaigns)) {
                            for (const brandCampaign of deviceSlotImage.targetBrandCampaigns) {
                                const bp = brandCampaign.brandPartner;
                                if (!bp) {
                                    continue
                                }
                                const s = new DeviceSlotImageScore();
                                s.brandPartner = bp;
                                s.brandPartnerId = bp.id + '';
                                bpMap.set(bp.id + '', s)
                            }
                        }
                        if (Array.isArray(deviceSlotImage.scores)) {
                            for (const imgScore of deviceSlotImage.scores) {
                                const scoreSlot = bpMap.get(imgScore.brandPartnerId)
                                if (scoreSlot) {
                                    scoreSlot.score = imgScore.score;
                                    scoreSlot.id = imgScore.id;
                                }
                            }
                        }

                    }
                }
                return Array.from(bpMap.values())

            })
        );
    }

    fetchUpcDetails(upc: string): Observable<{
        retailer: Retailer,
        upcDetails: Map<string, string>
    }[]> {
        return this.apollo.query({
            variables: {
                upc: upc
            },
            query: gql`
                query($upc:String!){
                    merchantPlatform{
                        upcDetails(upc:$upc){
                            retailer {
                                id
                                retailer_name
                            }
                            upcDetails {
                                key
                                value
                            }
                        }
                    }
                }
            `
        }).pipe(
            map(resp => {
                const entries = (resp?.data as {
                    merchantPlatform: { upcDetails: any }
                })?.merchantPlatform?.upcDetails as { retailer: object, upcDetails: StringPair[] }[] || []


                return entries.map(entry => {
                    const retailer = new Retailer().assign(entry.retailer)
                    const upcDetails = new Map<string, string>()
                    for (const pair of entry.upcDetails) {
                        upcDetails.set(pair.key, pair.value)
                    }
                    return {
                        retailer: retailer,
                        upcDetails: upcDetails,
                    }
                })

            })
        )
    }

}


export class DeviceUpdateData {
    assignation: {
        deviceId: number
        retailerId: number
        retailerRegionId: number
        productCategoryId: number
        storeId: number
        deviceNumber: number
    };

    teamviewer_id: string;
    looma_phase: string;
    mount_type: string;
    fixture_type: string;
    display_total_feet: string;
    is_installed: boolean;
    volume: number;
}

export interface CampaignPlaylistInput {
    region_id: string
    category_id: string;
    library_file_ids: string[];
}

export interface CampaignUpdateInput {
    playlists?: CampaignPlaylistInput[]
    name?: string
    published?: boolean
    valid_confirmed?: boolean
    release_date?: string
    release_now?: boolean;
}

export enum PromoPlaylistMutationOp {
    Update = 'Update',
    Upsert = 'Upsert',
    MakeDefault = 'MakeDefault',
    Create = 'Create',
    Delete = 'Delete',
    Reset = 'Reset',
    Publish = 'Publish',
    Activate = 'Activate'
}

export interface PromoPlaylistMutationInput {
    id: string
    promoPeriodId: string
    deviceSlotSegmentId: string
    name: string
    playbackEntries?: PromoPlaylistSlotMutationInput[]
}

export interface PromoPlaylistSlotMutationInput {
    loopIndex: number
    trackIndex: number
    brandCampaignSlotId: string
    mediaBundleId?: string
}

export interface RecordMutationInput<RecordType, MutationInputType> {
    mutationKey?: string
    record: RecordType; // the local model which needs to be mutated
    mutationInput: MutationInputType; // the actual mutation data
}

