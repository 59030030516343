<div class="navbar-content-wrap"  *ngIf="currentUser">
    <div class="navbar-top" [ngClass]="appConfig.layout.navbar.secondaryBackground">

        <div class="logo">
            <img class="logo-icon" src="assets/images/logos/looma.svg">
            <span class="logo-text secondary-text">Uptime</span>
        </div>

    </div>


    <div class="navbar-scroll-container" [ngClass]="appConfig.layout.navbar.primaryBackground"
         appPerfectScrollbar >

        <div class="user" fxLayout="column" [ngClass]="appConfig.layout.navbar.secondaryBackground">
            <div class="h3 username">{{currentUser.display_name}}</div>
            <div class="h5 email hint-text mt-8">{{currentUser.email}}</div>
            <div class="h5 hint-text">{{roleNames}}</div>
            <div class="avatar-container" [ngClass]="appConfig.layout.navbar.primaryBackground">
                <img class="avatar" [src]="currentUser.photo_url" >
            </div>
        </div>

        <div class="navbar-content">
            <app-navigation layout="vertical"></app-navigation>
        </div>

    </div>
</div>