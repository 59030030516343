import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutModule} from "../../layout/layout.module";
import {AppCommonModule} from "../../shared/common.module";
import {RouterModule} from "@angular/router";
import {DndModule} from "ngx-drag-drop";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatBadgeModule} from "@angular/material/badge";
import {ScheduledPlaylistsWarnDialogComponent} from "./components/scheduled-playlists-warn-dialog/scheduled-playlists-warn-dialog.component";
import {MediaFileFilmVariablesComponent} from './components/media-file-film-variables/media-file-film-variables.component';
import {MediaFileFilmVariablesEditDialogComponent} from './components/media-file-film-variables-edit-dialog/media-file-film-variables-edit-dialog.component';

@NgModule({
    declarations: [ScheduledPlaylistsWarnDialogComponent, MediaFileFilmVariablesComponent, MediaFileFilmVariablesEditDialogComponent],
    imports: [
        CommonModule,
        AppCommonModule,
        RouterModule,
        LayoutModule,
        DndModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatBadgeModule
    ],

    entryComponents: []
})
export class MediaFilesModule {
}
