import {TemplateRef} from '@angular/core';
import {Utils} from "@looma/shared/utils";

export const COLUMN_REGISTRY = new class {
    specs: Map<any, TableColumnSpec[]> = new Map();

    registerColumn(target: any, key: string, spec: TableColumnSpec): void {
        if (!this.specs.has(target)) {
            const x: Array<TableColumnSpec> = [];
            this.specs.set(target, x);
        }
        const placeholder = this.specs.get(target);
        if (typeof spec.visible !== 'boolean') {
            spec.visible = true;
        }

        spec.width = spec.width || 1;
        spec.key = key;
        spec.defaultDisplayValue = spec.defaultDisplayValue || 'N/A';
        spec.customizable = spec.customizable !== false;
        placeholder.push(spec);
    }

    getColumns(target: any): TableColumnSpec[] {
        const items = [].concat(this.specs.get(target));
        Object.freeze(items);
        return items;
    }

};

export function TableColumn(spec: TableColumnSpec): any {
    return function(target, propertyKey: string, _: PropertyDescriptor): void {
        spec.formatToPrimitive = spec.formatToPrimitive || defaultFormatToPrimitive;
        COLUMN_REGISTRY.registerColumn(target.constructor, propertyKey, spec);
    };
}

TableColumn.FLAG_FILTERABLE = 1 << 0;
TableColumn.FLAG_HIDDEN = 1 << 1;
TableColumn.FLAG_SORTABLE = 1 << 2;
TableColumn.FLAG_CUSTOMIZABLE = 1 << 3;

export interface TableColumnSpec {
    customizable?: boolean
    sortable?: boolean;
    filterable?: boolean;
    label: string;
    alignContent?: 'center' | 'start'
    ellipsizeContent?: boolean
    key?: string;
    selectionColumn?: boolean;
    visible?: boolean;
    // a numeric value represents the flex-grow value
    // a string value may be a percent (10%) or a fixed size (200px)
    width?: string | number;
    defaultDisplayValue?: string;
    cellTemplate?: TemplateRef<any> | string;
    headerTemplate?: TemplateRef<any>;
    flags?: number;
    formatToPrimitive?: (fieldValue: any, parentObject: any) => any
}


function defaultFormatToPrimitive(v: any, parentObject: any) {
    if (Utils.isNullOrUndefined(v)) {
        return ''
    }
    if (Utils.isString(v)) {
        return v.toString().trim();
    }
    if (Utils.isNumber(v)) {
        return v
    }
    if (Utils.isDate(v)) {
        return v.toLocaleString();
    }
    return v.toString();
}
