<ng-template let-dataSource="dataSource" #tplStoreList>
    <looma-grid *ngIf="dataSource" [dataSource]="dataSource" fxFill class="device_slot_list"
                [title]="dataSource.dataSourceLabel"
                [class.slot_list]="dataSource.hasUnassignableItems" [dragDropController]="dataSource.ddController">
        <ng-template dragPreview let-dataSource="dataSource" let-selectedItems="selectedItems">
            <div>
                <div *ngFor="let bp of selectedItems" class="dragged_item">
                    <div>{{bp.getDisplayName()}}</div>
                </div>
            </div>
        </ng-template>

        <ng-template gridColumnHeader="product-category" let-dataSource="dataSource" let-label="label">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                <span fxFlex>{{ label }}</span>
                <div style="display: flex">
                    <button class="small filter_active_badge" mat-icon-button (click)="openFilters($event)">
                        <mat-icon matBadgeColor="warn" [matBadge]="dataSource.hasLocalFilters() ? '!' : null"
                                  matBadgePosition="above after">filter_list
                        </mat-icon>
                    </button>
                </div>
            </div>
        </ng-template>
    </looma-grid>
</ng-template>


<div app-looma-page
     [pageTitle]='"Preferred products: " + schedule?.retailerPromoProgram.name +" | "+schedule?.retailerPromoPeriod.name'
     pageIcon="playlist_play"
     style="height: 100%" [headerSideTpl]="headerSideTpl">

    <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
        <div style="flex: 1; display: flex; position: relative;">
            <div
                style="display: flex; overflow-y: hidden; position: absolute; top:0; left:0; right:10px; bottom:0; padding:8px 0 ; gap:10px;">
                <div class="mat-elevation-z3" *ngFor="let ds of controller.itemDataSources"
                     style="height:100%; min-width: 300px;flex:1">
                    <ng-container [ngTemplateOutlet]="tplStoreList"
                                  [ngTemplateOutletContext]="{dataSource:ds}"></ng-container>
                </div>
            </div>

        </div>
        <div style="z-index: 1; min-width: 500px !important; max-width: 25%; " class="mat-elevation-z3">
            <ng-container [ngTemplateOutlet]="tplStoreList"
                          [ngTemplateOutletContext]="{dataSource:controller.defaultItemDataSource}"></ng-container>
        </div>

    </div>
</div>

<ng-template #tplFilter>
    <div class="filters_dialog mat-elevation-z3" fxLayout="column">
        <mat-form-field appearance="fill">
            <mat-label>Choose a Brand</mat-label>
            <input type="text"
                   placeholder="Pick one"
                   aria-label="Brand"
                   matInput
                   [formControl]="bpControl"
                   [matAutocomplete]="bpAuto">
            <mat-autocomplete #bpAuto="matAutocomplete" [displayWith]="bpDisplayFn">
                <mat-option *ngFor="let option of bpFilteredOptions | async" [value]="option">
                    {{ option.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Product name</mat-label>
            <input type="text"
                   placeholder="Pick one"
                   aria-label="Product"
                   matInput
                   [formControl]="productControl"
                   [matAutocomplete]="productAuto">
            <mat-autocomplete #productAuto="matAutocomplete" [displayWith]="productDisplayFn">
                <mat-option *ngFor="let option of productFilteredOptions | async" [value]="option">
                    {{option.brand_partner.name}} {{ option.name }} {{ option.upc_code }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #headerSideTpl>
    <button mat-raised-button (click)="saveAssignment()"
            [disabled]="isBusy" class="mr-16">Save
    </button>
</ng-template>