import {BaseModel} from '@looma/shared/models/base_model';
import {RetailerCampaignRolloutPlaylist} from '@looma/shared/models/retailer_campaign_rollout_playlist'


export class RetailerCampaignRollout extends BaseModel {
    static readonly STATUS_DRAFT = 0;
    static readonly STATUS_PUBLISHED = 1;
    static readonly STATUS_CONFIRMED_VALID = 2;
    static readonly STATUS_PENDING_RELEASE = 3;
    static readonly STATUS_RELEASED = 4;
    static readonly STATUS_ABORTED = 5;

    id: string;
    campaign_id: number;
    version_number: number;
    version_name: string;
    created_at: Date;
    updated_at: Date;
    status: number;
    scheduled_release_date: Date;
    released_at: Date;
    is_valid: boolean;
    playlists: RetailerCampaignRolloutPlaylist[];


    assign(input: any): this {
        super.assign(input);

        this.assignDate(input, 'created_at');
        this.assignDate(input, 'updated_at');
        this.assignDate(input, 'scheduled_release_date');
        this.assignDate(input, 'released_at');

        this.assignTypedArray(input, 'playlists', RetailerCampaignRolloutPlaylist);
        return this;
    }
}
