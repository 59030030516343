<div mat-dialog-title>
    <div style="margin: 0">{{dialogTitle}}</div>
</div>
<mat-dialog-content>
    <mat-form-field class="full-width">
        <mat-label>Name</mat-label>
        <input autocomplete="off" matInput [(ngModel)]="mediaPlaylist.name">
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="this.isBusy || !this.isValid" (click)="handleAction('ok')">Ok
    </button>
    <button mat-button [disabled]="this.isBusy" (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>