import {BaseModel} from '@looma/shared/models/base_model';
import {BrandPartner} from '@looma/shared/models/brand_partner'
import {Retailer} from "@looma/shared/models/retailer";
import {StoreGroup} from "@looma/shared/models/store_group";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";

export class UserAssociation extends BaseModel {
    id: string;
    primaryBrand: BrandPartner;
    brands: BrandPartner[];
    childBrands: BrandPartner[];
    retailers: Retailer[];
    storeGroups: StoreGroup[];
    programs: RetailerPromoProgram[];

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'primaryBrand', BrandPartner);
        this.assignTypedArray(input, 'brands', BrandPartner);
        this.assignTypedArray(input, 'retailers', Retailer);
        this.assignTypedArray(input, 'storeGroups', StoreGroup);
        this.assignTypedArray(input, 'programs', RetailerPromoProgram);
        this.assignTypedArray(input, 'childBrands', BrandPartner);
        return this;
    }
}
