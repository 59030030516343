import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiDataService, DeviceUpdateData} from '../../../../services/api-data.service';
import {Subscription} from 'rxjs';
import {Retailer} from '@looma/shared/models/retailer';
import {RetailerRegion} from '@looma/shared/models/retailer_region';
import {ProductCategory} from '@looma/shared/models/product_category';
import {Store} from '@looma/shared/models/store';
import {Device} from '@looma/shared/models/device';
import {ActivatedRoute} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {MatSelectChange} from '@angular/material/select';
import {Utils} from '@looma/shared/utils';
import {BaseModel} from '@looma/shared/models/base_model';
import {Location} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";

@LifecycleHooks()
@Component({
    selector: 'app-device-edit',
    templateUrl: './device-edit.component.html',
    styleUrls: ['./device-edit.component.scss']
})
export class DeviceEditComponent implements OnInit, OnDestroy {

    private retailersForAssignSub: Subscription;
    retailersForAssign: Retailer[];

    deviceAssignation = new DeviceAssignation(this.svcApi);

    constructor(
        private svcApi: ApiDataService,
        private snackbar: MatSnackBar,
        private route: ActivatedRoute,
        public location: Location,
    ) {
    }

    ngOnInit(): void {

        this.retailersForAssignSub = this.svcApi.getRetailersWithRegionsAndStoresAndProductCategories().subscribe(retailers => {
            this.retailersForAssign = retailers
        });

        this.svcApi.queryDevice(this.route.snapshot.paramMap.get('id')).valueChanges.pipe(
            take(1),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.deviceAssignation.setDevice(value.data['devices']['device'][0]);
        });

        this.svcApi.getRetailersWithRegionsAndStoresAndProductCategories().subscribe(value => {
            this.deviceAssignation.setAvailableData(value)
        });
    }

    ngOnDestroy(): void {
    }

    onAssignationChanged(which: string, e: MatSelectChange): void {
        switch (which) {
            case 'retailer':
                this.deviceAssignation.setRetailer(e.value as Retailer);
                break;
            case 'region':
                this.deviceAssignation.setRegion(e.value as RetailerRegion);
                break;
            case 'product_category':
                this.deviceAssignation.setProductCategory(e.value as ProductCategory);
                break;
            case 'store':
                this.deviceAssignation.setStore(e.value as Store);
                break;
            default:
                return
        }
        this.deviceAssignation.device.assignation = null // don't allow editing the slot fields if the slot is changed
    }

    canEdit(which: string): boolean {
        if (!this.deviceAssignation.isEditable) {
            return false
        }
        switch (which) {
            case 'retailer':
                return true;
            case 'region':
                return !!this.deviceAssignation.retailer;
            case 'store':
                return !!this.deviceAssignation.region;
            case 'product_category':
                return !!this.deviceAssignation.retailer;
            case 'device_number':
                return !!this.deviceAssignation.store && !!this.deviceAssignation.productCategory;
        }
        return false
    }


    saveDevice(): void {
        const data = this.deviceAssignation.getDataToUpdate();
        this.svcApi.updateDevice(this.deviceAssignation.device, data).subscribe(value => {
            if (value.success) {
                this.goBack()
            } else if (value.message) {
                this.snackbar.open(value.message, null, {duration: 5000})
            }
        });
    }

    itemsEqual(a: BaseModel, b: BaseModel): boolean {
        if (a && b) {
            if (a.constructor === b.constructor) {
                return a.getId() === b.getId()
            }
        }
        return a === b
    }

    goBack(): void {
        this.location.back();
        // this.router.navigate([`/devices/${this.route.snapshot.paramMap.get('id')}`])
    }

}


class DeviceAssignation {
    retailer?: Retailer;
    region?: RetailerRegion;
    productCategory?: ProductCategory;
    store?: Store;
    deviceNumber?: number = null;

    device = new Device();
    private availableRetailers: Retailer[];
    private assignationNumberSuggestionSub: Subscription;

    constructor(private svcApi: ApiDataService) {

    }

    setDevice(device: Device): void {
        this.device.assign(device);

        this.device.teamviewer_id = this.device.teamviewer_id || null;

        /*this.device.looma_phase = this.device.looma_phase || null;
        this.device.mount_type = this.device.mount_type || null;
        this.device.fixture_type = this.device.fixture_type || null;
        this.device.display_total_feet = this.device.display_total_feet || null;*/
        this.reset()
    }

    setAvailableData(retailers: Retailer[]): void {
        this.availableRetailers = retailers;
        this.reset()
    }

    get isEditable(): boolean {
        return !!this.device && !!this.device.id && !!this.availableRetailers
    }

    private reset(): void {
        if (this.device && this.availableRetailers) {
            const assignation = this.device.assignation;
            if (assignation) {

                const retailer = this.availableRetailers.find(value => value.id === assignation.store.retailer.id);
                const region = retailer.regions.find(value => value.id === assignation.store.retailer_region.id);
                const store = region.stores.find(value => value.id === assignation.store.id);
                const cat = retailer.product_categories.find(value => value.id === assignation.product_category.id);

                this.setRetailer(retailer);
                this.setRegion(region);
                this.setStore(store);
                this.setProductCategory(cat);
                this.setDeviceNumber(assignation.counter);
            }

        }
    }

    setRetailer(r: Retailer): void {
        if (this.retailer && this.retailer.id || 0 !== r.id) {
            Utils.unsubscribe(this.assignationNumberSuggestionSub);
            this.retailer = r;
            this.region = null;
            this.productCategory = null;
            this.store = null;
            this.deviceNumber = null;
        }
    }

    setRegion(r: RetailerRegion): boolean {

        if (this.region && this.region.id || '' !== r.id) {
            Utils.unsubscribe(this.assignationNumberSuggestionSub);
            this.region = r;
            this.store = null;
            this.productCategory = null;
            this.deviceNumber = null;
            return true
        }
        return false;
    }

    setStore(s: Store): boolean {
        if (this.store && this.store.id || 0 !== s.id) {
            Utils.unsubscribe(this.assignationNumberSuggestionSub);
            this.store = s;
            this.deviceNumber = null;
            this.loadSuggestion();
            return true
        }
        return false
    }

    setProductCategory(cat: ProductCategory): boolean {

        if (this.productCategory && this.productCategory.id || '' !== cat.id + '') {
            this.productCategory = cat;
            this.deviceNumber = null;
            this.loadSuggestion();
            return true;
        }
        return false;
    }

    setDeviceNumber(num: number): boolean {
        if (this.deviceNumber !== num) {
            Utils.unsubscribe(this.assignationNumberSuggestionSub);
            this.deviceNumber = num;
            return true;
        }
        return false
    }

    private loadSuggestion(): void {
        Utils.unsubscribe(this.assignationNumberSuggestionSub);
        if (this.store && this.productCategory) {
            this.assignationNumberSuggestionSub =
                this.svcApi.getDeviceNextAssignationNumber(this.store, this.productCategory).subscribe(value => {
                    this.deviceNumber = value
                })
        }
    }

    getDataToUpdate(): DeviceUpdateData {
        const d = new DeviceUpdateData();
        if (this.deviceNumber && this.region && this.retailer && this.store && this.productCategory) {
            d.assignation = {
                deviceId: parseInt(this.device.id, 10),
                deviceNumber: this.deviceNumber,
                productCategoryId: parseInt(this.productCategory.id + '', 10),
                retailerId: this.retailer.id,
                retailerRegionId: parseInt(this.region.id, 10),
                storeId: this.store.id,
            }
        }
        d.teamviewer_id = this.device.teamviewer_id;

        if (this.device.assignation) {
            d.looma_phase = this.device.assignation.looma_phase;
            d.mount_type = this.device.assignation.mount;
            d.fixture_type = this.device.assignation.loop_placement;
        }

        return d
    }
}
