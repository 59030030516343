import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {MediaFileFilmVariable} from "@looma/shared/models/media_file_film_variable";

@LifecycleHooks()
@Component({
    selector: 'app-media-file-film-variables-edit-dialog',
    templateUrl: './media-file-film-variables-edit-dialog.component.html',
    styleUrls: ['./media-file-film-variables-edit-dialog.component.scss']
})
export class MediaFileFilmVariablesEditDialogComponent implements OnInit {

    form: FormGroup;
    isSavingData = false;
    mediaFileFilmVariable: MediaFileFilmVariable;
    filmId: string
    filmType: string

    constructor(private fb: FormBuilder,
                private dialogRef: MatDialogRef<MediaFileFilmVariablesEditDialogComponent>,
                private svcApi: ApiDataService,
                private svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) data) {

        let mediaFileFilmVariableData = {};

        if (data && data.hasOwnProperty('mediaFileFilmVariable')) {
            mediaFileFilmVariableData = data.mediaFileFilmVariable || {};

            if (mediaFileFilmVariableData instanceof MediaFileFilmVariable) {
                this.mediaFileFilmVariable = mediaFileFilmVariableData as MediaFileFilmVariable
            }

            if (!this.mediaFileFilmVariable) {
                this.mediaFileFilmVariable = new MediaFileFilmVariable().assign(mediaFileFilmVariableData || {})
            }

            this.filmId = data.filmId
            this.filmType = data.filmType
        }

        this.form = fb.group({
            variableName: new FormControl({value: this.mediaFileFilmVariable.film_variable.name, disabled: true}),
            variableValue: new FormControl({value: this.mediaFileFilmVariable.variable_value, disabled: false})
        });
    }

    ngOnInit(): void {
    }

    save(): void {
        if (!this.form.valid) {
            return;
        }

        const variableValue = this.form.get('variableValue').value;
        this.svcApi.updateMediaFileFilmVariable(
            this.mediaFileFilmVariable.film_variable.id,
            variableValue,
            this.filmId,
            this.filmType
        ).subscribe(value => {
            if (value.success) {
                this.dialogRef.close(value.data)
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Unexpected error');
            }
            this.isSavingData = false;
        }, error => {
            this.svcLayout.showSnackMessage('Api error');
            this.isSavingData = false;
        });
    }

    close(): void {
        this.dialogRef.close()
    }
}
