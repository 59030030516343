<h2 mat-dialog-title>{{this.surveyProduct.isNewRecord() ? "Add new survey product" : "Edit survey product: " + this.surveyProduct.brandProduct.name}}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Product details">
            <div style="padding-top: 15px">
                <mat-form-field>
                    <mat-label>Brand campaign</mat-label>
                    <mat-select formControlName="brandCampaign" (selectionChange)="onCampaignSelectionChanged($event)"
                                [value]="selectedCampaign" [compareWith]="isSameObj"
                                [disabled]="!this.surveyProduct.isNewRecord()">
                        <mat-option *ngFor="let c of availableCampaigns"
                                    [value]="c">{{c.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Featured product</mat-label>
                    <mat-select formControlName="featuredProduct" (selectionChange)="onFeaturedProductChanged($event)"
                                [value]="selectedFeaturedProduct" [compareWith]="isSameObj"
                                [disabled]="!this.surveyProduct.isNewRecord()">
                        <mat-option *ngFor="let c of selectedCampaign?.featuredProducts"
                                    [value]="c">{{c.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Wine type</mat-label>
                    <mat-select formControlName="wineType" (selectionChange)="onWineTypeChanged($event)"
                                [value]="selectedWineType" [compareWith]="isSameObj">
                        <mat-option *ngFor="let c of getWineTypes()"
                                    [value]="c">{{c.displayName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Pricing</mat-label>
                    <mat-select formControlName="pricingType" (selectionChange)="onPricingTypeChanged($event)"
                                [value]="selectedPricingType" [compareWith]="isSameObj">
                        <mat-option *ngFor="let c of getWinePricing()"
                                    [value]="c">{{c.displayName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Product Description</mat-label>
                    <textarea matInput rows="10" formControlName="description"
                              placeholder="Product description"></textarea>
                </mat-form-field>
            </div>

            <div>
                <div>
                    <button mat-button (click)="addProductPlacementImage()">
                        <mat-icon>upload</mat-icon>
                        Upload product placement image
                    </button>
                </div>
                <div>
                    <img [src]="productPlacementImageUrl" style="max-width: 300px; height: auto"/>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Tasting notes">
            <div style="padding-top: 15px">
                <mat-form-field style="display: block">
                    <input matInput formControlName="tastingNotesName" autocomplete="off"
                           placeholder="Tasting notes"/>
                </mat-form-field>

                <div>
                    <div>
                        <button mat-button (click)="addTastingNotesImage()">
                            <mat-icon>upload</mat-icon>
                            Upload tasting note image
                        </button>
                    </div>
                    <div>
                        <img [src]="tastingNotesImageUrl" style="max-width: 300px; height: auto"/>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Media content">
            <div class="media_contents" cdkDropList cdkDropListOrientation="horizontal">
                <div class="media_frame mat-elevation-z3"
                     *ngFor="let mediaContent of selectedMediaContents; let i = index" cdkDrag>
                    <div class="image_preview"
                         [style.background-image]="sanitizeImageUrl(mediaContent.defaultMediaContentVersion.thumbnail)">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="drag_handle" cdkDragHandle>
                                <mat-icon>drag_handle</mat-icon>
                            </div>
                            <media-content-type-icon [elevation]="1"
                                                     [mediaContentType]="mediaContent.contentType"></media-content-type-icon>
                            <div fxFlex></div>
                            <button mat-button (click)="removeMediaContent(i)"
                                    [disabled]="!canRemoveMediaContent(i)"
                                    color="warn">
                                <mat-icon class="delete_icon">close</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div style="padding-left: 8px;" fxLayout="row" fxLayoutAlign="start center" class="full-width"
                         fxLayoutGap="4px">
                        <div fxFlex="">{{mediaContent.displayName}}</div>
                        <button mat-icon-button (click)="playItem(mediaContent)">
                            <mat-icon>play_arrow</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <button mat-button (click)="addItem()" [disabled]="this.selectedCampaign == null">
                <mat-icon>add</mat-icon>
                Add Media Content
            </button>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isSavingData || !form.valid">Save</button>
    <button mat-button (click)="close()" [disabled]="isSavingData">cancel</button>
</mat-dialog-actions>
