import {BaseModel} from '@looma/shared/models/base_model';
import {ProductCategory} from '@looma/shared/models/product_category'
import {RetailerRegion} from '@looma/shared/models/retailer_region'
import {RetailerCampaignLibraryFile} from '@looma/shared/models/retailer_campaign_library_file'


export class RetailerCampaignRolloutPlaylist extends BaseModel {
    id: string;
    fb_reference: string;
    product_category: ProductCategory;

    retailer_region: RetailerRegion;
    campaign_library_files: RetailerCampaignLibraryFile[];


    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'product_category', ProductCategory);
        this.assignTypedObject(input, 'retailer_region', RetailerRegion);
        this.assignTypedArray(input, 'campaign_library_files', RetailerCampaignLibraryFile);

        return this;
    }
}
