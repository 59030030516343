import {UserAccessPermission} from "@looma/shared/models/user_role";

export interface AppNavigationItem {
    id: string;
    title: string;
    type: 'item' | 'group' | 'collapsable';
    permission?: UserAccessPermission
    translate?: string;
    icon?: string;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    externalUrl?: boolean;
    openInNewTab?: boolean;
    function?: any;
    badge?: {
        title?: string;
        translate?: string;
        bg?: string;
        fg?: string;
    };
    children?: AppNavigationItem[];
    availableForMobile?: boolean
}

export class AppNavigationHelper {
    static filter = <A extends AppNavigationItem>(children: A[], fn: (A) => boolean): A[] => {
        const filtered = [];
        children.forEach(node => {
            if (fn(node)) {
                if (node.children?.length > 0) {
                    const fc = AppNavigationHelper.filter(node.children, fn)
                    if(fc.length){
                        node.children = fc
                        filtered.push(node)
                    }
                }else{
                    filtered.push(node)
                }
            }
        })
        return filtered
    }

    static indexByPath = <A extends AppNavigationItem>(node: A[], accumulator: Map<string, A>): Map<string, A> => {
        node.forEach(item => {
            accumulator.set(item.url, item)
            if (item.children) {
                AppNavigationHelper.indexByPath(item.children, accumulator)
            }
        })
        return accumulator
    }
}

export interface AppNavigation extends AppNavigationItem {
    children?: AppNavigationItem[];
}
