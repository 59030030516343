import {BaseModel} from '@looma/shared/models/base_model';
import {Device} from '@looma/shared/models/device'
import {User} from '@looma/shared/models/user'
import {getCommand} from '@looma/shared/types/device_commands';

export enum DeviceCommandStatus {
    UNKNOWN = 0,
    NOTIFICATION_SENT = 1,
    RUNNING = 2,
    SUCCESSS = 3,
    FAILURE = 4,
    COMPLETED = 5
}

export class DeviceCommandBundleResult extends BaseModel {
    id: string;
    cmd_name: string;
    cmd_args: string;
    status: number;
    created_at: Date;
    expires_at: Date;
    device_results: DeviceCommandResult[];
    user: User;

    target_device_ids: number[];

    target_devices_count: number;
    notified_devices_count: number;
    payload_sent_devices_count: number;
    completed_devices_count: number;

    message: string;

    static getStatusName(status: any): string {
        switch (status) {
            case DeviceCommandStatus.SUCCESSS:
                return 'success';
            case DeviceCommandStatus.FAILURE:
                return 'failure';
            case DeviceCommandStatus.COMPLETED:
                return 'completed';
            case DeviceCommandStatus.NOTIFICATION_SENT:
                return 'notification sent';
            case DeviceCommandStatus.RUNNING:
                return 'running';
            case DeviceCommandStatus.UNKNOWN:
            default:
                return 'unknown';
        }
    }


    assign(input: any): this {
        super.assign(input);

        this.assignDate(input, 'created_at');
        this.assignDate(input, 'expires_at');

        this.assignNested(input, 'user', new User());

        if (Array.isArray(input.device_results)) {
            this.device_results = input.device_results.map(x => new DeviceCommandResult().assign(x))
        }

        return this;
    }

    get displayName(): string {
        const cmd = getCommand(this.cmd_name);
        if (cmd) {
            return cmd.displayMessage
        }
        return this.cmd_name;
    }

    get isDone(): boolean {
        switch (this.status) {
            case DeviceCommandStatus.FAILURE:
            case DeviceCommandStatus.SUCCESSS:
            case DeviceCommandStatus.COMPLETED:
                return true;
        }
        return false;
    }
}

export class DeviceCommandResult extends BaseModel {
    device: Device;
    status: number;
    message: string;
    attachment: DeviceCommandResultAttachment;

    assign(input: any): this {
        super.assign(input);
        this.assignNested(input, 'device', new Device());
        this.assignNested(input, 'attachment', new DeviceCommandResultAttachment());
        return this
    }

}

export class DeviceCommandResultAttachment extends BaseModel {
    content_type: string;
    preview_url: string;
    download_url: string;
}
