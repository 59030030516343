import {Component, HostBinding, Input} from '@angular/core';
import {AppNavigationItem} from '../../../../../shared/app-navigation';


@Component({
    selector   : 'nav-vertical-group',
    templateUrl: './group.component.html',
    styleUrls  : ['./group.component.scss']
})
export class NavVerticalGroupComponent{
    @HostBinding('class')
    classes = 'nav-group nav-item';

    @Input()
    item: AppNavigationItem;

    constructor(){
    }

}
