import {BaseModel} from '@looma/shared/models/base_model';
import {ProductCategory} from '@looma/shared/models/product_category'
import {Device} from '@looma/shared/models/device'
import {Store} from '@looma/shared/models/store'
import {DeviceSlotType} from '@looma/shared/models/device_slot_type'
import {
    DeviceSlotImageScoreSubmission,
    DeviceSlotImageScoreSubmissionTabletInstallationWarning
} from "@looma/shared/models/device_slot_image_score_submission";
import {CursorFilter} from "@looma/shared/types/cursor_filter";
import {DeviceSlotImage} from "@looma/shared/models/device_slot_image";
import {DeviceApp} from "@looma/shared/models/device_app";

export class DeviceSlot extends BaseModel {
    id: string;
    store: Store;
    device: Device;
    product_category: ProductCategory;
    name: string;
    counter: number;
    device_installed_at: Date;
    is_installed: boolean;
    looma_phase: string;
    store_location: string;
    loop_placement: string;
    temperature: string;
    mount: string;
    loop_x_score: string;
    decor: DeviceSlotDecor
    deviceSlotType: DeviceSlotType
    lastScoreSubmission: DeviceSlotImageScoreSubmission
    lastReceivedImage: DeviceSlotImage
    kioskDeviceApp: DeviceApp

    assign(input: any): this {
        super.assign(input);
        this.assignDate(input, 'device_installed_at');

        this.assignTypedObject(input, 'device', Device);
        this.assignTypedObject(input, 'store', Store);
        this.assignTypedObject(input, 'decor', DeviceSlotDecor);
        this.assignTypedObject(input, 'product_category', ProductCategory);
        this.assignTypedObject(input, 'deviceSlotType', DeviceSlotType);
        this.assignTypedObject(input, 'lastScoreSubmission', DeviceSlotImageScoreSubmission);
        this.assignTypedObject(input, 'lastReceivedImage', DeviceSlotImage);
        this.assignTypedObject(input, 'kioskDeviceApp', DeviceApp);

        this.is_installed = !!this.device_installed_at;
        return this
    }

    get hasInstallationWarnings(): boolean {
        return this.lastScoreSubmission?.hasTabletInstallationWarnings || false
    }

    get tabletInstallationWarnings(): DeviceSlotImageScoreSubmissionTabletInstallationWarning[] {
        return this.lastScoreSubmission?.tabletInstallationWarnings || [];
    }
}

export class DeviceSlotDecor extends BaseModel {
    header: string
    blades: string
    shelfRibbons: string
    accentCard: string
    coolerDecal: string
    sideLettering: string
}

export interface DeviceSlotMutationInput {
    id?: string
    store_id?: string
    product_category_id?: string
    looma_phase?: string
    store_location?: string;
    loop_placement?: string;
    temperature?: string;
    mount?: string;
    loop_x_score?: string;

    decorHeader?: string
    decorBlades?: string
    decorShelfRibbons?: string
    decorAccentCard?: string
    decorCoolerDecal?: string
    decorSideLettering?: string

    deviceSlotTypeId?: string
    resolveWarnings?: string[]
    kioskDeviceAppId?: string
}

export interface DeviceSlotSearchCriteria extends CursorFilter {
    id: string
    retailer_id: string
    retailer_store_num: string
    hasTabletInstallationWarnings: boolean
    loopPlacement: string
}
