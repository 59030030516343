import {BaseModel} from '@looma/shared/models/base_model';
import {Retailer} from '@looma/shared/models/retailer'
import {RetailerPromoPeriod} from '@looma/shared/models/retailer_promo_periods'


export class RetailerPromoSchedule extends BaseModel {
    id: string;
    name: string; // HT AP 2020
    retailer: Retailer;
    promoPeriods: RetailerPromoPeriod[];
    defaultPromoPeriodDurationDays: number
    startDate: Date;
    endDate: Date;

    assign(input: any): this {
        super.assign(input);

        this.assignTypedObject(input, 'retailer', Retailer);
        this.assignTypedArray(input, 'promoPeriods', RetailerPromoPeriod);
        return this;
    }
}

export interface RetailerPromoScheduleMutationInput {
    id: string
    name?: String
    retailerId?: string
    defaultPromoPeriodDurationDays?: number
}


