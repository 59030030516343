import {
    AfterViewInit, Attribute,
    Component, Directive, Host,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {TemplatePortal} from "@angular/cdk/portal";
import {Overlay} from "@angular/cdk/overlay";
import {
    ItemGridController,
    DeviceSlotGridDataSource,
    DeviceSlotGridController
} from "./device_slot_assignment_controller";
import {PageControllerService} from "../looma-page/looma-page.component";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";

@Component({
    selector: 'app-device-slot-assignment-list',
    templateUrl: './device-slot-assignment-list.component.html',
    styleUrls: ['./device-slot-assignment-list.component.scss']
})
export class DeviceSlotAssignmentListComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input('dataSource') dataSource: DeviceSlotGridDataSource

    @Input('retailerPromoPrograms') retailerPromoPrograms: RetailerPromoProgram[]

    @ViewChild('tplDeviceSlotFilter') tplDeviceSlotFilter: TemplateRef<any>;

    footerTemplate: TemplateRef<any>

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        @Inject(ItemGridController) private controller: DeviceSlotGridController
    ) {

    }

    performCollectionMenuOption(option: string, ev: MouseEvent) {
        this.controller.performCollectionMenuOption(this.dataSource.collection, option, ev)
    }

    openFilters(event: MouseEvent) {
        const portal = new TemplatePortal(this.tplDeviceSlotFilter, this.viewContainerRef, {});
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().flexibleConnectedTo(event.target as HTMLElement).withPositions([{
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
            }]),
            hasBackdrop: true,
            backdropClass: 'transparent'
        });
        overlayRef.backdropClick().subscribe(() => overlayRef.detach());
        overlayRef.attach(portal);
    }

    unassignSelectedItems() {
        this.controller.unassignSelectedItems(this.dataSource)
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.controller.afterDatasourceViewInit(this.dataSource)
    }

    ngOnDestroy(): void {
    }

}

@Directive({
    selector: 'ng-template[slot-assignment-list-footer]'
})
export class DeviceSlotAssignmentListFooterDirective {

    constructor(
        @Inject(DeviceSlotAssignmentListComponent) cmp: DeviceSlotAssignmentListComponent,
        @Host() template: TemplateRef<any>) {
        cmp.footerTemplate = template
    }

}
