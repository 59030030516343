<h2 mat-dialog-title>Edit Photo</h2>

<mat-dialog-content style="height: 600px" fxLayout="column">

    <mat-slide-toggle [(ngModel)]="isPrimaryImage" (ngModelChange)="onPrimaryChanged()" *ngIf="dialogData.deviceSLotImage.photos.length > 1">Primary Image</mat-slide-toggle>
    
    <looma-zoomable-image [imageSource]="imageSource" fxFlex="1 1 auto" style="display: flex">s
    </looma-zoomable-image>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleAction('ok')" [disabled]="isBusy || imageSource.isBusy || !hasChanges">Save</button>
    <button mat-button (click)="handleAction('cancel')" [disabled]="isBusy">cancel</button>
</mat-dialog-actions>