<div class="image_tile" [class.empty]="!imagePreviewUrl"
     [class.busy]="uploadSession.isBusy">
    <img [src]="imagePreviewUrl" *ngIf="imagePreviewUrl"/>
    <button (click)="uploadSession.openPicker()" *ngIf="!uploadSession.isBusy">
        {{buttonText}}
    </button>
    <div class="progress" *ngIf="uploadSession.isBusy">
        <mat-progress-bar *ngIf="uploadProgress >= 0" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
        <mat-progress-bar *ngIf="uploadProgress < 0" mode="indeterminate"></mat-progress-bar>
    </div>
</div>
