<div app-looma-page pageTitle="Custom Brands" pageIcon="stars" [headerSideTpl]="headerSideTpl">
    <ng-container *ngIf="dataSource">
        <div style="overflow:auto">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="submission-date">
                    <th mat-header-cell *matHeaderCellDef>Submission date</th>
                    <td mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'MM/dd/yyyy' }}</td>
                </ng-container>

                <ng-container matColumnDef="parent-brand">
                    <th mat-header-cell *matHeaderCellDef> Parent Brand</th>
                    <td mat-cell *matCellDef="let element"> {{ element.submittedByBrand.name }}</td>
                </ng-container>

                <ng-container matColumnDef="submitted-by">
                    <th mat-header-cell *matHeaderCellDef> Submitted By</th>
                    <td mat-cell *matCellDef="let element"> {{ element.submittedByUser }}</td>
                </ng-container>

                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef> Custom Brand</th>
                    <td mat-cell *matCellDef="let element"> {{ element.brandPartner.name }}</td>
                </ng-container>

                <ng-container matColumnDef="logo">
                    <th mat-header-cell *matHeaderCellDef> Logo</th>
                    <td mat-cell *matCellDef="let element">
                        <img *ngIf="element.brandPartner?.logoUrl" [src]="element.brandPartner.logoUrl" alt="Brand Logo"
                             width="50">
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-flat-button (click)="createBrand(element)">New brand</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-container>

</div>

<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button (click)="onBrandsClicked()">Brands</button>
    </div>
</ng-template>
