import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LayoutService} from "../../../services/layout.service";
import {UploadedFileInfo, UploadService, UploadSession, UploadState} from "@looma/shared/services/upload.service";
import {distinctUntilChanged, map, switchMap, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";

@LifecycleHooks()
@Component({
    selector: 'app-image-tile-uploader',
    templateUrl: './image-tile-uploader.component.html',
    styleUrls: ['./image-tile-uploader.component.scss']
})
export class ImageTileUploaderComponent implements OnInit, OnDestroy {

    readonly uploadSession: UploadSession
    uploadProgress = -1

    private fileUploadedSubject = new Subject<UploadedFileInfo>()
    private uploadStateSubject = new BehaviorSubject<UploadState>(UploadState.Idle)

    @Output()
    get onFileUploaded(): Observable<UploadedFileInfo> {
        return this.fileUploadedSubject
    }

    @Output()
    get onUploadStateChanged(): Observable<UploadState> {
        return this.uploadStateSubject.pipe(
            distinctUntilChanged()
        )
    }

    imagePreviewUrl: string

    @Input()
    texts = {
        empty: 'Click to upload',
        nonEmpty: 'Click to change'
    }

    @Input()
    set imageUrl(value: string) {
        if (value) {
            this.imagePreviewUrl = value
        }
    }

    get buttonText() {
        if (!this.imagePreviewUrl) {
            return this.texts.empty
        }
        return this.texts.nonEmpty
    }

    constructor(
        private svcLayout: LayoutService,
        private svcUpload: UploadService,
    ) {

        this.uploadSession = svcUpload.getUploadSession('generic_upload', {
            fileTypes: ['image'],
            multiSelection: false
        });
        
        
        // notify about upload progress
        this.uploadSession.onFileAdded().pipe(
            switchMap(file => {
                return file.onProgress.pipe(
                    map(progress => {
                        return {file: file, progress: progress}
                    })
                )
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.uploadProgress = value.progress.progress
            if (this.uploadProgress == 100) {
                this.uploadProgress = -1
            }
        })

        // notify about upload state changed
        this.uploadSession.onFileAdded().pipe(
            switchMap(file => {
                return file.onStateChanged
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(state => {
            this.uploadStateSubject.next(state)
        })

        // notify about upload state changed
        this.uploadSession.onFileAdded().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.imagePreviewUrl = Utils.BLANK_IMAGE
        })

        this.uploadSession.onFileUploaded().pipe(
            takeUntil(Utils.onDestroy(this))
        )
            .subscribe(uploadedFileInfo => {
                this.fileUploadedSubject.next(uploadedFileInfo)
                this.imagePreviewUrl = uploadedFileInfo.downloadUrl
            }, error => {
                this.svcLayout.showSnackMessage('Update error');
            });
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.uploadStateSubject.complete()
        this.fileUploadedSubject.complete()
        this.uploadSession.destroy()
    }

}
