import {BaseModel} from '@looma/shared/models/base_model';
import {DeviceSlot} from '@looma/shared/models/device_slot'
import {DeviceSlotImageScore} from '@looma/shared/models/device_slot_image_score'
import {DeviceSlotImagePhoto, DeviceSlotImagePhotoVersion} from "@looma/shared/models/device_slot_image_photo";
import {DeviceSlotImageScoreSubmission} from "@looma/shared/models/device_slot_image_score_submission";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";

export class DeviceSlotImage extends BaseModel {
    id: string;
    deviceSlot: DeviceSlot;
    date: Date;
    approved: boolean;
    source: string;

    primaryPhotoVersionIdx: number;
    thumb: DeviceSlotImagePhotoVersion;
    large: DeviceSlotImagePhotoVersion;
    original: DeviceSlotImagePhotoVersion;

    photos: DeviceSlotImagePhoto[]
    scores: DeviceSlotImageScore[];
    
    lastScoreSubmission: DeviceSlotImageScoreSubmission
    targetBrandCampaigns: BrandPromoCampaign[];

    private warnings: string;


    assign(input: any): this {
        super.assign(input);
        this.assignDate(input, 'date');
        this.assignTypedObject(input, 'deviceSlot', DeviceSlot);
        this.assignTypedObject(input, 'lastScoreSubmission', DeviceSlotImageScoreSubmission);

        this.assignTypedObject(input, "thumb", DeviceSlotImagePhotoVersion)
        this.assignTypedObject(input, "large", DeviceSlotImagePhotoVersion)
        this.assignTypedObject(input, "original", DeviceSlotImagePhotoVersion)
        this.assignTypedArray(input, "photos", DeviceSlotImagePhoto)
        this.assignTypedArray(input, "targetBrandCampaigns", BrandPromoCampaign)

        this.assignTypedArray(input, 'scores', DeviceSlotImageScore);
        this.approved = !!input.approved;
        return this;
    }

    displayName(): string {
        const store = this.deviceSlot && this.deviceSlot.store;
        if (store) {
            const chunks = [];
            chunks.push(store.retailer && store.retailer.retailer_id || '');
            chunks.push(store.getPaddedStoreNum());
            chunks.push(this.deviceSlot.product_category && this.deviceSlot.product_category.category_name || '')
            return chunks.join('-')
        }
        return 'unknown';
    }

    isValidForApproval(): boolean {
        if (this.approved) {
            return false
        }
        if (!this.scores?.length) {
            return false
        }
        return true
    }

    getWarnings(): string {
        if (!this.warnings) {
            const warnings = [];
            if (this.lastScoreSubmission) {
                if (this.lastScoreSubmission.imageHasCorrectOrientation == false) {
                    warnings.push("Image doesn't have correct orientation")
                }
                if (this.lastScoreSubmission.imageHasGoodQuality == false) {
                    warnings.push("Image doesn't have good quality")
                }
            }
            if (!this.approved) {
                warnings.push('Not Approved')
            }
            if (!(this.scores && this.scores.length)) {
                warnings.push('No Scores')
            }
            this.warnings = warnings.join('\n')
        }

        return this.warnings.length && this.warnings || null
    }
}

export interface DeviceSlotImageMutationInput {
    id: string;
    deviceSlotId: string
    date: string;

    imageStorageRefs: string[]
    
    approved: boolean;
    resendToScore: boolean
    approveZeroScores: boolean
}

export interface DeviceSlotImageFilter {
    cursor?: string
    ids?: string[]
    retailerIds?: string[]
    retailerRegionIds?: string[]
    productCategoryIds?: string[]
    storeIds?: string[]
    retailerCampaignIds?: string[]
    sources?: string[]
    approved?: boolean
    scores?: boolean
    imageQuality?: string
}
