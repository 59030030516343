import { BaseModel } from "@looma/shared/models/base_model";
import { Retailer } from "@looma/shared/models/retailer";
import { RetailerPromoProgram } from "@looma/shared/models/retailer_promo_program";
import { Utils } from "@looma/shared/utils";


const idCounterMap = new Map<string, number>()

export class RetailerProgramDeviceStatEntry extends BaseModel{
    retailer: Retailer
    program: RetailerPromoProgram
    storesCount: number
    slotsCount: number
    installedCount: number
    assignedCount: number
    onlineCount: number
    offlineCount: number
    ts: number
    recordId: string
    parentRecordId: string
    label: string

    onlinePercent: number
    globalOpenHoursOnlinePercent: number
    globalDayOnlinePercent: number

    assign(input: any): this{
        super.assign(input);
        this.assignTypedObject(input, 'retailer', Retailer)
        this.assignTypedObject(input, 'program', RetailerPromoProgram)

        this.offlineCount = this.installedCount - this.onlineCount
        this.onlinePercent = Utils.round(this.onlineCount * 100.0 / this.installedCount, 2)

        return this
    }

    getStringId(): string{
        return this.recordId || super.getStringId()
    }

}
