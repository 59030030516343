import {BaseModel} from '@looma/shared/models/base_model';
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";

export class BrandPromoCampaignReport extends BaseModel {
    id: number;
    brandPromoCampaign: BrandPromoCampaign;
    cprStatus: string;
    emailNotificationSentTo: CampaignReportEmailNotification[];
    cpr30Data: string;
    cpr30PublicUrl: string;
    performanceData: IBrandCampaignPerformance
    updatedAt: Date;


    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'brandPromoCampaign', BrandPromoCampaign);
        this.assignDate(input, 'updatedAt');
        return this
    }

    prettyPrintEmailNotifications(): string {
        return "\n" + this.emailNotificationSentTo.join("\n")
    }

    cpr30Url(): string {
        return this.cpr30PublicUrl
    }

    isVisible(): boolean {
        return this.brandPromoCampaign?.cprEnabled && this.brandPromoCampaign?.isVisible;
    }
}

export interface BrandPromoCampaignReportInput {
    id?: number
    approved?: boolean
    brandCampaignId?: string,
    reportFirebaseKey?: string,
    sendNotificationEmail?: boolean,
    notificationEMailAddresses?: string[],
}


export interface IBrandCampaignPerformance {
    retailerName: string;
    brandName: string;
    brandCampaignName: string;
    numberOfStores: number;
    promoPeriodName: string;
    awarenessValue: number;
    discoveryValue: number;
    conversionValue: number;
    conversionSalesValue: number;
    loyaltyValue: number;
    overlapValue: number;
    fullFunnelValue: number;
    roasMultiplier: number;
    salesLift: number;
    roasMultiplierDiscount: number;
    campaignCost: number;
    campaignDiscount: number;
    campaignRegions: string;
    reportUrl: string
}

export interface CampaignReportEmailNotification {
    email: string;
    sentAt: Date;
}

