import {BaseModel} from '@looma/shared/models/base_model';
import {MediaFileVariant} from '@looma/shared/models/media_file_variant'

export class MediaFileVersion extends BaseModel {
    id: string;
    file_name: string;
    firebase_job_id: string;
    created_at: Date;
    processed_url: string;
    original_url: string;
    fileLocation: string;
    description: string;
    is_active: boolean;
    is_splash_screen: boolean;
    thumbnail: string;
    mediaFileVariant: MediaFileVariant
    standalone: boolean

    assign(input: any): this {
        super.assign(input);
        this.assignDate(input, 'created_at');
        this.assignTypedObject(input, 'mediaFileVariant', MediaFileVariant)
        return this;
    }

    isNewRecord(): boolean {
        return !this.id
    }

}
