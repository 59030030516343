import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";
import { ApiDataService } from "../../../../services/api-data.service";
import gql from "graphql-tag";
import { map, takeUntil } from "rxjs/operators";
import { CursorFeed } from "@looma/shared/cursor_feed";
import { MediaPlaylist, MediaPlaylistVersion, MediaPlaylistVersionEntry } from "@looma/shared/models/media_playlist";
import { LayoutService } from "../../../../services/layout.service";
import { ModelListDataSource } from "../../../../layout/components/looma-grid/grid-data-source";
import { Device } from "@looma/shared/models/device";
import { SetPlaylistCommand } from "@looma/shared/types/device_commands";
import { DeviceCommandService } from "../../../../services/device-command.service";
import { VideoPlayerService } from "../../../../services/video-player.service";
import { SearchableObject } from "@looma/shared/search";
import { VideoReference } from "@looma/shared/components/video-player/video-player.component";
import { BehaviorSubject } from "rxjs";
import { Utils } from "@looma/shared/utils";

@LifecycleHooks()
@Component({
    selector: 'app-published-playlist-info-dialog',
    templateUrl: './media-playlist-version-dialog.component.html',
    styleUrls: [ './media-playlist-version-dialog.component.scss' ]
})
export class MediaPlaylistVersionDialogComponent implements OnInit, OnDestroy, AfterViewInit{

    constructor(
        public dialogRef: MatDialogRef<MediaPlaylistVersionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: PublishedPlaylistInfoDialogData,
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        private svcDeviceCommand: DeviceCommandService,
        private svcVideoPlayer: VideoPlayerService,
    ){
    }

    title: string;
    dataSource: PlaylistEntriesDataSource;
    playlistVersion: MediaPlaylistVersion;
    playListFilesSubject: BehaviorSubject<VideoReference[]> = new BehaviorSubject<VideoReference[]>([]);
    playlistFirebasePath: string

    static open(svcLayout: LayoutService, playlistId: string, versionId: string){
        const data: PublishedPlaylistInfoDialogData = {
            playlistId: playlistId,
            versionId: versionId,
        };

        svcLayout.openDialogForResult(MediaPlaylistVersionDialogComponent, {
            data: data,
            minWidth: '900px',
            disableClose: false
        }).subscribe()
    }

    ngOnInit(): void{
        this.svcApi.rawQueryNoCache({
            query: FETCH_PLAYLIST_GQL_QUERY,
            variables: {
                playlistId: this.dialogData.playlistId,
                versionId: this.dialogData.versionId,
            }
        }).pipe(
            map(value => {
                const rawData = value.data['mediaPlaylists'];
                return CursorFeed.create(rawData, MediaPlaylist, 'data');
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.data && value.data.length == 1) {
                this.setup(value.data[0])
            } else {
                this.close()
            }
        })
    }

    ngAfterViewInit(){
    }

    private setup(pl: MediaPlaylist){
        this.title = pl.name;
        this.playlistVersion = pl.version;
        if (pl.version && pl.version.firebasePath) {
            this.playlistFirebasePath = pl.version.firebasePath;
        }
        this.dataSource = new PlaylistEntriesDataSource(pl);

        this.playListFilesSubject.next(
            this.playlistVersion.playlistEntries.map(entry => {
                return {
                    srcUrl: entry.mediaContentVersion.processedUrl,
                    filmName: entry.mediaContentVersion.mediaContent.displayName,
                }
            })
        )
    }

    ngOnDestroy(): void{
    }

    close(){
        this.dialogRef.close()
    }

    sendPlaylistToDevice(): void{
        if (this.playlistFirebasePath) {
            this.svcLayout.promptSearchObject('Select Device', 'Device', {
                objectType: SearchableObject.Device
            }).subscribe(selection => {
                if (selection) {
                    this.svcDeviceCommand.sendDeviceCommand(selection.value, SetPlaylistCommand, this.playlistFirebasePath)
                }
            })

        }

    }

    getPlaylistLink(){
        this.svcLayout.prompt('Playlist Name', 'Please enter the playlist name', { width: '800px' }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(playlistTitle => {

            const ids = this.playlistVersion.playlistEntries.map(value1 => value1.mediaContentVersion.mediaContent.externalKey)

            const playlistData = { ids: ids, title: playlistTitle }

            const playlistId = btoa(JSON.stringify(playlistData))
            const playlistUrl = `https://films.theloomaproject.com/playlist/${playlistId}`

            navigator.clipboard.writeText(playlistUrl);
            this.svcLayout.showSnackMessage('Playlist url copied to clipboard')
        })
    }

    playFile(entry: MediaPlaylistVersionEntry){
        if (entry) {
            this.svcVideoPlayer.play(entry.mediaContentVersion.processedUrl)
        }
    }
}

export interface PublishedPlaylistInfoDialogData{
    playlistId: string
    versionId: string
}

const FETCH_PLAYLIST_GQL_QUERY = gql`
    query fetchMediaPlaylist($playlistId: ID!, $versionId: ID!) {
        mediaPlaylists(filter: { id: $playlistId }) {
            data: mediaPlaylists {
                id
                name
                version(id: $versionId) {
                    id
                    name
                    firebasePath
                    playlistEntries {
                        id
                        mediaContentVersion {
                            id
                            fileName
                            processedUrl
                            videoDuration
                            mediaContent {
                                id
                                contentType
                                displayName
                                description
                                externalKey
                            }
                        }
                    }
                }
            }
        }
    }

`

class PlaylistEntriesDataSource extends ModelListDataSource<MediaPlaylistVersionEntry>{
    constructor(playlist: MediaPlaylist){
        super({
            columns: [
                {
                    key: 'index',
                    label: 'Track Num',
                    width: '70px',
                    valueReader: (item: MediaPlaylistVersionEntry) => {
                        return data.indexOf(item) + 1
                    }
                }, {
                    key: 'file_name',
                    label: 'Media File',
                    valueReader: (item: MediaPlaylistVersionEntry) => {

                        return item.mediaContentVersion?.mediaContent?.displayName || '-'
                    }
                }, {
                    key: 'content_type',
                    label: 'Type',
                    width: '50px'
                }
            ]
        });

        let data: MediaPlaylistVersionEntry[] = []
        if (playlist.version) {
            data = playlist.version.playlistEntries || []
        }
        this.setLocalData(data)
    }
}
