<div app-looma-page [pageTitle]="pageTitle" pageIcon="featured_play_list" [headerSideTpl]="headerSideTpl"
     style="background: red">

    <div *ngIf="isPublishAvailable" fxLayoutAlign="space-between center" class="p-8">
        <ng-container
            *ngTemplateOutlet="warn; context:{title:'This playlist has unpublished changed', style:'warn'}"></ng-container>
    </div>

    <div *ngIf="mediaPlaylist?.activeVersion" fxLayoutAlign="space-between center" class="p-8">
        <ng-container
            *ngTemplateOutlet="warn; context:{title:'Active version', subtitle:mediaPlaylist.activeVersion.firebasePath, style:'info'}"></ng-container>
        <button mat-raised-button (click)="viewActivePlaylist()">View</button>
    </div>


    <div cdkDropList (cdkDropListDropped)="changePlaylistPosition($event.previousIndex, $event.currentIndex)"
         class="p-16" style="flex: 1; background:#ccc">

        <div class="mat-elevation-z3 playback_entry"
             *ngFor="let entry of playlistEntries; let i = index;"
             cdkDrag
             cdkDragLockAxis="y"
             fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="8px">
            <div class="drag_placeholder" *cdkDragPlaceholder></div>
            <span class="drag_handle" cdkDragHandle style="background: rgb(255, 152, 0)">{{i + 1}}</span>

            <div fxFlex>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="video_thumb" [style.background-image]="getBackgroundThumbImage(entry)"></div>
                    <media-content-type-icon style="margin-right: 16px;"
                                             [mediaContentType]="entry.mediaContentVersion?.mediaContent?.contentType"></media-content-type-icon>
                    <span style="font-weight: 500">{{entry.mediaContentVersion?.mediaContent?.displayName}}</span>
                    <mat-icon style="cursor: pointer;" (click)="playPlaylistEntry(entry)">
                        play_arrow
                    </mat-icon>
                </div>
            </div>

            <button mat-icon-button color="warn" (click)="removePlaylistItem(entry)">
                <mat-icon>clear</mat-icon>
            </button>


        </div>
    </div>


</div>


<ng-template #headerSideTpl>
    <button mat-button (click)="addLibraryItem()" class="mr-16">Add Playlist Item</button>
    <button mat-raised-button color="warn" [disabled]="!isSaveAvailable" (click)="saveMediaPlaylist()" class="mr-16">
        Save
    </button>
    <button mat-raised-button color="warn" [disabled]="!isPublishAvailable" (click)="publishMediaPlaylist()"
            class="mr-16">Publish
    </button>
</ng-template>


<ng-template #warn let-title="title" let-subtitle="subtitle" let-style="style">
    <div class="playlist_warn"
         [class.with_error]="style=='error'"
         [class.with_warn]="style=='warn'"
         [class.with_info]="style=='info'"
         fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon *ngIf="style=='error'">warning</mat-icon>
        <mat-icon *ngIf="style=='warn'">warning</mat-icon>
        <mat-icon *ngIf="style=='info'">info</mat-icon>
        <div>
            <div class="title">{{title}}</div>
            <div class="subtitle" *ngIf="subtitle">{{subtitle}}</div>
        </div>

    </div>

</ng-template>