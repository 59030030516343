import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { ApiDataService } from "../../../../services/api-data.service";
import { PreferencesService } from "@looma/shared/services/preferences.service";
import { takeUntil } from "rxjs/operators";
import { createStoreMarker, Utils } from "@looma/shared/utils";
import { Device } from "@looma/shared/models/device";
import { Store } from "@looma/shared/models/store";
import { ModelListDataSource } from "../../../../layout/components/looma-grid/grid-data-source";
import { EMPTY, Observable } from "rxjs";
import { RetailerProgramDeviceStatEntry } from "../../../../models/RetailerProgramDeviceStatEntry";
import gql from "graphql-tag";
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";

declare var google: any;


@LifecycleHooks()
@Component({
    selector: 'app-network-health-overview-dashboard-tab',
    templateUrl: './network-health-overview-dashboard-tab.component.html',
    styleUrls: [ './network-health-overview-dashboard-tab.component.scss' ]
})
export class NetworkHealthOverviewDashboardTabComponent implements OnInit, OnDestroy{
    @ViewChild('devicesMap', { static: true }) set devicesMap(v: GoogleMap){
        this._devicesMap = v
    }

    get devicesMap(): GoogleMap{
        return this._devicesMap
    }

    constructor(
        private svcApi: ApiDataService,
        private svcPrefs: PreferencesService){
    }

    private _devicesMap: GoogleMap

    @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;

    deviceGroups: DeviceMapGroup[];

    mapPosition: MapPosition;

    selectedGroup: DeviceMapGroup

    statsDataSource = new StatEntriesDataSource()

    private activeInfoWindow: MapInfoWindow

    ngOnInit(): void{
        this.mapPosition = this.svcPrefs.getSessionValue('dashboard_map_position') as MapPosition;

        this.svcApi.queryDevicesForMap().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(devices => {
            const groups: Map<number, DeviceMapGroup> = new Map();
            for (const device of devices) {
                const deviceStore = device.assignation.store;
                if (!deviceStore.location.geo_point) {
                    continue
                }
                if (!deviceStore.location.geo_point.length) {
                    continue
                }
                if (!groups.has(deviceStore.id)) {
                    groups.set(deviceStore.id, new DeviceMapGroup(deviceStore));
                }
                groups.get(deviceStore.id).addDevice(device);
            }

            this.deviceGroups = Array.from(groups.values()).sort((a, b) => a.playbackStatus - b.playbackStatus);
            this.refreshMapBounds();
        });

        this.loadData()
    }

    onMarkerClick(index: number, marker: MapMarker, group: DeviceMapGroup): void{
        if (this.activeInfoWindow) {
            this.activeInfoWindow.close()
        }
        this.activeInfoWindow = this.infoWindowsView.get(index)
        this.activeInfoWindow.open(marker)
    }

    ngOnDestroy(): void{
        const center = this.devicesMap?.getCenter();
        if (center) {
            this.svcPrefs.setSessionValue('dashboard_map_position', {
                lat: center.lat(),
                lng: center.lng(),
                zoom: this.devicesMap.getZoom(),
            });
        }
    }

    onMapPrepared(map: any): void{
        this.refreshMapBounds();
    }

    refreshMapBounds(): void{
        const googleMap = this.devicesMap?.googleMap
        if (googleMap && this.deviceGroups) {
            if (this.mapPosition) {
                googleMap.setCenter({ lat: this.mapPosition.lat, lng: this.mapPosition.lng });
                googleMap.setZoom(this.mapPosition.zoom);
            } else {
                const bounds = new google.maps.LatLngBounds();
                for (const group of this.deviceGroups) {
                    bounds.extend(new google.maps.LatLng(group.lat, group.lng));
                }
                googleMap.fitBounds(bounds);
            }
        }
    }

    private loadData(){
        return this.svcApi.rawQuery({
            query: QUERY_DATA,
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
                const entries = Utils.getNestedTypedArray(value, RetailerProgramDeviceStatEntry, 'data', 'retailerProgramDeviceStats')
                const retailerChildrenEntries = new Map<string | null, RetailerProgramDeviceStatEntry[]>();

                for (const x of entries) {
                    const dest = (retailerChildrenEntries.get(x.parentRecordId) || [])
                    dest.push(x)
                    retailerChildrenEntries.set(x.parentRecordId, dest)
                }

                this.statsDataSource.setMapData(retailerChildrenEntries)

            }
        )
    }
}


interface MapPosition{
    lat: number;
    lng: number;
    zoom: number;
}

class DeviceMapGroup{
    devices: Device[] = [];
    private _marker: any;

    static getMarkerWithFill(color: string): string{
        return `data:image/svg+xml;utf-8, \
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="${color}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
    <path d="M0 0h24v24H0z" fill="none"/>
</svg>`;
    }

    constructor(private store: Store){
    }

    addDevice(device: Device): void{
        if (device.assignation.store.id === this.store.id) {
            this.devices.push(device);
        }
    }

    get playbackStatus(): number{
        return this.devices.reduce((status, item) => {
            return Math.max(item.remote_status_info?.playback_status || status, status);
        }, 0);
    }

    get lat(): number{
        return this.store.location.geo_point[0];
    }

    get lng(): number{
        return this.store.location.geo_point[1];
    }

    get markerIcon(): any{
        if (!this._marker) {
            this._marker = {
                url: createStoreMarker({
                    text: this.store.getPaddedStoreNum(),
                    fillColor: Utils.getStatusColor(this.playbackStatus),
                })
            };
        }
        return this._marker;
    }
}

class StatEntriesDataSource extends ModelListDataSource<RetailerProgramDeviceStatEntry>{
    childrenDataSource = new Map<string, StatEntriesDataSource>()
    private mapData: Map<string | null, RetailerProgramDeviceStatEntry[]>

    constructor(){
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Retailer',
                    valueReader: (item: RetailerProgramDeviceStatEntry) => {
                        return item.label
                    }
                },
                {
                    key: 'stores_count',
                    label: 'Stores',
                    width: '80px',
                    valueReader: (item: RetailerProgramDeviceStatEntry) => {
                        return item.storesCount
                    }
                },
                {
                    key: 'installed',
                    label: 'Installed',
                    width: '80px',
                    valueReader: (item: RetailerProgramDeviceStatEntry) => {
                        return item.installedCount
                    }
                },
                {
                    key: 'online',
                    label: 'Online',
                    width: '80px',
                    valueReader: (item: RetailerProgramDeviceStatEntry) => {
                        return item.onlineCount
                    }
                },
                {
                    key: 'offline',
                    label: 'Offline',
                    width: '80px',
                    valueReader: (item: RetailerProgramDeviceStatEntry) => {
                        return item.offlineCount
                    }
                },
                {
                    key: 'online_percent',
                    label: 'Online %',
                    width: '80px',
                    valueReader: (item: RetailerProgramDeviceStatEntry) => {
                        if (isNaN(item.onlinePercent)) {
                            return '-'
                        }
                        return item.onlinePercent + ' %'
                    }
                },
            ]
        });
    }

    setMapData(m: Map<string | null, RetailerProgramDeviceStatEntry[]>, rootKey = null){
        this.mapData = m
        this.setLocalData(m.get(rootKey) || [])
    }


    onEmptyData(): Observable<boolean>{
        return EMPTY
    }

    getChildDataSource(parent: RetailerProgramDeviceStatEntry){

        if (!this.isItemExpandable(parent)) {
            return null
        }

        if (!this.childrenDataSource.has(parent.recordId)) {
            const ds = new StatEntriesDataSource()
            ds.setMapData(this.mapData, parent.recordId)
            this.childrenDataSource.set(parent.recordId, ds)
        }
        return this.childrenDataSource.get(parent.recordId)
    }

    isItemExpandable(item: RetailerProgramDeviceStatEntry){
        return this.mapData?.has(item.recordId);
    }

}

const QUERY_DATA = gql`
    query retailerProgramDeviceStats{
        retailerProgramDeviceStats(scope:LatestWithTotals){
            recordId
            parentRecordId
            label
            storesCount
            slotsCount
            installedCount
            assignedCount
            onlineCount
        }
    }
`