<div app-looma-page pageTitle="Store Groups" style="height: 100%; display: flex" [headerSideTpl]="tplHeader">
    <ng-template *ngFor="let retailer of retailers" pageTab="{{retailer.retailer_name}}" [pageTabActive]="svcTabsController.isActiveRetailer(retailer)">
        <app-retailer-store-groups-list [retailer]="retailer"></app-retailer-store-groups-list>
    </ng-template>
</div>

<ng-template #tplHeader>
    <button mat-raised-button (click)="openManageGroups()">Manage Groups</button>
</ng-template>

