import {BaseModel} from '@looma/shared/models/base_model';
import {RetailerPromoProgram} from '@looma/shared/models/retailer_promo_program'
import {DeviceSlot} from '@looma/shared/models/device_slot'
import {RetailerRegion} from '@looma/shared/models/retailer_region'

export class DeviceSlotType extends BaseModel {
    id: string;
    name: string;
    retailerPromoProgram: RetailerPromoProgram;
    defaultBrandSlotsCount: number;
    deviceSlots: DeviceSlot[];
    retailerRegions: RetailerRegion[];

    retailerRegionIds: number[];
    storesCount: number;
    deviceSlotsCount: number;

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'retailerPromoProgram', RetailerPromoProgram);
        this.assignTypedArray(input, 'deviceSlots', DeviceSlot);
        this.assignTypedArray(input, 'retailerRegions', RetailerRegion);
        return this;
    }
}

export interface DeviceSlotTypeMutationInput {
    id: string
    name?: string
    retailerPromoProgramId?: string
    defaultBrandSlotsCount?: number
}
