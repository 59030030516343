<div app-looma-page pageTitle="Schedules" style="height: 100%; display: flex" [headerSideTpl]="tplHeader">
    <ng-template *ngFor="let retailer of retailers" pageTab="{{retailer.retailer_name}}" [pageTabActive]="svcScheduleTabs.isActiveRetailer(retailer)">
        <app-promo-schedules-for-retailer-list [retailer]="retailer"></app-promo-schedules-for-retailer-list>
    </ng-template>
</div>

<ng-template #tplHeader>
    <button mat-raised-button (click)="createNewSchedule()">New</button>
</ng-template>

