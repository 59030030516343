import {BaseModel} from '@looma/shared/models/base_model';
import {RetailerCampaignLibraryFileVersion} from '@looma/shared/models/retailer_campaign_library_file_version'
import {BrandPartner} from '@looma/shared/models/brand_partner'
import {ProductCategory} from '@looma/shared/models/product_category'
import {VideoFileInfo} from '@looma/shared/models/media_file_info'

export class RetailerCampaignLibraryFile extends BaseModel {

    static readonly FILE_STATUS_OK = 100;
    static readonly FILE_STATUS_FAILURE = 101;

    id: number;
    campaign_id: number;
    file_status: number;
    file_name: string;
    error_message: string;
    firebase_job_id: string;
    versions: RetailerCampaignLibraryFileVersion[];
    brand_partner_id: number;
    product_category_id: number;
    brand_partner: BrandPartner;
    product_category: ProductCategory;

    private versionMap: Map<string, RetailerCampaignLibraryFileVersion> = null;


    assign(input: any): this {
        super.assign(input);
        if (Array.isArray(input.versions)) {
            this.versions = input.versions.map(x => new RetailerCampaignLibraryFileVersion().assign(x))
        }
        this.assignNested(input, 'brand_partner', new BrandPartner());
        this.assignNested(input, 'product_category', new ProductCategory());
        return this;
    }

    get fileNameWithoutExtension(): string {
        if (this.file_name) {
            return this.file_name.replace(/\.[^/.]+$/, '')
        }
        return '';
    }


    get processedVersion(): RetailerCampaignLibraryFileVersion {
        return this.getVersionsMap().get('processed');
    }

    get originalVersion(): RetailerCampaignLibraryFileVersion {
        return this.getVersionsMap().get('original');
    }

    get processedVideoInfo(): VideoFileInfo {
        const v = this.processedVersion;
        if (v && v.file_info) {
            return v.file_info.video
        }
        return null
    }

    private getVersionsMap(): Map<string, RetailerCampaignLibraryFileVersion> {
        if (this.versionMap == null) {
            this.versionMap = new Map();
            for (const v of this.versions) {
                this.versionMap.set(v.version_name, v)
            }
        }
        return this.versionMap;

    }
}
