import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';

@Component({
    selector: 'app-releases',
    templateUrl: './app-releases.component.html',
    styleUrls: ['./app-releases.component.scss']
})
export class AppReleasesComponent implements OnInit, OnDestroy {

    constructor(private afStorage: AngularFireStorage) { }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
    }


}


export class FileUpload{

}
