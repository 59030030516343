import {BaseModel} from "@looma/shared/models/base_model";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {DeviceSlotType} from "@looma/shared/models/device_slot_type";
import {RetailerRegion} from "@looma/shared/models/retailer_region";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {PromoPlaylistAssignment} from "@looma/shared/models/promo_playlist_assignment";
import {PromoPeriodDeviceSlotSubgroup} from "@looma/shared/models/promo_period_device_slot_subgroup";

export class RetailerPromoPeriodProgramAssignment extends BaseModel {
    promoPeriod: RetailerPromoPeriod
    deviceSlotType: DeviceSlotType
    retailerRegion: RetailerRegion
    promoProgram: RetailerPromoProgram
    playlistAssignments: PromoPlaylistAssignment[]
    deviceSlotSubgroups: PromoPeriodDeviceSlotSubgroup[]
    


    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'promoPeriod', RetailerPromoPeriod)
        this.assignTypedObject(input, 'deviceSlotType', DeviceSlotType)
        this.assignTypedObject(input, 'retailerRegion', RetailerRegion)
        this.assignTypedObject(input, 'promoProgram', RetailerPromoProgram)
        this.assignTypedArray(input, 'deviceSlotSubgroups', PromoPeriodDeviceSlotSubgroup)
        this.assignTypedArray(input, 'playlistAssignments', PromoPlaylistAssignment)

        return this
    }
}