import {Component, Inject, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {User} from "@looma/shared/models/user";
import {MatSelectionListChange} from "@angular/material/list";
import {ApiDataService} from "../../../services/api-data.service";
import {BrandPromoCampaignReport} from "@looma/shared/models/brand_promo_campaign_report";
import {LayoutService} from "../../../services/layout.service";

@LifecycleHooks()
@Component({
    selector: 'app-campaign-report-email-dialog',
    templateUrl: './campaign-report-email-dialog.component.html',
    styleUrls: ['./campaign-report-email-dialog.component.scss']
})
export class CampaignReportEmailDialogComponent implements OnInit {

    cpr: BrandPromoCampaignReport
    users: User[]
    selectedUsers: User[] = []

    constructor(private dialogRef: MatDialogRef<BrandPromoCampaignReport>,
                private svcApi: ApiDataService,
                private svcLayout: LayoutService,
                @Inject(MAT_DIALOG_DATA) data) {
        if (data && data.hasOwnProperty('users') && data.hasOwnProperty('cpr')) {
            this.users = data.users
            this.cpr = data.cpr
        }
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    onSelectionChanged(event: MatSelectionListChange) {
        for (const opt of event.options) {
            const user: User = opt.value;
            if (opt.selected) {
                this.selectedUsers.push(user)
            } else {
                const index = this.selectedUsers.indexOf(user, 0);
                if (index > -1) {
                    this.selectedUsers.splice(index, 1);
                }
            }
        }
    }

    sendEmail(): void {
        this.svcApi.sendNotificationEmailCampaignReport(
            this.cpr.id,
            this.selectedUsers.map(u => u.email)
        ).subscribe(result => {
            if (result) {
                this.svcLayout.showSnackMessage("Email notification sent to " + this.selectedUsers.map(u => u.email).join(','));
                this.dialogRef.close(result)
            } else {
                this.svcLayout.showSnackMessage('Unexpected error');
            }
        })
    }
}
