import {BaseModel} from '@looma/shared/models/base_model';
import {Retailer} from '@looma/shared/models/retailer'
import {RetailerRegion} from '@looma/shared/models/retailer_region'
import {ProductCategory} from '@looma/shared/models/product_category'
import {Location, LocationInput} from '@looma/shared/models/location'
import {WordFilterable} from '@looma/shared/types/word_filterable';
import {DeviceSlot} from '@looma/shared/models/device_slot'
import {NamedValue} from '@looma/shared/types/named_value';
import {CursorFilter} from '@looma/shared/types/cursor_filter';
import {RetailerDivision} from "@looma/shared/models/retailer_division";

export class Store extends BaseModel implements WordFilterable{
    id: number;
    retailer: Retailer;
    retailer_region: RetailerRegion;
    retailer_store_num?: number;
    location: Location;
    product_categories: ProductCategory[];
    device_slots: DeviceSlot[];
    division: RetailerDivision
    operationsPriority: number

    static from(v: NamedValue): Store{
        if (!v) {
            return null
        }
        const s = new Store();
        s.id = v.intValue();
        s.retailer_store_num = parseInt(v.getDisplayName())
        return s;
    }


    assign(input: any): this{
        super.assign(input);

        this.assignTypedObject(input, 'retailer_region', RetailerRegion);
        this.assignTypedObject(input, 'division', RetailerDivision);
        this.assignNested(input, 'retailer', new Retailer());
        this.assignNested(input, 'location', new Location());
        this.assignTypedArray(input, 'device_slots', DeviceSlot);

        if (!Array.isArray(this.device_slots)) {
            this.device_slots = [];
        }

        if (Array.isArray(input.product_categories)) {
            this.product_categories = input.product_categories.map(x => new ProductCategory().assign(x))
        } else {
            this.product_categories = []
        }

        return this;
    }

    getPhrases(): string[]{
        return [ this.retailer_store_num + '' ];
    }

    getDisplayName(): string{
        if (!this.retailer_store_num) {
            return '-'
        }
        return this.getPaddedStoreNum();
    }

    getPaddedStoreNum(): string{
        let v = this.retailer_store_num;
        if (isNaN(v)) {
            v = 0
        }
        return v.toString().padStart(3, '0');
    }
}

export interface StoreMutationInput{
    id?: string
    retailer_id?: string
    retailer_store_num?: string
    retailer_region_id?: string
    product_category_ids?: string[]
    location?: LocationInput
    division?: string
    operationsPriority?: number
}

export interface StoreFeedFilter extends CursorFilter{
    cursor: string;
    retailer_store_num: string;
    retailer_id: string;
    retailer_region_id: string;
}
