import {Component, HostBinding, Input} from '@angular/core';
import {AppNavigationItem} from '../../../../../shared/app-navigation';


@Component({
    selector   : 'app-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class NavVerticalItemComponent{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: AppNavigationItem;

    /**
     * Constructor
     */
    constructor(){}
}
