import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {animate, AnimationBuilder, AnimationPlayer, style} from '@angular/animations';
import {NavigationEnd, Router} from '@angular/router';

import {filter, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SplashScreenService {

    splashScreenEl: HTMLElement;
    player: AnimationPlayer;
    isVisible: boolean;
    private tokens = new Map<any, number>();

    constructor(
        private _animationBuilder: AnimationBuilder,
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router
    ) {
        this.attach();
    }

    private attach(): void {
        // Get the splash screen element
        this.splashScreenEl = this._document.body.querySelector('#app-splash-screen');
        this.isVisible = false;

        //this.setTokenAdded(this, true)

        // If the splash screen element exists...
        if (this.splashScreenEl) {
            // Hide it on the first NavigationEnd event
            this.isVisible = true;
            this._router.events
                .pipe(
                    filter((event => event instanceof NavigationEnd)),
                    take(1)
                )
                .subscribe(() => {
                    setTimeout(() => {
                        this.hide(this);
                    });
                });
        }
    }

    show(token: any): void {
        if (this.setTokenAdded(token, true)) {
            if (this.tokens.size === 1) {
                this.setVisible(true);
            }
        }
    }

    hide(token: any): void {
        if (this.setTokenAdded(token, false)) {
            if (this.tokens.size === 0) {
                this.setVisible(false);
            }
        }
    }

    private get locksCount(): number {
        let num = 0;
        for (const x of this.tokens.values()) {
            num += x
        }
        return num
    }

    private setTokenAdded(token: any, add: boolean): boolean {
        const originalCount = this.locksCount
        let count = this.tokens.get(token) || 0;
        if (add) {
            count += 1;
        } else {
            count -= 1;

        }
        if (count == 0) {
            this.tokens.delete(token)
        } else if (count > 0) {
            this.tokens.set(token, count)
        } else {
            return false
        }

        if (originalCount != this.locksCount) {
            return true
        }

        return false
    }


    private setVisible(visible: boolean): void {
        if (!!this.splashScreenEl) {
            if (this.isVisible === visible) {
                return;
            }
            this.isVisible = visible;
            if (this.isVisible) {
                this.player =
                    this._animationBuilder
                        .build([
                            style({
                                opacity: '0',
                                zIndex: '99999'
                            }),
                            animate('400ms ease', style({opacity: '1'}))
                        ]).create(this.splashScreenEl);
            } else {
                this.player =
                    this._animationBuilder
                        .build([
                            style({opacity: '1'}),
                            animate('400ms ease', style({
                                opacity: '0',
                                zIndex: '-10'
                            }))
                        ]).create(this.splashScreenEl);
            }
            setTimeout(() => {
                this.player.play();
            }, 0);
        }
    }
}
