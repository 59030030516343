<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>

    <mat-form-field class="full-width">
        <mat-label>Playlist</mat-label>
        <mat-select [formControl]="formControls.playlist">
            <mat-option *ngFor="let x of availablePlaylistAssignments" [value]="x">{{x.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="hasErrorMessage(formControls.playlist)">{{getErrorMessage(formControls.playlist)}}</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width mb-24">
        <input matInput autocomplete="off"
               [formControl]="formControls.cronExp"
               placeholder="Cron Expression"/>
        <mat-error *ngIf="hasErrorMessage(formControls.cronExp)">{{getErrorMessage(formControls.cronExp)}}</mat-error>
        <mat-hint>
            <span>Please use a cron expression generator, like <a href="https://www.freeformatter.com/cron-expression-generator-quartz.html" target="_blank"> this </a> one </span>
            <br /><span>Valid expressions need to have 6 fields ( Eg * * * * * * )</span>
        </mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width mb-24">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [min]="promoPeriod.startDate" [max]="promoPeriod.endDate" [formGroup]="dateControl">
            <input readonly matStartDate placeholder="Start date" formControlName="startDate">
            <input readonly matEndDate placeholder="End date" formControlName="endDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <button *ngIf="hasRangeSelected()" mat-button matSuffix class="datepicker_clear_button" (click)="clearSelectedDateRange()">
            <mat-icon>clear</mat-icon>
        </button>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-hint>Leave empty if this schedule applies for the entire {{promoPeriod.name}} promo period duration</mat-hint>
        <mat-error *ngIf="hasErrorMessage(this.formControls.startDate)">{{getErrorMessage(this.formControls.startDate)}}</mat-error>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="isBusy || !canSave()" (click)="handleAction('ok')">Ok</button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>
