import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {LayoutService} from "../../../../services/layout.service";
import {DeviceSlot} from "@looma/shared/models/device_slot";
import {EMPTY, Observable, of, Subscription} from "rxjs";
import {FileProgressData, onWindowResize, Utils} from "@looma/shared/utils";
import {UploadedFileInfo} from "@looma/shared/services/upload.service";
import {
    SplashTemplateRenderer
} from "../../lib/splash_template_drawer";
import {MatSliderChange} from "@angular/material/slider";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {debounce, debounceTime, takeUntil} from "rxjs/operators";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Retailer} from "@looma/shared/models/retailer";
import {
    getTemplateDataForRetailer,
    ImageTemplateData,
    ImageTemplateVariables,
} from "../../lib/templates";

@LifecycleHooks()
@Component({
    selector: 'app-splash-image-editor-dialog',
    templateUrl: './splash-image-editor-dialog.component.html',
    styleUrls: ['./splash-image-editor-dialog.component.scss']
})
export class SplashImageEditorDialogComponent implements OnInit {

    renderer: SplashTemplateRenderer

    logoScale: number

    private windowResizeSub: Subscription
    templateVars: ImageTemplateVariables = {}

    @ViewChild("canvasWrapEl") set canvasWrapEl(ref: ElementRef<HTMLCanvasElement>) {
        Utils.unsubscribe(this.windowResizeSub)
        const el = ref?.nativeElement
        const parentEl = el?.parentNode as HTMLElement
        if (!parentEl) {
            return
        }

        onWindowResize(true).pipe(
            debounceTime(10),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            const parentWidth = parentEl.clientWidth, parentHeight = parentEl.clientHeight
            let childWidth = parentWidth, childHeight = parentWidth * 9 / 16
            if (childHeight > parentHeight) {
                childHeight = parentHeight
                childWidth = parentHeight * 16 / 9
            }
            el.style.width = `${childWidth}px`
            el.style.height = `${childHeight}px`
            this.renderer?.setViewportSize(childWidth, childHeight)
        })
    }

    @ViewChild("canvasEl") set canvasEl(ref: ElementRef<HTMLCanvasElement>) {
        this.renderer = new SplashTemplateRenderer(ref.nativeElement, this.getTemplateData())

        this.renderer.applyTemplateVars(this.templateVars)

    }

    @ViewChild("backgroundImageInput") backgroundImageInputEl: ElementRef<HTMLInputElement>

    static open(svcLayout: LayoutService, data?: SplashImageEditorDialogComponentData): Observable<Blob> {

        return svcLayout.openDialogForResult(SplashImageEditorDialogComponent, {
            data: data,
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            panelClass: 'splash-image-editor-dialog',
        })

    }

    constructor(
        private dialogRef: MatDialogRef<SplashImageEditorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: SplashImageEditorDialogComponentData
    ) {
        this.templateVars = dialogData?.templateVars || {}
        this.logoScale = this.templateVars.logo?.scale || 1
    }

    ngOnInit(): void {
    }

    openFileBackgroundPicker() {
        this.backgroundImageInputEl.nativeElement.click()
    }

    onBackgroundImagePicked(event): void {
        console.warn('onBackgroundImagePicked', event)
        if (event.target.files.length > 0) {
            const url = URL.createObjectURL(event.target.files[0])
            this.renderer.applyTemplateVars({
                backgroundImageUrl: url
            })
        }
    }

    handleLogoScaleChanged(value: number) {
        this.renderer.applyTemplateVars({
            logo: {
                scale: value,
            }
        })
    }

    handleTitleChanged(value: string) {
        this.renderer.applyTemplateVars({
            title: {
                text: value
            }
        })
    }

    setResult(f: Blob) {
        this.dialogRef.close(f)
    }

    cancel() {
        this.setResult(null)
    }

    save() {
        this.renderer.toBlob().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            this.setResult(value)
        })
    }

    getTemplateData(): ImageTemplateData {
        return getTemplateDataForRetailer(this.dialogData.retailer.getStringId())

    }

}

export interface SplashImageEditorDialogComponentData {
    retailer: Retailer,
    templateVars?: ImageTemplateVariables,
}