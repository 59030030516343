import {BaseModel} from '@looma/shared/models/base_model';

export class RemoteAccelerometerInfo extends BaseModel {
    X: number;
    Y: number;
    Z: number;

    deviation_x: number;
    deviation_y: number;
    deviation_z: number;

    calibration_threshold: number;
    isCalibrated: boolean;
}
