import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LayoutService} from "../../../../services/layout.service";
import {ApiDataService} from "../../../../services/api-data.service";
import {RetailerPromoPeriod, RetailerPromoPeriodStatus} from "@looma/shared/models/retailer_promo_periods";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {switchMap, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    MUTATION_UPSERT_SUBMISSION_SCHEDULE,
    QUERY_PROGRAM_SUBMISSION_SCHEDULE,
    QUERY_PROMO_PERIOD,
    RetailerPromoPeriodSubmissionSchedule,
    RetailerPromoPeriodSubmissionScheduleMutationInput,
    SegmentCost,
    SubmissionScheduleCriteria,
} from "@looma/shared/models/retailer_promo_period_submission_schedule";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {RetailerPromoPeriodSubmission} from "@looma/shared/models/retailer_promo_period_submission";

@LifecycleHooks()
@Component({
    selector: 'app-program-submissions-overview',
    templateUrl: './program-submissions-overview.component.html',
    styleUrls: ['./program-submissions-overview.component.scss']
})
export class ProgramSubmissionsOverviewComponent implements OnInit {

    customCollapsedHeight = '100px';
    customExpandedHeight = '190px';

    today = new Date();

    form: FormGroup;

    retailerPromoPeriod: RetailerPromoPeriod
    retailerPromoProgram: RetailerPromoProgram
    retailerPromoPeriodSubmissionSchedule: RetailerPromoPeriodSubmissionSchedule

    isThematic = false

    dataSource: RetailerPromoPeriodSubmission[]

    availableSegments: string[]
    availableAirtimeSegments: string[]

    segmentGroups: SegmentCost[] = [];

    readyForReviewChanged = false

    displayedColumns: string[] = ['submittedByBrand', 'brandPartner', 'featuredProducts', 'note', 'status', 'submittedOn', 'submittedBy'];

    static open(router: Router, promoPeriod: RetailerPromoPeriod, prog: RetailerPromoProgram) {
        if (!(promoPeriod || prog)) {
            return
        }
        router.navigate([`promo-schedules/program-submissions/promo-period/${promoPeriod.id}/promo-program/${prog.id}`])
    }

    constructor(private activatedRoute: ActivatedRoute,
                private svcLayout: LayoutService,
                private router: Router,
                private formBuilder: FormBuilder,
                private svcApi: ApiDataService) {
    }

    ngOnInit(): void {
        this.today.setHours(0, 0, 0, 0);
        const promoProgramId = this.activatedRoute.snapshot.paramMap.get("promoProgramId") || null
        if (promoProgramId == null) {
            return;
        }

        const promoPeriodId = this.activatedRoute.snapshot.paramMap.get("promoPeriodId") || null
        if (promoPeriodId == null) {
            return;
        }

        this.fetchPromoPeriodAndSubmissionSchedule(promoPeriodId, promoProgramId)
    }

    getRetailerPromoPeriod(promoPeriodId) {
        const variables = {id: promoPeriodId};
        return this.svcApi.rawQuery({query: QUERY_PROMO_PERIOD, variables: variables})
            .pipe(takeUntil(Utils.onDestroy(this)));
    }

    getProgramSubmissionSchedule(promoPeriod, promoProgram) {
        const criteria: SubmissionScheduleCriteria = {
            promoPeriodId: promoPeriod,
            promoProgramId: promoProgram
        };
        return this.svcApi.rawQuery({
            query: QUERY_PROGRAM_SUBMISSION_SCHEDULE,
            fetchPolicy: "no-cache",
            variables: {
                criteria: criteria
            }
        }).pipe(takeUntil(Utils.onDestroy(this)));
    }

    fetchPromoPeriodAndSubmissionSchedule(promoPeriodId, promoProgramId) {
        this.getRetailerPromoPeriod(promoPeriodId).pipe(
            switchMap(result => {
                this.retailerPromoPeriod = result.data["retailerPromoPeriod"] as RetailerPromoPeriod;
                this.retailerPromoProgram = this.retailerPromoPeriod.promoPrograms.find(t => t.id === promoProgramId);
                this.isThematic = this.retailerPromoProgram?.programType == "Thematic"
                return this.getProgramSubmissionSchedule(this.retailerPromoPeriod.id, this.retailerPromoProgram.id);
            })
        ).subscribe({
            next: result => {
                this.retailerPromoPeriodSubmissionSchedule = new RetailerPromoPeriodSubmissionSchedule()
                this.retailerPromoPeriodSubmissionSchedule.assign(result.data["programSubmissionSchedule"])
                if (this.retailerPromoPeriodSubmissionSchedule?.submissions) {
                    this.dataSource = this.retailerPromoPeriodSubmissionSchedule?.submissions
                } else {
                    this.dataSource = []
                }
                this.initForm()
            },
            error: err => {
                console.error('Error fetching data:', err);
            },
        });
    }

    canSave(): boolean {
        if (!this.form) {
            return false;
        }
        if (this.readyForReviewChanged) {
            return true
        }
        if (this.retailerPromoPeriod?.status != RetailerPromoPeriodStatus.Pending) {
            return false
        }

        const openForSubmission = this.form.get('openForSubmissions').value
        const dueDate = this.form.get('dueDate').value

        if (openForSubmission && !dueDate) {
            return false
        }
        return this.form.dirty
    }

    doSave() {
        const openForSubmission = this.form.get('openForSubmissions').value
        const readyForReview = this.form.get('readyForReview').value
        const dueDate = new Date(this.form.get('dueDate').value)
        const campaignCost = this.form.get('campaignCost').value
        const theme = this.form.get('theme').value

        const data: RetailerPromoPeriodSubmissionScheduleMutationInput = {
            id: this.retailerPromoPeriodSubmissionSchedule?.id,
            promoPeriodId: this.retailerPromoPeriod.id,
            promoProgramId: this.retailerPromoProgram.id,
            openForSubmission: openForSubmission,
            readyForReview: readyForReview,
            dueDate: this.makeUtcDate(dueDate).toISOString(),
            campaignCost: campaignCost,
            theme: theme,
            segments: this.segmentGroups,
        }

        this.svcApi.rawObjectMutate(MUTATION_UPSERT_SUBMISSION_SCHEDULE, {
            op: MutationOperation.Create,
            input: data
        }, RetailerPromoPeriodSubmissionSchedule).subscribe(result => {
            if (result.success) {
                this.form.markAsPristine()
                this.retailerPromoPeriodSubmissionSchedule = result.data
                this.svcLayout.showSnackMessage("Submissions schedule saved")
            } else {
                this.svcLayout.showMutationResultMessage(result)
            }
        })
    }


    private initForm() {
        this.form = this.formBuilder.group({
            openForSubmissions: [this.retailerPromoPeriodSubmissionSchedule?.openForSubmission],
            readyForReview: [this.retailerPromoPeriodSubmissionSchedule?.readyForReview],
            dueDate: [this.retailerPromoPeriodSubmissionSchedule?.dueDate, Validators.required],
            campaignCost: [this.retailerPromoPeriodSubmissionSchedule?.campaignCost],
            theme: [this.retailerPromoPeriodSubmissionSchedule?.theme],
        });

        this.form.get('openForSubmissions').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('dueDate').enable();
                this.form.get('campaignCost').enable();
                if (this.isThematic) {
                    this.form.get('theme').enable();
                } else {
                    this.form.get('theme').disable();
                }
            } else {
                this.form.get('dueDate').disable();
                this.form.get('campaignCost').disable();
                this.form.get('theme').disable();
            }
        });

        this.form.get('readyForReview').valueChanges.subscribe(value => {
                if (this.retailerPromoPeriodSubmissionSchedule?.readyForReview != value) {
                    this.readyForReviewChanged = true
                }
            }
        );

        this.availableSegments = this.retailerPromoProgram.segments
        this.availableAirtimeSegments = this.retailerPromoProgram.airtimeSegments

        const createSegmentItem = (segmentName: string, groupName: string = "segment") => {
            return {
                segmentName: segmentName,
                segmentCost: this.getSegmentValue(groupName, segmentName)
            };
        };

        const addToSegmentGroups = (groupName, segments) => {
            this.segmentGroups.push({
                groupName: groupName,
                segments: segments.map(s => createSegmentItem(s, groupName))
            });
        };

        if (this.airTimesAvailable()) {
            if (!this.availableSegments || this.availableSegments.length == 0) {
                addToSegmentGroups("airtime", this.availableAirtimeSegments);
            } else {
                for (const airtimeSegment of this.availableAirtimeSegments) {
                    addToSegmentGroups(airtimeSegment, this.availableSegments);
                }
            }
        } else if (this.availableSegments && this.availableSegments.length > 0) {
            addToSegmentGroups("segment", this.availableSegments);
        }

        if (this.retailerPromoPeriod && this.retailerPromoPeriod.status != RetailerPromoPeriodStatus.Pending) {
            // this.form.get('openForSubmissions').disable()
            this.form.get('dueDate').disable()
            this.form.get('campaignCost').disable();
            this.form.get('theme').disable();
        } else {
            this.form.get('openForSubmissions').enable()
        }
    }


    makeUtcDate(d: Date): Date | null {
        if (!(d instanceof Date)) {
            return null;
        }
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
    }

    onPreferredProductsClicked() {
        if (this.retailerPromoPeriodSubmissionSchedule) {
            this.router.navigateByUrl(`promo-schedules/program-submissions/${this.retailerPromoPeriodSubmissionSchedule.id}/products`).then()
        }
    }

    onBrandsClicked() {
        if (this.retailerPromoProgram) {
            this.router.navigateByUrl(`promo-schedules/program-submissions/promo-program/${this.retailerPromoProgram.id}`).then()
        }
    }

    onSegmentChange(): void {
        this.form.markAsDirty();
    }

    saveSegmentValue(group: string, segmentName: string, segmentValue: number): void {
        if (!this.segmentGroups[group]) {
            this.segmentGroups[group] = {};
        }
        this.segmentGroups[group][segmentName] = segmentValue;
    }

    private getSegmentValue(groupName: string, segmentName: string):
        number | null {
        const groupEntry = this.retailerPromoPeriodSubmissionSchedule?.segments?.find(segment =>
            segment.groupName === groupName
        );
        if (groupEntry) {
            const segmentEntry = groupEntry.segments?.find(segment => segment.segmentName === segmentName);
            if (segmentEntry) {
                return segmentEntry.segmentCost;
            }
        }
        return null;
    }

    airTimesAvailable(): boolean {
        return this.availableAirtimeSegments && this.availableAirtimeSegments.length > 0
    }

    displaySegmentsForm(): boolean {
        return this.retailerPromoProgram?.segments?.length > 0 || this.retailerPromoProgram?.airtimeSegments?.length > 0
    }

    onEnrollmentClicked() {
        if (this.retailerPromoPeriodSubmissionSchedule) {
            this.router.navigateByUrl(`promo-schedules/program-submissions/${this.retailerPromoPeriodSubmissionSchedule.id}/enrolled_products`).then()
        }
    }
}
