<div app-looma-page [pageTitle]="pageTitle" [pageSubtitle]="pageSubtitle" pageIcon="group_work">
    <div fxFill class="p-8">
        <div fxFill fxLayout="row">
            <div fxFlex="300px" class="section" [class.disabled]="hasDirtyPlaylistSchedules">
                <div class="section_overlay"></div>
                <div class="panel inline">
                    <mat-selection-list [multiple]="false" (selectionChange)="onOptionSelected($event)"
                                        [disabled]="hasDirtyPlaylistSchedules">
                        <mat-list-option [class.selected-entry]="isActiveSelection(entry)"
                                         *ngFor="let entry of slotEntries"
                                         [value]="entry" [selected]="isActiveSelection(entry)">
                            {{entry.subgroup.name}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>

            </div>
            <div class="section" fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="8px" *ngIf="activeSlotEntry"
                 [class.disabled]="hasDirtyPlaylistSchedules">
                <div class="section_overlay"></div>
                <div class="section_header" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="section_title">Subgroup Device Slots</div>
                    <button mat-icon-button [matMenuTriggerFor]="deviceSlotMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>
                <mat-menu #deviceSlotMenu="matMenu">
                    <button mat-menu-item (click)="handleMenuOptionForCurrentEntry('edit')">Edit</button>
                    <button mat-menu-item (click)="handleMenuOptionForCurrentEntry('delete')">Delete</button>
                </mat-menu>
                <div class="panel mat-elevation-z3" fxFlex="1 1 auto">
                    <app-device-slot-assignment-list
                        [dataSource]="activeSlotEntry.slotDataSource"></app-device-slot-assignment-list>
                </div>
                <div class="panel mat-elevation-z3" fxFlex="1 1 auto">

                    <app-device-slot-assignment-list
                        [dataSource]="controller.defaultItemDataSource"></app-device-slot-assignment-list>
                </div>
            </div>

            <div class="section" fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="8px" *ngIf="activeSlotEntry"
                 [class.disabled]="hasDirtySlotAssignments">
                <div class="section_overlay"></div>

                <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="8px" *ngIf="activeSlotEntry">
                    <div class="section_header" fxLayout="row" fxLayoutAlign="space-between center">
                        <div>
                            <div class="section_title">Playlist Schedule Rules</div>
                            <span class="small_hint">First rule has the highest priority, last rule has the lowest priority</span>    
                        </div>
                        
                    </div>
                    <div class="panel mat-elevation-z3" style="max-height: 50%">
                        <div class="panel-row header">
                            <div class="priority-column">Priority</div>
                            <div class="drag-handle-column "></div>
                            <div class="name-column">Playlist</div>
                            <div class="cron-exp-column">Cron Exp</div>
                            <div class="date-column">Start Time</div>
                            <div class="date-column">End Time</div>
                            <div class="actions-column">

                            </div>
                        </div>
                        <div class="panel-body" cdkDropList
                             (cdkDropListDropped)="activeSlotEntry.changePlaylistItemPosition($event.previousIndex, $event.currentIndex)">
                            <div class="panel-row"
                                 *ngFor="let schedulePlaylistEntry of activeSlotEntry.playlistScheduleEntries; let idx=index"
                                 cdkDrag>
                                <div class="priority-column">{{idx+1}}</div>
                                <div class="drag-handle-column">
                                    <mat-icon cdkDragHandle>drag_indicator
                                    </mat-icon>
                                </div>
                                
                                <div class="name-column">{{schedulePlaylistEntry.playlistAssignment.name}}</div>
                                <div class="cron-exp-column">{{schedulePlaylistEntry.cronExpression}}</div>
                                <ng-container
                                    *ngTemplateOutlet="dateColumn;context:{date: schedulePlaylistEntry.startTime}"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="dateColumn;context:{date: schedulePlaylistEntry.endTime}"></ng-container>
                                <div class="actions-column">
                                    <button class="small" mat-icon-button [matMenuTriggerFor]="playlistEntryMenu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #playlistEntryMenu="matMenu">
                                        <button mat-menu-item
                                                (click)="editPlaylistScheduleEntry(schedulePlaylistEntry)">Edit
                                        </button>
                                        <button mat-menu-item
                                                (click)="deletePlaylistScheduleEntry(schedulePlaylistEntry)">Delete
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer panel-row" fxLayout="row" fxLayoutAlign="end center">
                            <button mat-button (click)="evaluatePlaylistSchedules()">Evaluate</button>
                        </div>
                    </div>
                    <div class="panel mat-elevation-z3" fxFlex="1 1 auto">
                        <looma-grid [title]="scheduleEvalResultTitle"
                                    [dataSource]="evaluatedPlaylistScheduleDataSource"></looma-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pageHeaderNav>
        <div fxLayout="row" fxLayoutGap="16px">
            <button mat-raised-button (click)="addNewSubgroup()" [disabled]="isBusy || hasDirtyPlaylistSchedules">New
                Subgroup
            </button>
            <button mat-raised-button (click)="addNewPlaylistScheduleEntry()"
                    [disabled]="!activeSlotEntry || isBusy || hasDirtySlotAssignments">New Playlist Schedule Rule
            </button>
            <button mat-raised-button color="warn" (click)="saveChanges()"
                    [disabled]="isBusy || !(hasDirtySlotAssignments || hasDirtyPlaylistSchedules)">Save
            </button>
        </div>
    </ng-template>

</div>


<ng-template #dateColumn let-date="date">
    <div class="date-column">
        <span *ngIf="date">{{date | date:'short'}}</span>
        <span *ngIf="!date">-</span>
    </div>
</ng-template>
