import { Inject, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AsyncSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, flatMap, map, switchMap, take, timeout } from 'rxjs/operators';
import {
    PromptDialogComponent,
    PromptDialogData,
    PromptDialogSearchConfig
} from '../layout/components/prompt-dialog/prompt-dialog.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Utils } from '@looma/shared/utils';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { GridDataSource, IDataSourceEntry } from '../layout/components/looma-grid/grid-data-source';
import { BaseModel } from '@looma/shared/models/base_model';
import { NamedValue } from '@looma/shared/types/named_value';
import { MutationResponse } from '@looma/shared/types/mutation_response';
import { ErrorSnackbarComponent } from '../layout/components/error-snackbar/error-snackbar.component';
import { ErrorSnackbarData } from '@looma/shared/types/error-snackbar-data';
import { LoomaLayoutService } from '@looma/shared/services/looma-layout.service';
import { MediaObserver } from '@angular/flex-layout';
import { DOCUMENT } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class LayoutService extends LoomaLayoutService{

    constructor(
        private overlay: Overlay,
        public mediaObserver: MediaObserver,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        @Inject(DOCUMENT) private document: Document
    ){
        super(mediaObserver, snackBar, dialog);

    }

    wrapObservableWithMessages<T>(source: Observable<T>, messages: {
        pending: string,
        success: string,
        failure: string
    }): Observable<T>{
        let isDone = false;
        const setDone = (msg: string): void => {
            if (!isDone) {
                isDone = true;
                this.snackBar.open(msg, null, { duration: 5000 });
            }
        };

        return new Observable(subscriber => {
            this.snackBar.open(messages.pending);
            subscriber.next(true);
            subscriber.complete()
        }).pipe(
            flatMap(value => {
                return source
            }),
            catchError(err => {
                setDone(Utils.extractErrorMessage(err, messages.failure));
                return throwError((err))
            }),
            finalize(() => {
                setDone(messages.success);
            })
        )
    }


    prompt(titleOrDialogData: string | Partial<PromptDialogData>, placeholder?: string, config?: {
        width?: string
    }): Observable<string>{
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = config?.width || '300px';

        let cfg = {} as PromptDialogData

        if (Utils.isString(titleOrDialogData)) {
            cfg.title = titleOrDialogData.toString()
            cfg.message = placeholder
        } else if (Utils.isObject(titleOrDialogData)) {
            cfg = titleOrDialogData as PromptDialogData
        }

        cfg.type = cfg.type || 'text'

        dialogConfig.data = cfg;

        const dialogRef = this.dialog.open(PromptDialogComponent, dialogConfig);
        return dialogRef.afterClosed().pipe(
            filter(value => !!value)
        );
    }

    promptSearchObject(title: string, placeholder: string, searchConfig: PromptDialogSearchConfig): Observable<NamedValue>{
        const cfg: PromptDialogData = {
            type: 'search',
            title: title,
            message: placeholder,
            searchConfig: searchConfig,
        };

        return this.openDialogForResult(PromptDialogComponent, {
            data: cfg,
        })
    }

    showSnackErrors(data: ErrorSnackbarData[]): MatSnackBarRef<SimpleSnackBar>{
        if (Array.isArray(data) && data.length) {
            if (this.activeSnackbar) {
                this.activeSnackbar.dismiss();
            }
            this.activeSnackbar = this.snackBar.openFromComponent(ErrorSnackbarComponent, {
                duration: 5000,
                panelClass: 'warn-snackbar',
                verticalPosition: 'bottom',
                data: data
            });

            return this.activeSnackbar;
        }
        return null
    }

    showMutationResultMessage(resp: MutationResponse<any>): MatSnackBarRef<SimpleSnackBar>{
        if (resp.success) {
            return null
        }
        const data = Utils.extractMutationErrors(resp)
        return this.showSnackErrors([ data ]);
    }

    showSnackMessage(msg: string, duration: number = 5000): MatSnackBarRef<SimpleSnackBar>{
        if (this.activeSnackbar) {
            this.activeSnackbar.dismiss();
        }
        const snack = this.snackBar.open(msg, null, { duration: duration });
        snack.afterDismissed().subscribe(value => {
            if (snack === this.activeSnackbar) {
                this.activeSnackbar = null
            }
        });
        this.activeSnackbar = snack;
        return snack;
    }

    openRowEditor<T extends BaseModel, Q extends GridRowEditor<T>>(
        dataSource: GridDataSource<T, IDataSourceEntry<T>>,
        dataSourceEntry: T,
        editRowTpl: TemplateRef<any>,
        viewContainerRef: ViewContainerRef,
        editor: Q,
        context?: object): Observable<T>{
        if (!dataSource.attachedComponent) {
            return throwError('datasource not attached')
        }

        const rootEl = dataSource.attachedComponent.rootEl.nativeElement as HTMLElement;
        const rowSelector = `[data-row-id='${dataSourceEntry.getId()}'] .mat-grid-row`;

        const findTarget = (): HTMLElement => {
            return rootEl.querySelector(rowSelector) as HTMLElement;
        };

        return new Observable<HTMLElement>(subscriber => {

            const foundRow = findTarget();
            if (foundRow) {
                subscriber.next(foundRow);
                return
            }

            const mutationObserver = new MutationObserver(() => {
                const foundRow = findTarget();
                if (foundRow) {
                    subscriber.next(foundRow);
                    return
                }
            });

            mutationObserver.observe(rootEl, {
                attributes: true,
                // characterData: true,
                childList: true,
                subtree: true,
                attributeOldValue: true,
                // characterDataOldValue: true
            });

            return () => {
                mutationObserver.disconnect()
            }

        }).pipe(
            timeout(1000),
            catchError(err => of(null)),
            take(1),
            switchMap((rowHtmlEl: HTMLElement) => {
                console.warn('editor', editor)
                if (!rowHtmlEl) {
                    editor.dismiss(null)
                    return editor.onDismissed();
                }
                return this.openGridRowEditor(rowHtmlEl, editRowTpl, viewContainerRef, editor, context) as Observable<T>
            })
        )

    }

    private openGridRowEditor<T, Q extends GridRowEditor<T>>(targetRowEl: HTMLElement, editRowTpl: TemplateRef<any>, viewContainerRef: ViewContainerRef, editor: Q, context?: object): Observable<T>{
        const portalContext = (context || {}) as { editor: any };
        portalContext.editor = editor;

        while (targetRowEl && (targetRowEl.className.indexOf('mat-grid-row') == -1)) {
            targetRowEl = targetRowEl.parentElement
        }
        const portal = new TemplatePortal(editRowTpl, viewContainerRef, portalContext);

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().flexibleConnectedTo(targetRowEl).withPositions([ {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'top',
            } ]),
            hasBackdrop: true,
            width: targetRowEl.clientWidth,
            height: targetRowEl.clientHeight,
        });

        overlayRef.attach(portal);

        const cells = Array.from(targetRowEl.querySelectorAll('[data-column]')) as HTMLElement[];
        let left = 0;
        const cssRules: string[] = [];

        for (const cell of cells) {
            const style = window.getComputedStyle(cell);

            const pLeft = parseInt(style.paddingLeft),
                pRight = parseInt(style.paddingRight),
                mLeft = parseInt(style.marginLeft),
                mRight = parseInt(style.marginRight),
                totalWidth = cell.clientWidth,
                width = totalWidth - pLeft - pRight - mLeft - mRight,
                height = cell.clientHeight;


            cssRules.push(`[data-proxy-column=${cell.getAttribute('data-column')}]{position:absolute; top:0; left:${left + mLeft + pLeft}px; width:${width}px; height: ${height}px;`);
            left += totalWidth;
        }

        Utils.appendCssRulesUntil(editor.onDismissed(), overlayRef.overlayElement, ...cssRules);
        editor.onAttached(overlayRef);
        return editor.onDismissed();
    }

}

export class GridRowEditor<T>{
    private dismissSubject = new AsyncSubject<T>();
    private overlayRef: OverlayRef;

    onAttached(overlayRef: OverlayRef){
        this.overlayRef = overlayRef;
    }

    dismiss(res?: T){
        res = res || null;
        if (!this.isDone) {
            if (this.overlayRef) {
                this.overlayRef.detach();
                this.dismissSubject.next(res);
                this.overlayRef = null;
            }

            this.dismissSubject.complete();

        }
    }

    backdropClick(): Observable<GridRowEditor<T>>{
        if (!this.overlayRef) {
            return EMPTY
        }
        return this.overlayRef.backdropClick().pipe(
            map(value => this)
        )
    }

    private get isDone(): boolean{
        return this.dismissSubject.isStopped
    }

    onDismissed(): Observable<T>{
        return this.dismissSubject
    }
}
