<h2 mat-dialog-title>Send CPR notification email</h2>
<mat-dialog-content>
    <mat-selection-list (selectionChange)="onSelectionChanged($event)">
        <mat-list-option *ngFor="let user of users || []"
                         [value]="user">
            <div style="margin-bottom: 10px;">
                <span>{{user.display_name}}</span>
                <div style="color: #2D323D; font-size: 14px">{{user.email}}</div>
            </div>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="sendEmail()">Send email</button>
    <button mat-button (click)="close()">Cancel</button>
</mat-dialog-actions>
