<div app-looma-page pageTitle="Stores" pageIcon="shopping_cart">
    <looma-grid [dataSource]="dataSource">
    </looma-grid>

    <ng-template pageHeaderNav>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <mat-form-field>
                <mat-label>Store Num</mat-label>
                <input autocomplete="off"  matInput fxFlex (input)="searchForStore($event)" />
            </mat-form-field>

            <app-autocomplete-search-field
                showClear="true"
                placeholder="Retailer"
                searchObjectType="Retailer"
                (valueChanged)="onRetailerFilterChanged($event)"
                selectionMode="single" style="width: 300px;">
            </app-autocomplete-search-field>

            <app-autocomplete-search-field
                [disabled]="!retailerRegionSearchCriteria"
                [searchCriteria]="retailerRegionSearchCriteria"
                [value]="selectedRetailerRegion"
                showClear="true"
                placeholder="Retailer Region"
                searchObjectType="RetailerRegion"
                (valueChanged)="onRetailerRegionFilterChanged($event)"
                selectionMode="single" style="width: 200px;">
            </app-autocomplete-search-field>

            <button mat-raised-button (click)="addNewRetailer()">New Store</button>
        </div>

    </ng-template>
</div>
