import {Component, Inject, OnInit} from '@angular/core';
import {ModelEditDialog} from '../../../../shared/model_edit_dialog';
import {RetailerPromoSchedule, RetailerPromoScheduleMutationInput} from '@looma/shared/models/retailer_promo_schedule';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LayoutService} from '../../../../services/layout.service';
import {Observable} from 'rxjs';
import {MutationResponse} from '@looma/shared/types/mutation_response';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import gql from 'graphql-tag';
import {NamedValue} from '@looma/shared/types/named_value';
import {ApiDataService} from '../../../../services/api-data.service';
import {map} from 'rxjs/operators';
import {Utils} from '@looma/shared/utils';
import {Retailer} from '@looma/shared/models/retailer';
import {DocumentNode} from 'graphql';
import {MutationOperation} from '@looma/shared/models/mutation_operation';

@LifecycleHooks()
@Component({
    selector: 'app-promo-schedule-edit-dialog',
    templateUrl: './promo-schedule-edit-dialog.component.html',
    styleUrls: ['./promo-schedule-edit-dialog.component.scss']
})
export class PromoScheduleEditDialogComponent extends ModelEditDialog<RetailerPromoSchedule> implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<PromoScheduleEditDialogComponent>,
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        @Inject(MAT_DIALOG_DATA) private dialogData: PromoScheduleEditDialogData
    ) {
        super();
        this.promoSchedule = dialogData.schedule;

        if(this.promoSchedule.isNewRecord()){
            this.promoSchedule.defaultPromoPeriodDurationDays = 28;
        }

        this.promoSchedule.name = this.promoSchedule.name || '';
        this.title = this.promoSchedule.isNewRecord() ? 'New Retailer Schedule' : `Edit ${this.promoSchedule.name}`
    }

    promoSchedule: RetailerPromoSchedule;
    title: string;

    static open(svcLayout: LayoutService, schedule: RetailerPromoSchedule, scheduleGqlFields: string): Observable<RetailerPromoSchedule>{
        const sch = new RetailerPromoSchedule();
        Object.assign(sch, schedule);
        const data: PromoScheduleEditDialogData = {
            schedule: sch,
            scheduleGqlFields: scheduleGqlFields,
        };
        return svcLayout.openDialogForResult(PromoScheduleEditDialogComponent, {
            data: data
        })
    }

    ngOnInit() {
    }


    onSave(): Observable<MutationResponse<RetailerPromoSchedule>> | null {
        this.promoSchedule.name = this.promoSchedule.name.trim();

        const data: RetailerPromoScheduleMutationInput = {
            id:  this.promoSchedule.getStringId(),
            name: this.promoSchedule.name.trim(),
            retailerId: this.promoSchedule.retailer.getStringId(),
            defaultPromoPeriodDurationDays: this.promoSchedule.defaultPromoPeriodDurationDays
        }

        const op = this.promoSchedule.isNewRecord() ? MutationOperation.Create : MutationOperation.Update;

        return this.svcApi.mutatePromoSchedule(op, data, this.dialogData.scheduleGqlFields)
    }

    canSave(): boolean {
        if((this.promoSchedule.name || '').trim() == ''){
            return false
        }
        if(!this.promoSchedule.retailer){
            return false
        }
        return true;
    }

    onRetailerSelected(data: NamedValue){
        if(!data){
            this.promoSchedule.retailer = null;
            return;
        }
        const currentId = this.promoSchedule.retailer ? undefined : this.promoSchedule.id;
        const selectedId = data.getId();
        if(selectedId != currentId){
            const loadRetailer: Observable<Retailer> = this.svcApi.rawQuery({
                query: QUERY_FETCH_RETAILER,
                variables:{
                    id: selectedId
                }
            }).pipe(
                map(value1 => {
                    const data = Utils.getNestedTypedArray(value1, Retailer, 'data', 'retailers');
                    if(Array.isArray(data) && data.length){
                        return data[0]
                    }
                    return null
                })
            );


            this.wrap(loadRetailer).subscribe(value => {
                this.promoSchedule.retailer = value
            })
        }
    }

    getMutationQuery(): DocumentNode{

        const op = this.promoSchedule.isNewRecord() ? MutationOperation.Create : MutationOperation.Update;

        return gql `
mutation mutateRetailerPromoSchedule(
  $data: RetailerPromoScheduleMutationInput!
) {
  response: mutateRetailerPromoSchedule(op: ${op}, data: $data) {
    success
    message
    retailerPromoSchedule {
      ${this.dialogData.scheduleGqlFields}
    }
  }
}        
        `
    }

}


export interface PromoScheduleEditDialogData {
    schedule: RetailerPromoSchedule
    scheduleGqlFields?: string
}

const QUERY_FETCH_RETAILER = gql `
query getRetailer($id:String!){
  retailers(id:$id){
    id
    retailer_name
  }
}
`;
