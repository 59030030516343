<h2 mat-dialog-title><span style="color: darkorange"
                           *ngIf="mediaContent.isDraft">Draft: </span>{{mediaContent.displayName}}</h2>
<mat-dialog-content>
    <div class="form-content" [formGroup]="form">
        <div class="form-fields">
            <mat-form-field>
                <mat-label>Media content type</mat-label>
                <mat-select formControlName="mediaContentType" [value]="mediaContent.contentType"
                            [compareWith]="isSameObj">
                    <mat-option *ngFor="let mc of mediaContentTypes"
                                [value]="mc.id">{{mc.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Film name</mat-label>
                <input matInput formControlName="displayName" autocomplete="off"/>
            </mat-form-field>

            <app-autocomplete-search-field
                placeholder="Brand"
                [searchObjectType]="'BrandPartner'"
                [value]="mediaContent.brandPartner"
                selectionMode="single"
                showClear="true"
                (valueChanged)="onBrandSelected($event)">
            </app-autocomplete-search-field>

            <mat-form-field *ngIf="isFilm()">
                <mat-label>Film maker</mat-label>
                <input matInput formControlName="filmMaker" autocomplete="off"/>
            </mat-form-field>

            <mat-form-field *ngIf="isFilm()">
                <mat-label>Vimeo ID</mat-label>
                <input matInput formControlName="vimeoVideoId" autocomplete="off"/>
            </mat-form-field>

            <mat-form-field *ngIf="isFilm()">
                <mat-label>Story type</mat-label>
                <mat-select formControlName="storyType">
                    <mat-option *ngFor="let storyType of StoryTypes" [value]="storyType">
                        {{storyType}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isShopperEdSlide()">
                <mat-label>Slide type</mat-label>
                <mat-select formControlName="slideType" [value]="mediaContent.slideType"
                            [compareWith]="isSameObj">
                    <mat-option *ngFor="let s of allSlideTypes"
                                [value]="s">{{s}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isFilm() || isShopperEdSlide()">
                <mat-label>Featured product</mat-label>
                <input matInput formControlName="featuredProduct" autocomplete="off"/>
            </mat-form-field>


            <mat-form-field *ngIf="isFilm()">
                <mat-label>Story IQ</mat-label>
                <input matInput type="number" min="0" max="100" formControlName="storyIqScore"/>
            </mat-form-field>

            <app-autocomplete-search-field
                placeholder="Retailer"
                [searchObjectType]="'Retailer'"
                [value]="mediaContent.retailer"
                selectionMode="single"
                showClear="true"
                (valueChanged)="onRetailerSelected($event)"
                *ngIf="shouldDisplayRetailer()"></app-autocomplete-search-field>

            <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea matInput rows="10" formControlName="description" placeholder="Enter description"></textarea>
            </mat-form-field>

            <mat-slide-toggle [checked]="mediaContent?.isVisible" (change)="setVisibility($event)">Visible in Loop Dashboard</mat-slide-toggle>
        </div>

        <div style="display: flex; flex-direction: column">
            <div class="details-content" *ngIf="this.mediaContent.defaultMediaContentVersion">
                <div style="width: 100%; text-align: center; font-size:24px; color: grey">Loop&trade; Version</div>
                <div class="thumb_preview_container">
                    <img [src]="previewFilmThumbUrl"/>
                    <div class="click_mask" (click)="openThumbPicker()">
                        <span>Click to change</span>
                    </div>
                </div>

                <div style="width: 100%; text-align: center; font-size:24px; color: grey; margin-top:16px;">Splash Image</div>
                <div class="thumb_preview_container" style="margin:0 16px; text-align: center">
                    <div *ngIf="previewFilmSplashUrl">
                        <img [src]="previewFilmSplashUrl"/>
                        <div class="click_mask" (click)="openSplashImagePicker()">
                            <span>Click to change</span>
                        </div>    
                    </div>
                    <div *ngIf="!previewFilmSplashUrl">
                        <button  mat-raised-button color="primary" (click)="openSplashImagePicker()">Add Splash Image</button>
                    </div>
                    
                </div>

                <div class="metadata">
                    <div class="metadata-section">
                        <span style="color: #6e6e6e; font-size: 16px">Uploaded at:</span>
                        <div style="padding-top: 5px; padding-bottom:5px; font-size:14px">
                            <button style="padding-left: 0px" mat-button
                                    [matMenuTriggerFor]="matFileMenu">{{mediaContent.defaultMediaContentVersion.createdAt | date:'MMM d, y hh:mm'}}</button>
                            <mat-menu #matFileMenu="matMenu">
                                <a [href]="mediaContent.defaultMediaContentVersion.processedUrl"
                                   *ngIf="mediaContent.defaultMediaContentVersion.processedUrl" target="_blank"
                                   mat-menu-item>Processed version</a>
                                <a [href]="mediaContent.defaultMediaContentVersion.thumbnail"
                                   *ngIf="mediaContent.defaultMediaContentVersion.thumbnail" target="_blank"
                                   mat-menu-item>Generated thumbnail</a>
                            </mat-menu>
                        </div>
                    </div>


                    <div class="metadata-section" *ngIf="isVimeoDataAvailable(mediaContent)">
                        <span style="color: #6e6e6e; font-size: 16px">Vimeo:</span>
                        <div style="padding-top: 5px; padding-bottom:5px; font-size:14px">
                            <button style="padding-left: 0px" mat-button
                                    [matMenuTriggerFor]="menu">{{mediaContent.vimeoVideoId}}</button>
                            <mat-menu #menu="matMenu">
                                <a [href]="mediaContent.externalUrl" *ngIf="mediaContent.externalUrl" target="_blank"
                                   mat-menu-item>Go to film</a>
                                <a [href]="mediaContent.externalThumbUrl" *ngIf="mediaContent.externalThumbUrl"
                                   target="_blank"
                                   mat-menu-item>View thumbnail</a>
                                <a [href]="mediaContent.externalDownloadUrl" *ngIf="mediaContent.externalDownloadUrl"
                                   target="_blank"
                                   mat-menu-item>Download film</a>
                            </mat-menu>
                        </div>
                    </div>

                    <div class="metadata-section" *ngIf=" mediaContent.mediaContentVersions?.length > 0">
                        <span style="color: #6e6e6e; font-size: 16px">Previous versions:</span>
                        <div style="display: flex">
                            <div *ngFor="let version of mediaContent.mediaContentVersions">
                                <div
                                    style="margin: 5px; cursor: pointer; border: 4px solid #ccc; background-color: #ccc"
                                    [matMenuTriggerFor]="menu">
                                    <img [src]="version.thumbnail" style="height: 80px"/>
                                    <div style="font-size: 12px;margin-top: 5px">
                                        <div>Uploaded at:</div>
                                        <div>{{version.createdAt | date:'MMM d, y hh:mm'}}</div>
                                    </div>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="setActiveVersion(version)">Set active</button>
                                        <button mat-menu-item (click)="deleteVersion(version)">Delete</button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="metadata-section" *ngIf="mediaContent.featuredBrandCampaigns?.length > 0">
                        <span style="color: #6e6e6e; font-size: 16px">Featured in campaigns</span>
                        <table>
                            <tr *ngFor="let campaign of mediaContent.featuredBrandCampaigns">
                                <td><span
                                    style="font-size:15px; color: #0b2554; font-weight: 600">{{campaign.retailer.retailer_id}}</span>
                                </td>
                                <td><span style="font-size:15px"> {{campaign.name}}</span></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>


            <div class="details-content" *ngIf="mediaContent.cleanMediaContentVersion" style="padding-top: 20px">
                <div style="width: 100%; text-align: center; font-size:24px; color: grey">Clean Version</div>
                <div>
                    <img [src]="cleanVersionPreviewThumbUrl"/>
                </div>

                <div class="metadata">
                    <div class="metadata-section">
                        <span style="color: #6e6e6e; font-size: 16px">Uploaded at:</span>
                        <div style="padding-top: 5px; padding-bottom:5px; font-size:14px">
                            <button style="padding-left: 0px" mat-button
                                    [matMenuTriggerFor]="matFileMenu">{{mediaContent.cleanMediaContentVersion.createdAt | date:'MMM d, y hh:mm'}}</button>
                            <mat-menu #matFileMenu="matMenu">
                                <a [href]="mediaContent.cleanMediaContentVersion.processedUrl"
                                   *ngIf="mediaContent.cleanMediaContentVersion.processedUrl" target="_blank"
                                   mat-menu-item>Processed version</a>
                                <a [href]="mediaContent.cleanMediaContentVersion.thumbnail"
                                   *ngIf="mediaContent.cleanMediaContentVersion.thumbnail" target="_blank"
                                   mat-menu-item>Generated thumbnail</a>
                            </mat-menu>
                        </div>
                    </div>

                    <div class="metadata-section" *ngIf=" mediaContent.cleanVersions?.length > 0">
                        <span style="color: #6e6e6e; font-size: 16px">Previous versions:</span>
                        <div style="display: flex">
                            <div *ngFor="let version of mediaContent.cleanVersions">
                                <div
                                    style="margin: 5px; cursor: pointer; border: 4px solid #ccc; background-color: #ccc"
                                    [matMenuTriggerFor]="menu">
                                    <img [src]="version.thumbnail" style="height: 80px"/>
                                    <div style="font-size: 12px;margin-top: 5px">
                                        <div>Uploaded at:</div>
                                        <div>{{version.createdAt | date:'MMM d, y hh:mm'}}</div>
                                    </div>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="setActiveVersion(version)">Set active</button>
                                        <button mat-menu-item (click)="deleteVersion(version)">Delete</button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleAction('ok')" [disabled]="!form.valid || isBusy()">Ok
    </button>
    <button mat-button (click)="handleAction('cancel')">cancel</button>
</mat-dialog-actions>