<h2 matDialogTitle style="padding-top:0; margin-top:0">
    <div style="display: flex; align-items: center">
        <div style="padding-right: 10px">
            <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
        </div>
        <div *ngIf="!isLoading">{{bucketName}}</div>
        <div *ngIf="isLoading">Loading content ...</div>
    </div>
</h2>

<div mat-dialog-content>
    <table mat-table [dataSource]="dataSource" style="width: 100%" *ngIf="this.dataSource">
        <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.objectType == 'folder'">folder</mat-icon>
                <mat-icon *ngIf="element.objectType == 'file'">description</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.objectType == 'file'">
                    <a style="cursor: pointer" [matMenuTriggerFor]="menu"> {{element.displayName}}</a>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onItemSelected(element)">Select file</button>
                        <button mat-menu-item (click)="onPreview(element)">Preview</button>
                    </mat-menu>
                </div>
                <div *ngIf="element.objectType == 'folder'">
                    <a style="cursor: pointer" (click)="onItemSelected(element)"> {{element.displayName}}</a>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef> Size</th>
            <td mat-cell *matCellDef="let element"> {{element.sizeInMB}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type</th>
            <td mat-cell *matCellDef="let element"> {{element.objectTypeDisplayName}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Last modified</th>
            <td mat-cell *matCellDef="let element"> {{element.uploadedAt | date}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div mat-dialog-actions class="pt-24">
    <button mat-flat-button (click)="dialogRef.close(null)">Cancel</button>
</div>


