import {BaseModel} from "@looma/shared/models/base_model";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";

export class RetailerYearSchedules extends BaseModel {
    scheduleEntries: RetailerYearSchedule[]

    assign(input: any): this {
        super.assign(input);
        this.assignTypedArray(input, 'scheduleEntries', RetailerYearSchedule);
        return this;
    }
}

export class RetailerYearSchedule extends BaseModel {
    year: string
    promoPeriodEntries: SchedulePromoPeriodEntry[]

    assign(input: any): this {
        super.assign(input);
        this.assignTypedArray(input, 'promoPeriodEntries', SchedulePromoPeriodEntry);
        return this;
    }
}

export class PromoPeriodSegment extends BaseModel {
    deviceSlotSegmentId: string
    deviceSlotSegmentName: string
    campaigns: ScheduledCampaign[]

    assign(input: any): this {
        super.assign(input);
        this.assignTypedArray(input, 'campaigns', ScheduledCampaign);
        return this;
    }
}

export class ScheduledCampaign extends BaseModel {
    id: string
    name: string
    brandPartnerName: string
    status: string
    brandCampaign: BrandPromoCampaign
    isSubmission: boolean

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'brandCampaign', BrandPromoCampaign);
        return this;
    }
}

export class SchedulePromoPeriodEntry extends BaseModel {
    retailerPromoPeriod: RetailerPromoPeriod
    segments: PromoPeriodSegment[]

    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'retailerPromoPeriod', RetailerPromoPeriod);
        this.assignTypedArray(input, 'segments', PromoPeriodSegment);
        return this;
    }
}
